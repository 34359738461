import {
  Box,
  Button,
  Card,
  Center,
  Flex,
  FormControl,
  HStack,
  Icon,
  Input,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  Textarea,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { GenericModal } from "../../../common/generic-modal";
import { useAddBasic } from "../../../../service/education.service";
import { getUserId, languageArray } from "../../../../utils/helper";
import { useFetch } from "../../../../utils/useFetch";
import { upload } from "../../../../assets/images";
import AWS from "aws-sdk";
import { storage } from "../../../../firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { MdCancel } from "react-icons/md";

interface EditBasicInfoProps {
  isOpen: any;
  onClose: any;
  basicInfo?: any;
}
const EditBasicInfo = ({ isOpen, onClose, basicInfo }: EditBasicInfoProps) => {
  const userId = getUserId();
  const { mutate: basicMutation, isLoading: basicLoading } = useAddBasic();
  const inputref = useRef<HTMLInputElement>(null);
  const [achievement, setAchievementsList] = useState<any>([]);
  const [shortBio, setShortBio] = useState<any>("");
  const [linkedinProfileLink, setLinkedinProfileLink] = useState<any>("");
  const [interest, setInterest] = useState<any>([]);
  const [spokenLanguagesList, setSpokenLanguagesList] = useState<any>([]);
  const [errors, setErrors] = useState<any>({
    achievement: "",
    interest: "",
    spokenLanguagesList: "",
    duties: "",
    shortBio: "",
    linkedinProfileLink: "",
    websiteOrPortfolioLink: "",
    gitHubLink: "",
  });

  const [achievementName, setAchievementName] = useState("");

  const [spokenLanguage, setSpokenLanguage] = useState("");
  const [showLanguagesResult, setshowLanguagesResult] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [filteredLanguages, setFilteredLanguages] = useState<any>([]);
  const [hobby, setHobby] = useState("");
  const [interestResult, setInterestResult] = useState<any>([]);
  const [showInterestResult, setShowInterestResult] = useState(false);
  const [websiteOrPortfolioLink, setWebsiteOrPortfolioLink] = useState("");
  const [gitHubLink, setGitHubLink] = useState("");
  const languageResultRef = useRef<HTMLDivElement>(null);
  const interestResultRef = useRef<HTMLDivElement>(null);
  const [user, setUser] = useState({});
  const [uploadedFileName, setUploadedFileName] = useState<string>();
  const fileInputRef = useRef<any>(null);
  const [resumeType, setResumeType] = useState<string>();
  const [resumeOrLinkedin, setResumeOrLinkedin] = useState("");
  const [resumeFile, setResumeFile] = useState<File | null>(null);
  const [isLoading, setIsloading] = useState(false);
  const [continueLoading, setContinueLoading] = useState(false);
  const [fileValidity, setFileValidity] = useState({
    valid: true,
    message: "",
  });

  const handleFileInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file: any = event.target.files?.[0];
    const imageFileType = ["pdf", "doc", "docx"];
    setUploadedFileName(file.name);

    if (!file) {
      return;
    }
    const fileExtension = file.name.split(".").pop()?.toLowerCase();

    if (!fileExtension || !imageFileType.includes(fileExtension)) {
      setFileValidity({
        valid: false,
        message: "Invalid file type. Please select a PDF, DOC, or DOCX file.",
      });
      return;
    }

    setFileValidity({
      valid: true,
      message: "",
    });

    const S3_BUCKET = process.env.REACT_APP_S3_BUCKET || "";
    const REGION = process.env.REACT_APP_REGION;

    AWS.config.update({
      accessKeyId: process.env.REACT_APP_accessKeyId,
      secretAccessKey: process.env.REACT_APP_secretAccessKey,
    });
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });
    const folder = "resume";
    const params = {
      Bucket: S3_BUCKET,
      Key: `${folder}/${file.name}`,
      Body: file,
    };

    try {
      const upload = await s3.putObject(params).promise();
      const url = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${folder}/${file.name}`;
      setResumeOrLinkedin(url as string);
      setResumeType(url ? "pdf" : "");
      setUploadedFileName(file.name);
      setUser({ ...user, resumeOrLinkedin: url });
      setIsloading(false);
    } catch (error: any) {
      console.error(error);
      setIsloading(false);
    }
  };

  const handleAddAchievements = () => {
    if (achievementName) {
      if (!achievement?.includes(achievementName)) {
        setAchievementsList((prevAchievementsList: any) => [
          ...prevAchievementsList,
          achievementName,
        ]);
        setAchievementName("");
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          achievement: "",
        }));
      } else {
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          achievement: "You cannot Add Duplicate Option",
        }));
      }
    }
  };
  const handleRemoveAchievements = (indexToRemove: number) => {
    setAchievementsList((prevAchievementsList: any) =>
      prevAchievementsList.filter(
        (_: any, index: number) => index !== indexToRemove
      )
    );
  };
  function handleClickOutside(event: MouseEvent) {
    if (
      languageResultRef.current &&
      !languageResultRef.current.contains(event.target as Node)
    ) {
      setshowLanguagesResult(false);
    }

    if (
      interestResultRef.current &&
      !interestResultRef.current.contains(event.target as Node)
    ) {
      handleFocusBlur(false);

      setShowInterestResult(false);
    }
  }
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (basicInfo?.userId) {
      {
        basicInfo?.achievement && setAchievementsList(basicInfo?.achievement);
      }
      {
        basicInfo?.shortBio && setShortBio(basicInfo?.shortBio);
      }
      {
        basicInfo?.linkedInProfile &&
          setLinkedinProfileLink(basicInfo?.linkedInProfile);
        basicInfo?.linkedInProfile !== null &&
          basicInfo?.linkedInProfile !== "" &&
          setResumeType("url");
      }
      {
        basicInfo?.portfolio && setWebsiteOrPortfolioLink(basicInfo?.portfolio);
        basicInfo?.resume && setResumeOrLinkedin(basicInfo?.resume);
        basicInfo?.resume !== null &&
          basicInfo?.resume !== "" &&
          setResumeType("pdf");
      }
      {
        basicInfo?.githubProfileLink &&
          setGitHubLink(basicInfo?.githubProfileLink);
      }

      {
        basicInfo?.interest && setInterest(basicInfo?.interest);
      }
      {
        basicInfo?.spokenLanguagesList &&
          setSpokenLanguagesList(basicInfo?.spokenLanguagesList);
      }
    }
  }, [basicInfo]);

  const handleLanguagesInputChange = (e: any) => {
    const inputValue = e.target.value.trimStart();
    setSpokenLanguage(inputValue);
    setshowLanguagesResult(true);
    const filtered = languageArray.filter((language) =>
      language.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredLanguages(filtered);
  };
  const handleValidation = () => {
    let error: any = {
      achievement: "",
      interest: "",
      spokenLanguagesList: "",
      duties: "",
      shortBio: "",
      linkedinProfileLink: "",
      websiteOrPortfolioLink: "",
      gitHubLink: "",
    };

    let formIsValid = true;

    if (!shortBio) {
      formIsValid = false;
      error.shortBio = "Please enter bio";
    }

    if (!achievement?.length) {
      formIsValid = false;
      error.achievement = "Please Add Achievements";
    }

    if (!interest?.length) {
      formIsValid = false;
      error.interest = "Please Add interests and Hobbies";
    }
    if (!spokenLanguagesList?.length) {
      formIsValid = false;
      error.spokenLanguagesList = "Please Add Spoken Languages";
    }
    if (!resumeType) {
      formIsValid = false;
      error.linkedinProfileLink = "Please enter LinkedIn url or upload resume";
    }
    if (fileValidity.message) {
      formIsValid = false;
      error.linkedinProfileLink = fileValidity.message;
    }
    if (errors?.linkedIn?.length > 1) {
      formIsValid = false;
      errors.linkedIn = errors?.linkedIn;
    }
    if (errors?.portfolio?.length > 1) {
      formIsValid = false;
      error.portfolio = errors?.portfolio;
    }
    if (errors?.github?.length > 1) {
      formIsValid = false;
      error.github = errors?.github;
    }

    setErrors(error);
    return formIsValid;
  };

  const onSubmit = () => {
    if (!!handleValidation()) {
      onModalClose();
    }
  };
  const onModalClose = () => {
    basicMutation(
      {
        userId,
        achievement,
        shortBio,
        linkedInProfile:
          resumeType === "pdf" ? null : linkedinProfileLink.trim(),
        resume: resumeType === "pdf" ? resumeOrLinkedin : null,
        portfolio: websiteOrPortfolioLink.trim(),
        githubProfileLink: gitHubLink.trim(),
        interest,
        spokenLanguagesList,
        duties: [],
      },
      {
        onSuccess: () => {
          onClose();
          setErrors({
            achievement: "",
            interest: "",
            spokenLanguagesList: "",
            duties: "",
            shortBio: "",
            linkedinProfileLink: "",
            websiteOrPortfolioLink: "",
            gitHubLink: "",
          });
        },
      }
    );
  };
  const handleAddSpokenLanguage = (spokenLanguageProps: string) => {
    if (spokenLanguageProps) {
      setshowLanguagesResult(false);
      if (!spokenLanguagesList?.includes(spokenLanguageProps)) {
        setSpokenLanguagesList((prevSpoken: any) => [
          ...prevSpoken,
          spokenLanguageProps,
        ]);
        setSpokenLanguage("");
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          spokenLanguagesList: "",
        }));
      } else {
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          spokenLanguagesList: "You cannot Add Duplicate Option",
        }));
      }
    }
  };
  const handleAddInterests = (hobbyProps: string) => {
    if (hobbyProps) {
      setShowInterestResult(false);
      if (!interest.includes(hobbyProps)) {
        setInterest((prevSpoken: any) => [...prevSpoken, hobbyProps]);
        setHobby("");
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          interest: "",
        }));
      } else {
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          interest: "You cannot Add Duplicate Option",
        }));
      }
    }
  };
  const handleRemoveSpokenLanguage = (indexToRemove: number) => {
    setSpokenLanguagesList((prevSpoken: any) =>
      prevSpoken.filter((_: any, index: number) => index !== indexToRemove)
    );
  };
  const handleRemoveInterest = (indexToRemove: number) => {
    setInterest((prevInterest: any) =>
      prevInterest.filter((_: any, index: number) => index !== indexToRemove)
    );
  };
  const handleFocusBlur = (value: boolean) => {
    setIsFocused(value);
  };
  useEffect(() => {
    if (isFocused) {
      if (hobby) {
        useFetch
          .get(`/users/domain/hobbies-interest?query=${hobby}`)
          .then((response) => {
            setInterestResult(response.data);
            setShowInterestResult(true);
          })
          .catch((error) => {
            console.log("Error", error);
          });
      }
    }
  }, [hobby]);

  return (
    <>
      <GenericModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setErrors({
            achievement: "",
            interest: "",
            spokenLanguagesList: "",
            duties: "",
            shortBio: "",
            linkedinProfileLink: "",
            websiteOrPortfolioLink: "",
            gitHubLink: "",
          });
          setFileValidity({
            valid: true,
            message: "",
          });
        }}
        title={"Edit Additional Information"}
        onClickHandler={() => console.log("values")}
        buttonText={false ? "Loading" : "Save"}
        loadingButton={false || false}
        buttonShow={false}
      >
        <form
          style={{ width: "100%" }}
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
          onKeyDown={(e: any) => {
            if (e.key === "Enter") {
              e.preventDefault();
              onSubmit();
            }
          }}
        >
          <Box position="relative" width="full" background="white">
            <VStack w="full" gap={"4"}>
              <VStack w="full">
                <Card w="full" p="4">
                  <HStack>
                    <Text
                      color="#48465b"
                      fontWeight="bold"
                      fontSize="md"
                      textAlign="left"
                    >
                      Add a Short Bio
                    </Text>
                    <Box as="span" ml="2" color="red" fontSize="md" mt="1">
                      *
                    </Box>
                  </HStack>
                  <Text fontSize="12px">
                    Describe your professional background and what you're
                    looking for in your next role.
                  </Text>
                  <Textarea
                    isInvalid={Boolean(!shortBio && errors?.shortBio)}
                    placeholder="Enter a short Bio"
                    size="sm"
                    border="1px solid grey"
                    borderRadius="10px"
                    onChange={(e) => setShortBio(e.target.value.trimStart())}
                    value={shortBio}
                    maxLength={250}
                  />
                  {!shortBio && errors?.shortBio && (
                    <Text
                      color="red.500"
                      fontSize="sm"
                      textAlign="left"
                      w="full"
                      pl="1"
                    >
                      {errors?.shortBio}
                    </Text>
                  )}
                </Card>
              </VStack>
              <VStack w="full">
                <Card w="full" p="4" gap="4">
                  <FormControl
                    position="relative"
                    isInvalid={Boolean(errors?.achievement)}
                  >
                    <HStack>
                      <Text
                        color="#48465b"
                        fontWeight="bold"
                        fontSize="md"
                        textAlign="left"
                      >
                        Add Achievements
                      </Text>
                      <Box as="span" ml="2" color="red" fontSize="md" mt="1">
                        *
                      </Box>
                    </HStack>
                    <Text fontSize="12px">
                      List any awards, publications, or other accomplishments
                      that set you apart.
                    </Text>
                    <HStack pt="2">
                      <Input
                        maxLength={50}
                        type="text"
                        placeholder="Add Your Achievement"
                        maxW={{ base: "100%", md: "70%" }}
                        borderColor="grey"
                        onChange={(e) =>
                          setAchievementName(e.target.value.trimStart())
                        }
                        value={achievementName}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.stopPropagation();
                            e.preventDefault();
                            handleAddAchievements();
                          }
                        }}
                      />

                      <Button
                        ml="2"
                        colorScheme="buttonPrimary"
                        onClick={handleAddAchievements}
                      >
                        Add
                      </Button>
                    </HStack>
                    {errors?.achievement && (
                      <Text
                        color="red.500"
                        fontSize="sm"
                        textAlign="left"
                        w="full"
                        pl="1"
                      >
                        {errors?.achievement}
                      </Text>
                    )}
                  </FormControl>
                  <Flex gap={"10px"}>
                    {achievement &&
                      achievement?.map((functions: any, index: number) => (
                        <Tag key={index}>
                          <TagLabel>{functions}</TagLabel>
                          <TagCloseButton
                            onClick={() => handleRemoveAchievements(index)}
                          />
                        </Tag>
                      ))}
                  </Flex>
                </Card>
              </VStack>
              <VStack w="full">
                <Card w="full" p="4" gap="4">
                  <FormControl
                    position="relative"
                    isInvalid={Boolean(errors?.spokenLanguagesList)}
                  >
                    <HStack>
                      <Text
                        color="#48465b"
                        fontWeight="bold"
                        fontSize="md"
                        textAlign="left"
                      >
                        Add Spoken Languages
                      </Text>
                      <Box as="span" ml="2" color="red" fontSize="md" mt="1">
                        *
                      </Box>
                    </HStack>
                    <HStack pt="2">
                      <Input
                        maxLength={50}
                        type="text"
                        placeholder="Search Language"
                        maxW={{ base: "100%", md: "70%" }}
                        borderColor="grey"
                        onChange={handleLanguagesInputChange}
                        value={spokenLanguage}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.stopPropagation();
                            e.preventDefault();
                            handleAddSpokenLanguage(spokenLanguage.trim());
                          }
                        }}
                      />

                      <Button
                        ml="2"
                        colorScheme="buttonPrimary"
                        onClick={() => handleAddSpokenLanguage(spokenLanguage)}
                      >
                        Add
                      </Button>
                    </HStack>
                    {showLanguagesResult && filteredLanguages.length >= 1 && (
                      <VStack
                        ref={languageResultRef}
                        height="100px"
                        overflowY="scroll"
                        position="absolute"
                        top="78px"
                        zIndex="50"
                        bg="white"
                        border="1px solid #F1F0F9"
                        borderRadius="10px"
                        w="70%"
                        boxShadow="0px 8px 32px 0px rgba(78, 48, 123, 0.04)"
                        padding="5px 0px"
                      >
                        {filteredLanguages.map((data: any, index: number) => (
                          <Flex
                            w="full"
                            p="5px"
                            borderBottom="1px solid #eae8e8"
                            onClick={() => handleAddSpokenLanguage(data)}
                            _hover={{ bg: "#F1F0F9", color: "black" }}
                            key={index}
                            cursor="pointer"
                          >
                            <Text ms="4px">{data}</Text>
                          </Flex>
                        ))}
                      </VStack>
                    )}
                    {errors?.spokenLanguagesList && (
                      <Text
                        color="red.500"
                        fontSize="sm"
                        textAlign="left"
                        w="full"
                        pl="1"
                      >
                        {errors?.spokenLanguagesList}
                      </Text>
                    )}
                  </FormControl>
                  <Flex gap={"10px"}>
                    {spokenLanguagesList &&
                      spokenLanguagesList?.map(
                        (functions: any, index: number) => (
                          <Tag key={index}>
                            <TagLabel>{functions}</TagLabel>
                            <TagCloseButton
                              onClick={() => handleRemoveSpokenLanguage(index)}
                            />
                          </Tag>
                        )
                      )}
                  </Flex>
                </Card>
              </VStack>
              <VStack w="full">
                <Card w="full" p="4" gap="4">
                  <FormControl
                    position="relative"
                    isInvalid={Boolean(errors?.interest)}
                  >
                    <HStack>
                      <Text
                        color="#48465b"
                        fontWeight="bold"
                        fontSize="md"
                        textAlign="left"
                      >
                        Add Interests and Hobbies
                      </Text>
                      <Box as="span" ml="2" color="red" fontSize="md" mt="1">
                        *
                      </Box>
                    </HStack>
                    <HStack pt="2">
                      <Input
                        maxLength={50}
                        type="text"
                        placeholder="Search Interests and Hobbies"
                        maxW={{ base: "100%", md: "70%" }}
                        ref={inputref}
                        onFocus={() => handleFocusBlur(true)}
                        onBlur={() => handleFocusBlur(false)}
                        borderColor="grey"
                        onChange={(e) => setHobby(e.target.value.trimStart())}
                        value={hobby}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.stopPropagation();
                            e.preventDefault();
                            handleAddInterests(hobby);
                          }
                        }}
                      />

                      <Button
                        ml="2"
                        colorScheme="buttonPrimary"
                        onClick={() => handleAddInterests(hobby)}
                      >
                        Add
                      </Button>
                    </HStack>
                    {showInterestResult && (
                      <VStack
                        ref={interestResultRef}
                        height="100px"
                        overflowY="scroll"
                        position="absolute"
                        top="74px"
                        zIndex="50"
                        bg="white"
                        border="1px solid #F1F0F9"
                        borderRadius="10px"
                        w="70%"
                        boxShadow="0px 8px 32px 0px rgba(78, 48, 123, 0.04)"
                        padding="5px 0px"
                      >
                        {interestResult.map((data: any, index: number) => (
                          <Flex
                            w="full"
                            p="5px"
                            borderBottom="1px solid #eae8e8"
                            onClick={() => handleAddInterests(data.name)}
                            _hover={{ bg: "#F1F0F9", color: "black" }}
                            key={index}
                            cursor="pointer"
                          >
                            <Text ms="4px">{data.name}</Text>
                          </Flex>
                        ))}
                      </VStack>
                    )}
                    {errors?.interest && (
                      <Text
                        color="red.500"
                        fontSize="sm"
                        textAlign="left"
                        w="full"
                        pl="1"
                      >
                        {errors?.interest}
                      </Text>
                    )}
                  </FormControl>
                  <Flex gap={"10px"}>
                    {interest &&
                      interest?.map((interest: any, index: number) => (
                        <Tag key={index}>
                          <TagLabel>{interest}</TagLabel>
                          <TagCloseButton
                            onClick={() => handleRemoveInterest(index)}
                          />
                        </Tag>
                      ))}
                  </Flex>
                </Card>
              </VStack>
              <Flex direction="column" w="full" gap={2}>
                <Text
                  color="#48465b"
                  fontWeight="bold"
                  fontSize="md"
                  textAlign="left"
                >
                  Professional Networks
                </Text>
                <Card w="full" p="4" gap={2}>
                  <FormControl
                    isInvalid={!resumeType && errors?.linkedinProfileLink}
                    w="full"
                    gap={2}
                  >
                    <HStack>
                      <Text
                        color="#48465b"
                        fontWeight="bold"
                        fontSize="md"
                        textAlign="left"
                      >
                        LinkedIn Profile:
                      </Text>
                      <Box as="span" ml="2" color="red" fontSize="md" mt="1">
                        *
                      </Box>
                    </HStack>
                    <Flex
                      align="start"
                      gap="3"
                      direction={{ base: "column", md: "row" }}
                    >
                      <Input
                        maxLength={255}
                        placeholder="Enter Linkedin"
                        size="md"
                        isDisabled={resumeType === "pdf" ? true : false}
                        value={linkedinProfileLink}
                        onChange={(e) => {
                          const newLinkedinProfileLink = e.target.value;
                          setLinkedinProfileLink(newLinkedinProfileLink);
                          setResumeType(
                            newLinkedinProfileLink?.length === 0 ? "" : "url"
                          );
                          const linkedinURLPattern =
                            /^(https?:\/\/)?(www\.)?linkedin\.com\/.*$/;
                          if (
                            newLinkedinProfileLink?.length > 0 &&
                            !linkedinURLPattern.test(newLinkedinProfileLink)
                          ) {
                            setErrors((prevError: any) => ({
                              ...prevError,
                              linkedinProfileLink:
                                "Please enter a valid LinkedIn profile URL.",
                            }));
                          } else {
                            setErrors((prevError: any) => ({
                              ...prevError,
                              linkedinProfileLink: "",
                            }));
                          }
                        }}
                        border="1px solid grey"
                        w="70%"
                      />
                      <Text pt="2">or</Text>
                      {/* <Flex direction="column"> */}
                      <div
                        style={{ position: "relative", width: "fit-content" }}
                      >
                        <input
                          type="file"
                          style={{ display: "none" }}
                          accept=".pdf, .docx, .doc"
                          id="resumeUpload"
                          ref={fileInputRef}
                          onChange={handleFileInputChange}
                        />

                        {resumeOrLinkedin && (
                          <Icon
                            as={MdCancel}
                            cursor="pointer"
                            bg="white"
                            color="gray.500"
                            borderRadius="full"
                            position="absolute"
                            top="-10px"
                            zIndex="10"
                            right="-5px"
                            fontSize="19px"
                            onClick={() => {
                              setResumeOrLinkedin("");
                              setResumeType("");
                              setUploadedFileName("");
                            }}
                          />
                        )}

                        <Flex w="full" direction="column" gap="5px">
                          <Button
                            onClick={() =>
                              fileInputRef.current &&
                              fileInputRef.current.click()
                            }
                            isLoading={isLoading}
                            isDisabled={resumeType === "url" || isLoading}
                            colorScheme="buttonPrimary"
                          >
                            Upload Resume
                          </Button>
                          <Text>{uploadedFileName}</Text>
                        </Flex>
                      </div>
                    </Flex>
                    {/* </Flex> */}
                    {errors?.linkedinProfileLink ? (
                      <Text
                        color="red.500"
                        fontSize="sm"
                        textAlign="left"
                        w="full"
                        pl="1"
                      >
                        {errors?.linkedinProfileLink}
                      </Text>
                    ) : fileValidity?.message ? (
                      <Text
                        color="red.500"
                        fontSize="sm"
                        textAlign="left"
                        w="full"
                        pl="1"
                      >
                        {fileValidity.message}
                      </Text>
                    ) : (
                      ""
                    )}
                  </FormControl>
                  <Flex direction="column" w="full">
                    <Text
                      color="#48465b"
                      fontWeight="bold"
                      fontSize="md"
                      textAlign="left"
                    >
                      Website or Portfolio:
                    </Text>
                    <Input
                      maxLength={255}
                      placeholder="Enter Website or Portfolio:"
                      size="md"
                      value={websiteOrPortfolioLink}
                      onChange={(e) => {
                        const newWebsiteOrPortfolioLink = e.target.value;
                        setWebsiteOrPortfolioLink(newWebsiteOrPortfolioLink);

                        const urlPattern =
                          /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?$/;

                        if (
                          newWebsiteOrPortfolioLink?.length > 0 &&
                          !urlPattern.test(newWebsiteOrPortfolioLink)
                        ) {
                          setErrors((prevError: any) => ({
                            ...prevError,
                            portfolio: "Please enter a valid URL.",
                          }));
                        } else {
                          setErrors((prevError: any) => ({
                            ...prevError,
                            portfolio: "",
                          }));
                        }
                      }}
                      border="1px solid grey"
                    />
                    {errors?.portfolio && (
                      <Text
                        color="red.500"
                        fontSize="sm"
                        textAlign="left"
                        w="full"
                        pl="1"
                      >
                        {errors?.portfolio}
                      </Text>
                    )}
                  </Flex>
                  <Flex direction="column" w="full">
                    <Text
                      color="#48465b"
                      fontWeight="bold"
                      fontSize="md"
                      textAlign="left"
                    >
                      GitHub Profile:
                    </Text>
                    <Input
                      maxLength={255}
                      placeholder="Enter GitHub Profile"
                      size="md"
                      value={gitHubLink}
                      onChange={(e) => {
                        const newGtihubLink = e.target.value;
                        setGitHubLink(newGtihubLink);

                        const urlPattern =
                          /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?$/;

                        if (
                          newGtihubLink?.length > 0 &&
                          !urlPattern.test(newGtihubLink)
                        ) {
                          setErrors((prevError: any) => ({
                            ...prevError,
                            github: "Please enter a valid URL.",
                          }));
                        } else {
                          setErrors((prevError: any) => ({
                            ...prevError,
                            github: "",
                          }));
                        }
                      }}
                      border="1px solid grey"
                    />
                    {errors?.github && (
                      <Text
                        color="red.500"
                        fontSize="sm"
                        textAlign="left"
                        w="full"
                        pl="1"
                      >
                        {errors?.github}
                      </Text>
                    )}
                  </Flex>
                </Card>
              </Flex>

              <Center>
                <Button
                  colorScheme="buttonPrimary"
                  mt="6"
                  type="submit"
                  isLoading={basicLoading}
                  isDisabled={basicLoading}
                >
                  Save
                </Button>
              </Center>
            </VStack>
          </Box>
        </form>
      </GenericModal>
    </>
  );
};

export default EditBasicInfo;
