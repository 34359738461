import {
  Flex,
  Box,
  FormControl,
  Input,
  InputGroup,
  HStack,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  InputLeftElement,
  chakra,
  FormErrorMessage,
  Image,
} from "@chakra-ui/react";
import { useState } from "react";
import { FaUserAlt } from "react-icons/fa";
import useSignup from "./useSignup";
import RadioGroup from "../common/radioGroup";
import logo from "../../assets/images/logoBlack.svg";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import "./signup.css";
import Registration from "./Registration/registration";

const CFaUserAlt = chakra(FaUserAlt);

export default function Signup() {
  const [isHiringLogin, setIsHiringLogin] = useState(true);
  const {
    register,
    handleSubmit,
    errors,
    onSubmit,

    setSignupType,

    signupType,
    signupLoading,
    setValue,
  } = useSignup();

  let updatedURL =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname +
    "?client=";

  updatedURL += signupType === "Looking for job" ? "candidate" : "employer";

  window.history.pushState({ path: updatedURL }, "", updatedURL);
  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      backgroundColor="gray.50"
    >
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <Image
            src={logo}
            maxW={{ base: "120px", md: "200px" }}
            mb="8"
            // mr="30px"
          />
          <Heading fontSize={"2xl"} textAlign={"center"} fontWeight={"500"}>
            Sign up / Login
          </Heading>
        </Stack>
        <Box
          rounded={"md"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={{ base: "5", sm: "6", md: "8" }}
        >
          <Stack spacing={4} flexWrap={"nowrap"} align="center">
            <RadioGroup
              options={["Looking for job", "Hiring Talent"]}
              name="signupType"
              defaultValue={signupType}
              onChange={(value: string) => {
                setSignupType(value);
              }}
              maxW="100%"
              justify="center"
            />
            {signupType === "Hiring Talent" && (
              <>
                {isHiringLogin ? (
                  <Flex direction="column" gap="4">
                    <FormControl isRequired isInvalid={Boolean(errors?.email)}>
                      <InputGroup>
                        <InputLeftElement
                          pointerEvents="none"
                          children={<CFaUserAlt color="gray.300" />}
                        />
                        <Input
                          type="email"
                          placeholder="Email address"
                          {...register("email", {
                            required: "Email is required",
                            pattern: {
                              value:
                                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                              message: "Invalid email address",
                            },
                          })}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleSubmit(onSubmit)();
                            }
                          }}
                        />
                      </InputGroup>
                      {errors.email && (
                        <Text color="red" textAlign="left" w="full" pl="1">
                          {errors.email.message}
                        </Text>
                      )}
                    </FormControl>

                    <Button
                      type="submit"
                      variant="solid"
                      colorScheme="buttonPrimary"
                      width="full"
                      onClick={handleSubmit(onSubmit)}
                      isLoading={signupLoading}
                      isDisabled={signupLoading}
                    >
                      Login
                    </Button>
                    <HStack justify="center">
                      <Text fontSize="12px" fontWeight="500">
                        Don’t have an account ?
                      </Text>
                      <Text
                        fontSize="12px"
                        cursor="pointer"
                        fontWeight="600"
                        _hover={{
                          color: "blue.500",
                          textDecoration: "underline",
                        }}
                        onClick={() => setIsHiringLogin(false)}
                      >
                        Sign Up
                      </Text>
                    </HStack>
                  </Flex>
                ) : (
                  <Registration setIsHiringLogin={setIsHiringLogin} />
                )}
              </>
            )}
            {signupType === "Looking for job" && (
              <Flex direction="column" gap={5} align="center">
                <FormControl
                  isRequired
                  isInvalid={Boolean(errors?.phoneNumber)}
                >
                  <InputGroup>
                    <PhoneInput
                      className="phone-input"
                      placeholder="Enter phone number"
                      defaultCountry="US"
                      limitMaxLength={true}
                      value={""}
                      onChange={(value) => {
                        if (value) {
                          setValue("phoneNumber", value);
                        }
                      }}
                      onKeyDown={(e: any) => {
                        if (e.key === "Enter") {
                          handleSubmit(onSubmit)();
                        }
                      }}
                    />
                  </InputGroup>
                  <Text fontSize="sm" color="red.500">
                    {errors?.phoneNumber?.message}
                  </Text>
                </FormControl>

                <Button
                  // borderRadius={0}
                  type="submit"
                  variant="solid"
                  colorScheme="buttonPrimary"
                  width="full"
                  onClick={handleSubmit(onSubmit)}
                  isLoading={signupLoading}
                  isDisabled={signupLoading}
                >
                  Sign up / Login
                </Button>
              </Flex>
            )}
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}
