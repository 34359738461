import {
  Box,
  Button,
  Card,
  Center,
  HStack,
  Heading,
  Spinner,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { BiPlus } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import useEducation from "./useEducation";
import EducationCard from "./educationCard";

const Education = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const { educationList, educationListLoading, educationDelLoading } =
    useEducation();

  return (
    <Card p="4" w="full">
      <Box my="4">
        <Stack>
          <VStack w="full" align="start">
            <Heading size="lg" fontWeight={"500"}>
              Education Information
            </Heading>{" "}
            <Text>
              Add degrees with details of the school, major, and education
              status.
            </Text>
            {/* {educationList && educationList.length < 3 && ( */}
            <HStack justify="start" w="full">
              <Button
                leftIcon={<BiPlus />}
                colorScheme="buttonPrimary"
                variant="outline"
                size={"sm"}
                _hover={{ color: "white", bg: "buttonPrimary.600" }}
                onClick={() => navigate("add")}
              >
                Add Education
              </Button>
            </HStack>
            {/* )} */}
          </VStack>
          {educationListLoading ? (
            <Center>
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            </Center>
          ) : educationList && educationList?.length > 0 ? (
            educationList?.map((item: any, index: any) => (
              <EducationCard
                data={item}
                key={index}
                index={index}
                isLoading={educationDelLoading}
              />
            ))
          ) : (
            <Center
              h="100px"
              border="1px solid #e3e3e3"
              w="full"
              borderRadius={"md"}
            >
              <Text color={"#c1c1c1"}> No Educational Data available</Text>
            </Center>
          )}
        </Stack>
        {educationList && educationList?.length !== 0 && (
          <Button
            colorScheme="buttonPrimary"
            mt="4"
            onClick={() => {
              state && state === "profile"
                ? navigate(-1)
                : navigate("/setupFlow/experience");
            }}
          >
            {state && state === "profile" ? "Save" : "Continue"}
          </Button>
        )}
      </Box>
    </Card>
  );
};

export default Education;
