import React, { ReactNode, useEffect, useState } from "react";
import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Button,
  Image,
} from "@chakra-ui/react";
import {
  FiHome,
  FiSettings,
  FiMenu,
  FiBell,
  FiShoppingBag,
  FiSearch,
  FiUser,
  FiMessageSquare,
  FiBarChart2,
} from "react-icons/fi";
import { IconType } from "react-icons";
import { ReactText } from "react";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useAuthStore } from "../../../store/useAuth";
import { getUserId, handleLogoutLocalStorage } from "../../../utils/helper";
import logo from "../../../assets/images/logoWhite.svg";
import logoBlack from "../../../assets/images/logoBlack.svg";
import { useUserStore } from "../../../store/useUser";
import { firebase_db } from "../../../firebase";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { useFetchJobs } from "../../../service/candidate.service";
import { useFetchApprovedJobs } from "../../../service/job.service";
import { useGeneralInfoFetch } from "../../../service/education.service";
import {
  useFetchCadidateScheldules,
  useFetchEmployerScheldules,
} from "../../../service/schedule.service";

export default function SidebarWithHeader({
  children,
  LinkItemProps,
}: {
  children: ReactNode;
  LinkItemProps: any;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "none", lg: "block" }}
        LinkItemProps={LinkItemProps}
        isMobile={false}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size={"xs"}
      >
        <DrawerContent>
          <SidebarContent
            onClose={onClose}
            LinkItemProps={LinkItemProps}
            onCloseDrawer={onClose}
            isMobile={true}
          />
        </DrawerContent>
      </Drawer>

      <MobileNav onOpen={onOpen} />
      <Box
        ml={{ base: 0, md: 0, lg: "260px" }}
        h={`calc(100vh - 64px)`}
        overflow="auto"
        id="mainDivBox"
      >
        {children}
      </Box>
    </Box>
  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
  LinkItemProps: any;
  onCloseDrawer?: () => void;
  isMobile: boolean;
}

const SidebarContent = ({
  onClose,
  LinkItemProps,
  onCloseDrawer,
  isMobile,
  ...rest
}: SidebarProps) => {
  const chatRoomsRef = collection(firebase_db, "ChatRooms");
  const [hasUnreadMessages, setHasUnreadMessages] = useState<any>(false);
  const userId = getUserId();
  const { data: employerScheduleList } = useFetchEmployerScheldules();
  const { data: scheduleList } = useFetchCadidateScheldules();

  // const {
  //   isLoading: jobsLoading,
  //   data: jobsList,
  //   refetch,
  // } = useFetchApprovedJobs(userId);
  const checkUnreadCount = async () => {
    try {
      // Wrap the onSnapshot operation in a Promise
      const snapshotPromise = new Promise((resolve, reject) => {
        const unsubscribe = onSnapshot(
          query(
            chatRoomsRef,
            where("members", "array-contains-any", [userId]),
            where("unreadCount", "==", 1)
          ),
          (snapshot) => {
            const chats = snapshot.docs
              .map((doc) => {
                const data = doc.data();

                return data.lastMsgTo === userId.toString() && data?.unreadCount
                  ? true
                  : false;
              })
              .find((chat) => chat === true);

            resolve(chats);
          },
          (error) => {
            reject(error);
          }
        );
      });

      const chats: any = await snapshotPromise;
      setHasUnreadMessages(chats);
    } catch (error) {
      console.log("error", error);
    }
  };

  checkUnreadCount();

  return (
    <Box
      transition="3s ease"
      bg={"#1e1e2d"}
      borderRight="1px"
      w={{ base: "100%", md: "100%", lg: "260px" }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex
        h="20"
        alignItems="center"
        mx="8"
        justifyContent="space-between"
        margin={"auto"}
        // mr="20px"
        maxW="120px"
      >
        <Image src={logo}></Image>
        <CloseButton
          display={{ base: "flex", lg: "none" }}
          onClick={isMobile ? () => onClose() : () => console.log("")}
          color="white"
        />
      </Flex>
      {LinkItemProps.map((link: any) => {
        if (link.name === "Upcoming Interviews" && !scheduleList?.length) {
          return null;
        } else if (
          link.name === "Interviews" &&
          !employerScheduleList?.length
        ) {
          return null;
        }

        return (
          <NavItem
            key={link.name}
            icon={link.icon}
            link={link?.link}
            onClick={onCloseDrawer}
            hasUnreadMessages={hasUnreadMessages}
          >
            {link.name}
          </NavItem>
        );
      })}
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  icon: IconType;
  link: string;
  children: ReactText;
  hasUnreadMessages: boolean;
}
const NavItem = ({
  icon,
  children,
  link,
  hasUnreadMessages,
  ...rest
}: NavItemProps) => {
  const { pathname } = useLocation();

  return (
    <Link to={link}>
      <Flex
        align="center"
        p="4"
        // mx="4"
        my="2"
        fontSize={"13px"}
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: "#1b1b28 !important",
          color: "white",
        }}
        bg={pathname.includes(`${link}`) ? "#1b1b28" : ""}
        color={pathname.includes(`${link}`) ? "white" : "gray.400"}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="14"
            color={pathname.includes(link) ? "#5D78FF" : "gray.400"}
            _groupHover={{
              color: "#5D78FF",
            }}
            boxSize="5"
            as={icon}
          />
        )}

        {children}
        {link === "/chat" && hasUnreadMessages && (
          <Box
            w="10px"
            h="10px"
            borderRadius="50%"
            bg="#5D78FF"
            boxShadow="0 0 6px rgba(0, 0, 255, 0.5)"
            ml="4"
          />
        )}
      </Flex>
    </Link>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  const userId = getUserId();
  const { user, profilepicture } = useUserStore();
  const { userName, setUserName } = useUserStore();
  const { data: generalInfo } = useGeneralInfoFetch(userId);
  const navigate: any = useNavigate();

  useEffect(() => {
    {
      generalInfo?.fullName && setUserName(generalInfo?.fullName);
    }
  }, []);

  return (
    <Flex
      ml={{ base: 0, md: 0, lg: 60 }}
      px={{ base: 4, md: 4 }}
      height="16"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent={{ base: "space-between", lg: "flex-end" }}
      zIndex={20}
      {...rest}
    >
      <IconButton
        display={{ base: "flex", lg: "none" }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text
        display={{ base: "flex", lg: "none" }}
        fontSize="2xl"
        fontWeight="bold"
      >
        <Image src={logoBlack} maxW={{ base: "100px", md: "160px" }} />
      </Text>

      <HStack spacing={{ base: "0", lg: "6" }}>
        <Flex alignItems={"end"} marginStart="0 !important">
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: "none" }}
              borderRadius="md"
              _hover={{
                bg: "#efeef4",
              }}
              px="4"
            >
              <HStack>
                <VStack
                  display={{ base: "none", md: "flex" }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  {user?.fullName || userName ? (
                    <Text fontSize="sm">
                      {`Hi, ${
                        user?.fullName
                          ? user?.fullName.slice(0, 20)
                          : userName.slice(0, 20)
                      }`}
                    </Text>
                  ) : (
                    <Text fontSize="sm">Hi</Text>
                  )}
                </VStack>
                <Avatar
                  borderRadius="full"
                  size="sm"
                  src={user?.profilePicture}
                  name={user?.fullName}
                  bg="#e5f6ff"
                />
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue("white", "gray.900")}
              borderColor={useColorModeValue("gray.200", "gray.700")}
            >
              <MenuItem
                onClick={() => {
                  navigate("/profile");
                }}
              >
                Profile
              </MenuItem>
              <MenuDivider />
              <MenuItem
                onClick={() => {
                  handleLogoutLocalStorage();
                  navigate("/signup");
                }}
              >
                Sign out
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};
