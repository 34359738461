import {
  Flex,
  HStack,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { BiArchiveIn } from "react-icons/bi";

import AppliedJobs from "../../components/candidate/appliedJobs";
import ArchivedCandidatePage from "./archived-candidate";

import "./candidate.css";

const JobsCandidatePage = () => {
  return (
    <>
      <Tabs variant="enclosed" w="full">
        <Flex w="full" justify="end" p="2">
          <TabList>
            <Tab>Applied Jobs</Tab>
            <Tab>
              <HStack>
                <Icon as={BiArchiveIn} fontSize="18px" />
                <Text>Archived Jobs</Text>
              </HStack>
            </Tab>
          </TabList>
        </Flex>
        <TabPanels>
          <TabPanel>
            <AppliedJobs />
          </TabPanel>
          <TabPanel>
            <ArchivedCandidatePage />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};

export default JobsCandidatePage;
