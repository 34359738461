import { useState } from "react";
import {
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  Link,
  Avatar,
  FormControl,
  FormHelperText,
  InputRightElement,
  FormErrorMessage,
} from "@chakra-ui/react";
import { FaUserAlt, FaLock } from "react-icons/fa";
import useLogin from "./useLogin";

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const handleShowClick = () => setShowPassword(!showPassword);
  const { register, handleSubmit, errors, onSubmit, navigate, loginLoading } =
    useLogin();

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="gray.50"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Avatar bg="#5D78FF" size="lg" mb="2" />
        <Heading fontSize={"4xl"} textAlign={"center"}>
          Login
        </Heading>
        <Box minW={{ base: "90%", md: "468px" }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack
              spacing={4}
              p={8}
              backgroundColor="whiteAlpha.900"
              boxShadow="md"
              rounded={"md"}
            >
              <FormControl isInvalid={Boolean(errors?.email)}>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<CFaUserAlt color="gray.300" />}
                  />
                  <Input
                    type="text"
                    placeholder="Email address"
                    {...register("email")}
                  />
                </InputGroup>
                <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
              </FormControl>
              <FormControl id="password" isInvalid={Boolean(errors?.password)}>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    children={<CFaLock color="gray.300" />}
                  />
                  <Input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    {...register("password")}
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                      {showPassword ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
                <FormHelperText textAlign="right">
                  <Link
                    color="#5D78FF"
                    onClick={() => navigate("/forgot-password")}
                  >
                    Forgot password?
                  </Link>
                </FormHelperText>
              </FormControl>
              <Button
                // borderRadius={0}
                variant="solid"
                colorScheme="buttonPrimary"
                width="full"
                type="submit"
                isLoading={loginLoading}
                isDisabled={loginLoading}
              >
                Login
              </Button>
            </Stack>
          </form>
        </Box>
      </Stack>
      <Box>
        New to us?{" "}
        <Link color="#5D78FF" onClick={() => navigate("/signup")}>
          Sign Up
        </Link>
      </Box>
    </Flex>
  );
};

export default Login;
