import {
  Badge,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  Heading,
  HStack,
  Icon,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";

import { useNavigate } from "react-router-dom";
import moment from "moment";
import { MdCancel } from "react-icons/md";
import { useFetch } from "../../../utils/useFetch";
import { FiEdit } from "react-icons/fi";
import { IoIosPeople } from "react-icons/io";

const JobCard = ({ data, refetchDataArray }: any) => {
  const toast = useToast();
  const navigate = useNavigate();
  const handleJobDelete = (id: any) => {
    useFetch
      .delete(`/jobs/delete-job?jobId=${id}`)
      .then(async (res) => {
        if (res.status === 200) {
          toast({
            title: ` ${
              data?.deleted
                ? "Job Re-Opened Successfully"
                : "Job Closed Successfully"
            }`,
            status: "success",
            variant: "top-accent",
            isClosable: true,
            duration: 3000,
          });
        }
        for (const refetchFunction of refetchDataArray) {
          await refetchFunction();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <Card maxW="100%" borderRadius={"md"} w="full" overflow="hidden">
        <CardHeader
          pb="0px"
          cursor="pointer"
          onClick={() => navigate("/jobs/1", { state: data })}
        >
          {data?.postedLessThan14DaysAgo && (
            <Badge
              borderRadius={"md"}
              ml="1"
              fontSize="0.8em"
              colorScheme="green"
              px="2"
              py="1"
              color={"green.500"}
              textTransform={"capitalize"}
            >
              NEW
            </Badge>
          )}
        </CardHeader>
        <CardBody
          pb="0px"
          cursor="pointer"
          onClick={() => navigate("/jobs/1", { state: data })}
        >
          <Flex overflow="hidden">
            <Flex flex="1" gap="2" alignItems="start" direction="column">
              <Heading
                size="lg"
                color="#48465b"
                fontWeight={"500"}
                fontSize={{
                  base: "16.8px",
                  md: "16.8px",
                  lg: "18.2px",
                  xl: "18.2px",
                }}
                _hover={{ color: "#5867DD", cursor: "pointer" }}
              >
                {data?.name?.length > 30
                  ? `${data?.name?.slice(0, 31)}...`
                  : data?.name}
              </Heading>
              {/* <Text
                  fontSize={"13px"}
                  color={"#646c9a"}
                  fontWeight="500"
                  mt="0px"
                 
                >
                 
                  {truncatedDescription}
                </Text> */}
              <Text
                fontSize={"13px"}
                color={"#646c9a"}
                fontWeight="500"
                mt="0px"
              >
                {data?.locationsList?.length
                  ? `${data?.locationsList[0]}`
                  : "Remote"}
                {data?.reqNumber && ` | Req#  ${data?.reqNumber}`}
              </Text>
              <Stack
                direction="row"
                spacing={4}
                flexWrap="wrap"
                justify="space-between"
                w="full"
              >
                <Text fontSize={"13px"} color={"#646c9a"}>
                  Posting Date
                  <Badge
                    borderRadius={"md"}
                    ml="1"
                    colorScheme="buttonPrimary"
                    p="1"
                    color={"#5867DD"}
                    px="2"
                    bg={"#5d78ff1a"}
                    textTransform={"capitalize"}
                  >
                    {moment(data?.createdAt).format("MMMM DD, YYYY")}
                  </Badge>
                </Text>
              </Stack>
              <HStack flexWrap={"wrap"}>
                {data?.jobFunctions.map((item: string) => (
                  <Badge
                    bg={"#ffb822"}
                    fontWeight="100"
                    px="2"
                    py="1"
                    borderRadius={"xl"}
                    fontSize="12px"
                    mt="2 !important"
                    mr="2 !important"
                    ml="0 !important"
                    key={item}
                    color={"#48465b"}
                    textTransform={"capitalize"}
                  >
                    {item}
                  </Badge>
                ))}
              </HStack>
            </Flex>
            {/* {data?.jobStatus ? (
              <Menu>
                <MenuButton
                  as={IconButton}
                  variant="ghost"
                  colorScheme="gray"
                  aria-label="See menu"
                  mr="1"
                  my="1"
                  pos={"absolute"}
                  top={"0"}
                  right={"0"}
                  onClick={(e) => e.stopPropagation()}
                  icon={<BsThreeDots size={"20px"} color="#2b6cb0" />}
                />
                <MenuList>
                  {!data?.deleted && (
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate("/jobs/post", {
                          state: { isEdit: true, ...data },
                        });
                      }}
                    >
                      Edit
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate("/jobs/view-applicant", {
                        state: { data: data },
                      });
                    }}
                  >
                    View Applicants
                  </MenuItem>
                  <MenuItem onClick={() => handleJobDelete(data.id)}>
                    {data.deleted ? "Re-open" : "Close Job"}
                  </MenuItem>
                </MenuList>
              </Menu>
            ) : ( */}
            <Badge
              borderRadius="md"
              ml="1"
              colorScheme="buttonPrimary"
              p="1"
              color="#50cd89"
              px="2"
              bg="#d5ffe9"
              textTransform="capitalize"
              pos="absolute"
              top="15px"
              right="15px"
            >
              {!data?.jobStatus
                ? "Under Approval"
                : data?.deleted
                ? "Closed"
                : "Active"}
              {/* {data?.jobStatus && data?.deleted ? "Closed" : "Active"} */}
            </Badge>
          </Flex>
        </CardBody>
        {/* <Divider color={"gray.300"} /> */}
        <CardFooter justify="space-between" flexWrap="wrap" pt="10px">
          {!data?.deleted && (
            <HStack w="full" justify="start">
              {/* <AvatarGroup size="sm" max={2}>
              {new Array(data?.totalApplicants || 0)
                .fill(null)
                .map((item: any) => (
                  <Avatar key={item} src="" />
                ))}
            </AvatarGroup> */}
              <Icon as={IoIosPeople} fontSize="24px" />

              <Flex
                cursor="pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate("/jobs/view-applicant", {
                    state: { data: data },
                  });
                }}
                _hover={{ textDecor: "underline" }}
              >
                <Text fontStyle="italic">
                  Job{" "}
                  {data?.totalApplicants === 1 ? "Applicant: " : "Applicants: "}
                  {data?.totalApplicants || 0}
                </Text>
              </Flex>
            </HStack>
          )}
          <HStack w="full" justify="end" gap="3">
            {/* {!data?.deleted && ( */}
            <Flex
              align="center"
              gap="1"
              cursor="pointer"
              onClick={(e) => {
                e.stopPropagation();
                navigate("/jobs/post", {
                  state: { isEdit: true, ...data },
                });
              }}
            >
              <FiEdit />
              <Text color="blue.500">Edit</Text>
            </Flex>
            {/* )} */}
            <Flex align="center" gap="1" cursor="pointer">
              <MdCancel color="#f1416c" />
              <Text color="blue.500" onClick={() => handleJobDelete(data?.id)}>
                {data?.deleted ? "Re-open" : "Close Job"}
              </Text>
            </Flex>
          </HStack>
        </CardFooter>
      </Card>
    </>
  );
};

export default JobCard;
