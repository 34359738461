import { useEffect, useRef, useState } from "react";
import {
  Card,
  CardBody,
  Heading,
  Text,
  Box,
  HStack,
  Flex,
  Input,
  Button,
  Progress,
  FormControl,
  FormLabel,
  Tag,
  TagLabel,
  TagCloseButton,
  VStack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import RadioGroup from "../../../common/radioGroup";
import { getUserId } from "../../../../utils/helper";
import { useFetch } from "../../../../utils/useFetch";

const PersonalizeJobSearch = () => {
  const seniorityLevelLookup: any = {
    "Entry-level": 0,
    "Mid-level, hands-on": 1,
    "Senior-level, hands-on": 2,
    "Manager, hands-on": 3,
    "Manager, with direct reports": 4,
  };
  const seniorityLevelLookupReverse: any = {
    0: "Entry-level",
    1: "Mid-level, hands-on",
    2: "Senior-level, hands-on",
    3: "Manager, hands-on",
    4: "Manager, with direct reports",
  };
  const [seniorityLevel, setSeniorityLevel] = useState<any>("");

  const [error, setError] = useState({
    jobFunctionsError: "",
    prefferedIndustriesError: "",
    prefferedDomainError: "",
    seniorityLevelError: "",
  });

  const [continueLoading, setContinueLoading] = useState(false);
  const inputref = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const userId = getUserId();
  const [functions, setFunctions] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [searchJobFunctionResult, setSearchJobFunctionResult] = useState<any>(
    []
  );
  const [showJobFunctionResult, setShowJobFunctionResult] = useState(false);
  const [jobFuncList, setJobFuncList] = useState<string[]>([]);
  const [showJobIndustriesResult, setShowJobIndustriesResult] = useState(false);
  const [jobIndustryResult, setJobIndustryResult] = useState<any>([]);
  const [industryName, setIndustryName] = useState("");
  const [isIndustryFocused, setIsIndustryFocused] = useState(false);
  const [industriesList, setIndustriesList] = useState<string[]>([]);
  const [isDomainFocused, setIsDomainFocused] = useState(false);
  const [domainName, setDomainName] = useState("");
  const [domainsList, setDomainsList] = useState<string[]>([]);
  const [showDomainsResult, setShowDomainsResult] = useState(false);
  const [domainsResult, setDomainsResult] = useState<any>([]);

  const handleFocusBlur = (value: boolean) => {
    setIsFocused(value);
  };

  const handleDomainFocusBlur = (value: boolean) => {
    setIsDomainFocused(value);
  };
  const handleIndustryFocusBlur = (value: boolean) => {
    setIsIndustryFocused(value);
  };

  const handleAddJobFunctions = (functionsProps: string) => {
    if (functionsProps) {
      setShowJobFunctionResult(false);

      if (jobFuncList.includes(functionsProps)) {
        setError((prevError) => ({
          ...prevError,
          jobFunctionsError: "You have already selected this job function",
        }));
      } else if (jobFuncList?.length >= 3) {
        setError((prevError) => ({
          ...prevError,
          jobFunctionsError: "You can Add up to 3 options",
        }));
      } else {
        setJobFuncList((prevJobFuncList) => [
          ...prevJobFuncList,
          functionsProps,
        ]);
        setFunctions("");
        setError((prevError) => ({ ...prevError, jobFunctionsError: "" }));
      }
    } else {
      setError((prevError) => ({
        ...prevError,
        jobFunctionsError: "Please fill the required field",
      }));
    }
  };

  useEffect(() => {
    console.log("Error of Job Functions:", error);
  }, [error]);

  const handleAddIndustry = (industryNameProps: string) => {
    if (industryNameProps) {
      setShowJobIndustriesResult(false);
      if (industriesList.includes(industryNameProps)) {
        setError((prevError) => ({
          ...prevError,
          prefferedIndustriesError: "You have already selected this industry",
        }));
      } else if (industriesList?.length >= 3) {
        setError((prevError) => ({
          ...prevError,
          prefferedIndustriesError: "You can Add up to 3 options",
        }));
      } else {
        setIndustriesList((prevIndustriesList) => [
          ...prevIndustriesList,
          industryNameProps,
        ]);
        setIndustryName("");
        setError((prevError) => ({
          ...prevError,
          prefferedIndustriesError: "",
        }));
      }
    }
  };

  const handleAddDomain = (domainNameProps: string) => {
    if (domainNameProps) {
      setShowDomainsResult(false);
      if (domainsList.includes(domainNameProps)) {
        setError((prevError) => ({
          ...prevError,
          prefferedDomainError: "You have already selected this domain",
        }));
      } else if (domainsList?.length >= 3) {
        setError((prevError) => ({
          ...prevError,
          prefferedDomainError: "You can Add up to 3 options",
        }));
      } else {
        setDomainsList((prevDomainsList) => [
          ...prevDomainsList,
          domainNameProps,
        ]);
        setDomainName("");
        setError((prevError) => ({ ...prevError, prefferedDomainError: "" }));
      }
    }
  };

  const handleContinueClick = async () => {
    if (jobFuncList?.length === 0 && seniorityLevel === "") {
      setContinueLoading(false);

      setError((prevError) => ({
        ...prevError,
        jobFunctionsError: "Please Add Job Functions",
        seniorityLevelError: "Please Select Seniority Level",
      }));
    } else if (jobFuncList?.length === 0) {
      setContinueLoading(false);

      setError((prevError) => ({
        ...prevError,
        jobFunctionsError: "Please Add Job Functions",
        seniorityLevelError: "",
      }));
    } else if (seniorityLevel === "") {
      setContinueLoading(false);

      setError((prevError) => ({
        ...prevError,
        jobFunctionsError: "",
        seniorityLevelError: "Please Select Seniority Level",
      }));
    } else {
      setContinueLoading(true);
      const postApiUrl = "/users/talent/search-preference";
      const postPayload = {
        userId: userId,
        interestedJobFunctions: jobFuncList,
        seniorityLevel: seniorityLevel,
        interestedIndustry: industriesList,
        interestedDomain: domainsList,
      };

      try {
        await useFetch
          .post(postApiUrl, postPayload)
          .then((res) => {
            const data = res.data;
            localStorage.setItem(
              "profilepercentage",
              JSON.stringify(data.profileCompletePercentage)
            );
            navigate("/search");
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.error("Error sending data to the server (POST)", error);
      }
    }
  };

  const handleRemoveJobFunction = (indexToRemove: number) => {
    setJobFuncList((prevJobfuncList: any) =>
      prevJobfuncList.filter((_: any, index: number) => index !== indexToRemove)
    );
  };

  const handleRemoveIndustry = (indexToRemove: number) => {
    setIndustriesList((prevIndustriesList: any) =>
      prevIndustriesList.filter(
        (_: any, index: number) => index !== indexToRemove
      )
    );
  };

  // const handleDomains = (search: any) => {
  //   setDomainName(search);
  //   setShowDomainsResult(false);
  // };
  const handleRemoveDomain = (indexToRemove: number) => {
    setDomainsList((prevDomainsList: any) =>
      prevDomainsList.filter((_: any, index: number) => index !== indexToRemove)
    );
  };

  // const { isLoading: otherInfoLoading, data: otherInfo }: any =
  //   useFetchAllOtherInfo(userId);
  const {
    formState: { isSubmitted },
  } = useForm();
  useEffect(() => {
    if (isFocused) {
      if (functions?.length > 0) {
        try {
          useFetch
            .get(`/users/domain/all-job-function-list?query=${functions}`)
            .then((response) => {
              setSearchJobFunctionResult(response.data);
              setShowJobFunctionResult(true);
            })
            .catch((error) => {
              console.log("Error", error);
            });
        } catch (error) {
          console.log("ERROR", error);
        }
      }
    }
  }, [functions]);
  useEffect(() => {
    if (isIndustryFocused) {
      if (industryName?.length > 0) {
        try {
          useFetch
            .get(`/users/domain/all-job-industry-list?query=${industryName}`)
            .then((response) => {
              setJobIndustryResult(response.data);
              setShowJobIndustriesResult(true);
            })
            .catch((error) => {
              console.log("Error", error);
            });
        } catch (error) {
          console.log("ERROR", error);
        }
      } else {
        setShowJobIndustriesResult(false);
      }
    }
  }, [industryName]);
  useEffect(() => {
    if (isDomainFocused) {
      if (domainName?.length > 0) {
        try {
          useFetch
            .get(`/users/domain/all-job-domain-list?query=${domainName}`)
            .then((response) => {
              setDomainsResult(response.data);
              setShowDomainsResult(true);
            })
            .catch((error) => {
              console.log("Error", error);
            });
        } catch (error) {
          console.log("ERROR", error);
        }
      } else {
        setShowDomainsResult(false);
      }
    }
  }, [domainName]);

  const searchFieldRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        searchFieldRef.current &&
        !searchFieldRef.current.contains(event.target as Node)
      ) {
        handleFocusBlur(false);
        setShowJobFunctionResult(false);
        setShowDomainsResult(false);
        handleIndustryFocusBlur(false);
        setShowJobIndustriesResult(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <Progress
        colorScheme="progressBar"
        size="xs"
        value={80}
        w="full"
        borderRadius={"lg"}
        mb="6 !important"
        mt="2 !important"
      />
      <form
        style={{ width: "100%" }}
        onSubmit={(e) => {
          e.preventDefault();
          handleContinueClick();
        }}
        onKeyDown={(e: any) => {
          if (e.key === "Enter") {
            e.preventDefault();
            handleContinueClick();
          }
        }}
      >
        <Card w="full">
          <Box my="4">
            <CardBody p="8">
              <Heading size="lg" fontWeight={"500"} pb={"2"}>
                Personalize Your Job Search
              </Heading>
              <Text pb={"10px"}>What type of roles are you searching for?</Text>

              <FormControl
                isInvalid={Boolean(error.jobFunctionsError)}
                position="relative"
              >
                <FormLabel fontWeight={"bold"} fontSize="md">
                  Job Functions
                  <Box as="span" ml="2" color="red" fontSize="md" mt="1">
                    *
                  </Box>
                </FormLabel>

                <HStack gap="4">
                  <Input
                    type="text"
                    placeholder="Search Job Functions"
                    maxLength={50}
                    maxW={{ base: "100%", md: "70%" }}
                    ref={inputref}
                    onFocus={() => handleFocusBlur(true)}
                    onBlur={() => handleFocusBlur(false)}
                    borderColor="grey"
                    onChange={(e) => setFunctions(e.target.value.trimLeft())}
                    value={functions}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.stopPropagation();
                        e.preventDefault();
                        handleAddJobFunctions(functions);
                      }
                    }}
                  />

                  <Button
                    ml="2"
                    colorScheme="buttonPrimary"
                    onClick={() => handleAddJobFunctions(functions)}
                  >
                    Add
                  </Button>
                </HStack>

                {error.jobFunctionsError && (
                  <Text fontSize="sm" color="red.500">
                    {error.jobFunctionsError}
                  </Text>
                )}

                {showJobFunctionResult &&
                  searchJobFunctionResult?.length >= 1 && (
                    <VStack
                      ref={searchFieldRef}
                      maxH="120px"
                      overflowY="scroll"
                      position="absolute"
                      top="74px"
                      zIndex="50"
                      bg="white"
                      border="1px solid #F1F0F9"
                      borderRadius="10px"
                      w="70%"
                      boxShadow="0px 8px 32px 0px rgba(78, 48, 123, 0.04)"
                      padding="5px 0px"
                    >
                      {searchJobFunctionResult.map(
                        (data: any, index: number) => (
                          <Flex
                            w="full"
                            p="5px"
                            borderBottom="1px solid #eae8e8"
                            onClick={() => handleAddJobFunctions(data.name)}
                            _hover={{ bg: "#F1F0F9", color: "black" }}
                            key={index}
                            cursor="pointer"
                          >
                            <Text>{data.name}</Text>
                          </Flex>
                        )
                      )}
                    </VStack>
                  )}
              </FormControl>

              <Flex my="2" gap={"10px"} wrap="wrap">
                {jobFuncList.map((functions, index) => (
                  <Tag key={index}>
                    <TagLabel>{functions}</TagLabel>
                    <TagCloseButton
                      onClick={() => handleRemoveJobFunction(index)}
                    />
                  </Tag>
                ))}
              </Flex>

              <FormControl isInvalid={Boolean(error.seniorityLevelError)}>
                <Text fontWeight={"bold"} size="md">
                  Seniority level
                  <Box as="span" ml="2" color="red" fontSize="md" mt="1">
                    *
                  </Box>
                </Text>
                <RadioGroup
                  options={[
                    "Entry-level",
                    "Mid-level, hands-on",
                    "Senior-level, hands-on",
                    "Manager, hands-on",
                    "Manager, with direct reports",
                  ]}
                  name="seniorityLevel"
                  onChange={(value: string) => {
                    const seniorityLevel = seniorityLevelLookup[value];
                    setSeniorityLevel(seniorityLevel);
                    if (seniorityLevel) {
                      setError((prevError) => ({
                        ...prevError,
                        seniorityLevelError: "",
                      }));
                    }
                  }}
                  maxW="100%"
                />
                {error?.seniorityLevelError && (
                  <Text fontSize="sm" color="red.500">
                    {error.seniorityLevelError}
                  </Text>
                )}
              </FormControl>

              <FormControl
                isInvalid={Boolean(error.prefferedIndustriesError)}
                position="relative"
                pt="2"
              >
                <FormLabel fontWeight={"bold"} fontSize="md">
                  Preferred Industries: (Optional)
                </FormLabel>
                <HStack gap="4">
                  <Input
                    maxLength={50}
                    type="text"
                    placeholder="Enter Industry Name"
                    maxW={{ base: "100%", md: "70%" }}
                    borderColor="grey"
                    onChange={(e) => setIndustryName(e.target.value.trimLeft())}
                    value={industryName}
                    ref={inputref}
                    onFocus={() => handleIndustryFocusBlur(true)}
                    onBlur={() => handleIndustryFocusBlur(false)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.stopPropagation();
                        e.preventDefault();
                        handleAddIndustry(industryName);
                      }
                    }}
                  />

                  <Button
                    ml="2"
                    colorScheme="buttonPrimary"
                    onClick={() => handleAddIndustry(industryName)}
                  >
                    Add
                  </Button>
                </HStack>
                {error.prefferedIndustriesError && (
                  <Text fontSize="sm" color="red.500">
                    {error.prefferedIndustriesError}
                  </Text>
                )}
                {showJobIndustriesResult && (
                  <VStack
                    ref={searchFieldRef}
                    maxH="150px"
                    overflowY="auto"
                    position="absolute"
                    top="80px"
                    zIndex="50"
                    bg="white"
                    border="1px solid #F1F0F9"
                    borderRadius="10px"
                    w="70%"
                    boxShadow="0px 8px 32px 0px rgba(78, 48, 123, 0.04)"
                    padding="5px 0px"
                  >
                    {jobIndustryResult.map((data: any, index: number) => (
                      <Flex
                        w="full"
                        p="5px"
                        borderBottom="1px solid #eae8e8"
                        onClick={() => handleAddIndustry(data.name)}
                        _hover={{ bg: "#F1F0F9", color: "black" }}
                        key={index}
                        cursor="pointer"
                      >
                        <Text ms="4px">{data.name}</Text>
                      </Flex>
                    ))}
                  </VStack>
                )}
              </FormControl>

              <Flex py="10px" gap={"10px"} wrap="wrap">
                {industriesList.map((Industry, index) => (
                  <Tag key={index}>
                    <TagLabel>{Industry}</TagLabel>
                    <TagCloseButton
                      onClick={() => handleRemoveIndustry(index)}
                    />
                  </Tag>
                ))}
              </Flex>
              <FormControl
                isInvalid={Boolean(error.prefferedDomainError)}
                position="relative"
              >
                <FormLabel fontWeight={"bold"} fontSize="md">
                  Preferred Domains/Technologies: (Optional){" "}
                </FormLabel>
                <HStack gap="4">
                  <Input
                    type="text"
                    maxLength={50}
                    placeholder="Enter Domain Name"
                    maxW={{ base: "100%", md: "70%" }}
                    borderColor="grey"
                    onChange={(e) => setDomainName(e.target.value.trimLeft())}
                    value={domainName}
                    ref={inputref}
                    onFocus={() => handleDomainFocusBlur(true)}
                    onBlur={() => handleDomainFocusBlur(false)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.stopPropagation();
                        e.preventDefault();
                        handleAddDomain(domainName);
                      }
                    }}
                  />

                  <Button
                    ml="2"
                    colorScheme="buttonPrimary"
                    onClick={() => handleAddDomain(domainName)}
                  >
                    Add
                  </Button>
                </HStack>
                {error.prefferedDomainError && (
                  <Text fontSize="sm" color="red.500">
                    {error.prefferedDomainError}
                  </Text>
                )}
                {showDomainsResult && (
                  <VStack
                    ref={searchFieldRef}
                    maxH="120px"
                    overflowY="scroll"
                    position="absolute"
                    top="72px"
                    zIndex="50"
                    bg="white"
                    border="1px solid #F1F0F9"
                    borderRadius="10px"
                    w="70%"
                    boxShadow="0px 8px 32px 0px rgba(78, 48, 123, 0.04)"
                    padding="5px 0px"
                  >
                    {domainsResult.map((data: any, index: number) => (
                      <Flex
                        w="full"
                        p="5px"
                        borderBottom="1px solid #eae8e8"
                        onClick={() => handleAddDomain(data.name)}
                        _hover={{ bg: "#F1F0F9", color: "black" }}
                        key={index}
                        cursor="pointer"
                      >
                        <Text ms="4px">{data.name}</Text>
                      </Flex>
                    ))}
                  </VStack>
                )}
              </FormControl>

              <Flex pt="10px" gap={"10px"} wrap="wrap">
                {domainsList.map((Domain, index) => (
                  <Tag key={index}>
                    <TagLabel>{Domain}</TagLabel>
                    <TagCloseButton onClick={() => handleRemoveDomain(index)} />
                  </Tag>
                ))}
              </Flex>

              <Button
                colorScheme="buttonPrimary"
                mt="4"
                type="submit"
                isLoading={continueLoading}
                isDisabled={continueLoading}
              >
                Submit
              </Button>
            </CardBody>
          </Box>
        </Card>
      </form>
    </>
  );
};
export default PersonalizeJobSearch;
