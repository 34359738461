import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import { ChakraProvider } from "@chakra-ui/react";
import { ColorModeScript, extendTheme } from "@chakra-ui/react";

import { App } from "./App";
import { AxiosInterceptor } from "./utils/useFetch";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";

import "./index.css";
import "./components/common/date-picker/datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import "@fontsource/poppins";

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(container);
const breakpoints = {
  sm: "30em", // 480px
  md: "48em", // 768px
  lg: "64em", // 992px
  xl: "80em", // 1280px
  "2xl": "87.375em", //
};
const theme = extendTheme({
  breakpoints,
  fonts: {
    body: "Poppins, sans-serif",
    heading: "Poppins, sans-serif",
    text: `Poppins`,
  },
  colors: {
    customPrimary: {
      500: "#5867DD",
      600: "#2b6cb7",
    },
    buttonPrimary: {
      500: "#5D78FF",
      600: "#5867DD",
      700: "#5867DD",
    },
    progressBar: {
      500: "#0ABB87",
    },
  },
  components: {
    Button: {
      baseStyle: {
        borderRadius: 4,
      },
    },
  },
  styles: {
    global: {
      body: {
        color: "#48465b",
      },
    },
  },
});

root.render(
  <React.StrictMode>
    <ColorModeScript />
    <QueryClientProvider client={new QueryClient()}>
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <AxiosInterceptor>
            <Routes>
              <Route path="/*" element={<App />} />
            </Routes>
          </AxiosInterceptor>
        </BrowserRouter>
      </ChakraProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
