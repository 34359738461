import {
  Avatar,
  Button,
  chakra,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import { useState } from "react";
import { FaLock } from "react-icons/fa";
import useResetPass from "./useResetPass";

export default function ResetPassword(): JSX.Element {
  const { register, handleSubmit, errors, onSubmit } = useResetPass();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const handleShowClick = () => setShowPassword(!showPassword);
  const handleShowClickConfirm = () =>
    setShowPasswordConfirm(!showPasswordConfirm);
  const CFaLock = chakra(FaLock);

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      backgroundColor="gray.50"
      flexDir={"column"}
    >
      <Stack align={"center"}>
        <Avatar bg="#5D78FF" size="lg" mb="2" />
        <Heading fontSize={"2xl"} textAlign={"center"} fontWeight={"500"}>
          Enter new password
        </Heading>
      </Stack>

      <Stack
        spacing={4}
        w={"full"}
        maxW={"md"}
        bg={useColorModeValue("white", "gray.700")}
        rounded={"xl"}
        boxShadow={"lg"}
        p={6}
        my={12}
      >
        <FormControl
          id="password"
          isInvalid={Boolean(errors?.password)}
          isRequired
        >
          <FormLabel>Password</FormLabel>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              color="gray.300"
              children={<CFaLock color="gray.300" />}
            />
            <Input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              {...register("password")}
            />
            <InputRightElement width="4.5rem">
              <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                {showPassword ? "Hide" : "Show"}
              </Button>
            </InputRightElement>
          </InputGroup>
          <FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
        </FormControl>
        <FormControl
          id="confirmPassword"
          isInvalid={Boolean(errors?.confirmPassword)}
        >
          <FormLabel>Confirm Password</FormLabel>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              color="gray.300"
              children={<CFaLock color="gray.300" />}
            />
            <Input
              type={showPasswordConfirm ? "text" : "password"}
              placeholder="Password"
              {...register("confirmPassword")}
            />
            <InputRightElement width="4.5rem">
              <Button h="1.75rem" size="sm" onClick={handleShowClickConfirm}>
                {showPasswordConfirm ? "Hide" : "Show"}
              </Button>
            </InputRightElement>
          </InputGroup>
          <FormErrorMessage>
            {errors?.confirmPassword?.message}
          </FormErrorMessage>
        </FormControl>
        <Stack spacing={6}>
          <Button
            type="submit"
            variant="solid"
            colorScheme="buttonPrimary"
            width="full"
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </Button>
        </Stack>
      </Stack>
    </Flex>
  );
}
