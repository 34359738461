import {
  Box,
  Button,
  Flex,
  HStack,
  Progress,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useWizard } from "react-use-wizard";
import CheckBoxButton from "../../common/checkboxButton";
import CustomDatePicker from "../../common/date-picker/datepicker";
import StepsAnimation from "../steps-animation";
const optionstimes: any = [
  "12:00 AM",

  "01:00 AM",

  "02:00 AM",

  "03:00 AM",

  "04:00 AM",

  "05:00 AM",

  "06:00 AM",

  "07:00 AM",

  "08:00 AM",

  "09:00 AM",

  "10:00 AM",

  "11:00 AM",

  "12:00 PM",

  "01:00 PM",

  "02:00 PM",

  "03:00 PM",

  "04:00 PM",

  "05:00 PM",

  "06:00 PM",

  "07:00 PM",

  "08:00 PM",

  "09:00 PM",

  "10:00 PM",

  "11:00 PM",
];

const Step3 = ({
  selectedTimes,
  watch,
  handleOptionTimes,
  onChangeDate,
  onModalClose,
  isSubmitTimes,
  setIsSubmitTimes,
  onChangeDate2,
  handleOptionTimes2,
  selectedTimes2,
  toggleAnotherDay,
  isSubmitTimes2,
  setIsSubmitTimes2,
  anotherDay,
  onSubmit,
  scheduleLoading,
  error,
  setError,
}: any) => {
  const { handleStep, previousStep } = useWizard();

  // Attach an optional handler
  handleStep(() => {
    alert(" This is last step");
  });
  console.log("ERROR", error);

  return (
    <VStack mt="2" spacing={4}>
      <Progress
        colorScheme="progressBar"
        size="sm"
        value={68}
        w="full"
        borderRadius={"lg"}
        my="2"
      />
      <StepsAnimation>
        <Stack
          w="full"
          minH="350px"
          maxH={"350px"}
          overflow="auto"
          align={"flex-start"}
          flexDir={{ base: "column", md: "row" }}
        >
          <VStack w="full" align={"flex-start"}>
            <VStack w="full" align={"flex-start"}>
              <Flex align="start" gap="3px" pt="2px">
                <Text fontWeight={"bold"} fontSize="md" w="full" pl="1">
                  Pick a day
                </Text>
                <Text color="red.500" fontWeight="bold">
                  *
                </Text>
              </Flex>

              <Box
                maxW={anotherDay ? "96%" : { base: "100%", md: "70%" }}
                w="full"
              >
                <CustomDatePicker
                  label="Select date"
                  selected={watch("date")}
                  onChange={onChangeDate}
                  minDate={new Date()}
                />
              </Box>
            </VStack>
            <VStack w="full" align={"flex-start"}>
              <Flex align="start" gap="3px" pt="2px" mt="1">
                <Text fontWeight={"bold"} fontSize="md" w="full" pl="1">
                  Select Time Slots
                </Text>
                <Text color="red.500" fontWeight="bold">
                  *
                </Text>
              </Flex>
              <Box
                maxW={anotherDay ? "96%" : { base: "100%", md: "70%" }}
                w="full"
                maxH="180px"
                h="180px"
                overflow={"auto"}
                p="2"
              >
                <CheckBoxButton
                  handleOptionSelection={handleOptionTimes}
                  options={optionstimes}
                  selectedOptions={selectedTimes}
                  width="full"
                />
              </Box>
              {error?.time1 && (
                <Text color="red" pl="2">
                  {error?.time1}
                </Text>
              )}
              {selectedTimes.length === 0 && isSubmitTimes2 && (
                <Text color="red" pl="2">
                  {"Select atleast one time slot"}
                </Text>
              )}
            </VStack>
          </VStack>
          <VStack
            w="full"
            align={"flex-start"}
            display={anotherDay ? "flex" : "none"}
            mt={{ base: "2 !important", md: "0px !important" }}
          >
            <VStack w="full" align={"flex-start"}>
              <Flex align="start" gap="3px" pt="2px" mt="1">
                <Text fontWeight={"bold"} fontSize="md" w="full" pl="1">
                  Pick another day
                </Text>
                <Text color="red.500" fontWeight="bold">
                  *
                </Text>
              </Flex>

              <Box
                maxW={anotherDay ? "96%" : { base: "100%", md: "70%" }}
                w="full"
              >
                <CustomDatePicker
                  label="Select date"
                  selected={watch("date2")}
                  onChange={onChangeDate2}
                  minDate={new Date()}
                />
                {error?.day2 && (
                  <Text color="red" pl="2">
                    {error?.day2}
                  </Text>
                )}
              </Box>
            </VStack>
            <VStack w="full" align={"flex-start"}>
              <Flex align="start" gap="3px" pt="2px" mt="1">
                <Text fontWeight={"bold"} fontSize="md" w="full" pl="1" mt="2">
                  Select Time Slots
                </Text>
                <Text color="red.500" fontWeight="bold" pt="4px">
                  *
                </Text>
              </Flex>
              <Box
                maxW={anotherDay ? "96%" : { base: "100%", md: "70%" }}
                w="full"
                maxH="180px"
                h="180px"
                overflow={"auto"}
                p="2"
              >
                <CheckBoxButton
                  handleOptionSelection={handleOptionTimes2}
                  options={optionstimes}
                  selectedOptions={selectedTimes2}
                  width="full"
                />
              </Box>
              {error?.time2 && (
                <Text color="red" pl="2">
                  {error?.time2}
                </Text>
              )}
              {/* {selectedTimes2.length === 0 && isSubmitTimes && (
                <Text color="red" pl="2">
                  {"Select atleast one time slot"}
                </Text>
              )} */}
            </VStack>
          </VStack>
        </Stack>
      </StepsAnimation>
      <HStack
        spacing={"24px"}
        justify={"space-between"}
        w="full"
        mt="2 !important"
      >
        <HStack>
          <Button
            loadingText="Loading"
            color="#74788d"
            spinnerPlacement="end"
            size="md"
            fontWeight={"bold"}
            _hover={{ color: "white", bg: "#5867DD" }}
            // leftIcon={<IoIosArrowBack />}
            onClick={() => {
              previousStep();
            }}
          >
            Back
          </Button>
          <Button
            colorScheme="buttonPrimary"
            fontWeight={"bold"}
            _hover={{ color: "white", bg: "#5867DD" }}
            // leftIcon={<IoIosArrowBack />}
            onClick={toggleAnotherDay}
          >
            {anotherDay ? "Cancel" : "Add another day"}
          </Button>
        </HStack>
        <Button
          colorScheme="buttonPrimary"
          isLoading={scheduleLoading}
          isDisabled={scheduleLoading}
          onClick={() => {
            setIsSubmitTimes(true);
            setIsSubmitTimes2(true);
            if (selectedTimes.length > 0) {
              onSubmit();
            }
          }}
        >
          Submit
        </Button>
      </HStack>
    </VStack>
  );
};

export default Step3;
