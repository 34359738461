import { create } from "zustand";

interface IAuth {
  user: any;
  setEducation: (data: any) => void;
  setExperience: (data: any) => void;
  deleteEducation: (data: any) => void;
  deleteExperience: (data: any) => void;
  setOtherOptions: (data: any) => void;
  isEmployeer: boolean;
  toggleUserType: () => void;
  setUserType: (type: any) => void;
}
const initial_state = {
  user: { education: [], experience: [] },
  isEmployeer: false,
};

export const useAuthStore = create<IAuth>((set) => ({
  ...initial_state,
  setEducation: (data: any) => {
    set((state) => {
      return {
        ...state,
        user: {
          ...state.user,
          education: [...state.user.education, ...data],
        },
      };
    });
  },
  deleteEducation: (data: any) => {
    set((state) => {
      return {
        ...state,
        user: {
          ...state.user,
          education: [...data],
        },
      };
    });
  },
  setExperience: (data: any) => {
    set((state) => {
      return {
        ...state,
        user: {
          ...state.user,
          experience: [...state.user.experience, ...data],
        },
      };
    });
  },
  deleteExperience: (data: any) => {
    set((state) => {
      return {
        ...state,
        user: {
          ...state.user,
          experience: [...data],
        },
      };
    });
  },
  setOtherOptions: (data: any) => {
    set((state) => {
      return {
        ...state,
        user: { ...state.user, ...data },
      };
    });
  },
  toggleUserType: () => {
    set((state) => {
      return {
        ...state,
        isEmployeer: !state.isEmployeer,
      };
    });
  },
  setUserType: (type: any) => {
    set((state) => {
      return {
        ...state,
        isEmployeer: type
      };
    });
  },
}));
