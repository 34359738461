import { FC } from "react";
import ReactDatePicker from "react-datepicker";
import { Flex } from "@chakra-ui/react";
import { FaCalendarAlt } from "react-icons/fa";
interface CustomInputProps {
  value?: Date | null;
  onClick?: () => void;
  label: string;
}

const CustomDatePicker: FC<any> = ({ minDate, label, ...props }) => {
  const CustomInput: FC<CustomInputProps> = ({ value, onClick }) => (
    <Flex
      onClick={onClick}
      w="full"
      border="1px solid gray"
      justify="space-between"
      p="2"
      alignItems="center"
      borderRadius="5px"
      cursor="pointer"
    >
      <span>{value ? value.toString() : label}</span>
      <FaCalendarAlt color="gray" />
    </Flex>
  );

  return (
    <ReactDatePicker
      minDate={minDate}
      {...props}
      customInput={<CustomInput label={label} />}
    />
  );
};

export default CustomDatePicker;
