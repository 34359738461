import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { firebase_db } from "../../../../firebase";
import { getUserId } from "../../../../utils/helper";

const useInbox = (handleSelectedUser?: any, selectedUser?: any) => {
  const userId = getUserId();
  const [chatList, setChatList] = useState([]);
  const [chatListLoading, setChatListLoading] = useState(true);

  useEffect(() => {
    const firestore = firebase_db;
    const chatRoomsRef = collection(firestore, "ChatRooms");

    try {
      setChatListLoading(true);
      const unsubscribe = onSnapshot(
        query(
          chatRoomsRef,
          where("members", "array-contains-any", [userId.toString()]),
          orderBy("lastUpdate", "desc")
        ),
        (snapshot) => {
          const chats: any = snapshot.docs.map((doc) => {
            const data = doc.data();
            return {
              ...data,
            };
          });
          //   if (chats.length) {
          //     !selectedUser && handleSelectedUser(chats[0]);
          //   }
          setChatList(chats);
          setChatListLoading(false);
        }
      );

      // Clean up the listener when component unmounts
      return () => {
        unsubscribe();
      };
    } catch (error) {
      setChatListLoading(false);
      console.log("error", error);
    }
  }, []);

  return { chatList, setChatList, chatListLoading };
};

export default useInbox;
