import { useDisclosure, useLatestRef } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  useFetchCadidateScheldules,
  useFetchCadidateScheldulesById,
  useFetchEmployerScheldulesById,
  useReSchedule,
  useReScheduleRequest,
} from "../../../service/schedule.service";
import { useLocation, useNavigate } from "react-router-dom";
import { date } from "yup";

const useInterviewReschedule = () => {
  const navigate = useNavigate();
  const [error, setError] = useState({
    day1: "",
    day2: "",
    time1: "",
    time2: "",
  });
  const [reason, setReason] = useState<any>([]);
  const [valueReason, setValueReason] = useState("");
  const { state } = useLocation();
  const {
    mutate: scheduleMutation,
    isLoading: scheduleLoading,
    data: scheduleRequest,
  } = useReSchedule();
  const { isLoading: fetchLoading, data: scheduleData } =
    useFetchEmployerScheldulesById(state);

  const [timeSlotsError, setTimeSlotsError] = useState(false);
  const [timeSlots2Error, setTimeSlots2Error] = useState(false);
  type FormData = {
    date: Date | null;
    date2: Date | null;
  };
  const {
    watch,
    setValue,
    trigger,
    reset,
    formState: { isSubmitted },
  } = useForm<FormData>({
    defaultValues: {
      date: new Date(),
      date2: null,
    },
  });

  useEffect(() => {
    if (scheduleData) {
      setSelectedtimes(scheduleData?.selectedTimes);
      setSelectedtimes2(scheduleData?.selectedTimes2);
      setAnotherDay(scheduleData?.anotherDay);
      setValue("date", new Date(scheduleData?.date));
      trigger("date");
      setValue(
        "date2",
        scheduleData?.date2 ? new Date(scheduleData?.date2) : null
      );
      trigger("date2");
    }
  }, [scheduleData]);

  const [reschedule, setReschedule] = useState(false);
  const [selectedTimes, setSelectedtimes] = useState<any>([]);
  const [selectedTimes2, setSelectedtimes2] = useState<any>([]);
  const [isSubmitTimes, setIsSubmitTimes] = useState(false);
  const [anotherDay, setAnotherDay] = useState(false);
  const [isSubmitTimes2, setIsSubmitTimes2] = useState(false);
  const [copied, setCopied] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const onModalClose = () => {
    reset();
    setReschedule(false);
    setIsSubmitTimes(false);
    setIsSubmitTimes2(false);
    setSelectedtimes([]);
    setSelectedtimes2([]);
    onClose();
  };

  const onChangeDate = (date: any) => {
    setError((prevData) => ({
      ...prevData,

      day1: "",
    }));
    setValue("date", date);
    trigger("date");
  };

  const handleOptionTimes = (option: any) => {
    setError((prevData) => ({
      ...prevData,
      time1: "",
    }));

    if (selectedTimes.includes(option)) {
      setSelectedtimes((prevSelectedOptions: any) =>
        prevSelectedOptions.filter((o: any) => o !== option)
      );
    } else {
      if (selectedTimes.length >= 3) {
        setError((prevData) => ({
          ...prevData,
          time1: "You can only select up to 3 time slots",
        }));
      } else {
        setSelectedtimes((prevSelectedOptions: any) => [
          ...prevSelectedOptions,
          option,
        ]);
      }
    }
  };
  const toggleAnotherDay = () => {
    setAnotherDay(!anotherDay);
    setValue("date2", null);
    setSelectedtimes2([]);
    setError((prevData) => ({
      ...prevData,
      day2: "",
      time2: "",
    }));
  };
  const onChangeDate2 = (date: any) => {
    setError((prevData) => ({
      ...prevData,
      day2: "",
    }));
    setValue("date2", date);
    trigger("date2");
  };

  const handleOptionTimes2 = (option: any) => {
    setError((prevData) => ({
      ...prevData,
      time2: "",
    }));

    if (selectedTimes2.includes(option)) {
      setSelectedtimes2((prevSelectedOptions: any) =>
        prevSelectedOptions.filter((o: any) => o !== option)
      );
    } else {
      if (selectedTimes2?.length >= 3) {
        setError((prevData) => ({
          ...prevData,
          time2: "You can only select up to 3 time slots",
        }));
      } else {
        setSelectedtimes2((prevSelectedOptions: any) => [
          ...prevSelectedOptions,
          option,
        ]);
      }
    }
  };
  useEffect(() => {
    if (copied) {
      const timeoutId = setTimeout(() => {
        setCopied(false);
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [copied]);
  const onSubmit = () => {
    let errorCheck = false;
    if (selectedTimes.length === 0) {
      setTimeSlotsError(true);
      setError((prevData) => ({
        ...prevData,
        time1: "Select atleast one time slot",
      }));
      errorCheck = true;
    }
    if (anotherDay && selectedTimes2.length === 0) {
      setTimeSlots2Error(true);
      setError((prevData) => ({
        ...prevData,
        time2: "Select atleast one time slot",
      }));
      errorCheck = true;
    }
    if (anotherDay && watch("date2") === null) {
      setTimeSlots2Error(true);
      setError((prevData) => ({
        ...prevData,
        day2: "Please Select a Date",
      }));
      errorCheck = true;
    } else if (errorCheck === false) {
      setTimeSlotsError(false);
      setTimeSlots2Error(false);

      const data = {
        anotherDay,
        selectedTimes,
        selectedTimes2,
        date: watch("date"),
        date2: watch("date2"),
        interviewScheduleId: scheduleData?.interviewScheduleId,
      };

      scheduleMutation(data, {
        onSuccess: (res) => {
          onModalClose();
        },
      });
    }
  };

  return {
    isOpen,
    onOpen,
    onModalClose,
    onChangeDate,
    handleOptionTimes,
    selectedTimes,
    watch,
    isSubmitted,
    setReschedule,
    reschedule,
    isSubmitTimes,
    setIsSubmitTimes,
    setCopied,
    copied,
    scheduleData,
    fetchLoading,
    scheduleLoading,
    reason,
    setReason,
    valueReason,
    setValueReason,
    onSubmit,
    navigate,
    onChangeDate2,
    handleOptionTimes2,
    isSubmitTimes2,
    selectedTimes2,
    toggleAnotherDay,
    anotherDay,
    setIsSubmitTimes2,
    timeSlotsError,
    setSelectedtimes,
    timeSlots2Error,
    error,
  };
};

export default useInterviewReschedule;
