import { IconType } from "react-icons";
import {
  FiSettings,
  FiShoppingBag,
  FiSearch,
  FiUser,
  FiMessageSquare,
  FiHeart,
  FiCalendar,
} from "react-icons/fi";
import { FaChartBar } from "react-icons/fa";
interface LinkItemProps {
  name: string;
  icon: IconType;
  link: string;
}
export const LinkItemsEmployeer: Array<LinkItemProps> = [
  { name: "Jobs", icon: FiShoppingBag, link: "/jobs" },
  { name: "Search Talent", icon: FiSearch, link: "/search" },
  // { name: "Applied  Talent", icon: FiSearch, link: "/archived" },
  { name: "Job Applicants", icon: FiUser, link: "/liked" },
  // { name: "Applicants", icon: FiUser, link: "/applicants" },
  { name: "Pipeline Visualization", icon: FaChartBar, link: "/reports" },
  { name: "Messages", icon: FiMessageSquare, link: "/chat" },
  { name: "Interviews", icon: FiCalendar, link: "/interview" },
  { name: "Profile", icon: FiSettings, link: "/profile" },
];
export const LinkItemsCandidate: Array<LinkItemProps> = [
  { name: "Job Search", icon: FiSearch, link: "/search" },
  // { name: "Archived Jobs", icon: FiHeart, link: "/archived" },
  { name: "Interested Employers", icon: FiHeart, link: "/likesYou" },
  { name: "My Applications", icon: FiShoppingBag, link: "/jobs" },
  { name: "Messages", icon: FiMessageSquare, link: "/chat" },
  { name: "Upcoming Interviews", icon: FiCalendar, link: "/interview" },
  { name: "My Profile", icon: FiSettings, link: "/profile" },
];
