import { useEffect, useRef, useState } from "react";
import useLocations from "./useLocations";
import {
  Box,
  Button,
  Card,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import { useFetch } from "../../../../utils/useFetch";
import { components } from "react-select";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

import { prefilledLocations } from "../../../../utils/helper";

import "./location.css";

const WorkStatusOptions = [
  { label: "My Current Location", value: "CURRENT_LOCATION" },
  {
    label: "Open to working in other Locations",
    value: "OPEN_TO_WORKING_IN_OTHER_LOCATIONS",
  },
];

const Location = () => {
  const googleAPI = process.env.REACT_APP_GOOGLE_API_KEY;

  const {
    onSubmit,
    register,
    errors,
    handleSubmit,
    watch,
    locationLoading,
    control,
    setCitiesList,
    citiesList,
    currentLocation,
    setCurrentLocation,
  } = useLocations();
  const googlePlacesRef = useRef<any>(null);

  const handleRemoveCity = (indexToRemove: number) => {
    setCitiesList((prevCitiesList: any) =>
      prevCitiesList.filter((_: any, index: number) => index !== indexToRemove)
    );
  };
  useEffect(() => {
    useFetch
      .get(
        `https://maps.googleapis.com/maps/api/js?key=YOUR_GOOGLE_API_KEY&libraries=lahore`
      )
      .then((response) => {});
  }, []);

  const CustomMenuList = (props: any) => {
    const handleSelectOption = (item: any) => {
      props.selectProps.onChange(item);
      props.selectProps.onMenuClose();
    };
    return (
      <components.MenuList {...props}>
        {props.options?.length > 0 ? (
          props.children
        ) : (
          <Flex direction="column" gap={1}>
            {prefilledLocations.map((item: any) => (
              <Text
                w="full"
                _hover={{ bg: "blue.50" }}
                padding="5px 10px"
                fontWeight="400"
                key={item?.value?.place_id}
                onClick={() => handleSelectOption(item)}
                cursor="pointer"
              >
                {item?.label}
              </Text>
            ))}
          </Flex>
        )}
      </components.MenuList>
    );
  };
  const validateFullName = (value: string) => {
    if (!value.trim()) {
      return "Full Name is required";
    }
    return true;
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={(e: any) => {
        if (e.key === "Enter") {
          handleSubmit(onSubmit)();
        }
      }}
    >
      <Card p="4" w="full">
        <Box my="4">
          <Stack>
            <Heading size="lg" fontWeight={"500"}>
              Join Moon - Find Your Dream Job!
            </Heading>
            <Text>
              Help us match you with the best job opportunities by sharing your
              preferences.
            </Text>
            <Heading size="md" fontWeight={"600"} p="30px 0px 10px">
              Personal Information
            </Heading>
            <FormControl isInvalid={Boolean(errors?.fullName)}>
              <Flex align="start">
                <FormLabel htmlFor="fullName" me="2px !important">
                  Full Name
                </FormLabel>
                <Box as="span" color="red" fontSize="md">
                  *
                </Box>
              </Flex>
              <Input
                type="text"
                placeholder="Enter your first and last name"
                maxW={{ base: "100%", md: "70%" }}
                maxLength={255}
                borderColor="grey"
                {...register("fullName", {
                  required: "Full Name is required",
                  validate: validateFullName,
                })}
                value={watch("fullName")}
              />
              <FormErrorMessage> {errors?.fullName?.message}</FormErrorMessage>
            </FormControl>
            {/* <FormControl isInvalid={Boolean(errors?.email)}>
              <Flex align="start">
                <FormLabel htmlFor="email" me="2px !important">
                  Email
                </FormLabel>
                <Box as="span" color="red" fontSize="md">
                  *
                </Box>
              </Flex>
              <Input
                type="text"
                placeholder="name@example.com"
                maxW={{ base: "100%", md: "70%" }}
                maxLength={255}
                borderColor="grey"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                })}
                value={watch("email")}
              />
              <FormErrorMessage> {errors?.email?.message}</FormErrorMessage>
            </FormControl> */}
            <FormControl isInvalid={Boolean(errors.linkedInProfile)}>
              <FormLabel htmlFor="linkedInProfile">
                Your linkedIn Profile
              </FormLabel>
              <Input
                type="text"
                placeholder="Please enter your linkedIn profile URL"
                maxW={{ base: "100%", md: "70%" }}
                maxLength={255}
                borderColor="grey"
                {...register("linkedInProfile", {
                  pattern: {
                    value: /^(https?:\/\/)?(www\.)?linkedin\.com\/.*$/,
                    message: "Invalid LinkedIn profile URL",
                  },
                })}
                value={watch("linkedInProfile")}
              />
              {errors.linkedInProfile && (
                <Text fontSize="sm" color="red.500">
                  {errors.linkedInProfile.message}
                </Text>
              )}
            </FormControl>
            <Controller
              name="location"
              control={control}
              defaultValue=""
              rules={{ required: "Current Location is required" }}
              render={({ field: { onChange, value } }) => (
                <FormControl
                  maxW={{ base: "100%", md: "70%" }}
                  position="relative"
                  isInvalid={Boolean(errors?.location)}
                >
                  <Flex align="start">
                    <FormLabel htmlFor="location" me="2px !important">
                      Current Location
                    </FormLabel>
                    <Box as="span" color="red" fontSize="md">
                      *
                    </Box>
                  </Flex>

                  <GooglePlacesAutocomplete
                    ref={googlePlacesRef}
                    apiKey={googleAPI}
                    selectProps={{
                      value: currentLocation,
                      onChange: (newValue) => {
                        setCurrentLocation(newValue);
                        onChange(newValue);
                      },
                      placeholder: "Enter the location...",
                      components: {
                        MenuList: CustomMenuList,
                      },
                      styles: {
                        input: (provided) => ({
                          ...provided,
                          color: "blue",
                        }),
                      },
                    }}
                  />

                  <FormErrorMessage>
                    {errors?.location?.message}
                  </FormErrorMessage>
                  {/* <Flex align="center" w="full" gap="2" mt="2" wrap="wrap">
                    {prefilledLocations.map((item) => (
                      <Text
                        padding="3px 8px"
                        border="2px solid #5D78FF"
                        borderRadius="14px"
                        color="#5D78FF"
                        fontWeight="500"
                        key={item?.value?.place_id}
                        onClick={() => {
                          setCurrentLocation(item);
                          onChange(item);
                        }}
                        cursor="pointer"
                        fontSize="11px"
                        _hover={{ bg: "#5D78FF", color: "#fff" }}
                      >
                        {item?.label}
                      </Text>
                    ))}
                  </Flex> */}
                </FormControl>
              )}
            />
            <FormControl isInvalid={Boolean(errors?.remoteWorkPreference)}>
              <Flex align="start">
                <FormLabel htmlFor="state" me="2px !important">
                  Remote Work Preferences
                </FormLabel>
                <Box as="span" color="red" fontSize="md">
                  *
                </Box>
              </Flex>
              <Select
                w={{ base: "100%", md: "70%" }}
                placeholder="Please select Preference"
                size="xs"
                h="42px"
                borderRadius={"md"}
                mr="2"
                {...register("remoteWorkPreference", {
                  required: "Preference is required",
                })}
                value={watch("remoteWorkPreference")}
                name="remoteWorkPreference"
                border="1px solid grey"
              >
                <option value="OPEN_TO_REMOTE_WORK">Open to remote work</option>
                <option value="ONLY_REMOTE_WORK">Remote work only</option>
                <option value="NOT_OPEN_TO_REMOTE_WORK">
                  Not open to remote work
                </option>
              </Select>

              <FormErrorMessage>
                {" "}
                {errors?.remoteWorkPreference?.message}
              </FormErrorMessage>
            </FormControl>

            {watch("remoteWorkPreference") === "OPEN_TO_REMOTE_WORK" ||
            watch("remoteWorkPreference") === "NOT_OPEN_TO_REMOTE_WORK" ? (
              <Controller
                name="preferredLocationToWork"
                control={control}
                defaultValue=""
                rules={{ required: "Please select work status" }}
                render={({ field: { onChange, value } }) => (
                  <FormControl
                    isInvalid={Boolean(errors?.preferredLocationToWork)}
                  >
                    <Flex align="start">
                      <FormLabel me="2px !important">
                        Preferred Locations to Work
                      </FormLabel>
                      <Box as="span" color="red" fontSize="md">
                        *
                      </Box>
                    </Flex>

                    <RadioGroup
                      value={value}
                      onChange={onChange}
                      isDisabled={
                        watch("remoteWorkPreference") === "ONLY_REMOTE_WORK"
                      }
                    >
                      <VStack w="full" align="flex-start">
                        {WorkStatusOptions.map((option) => (
                          <Radio key={option.value} value={option.value}>
                            {option.label}
                          </Radio>
                        ))}
                      </VStack>
                    </RadioGroup>
                    <FormErrorMessage>
                      {errors?.preferredLocationToWork?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              />
            ) : (
              ""
            )}
            {watch("remoteWorkPreference") === "ONLY_REMOTE_WORK" ||
              (watch("preferredLocationToWork") ===
                "OPEN_TO_WORKING_IN_OTHER_LOCATIONS" && (
                <>
                  <FormControl maxW={{ base: "100%", md: "70%" }}>
                    <FormLabel htmlFor="preferredLocationList">
                      Specify Preferred Locations (Optional)
                    </FormLabel>

                    <GooglePlacesAutocomplete
                      apiKey={googleAPI}
                      selectProps={{
                        value: citiesList[0],
                        onChange: (newValue) =>
                          setCitiesList((prevCitiesList: any) => [
                            ...prevCitiesList,
                            newValue?.label,
                          ]),
                        placeholder: "Enter the location...",
                        components: {
                          MenuList: CustomMenuList,
                        },
                        styles: {
                          input: (provided) => ({
                            ...provided,
                            color: "blue",
                          }),
                        },
                      }}
                    />
                  </FormControl>
                  <Flex gap={"10px"}>
                    {citiesList.map((city: any, index: any) => (
                      <Tag key={index}>
                        <TagLabel>{city}</TagLabel>
                        <TagCloseButton
                          onClick={() => handleRemoveCity(index)}
                        />
                      </Tag>
                    ))}
                  </Flex>
                </>
              ))}
          </Stack>
          <Button
            type="submit"
            colorScheme="buttonPrimary"
            mt="4"
            isLoading={locationLoading}
            isDisabled={locationLoading}
          >
            Continue
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default Location;
