import { useMutation, useQuery } from "react-query";

import { useToast } from "@chakra-ui/react";
import { useFetch } from "../utils/useFetch";
import { scrollToTopOfScrollable } from "../utils/scroll-to-top";

const fetchCandidates = async (data: any) => {
  const { data: response } = await useFetch.get(
    `/users/talent/candidate?page=${data?.itemOffset}&limit=12&function=${data?.jobFunction}&query=${data?.query}`
  );

  return response;
};

export const useFetchCandidates = (data: any) => {
  const toast = useToast();
  return useQuery(["candidate-fetch", data], () => fetchCandidates(data), {
    enabled: false,
    staleTime: Infinity,
    onSuccess: (data: any) => {
      scrollToTopOfScrollable();
    },
    onError: (error: any) => {
      toast({
        title: "Error Getting Candidate",
        description: `Something Went`,
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });
    },
  });
};

const fetchJobs = async (employId: any, type: string, page: number) => {
  const { data: response } = await useFetch.get(
    `/jobs/${type}-jobs?employId=${employId}&page=${page}&limit=${
      type === "gat-all" ? "11" : "12"
    }`
  );
  return {
    data: response.data,
    totalPages: response.totalPages,
  };
};

export const useFetchJobs = (
  employId: any,
  type: string,
  page: number,
  updatePage: any
) => {
  const toast = useToast();

  const { isLoading, data, refetch } = useQuery(
    ["job-fetch", employId, type, page],
    () => fetchJobs(employId, type, page),
    {
      onSuccess: (responseData: any) => {
        if (page >= 1 && page + 1 > responseData.totalPages) {
          updatePage(page - 1);
        }
        scrollToTopOfScrollable();
      },
      onError: (error: any) => {
        toast({
          title: "Error Getting Jobs",
          description: `Something Went Wrong`,
          status: "error",
          variant: "top-accent",
          isClosable: true,
        });
      },
    }
  );

  return {
    isLoading,
    data,
    refetch,
  };
};

const addProfileImage = async (data: any) => {
  const { data: response } = await useFetch.put(
    `users/talent/update-talent-profile-picture`,
    { profilePicture: data.profilePicture, userId: data.userId }
  );

  return response;
};
export const useAddProfileImage = () => {
  const toast = useToast();
  return useMutation(addProfileImage, {
    onSuccess: (data: any) => {
      toast({
        title: "Image Uploaded Successfully",
        status: "success",
        variant: "top-accent",
        isClosable: true,
      });
    },
    onError: (error: any) => {
      toast({
        title: "Failed to Upload image",
        description: `${error.response.data.message}`,
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });
    },
  });
};
const fetchAppliedCandidates = async (data: any) => {
  const { data: response } = await useFetch.get(
    `/jobs/apply/get-applicants?jobId=${data?.jobFunction}&query=${data?.query}`
  );

  return response;
};

export const useFetchAppliedCandidates = (data: any) => {
  const toast = useToast();
  return useQuery(
    ["candidate-fetch-applied", data],
    () => fetchAppliedCandidates(data),
    {
      onSuccess: (data: any) => {
        scrollToTopOfScrollable();
      },
      onError: (error: any) => {
        toast({
          title: "Error Getting Users",
          description: `Something Went`,
          status: "error",
          variant: "top-accent",
          isClosable: true,
        });
      },
    }
  );
};
const fetchCandidatesArchived = async (page: number) => {
  const { data: response } = await useFetch.get(
    `users/employer/all-archived-candidates?page=${page}&limit=12`
  );

  return response;
};

export const useFetchCandidatesArchived = (page: any) => {
  const toast = useToast();
  return useQuery(
    ["candidate-fetch-archived"],
    () => fetchCandidatesArchived(page),
    {
      enabled: false,
      onSuccess: (data: any) => {
        scrollToTopOfScrollable();
      },
      onError: (error: any) => {
        toast({
          title: "Error Getting Candidate",
          description: `Something Went`,
          status: "error",
          variant: "top-accent",
          isClosable: true,
        });
      },
    }
  );
};
const likedtatusEmployerSide = async (body: any) => {
  const { data: response } = await useFetch.post(
    `users/employer/employer-interest-candidate`,
    body
  );

  return response;
};
export const useLikedStatusEmployerSide = () => {
  const toast = useToast();
  return useMutation(likedtatusEmployerSide, {
    onSuccess: (data: any) => {},
    onError: (error: any) => {
      toast({
        title: "Failed to Add Job",
        description: `${error.response.data}`,
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });
    },
  });
};
const archiveStatusEmployerSide = async (body: any) => {
  const { data: response } = await useFetch.post(
    `users/employer/employer-archives-candidate`,
    body
  );

  return response;
};

export const useArchiveStatusEmployerSide = () => {
  const toast = useToast();
  return useMutation(archiveStatusEmployerSide, {
    onSuccess: (data: any) => {},
    onError: (error: any) => {
      toast({
        title: "Failed to Add Job",
        description: `${error.response.data.message}`,
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });
    },
  });
};

const fetchCandidatesLiked = async (page: number) => {
  const { data: response } = await useFetch.get(
    `users/employer/all-interested-in-candidates?page=${page}&limit=12`
  );
  return response;
};

export const useFetchCandidatesLiked = (page: any) => {
  const toast = useToast();
  return useQuery(["candidate-fetch-liked"], () => fetchCandidatesLiked(page), {
    enabled: false,
    onSuccess: (data: any) => {
      scrollToTopOfScrollable();
    },
    onError: (error: any) => {
      toast({
        title: "Error Getting Candidate",
        description: `Something Went`,
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });
    },
  });
};

const fetchCandidatesById = async (data: any) => {
  const { data: response } = await useFetch.get(
    `/users/employer/candidates-by-id?jobId=${data.jobId}&candidateId=${data.candidateId}`
  );

  return response;
};

export const useFetchCandidatesById = (data: any) => {
  const toast = useToast();
  return useQuery(["candidate-fetch-by-id"], () => fetchCandidatesById(data), {
    enabled: false,
    onSuccess: (data: any) => {},
    onError: (error: any) => {
      toast({
        title: "Error Getting Candidate",
        description: `Something Went`,
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });
    },
  });
};
