import React, { useState } from "react";
import {
  Flex,
  Card,
  CardHeader,
  CardBody,
  Text,
  Switch,
  Button,
  Icon,
  Box,
  Spinner,
} from "@chakra-ui/react";
import { FaFire } from "react-icons/fa";

import ReportTable from "../report-table/report-table";
import "../report.css";
const ReportView = ({
  activePipelineData,
  isActivelyInterviewing,
  setIsActivelyInterviewing,
  archievedPipelineData,
  setArchievedPipelineData,
  activeItemOffset,
  setActiveItemOffset,
  archievedItemOffset,
  setArchievedItemOffset,
  activeTab,
  setActiveTab,
  resetFilters,
  isArchiveLoading,
  isActiveLoading,
}: any) => {
  const handleActivePageClick = (event: any) => {
    const newOffset = event.selected;
    setActiveItemOffset(newOffset);
  };
  const handleArchievedPageClick = (event: any) => {
    const newOffset = event.selected;
    setArchievedItemOffset(newOffset);
  };
  return (
    <>
      <Flex direction="column" w="full" overflow="auto">
        <Card>
          <CardHeader borderBottom="1px solid #f7f8fa">
            <Flex
              align="center"
              justify="space-between"
              w="full"
              padding="5px 20px 0px"
              wrap="wrap"
              gap="10px"
            >
              <Flex direction="column" gap="15px">
                <Flex align="center" gap="5px">
                  <Switch
                    id="activelyInterviewing"
                    isChecked={isActivelyInterviewing}
                    onChange={(e) =>
                      setIsActivelyInterviewing(e?.target?.checked)
                    }
                  />
                  <Icon as={FaFire} color="#fd397a" fontSize="16px" />

                  <Text color="#646c9a" fontSize="13px">
                    Actively Interviewing
                  </Text>
                </Flex>

                <Flex align="center" gap="15px" wrap="wrap">
                  <Button
                    className={
                      activeTab === "Active Candidate"
                        ? "active-purple-btn"
                        : "purple-btn"
                    }
                    onClick={() => {
                      setActiveTab("Active Candidate");
                      resetFilters();
                    }}
                    size="sm"
                  >
                    Active Candidate
                  </Button>
                  <Button
                    className={
                      activeTab === "Archived"
                        ? "active-purple-btn"
                        : "purple-btn"
                    }
                    onClick={() => {
                      setActiveTab("Archived");
                      resetFilters();
                    }}
                    size="sm"
                  >
                    Archived
                  </Button>
                </Flex>
              </Flex>
              <Flex direction="column">
                <Flex
                  align="center"
                  gap="20px"
                  w="170px"
                  justify="space-between"
                >
                  <Text fontSize="13px" color="#646c9a">
                    On Time
                  </Text>{" "}
                  <Box padding="3px 10px" bg="#0abb87" borderRadius="20px" />
                </Flex>
                <Flex
                  align="center"
                  gap="20px"
                  w="170px"
                  justify="space-between"
                >
                  <Text fontSize="13px" color="#646c9a">
                    Need Attention
                  </Text>
                  <Box padding="3px 10px" bg="#ffb822" borderRadius="20px" />
                </Flex>
                <Flex
                  align="center"
                  gap="20px"
                  w="170px"
                  justify="space-between"
                >
                  <Text fontSize="13px" color="#646c9a">
                    Behind Schedule
                  </Text>
                  <Box padding="3px 10px" bg="#fd397a" borderRadius="20px" />
                </Flex>
              </Flex>
            </Flex>
          </CardHeader>
          {activeTab === "Active Candidate" ? (
            <CardBody>
              {isActiveLoading && !activePipelineData?.data?.length ? (
                <Flex align="center" w="full" justify="center" minH="10vh">
                  {" "}
                  <Spinner size="lg" />{" "}
                </Flex>
              ) : activePipelineData?.data?.length ? (
                <ReportTable
                  data={activePipelineData?.data}
                  handlePageClick={handleActivePageClick}
                  pageCount={activePipelineData?.totalPages}
                  itemOffset={activeItemOffset}
                  tab="active"
                />
              ) : (
                <Flex align="center" w="full" justify="center" minH="10vh">
                  No Records found
                </Flex>
              )}
            </CardBody>
          ) : (
            <CardBody>
              {isArchiveLoading && !archievedPipelineData?.data?.length ? (
                <Flex align="center" w="full" justify="center" minH="10vh">
                  {" "}
                  <Spinner size="lg" />{" "}
                </Flex>
              ) : archievedPipelineData?.data?.length ? (
                <ReportTable
                  data={archievedPipelineData?.data}
                  itemOffset={archievedItemOffset}
                  handlePageClick={handleArchievedPageClick}
                  pageCount={archievedPipelineData?.totalPages}
                  tab="Archived"
                />
              ) : (
                <Flex align="center" w="full" justify="center" minH="10vh">
                  No Records found
                </Flex>
              )}
            </CardBody>
          )}
        </Card>
      </Flex>
    </>
  );
};

export default ReportView;
