import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthStore } from "../../../../store/useAuth";
import moment from "moment";
import { getUserId } from "../../../../utils/helper";
import {
  useAddEducation,
  useDeleteEducation,
  useFetchAllEducation,
  useUpdateEducation,
} from "../../../../service/education.service";
import { usePercentageStore } from "../../../../store/useStore";
interface ErrorType {
  degree: string;
  schoolName: string;
  major: string;
  educationStatus: string;
  start: string;
  end: string;
}
const useEducation = (onModalClose?: () => void, isEdit?: boolean) => {
  const { percentage, updatePercentage } = usePercentageStore();

  const { pathname } = useLocation();
  const userId = getUserId();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { user, setEducation, deleteEducation } = useAuthStore();
  const [finalSchoolName, setFinalSchoolName] = useState("");
  const [finalFieldofStudy, setFinalFieldOfStudy] = useState("");
  const [editableData, setEditableData] = useState<any>(null);
  const [degree, setDegree] = useState<string>("");
  const [educationStatus, setEducationStatus] = useState<string>("");
  const [startDate, setStartDate] = useState<Date | null>();
  const [endDate, setEndDate] = useState<Date | null>();

  const [errors, setErrors] = useState<ErrorType>({
    degree: "",
    schoolName: "",
    major: "",
    educationStatus: "",
    start: "",
    end: "",
  });

  const {
    mutate: educationMutation,
    isLoading: educationLoading,
    data: educationData,
  } = useAddEducation();
  const {
    mutate: educationUpdateMutation,
    isLoading: educationUpdateLoading,
    data: educationUpdatedData,
  } = useUpdateEducation();
  const { isLoading: educationListLoading, data: educationList } =
    useFetchAllEducation(userId);
  const { mutate: educationDelete, isLoading: educationDelLoading } =
    useDeleteEducation();

  const handleValidation = () => {
    let isFormValid = true;
    let error = {
      degree: "",
      schoolName: "",
      major: "",
      educationStatus: "",
      start: "",
      end: "",
    };
    if (!degree) {
      isFormValid = false;
      error.degree = "Degree is required";
    }
    if (!finalSchoolName) {
      isFormValid = false;
      error.schoolName = "School Name is required";
    }
    if (!finalFieldofStudy) {
      isFormValid = false;
      error.major = "Field of Study is required";
    }
    if (!educationStatus) {
      isFormValid = false;
      error.educationStatus = "Please select education status";
    }
    if (educationStatus && !startDate) {
      isFormValid = false;
      error.start = "Start Date is required";
    }
    if (educationStatus && educationStatus !== "INCOMPLETE" && !endDate) {
      isFormValid = false;
      error.end = "End Date is required";
    }
    setErrors(error);
    return isFormValid;
  };
  useEffect(() => {
    if (state?.isEdit) {
      const { degree, educationStatus, endDate, startDate, schoolName, major } =
        state;
      setFinalSchoolName(schoolName);
      setFinalFieldOfStudy(major);
      setDegree(degree);
      setEducationStatus(educationStatus);
      setStartDate(startDate !== null ? moment(startDate).toDate() : null);
      setEndDate(endDate !== null ? moment(endDate).toDate() : null);
      setFinalFieldOfStudy(major);
    }
  }, [state]);
  useEffect(() => {
    if (editableData) {
      const { degree, educationStatus, endDate, startDate, schoolName, major } =
        editableData;
      setFinalSchoolName(schoolName);
      setFinalFieldOfStudy(major);
      setFinalSchoolName(schoolName);
      setFinalFieldOfStudy(major);
      setDegree(degree);
      setEducationStatus(educationStatus);
      setStartDate(startDate !== null ? moment(startDate).toDate() : null);
      setEndDate(endDate !== null ? moment(endDate).toDate() : null);
      setFinalFieldOfStudy(major);
      // educationStatus !== "INCOMPLETE"
      //   ? endDate !== null && setEndDate(moment(endDate).toDate())
      //   : endDate !== null && setEndDate(moment(endDate).toDate());
      // educationStatus !== "INCOMPLETE"
      //   ? startDate !== null &&
      //     setValue("startDate", moment(startDate).toDate())
      //   : startDate !== null &&
      //     setValue("startDate", moment(startDate).toDate());
      // setValue("schoolName", schoolName);
      // setValue("major", major);
    }
  }, [editableData]);

  const handleStartDateChange = (date: Date | null) => {
    if (date) {
      const startDate = moment(date)
        .startOf("month")
        .endOf("day")
        .utc()
        .toDate();
      setStartDate(date);
      setErrors((prevError) => ({ ...prevError, start: "" }));
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    if (date) {
      const endDate = moment(date).startOf("month").endOf("day").utc().toDate();
      setEndDate(endDate);
      setErrors((prevError) => ({ ...prevError, end: "" }));
    }
  };

  const onSubmit = async () => {
    if (handleValidation()) {
      let finalStartDate = "";
      let finalEndDate = "";
      if (startDate) {
        finalStartDate = `${startDate.getFullYear()}-${
          startDate.getMonth() + 1 < 10
            ? "0" + (startDate.getMonth() + 1)
            : startDate.getMonth() + 1
        }-${
          startDate.getDate() < 10
            ? "0" + startDate.getDate()
            : startDate.getDate()
        }`;
      }
      if (endDate) {
        finalEndDate = `${endDate.getFullYear()}-${
          endDate.getMonth() + 1 < 10
            ? "0" + (endDate.getMonth() + 1)
            : endDate.getMonth() + 1
        }-${
          endDate.getDate() < 10 ? "0" + endDate.getDate() : endDate.getDate()
        }`;
      }
      const values = {
        degree,
        educationStatus,
        endDate: finalEndDate,
        startDate: finalStartDate,
      };
      try {
        educationMutation(
          {
            userId,
            education: {
              ...values,
              schoolName: finalSchoolName,
              major: finalFieldofStudy,
            },
          },
          {
            onSuccess: (resData) => {
              localStorage.setItem(
                "profilepercentage",
                JSON.stringify(resData?.profileCompletePercentage)
              );
              updatePercentage(resData?.profileCompletePercentage);
              pathname !== "/profile"
                ? navigate(-1)
                : onModalClose && onModalClose();
            },
          }
        );
      } catch (error) {
        console.log("error", error);
      }
    }
  };
  const deleteHandler = (educationId: string, userId: string) => {
    educationDelete({ educationId, userId });
  };
  const updateHandler = () => {
    if (handleValidation()) {
      let finalStartDate = "";
      let finalEndDate = "";
      if (startDate) {
        finalStartDate = `${startDate.getFullYear()}-${
          startDate.getMonth() + 1 < 10
            ? "0" + (startDate.getMonth() + 1)
            : startDate.getMonth() + 1
        }-${
          startDate.getDate() < 10
            ? "0" + startDate.getDate()
            : startDate.getDate()
        }`;
      }
      if (endDate) {
        finalEndDate = `${endDate.getFullYear()}-${
          endDate.getMonth() + 1 < 10
            ? "0" + (endDate.getMonth() + 1)
            : endDate.getMonth() + 1
        }-${
          endDate.getDate() < 10 ? "0" + endDate.getDate() : endDate.getDate()
        }`;
      }
      const values = {
        degree,
        educationStatus,
        endDate: finalEndDate,
        startDate: finalStartDate,
      };
      try {
        educationUpdateMutation(
          {
            ...values,
            schoolName: finalSchoolName,
            major: finalFieldofStudy,
            userId,
            educationId: state?.id ? state?.id : editableData?.id,
          },
          {
            onSuccess: (resData) => {
              pathname !== "/profile"
                ? navigate(-1)
                : onModalClose && onModalClose();
            },
          }
        );
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  return {
    onSubmit,
    setEducation,
    user,
    deleteHandler,
    handleStartDateChange,
    handleEndDateChange,
    educationLoading,
    educationList,
    educationListLoading,
    navigate,
    educationDelLoading,
    updateHandler,
    state,
    educationUpdateLoading,
    setEditableData,
    finalSchoolName,
    setFinalSchoolName,
    finalFieldofStudy,
    setFinalFieldOfStudy,
    errors,
    setErrors,
    handleValidation,
    degree,
    setDegree,
    educationStatus,
    setEducationStatus,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
  };
};

export default useEducation;
