import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { loginSchema } from "../../utils/validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { handleLoginLocalStorage } from "../../utils/helper";
import { useLoginApi } from "../../service/auth.service";

const useLogin = () => {
  const navigate = useNavigate();
  const { mutate: loginCall, isLoading: loginLoading } = useLoginApi();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(loginSchema),
  });
  const onSubmit = async (data: any) => {
    try {
      // navigate("/");
      loginCall(data, {
        onSuccess: (resData) => {
          const { token, ...rest } = resData;
          handleLoginLocalStorage(token, rest);
          navigate("/");
        },
      });
    } catch (error) {
      console.log("error", error);
    }
  };
  return {
    register,
    handleSubmit,
    errors,
    onSubmit,
    navigate,
    loginLoading,
  };
};

export default useLogin;
