import { Navigate, Outlet } from "react-router-dom";
import { LinkItemsEmployeer } from "../../../../utils/routes";
import Sidebar from "../../../common/sidebar";
import { getAccessToken } from "../../../../utils/helper";

const PrivateRoutes = () => {
  const token = getAccessToken();
  // const { data: employerScheduleList } = useFetchEmployerScheldules();

  return token ? (
    <Sidebar
      LinkItemProps={LinkItemsEmployeer}
      // interviewList={employerScheduleList}
    >
      <Outlet />
    </Sidebar>
  ) : (
    <Navigate to="/signup" />
  );
};

export default PrivateRoutes;
