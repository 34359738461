import {
  Box,
  Button,
  Card,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Heading,
  Input,
  Select,
  Stack,
  Switch,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  Textarea,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { Controller } from "react-hook-form";
import { GenericModal } from "../../../common/generic-modal";
import RadioGroup from "../../../common/radioGroup";
import { useFetch } from "../../../../utils/useFetch";

const EditProfileExperience = ({
  isOpen,
  onClose,
  register,
  errors,
  handleSubmit,
  watch,
  updateHandler,
  control,
  isSubmitted,
  jobFunc,
  setJobFunc,
  topSkills,
  setTopSkills,
  setValue,
  trigger,
  industriesList,
  setIndustriesList,
  error,
  setError,
  handleStartDateChange,
  handleEndDateChange,
}: any) => {
  const inputref = useRef<HTMLInputElement>(null);
  const [functions, setFunctions] = useState("");
  const [showJobFunctionResult, setShowJobFunctionResult] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [searchJobFunctionResult, setSearchJobFunctionResult] = useState<any>(
    []
  );
  const [skills, setSkills] = useState("");
  const [showTopSkillsResult, setShowTopSkillsResult] = useState(false);
  const [searchTopSkillsResult, setSearchTopSkillsResult] = useState<any>([]);
  const [isSkillsFocused, setIsSkillsFocused] = useState(false);
  const [isIndustryFocused, setIsIndustryFocused] = useState(false);
  const [industryName, setIndustryName] = useState("");
  const [jobIndustryResult, setJobIndustryResult] = useState<any>([]);
  const [showJobIndustriesResult, setShowJobIndustriesResult] = useState(false);
  const [isSwitchOn, setIsSwitchOn] = useState(true);

  const [formErrorFunctions, setFormErrorFunctions] = useState("");
  const [formErrorSkills, setFormErrorSkills] = useState("");
  const [formErrorIndustry, setFormErrorIndustry] = useState("");
  const [editedJobDescription, setEditedJobDescription] = useState("");

  useEffect(() => {
    if (isIndustryFocused) {
      if (industryName?.length > 0) {
        try {
          useFetch
            .get(`/users/domain/all-job-industry-list?query=${industryName}`)
            .then((response) => {
              setJobIndustryResult(response.data);
              setShowJobIndustriesResult(true);
            })
            .catch((error) => {
              console.log("Error", error);
            });
        } catch (error) {
          console.log("ERROR", error);
        }
      } else {
        setShowJobIndustriesResult(false);
      }
    }
  }, [industryName]);
  useEffect(() => {
    if (isFocused) {
      if (functions?.length > 0) {
        try {
          useFetch
            .get(`/users/domain/all-job-function-list?query=${functions}`)
            .then((response) => {
              setSearchJobFunctionResult(response.data);
              setShowJobFunctionResult(true);
            })
            .catch((error) => {
              console.log("Error", error);
            });
        } catch (error) {
          console.log("ERROR", error);
        }
      } else {
        setShowJobFunctionResult(false);
      }
    }
  }, [functions]);
  useEffect(() => {
    if (isSkillsFocused) {
      if (skills?.length > 0) {
        try {
          useFetch
            .get(`/users/domain/all-job-skill-name-list?query=${skills}`)
            .then((response) => {
              setSearchTopSkillsResult(response.data);
              setShowTopSkillsResult(true);
            })
            .catch((error) => {
              console.log("Error", error);
            });
        } catch (error) {
          console.log("ERROR", error);
        }
      } else {
        setShowTopSkillsResult(false);
      }
    }
  }, [skills]);
  const handleAddJobFunctions = (functionsProps: string) => {
    if (functionsProps) {
      setShowJobFunctionResult(false);

      if (jobFunc.includes(functionsProps)) {
        setError((prevError: any) => ({
          ...prevError,
          jobFunctionError: "You have already selected this job function",
        }));
      } else if (jobFunc?.length >= 3) {
        setError((prevError: any) => ({
          ...prevError,
          jobFunctionError: "You can Add up to 3 options",
        }));
      } else {
        setJobFunc((prevJobFuncList: any) => [
          ...prevJobFuncList,
          functionsProps,
        ]);
        setFunctions("");
        setError((prevError: any) => ({ ...prevError, jobFunctionError: "" }));
      }
    } else {
      setError((prevError: any) => ({
        ...prevError,
        jobFunctionError: "Please fill the required field",
      }));
    }
  };

  const handleRemoveJobFunction = (indexToRemove: number) => {
    setJobFunc((prevJobfuncList: any) =>
      prevJobfuncList.filter((_: any, index: number) => index !== indexToRemove)
    );
  };
  const handleFocusBlur = (value: boolean) => {
    setIsFocused(value);
  };
  const handleAddTopSkills = (skillsProps: string) => {
    if (skillsProps) {
      setShowJobFunctionResult(false);
      if (topSkills.includes(skillsProps)) {
        setError((prevError: any) => ({
          ...prevError,
          skillsError: "You have already selected this job function",
        }));
      } else if (topSkills?.length >= 3) {
        setError((prevError: any) => ({
          ...prevError,
          skillsError: "You can select up to 3 options",
        }));
      } else {
        setTopSkills((prevTopSkillsList: any) => [
          ...prevTopSkillsList,
          skillsProps,
        ]);
        setSkills("");
        setError((prevError: any) => ({ ...prevError, skillsError: "" }));
      }
    }
  };

  const handleSkillsFocusBlur = (value: boolean) => {
    setIsSkillsFocused(value);
  };
  const handleRemoveTopSkills = (indexToRemove: number) => {
    setTopSkills((prevTopSkillsList: any) =>
      prevTopSkillsList.filter(
        (_: any, index: number) => index !== indexToRemove
      )
    );
  };
  const handleIndustryFocusBlur = (value: boolean) => {
    setIsIndustryFocused(value);
  };

  const handleAddIndustry = (industryNameProps: string) => {
    if (industryNameProps) {
      if (industriesList.includes(industryNameProps)) {
        setError((prevError: any) => ({
          ...prevError,
          industryError: "You have already selected this industry",
        }));
      } else if (industriesList?.length >= 3) {
        setError((prevError: any) => ({
          ...prevError,
          industryError: "You can select up to 3 options",
        }));
      } else {
        setIndustriesList((prevIndustriesList: any) => [
          ...prevIndustriesList,
          industryNameProps,
        ]);
        setIndustryName("");
        setError((prevError: any) => ({
          ...prevError,
          industryError: "",
        }));
      }
    }
  };

  const handleRemoveIndustry = (indexToRemove: number) => {
    setIndustriesList((prevIndustriesList: any) =>
      prevIndustriesList.filter(
        (_: any, index: number) => index !== indexToRemove
      )
    );
  };

  const handleSwitchChange = (e: any) => {
    setIsSwitchOn(e.target.checked);
    if (e.target.checked === true) {
      setValue("indirectReports", 0);
    }
  };

  return (
    <>
      <GenericModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setError({
            industryError: "",
            jobFunctionError: "",
            skillsError: "",
          });
          setFunctions("");
          setSkills("");
          setIndustryName("");
        }}
        title={"Edit Experience Information"}
        onClickHandler={() => console.log("values")}
        buttonText={false ? "Loading" : "Save"}
        loadingButton={false || false}
        buttonShow={false}
      >
        <Card p="4" w="full">
          <form
            style={{ width: "100%" }}
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(updateHandler)();
            }}
            onKeyDown={(e: any) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSubmit(updateHandler)();
              }
            }}
          >
            <Box my="4">
              <Stack spacing={6}>
                <HStack w="full" justify={"space-between"}>
                  <Heading size="lg" fontWeight={"500"}>
                    {" "}
                    Edit Position
                  </Heading>{" "}
                </HStack>

                <FormControl isInvalid={Boolean(errors?.jobTitle)}>
                  <FormLabel fontWeight={"bold"} fontSize="md">
                    Job Title
                  </FormLabel>
                  <Input
                    type="text"
                    placeholder="Enter job title"
                    w={{ base: "100%", md: "70%" }}
                    {...register("jobTitle", {
                      required: "Job Title is required",
                    })}
                    value={watch("jobTitle")}
                  />
                  <FormErrorMessage>
                    {errors?.jobTitle?.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={Boolean(errors?.employeeType)}>
                  <FormLabel fontWeight={"bold"} fontSize="md">
                    Employment Type
                  </FormLabel>

                  <Select
                    w={{ base: "100%", md: "70%" }}
                    isDisabled={false}
                    size="xs"
                    h="42px"
                    borderRadius={"md"}
                    placeholder="Please select employee type"
                    {...register("employeeType", {
                      required: "Employee type is required",
                    })}
                    value={watch("employeeType")}
                    name="employeeType"
                    mr="2"
                  >
                    <option value={0}>Full Time</option>
                    <option value={1}>Part Time</option>
                    <option value={2}>Contract/Temporary</option>
                    <option value={3}>Internship</option>
                    <option value={4}>Freelance</option>
                    <option value={5}>Self-Employed</option>
                    <option value={6}>Volunteer</option>
                    <option value={7}>Apprenticeship</option>
                  </Select>
                  <FormErrorMessage>
                    {errors?.employeeType?.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={Boolean(errors?.companyName)}>
                  <FormLabel fontWeight={"bold"} fontSize="md">
                    Company Name
                  </FormLabel>
                  <Input
                    type="text"
                    placeholder="Enter Company name"
                    w={{ base: "100%", md: "70%" }}
                    {...register("companyName", {
                      required: "Company Name is required",
                      validate: {
                        noLeadingSpaces: (value: string) =>
                          value.trimStart() === value,
                        noEmptyString: (value: string) => value.trim() !== "",
                      },
                    })}
                    value={watch("companyName")}
                  />
                  <FormErrorMessage>
                    {errors?.companyName?.message}
                  </FormErrorMessage>
                </FormControl>

                <>
                  <FormControl
                    isInvalid={Boolean(errors?.managedPeople)}
                    isRequired
                  >
                    <FormLabel fontSize="md">
                      Managed people in this role?
                    </FormLabel>
                    <RadioGroup
                      options={[
                        "No, I was individual contributor",
                        "Lead teams but no direct reports",
                        "Yes, I had direct reports",
                      ]}
                      name="managedPeople"
                      defaultValue={watch("managedPeople")}
                      value={watch("jomanagedPeoplebType")}
                      onChange={(value) => {
                        setValue("managedPeople", value);
                        trigger("managedPeople");
                      }}
                      maxW="100%"
                    />
                    {errors?.managedPeople && (
                      <FormErrorMessage>
                        {errors?.managedPeople?.message?.toString()}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                  {!!watch("managedPeople") &&
                    watch("managedPeople") === "Yes, I had direct reports" && (
                      <>
                        <Flex
                          w="full"
                          align="start"
                          gap="2"
                          direction={{ base: "column", md: "row" }}
                        >
                          <FormControl
                            isInvalid={Boolean(errors?.directReports)}
                            w={{ base: "100%", md: "50%" }}
                          >
                            <Flex align="start">
                              <FormLabel fontWeight={"bold"} fontSize="md">
                                Number of direct Reports
                              </FormLabel>
                              <Box as="span" color="red" fontSize="md">
                                *
                              </Box>
                            </Flex>

                            <Input
                              type="text"
                              placeholder="Enter direct reports"
                              {...register("directReports", {
                                required: "Direct reports are required",
                              })}
                              onInput={(e) => {
                                const inputElement =
                                  e.target as HTMLInputElement;
                                inputElement.value = inputElement.value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                              }}
                            />
                            <FormErrorMessage>
                              {errors?.directReports?.message}
                            </FormErrorMessage>
                          </FormControl>
                          <FormControl
                            isInvalid={Boolean(
                              !isSwitchOn && errors?.indirectReports
                            )}
                          >
                            <Flex w="full" align="start" gap="6">
                              <Flex direction="column">
                                <FormLabel fontWeight={"bold"} fontSize="md">
                                  Number of Indirect Reports (if any)
                                  <Box
                                    as="span"
                                    ml="2"
                                    color="red"
                                    fontSize="md"
                                    mt="1"
                                  >
                                    {isSwitchOn ? "" : "*"}
                                  </Box>
                                </FormLabel>

                                <Input
                                  type="text"
                                  placeholder="Enter Indirect reports"
                                  w="full"
                                  {...register("indirectReports", {
                                    required: isSwitchOn
                                      ? false
                                      : "Number of Indirect Reports is required",
                                  })}
                                  disabled={isSwitchOn}
                                  value={watch("indirectReports") || undefined}
                                  onInput={(e) => {
                                    const inputElement =
                                      e.target as HTMLInputElement;
                                    inputElement.value =
                                      inputElement.value.replace(/[^0-9]/g, "");
                                  }}
                                />
                              </Flex>
                              <Flex direction="column">
                                <FormLabel fontWeight={"bold"} fontSize="md">
                                  None or N/A
                                </FormLabel>
                                <Switch
                                  id="indirectReports"
                                  onChange={(e: any) => handleSwitchChange(e)}
                                  isChecked={isSwitchOn}
                                />
                              </Flex>
                            </Flex>
                            {!isSwitchOn && errors?.indirectReports && (
                              <FormErrorMessage>
                                {errors?.indirectReports?.message}
                              </FormErrorMessage>
                            )}
                          </FormControl>
                        </Flex>
                      </>
                    )}
                  <FormControl position="relative">
                    <Heading size="sm" pos="relative">
                      Job Functions
                    </Heading>
                    <HStack>
                      <Input
                        type="text"
                        placeholder="Search Job Functions"
                        maxW={{ base: "100%", md: "70%" }}
                        ref={inputref}
                        onFocus={() => handleFocusBlur(true)}
                        onBlur={() => handleFocusBlur(false)}
                        borderColor="grey"
                        onChange={(e) => setFunctions(e.target.value)}
                        value={functions}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.stopPropagation();
                            e.preventDefault();
                            handleAddJobFunctions(functions);
                          }
                        }}
                      />

                      <Button
                        ml="2"
                        colorScheme="buttonPrimary"
                        onClick={() => handleAddJobFunctions(functions)}
                      >
                        Add
                      </Button>
                    </HStack>
                    {error?.jobFunctionError && (
                      <Text fontSize={"sm"} color={"red.500"}>
                        {error?.jobFunctionError}
                      </Text>
                    )}

                    {showJobFunctionResult &&
                      searchJobFunctionResult?.length >= 1 && (
                        <VStack
                          height="100px"
                          overflowY="scroll"
                          position="absolute"
                          top="60px"
                          zIndex="50"
                          bg="white"
                          border="1px solid #F1F0F9"
                          borderRadius="10px"
                          w="70%"
                          boxShadow="0px 8px 32px 0px rgba(78, 48, 123, 0.04)"
                          padding="5px 0px"
                        >
                          {searchJobFunctionResult.map(
                            (data: any, index: number) => (
                              <Flex
                                w="full"
                                p="5px"
                                borderBottom="1px solid #eae8e8"
                                onClick={() => handleAddJobFunctions(data.name)}
                                _hover={{ bg: "#F1F0F9", color: "black" }}
                                key={index}
                                cursor="pointer"
                              >
                                <Text ms="4px">{data.name}</Text>
                              </Flex>
                            )
                          )}
                        </VStack>
                      )}
                  </FormControl>
                  <Flex gap={"10px"}>
                    {jobFunc.map((functions: string, index: number) => (
                      <Tag key={index}>
                        <TagLabel>{functions}</TagLabel>
                        <TagCloseButton
                          onClick={() => handleRemoveJobFunction(index)}
                        />
                      </Tag>
                    ))}
                  </Flex>

                  <FormControl position="relative">
                    <Heading size="sm">Top 3 Skills</Heading>
                    <HStack gap={3}>
                      <Input
                        type="text"
                        placeholder="Search Top Skills"
                        maxW={{ base: "100%", md: "70%" }}
                        borderColor="grey"
                        onChange={(e) => setSkills(e.target.value)}
                        value={skills}
                        ref={inputref}
                        onFocus={() => handleSkillsFocusBlur(true)}
                        onBlur={() => handleSkillsFocusBlur(false)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.stopPropagation();
                            e.preventDefault();
                            handleAddTopSkills(skills);
                          }
                        }}
                      />
                      <Button
                        ml="2"
                        colorScheme="buttonPrimary"
                        onClick={() => handleAddTopSkills(skills)}
                      >
                        Add
                      </Button>
                    </HStack>

                    {error?.skillsError && (
                      <Text fontSize={"sm"} color={"red.500"}>
                        {error?.skillsError}
                      </Text>
                    )}
                    <HStack mt={2}></HStack>
                    {showTopSkillsResult &&
                      searchTopSkillsResult?.length >= 1 && (
                        <VStack
                          height="100px"
                          overflowY="scroll"
                          position="absolute"
                          top="60px"
                          zIndex="50"
                          bg="white"
                          border="1px solid #F1F0F9"
                          borderRadius="10px"
                          w="70%"
                          boxShadow="0px 8px 32px 0px rgba(78, 48, 123, 0.04)"
                          padding="5px 0px"
                        >
                          {searchTopSkillsResult.map(
                            (data: any, index: number) => (
                              <Flex
                                w="full"
                                p="5px"
                                borderBottom="1px solid #eae8e8"
                                onClick={() => handleAddTopSkills(data.name)}
                                _hover={{ bg: "#F1F0F9", color: "black" }}
                                key={index}
                                cursor="pointer"
                              >
                                <Text ms="4px">{data.name}</Text>
                              </Flex>
                            )
                          )}
                        </VStack>
                      )}
                  </FormControl>
                  <Flex gap={"10px"}>
                    {topSkills.map((skills: string, index: number) => (
                      <Tag key={index}>
                        <TagLabel>{skills}</TagLabel>
                        <TagCloseButton
                          onClick={() => handleRemoveTopSkills(index)}
                        />
                      </Tag>
                    ))}
                  </Flex>
                </>
                <VStack w="full" align={"baseline"}>
                  <HStack w={{ base: "100%", md: "70%" }}>
                    <Controller
                      name="startDate"
                      control={control}
                      defaultValue={watch("startDate") || new Date()}
                      rules={{ required: "This date is required" }}
                      render={({ field: { onChange, value } }) => (
                        <FormControl isInvalid={Boolean(errors?.startDate)}>
                          <FormLabel fontWeight={"bold"} fontSize="md">
                            Start Date
                          </FormLabel>
                          <ReactDatePicker
                            selected={value}
                            onChange={handleStartDateChange}
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                            dropdownMode="select"
                            maxDate={new Date()}
                          />
                        </FormControl>
                      )}
                    />
                  </HStack>
                  {errors?.startDate && (
                    <Text color="red.500" fontSize="sm">
                      {errors?.startDate?.message}
                    </Text>
                  )}
                </VStack>
                <Checkbox
                  id="currentlyWorking"
                  {...register("currentlyWorking")}
                >
                  I am currently working in this role
                </Checkbox>
                {!watch("currentlyWorking") && (
                  <VStack w="full" align={"baseline"}>
                    <HStack w={{ base: "100%", md: "70%" }}>
                      <Controller
                        name="endDate"
                        control={control}
                        // defaultValue={new Date()}
                        rules={{ required: "This date is required" }}
                        render={({ field: { onChange, value } }) => (
                          <FormControl isInvalid={Boolean(errors?.endDate)}>
                            <FormLabel fontWeight={"bold"} fontSize="md">
                              End Date
                            </FormLabel>
                            <ReactDatePicker
                              selected={value}
                              onChange={handleEndDateChange}
                              dateFormat="MM/yyyy"
                              showMonthYearPicker
                              dropdownMode="select"
                              minDate={watch("startDate")}
                              maxDate={new Date()}
                            />
                          </FormControl>
                        )}
                      />
                    </HStack>
                    {errors?.endDate && (
                      <Text color="red.500" fontSize="sm">
                        {errors?.endDate?.message}
                      </Text>
                    )}
                  </VStack>
                )}

                <FormControl position="relative">
                  <FormLabel fontWeight={"bold"} fontSize="md">
                    Job Industry
                  </FormLabel>
                  <HStack gap={3}>
                    <Input
                      type="text"
                      placeholder="Enter Industry Name"
                      maxW={{ base: "100%", md: "70%" }}
                      borderColor="grey"
                      onChange={(e) => setIndustryName(e.target.value)}
                      value={industryName}
                      ref={inputref}
                      onFocus={() => handleIndustryFocusBlur(true)}
                      onBlur={() => handleIndustryFocusBlur(false)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.stopPropagation();
                          e.preventDefault();
                          handleAddIndustry(industryName);
                        }
                      }}
                    />

                    <Button
                      ml="2"
                      colorScheme="buttonPrimary"
                      onClick={() => handleAddIndustry(industryName)}
                    >
                      Add
                    </Button>
                  </HStack>
                  {error?.industryError && (
                    <Text fontSize={"sm"} color={"red.500"}>
                      {error?.industryError}
                    </Text>
                  )}
                  {showJobIndustriesResult &&
                    jobIndustryResult?.length >= 1 && (
                      <VStack
                        height="100px"
                        overflowY="scroll"
                        position="absolute"
                        top="72px"
                        zIndex="50"
                        bg="white"
                        border="1px solid #F1F0F9"
                        borderRadius="10px"
                        w="70%"
                        boxShadow="0px 8px 32px 0px rgba(78, 48, 123, 0.04)"
                        padding="5px 0px"
                      >
                        {jobIndustryResult.map((data: any, index: number) => (
                          <Flex
                            w="full"
                            p="5px"
                            borderBottom="1px solid #eae8e8"
                            onClick={() => handleAddIndustry(data.name)}
                            _hover={{ bg: "#F1F0F9", color: "black" }}
                            key={index}
                            cursor="pointer"
                          >
                            <Text ms="4px">{data.name}</Text>
                          </Flex>
                        ))}
                      </VStack>
                    )}
                </FormControl>

                <Flex gap={"10px"}>
                  {industriesList.map((Industry: string, index: number) => (
                    <Tag key={index}>
                      <TagLabel>{Industry}</TagLabel>
                      <TagCloseButton
                        onClick={() => handleRemoveIndustry(index)}
                      />
                    </Tag>
                  ))}
                </Flex>
                {formErrorIndustry && (
                  <Text color="red.500" fontSize="sm">
                    {formErrorIndustry}
                  </Text>
                )}
              </Stack>

              <FormControl>
                <FormLabel fontWeight={"bold"} fontSize="md" mt={5}>
                  Job Description
                </FormLabel>
                <Textarea
                  placeholder="Enter job description"
                  w={{ base: "100%", md: "70%" }}
                  value={watch("jobDescription")}
                  {...register("jobDescription")}
                />
              </FormControl>
              <Button
                colorScheme="buttonPrimary"
                mt="4"
                type="submit"
                // onClick={handleSubmit(updateHandler)}
              >
                Save
              </Button>
            </Box>
          </form>
        </Card>
      </GenericModal>
    </>
  );
};

export default EditProfileExperience;
