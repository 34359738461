import { Button, CheckboxGroup, HStack, Text } from "@chakra-ui/react";
interface ICheckBoxButtonProps {
  options: any[];
  selectedOptions: any[];
  width?: string;
  handleOptionSelection: (option: any) => void;
}

const CheckBoxButton = ({
  options,
  selectedOptions,
  handleOptionSelection,
  width = "auto",
}: ICheckBoxButtonProps) => {
  return (
    <CheckboxGroup colorScheme="buttonPrimary">
      <HStack flexWrap="wrap">
        {options.map((option: any) => (
          <Button
            mt="2 !important"
            mr="2 !important"
            ml="0 !important"
            key={option}
            color={selectedOptions?.includes(option) ? "white" : "#5867DD"}
            border="1px solid #5867DD"
            bg={selectedOptions?.includes(option) ? "#5867DD" : "white"}
            _hover={{
              bg: selectedOptions?.includes(option) ? "#5867DD" : "white",
            }}
            onClick={() => handleOptionSelection(option)}
            fontSize="13px"
            w={width}
            whiteSpace="normal"
            textAlign="start"
          >
            {option}
          </Button>
        ))}
      </HStack>
    </CheckboxGroup>
  );
};

export default CheckBoxButton;
