import { useEffect, useState } from "react";

import { useFetchCandidatesLiked } from "../../../service/candidate.service";

const useTalent = () => {
  const [itemOffset, setItemOffset] = useState(0);

  const [initialFetch, setInitialFetch] = useState(true);
  const {
    isLoading: candidateLoading,
    data: candidateList,
    refetch: refetchCandidate,
  } = useFetchCandidatesLiked(itemOffset);

  useEffect(() => {
    if (initialFetch) {
      refetchCandidate();
      setInitialFetch(false);
    } else {
      const delayDebounceFn = setTimeout(() => {
        refetchCandidate();
      }, 1000);

      return () => {
        clearTimeout(delayDebounceFn);
      };
    }
  }, [initialFetch]);
  useEffect(() => {
    refetchCandidate();
  }, [itemOffset]);

  return {
    candidateList,
    candidateLoading,
    itemOffset,
    setItemOffset,
  };
};

export default useTalent;
