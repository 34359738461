import {
  Avatar,
  Card,
  CardBody,
  CardHeader,
  Flex,
  HStack,
  Heading,
  Icon,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";

import { BsToggles } from "react-icons/bs";
import { useFetch } from "../../../../utils/useFetch";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment-timezone";

const MyTasks = () => {
  const [notificationsList, setNotificationsList] = useState<any>([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const limit = 500;
  const fetchNotifications = () => {
    if (loading) return;
    else {
      setLoading(true);
      try {
        useFetch
          .get(
            `/users/notification/get-all-notifications?page=${page}&limit=${limit}`
          )
          .then((res: any) => {
            const newData = res?.data?.data || [];
            setNotificationsList(newData);
            // const uniqueNotifications: any = [];
            // console.log("0-000 notification LIST", notificationsList);

            // for (const notification of newData) {
            //   const isUnique = !notificationsList.some(
            //     (existingNotification: any) =>
            //       existingNotification.id === notification.id
            //   );
            //   console.log(
            //     "0-000 NOTIFICATION ID",
            //     notification.id,
            //     "is UNIQUE",
            //     isUnique,
            //     "NOTIFICATION LIST",
            //     notificationsList
            //   );

            //   // If it's unique, add it to the uniqueNotifications array
            //   if (isUnique) {
            //     uniqueNotifications.push(notification);
            //   }
            //   console.log("0-0 uniqueNotifications --", uniqueNotifications);
            // }

            // Set unique notifications in the state
            // setNotificationsList((prevList: any) => [newData]);
            // setHasMore(page < res?.data?.totalPages);
            // page < res?.data?.totalPages && setPage((prevPage) => prevPage + 1);
          });
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <>
      <Card
        h="calc(30% - 34px)"
        w="100%"
        mb={{ base: "4 !important", lg: "0 !important" }}
        overflow="auto"
      >
        <CardHeader pb="0px !important">
          <Heading fontSize={"16px"} as={"h4"}>
            Notifications
          </Heading>
          {/* <Text color={"gray"} fontSize="sm">
            Total 7 tasks in backlog
          </Text> */}
        </CardHeader>
        <CardBody overflow="auto">
          {/* <InfiniteScroll
            dataLength={notificationsList?.length}
            next={fetchNotifications}
            hasMore={hasMore}
            loader={<h4 style={{ textAlign: "center" }}>Loading...</h4>}
            // endMessage={
            //   <p style={{ textAlign: "center" }}>
            //     <b>Yay! You have seen it all</b>
            //   </p>
            // }
            style={{ width: "100%" }}
          > */}
          <VStack w="full" spacing={3}>
            {notificationsList.map((item: any) => {
              const local = moment.utc(item?.createdAt).local().fromNow();
              return (
                <HStack
                  align="center"
                  borderLeft="4px solid #bebfc1"
                  paddingStart="4"
                  w="full"
                  justify="space-between"
                  key={item.id}
                >
                  <Flex gap="2" flex={1}>
                    <Avatar
                      src={item?.candidateProfilePicture}
                      height="40px"
                      width="40px"
                      borderRadius="full"
                    />
                    <VStack align={"baseline"}>
                      <Text
                        fontSize={"sm"}
                        mt="0px !important"
                        fontWeight={"bold"}
                      >
                        {item?.label}
                      </Text>
                      <HStack mt="0px !important">
                        <Text fontSize={"sm"} color="gray">
                          {item?.notificationMessage}
                        </Text>
                      </HStack>
                    </VStack>
                  </Flex>
                  <Text fontSize={"sm"} color="gray">
                    {local}
                  </Text>
                </HStack>
              );
            })}
          </VStack>
          {/* </InfiniteScroll> */}
        </CardBody>
      </Card>
    </>
  );
};

export default MyTasks;
