import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import { useToast } from "@chakra-ui/react";

import { useFetch } from "../utils/useFetch";

const addSchedule = async (data: any) => {
  const { data: response } = await useFetch.post(
    "/jobs/interview/schedule",
    data
  );

  return response;
};
export const useAddSchedule = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation(addSchedule, {
    onSuccess: (data: any) => {
      queryClient.invalidateQueries("candidate-fetch-applied2");
      fetchEmployerSchedule();
      toast({
        title: "Interview Scheduled Successfully",
        status: "success",
        variant: "top-accent",
        isClosable: true,
      });
    },
    onError: (error: any) => {
      toast({
        title: "Failed to Schedule Interview",
        description: `${error.response.data.message}`,
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });
    },
  });
};
const addReschedule = async (data: any) => {
  const { data: response } = await useFetch.post(
    "/jobs/interview/reschedule-interview",
    data
  );

  return response;
};
export const useReSchedule = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation(addReschedule, {
    onSuccess: (data: any) => {
      queryClient.invalidateQueries("employer-fetch-schedules");
      toast({
        title: "Rescheduled successfully",
        status: "success",
        variant: "top-accent",
        isClosable: true,
      });
      navigate(`/interview`);
    },
    onError: (error: any) => {
      toast({
        title: "Failed to Schedule meeting",
        description: `${error.response.data.message}`,
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });
    },
  });
};
const addRescheduleRequest = async (data: any) => {
  const { data: response } = await useFetch.post(
    "/jobs/interview/request-reschedule-interview",
    data
  );

  return response;
};
export const useReScheduleRequest = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation(addRescheduleRequest, {
    onSuccess: (data: any) => {
      queryClient.invalidateQueries("candidate-fetch-schedules-by-id");
      toast({
        title: "Added  successfully",
        status: "success",
        variant: "top-accent",
        isClosable: true,
      });
    },
    onError: (error: any) => {
      toast({
        title: "Failed to Schedule meeting",
        description: `${error.response.data.message}`,
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });
    },
  });
};
const addWidrawSchedule = async (data: any) => {
  const { data: response } = await useFetch.delete(
    `/jobs/interview/delete-schedule?jobId=${data?.jobId}&candidateId=${data?.candidateId}`
  );

  return response;
};
export const useWidrawRequest = (data: any) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation(addWidrawSchedule, {
    onSuccess: (data: any) => {
      queryClient.invalidateQueries("candidate-fetch-schedules-by-id");
      toast({
        title: "Interview withdraw successfully",
        status: "success",
        variant: "top-accent",
        isClosable: true,
      });
    },
    onError: (error: any) => {
      toast({
        title: "Failed to Schedule meeting",
        description: `${error.response.data.message}`,
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });
    },
  });
};
const fetchEmployerSchedule = async () => {
  const data = localStorage.getItem("data");
  const userType = data ? JSON.parse(data)?.userType : null;

  if (userType !== "TALENT") {
    const { data: response } = await useFetch.get(
      `/jobs/interview/scheduled-interviews`
    );

    return response;
  }
};

export const useFetchEmployerScheldules = () => {
  const toast = useToast();
  return useQuery(["employer-fetch-schedules"], () => fetchEmployerSchedule(), {
    onSuccess: (data: any) => {},
    onError: (error: any) => {
      // toast({
      //   title: "Error Getting Users",
      //   description: `Something Went`,
      //   status: "error",
      //   variant: "top-accent",
      //   isClosable: true,
      // });
    },
  });
};
const fetchCandidateSchedule = async () => {
  const { data: response } = await useFetch.get(
    `/jobs/interview/candidate-interviews`
  );

  return response;
};

export const useFetchCadidateScheldules = () => {
  const toast = useToast();
  return useQuery(
    ["candidate-fetch-schedules"],
    () => fetchCandidateSchedule(),
    {
      onSuccess: (data: any) => {},
      onError: (error: any) => {
        // toast({
        //   title: "Error Getting Interviews",
        //   description: `Something Went`,
        //   status: "error",
        //   variant: "top-accent",
        //   isClosable: true,
        // });
      },
    }
  );
};
const fetchCandidateScheduleById = async (id: string) => {
  const { data: response } = await useFetch.get(
    `jobs/interview/scheduled-interview-by-id?interviewId=${id}`
  );
  return response;
};

export const useFetchCadidateScheldulesById = (id: string) => {
  const toast = useToast();
  return useQuery(
    ["candidate-fetch-schedules-by-id"],
    () => fetchCandidateScheduleById(id),
    {
      onSuccess: (data: any) => {},
      onError: (error: any) => {
        toast({
          title: "Error Getting Interviews",
          description: `Something Went`,
          status: "error",
          variant: "top-accent",
          isClosable: true,
        });
      },
    }
  );
};
const fetchEmployerScheduleById = async (id: string) => {
  const { data: response } = await useFetch.get(
    `/jobs/interview/scheduled-interview-by-interview-id?interviewId=${id}`
  );
  return response;
};

export const useFetchEmployerScheldulesById = (id: string) => {
  const toast = useToast();
  return useQuery(
    ["employer-fetch-schedules-by-id"],
    () => fetchEmployerScheduleById(id),
    {
      onSuccess: (data: any) => {},
      onError: (error: any) => {
        toast({
          title: "Error Getting Interviews",
          description: `Something Went`,
          status: "error",
          variant: "top-accent",
          isClosable: true,
        });
      },
    }
  );
};
