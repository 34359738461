import { useDisclosure } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { userDetailSchema } from "../../../utils/validation";
import { getUserId } from "../../../utils/helper";
import AWS from "aws-sdk";
import {
  useAddProfileImage,
  useEmployerProfile,
} from "../../../service/employerProfile.service";
import { useUserStore } from "../../../store/useUser";
import { useToast } from "@chakra-ui/react";

const useSettings = () => {
  const { setUser, user } = useUserStore();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const S3_BUCKET = process.env.REACT_APP_S3_BUCKET || "";
  const REGION = process.env.REACT_APP_REGION;

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_accessKeyId,
    secretAccessKey: process.env.REACT_APP_secretAccessKey,
  });
  const s3 = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });
  const folder = "profile-images";

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    trigger,
    formState: { errors, isSubmitted },
  } = useForm({
    mode: "all",
    shouldUnregister: false,
    defaultValues: {
      companyName: "",
      companyWebsite: "",
      fullName: "",
      title: "",
      roleName: "",
      email: "",
      linkedinUrl: "",
      calandarUrl: "",
    },
    resolver: yupResolver(userDetailSchema),
  });

  const userId = getUserId();
  const {
    isLoading: profileLoading,
    data: profileData,
    isSuccess: profileSuccess,
  } = useEmployerProfile(userId);
  const {
    mutate: profileImageMutation,
    isLoading: profileImageLoading,
    data: profileImage,
  } = useAddProfileImage();

  const [profilePicture, setProfilePicture] = useState<string>("");
  const [profileLogoLoading, setProfileLogoLoading] = useState<boolean>(false);
  const [companyLogo, setCompanyLogo] = useState<string>("");
  const [companyLogoLoading, setCompanyLogoLoading] = useState<boolean>(false);
  const [profileVideo, setCompanyProfile] = useState<string>("");
  const fileInputRef: any = useRef<HTMLInputElement>(null);
  const fileInputRefCompany: any = useRef<HTMLInputElement>(null);
  const toast = useToast();
  useEffect(() => {
    setValue("companyName", profileData?.companyName || "");
    trigger("companyName");
    setValue("companyWebsite", profileData?.companyWebsite || "");
    trigger("companyWebsite");
    setValue("fullName", profileData?.fullName || "");
    trigger("fullName");
    setValue("title", profileData?.title || "");
    trigger("title");
    setValue("roleName", profileData?.roleName || "");
    trigger("roleName");
    setValue("email", profileData?.email || "");
    trigger("email");
    setValue("linkedinUrl", profileData?.linkedInProfile || "");
    trigger("linkedinUrl");
    setValue("calandarUrl", profileData?.calenderUrl || "");
    trigger("calandarUrl");
    setProfilePicture(profileData?.profilePicture);
    setCompanyLogo(profileData?.companyLogo);
    setCompanyProfile(profileData?.profileVideo);
  }, [profileData]);

  const handleFileInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file: any = event.target.files?.[0];
    if (!file) {
      console.error("No file selected.");
      toast({
        title: "Unsupported file type",
        description: "Please select a file",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    const fileExtension = file.name.split(".").pop().toLowerCase();
    const supportedExtensions = ["png", "jpeg", "jpg", "svg"];
    if (!supportedExtensions.includes(fileExtension)) {
      console.error(
        "Unsupported file type. Please select a PNG, JPEG, JPG, or SVG file."
      );
      toast({
        title: "Unsupported file type",
        description: "Please select a PNG, JPEG, JPG, or SVG file.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    const params = {
      Bucket: S3_BUCKET,
      Key: `${folder}/${file.name}`,
      Body: file,
    };
    try {
      setProfileLogoLoading(true);
      const upload = await s3.putObject(params).promise();
      const url = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${folder}/${file.name}`;
      setProfilePicture(url);
      profileImageMutation(url);
      setUser({ ...user, profilePicture: url });
      setProfileLogoLoading(false);
    } catch (error) {
      console.error("Error uploading file:", error);
      setProfileLogoLoading(false);
    }
  };

  const handleEditButtonClick = () => {
    fileInputRef.current?.click();
  };
  const handleFileInputChangeCompany = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file: any = event.target.files?.[0];
    const params = {
      Bucket: S3_BUCKET,
      Key: `${folder}/${file.name}`,
      Body: file,
    };
    try {
      setCompanyLogoLoading(true);
      const upload = await s3.putObject(params).promise();
      const url = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${folder}/${file.name}`;
      setCompanyLogo(url as string);
      setUser({ ...user, companyLogo: url });
      setCompanyLogoLoading(false);
    } catch (error) {
      console.log("error", error);
      setCompanyLogoLoading(false);
    }
  };

  const handleEditButtonClickCompany = () => {
    fileInputRefCompany.current?.click();
  };
  const onModalClose = () => {
    onClose();
  };
  return {
    onModalClose,
    onOpen,
    isOpen,
    register,
    handleSubmit,
    errors,
    watch,
    reset,
    handleEditButtonClick,
    handleFileInputChange,
    profilePicture,
    profileLogoLoading,
    fileInputRef,
    profileVideo,
    setCompanyProfile,
    profileData,
    profileLoading,
    companyLogo,
    companyLogoLoading,
    handleEditButtonClickCompany,
    fileInputRefCompany,
    handleFileInputChangeCompany,
    isSubmitted,
  };
};

export default useSettings;
