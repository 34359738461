import {
  Flex,
  Avatar,
  AvatarBadge,
  Text,
  HStack,
  Button,
  Icon,
  VStack,
  Heading,
  Box,
} from "@chakra-ui/react";
import { GoLocation } from "react-icons/go";
import useChat from "../useChat";
import { useUserStore } from "../../../../store/useUser";

const Header = ({ selectedUser, searchedUser }: any) => {
  const { user } = useUserStore();
  return (
    <Flex
      w="100%"
      justify={"space-between"}
      flexWrap={"wrap"}
      align={"flex-start"}
      p="6"
      pb="0"
    >
      <Flex align="center">
        <Avatar
          size="sm"
          name={searchedUser?.name}
          src={
            searchedUser?.profilePicture ||
            selectedUser?.sendTo?.id === user?.userId
              ? selectedUser?.sendBy?.profilePicture
              : selectedUser?.sendTo?.profilePicture
          }
        >
          {/* <AvatarBadge boxSize="1em" bg="green.500" /> */}
        </Avatar>

        <VStack align={"baseline"} pos="relative" ml="2" mt="0px !important">
          <Heading
            fontWeight="bold"
            color={"#646c9a"}
            fontSize={{
              base: "13px",
              md: "13px",
              lg: "14x",
              xl: "14px",
            }}
            // whiteSpace={"nowrap"}
            mb="6px !important"
            // _hover={{ color: "#5867DD", cursor: "pointer" }}
          >
            {searchedUser?.name || selectedUser?.sendTo?.id === user?.userId
              ? selectedUser?.sendBy?.name?.length > 30
                ? selectedUser?.sendBy?.name.slice(0, 30) + "..."
                : selectedUser?.sendBy?.name
              : selectedUser?.sendTo?.name?.length > 30
              ? selectedUser?.sendTo?.name.slice(0, 30) + "..."
              : selectedUser?.sendTo?.name}
          </Heading>
          {/* <Text
            fontSize={"13px"}
            fontWeight="bold"
            color={"#646c9a"}
            mt="0px !important"
          >
            Data Science @ Google
          </Text>
          <Text fontSize={"13px"} color={"#646c9a"} mt="0px !important">
            M.S., Applied Math @ UC Berkeley
          </Text>
          <Text fontSize={"13px"} color={"#646c9a"} mt="4px !important">
            <Icon as={GoLocation} color="blue.500" /> Mountain View, CA
          </Text> */}
          <HStack mt="0px !important">
            {" "}
            {/* <Box ml="1" h="8px" w="8px" borderRadius="50%" bg="green.500"></Box>
            <Text color="green.500" fontSize={"13px"} mt="0px !important">
              Active
            </Text> */}
          </HStack>
        </VStack>
      </Flex>

      {/* <HStack flexWrap={"wrap"}>
        <Button
          colorScheme="buttonPrimary"
          variant="solid"
          my="1"
          size={{ base: "xs", sm: "xs", md: "xs", "2xl": "sm" }}
        >
          Share my resume
        </Button>
      </HStack> */}
    </Flex>
  );
};

export default Header;
