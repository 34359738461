import React from "react";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Center,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Progress,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { BsThreeDots } from "react-icons/bs";
import { BiMedal } from "react-icons/bi";
import { AiFillFire } from "react-icons/ai";
import { GoLocation } from "react-icons/go";
import { useUserStore } from "../../../store/useUser";

const AppliedTalentCard = ({ data }: any) => {
  const { pathname } = useLocation();
  const { user } = useUserStore();

  const navigate = useNavigate();
  return (
    <>
      <Card maxW="lg" borderRadius={"md"} py="2" maxWidth={"100%"}>
        <Card mx="4" my="2" boxShadow={"sm"} border="1px solid #e3e3e3">
          <Flex p="2">
            <Flex flex="1" gap="4" alignItems="center" flexWrap="wrap">
              <Avatar name="" src={data?.companyLogo} size={"lg"} />

              <Box>
                <Heading size="sm" fontWeight={"600"}>
                  {data?.jobName}
                </Heading>
                <Heading fontSize={"xs"} color={"#646c9a"}>
                  {user?.fullName}
                </Heading>
              </Box>
            </Flex>
          </Flex>
        </Card>
        <Flex justifyContent={"flex-end"} h="24px" pr="4">
          {" "}
          <Menu>
            <MenuButton
              as={IconButton}
              variant="ghost"
              colorScheme="gray"
              aria-label="See menu"
              my="1"
              icon={<BsThreeDots size={"22px"} color="#5867DD" />}
            />
            <MenuList>
              {/* <MenuItem>Schedule an Interview</MenuItem> */}
              <MenuItem onClick={() => navigate(`/chat`, { state: data })}>
                Message Candidate
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
        <CardHeader>
          <Flex>
            <Flex flex="1" gap="4" alignItems="center">
              <Avatar name="" src={data?.profilePicture} size={"xl"} />

              <VStack align={"baseline"} pos="relative">
                <Heading
                  size="lg"
                  color="#48465b"
                  fontWeight={"500"}
                  fontSize={{
                    base: "16.8px",
                    md: "16.8px",
                    lg: "18.2px",
                    xl: "18.2px",
                  }}
                  whiteSpace={"nowrap"}
                  mb="6px !important"
                  _hover={{ color: "#5867DD", cursor: "pointer" }}
                >
                  {data?.fullName}
                </Heading>
                <Text
                  fontSize={"13px"}
                  fontWeight="bold"
                  color={"#646c9a"}
                  mt="0px !important"
                >
                  {data?.jobTitle}{" "}
                  {data?.companyName && `@ ${data?.companyName}`}
                </Text>
                <Text fontSize={"13px"} color={"#646c9a"} mt="0px !important">
                  {data?.latestDegree}{" "}
                  {data?.schoolName && `@ ${data?.schoolName}`}
                </Text>
                <Text fontSize={"13px"} color={"#646c9a"} mt="6px !important">
                  <Icon as={GoLocation} color="blue.500" />
                  {data?.userLocation?.city}, {data?.userLocation?.state}{" "}
                </Text>
              </VStack>
            </Flex>

            {/* <Box
                h="50px"
                w="40px"
                bg="#fd397a"
                borderBottomRightRadius={"md"}
                borderBottomLeftRadius={"md"}
                pos={"absolute"}
                top="0"
                boxShadow={"rgba(0, 0, 0, 0.35) 0px 5px 15px"}
              >
                <Center h="full">
                  <Icon as={AiFillFire} fontSize="24px" color="white" />
                </Center>
              </Box> */}
          </Flex>
        </CardHeader>
        <CardBody pt="0">
          <VStack align={"baseline"} w="full">
            {/* <HStack justifyContent={"space-between"} mt="2" w="full" maxW="80%">
              <Text fontSize={"13px"} color={"#646c9a"}>
                80% match
              </Text>
              <Text fontSize={"13px"} color={"#646c9a"}>
                Req: 55647BR
              </Text>
            </HStack>
            <Progress
              w="full"
              maxW="80%"
              value={80}
              size="sm"
              colorScheme="progressBar"
              borderRadius="20px"
              mt={"0px !important"}
            /> */}
            <HStack w={"full"} alignItems="flex-start" mt="4 !important">
              <HStack flexWrap={"wrap"}>
                <Icon as={BiMedal} fontSize="30px" color="gray.500" mt="0.5" />
                {data?.interestedJobFunctions?.map((item: string) => (
                  <Badge
                    bg={"#ffb822"}
                    fontWeight="100"
                    px="1.5"
                    py="0.5"
                    borderRadius={"xl"}
                    fontSize="12px"
                    mt="0.5 !important"
                    mr="0 !important"
                    ml="1 !important"
                    key={item}
                    color={"#48465b"}
                    textTransform={"capitalize"}
                  >
                    {item}
                  </Badge>
                ))}
              </HStack>
            </HStack>

            {/* <Text
              fontSize={"13"}
              mt="4 !important"
              border="3px solid rgb(239, 239, 239)"
              p="2"
              color={"#646c9a"}
            >
              With Chakra UI, I wanted to sync the speed of development with the
              speed of design.
            </Text> */}
          </VStack>
        </CardBody>

        <CardFooter justify="space-between" flexWrap="wrap">
          <Button
            flex="1"
            variant="outline"
            w="full"
            // colorScheme="buttonPrimary"
            color={"#5867DD"}
            borderColor={"#5867DD"}
            fontSize={"13px"}
            py="6"
            onClick={() =>
              navigate(`${pathname}/${data?.userId}`, { state: data })
            }
            _hover={{ bg: "#5867DD", color: "white" }}
            maxW={"500px"}
          >
            View Details
          </Button>
        </CardFooter>
      </Card>
    </>
  );
};

export default AppliedTalentCard;
