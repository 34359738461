import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getUserId } from "../../../utils/helper";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { firebase_db } from "../../../firebase";
import { useUserStore } from "../../../store/useUser";

const useChat = () => {
  const { state } = useLocation();
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [chatId, setChatId] = useState("");
  const { user } = useUserStore();

  useEffect(() => {
    const updateChatRoom = async () => {
      if (state) {
        const sortedIds = [
          user?.userId,
          state?.userId ? state?.userId : state?.createdBy,
        ]
          .map((id) => id?.toLowerCase())
          .sort();
        const newUserId = sortedIds.join("_");
        const chatRoomRef = doc(firebase_db, "ChatRooms", newUserId);

        setSelectedUser({
          sendTo: {
            name: state?.fullName ? state?.fullName : state?.employerName,
            id: state?.userId ? state?.userId : state?.createdBy,
            profilePicture: state?.profilePicture,
          },
          sendBy: {
            name: user?.fullName,
            id: user?.userId,
            profilePicture: user?.profilePicture,
          },
          userId: state?.userId ? state?.userId : state?.createdBy,
        });
        try {
          const chatRoomDoc = await getDoc(chatRoomRef);
          if (chatRoomDoc.exists()) {
            await updateDoc(chatRoomRef, {
              unreadCount: 0,
            });
          }
        } catch (error) {
          console.error("Error updating ChatRoom:", error);
        }
      }
    };

    updateChatRoom();
  }, [state]);

  const handleSelectedUser = async (value: any) => {
    const chatRoomRef = doc(firebase_db, "ChatRooms", value?.chatId);
    const id = value?.members.find((item: string) => item !== user?.userId);
    const updatedCurrentUser = {
      id: user?.userId,
      name: user?.fullName,
      profilePicture: user?.profilePicture,
    };
    setSelectedUser({
      sendTo:
        value?.sendTo?.id === user?.userId ? updatedCurrentUser : value?.sendTo,
      sendBy:
        value?.sendBy?.id === user?.userId ? updatedCurrentUser : value?.sendBy,
      userId: id,
      profilePicture: value?.profilePicture,
    });
    await updateDoc(chatRoomRef, {
      unreadCount: 0,
    });
  };
  const handleSelectedUserSearched = async (value: any) => {
    setSelectedUser({
      sendTo: {
        name: value?.fullName,
        id: value?.userId,
        profilePicture: value?.profilePicture,
      },
      sendBy: {
        name: user?.fullName,
        id: user?.userId,
        profilePicture: user?.profilePicture,
      },
      userId: value?.userId,
    });
  };

  return {
    state,
    selectedUser,
    handleSelectedUser,
    handleSelectedUserSearched,
  };
};

export default useChat;
