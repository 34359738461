import React from "react";
import InterviewCard from "./interviewCard";
import {
  Box,
  Card,
  CardBody,
  Flex,
  Grid,
  Heading,
  Stack,
} from "@chakra-ui/react";
import useInterview from "./useInterview";
import NoDataCard from "../../common/nodata";
import LoadingScreen from "../../common/loadingScreen";

const InterviewPage = () => {
  const { fetchLoading, scheduleList } = useInterview();
  return (
    <>
      <Card boxShadow={"none"} borderRadius="none" w="full" bg="#f7f6f8">
        <CardBody py="4 !important">
          <Stack
            direction="row"
            spacing={4}
            align="center"
            justify="flex-start"
          >
            <Heading size="sm" color={"#959cb6"} _hover={{ color: "#5D78FF" }}>
              Your Interviews
            </Heading>
          </Stack>
        </CardBody>
      </Card>

      {fetchLoading ? (
        <LoadingScreen />
      ) : scheduleList?.length ? (
        <Flex w="full" justify={"center"} m="auto" p="4" py="8" bg="#f0f1f7">
          <Grid
            templateColumns={[
              "1fr",
              "1fr",
              "1fr",
              "repeat(2, 1fr)",
              "repeat(2, 1fr)",
              "repeat(3, 1fr)",
            ]}
            gap={4}
            overflowX="auto"
            width={["100%", "100%", "100%", "100%", "100%", "100%"]}
            sx={{
              "&::-webkit-scrollbar": {
                height: "4px",
              },
              "&::-webkit-scrollbar-track": {
                background: "transparent",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "gray.400",
                borderRadius: "full",
              },
            }}
          >
            {scheduleList?.map((item: any, index: number) => {
              return <InterviewCard key={index} data={item} />;
            })}
          </Grid>
        </Flex>
      ) : (
        <Box p="4">
          <NoDataCard message={"No interview schedule."} />
        </Box>
      )}
    </>
  );
};

export default InterviewPage;
