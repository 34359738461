import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Icon,
  Text,
  Tooltip,
  Tfoot,
} from "@chakra-ui/react";
import { FaFire } from "react-icons/fa";

import ReportProgressBar from "../../../../utils/report-progress";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import "../report.css";

const ReportTable = ({
  data,
  handlePageClick,
  pageCount,
  itemOffset,
  tab,
}: any) => {
  const navigate = useNavigate();

  return (
    <>
      <TableContainer w="full">
        <Table color="#212529">
          <Thead className="report-table">
            <Tr gap="2px">
              <Th maxW="130px" whiteSpace="normal">
                Job Name
              </Th>
              <Th maxW="130px" whiteSpace="normal">
                Candidate Name
              </Th>
              <Th maxW="70px" whiteSpace="normal"></Th>
              <Th maxW="130px" whiteSpace="normal">
                Submission
              </Th>
              <Th maxW="130px" whiteSpace="normal">
                Under Review
              </Th>
              <Th maxW="130px" whiteSpace="normal">
                HR Screen
              </Th>
              <Th maxW="130px" whiteSpace="normal">
                Interview - Pending Scheduling
              </Th>
              <Th maxW="130px" whiteSpace="normal">
                Interview - Scheduled
              </Th>
              <Th maxW="130px" whiteSpace="normal">
                Interview - Pending Feedback
              </Th>
              <Th maxW="130px" whiteSpace="normal">
                Onsite - Pending Scheduling
              </Th>
              <Th maxW="130px" whiteSpace="normal">
                Onsite - Scheduled
              </Th>
              <Th maxW="130px" whiteSpace="normal">
                Onsite - Pending Feedback
              </Th>
              <Th maxW="130px" whiteSpace="normal">
                Offer - Pending
              </Th>
              <Th maxW="130px" whiteSpace="normal">
                Offer - Extended
              </Th>
              <Th maxW="130px" whiteSpace="normal">
                Offer - Accepted
              </Th>
              <Th maxW="130px" whiteSpace="normal">
                Hired
              </Th>
              {tab === "Archived" && (
                <Th maxW="130px" whiteSpace="normal">
                  Archived
                </Th>
              )}
              <Th maxW="130px" whiteSpace="normal">
                Days in Pipeline
              </Th>
            </Tr>
          </Thead>
          <Tbody fontSize="13px">
            {Array.isArray(data) &&
              data?.map((item: any, index: number) => {
                return (
                  <Tr gap="2px" key={index}>
                    <Td maxWidth="150px">
                      <Tooltip hasArrow label={item?.jobTitle}>
                        <Text>
                          {item?.jobTitle?.length > 18
                            ? item?.jobTitle?.slice(0, 16) + ".."
                            : item?.jobTitle}
                        </Text>
                      </Tooltip>
                    </Td>
                    <Td maxWidth="150px">
                      <Tooltip hasArrow label={item?.fullName}>
                        <Text
                          _hover={{ color: "blue.500" }}
                          cursor="pointer"
                          onClick={() =>
                            navigate(`/liked/${item?.userId}`, {
                              state: {
                                userId: item?.userId,
                                jobId: item?.jobId,
                              },
                            })
                          }
                        >
                          {item?.fullName?.length > 18
                            ? item?.fullName?.slice(0, 16) + ".."
                            : item?.fullName}
                        </Text>
                      </Tooltip>
                    </Td>
                    <Td maxW="50px">
                      {item?.isActivelyInterviewing && (
                        <Icon as={FaFire} color="#fd397a" fontSize="16px" />
                      )}
                    </Td>
                    <Td maxW="100px" textAlign="center">
                      {item?.submissionDate
                        ? moment(new Date(item?.submissionDate)).format(
                            "DD/MM/YY"
                          )
                        : "--"}
                    </Td>
                    <Td maxW="100px" textAlign="center">
                      {item?.underReview ? (
                        <ReportProgressBar
                          value={item?.underReview?.percentage}
                          noOfDays={item?.underReview?.days}
                          color={item?.underReview?.color}
                        />
                      ) : (
                        "  ---"
                      )}
                    </Td>
                    <Td maxW="100px" textAlign="center">
                      {item?.hrScreen ? (
                        <ReportProgressBar
                          value={item?.hrScreen?.percentage}
                          noOfDays={item?.hrScreen?.days}
                          color={item?.hrScreen?.color}
                        />
                      ) : (
                        "  ---"
                      )}
                    </Td>
                    <Td maxW="100px" textAlign="center">
                      {item?.interviewPendingScheduling ? (
                        <ReportProgressBar
                          value={item?.interviewPendingScheduling?.percentage}
                          noOfDays={item?.interviewPendingScheduling?.days}
                          color={item?.interviewPendingScheduling?.color}
                        />
                      ) : (
                        "  ---"
                      )}
                    </Td>
                    <Td maxW="100px" textAlign="center">
                      {item?.interviewScheduled ? (
                        <ReportProgressBar
                          value={item?.interviewScheduled?.percentage}
                          noOfDays={item?.interviewScheduled?.days}
                          color={item?.interviewScheduled?.color}
                        />
                      ) : (
                        "  ---"
                      )}
                    </Td>
                    <Td maxW="100px" textAlign="center">
                      {item?.interviewPendingFeedback ? (
                        <ReportProgressBar
                          value={item?.interviewPendingFeedback?.percentage}
                          noOfDays={item?.interviewPendingFeedback?.days}
                          color={item?.interviewPendingFeedback?.color}
                        />
                      ) : (
                        "  ---"
                      )}
                    </Td>
                    <Td maxW="100px" textAlign="center">
                      {item?.onsitePendingScheduling ? (
                        <ReportProgressBar
                          value={item?.onsitePendingScheduling?.percentage}
                          noOfDays={item?.onsitePendingScheduling?.days}
                          color={item?.onsitePendingScheduling?.color}
                        />
                      ) : (
                        "  ---"
                      )}
                    </Td>
                    <Td maxW="100px" textAlign="center">
                      {item?.onsiteScheduled ? (
                        <ReportProgressBar
                          value={item?.onsiteScheduled?.percentage}
                          noOfDays={item?.onsiteScheduled?.days}
                          color={item?.onsiteScheduled?.color}
                        />
                      ) : (
                        "  ---"
                      )}
                    </Td>
                    <Td maxW="100px" textAlign="center">
                      {item?.onsitePendingFeedback ? (
                        <ReportProgressBar
                          value={item?.onsitePendingFeedback?.percentage}
                          noOfDays={item?.onsitePendingFeedback?.days}
                          color={item?.onsitePendingFeedback?.color}
                        />
                      ) : (
                        "  ---"
                      )}
                    </Td>
                    <Td maxW="100px" textAlign="center">
                      {item?.offerPending ? (
                        <ReportProgressBar
                          value={item?.offerPending?.percentage}
                          noOfDays={item?.offerPending?.days}
                          color={item?.offerPending?.color}
                        />
                      ) : (
                        "  ---"
                      )}
                    </Td>
                    <Td maxW="100px" textAlign="center">
                      {item?.offerExtended ? (
                        <ReportProgressBar
                          value={item?.offerExtended?.percentage}
                          noOfDays={item?.offerExtended?.days}
                          color={item?.offerExtended?.color}
                        />
                      ) : (
                        "  ---"
                      )}
                    </Td>

                    <Td maxW="100px" textAlign="center">
                      {item?.offerAccepted ? (
                        <ReportProgressBar
                          value={item?.offerAccepted?.percentage}
                          noOfDays={item?.offerAccepted?.days}
                          color={item?.offerAccepted?.color}
                        />
                      ) : (
                        "  ---"
                      )}
                    </Td>
                    <Td maxW="100px" textAlign="center">
                      {item?.hiredStarted ? (
                        <ReportProgressBar
                          value={item?.hiredStarted?.percentage}
                          noOfDays={item?.hiredStarted?.days}
                          color={item?.hiredStarted?.color}
                        />
                      ) : (
                        "  ---"
                      )}
                    </Td>
                    {tab === "Archived" && (
                      <Td maxW="100px" textAlign="center">
                        {item?.archived
                          ? `${item?.archived?.days} days`
                          : "0 days"}
                      </Td>
                    )}
                    <Td maxW="100px" textAlign="center">
                      {item?.daysInPipeline} days
                    </Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </TableContainer>
      <ReactPaginate
        nextLabel="Next >"
        onPageChange={handlePageClick}
        forcePage={itemOffset}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel="< Previous"
        breakLabel="..."
        previousClassName={`page-item${itemOffset === 0 ? " disabled" : ""}`}
        nextClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName={"container"}
        previousLinkClassName={"page"}
        breakClassName={"page"}
        nextLinkClassName={"page"}
        pageClassName={"page"}
        disabledClassName={"disabled"}
        activeClassName={"active-venu-button"}
      />
    </>
  );
};

export default ReportTable;
