import { useDisclosure } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  useFetchCadidateScheldulesById,
  useReScheduleRequest,
} from "../../../service/schedule.service";
import { useLocation, useNavigate } from "react-router-dom";

const useInterviewReschedule = () => {
  const navigate = useNavigate();
  const [reason, setReason] = useState<any>([]);
  const [valueReason, setValueReason] = useState("");
  const [errors, setErrors] = useState({
    reason: "",
    valueReason: "",
  });
  const { state } = useLocation();
  const {
    mutate: scheduleMutation,
    isLoading: scheduleLoading,
    data: scheduleRequest,
  } = useReScheduleRequest();

  const { isLoading: fetchLoading, data: scheduleData } =
    useFetchCadidateScheldulesById(state);
  useEffect(() => {
    if (scheduleData) {
      setSelectedtimes(scheduleData?.selectedTimes);
      setSelectedtimes2(scheduleData?.selectedTimes2);
      setAnotherDay(scheduleData?.anotherDay);
      setValue("date", new Date(scheduleData?.date));
      trigger("date");
      setValue(
        "date2",
        scheduleData?.date2 ? new Date(scheduleData?.date2) : new Date()
      );
      trigger("date2");
    }
  }, [scheduleData]);
  const {
    watch,
    setValue,
    trigger,
    reset,
    formState: { isSubmitted },
  } = useForm({
    defaultValues: {
      date: new Date(),
      date2: new Date(),
    },
  });
  const [reschedule, setReschedule] = useState(false);
  const [copied, setCopied] = useState(false);
  const [selectedTimes2, setSelectedtimes2] = useState<any>([]);
  const [isSubmitTimes, setIsSubmitTimes] = useState(false);
  const [isSubmitTimes2, setIsSubmitTimes2] = useState(false);
  const [anotherDay, setAnotherDay] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const onModalClose = () => {
    reset();
    setReschedule(false);
    setSelectedtimes([]);
    setIsSubmitTimes(false);
    onClose();
  };

  const onChangeDate = (date: any) => {
    setValue("date", date);
    trigger("date");
  };
  const [selectedTimes, setSelectedtimes] = useState<any>([]);

  const handleOptionTimes = (option: any) => {
    if (selectedTimes.includes(option)) {
      setSelectedtimes((prevSelectedOptions: any) =>
        prevSelectedOptions.filter((o: any) => o !== option)
      );
    } else {
      setSelectedtimes((prevSelectedOptions: any) => [
        ...prevSelectedOptions,
        option,
      ]);
    }
  };
  useEffect(() => {
    if (copied) {
      const timeoutId = setTimeout(() => {
        setCopied(false);
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [copied]);
  const toggleAnotherDay = () => {
    setAnotherDay(!anotherDay);
  };
  const onChangeDate2 = (date: any) => {
    setValue("date2", date);
    trigger("date2");
  };

  const handleOptionTimes2 = (option: any) => {
    if (selectedTimes2.includes(option)) {
      setSelectedtimes2((prevSelectedOptions: any) =>
        prevSelectedOptions.filter((o: any) => o !== option)
      );
    } else {
      setSelectedtimes2((prevSelectedOptions: any) => [
        ...prevSelectedOptions,
        option,
      ]);
    }
  };
  const handleValidation = () => {
    let errors: any = {
      reason: "",
      valueReason: "",
    };

    let formIsValid = true;

    if (!reason?.length) {
      formIsValid = false;
      errors.reason = "Please select reason for rescheduling";
    }
    if (reason === "Other" && valueReason === "") {
      formIsValid = false;
      errors.valueReason = "Please Enter reason for rescheduling";
    }

    setErrors(errors);
    return formIsValid;
  };
  const onSubmit = () => {
    if (!handleValidation()) {
      return false;
    }

    const reasonData = reason === "Other" ? valueReason : reason;
    scheduleMutation(
      {
        interviewScheduleId: scheduleData?.interviewScheduleId,
        reason: reasonData,
      },
      {
        onSuccess: (data) => {
          navigate("/interview");
        },
      }
    );
  };

  return {
    isOpen,
    onOpen,
    onModalClose,
    onChangeDate,
    handleOptionTimes,
    selectedTimes,
    watch,
    isSubmitted,
    setReschedule,
    reschedule,
    isSubmitTimes,
    setIsSubmitTimes,
    setCopied,
    copied,
    scheduleData,
    fetchLoading,
    scheduleLoading,
    reason,
    setReason,
    valueReason,
    setValueReason,
    onSubmit,
    navigate,
    anotherDay,
    setAnotherDay,
    isSubmitTimes2,
    setIsSubmitTimes2,
    selectedTimes2,
    setSelectedtimes2,
    onChangeDate2,
    handleOptionTimes2,
    errors,
  };
};

export default useInterviewReschedule;
