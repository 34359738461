import { useMutation, useQuery, useQueryClient } from "react-query";

import { useToast } from "@chakra-ui/react";

import { useFetch } from "../utils/useFetch";
import { useUserStore } from "../store/useUser";

const fetchEmployerProfile = async (userId: any) => {
  const { data: response } = await useFetch.get(
    `/users/employer/employer-profile?userId=${userId}`
  );

  return response;
};

export const useEmployerProfile = (data: any) => {
  const { user, setUser } = useUserStore();
  const toast = useToast();
  return useQuery(
    ["employerProfile-fetch", data],
    () => fetchEmployerProfile(data),
    {
      onSuccess: (data: any) => {
        setUser({ ...user, profilePicture: data?.profilePicture });
      },
      onError: (error: any) => {
        toast({
          title: "Error Getting Profile",
          description: `Something Went`,
          status: "error",
          variant: "top-accent",
          isClosable: true,
        });
      },
    }
  );
};

const addProfile = async (data: any) => {
  const { data: response } = await useFetch.post(
    `/users/employer/employer-profile`,
    data
  );

  return response;
};
export const useAddProfile = () => {
  const queryClient = useQueryClient();
  const toast = useToast();
  return useMutation(addProfile, {
    onSuccess: (data: any) => {
      queryClient.invalidateQueries("employerProfile-fetch");
      toast({
        title: "Profile updated successfully",
        status: "success",
        variant: "top-accent",
        isClosable: true,
      });
    },
    onError: (error: any) => {
      toast({
        title: "Failed to update profile",
        description: `${error.response.data.message}`,
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });
    },
  });
};

const addProfileImage = async (data: any) => {
  const { data: response } = await useFetch.post(
    `users/employer/employ-profile-picture`,
    { profilePicture: data }
  );

  return response;
};
export const useAddProfileImage = () => {
  // const queryClient = useQueryClient();
  const toast = useToast();
  return useMutation(addProfileImage, {
    onSuccess: (data: any) => {
      //   queryClient.invalidateQueries("jobs-fetch");
      toast({
        title: "Image added successfully",
        status: "success",
        variant: "top-accent",
        isClosable: true,
      });
    },
    onError: (error: any) => {
      toast({
        title: "Failed to add image",
        description: `${error.response.data.message}`,
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });
    },
  });
};
const addProfileVideo = async (data: any) => {
  const { data: response } = await useFetch.post(
    `users/employer/employ-profile-video`,
    { profileVideo: data }
  );

  return response;
};
export const useAddProfileVideo = () => {
  const toast = useToast();
  return useMutation(addProfileVideo, {
    onSuccess: (data: any) => {
      toast({
        title: "Video uploaded successfully",
        status: "success",
        variant: "top-accent",
        isClosable: true,
      });
    },
    onError: (error: any) => {
      toast({
        title: "Failed to upload video",
        description: `${error.response.data.message}`,
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });
    },
  });
};
