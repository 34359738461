import { Progress } from "@chakra-ui/react";
import AddExperience from "../../../components/candidate/signupFlow/experience/add-experience";

const AddExperiencePage = () => {
  return (
    <>
      <Progress
        colorScheme="progressBar"
        size="xs"
        value={75}
        w="full"
        borderRadius={"lg"}
        mb="6 !important"
        mt="2 !important"
      />
      <AddExperience />
    </>
  );
};

export default AddExperiencePage;
