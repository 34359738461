import {
  Button,
  Card,
  CardBody,
  Flex,
  Grid,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Progress,
  Select,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import SearchJobCard from "./searchJobCard";
import { useFetchApprovedJobs } from "../../../service/job.service";
import { getUserId, getisProfile30percent } from "../../../utils/helper";
import NoDataCard from "../../common/nodata";
import LoadingScreen from "../../common/loadingScreen";
import { useNavigate } from "react-router-dom";
import {
  useFetchAllEducation,
  useGeneralInfoFetch,
} from "../../../service/education.service";
import { useUserStore } from "../../../store/useUser";
import { useFetchAllExperience } from "../../../service/experience.service";
import { BiSearch } from "react-icons/bi";
import { useFetch } from "../../../utils/useFetch";
import ReactPaginate from "react-paginate";
import { useFetchAllAproveJobs } from "./useSearchJob";

const SearchJobs = () => {
  const {
    isLoading: jobsLoading,
    response: jobsList,
    fetchAllAproveJobs,
    selectJobFunc,
    jobFunction,
    query,
    setQuery,
    itemOffset,
    setItemOffset,
    userId,
  } = useFetchAllAproveJobs();
  const [searchField, setSearchField] = useState("");
  const [searchJobFunctionResult, setSearchJobFunctionResult] = useState<any>(
    []
  );
  const { setprofilePicture, setUserName, user, setUser } = useUserStore();
  const profilePercentage = getisProfile30percent();

  const { data: generalInfo } = useGeneralInfoFetch(userId);
  const { data: experienceList } = useFetchAllExperience(userId);
  const { data: educationList } = useFetchAllEducation(userId);

  const handlePageClick = (event: any) => {
    const newOffset = event.selected;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    setprofilePicture(generalInfo?.profilePicture);
    setUserName(generalInfo?.fullName);
    setUser({
      ...user,
      fullName: generalInfo?.fullName,
      profilePicture: generalInfo?.profilePicture,
    });
    if (generalInfo?.profileCompletePercentage) {
      const percentage = JSON.stringify(generalInfo?.profileCompletePercentage);
      localStorage.setItem("profilepercentage", percentage);
    }
  }, [generalInfo]);

  const navigate = useNavigate();
  const handleClick = () => {
    if (profilePercentage >= 30 && profilePercentage < 60) {
      navigate("/setupFlow/setup");
    } else if (profilePercentage > 59 && profilePercentage <= 79) {
      if (!experienceList?.length && !educationList?.length) {
        navigate("/setupFlow/education");
      } else if (experienceList?.length && !educationList?.length) {
        navigate("/setupFlow/education");
      } else {
        navigate("/setupFlow/setup");
      }
    } else if (profilePercentage > 79) {
      if (!experienceList?.length && !educationList?.length) {
        navigate("/setupFlow/education");
      } else if (!experienceList?.length && educationList?.length) {
        navigate("/setupFlow/experience");
      } else {
        navigate("/setupFlow/setup");
      }
    }
  };

  useEffect(() => {
    try {
      useFetch
        .get(`/users/domain/all-job-function-list?query=&page=0&limit=500`)
        .then((response: any) => {
          setSearchJobFunctionResult(response.data);
        })
        .catch((error: any) => {
          console.log("Error", error);
        });
    } catch (error) {
      console.log("ERROR", error);
    }
  }, []);
  const isShowSearchBar =
    !jobsList?.data?.length && (!!query?.length || !!jobFunction?.length);
  const handleChange = (e: any) => {
    e.preventDefault();
    let value = e.target.value;
    setQuery(value.trimLeft());
    setSearchField(value.trimLeft());
    if (value.length && itemOffset !== 0) {
      setItemOffset(0);
    }
  };
  const handleSearchJobFunctions = (search: any) => {
    selectJobFunc(search);
    if (search.length && itemOffset !== 0) {
      setItemOffset(0);
    }
  };
  return (
    <>
      <Flex direction="column" w="full" padding="10px" gap="10px">
        {profilePercentage < 100 && (
          <Card
            p={4}
            boxShadow="md"
            borderRadius="md"
            m="20px 0px 5px 0px"
            w="full"
          >
            <Flex align="start" display={{ base: "column", md: "row" }}>
              <VStack align="start" justify="start">
                <Text fontWeight="900" fontSize="60px" letterSpacing="1.5px">
                  {profilePercentage}%
                </Text>
                <Text fontWeight="700" fontSize="24px" letterSpacing="-0.24px">
                  of your profile is complete ⓘ
                </Text>
              </VStack>
              <VStack align="start" justify="space-between" w="full" gap="10px">
                <Progress
                  value={profilePercentage}
                  w="full"
                  max={100}
                  colorScheme={profilePercentage >= 80 ? "green" : "yellow"}
                  borderRadius="20px"
                  size="lg"
                />
                <Text fontWeight="700">
                  Complete Your Profile to Unlock Your Full Potential
                </Text>
                <Text>
                  Take the next step to make your profile stand out. A fully
                  completed profile attracts more opportunities and establishes
                  your credibility. Learn more about building a great profile.{" "}
                </Text>
                <Button
                  borderRadius="20px"
                  color="white"
                  bg="gray.900"
                  colorScheme="black"
                  onClick={handleClick}
                >
                  Complete your profile
                </Button>
              </VStack>
            </Flex>
          </Card>
        )}
        {(!!jobsList?.data?.length || isShowSearchBar) && (
          <Card bg="#f7f6f8" borderRadius="0px" boxShadow="none">
            <CardBody>
              <Flex
                w={{ base: "full", lg: "100%" }}
                flexDir={{ base: "column", lg: "row" }}
                gap="4"
              >
                <HStack
                  w="full"
                  spacing={4}
                  mr={{ base: "0", sm: "2" }}
                  align={"baseline"}
                >
                  <Text color="#646C9A" whiteSpace={"nowrap"} fontSize={"13px"}>
                    {`${
                      jobsList?.data?.length
                        ? jobsList?.data?.length === 1
                          ? "1 Total"
                          : `${jobsList?.totalElements} Total`
                        : "0"
                    }`}
                  </Text>
                  <InputGroup w={{ base: "100%", sm: "60%" }}>
                    <InputRightElement
                      pointerEvents="none"
                      children={<BiSearch color="#5867DD" />}
                    />
                    <Input
                      type="text"
                      placeholder="Search ..."
                      bg="#edeef3"
                      border={"none"}
                      sx={{ "::placeholder": { fontSize: "13px" } }}
                      value={query}
                      onChange={(e) => handleChange(e)}
                    />
                  </InputGroup>
                </HStack>
                <HStack
                  w="full"
                  spacing={4}
                  mt={{ base: "2", sm: "0" }}
                  align={"baseline"}
                >
                  <Text color="#646C9A" whiteSpace={"nowrap"} fontSize={"13px"}>
                    Job Functions
                  </Text>
                  <Select
                    placeholder="All"
                    value={jobFunction}
                    onChange={(e) => handleSearchJobFunctions(e.target.value)}
                  >
                    {searchJobFunctionResult?.map(
                      (item: any, index: number) => (
                        <option value={item?.name} key={index}>
                          {item?.name}
                        </option>
                      )
                    )}
                  </Select>
                </HStack>
              </Flex>
            </CardBody>
          </Card>
        )}
        <Flex w="full" m="auto" p="4" py="8" bg="#f0f1f7">
          {jobsLoading && !jobsList?.data?.length ? (
            <LoadingScreen />
          ) : jobsList?.data?.length ? (
            <>
              <Flex
                flexDirection={"column"}
                justify="space-between"
                minH="70vh"
                w="full"
              >
                <Grid
                  w="100% !important"
                  templateColumns={[
                    "1fr",
                    "1fr",
                    "1fr",
                    "repeat(2, 1fr)",
                    "repeat(2, 1fr)",
                    "repeat(3, 1fr)",
                  ]}
                  gap={4}
                  sx={{
                    "&::-webkit-scrollbar": {
                      height: "4px",
                    },
                    "&::-webkit-scrollbar-track": {
                      background: "transparent",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: "gray.400",
                      borderRadius: "full",
                    },
                  }}
                >
                  {jobsList?.data?.map((item: any, index: number) => (
                    <SearchJobCard
                      key={index}
                      data={item}
                      refetch={fetchAllAproveJobs}
                    />
                  ))}
                </Grid>
                <ReactPaginate
                  nextLabel="Next >"
                  onPageChange={handlePageClick}
                  forcePage={itemOffset}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  pageCount={jobsList?.totalPages}
                  previousLabel="< Previous"
                  breakLabel="..."
                  previousClassName={`page-item${
                    itemOffset === 0 ? " disabled" : ""
                  }`}
                  nextClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName={"container"}
                  previousLinkClassName={"page"}
                  breakClassName={"page"}
                  nextLinkClassName={"page"}
                  pageClassName={"page"}
                  disabledClassName={"disabled"}
                  activeClassName={"active-venu-button"}
                />
              </Flex>
            </>
          ) : searchField.length >= 1 || jobFunction.length >= 1 ? (
            <Card p={4} boxShadow="md" borderRadius="md" py="10" w="full">
              <Flex w="full" align="center" justify="center">
                <Text fontSize="20px" fontWeight="500">
                  No Matching Record Found
                </Text>
              </Flex>
            </Card>
          ) : (
            <NoDataCard
              message={
                "You will start viewing jobs as soon as the admin approves your profile."
              }
            />
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default SearchJobs;
