import { useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAddJobs, useUpdateJob } from "../../../service/job.service";
import { addCommas, getUserId, removeNonNumeric } from "../../../utils/helper";
import { useFetchJobFunc } from "../../../service/helper.service";
const optionsJobFunc: any = [
  "Data Scientist ",
  "Data Engineer/Data Architect",
  "BI Analyst/Developer",
  "Quant (Quantitative Scientist/Analyst)",
  "Machine Learning Engineer / AI Engineer",
  "Machine Learning Researcher",
  "MLOps Engineer",
  "NLP Engineer",
  "Computer Vision Engineer",
  "Robotics Engineer",
  "Software Engineer (Front-end / Back-end)",
  "Mobile App",
  "Embedded Systems Developer",
  "DevOps",
  "QA Engineer",
  "Security Analyst / Engineer",
  "Ethical Hacker",
  "Information Security Manager",
  "Cloud Engineer / Architect",
  "Network Engineer",
  "Technical Project / Program Manager",
  "Agile Coach / Scrum Master",
  "Product Manager",
];
const optionsLocations: any = [
  "NYC",
  "Boston",
  "Chicago",
  "LA",
  "London",
  "Lahore",
  "Delhi",
];
const optionRemoteLocations: any = [
  "United States",
  " Open to all locations (International)",
  "Prefer low-cost regions (Non U.S./CA/UK)",
  "Canada",
  "Latin America",
  "European Union",
  " Eastern Europe",
  "Asia-Pacific",
  "Specific countries (please specify below)",
];
const optionsResponsibilities: any = [
  "Build Team",
  "Manage Team",
  "Build new software",
  "Maintain existing software",
  "Work with internal clients",
  "Work with external clients",
];
const optionsBenefits: any = [
  "Bonus",
  "Stock",
  "Long-Term Cash Incentives",
  "Retirement Plan",
  "Health Insurance",
  "PTO",
  "Pension",
  "Other",
];
const optionsIndustry: any = ["HealtCare", "Finance", "Insurance", "Marketing"];
const optionsSponsorship: any = [
  "H1B sponsorship",
  "H1B transfer",
  "Green Card Sponsorship",
  "OPT/CPT Candidates",
  "TN Visa",
];
const optionCollegeMajor: any = [
  "Computer Science",
  "Statistics",
  "Mathematics",
  "Engineering",
  "Physics",
  "Medicine",
];
const optionsDomain: any = [
  "Data Science ",
  "Analytics",
  "Data Engineer",
  "Ml Ops",
  "Data Analyst",
  "Report & BI",
  "Node.js",
  "React.js",
];
const optionsSkills: any = [
  "Data Science ",
  "Analytics",
  "Ml Engineer",
  "ML Researcher",
  "Data Engineer",
  "Ml Ops",
  "Data Analyst",
  "Report & BI",
  "Node.js",
  "React.js",
];
const useJobPost = () => {
  const [preferredCollegeMajors, setPreferredCollegeMajors] = useState<
    string[]
  >([]);
  const [mustHaveSkills, setMustHaveSkills] = useState<string[]>([]);
  const [niceToHaveSkills, setNiceToHaveSkills] = useState<string[]>([]);
  const [searchLocationList, setSearchLocationList] = useState<any>([]);

  const {
    mutate: jobMutation,
    isLoading: jobLoading,
    data: jobData,
  } = useAddJobs();
  const {
    mutate: jobUpdateMutation,
    isLoading: jobUpdateLoading,
    data: jobUpdateData,
  } = useUpdateJob();
  const toast = useToast();
  const { state } = useLocation();

  const userId = getUserId();
  const [searchTerm, setSearchTerm] = useState("");
  const [jobFunctionList, setJobFunctionList] = useState<any>(
    state?.jobFunctionList ? state.jobFunctionList : []
  );
  const [jobFuncCheck, setJobFuncCheck] = useState<any>(false);
  const [peopleManagement, setPeopleManagement] = useState<any>(
    state?.peopleManagement ? state?.peopleManagement : ""
  );
  const [locationsType, setLocationType] = useState<any>(
    state?.locationsType ? state?.locationsType : ""
  );
  const [sponsorshipType, setSponsorshipType] = useState<any>(
    state?.sponsorshipType ? state?.sponsorshipType : ""
  );
  const [minEducation, setMinEducationType] = useState<any>(
    state?.minEducation ? state?.minEducation : ""
  );
  const [name, setName] = useState<any>(state?.name ? state?.name : "");
  const [description, setDescription] = useState<any>(
    state?.description ? state?.description : ""
  );
  const [reqNumber, setReqNumber] = useState<any>(
    state?.reqNumber ? state?.reqNumber : ""
  );
  const [reports, setReports] = useState<any>(
    state?.reports ? state?.reports : ""
  );
  const [seniorityLevel, setSeniorityLevel] = useState<any>(
    state?.seniorityLevel ? state?.seniorityLevel : ""
  );
  const [experience, setExperience] = useState<any>(
    state?.experience ? state?.experience : []
  );
  const [salary, setSalary] = useState<any>({
    currency: state?.salary?.currency ? state?.salary?.currency : "usd",
    from: state?.salary?.from ? addCommas(state?.salary?.from) : "",
    to: state?.salary?.to ? addCommas(state?.salary?.to) : "",
    duration: state?.salary?.duration ? state?.salary?.duration : "annually",
  });
  const [bonus, setBonus] = useState<any>({
    bonusType: state?.bonus?.bonusType ? state?.bonus?.bonusType : "percentage",
    from: state?.bonus?.from ? addCommas(state?.bonus?.from) : "",
    to: state?.bonus?.to ? addCommas(state?.bonus?.to) : "",
    duration: state?.bonus?.duration ? state?.bonus?.duration : "annually",
  });
  const [stock, setStock] = useState<any>({
    type: state?.stock?.type ? state?.stock?.type : "RSU's",
    stockType: state?.stock?.stockType ? state?.stock?.stockType : "percentage",
    from: state?.stock?.from ? addCommas(state?.stock?.from) : "",
    to: state?.stock?.to ? addCommas(state?.stock?.to) : "",
    duration: state?.stock?.duration
      ? state?.stock?.duration
      : "annually grant - fixed amonth",
  });
  const {
    isLoading: loadingJobFunc,
    data: jobFunctionListOption,
    isSuccess,
  } = useFetchJobFunc(userId);
  useEffect(() => {
    setJobFuncCheck(true);
  }, [isSuccess]);
  useEffect(() => {
    setJobFunctionList(state?.jobFunctions ? state?.jobFunctions : []);
  }, [jobFuncCheck]);

  const navigate = useNavigate();
  const handleSearch = () => {
    // Perform search logic here
  };

  const handleInputChange = (event: any) => {
    const { value, name } = event.target;
    if (name === "name") {
      setName(value.trimLeft());
    }
    if (name === "reqNumber") {
      setReqNumber(value);
    }
    if (name === "description") {
      setDescription(value);
    }
  };

  const handleOptionSelection = (option: any) => {
    if (jobFunctionList.includes(option)) {
      setJobFunctionList((prevSelectedOptions: any) =>
        prevSelectedOptions.filter((o: any) => o !== option)
      );
    } else if (jobFunctionList.length < 3) {
      setJobFunctionList((prevSelectedOptions: any) => [
        ...prevSelectedOptions,
        option,
      ]);
    } else if (jobFunctionList.length >= 3) {
      toast({
        title: "You can select up to 3 options",
        status: "info",
        variant: "top-accent",
        isClosable: true,
        position: "top-right",
        duration: 3000,
      });
    }
  };
  const handleExperience = (
    value: any,
    jobFunc: any,
    degree: any,
    experienceIndex: any
  ) => {
    const data = {
      jobFunction: jobFunc,
      degree: degree,
      experience: value,
    };

    const existingEntry = experience.find(
      (entry: any) =>
        entry && entry.jobFunction === jobFunc && entry.degree === degree
    );

    if (existingEntry) {
      setExperience((prev: any) => {
        const updatedExperience = [...prev];
        updatedExperience[experienceIndex] = {
          ...data,
        };
        return updatedExperience;
      });
    } else {
      setExperience((prev: any) => {
        const updatedExperience = [...prev];
        updatedExperience[experienceIndex] = {
          ...data,
        };
        return updatedExperience;
      });
    }
  };

  const handleSalary = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    if (name == "from" || name == "to") {
      let formattedValue = addCommas(removeNonNumeric(value));
      setSalary({ ...salary, [name]: formattedValue });
    } else {
      let formattedValue = value;
      setSalary({ ...salary, [name]: formattedValue });
    }
  };

  const handleBonus = (event: any) => {
    const { value, name } = event.target;
    if (name == "from" || name == "to") {
      let formattedValue = addCommas(removeNonNumeric(value));

      setBonus({ ...bonus, [name]: formattedValue });
    } else {
      let formattedValue = value;

      setBonus({ ...bonus, [name]: formattedValue });
    }
  };
  const handleStock = (event: any) => {
    const { value, name } = event.target;

    if (name == "from" || name == "to") {
      let formattedValue = addCommas(removeNonNumeric(value));

      setStock({ ...stock, [name]: formattedValue });
    } else {
      let formattedValue = value;

      setStock({ ...stock, [name]: formattedValue });
    }
  };

  const useSelectOptions = (initialState: any) => {
    const [selectedOptions, setSelectedOptions] = useState<any>(initialState);

    const handleOptionSelection = (option: any) => {
      if (selectedOptions.includes(option)) {
        setSelectedOptions((prevSelectedOptions: any) =>
          prevSelectedOptions.filter((o: any) => o !== option)
        );
      } else {
        setSelectedOptions((prevSelectedOptions: any): any => [
          ...prevSelectedOptions,
          option,
        ]);
      }
    };
    const updateHandler = (value: any) => {
      setSelectedOptions(value ? value : []);
    };

    return [
      selectedOptions,

      handleOptionSelection,
      updateHandler,
      setSelectedOptions,
    ];
  };
  const [preferDregeeList, handlePreferDregeeList, updateHandlerPreferDegree] =
    useSelectOptions(state?.preferDregeeList ? state?.preferDregeeList : []);
  const [
    preferRemoteLocationList,
    handlePreferRemoteLocationList,
    updateHandlerPreferRemoteLocation,
  ] = useSelectOptions(
    state?.preferRemoteLocationList ? state?.preferRemoteLocationList : []
  );
  const [customLocationList, setCustomLocationList] = useState<any>([]);

  const [
    locationsList,

    handleLocationList,
    updateHandlerLocationList,
    setLocationList,
  ] = useSelectOptions([]);

  const [
    responsibilitiesList,
    handleResponsibilitiesList,
    updateHandlerResponsibilitiesList,
  ] = useSelectOptions(
    state?.responsibilitiesList ? state?.responsibilitiesList : []
  );
  const [
    responsibilitiesList2,
    handleResponsibilities2,
    updateHandlerResponsibilities2,
  ] = useSelectOptions(
    state?.responsibilitiesList2 ? state?.responsibilitiesList2 : []
  );
  const [
    industryExperienceList,
    handleIndustryList,
    updateHandlerIndustryList,
  ] = useSelectOptions(
    state?.industryExperienceList ? state?.industryExperienceList : []
  );
  const [
    employeeBenefitList,
    handleEmployeeBenefitList,
    updateHandlerEmployeeBenefits,
  ] = useSelectOptions(
    state?.employeeBenefitList ? state?.employeeBenefitList : []
  );
  const [sponsorshipList, handleSponsorshipList, updateHandlerSponsorshipList] =
    useSelectOptions(state?.sponsorshipList ? state?.sponsorshipList : []);
  const [
    collegeMajorList,
    handleCollegeMajorList,
    updateHandlerCollegeMajorList,
  ] = useSelectOptions(state?.collegeMajorList ? state?.collegeMajorList : []);
  const [domainList, handleDomainList, updateHandlerHandleDomainList] =
    useSelectOptions(state?.domainList ? state?.domainList : []);
  const [topSkillList, handleTopSkillList, updateHandlerTopSkills] =
    useSelectOptions(state?.topSkillList ? state?.topSkillList : []);

  useEffect(() => {
    console.log("STATE ", state);

    if (state?.name) {
      setJobFunctionList(state?.jobFunctionList);
      setMustHaveSkills(state?.mustHaveSkills);
      setNiceToHaveSkills(state?.niceToHaveSkills);
      setPreferredCollegeMajors(state?.collegeMajorList);
      setPeopleManagement(peopleManagement);
      setLocationType(locationsType);
      setSponsorshipType(sponsorshipType);
      setReqNumber(reqNumber);
      setName(name);
      setDescription(description);
      setSalary(salary);
      setBonus(bonus);
      setStock(stock);
      updateHandlerPreferDegree(preferDregeeList);
      updateHandlerPreferRemoteLocation(preferRemoteLocationList);
      if (state?.locationsType === "Remote") {
        let customLocations: any = [];
        let mainList: any = [];

        state?.locationsList.forEach((location: any) => {
          if (
            ![
              "United States",
              " Open to all locations (International)",
              "Prefer low-cost regions (Non U.S./CA/UK)",
              "Canada",
              "Latin America",
              "European Union",
              " Eastern Europe",
              "Asia-Pacific",
            ].includes(location)
          ) {
            customLocations.push(location);
          } else {
            mainList.push(location);
          }
        });
        if (customLocations.length > 0) {
          mainList.push("Specific countries (please specify below)");
          setCustomLocationList(customLocations);
        }
        setLocationList(mainList);
      } else if (
        state?.locationsType === "In Office" ||
        state?.locationsType === "Hybrid"
      ) {
        setSearchLocationList(state?.locationsList);
      }
      updateHandlerResponsibilitiesList(responsibilitiesList);
      updateHandlerResponsibilities2(responsibilitiesList2);
      updateHandlerIndustryList(industryExperienceList);
      updateHandlerEmployeeBenefits(employeeBenefitList);
      updateHandlerSponsorshipList(sponsorshipList);
      updateHandlerCollegeMajorList(collegeMajorList);
      updateHandlerHandleDomainList(domainList);
      updateHandlerTopSkills(topSkillList);
      setSeniorityLevel(seniorityLevel);
      setReports(reports);
      setExperience(experience);
    }
  }, [state]);

  useEffect(() => {
    let updatedExp: any = [];
    if (preferDregeeList.length) {
      const updatedExperience = experience.map((item: any) => {
        preferDregeeList.map((preferedItem: any) => {
          if (preferedItem === item.degree) {
            updatedExp.push(item);
          }
        });
      });
    }
    setExperience(updatedExp);
  }, [preferDregeeList?.length]);
  const handleSubmit = () => {
    let updatedLocationsList;
    if (locationsList.includes("Specific countries (please specify below)")) {
      updatedLocationsList = [
        ...locationsList.filter(
          (location: any) =>
            location !== "Specific countries (please specify below)"
        ),
        ...customLocationList,
      ];
    } else {
      updatedLocationsList = locationsList;
    }
    const data = {
      description: description.trim(),
      jobId: state?.id,
      userId,
      name: name.trim(),
      reqNumber: reqNumber.trim(),
      jobFunctionList,
      seniorityLevel,
      locationsType: locationsType.trim(),
      minEducation: minEducation.trim(),
      collegeMajorList: preferredCollegeMajors,
      mustHaveSkills,
      niceToHaveSkills,
      salary: {
        currency: salary?.currency,
        from: salary?.from ? Number(removeNonNumeric(salary?.from)) : null,
        to: salary?.to ? Number(removeNonNumeric(salary?.to)) : null,
        duration: salary?.duration,
      },
      bonus: {
        bonusType: bonus?.bonusType,
        from: bonus?.from ? Number(removeNonNumeric(bonus?.from)) : null,
        to: bonus?.to ? Number(removeNonNumeric(bonus?.to)) : null,
        duration: bonus?.duration,
      },
      stock: {
        type: stock?.type,
        stockType: stock?.stockType,
        from: stock?.from ? Number(removeNonNumeric(stock?.from)) : null,
        to: stock?.to ? Number(removeNonNumeric(stock?.to)) : null,
        duration: stock?.duration,
      },
      locationsList:
        locationsType !== "Remote" ? searchLocationList : updatedLocationsList,
      sponsorshipType,
      sponsorshipList: sponsorshipType === "Yes" ? sponsorshipList : [],
    };

    state?.isEdit
      ? jobUpdateMutation(data, {
          onSuccess: (resData) => {
            navigate("/jobs");
          },
        })
      : jobMutation(data, {
          onSuccess: (resData) => {
            navigate("/jobs-post-done");
          },
        });
  };

  return {
    mustHaveSkills,
    setMustHaveSkills,
    niceToHaveSkills,
    setNiceToHaveSkills,
    preferredCollegeMajors,
    setPreferredCollegeMajors,
    setSearchTerm,
    setJobFunctionList,
    setPeopleManagement,
    setLocationType,
    setSponsorshipType,
    setMinEducationType,
    searchTerm,
    jobFunctionList,
    peopleManagement,
    locationsType,
    sponsorshipType,
    minEducation,
    handleOptionSelection,
    optionsJobFunc,
    optionsLocations,
    optionRemoteLocations,
    optionsResponsibilities,
    optionsIndustry,
    optionsBenefits,
    optionsSponsorship,
    optionCollegeMajor,
    optionsDomain,
    optionsSkills,
    useSelectOptions,
    handleInputChange,
    name,
    reqNumber,
    setSeniorityLevel,
    setReports,
    handleExperience,
    handleSalary,
    salary,
    handleBonus,
    bonus,
    handleStock,
    stock,
    handleSubmit,
    navigate,
    preferDregeeList,
    preferRemoteLocationList,
    handlePreferRemoteLocationList,
    handlePreferDregeeList,
    locationsList,
    setLocationList,
    customLocationList,
    setCustomLocationList,
    handleLocationList,
    responsibilitiesList,
    handleResponsibilitiesList,
    responsibilitiesList2,
    handleResponsibilities2,
    industryExperienceList,
    handleIndustryList,
    employeeBenefitList,
    handleEmployeeBenefitList,
    sponsorshipList,
    handleSponsorshipList,
    collegeMajorList,
    handleCollegeMajorList,
    domainList,
    handleDomainList,
    topSkillList,
    handleTopSkillList,
    reports,
    seniorityLevel,
    experience,
    state,
    jobLoading,
    jobFunctionListOption,
    description,
    setDescription,
    jobUpdateLoading,
    searchLocationList,
    setSearchLocationList,
  };
};

export default useJobPost;
