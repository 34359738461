import { Navigate, Outlet } from "react-router-dom";

import { LinkItemsCandidate } from "../../../../utils/routes";
import Sidebar from "../../../common/sidebar";
import {
  getAccessToken,
  getisProfile30percent,
} from "../../../../utils/helper";

const PrivateRoutes = () => {
  const profilePercentage = getisProfile30percent();
  const token = getAccessToken();

  return token ? (
    profilePercentage && profilePercentage >= 29 ? (
      <Sidebar
        LinkItemProps={LinkItemsCandidate}
        // interviewList={scheduleList}
      >
        <Outlet />
      </Sidebar>
    ) : (
      <Navigate to="/setupFlow/location" />
    )
  ) : (
    <Navigate to="/signup" />
  );
};

export default PrivateRoutes;
