import {
  Flex,
  Box,
  Card,
  CardBody,
  Heading,
  VStack,
  Stack,
} from "@chakra-ui/react";
import ProfileGeneral from "./partials/ProfileGeneral";
import ProfileOtherInfo from "./partials/ProfileOtherInfo";
import ProfileEducationAndExperience from "./partials/ProfileEdu&Exp";
import BasicInfo from "./partials/BasicInfo";

const Settings = () => {
  return (
    <>
      <Card boxShadow={"none"} borderRadius="none" mb="2" bg="#f7f6f8">
        <CardBody>
          <Stack
            direction="row"
            spacing={4}
            align="center"
            justify="space-between"
          >
            <Heading size="sm" color={"#959cb6"}>
              Profile Overview
            </Heading>
          </Stack>
        </CardBody>
      </Card>
      <Flex w="100%" justify="center" align="flex-start" h="full" p="4" py="6">
        <VStack w="full" h="full" spacing={6}>
          <Box w="full">
            <ProfileGeneral />
          </Box>

          <ProfileOtherInfo />
          <Box w="full">
            <BasicInfo />
          </Box>
          <Box w="full">
            <ProfileEducationAndExperience />
          </Box>
        </VStack>
      </Flex>
    </>
  );
};

export default Settings;
