import { Flex, Grid } from "@chakra-ui/react";
import { useState } from "react";
import SearchTalentCard from "./archivedTalentCard";
import useTalent from "./useTalent";
import LoadingScreen from "../../common/loadingScreen";
import NoDataCard from "../../common/nodata";
import ReactPaginate from "react-paginate";

const LikedTalent = () => {
  const itemsPerPage = 12;
  const [pageCount, setPageCount] = useState(0);
  const { candidateList, candidateLoading, itemOffset, setItemOffset } =
    useTalent();
  const handlePageClick = (event: any) => {
    const newOffset = event.selected;
    setItemOffset(newOffset);
  };

  return (
    <Flex w="full" m="auto" p="4" py="8" bg="#f0f1f7">
      {candidateLoading ? (
        <LoadingScreen />
      ) : candidateList?.data?.length ? (
        <>
          <Flex
            flexDirection={"column"}
            justify="space-between"
            minH="70vh"
            w="full"
          >
            <Grid
              w="100% !important"
              templateColumns={[
                "1fr",
                "1fr",
                "1fr",
                "repeat(2, 1fr)",
                "repeat(2, 1fr)",
                "repeat(3, 1fr)",
              ]}
              //   p="2"
              gap={4}
              overflowX="auto"
              width={["100%", "100%", "100%", "100%", "100%", "100%"]}
              sx={{
                "&::-webkit-scrollbar": {
                  height: "4px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "transparent",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "gray.400",
                  borderRadius: "full",
                },
              }}
            >
              {" "}
              {candidateList.data?.map((data: any, index: number) => (
                <SearchTalentCard key={index} data={data} />
              ))}
            </Grid>
            <ReactPaginate
              nextLabel="Next >"
              onPageChange={handlePageClick}
              forcePage={itemOffset}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={candidateList.totalPages ?? 1}
              previousLabel="< Previous"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              breakLabel="..."
              nextClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName={"container"}
              previousLinkClassName={"page"}
              breakClassName={"page"}
              nextLinkClassName={"page"}
              pageClassName={"page"}
              disabledClassName={"disabled"}
              activeClassName={"active-venu-button"}
              // renderOnZeroPageCount={null}
            />
          </Flex>
        </>
      ) : (
        <NoDataCard message="Your job post is live! Expect interview-ready candidates here within 2 to 3 business days." />
      )}
    </Flex>
  );
};

export default LikedTalent;
