import {
  Avatar,
  AvatarBadge,
  Box,
  Button,
  Center,
  CloseButton,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Select,
  SkeletonCircle,
  Stack,
  Text,
} from "@chakra-ui/react";

import useSettings from "./useSettings";
import {
  useAddProfile,
  useEmployerProfile,
} from "../../../service/employerProfile.service";

import {
  getUserId,
  handleLoginLocalStorageData,
  trapSpacesForRequiredFields,
} from "../../../utils/helper";
import { useUserStore } from "../../../store/useUser";
import { FiEdit } from "react-icons/fi";

import "../../customModal/modal.css";
const UserModal = ({ onModalClose, isOpen }: any) => {
  const { setUser } = useUserStore();

  const {
    register,
    errors,
    watch,
    handleSubmit,
    reset,
    handleEditButtonClickCompany,
    fileInputRefCompany,
    handleFileInputChangeCompany,
    companyLogo,
    companyLogoLoading,
    isSubmitted,
  } = useSettings();

  const userId = getUserId();
  const {
    mutate: profileMutation,
    isLoading: profileLoading,
    data: profileData,
  } = useAddProfile();
  const {
    refetch: fetchProfile,
    isLoading: isLoadingFetch,
    data: profileDataFetch,
  } = useEmployerProfile(userId);

  const isParent = profileDataFetch?.isParent;
  const handleSave = async (values: any) => {
    try {
      profileMutation(
        {
          userId,
          companyLogo,
          companyName: values.companyName.trim(),
          companyWebsite: values.companyWebsite.trim(),
          fullName: values.fullName.trim(),
          title: values.title.trim(),
          roleName: values.roleName.trim(),
          email: values.email.trim(),
          linkedInProfile: values?.linkedinUrl.trim(),
          positionName: values?.roleName.trim(),
          calenderUrl: values?.calandarUrl.trim(),
        },
        {
          onSuccess: (resDate) => {
            reset();
            onModalClose();
            handleLoginLocalStorageData(resDate);
            setUser(resDate);
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {isOpen && (
        <Box
          className="modal"
          onClick={() => {
            reset();
            onModalClose();
          }}
          px={{ base: "3 !important", md: "0 !important" }}
        >
          <Box className="modal-content" onClick={(e) => e.stopPropagation()}>
            <HStack
              spacing={"24px"}
              justify={"space-between"}
              w="full"
              bg="#5867DD"
              h={"60px"}
              px="6"
              py="4"
            >
              <Text color={"white"} fontSize={"lg"} fontWeight={"bold"}>
                Details
              </Text>
              <CloseButton
                color={"white"}
                onClick={() => {
                  reset();
                  onModalClose();
                }}
              />
            </HStack>
            <Box w="full" p="5" className="modal-body">
              <FormLabel fontWeight={"bold"} fontSize="md">
                Company Logo
              </FormLabel>
              <form>
                <Stack spacing={4} rounded={"md"}>
                  <Center w="full">
                    {companyLogoLoading ? (
                      <SkeletonCircle size="64px" />
                    ) : (
                      <Avatar size="lg" src={companyLogo}>
                        <AvatarBadge
                          as={IconButton}
                          size="sm"
                          rounded="full"
                          top="-10px"
                          colorScheme="buttonPrimary"
                          aria-label="edit Image"
                          icon={<FiEdit />}
                          onClick={handleEditButtonClickCompany}
                        />
                      </Avatar>
                    )}

                    <input
                      ref={fileInputRefCompany}
                      type="file"
                      // accept="image/*"
                      accept="image/png, image/jpeg, image/jpg, image/svg"
                      style={{ display: "none" }}
                      disabled={!isParent}
                      onChange={handleFileInputChangeCompany}
                    />
                  </Center>
                  <FormControl
                    isInvalid={isSubmitted && Boolean(errors?.companyName)}
                    isRequired
                  >
                    <FormLabel fontWeight={"bold"} fontSize="md">
                      Company Name
                    </FormLabel>

                    <Input
                      maxLength={255}
                      type="text"
                      placeholder="Enter company name"
                      isDisabled={!isParent}
                      {...register("companyName", {
                        required: "Company Name is required",
                        validate: trapSpacesForRequiredFields,
                      })}
                    />
                    {isSubmitted && (
                      <FormErrorMessage>
                        {errors?.companyName?.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl
                    isInvalid={isSubmitted && Boolean(errors?.companyWebsite)}
                    isRequired
                  >
                    <FormLabel fontWeight={"bold"} fontSize="md">
                      Company Website
                    </FormLabel>
                    <Input
                      maxLength={5000}
                      type="text"
                      placeholder="Enter company website"
                      isDisabled={!isParent}
                      {...register("companyWebsite", {
                        pattern: {
                          value: /^(ftp|http|https):\/\/[^ "]+$/,
                          message: "Invalid URL format",
                        },
                      })}
                    />
                    {isSubmitted && (
                      <FormErrorMessage>
                        {errors?.companyWebsite?.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl
                    isInvalid={isSubmitted && Boolean(errors?.fullName)}
                    isRequired
                  >
                    <FormLabel fontWeight={"bold"} fontSize="md">
                      Full Name
                    </FormLabel>
                    <Input
                      maxLength={255}
                      type="text"
                      placeholder="Enter your full name"
                      {...register("fullName")}
                    />
                    {isSubmitted && (
                      <FormErrorMessage>
                        {errors?.fullName?.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl
                    isInvalid={isSubmitted && Boolean(errors?.title)}
                    isRequired
                  >
                    <FormLabel fontWeight={"bold"} fontSize="md">
                      Title
                    </FormLabel>
                    <Input
                      type="text"
                      maxLength={50}
                      placeholder="Enter your title"
                      {...register("title")}
                    />
                    {isSubmitted && (
                      <FormErrorMessage>
                        {errors?.title?.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl
                    isInvalid={isSubmitted && Boolean(errors?.roleName)}
                    isRequired
                  >
                    <FormLabel fontWeight={"bold"} fontSize="md">
                      Your Role
                    </FormLabel>
                    <Select
                      isDisabled={!isParent}
                      size="md"
                      h="38px"
                      borderRadius={"md"}
                      {...register("roleName")}
                      ml="0px"
                    >
                      <option value="Interviewer">Interviewer</option>
                      <option value="Recruiter">Recruiter</option>
                      <option value="Human Resourses">Human Resourses</option>
                      <option value="Coordinator">Coordinator</option>
                      <option value="Project Manager">Project Manager</option>
                      <option value="Administrator">Administrator</option>
                    </Select>

                    {isSubmitted && (
                      <FormErrorMessage>
                        {errors?.roleName?.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl
                    isInvalid={Boolean(errors?.email)}
                    isDisabled={true}
                  >
                    <FormLabel fontWeight={"bold"} fontSize="md">
                      Email
                    </FormLabel>
                    <Input
                      maxLength={255}
                      type="text"
                      placeholder="Enter your email"
                      {...register("email")}
                    />
                    <FormErrorMessage>
                      {errors?.email?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={isSubmitted && Boolean(errors?.linkedinUrl)}
                    isRequired
                  >
                    <FormLabel fontWeight={"bold"} fontSize="md">
                      LinkedIn Profile
                    </FormLabel>
                    <Input
                      maxLength={255}
                      type="text"
                      placeholder="Enter your linkedin profile"
                      {...register("linkedinUrl")}
                    />
                    {isSubmitted && (
                      <FormErrorMessage>
                        {errors?.linkedinUrl?.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>

                  <FormControl
                    isInvalid={isSubmitted && Boolean(errors?.calandarUrl)}
                    isRequired
                  >
                    <FormLabel fontWeight={"bold"} fontSize="md">
                      Calendar Url
                    </FormLabel>
                    <Input
                      maxLength={255}
                      type="text"
                      placeholder="Enter your calendar url"
                      {...register("calandarUrl")}
                    />
                    {isSubmitted && (
                      <FormErrorMessage>
                        {errors?.calandarUrl?.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </Stack>
                <HStack
                  spacing={"24px"}
                  justify={"flex-end"}
                  w="full"
                  mt="2 !important"
                  py="4"
                >
                  <Button
                    colorScheme="buttonPrimary"
                    isLoading={profileLoading}
                    isDisabled={profileLoading}
                    onClick={handleSubmit(handleSave)}
                  >
                    Save
                  </Button>
                  <Button
                    onClick={() => {
                      reset();
                      onModalClose();
                    }}
                  >
                    Close
                  </Button>
                </HStack>
              </form>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default UserModal;
