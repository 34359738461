import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Heading,
  HStack,
  Stack,
  VStack,
  Badge,
  Box,
  Flex,
  Text,
  Icon,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  Tooltip,
} from "@chakra-ui/react";
import { BiCoinStack, BiSliderAlt } from "react-icons/bi";
import { AiOutlineBank } from "react-icons/ai";

import { addCommas } from "../../../../utils/helper";
import { IoIosPeople } from "react-icons/io";

const JobDetail = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  return (
    <>
      <VStack w="full" spacing={6}>
        <Card boxShadow={"none"} borderRadius="none" w="full" bg="#f7f6f8">
          <CardBody py="2.5 !important">
            <Stack
              direction="row"
              spacing={4}
              align="center"
              justify="space-between"
            >
              <Heading
                size="sm"
                color={"#959cb6"}
                _hover={{ color: "#5D78FF" }}
              >
                Job Details
              </Heading>
              <Button
                loadingText="Loading"
                color="#74788d"
                spinnerPlacement="end"
                size="sm"
                fontWeight={"bold"}
                _hover={{ color: "white", bg: "#5867DD" }}
                // leftIcon={<IoIosArrowBack />}
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
            </Stack>
          </CardBody>
        </Card>
        <VStack
          w="full"
          spacing={6}
          p="4"
          py="8"
          bg="#f0f1f7"
          gap={"4"}
          mt="0px !important"
        >
          <Card w="full" borderRadius={"sm"}>
            <CardBody>
              <Flex flex="1" gap="4" alignItems="flex-start" flexWrap="wrap">
                <VStack align={"baseline"}>
                  <HStack align={"flex-start"}>
                    {/* <Avatar
                      name=""
                      src={state?.companyLogo}
                      size={{ sm: "md", base: "sm", md: "lg" }}
                    /> */}
                    <Heading
                      fontSize={{
                        base: "18.8px",
                        md: "18.8px",
                        lg: "20.2px",
                        xl: "20.2px",
                      }}
                      fontWeight={"500"}
                      color="#48465b"
                    >
                      {state?.companyName}
                    </Heading>
                  </HStack>
                </VStack>
              </Flex>
            </CardBody>
          </Card>
          <Card w="full" mt="0px ! important">
            <CardBody>
              <VStack w="full">
                <Box flex={1} w="full">
                  <Text fontSize={"20px"} fontWeight="bold" color={"#48465b"}>
                    {state?.name}
                  </Text>
                  <HStack w="full">
                    <Text
                      fontSize={"13px"}
                      fontWeight={"bold"}
                      color={"#646c9a"}
                      minW={{ base: "auto", md: "115px" }}
                    >
                      Primary Location:
                    </Text>
                    <Text fontSize={"13px"} color="#595d6e">
                      {state?.locationsList && state?.locationsList[0]}
                    </Text>
                  </HStack>
                  <HStack w="full">
                    <Text
                      fontSize={"13px"}
                      fontWeight={"bold"}
                      color={"#646c9a"}
                      minW={{ base: "auto", md: "115px" }}
                    >
                      Other Location:
                    </Text>
                    <HStack wrap="wrap">
                      {state?.locationsList
                        ?.slice(1)
                        .map((item: string, index: number) => {
                          return (
                            <Text
                              fontSize="13px"
                              color="#595d6e"
                              whiteSpace="nowrap"
                            >
                              {item}
                              {index < state?.locationsList?.length - 2 && (
                                <span>,</span>
                              )}
                            </Text>
                          );
                        })}
                    </HStack>
                  </HStack>
                  <HStack w="full" align={"baseline"}>
                    <Text
                      fontSize={"13px"}
                      fontWeight={"bold"}
                      whiteSpace={"nowrap"}
                      color={"#646c9a"}
                      minW={{ base: "auto", md: "115px" }}
                    >
                      Job type:
                    </Text>
                    <Text fontSize={"13px"} color="#595d6e">
                      {state?.locationsType}
                    </Text>
                  </HStack>
                  <HStack w="full">
                    <Text
                      fontSize={"13px"}
                      fontWeight={"bold"}
                      display={"flex"}
                      color={"#646c9a"}
                      minW={{ base: "auto", md: "115px" }}
                    >
                      Job functions:
                    </Text>
                    <HStack flexWrap={"wrap"} w="full">
                      {state?.jobFunctions?.map((item: any, index: any) => (
                        <React.Fragment key={index}>
                          <Text
                            fontSize={"13px"}
                            fontWeight={"100"}
                            color="#595d6e"
                            ml="0px !important"
                          >
                            {`${item}`}
                          </Text>
                          {index !== state.jobFunctions.length - 1 && (
                            <Text
                              fontSize={"13px"}
                              fontWeight={"100"}
                              color="#595d6e"
                              ml="0px !important"
                              mr="1 !important"
                            >
                              {`, `}
                            </Text>
                          )}
                        </React.Fragment>
                      ))}
                    </HStack>
                  </HStack>
                  {/* <HStack w="full">
                    <Text
                      fontSize={"13px"}
                      fontWeight={"bold"}
                      color={"#646c9a"}
                    >
                      Responsibilities:
                    </Text>
                    <HStack flexWrap={"wrap"} flex={1} ml="2">
                      {state?.responsibilitiesList?.map(
                        (item: any, index: any) => (
                          <React.Fragment key={index}>
                            <Text
                              fontSize={"13px"}
                              fontWeight={"100"}
                              color="#595d6e"
                              ml="0px !important"
                            >
                              {`${item}`}
                            </Text>
                            {index !==
                              state.responsibilitiesList.length - 1 && (
                              <Text
                                fontSize={"13px"}
                                fontWeight={"100"}
                                color="#595d6e"
                                ml="0px !important"
                                mr="1 !important"
                              >
                                {`, `}
                              </Text>
                            )}
                          </React.Fragment>
                        )
                      )}
                    </HStack>
                  </HStack> */}
                  {state?.reqNumber && (
                    <HStack>
                      <Text
                        fontSize={"13px"}
                        fontWeight={"bold"}
                        color={"#646c9a"}
                        minW={{ base: "auto", md: "115px" }}
                      >
                        Requisition:
                      </Text>
                      <Text fontSize={"13px"} color="#595d6e">
                        {state?.reqNumber}
                      </Text>
                    </HStack>
                  )}
                  <HStack>
                    <Text
                      color="#646c9a"
                      fontWeight={"bold"}
                      fontSize="13px"
                      minW={{ base: "auto", md: "115px" }}
                    >
                      Must have:
                    </Text>
                    <HStack flexWrap={"wrap"} gap="5px">
                      {state?.mustHaveSkills?.map((item: any) => (
                        <Badge
                          bg={"#ffb822"}
                          fontWeight="100"
                          px="2"
                          py="0"
                          borderRadius={"xl"}
                          fontSize="12px"
                          mt="0 !important"
                          mr="2 !important"
                          ml="0 !important"
                          key={item}
                          color={"#595d6e"}
                          textTransform={"capitalize"}
                        >
                          {item}
                        </Badge>
                      ))}
                    </HStack>
                  </HStack>
                </Box>
              </VStack>
            </CardBody>
          </Card>
          <Stack
            w="full"
            alignItems={"flex-start"}
            flexDir={{ base: "column", "2xl": "row" }}
            mt="0px !important"
            gap={"4"}
          >
            <VStack
              w={{ base: "100%", "2xl": "40%" }}
              mr={{ md: 0, lg: 6 }}
              gap={"4"}
            >
              {/* <Card w="full">
                <CardBody>
                  <Stack direction="row" spacing={4} justify="center">
                    <HStack>
                      {" "}
                      <Button
                        leftIcon={<RiThumbUpFill />}
                        colorScheme="green"
                        variant="solid"
                        my="1"
                        size={{ base: "xs", sm: "sm", "2xl": "md" }}
                      >
                        I'm Interested
                      </Button>
                      <Button
                        leftIcon={<RiThumbDownFill />}
                        colorScheme="red"
                        // bg="white"
                        // borderColor="#fd397a"
                        // color="#fd397a"
                        variant="outline"
                        my="1"
                        color="white"
                        bg="#fd397a"
                        size={{ base: "xs", sm: "sm", "2xl": "md" }}
                        _hover={{ color: "white", bg: "#F51B63" }}
                      >
                        Archive
                      </Button>
                    </HStack>
                    <HStack>
                      <IconButton
                        aria-label="Search database"
                        icon={<FiShare2 />}
                        h="30px"
                        variant="outline"
                        // my="1"
                        color="#5867DD"
                        borderColor="#5867DD"
                        _hover={{ color: "white", bg: "#5867DD" }}
                      />
                      <Menu>
                        <MenuButton
                          as={IconButton}
                          variant="ghost"
                          aria-label="See menu"
                          color="#9382dd"
                          height={"32px"}
                          my="1"
                          _hover={{
                            color: "#5867DD !important",
                            bg: "#5d78ff1a",
                          }}
                          icon={<BiDotsHorizontalRounded size="30px" />}
                        />
                        {/* <MenuList>
                          <MenuItem>Schedule an Interview</MenuItem>
                          <MenuItem>Message Candidate</MenuItem>
                        </MenuList> 
                      </Menu>
                    </HStack>
                  </Stack>
                </CardBody>
              </Card> */}
              <Card w="full">
                <CardBody>
                  <VStack
                    gap="5px"
                    flexDir={{ base: "column", sm: "row", "2xl": "column" }}
                    align="start"
                  >
                    <Flex
                      flexDir={{ base: "column", sm: "row" }}
                      w="full"
                      flexWrap={"wrap"}
                      align="start"
                    >
                      <HStack w={{ base: "100%", lg: "50%" }}>
                        <Icon
                          as={BiCoinStack}
                          fontSize="40px"
                          color="#94a0b0"
                        />
                        <VStack align={"baseline"}>
                          <Text
                            color="#595d6e"
                            fontWeight={"600"}
                            fontSize={{ base: "xs", md: "xs" }}
                          >
                            Base Salary
                          </Text>
                          {state?.salary &&
                          (state?.salary?.from !== null ||
                            state?.salary?.to !== null) ? (
                            <Text
                              color="#48465b"
                              fontWeight={"600"}
                              mt={"0px !important"}
                              fontSize={{ base: "xs", md: "sm" }}
                            >
                              {`$${
                                state?.salary?.from === null
                                  ? "0"
                                  : addCommas(state?.salary?.from)
                              } - $${
                                state?.salary?.to === null
                                  ? "0"
                                  : addCommas(state?.salary?.to)
                              }`}
                            </Text>
                          ) : (
                            <Text
                              color="#48465b"
                              fontWeight={"600"}
                              mt={"0px !important"}
                              fontSize={{ base: "xs", md: "sm" }}
                            >
                              Not Specified
                            </Text>
                          )}
                        </VStack>
                      </HStack>
                      <HStack
                        w={{ base: "100%", lg: "50%" }}
                        mt={{ base: "2", md: "0px !important" }}
                      >
                        <Icon
                          as={AiOutlineBank}
                          fontSize="40px"
                          color="#94a0b0"
                        />
                        <VStack align={"baseline"}>
                          <Text
                            color="#595d6e"
                            fontWeight={"600"}
                            fontSize={{ base: "xs", md: "xs" }}
                          >
                            Bonus - Stock
                          </Text>

                          {state?.bonus?.from !== null ? (
                            <Text
                              color="#48465b"
                              fontWeight={"600"}
                              mt={"0px !important"}
                              fontSize={{ base: "xs", md: "sm" }}
                            >
                              {`${
                                state?.bonus?.bonusType === "percentage"
                                  ? ""
                                  : "$"
                              }${
                                state?.bonus?.from === null
                                  ? "N/A"
                                  : addCommas(state?.bonus?.from)
                              }${
                                state?.bonus?.bonusType === "percentage"
                                  ? "%"
                                  : ""
                              } - ${
                                state?.stock?.from === null
                                  ? "N/A"
                                  : addCommas(state?.stock?.from)
                              }${
                                state?.stock?.stockType &&
                                state?.stock?.stockType?.toLowerCase() ===
                                  "percentage"
                                  ? "%"
                                  : ""
                              }`}
                            </Text>
                          ) : (
                            <Text
                              color="#48465b"
                              fontWeight={"600"}
                              mt={"0px !important"}
                              fontSize={{ base: "xs", md: "sm" }}
                            >
                              Not Specified
                            </Text>
                          )}
                        </VStack>
                      </HStack>
                    </Flex>
                    <Flex
                      flexDir={{ base: "column", sm: "row" }}
                      w="full"
                      flexWrap="wrap"
                      align="start"
                    >
                      <HStack w={{ base: "100%", lg: "50%" }}>
                        <Icon
                          as={BiSliderAlt}
                          fontSize="40px"
                          color="#94a0b0"
                        />
                        <VStack align={"baseline"}>
                          <Text
                            color="#595d6e"
                            fontWeight={"600"}
                            fontSize={{ base: "xs", md: "xs" }}
                          >
                            Report to
                          </Text>
                          <Text
                            color="#48465b"
                            fontWeight={"600"}
                            mt={"0px !important"}
                            fontSize={{ base: "xs", md: "sm" }}
                          >
                            {state?.createdByName?.length > 60 ? (
                              <Tooltip hasArrow label={state?.createdByName}>
                                {state?.createdByName?.slice(0, 60) + "..."}
                              </Tooltip>
                            ) : (
                              state?.createdByName
                            )}
                          </Text>
                        </VStack>
                      </HStack>
                      <HStack w={{ base: "100%", lg: "50%" }}>
                        <Icon
                          as={IoIosPeople}
                          fontSize="40px"
                          color="#94a0b0"
                        />

                        <Flex
                          direction="column"
                          cursor="pointer"
                          color="#595d6e"
                          fontWeight="600"
                          fontSize={{ base: "xs", md: "xs" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate("/jobs/view-applicant", {
                              state: { data: state },
                            });
                          }}
                        >
                          <Text _hover={{ textDecor: "underline" }}>
                            Job
                            {state?.totalApplicants === 1
                              ? "Applicant "
                              : "Applicants "}{" "}
                            <span style={{ color: "#3182ce" }}>(View)</span>
                          </Text>
                          {state?.totalApplicants || 0}
                        </Flex>
                      </HStack>
                      {/* <HStack
                        w={{ base: "100%", lg: "50%" }}
                        mt={{ base: "2", md: "0px !important" }}
                      >
                        <Icon
                          as={BsFileEarmarkText}
                          fontSize="40px"
                          color="#94a0b0"
                        />
                        <VStack align={"baseline"}>
                          <Text
                            color="#595d6e"
                            fontWeight={"600"}
                            fontSize={{ base: "xs", md: "xs" }}
                          >
                            Other Benefits
                          </Text>
                          <Popover>
                            <PopoverTrigger>
                              <Text
                                color="blue.500"
                                fontWeight={"bold"}
                                mt={"0px !important"}
                                cursor={"pointer"}
                                fontSize={{ base: "xs", md: "sm" }}
                              >
                                View
                              </Text>
                            </PopoverTrigger>
                            <PopoverContent>
                              <PopoverArrow />
                              <PopoverCloseButton color="white" />
                              <PopoverHeader bg="#5D78FF" color="white">
                                Benefits!
                              </PopoverHeader>
                              <PopoverBody>
                                <VStack align={"baseline"}>
                                  {state?.employeeBenefitList?.length ? (
                                    state?.employeeBenefitList.map(
                                      (item: any, index: any) => {
                                        return (
                                          <Text>
                                            {index + 1}
                                            {`. ${item}`}
                                          </Text>
                                        );
                                      }
                                    )
                                  ) : (
                                    <Text>No Data</Text>
                                  )}
                                </VStack>
                              </PopoverBody>
                            </PopoverContent>
                          </Popover>
                        </VStack>
                      </HStack> */}
                    </Flex>
                  </VStack>
                </CardBody>
              </Card>
            </VStack>
            <Stack
              w={{ base: "100%", "2xl": "60%" }}
              mt={{
                base: "0 !important",
                md: "0 important",
                xl: "0 !important",
              }}
            >
              <Card w="full">
                <CardBody>
                  <Tabs>
                    <TabList>
                      <Tab>Position Summary</Tab>
                      {/* <Tab>
                          <Icon
                            as={BiTime}
                            fontSize="x-large"
                            color={"gray.500"}
                          />
                          Activities
                        </Tab> */}
                    </TabList>

                    <TabPanels>
                      <TabPanel>
                        <VStack spacing={6}>
                          <Card w="full" overflow="hidden">
                            <CardBody bg="#f7f8fc" w="full">
                              <VStack w="full" align={"baseline"}>
                                <Text
                                  textAlign={"justify"}
                                  fontSize={"13px"}
                                  color={"#646c9a"}
                                >
                                  {state?.description}
                                </Text>
                              </VStack>
                            </CardBody>
                          </Card>
                        </VStack>
                      </TabPanel>
                      {/* <TabPanel>
                          <p>two!</p>
                        </TabPanel> */}
                    </TabPanels>
                  </Tabs>
                </CardBody>
              </Card>
            </Stack>
          </Stack>
        </VStack>
      </VStack>
    </>
  );
};

export default JobDetail;
