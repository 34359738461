import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  useAddLocation,
  useFetchLocation,
} from "../../../../service/education.service";
import { getUserId } from "../../../../utils/helper";

const useLocations = () => {
  const [citiesList, setCitiesList] = useState<any>([]);
  const [currentLocation, setCurrentLocation] = useState<any>(null);
  const [selectedPlace, setSelectedPlace] = useState<any>("");

  const {
    mutate: locationMutation,
    isLoading: locationLoading,
    data: locationData,
  } = useAddLocation();
  const userId = getUserId();
  const { isLoading: experienceListLoading, data: locationDataList } =
    useFetchLocation(userId);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      location: "",
      fullName: "",
      email: "",
      linkedInProfile: "",
      remoteWorkPreference: "",
      preferredLocationToWork: "",
    },
  });
  useEffect(() => {
    if (locationDataList?.userId) {
      setValue("fullName", locationDataList.fullName);
      trigger("fullName");
      localStorage.setItem("fullName", locationDataList.fullName);
      setValue("linkedInProfile", locationDataList.linkedInProfile);
      trigger("linkedInProfile");
      setValue("location", locationDataList?.userLocationDto?.address);
      trigger("location");
      setValue("remoteWorkPreference", locationDataList.remoteWorkPreference);
      trigger("remoteWorkPreference");
      setValue(
        "preferredLocationToWork",
        locationDataList.preferredLocationToWork
      );
      trigger("preferredLocationToWork");
      const uniqueCities = new Set<string>(
        locationDataList.preferredLocationList
      );
      setCurrentLocation(
        JSON.parse(locationDataList?.userLocationDto?.address)
      );
      const uniqueCitiesArray = Array.from(uniqueCities);
      setCitiesList(uniqueCitiesArray);
    }
  }, [locationDataList]);
  const navigate = useNavigate();

  const onSubmit = async (values: any) => {
    const formDate = {
      userId,
      fullName: values.fullName.trim(),
      linkedInProfile: values.linkedInProfile,
      userLocationDto: {
        address: JSON.stringify(currentLocation),
        city: "",
        state: "",
      },

      remoteWorkPreference: values.remoteWorkPreference,
      preferredLocationToWork:
        values.preferredLocationToWork !== ""
          ? values.preferredLocationToWork
          : "NONE",
      preferredLocationList: citiesList,
    };
    await locationMutation(formDate, {
      onSuccess: (resData) => {
        {
          watch("linkedInProfile") === ""
            ? navigate("/setupFlow/resume")
            : navigate("/setupFlow/personalizeJobSearch");
        }
      },
    });
  };

  return {
    onSubmit,
    register,
    errors,
    handleSubmit,
    watch,
    locationLoading,
    control,
    setCitiesList,
    citiesList,
    currentLocation,
    setCurrentLocation,
    selectedPlace,
    setSelectedPlace,
  };
};

export default useLocations;
