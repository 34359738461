import { handleLogoutLocalStorage } from "./helper";
const useLogout = () => {
  const logoutHandler = () => {
    handleLogoutLocalStorage();
  };

  return { logoutHandler };
};

export default useLogout;
