import { Card, Flex, Grid, Heading, Text } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import useTalent from "./useTalent";
import LoadingScreen from "../../common/loadingScreen";
import ArchivedTalentCard from "./archivedTalentCard";
import ReactPaginate from "react-paginate";

const ArchivedTalent = () => {
  const { pathname } = useLocation();
  const { candidateList, candidateLoading, itemOffset, setItemOffset } =
    useTalent();

  const handlePageClick = (event: any) => {
    const newOffset = event.selected;
    setItemOffset(newOffset);
  };

  return (
    <>
      <Flex w="full" justify={"center"} m="auto" p="4" py="8" bg="#f0f1f7">
        {candidateLoading ? (
          <LoadingScreen />
        ) : candidateList?.data?.length ? (
          <>
            <Flex
              flexDirection={"column"}
              justify="space-between"
              minH="75vh"
              w="full"
            >
              <Grid
                w="100% !important"
                templateColumns={[
                  "1fr",
                  "1fr",
                  "1fr",
                  "repeat(2, 1fr)",
                  "repeat(2, 1fr)",
                  "repeat(3, 1fr)",
                ]}
                //   p="2"
                gap={4}
                overflowX="auto"
                width="100%"
                // width={["100%", "100%", "100%", "100%", "100%", "auto"]}
                sx={{
                  "&::-webkit-scrollbar": {
                    height: "4px",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "transparent",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "gray.400",
                    borderRadius: "full",
                  },
                }}
              >
                {" "}
                {candidateList?.data?.map((data: any, index: number) => (
                  <ArchivedTalentCard key={index} data={data} />
                ))}
              </Grid>
              <ReactPaginate
                nextLabel="Next >"
                onPageChange={handlePageClick}
                forcePage={itemOffset}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={candidateList.totalPages ?? 1}
                previousLabel="< Previous"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                breakLabel="..."
                nextClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName={"container"}
                previousLinkClassName={"page"}
                breakClassName={"page"}
                nextLinkClassName={"page"}
                pageClassName={"page"}
                disabledClassName={"disabled"}
                activeClassName={"active-venu-button"}
                // renderOnZeroPageCount={null}
              />
            </Flex>
          </>
        ) : (
          <Card p={4} boxShadow="md" borderRadius="md" py="10" w="full">
            <Heading size="md" mb={4} color="#48465b">
              Archived Talent Pool
            </Heading>

            <Text color="#646c9a">
              Currently, there are no archived talents. Start archiving
              candidates now for future opportunities!
            </Text>
          </Card>
        )}
      </Flex>
    </>
  );
};

export default ArchivedTalent;
