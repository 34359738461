import { useMutation, useQuery, useQueryClient } from "react-query";

import { useToast } from "@chakra-ui/react";

import { useFetch } from "../utils/useFetch";

const addExperience = async (data: any) => {
  const { data: response } = await useFetch.post(
    "/users/talent/experience/edit-experience",
    data
  );

  return response;
};
export const useAddExperience = () => {
  const queryClient = useQueryClient();
  const toast = useToast();
  return useMutation(addExperience, {
    onSuccess: (data: any) => {
      queryClient.invalidateQueries("experience-fetch");
      toast({
        title: "Experience added successfully",
        status: "success",
        variant: "top-accent",
        isClosable: true,
      });
    },
    onError: (error: any) => {
      toast({
        title: "Failed to Add Experience",
        description: `${error.response.data.message}`,
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });
    },
  });
};
const fetchAllExperience = async (id: any) => {
  const { data: response } = await useFetch.get(
    `users/talent/experience/all-position?userId=${id}`
  );

  return response?.body ? response?.body : response;
};

export const useFetchAllExperience = (data: any) => {
  const toast = useToast();
  return useQuery(["experience-fetch", data], () => fetchAllExperience(data), {
    onSuccess: (data: any) => {},
    onError: (error: any) => {
      toast({
        title: "Error Getting Experience list",
        description: `Something Went Wrong`,
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });
    },
  });
};
const deleteExperience = async ({ positionId, userId }: any) => {
  const { data: response } = await useFetch.delete(
    `/users/talent/experience/delete-experience-by-id?userId=${userId}&positionId=${positionId}`
  );
  return response;
};
export const useDeleteExperience = () => {
  const queryClient = useQueryClient();
  const toast = useToast();
  return useMutation(deleteExperience, {
    onSuccess: (data: any) => {
      queryClient.invalidateQueries("experience-fetch");
      toast({
        title: ` Experience data  is deleted !`,
        status: "success",
        variant: "top-accent",
        isClosable: true,
      });
    },
    onError: (error: any) => {
      toast({
        title: "Failed to Delete Experience data",
        description: `${error.response.data.message}`,
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });
    },
  });
};
const updateExperience = async (data: any) => {
  const { data: response } = await useFetch.put(
    "/users/talent/experience/update-talent-experience",
    data
  );

  return response;
};
export const useUpdateExperience = () => {
  const queryClient = useQueryClient();
  const toast = useToast();
  return useMutation(updateExperience, {
    onSuccess: (data: any) => {
      queryClient.invalidateQueries("experience-fetch");
      toast({
        title: "Experience updated successfully",
        status: "success",
        variant: "top-accent",
        isClosable: true,
      });
    },
    onError: (error: any) => {
      toast({
        title: "Failed to update experience",
        description: `${error.response.data.message}`,
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });
    },
  });
};
