import React from "react";
import { Wizard, useWizard } from "react-use-wizard";
import {
  Box,
  Input,
  HStack,
  Heading,
  Stack,
  Card,
  VStack,
  FormControl,
  FormLabel,
  Text,
  Divider,
  InputGroup,
  InputLeftAddon,
  Select,
  Button,
  Center,
  Progress,
} from "@chakra-ui/react";
import CheckBoxButton from "../../common/checkboxButton";
import RadioGroup from "../..//common/radioGroup";
import useJobPost from "./useJobPost";
import { IoIosArrowBack } from "react-icons/io";
import BasicInfoJob from "./partials/basicInfo";
import JobFunc from "./partials/jobFunc";
import PeopleManagement from "./partials/peopleManagemnet";
import LocationJob from "./partials/locationJob";
import EducationJob from "./partials/educationJob";
import SkillsJob from "./partials/skillsJob";
import Qualification from "./partials/qualification";
import Sponsorship from "./partials/sponsorship";
import Benefits from "./partials/benefits";
import Salary from "./partials/salary";
import Responsibilities from "./partials/responsibilities";

const JobPost = () => {
  const {
    setSearchTerm,
    setPeopleManagement,
    setLocationType,
    setSponsorshipType,
    setMinEducationType,
    searchTerm,
    jobFunctionList,
    peopleManagement,
    locationsType,
    sponsorshipType,
    handleOptionSelection,
    optionsJobFunc,
    optionsLocations,
    optionRemoteLocations,
    optionsResponsibilities,
    optionsIndustry,
    optionsBenefits,
    optionsSponsorship,
    optionCollegeMajor,
    optionsDomain,
    optionsSkills,
    handleInputChange,
    reqNumber,
    name,
    handleSalary,
    salary,
    handleBonus,
    bonus,
    handleStock,
    stock,
    handleSubmit,
    navigate,
    preferDregeeList,
    handlePreferDregeeList,
    locationsList,
    customLocationList,
    setCustomLocationList,
    setLocationList,
    handleLocationList,
    responsibilitiesList,
    handleResponsibilitiesList,
    responsibilitiesList2,
    handleResponsibilities2,
    industryExperienceList,
    handleIndustryList,
    employeeBenefitList,
    handleEmployeeBenefitList,
    sponsorshipList,
    handleSponsorshipList,
    collegeMajorList,
    handleCollegeMajorList,
    domainList,
    handleDomainList,
    topSkillList,
    handleTopSkillList,
    setReports,
    setSeniorityLevel,
    handleExperience,
    reports,
    seniorityLevel,
    experience,
    minEducation,
    state,
    jobLoading,
    setJobFunctionList,
    jobFunctionListOption,
    description,
    setDescription,
    jobUpdateLoading,
    preferredCollegeMajors,
    setPreferredCollegeMajors,
    mustHaveSkills,
    setMustHaveSkills,
    niceToHaveSkills,
    setNiceToHaveSkills,
    searchLocationList,
    setSearchLocationList,
  } = useJobPost();

  return (
    <VStack
      w={{ base: "full", md: "80%", lg: "80%", xl: "60%" }}
      m="auto"
      spacing={"4"}
      p="4"
      py="8"
      bg="#f0f1f7"
    >
      <Card p="4" w="full">
        <Box my="4">
          <Stack
            direction="row"
            spacing={4}
            align="center"
            justify="space-between"
            w="full"
          >
            <Heading fontSize={"xl"} color="#646c9a">
              {state?.isEdit ? "Update a Job" : "Create a Job"}
            </Heading>
            <Button
              loadingText="Loading"
              color="#74788d"
              spinnerPlacement="end"
              size="sm"
              fontWeight={"bold"}
              _hover={{ color: "white", bg: "#5867DD" }}
              // leftIcon={<IoIosArrowBack />}
              onClick={() => navigate("/jobs")}
            >
              Back
            </Button>
          </Stack>
        </Box>
      </Card>

      <Wizard>
        <BasicInfoJob
          handleInputChange={handleInputChange}
          description={description}
          setDescription={setDescription}
        />
        <JobFunc
          handleInputChange={handleInputChange}
          reqNumber={reqNumber}
          name={name}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          handleOptionSelection={handleOptionSelection}
          jobFunctionList={jobFunctionList}
          setJobFunctionList={setJobFunctionList}
          state={state}
          jobFunctionListOption={jobFunctionListOption}
          optionsJobFunc={optionsJobFunc}
          setSeniorityLevel={setSeniorityLevel}
          seniorityLevel={seniorityLevel}
          setLocationType={setLocationType}
          locationsType={locationsType}
          handleLocationList={handleLocationList}
          optionsLocations={optionsLocations}
          optionRemoteLocations={optionRemoteLocations}
          locationsList={locationsList}
          setLocationList={setLocationList}
          customLocationList={customLocationList}
          setCustomLocationList={setCustomLocationList}
          searchLocationList={searchLocationList}
          setSearchLocationList={setSearchLocationList}
        />

        <EducationJob
          preferredCollegeMajors={preferredCollegeMajors}
          setPreferredCollegeMajors={setPreferredCollegeMajors}
          handleExperience={handleExperience}
          setMinEducationType={setMinEducationType}
          handlePreferDregeeList={handlePreferDregeeList}
          preferDregeeList={preferDregeeList}
          jobFunctionList={jobFunctionList}
          experience={experience}
          minEducation={minEducation}
          setSponsorshipType={setSponsorshipType}
          sponsorshipType={sponsorshipType}
          handleSponsorshipList={handleSponsorshipList}
          optionsSponsorship={optionsSponsorship}
          sponsorshipList={sponsorshipList}
          handleTopSkillList={handleTopSkillList}
          topSkillList={topSkillList}
          optionsSkills={optionsSkills}
          mustHaveSkills={mustHaveSkills}
          setMustHaveSkills={setMustHaveSkills}
          niceToHaveSkills={niceToHaveSkills}
          setNiceToHaveSkills={setNiceToHaveSkills}
        />

        <Salary
          handleSalary={handleSalary}
          salary={salary}
          handleBonus={handleBonus}
          bonus={bonus}
          handleStock={handleStock}
          stock={stock}
          handleSubmit={handleSubmit}
          state={state}
          jobLoading={jobLoading}
          jobUpdateLoading={jobUpdateLoading}
        />
        {/* 
        <Responsibilities
          handleResponsibilitiesList={handleResponsibilitiesList}
          optionsResponsibilities={optionsResponsibilities}
          responsibilitiesList={responsibilitiesList}
          setSearchTerm={setSearchTerm}
          handleResponsibilities2={handleResponsibilities2}
          responsibilitiesList2={responsibilitiesList2}
          searchTerm={searchTerm}
          handleSubmit={handleSubmit}
          jobLoading={jobLoading}
          jobUpdateLoading={jobUpdateLoading}
          state={state}
        /> */}
      </Wizard>
    </VStack>
  );
};

export default JobPost;
