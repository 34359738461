import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { useToast } from "@chakra-ui/react";
import { useFetch } from "../utils/useFetch";

const login = async (data: any) => {
  const { data: response } = await useFetch.post("/users/auth/login", data);
  return response;
};
export const useLoginApi = () => {
  const toast = useToast();
  return useMutation(login, {
    onError: (error: any) => {
      toast({
        title: "Failed to login",
        description: `${error.response.data.message}`,
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });
    },
  });
};
const signUp = async (data: any) => {
  const { data: response } = await useFetch.post("/users/auth/register", data);
  return response;
};
export const useSignupApi = () => {
  const toast = useToast();
  return useMutation(signUp, {
    onError: (error: any) => {
      toast({
        title: `${error.response.data}`,
        // description: `${error.response.data}`,
        // description: `This user does not exist`,
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });
    },
  });
};
const verify = async (data: any) => {
  const { data: response } = await useFetch.post(
    "/users/auth/verify-otp",
    data
  );
  return response;
};

export const useVerifyAPI = () => {
  const [errorMessage, setErrorMessage] = useState("");

  const mutation = useMutation(verify, {
    onError: (error: any) => {
      setErrorMessage("This OTP is Invalid");
    },
  });

  return { ...mutation, errorMessage };
};

const addOtherInfo = async (data: any) => {
  const { data: response } = await useFetch.post(
    "/users/talent/edit-other-detail",
    data
  );

  return response;
};

export const useAddOtherInfo = () => {
  const queryClient = useQueryClient();
  const toast = useToast();
  return useMutation(addOtherInfo, {
    onSuccess: (data: any) => {
      queryClient.invalidateQueries("otherInfo-fetch");
      toast({
        title: "Other Information added successfully",
        status: "success",
        variant: "top-accent",
        isClosable: true,
      });
    },
    onError: (error: any) => {
      toast({
        title: "Failed to Add Other Information",
        description: `${error.response.data.message}`,
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });
    },
  });
};

const fetchAllOtherInfo = async (id: any) => {
  const { data: response } = await useFetch.get(
    `users/talent/find-other-detail-by-Id?userId=${id}`
  );

  return response ? response : {};
};

export const useFetchAllOtherInfo = (data: any) => {
  const toast = useToast();
  return useQuery(["otherInfo-fetch", data], () => fetchAllOtherInfo(data), {
    onSuccess: (data: any) => {},
    onError: (error: any) => {
      toast({
        title: "Error Getting Other information",
        description: `Something Went Wrong`,
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });
    },
  });
};

const updateOtherInfo = async (data: any) => {
  const { data: response } = await useFetch.put(
    "/users/talent/update-other-details",
    data
  );

  return response;
};
export const useUpdateOtherInfo = () => {
  const queryClient = useQueryClient();
  const toast = useToast();
  return useMutation(updateOtherInfo, {
    onSuccess: (data: any) => {
      queryClient.invalidateQueries("otherInfo-fetch");
      toast({
        title: "Other Information added successfully",
        status: "success",
        variant: "top-accent",
        isClosable: true,
      });
    },
    onError: (error: any) => {
      toast({
        title: "Failed to Add Other Information",
        description: `${error.response.data.message}`,
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });
    },
  });
};
