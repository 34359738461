import { useEffect, useRef, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Flex,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  HStack,
  Input,
  Button,
  Tag,
  TagCloseButton,
  TagLabel,
  VStack,
  useToast,
  Select,
} from "@chakra-ui/react";
import { FaFilter } from "react-icons/fa";

import { useFetch } from "../../../../utils/useFetch";
import { stageOptions } from "../../../../utils/helper";
const seniorityLevelLookup: any = [
  "Entry-level",
  "Mid-level, hands-on",
  "Senior-level, hands-on",
  "Manager, hands-on",
  "Manager, with direct reports",
];
const PipelineFilter = ({
  inputref,
  error,
  topSkills,
  isSkillsFocused,
  setIsSkillsFocused,
  setTopSkills,
  selected,
  jobFunctionList,
  setJobFunctionList,
  jobFuncDisplayList,
  setJobFuncDisplayList,
  seniorityLevelList,
  setSeniorityLevelList,
  setSelected,
  skills,
  setSkills,
  showCustomInput,
  setShowCustomInput,
  searchTopSkillsResult,
  setSearchTopSkillsResult,
  setError,
  handleAddTopSkills,
  handleRemoveJobFunction,
  handleAddJobFunctions,
  handleRemoveSeniorityLevel,
  handleAddSeniorityList,
  handleSkillsFocusBlur,
  handleRemoveTopSkills,
  showTopSkillsResult,
  setShowTopSkillsResult,
  domainDisplayList,
  handleRemoveDomain,
  handleAddDomainList,
  domainList,
  handleRemoveSkill,
  handleAddWorkPermit,
  handleRemoveWorkPermit,
  workPermitList,
  handleAddLocation,
  handleRemoveLcoation,
  locationList,
  stageList,
  hiringTeamList,
  jobRequisitionList,
  handleAddStages,
  handleRemoveStages,
  handleAddHiringTeam,
  handleRemoveHiringTeam,
  handleAddJobRequisition,
  handleRemoveJobRequisition,
  requisition,
  setRequisition,
  hiringTeamResults,
  applyFilters,
}: any) => {
  return (
    <>
      <Flex direction="column" w="300px">
        <Card>
          <CardHeader borderBottom="1px solid #ECEDF3">
            <Flex align="center" w="full" justify="space-between">
              <Flex
                align="center"
                gap="10px"
                color="#646c9a"
                fontWeight="500"
                fontSize="1.2rem"
              >
                <FaFilter />
                <Text>Filter</Text>
              </Flex>
              <Button
                size="sm"
                colorScheme="buttonPrimary"
                onClick={applyFilters}
              >
                Apply Filter
              </Button>
            </Flex>
          </CardHeader>
          <CardBody>
            <Accordion
              allowToggle
              border="1px solid #ECEDF3"
              position="relative"
            >
              <AccordionItem>
                <h2>
                  <AccordionButton
                    color="#595d6e"
                    bg="#f7f8fa"
                    padding="0.75rem 1.25rem"
                  >
                    <Box as="span" flex="1" textAlign="left">
                      Job Function
                    </Box>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Flex direction="column" padding="10px 1px" gap="10px">
                    <Select
                      onChange={(e) => {
                        setSelected(e.target.value);
                        handleAddJobFunctions(e.target.value);
                      }}
                      placeholder="Select job functions"
                      value={""}
                    >
                      {jobFuncDisplayList?.map((data: any, key: number) => (
                        <option key={key} value={data.name}>
                          {data.name}
                        </option>
                      ))}
                    </Select>

                    <Flex gap={"10px"} wrap="wrap">
                      {jobFunctionList.map(
                        (functions: string, index: number) => (
                          <Tag
                            key={index}
                            variant="outline"
                            border="1px solid #5d78ff"
                            borderRadius="20px"
                            color="#595d6e"
                          >
                            <TagLabel>{functions}</TagLabel>
                            <TagCloseButton
                              onClick={() => handleRemoveJobFunction(index)}
                            />
                          </Tag>
                        )
                      )}
                    </Flex>
                  </Flex>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <h2>
                  <AccordionButton
                    color="#595d6e"
                    bg="#f7f8fa"
                    padding="0.75rem 1.25rem"
                  >
                    <Box as="span" flex="1" textAlign="left">
                      Seniority
                    </Box>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Flex direction="column" padding="10px 1px" gap="10px">
                    <Select
                      onChange={(e) => handleAddSeniorityList(e.target.value)}
                      placeholder="Select Seniority Level"
                      value={""}
                    >
                      {seniorityLevelLookup?.map((item: string) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </Select>
                    <Flex gap={"10px"} wrap="wrap">
                      {seniorityLevelList.map(
                        (seniority: string, index: number) => (
                          <Tag
                            key={index}
                            variant="outline"
                            border="1px solid #5d78ff"
                            borderRadius="20px"
                            color="#595d6e"
                          >
                            <TagLabel>{seniority}</TagLabel>
                            <TagCloseButton
                              onClick={() => handleRemoveSeniorityLevel(index)}
                            />
                          </Tag>
                        )
                      )}
                    </Flex>
                  </Flex>
                </AccordionPanel>
              </AccordionItem>
              {/* <AccordionItem>
                <h2>
                  <AccordionButton
                    color="#595d6e"
                    bg="#f7f8fa"
                    padding="0.75rem 1.25rem"
                  >
                    <Box as="span" flex="1" textAlign="left">
                      Domain
                    </Box>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Flex direction="column" padding="10px 1px" gap="10px">
                    <Select
                      placeholder="Select Domain"
                      onChange={(e) => handleAddDomainList(e.target.value)}
                    >
                      {domainDisplayList.map((item: any) => (
                        <option value={item?.name}>{item?.name}</option>
                      ))}
                    </Select>
                    <Flex gap={"10px"} wrap="wrap">
                      {domainList.map((seniority: string, index: number) => (
                        <Tag
                          key={index}
                          variant="outline"
                          border="1px solid #5d78ff"
                          borderRadius="20px"
                          color="#595d6e"
                        >
                          <TagLabel>{seniority}</TagLabel>
                          <TagCloseButton
                            onClick={() => handleRemoveDomain(index)}
                          />
                        </Tag>
                      ))}
                    </Flex>
                  </Flex>
                </AccordionPanel>
              </AccordionItem> */}
              <AccordionItem>
                <h2>
                  <AccordionButton
                    color="#595d6e"
                    bg="#f7f8fa"
                    padding="0.75rem 1.25rem"
                  >
                    <Box as="span" flex="1" textAlign="left">
                      Skills
                    </Box>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Flex direction="column" padding="10px 1px" gap="10px">
                    <Select
                      placeholder="Select Skill"
                      onChange={(e) => handleAddTopSkills(e.target.value)}
                      value={""}
                    >
                      {searchTopSkillsResult.map((item: any) => (
                        <option value={item?.name}>{item?.name}</option>
                      ))}
                    </Select>
                    <Flex gap={"10px"} wrap="wrap">
                      {topSkills.map((skill: string, index: number) => (
                        <Tag
                          key={index}
                          variant="outline"
                          border="1px solid #5d78ff"
                          borderRadius="20px"
                          color="#595d6e"
                        >
                          <TagLabel>{skill}</TagLabel>
                          <TagCloseButton
                            onClick={() => handleRemoveSkill(index)}
                          />
                        </Tag>
                      ))}
                    </Flex>
                  </Flex>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton
                    color="#595d6e"
                    bg="#f7f8fa"
                    padding="0.75rem 1.25rem"
                  >
                    <Box as="span" flex="1" textAlign="left">
                      Work Permit
                    </Box>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Flex direction="column" padding="10px 1px" gap="10px">
                    <Select
                      onChange={(e) => handleAddWorkPermit(e.target.value)}
                      placeholder="Select work permit"
                      value={""}
                    >
                      {[
                        "Yes",
                        "No",
                        "Don't know / not decided yet",
                        "It depends",
                      ]?.map((data: any, key: number) => (
                        <option key={key} value={data}>
                          {data}
                        </option>
                      ))}
                    </Select>

                    <Flex gap={"10px"} wrap="wrap">
                      {workPermitList.map((permit: string, index: number) => (
                        <Tag
                          key={index}
                          variant="outline"
                          border="1px solid #5d78ff"
                          borderRadius="20px"
                          color="#595d6e"
                        >
                          <TagLabel>{permit}</TagLabel>
                          <TagCloseButton
                            onClick={() => handleRemoveWorkPermit(index)}
                          />
                        </Tag>
                      ))}
                    </Flex>
                  </Flex>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton
                    color="#595d6e"
                    bg="#f7f8fa"
                    padding="0.75rem 1.25rem"
                  >
                    <Box as="span" flex="1" textAlign="left">
                      Location
                    </Box>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Flex direction="column" padding="10px 1px" gap="10px">
                    <Select
                      onChange={(e) => handleAddLocation(e.target.value)}
                      placeholder="Select Location"
                      value={""}
                    >
                      {["Remote", "In Office", "Hybrid"]?.map(
                        (data: any, key: number) => (
                          <option key={key} value={data}>
                            {data}
                          </option>
                        )
                      )}
                    </Select>

                    <Flex gap={"10px"} wrap="wrap">
                      {locationList?.map((location: string, index: number) => (
                        <Tag
                          key={index}
                          variant="outline"
                          border="1px solid #5d78ff"
                          borderRadius="20px"
                          color="#595d6e"
                        >
                          <TagLabel>{location}</TagLabel>
                          <TagCloseButton
                            onClick={() => handleRemoveLcoation(index)}
                          />
                        </Tag>
                      ))}
                    </Flex>
                  </Flex>
                </AccordionPanel>
              </AccordionItem>
              {/* <AccordionItem>
                <h2>
                  <AccordionButton
                    color="#595d6e"
                    bg="#f7f8fa"
                    padding="0.75rem 1.25rem"
                  >
                    <Box as="span" flex="1" textAlign="left">
                      Stage
                    </Box>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Flex direction="column" padding="10px 1px" gap="10px">
                    <Select
                      onChange={(e) => handleAddStages(e.target.value)}
                      placeholder="Select Stage"
                      value={selected}
                    >
                      {stageOptions?.map((stage: any) => (
                        <option key={stage?.id} value={stage?.value}>
                          {stage?.name}
                        </option>
                      ))}
                    </Select>

                    <Flex gap="10px" wrap="wrap">
                      {stageList?.map((stage: string, index: number) => (
                        <Tag
                          key={index}
                          variant="outline"
                          border="1px solid #5d78ff"
                          borderRadius="20px"
                          color="#595d6e"
                        >
                          <TagLabel>{stage}</TagLabel>
                          <TagCloseButton
                            onClick={() => handleRemoveStages(index)}
                          />
                        </Tag>
                      ))}
                    </Flex>
                  </Flex>
                </AccordionPanel>
              </AccordionItem> */}

              <AccordionItem>
                <h2>
                  <AccordionButton
                    color="#595d6e"
                    bg="#f7f8fa"
                    padding="0.75rem 1.25rem"
                  >
                    <Box as="span" flex="1" textAlign="left">
                      Hiring Team
                    </Box>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Flex direction="column" padding="10px 1px" gap="10px">
                    <Select
                      onChange={(e: any) => handleAddHiringTeam(e.target.value)}
                      placeholder="Select Hiring Team"
                      value={""}
                    >
                      {hiringTeamResults
                        ?.filter((item: any) => item?.fullName)
                        .map((data: any) => (
                          <option key={data?.id} value={JSON.stringify(data)}>
                            {data?.fullName}
                          </option>
                        ))}
                    </Select>

                    <Flex gap={"10px"} wrap="wrap">
                      {hiringTeamList?.map((member: any, index: number) => (
                        <Tag
                          key={index}
                          variant="outline"
                          border="1px solid #5d78ff"
                          borderRadius="20px"
                          color="#595d6e"
                        >
                          <TagLabel>{member?.fullName}</TagLabel>
                          <TagCloseButton
                            onClick={() => handleRemoveHiringTeam(index)}
                          />
                        </Tag>
                      ))}
                    </Flex>
                  </Flex>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton
                    color="#595d6e"
                    bg="#f7f8fa"
                    padding="0.75rem 1.25rem"
                  >
                    <Box as="span" flex="1" textAlign="left">
                      Job Requisition
                    </Box>
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Flex direction="column" padding="10px 1px" gap="10px">
                    <HStack>
                      <Input
                        type="text"
                        placeholder="Add Job Requisition"
                        maxW={{ base: "100%", md: "70%" }}
                        borderColor="grey"
                        onChange={(e) => setRequisition(e.target.value)}
                        value={requisition}
                        size="sm"
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.stopPropagation();
                            e.preventDefault();
                            handleAddJobRequisition(requisition);
                          }
                        }}
                      />
                      <Button
                        ml="2"
                        colorScheme="buttonPrimary"
                        onClick={() => handleAddJobRequisition(requisition)}
                        size="sm"
                      >
                        Add
                      </Button>
                    </HStack>

                    <Flex gap={"10px"} wrap="wrap">
                      {jobRequisitionList.map(
                        (requisition: string, index: number) => (
                          <Tag
                            key={index}
                            variant="outline"
                            border="1px solid #5d78ff"
                            borderRadius="20px"
                            color="#595d6e"
                          >
                            <TagLabel>{requisition}</TagLabel>
                            <TagCloseButton
                              onClick={() => handleRemoveJobRequisition(index)}
                            />
                          </Tag>
                        )
                      )}
                    </Flex>
                  </Flex>
                </AccordionPanel>{" "}
              </AccordionItem>
            </Accordion>
          </CardBody>
        </Card>
      </Flex>
    </>
  );
};

export default PipelineFilter;
