import React, { useEffect, useState } from "react";
import { getUserId } from "../../../utils/helper";
import {
  useFetchCandidates,
  useFetchCandidatesArchived,
} from "../../../service/candidate.service";
import { useFetchJobFunc } from "../../../service/helper.service";

const useTalent = () => {
  // const [jobFunction, setjobFunction] = useState("");
  // const [query, setQuery] = useState("");
  const [itemOffset, setItemOffset] = useState(0);

  const [initialFetch, setInitialFetch] = useState(true);
  const userId = getUserId();
  // const {
  //   isLoading: loadingJobFunc,
  //   data: jobFunctionListOption,
  //   isSuccess,
  // } = useFetchJobFunc(userId);
  const {
    isLoading: candidateLoading,
    data: candidateList,
    refetch: refetchCandidate,
  } = useFetchCandidatesArchived(itemOffset);

  // const selectJobFunc = (e: any) => {
  //   if (e.target.value === "All") {
  //     setjobFunction("");
  //   } else {
  //     setjobFunction(e.target.value);
  //   }
  // };

  useEffect(() => {
    if (initialFetch) {
      refetchCandidate();
      setInitialFetch(false);
    } else {
      const delayDebounceFn = setTimeout(() => {
        refetchCandidate();
      }, 1000);

      return () => {
        clearTimeout(delayDebounceFn);
      };
    }
  }, [initialFetch]);
  useEffect(() => {
    refetchCandidate();
  }, [itemOffset]);

  // const handleSearch = (event: any) => {
  //   setQuery(event.target.value);
  // };

  return {
    candidateList,
    candidateLoading,
    itemOffset,
    setItemOffset,
  };
};

export default useTalent;
