import { useEffect } from "react";
import axios from "axios";
import useLogout from "./useLogout";
const useFetch = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  // baseURL: "http://192.168.10.154:8001",
});

useFetch.interceptors.request.use(
  async (config: any) => {
    config.headers = config.headers;
    if (localStorage.getItem("access_token")) {
      const accessToken: any = localStorage.getItem("access_token");
      const token = JSON.parse(accessToken);
      config.headers!.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error: any) {
    return Promise.reject(error);
  }
);

const AxiosInterceptor = ({ children }: any) => {
  const { logoutHandler } = useLogout();

  useEffect(() => {
    const interceptor = useFetch.interceptors.response.use(
      function (response: any) {
        return response;
      },
      function (error: any) {
        if (error?.response?.status === 401) {
          logoutHandler();
          window.location.href = "/";
        }
        return Promise.reject(error);
      }
    );

    return () => useFetch.interceptors.response.eject(interceptor);
  }, []);

  return children;
};

export { useFetch, AxiosInterceptor };
