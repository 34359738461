import {
  Button,
  FormControl,
  Flex,
  Heading,
  Input,
  Stack,
  Text,
  useColorModeValue,
  Avatar,
  FormErrorMessage,
} from "@chakra-ui/react";
import useForgotPass from "./useForgotPass";

export default function ForgotPasswordForm(): JSX.Element {
  const { register, handleSubmit, errors, onSubmit } = useForgotPass();

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      backgroundColor="gray.50"
      flexDir={"column"}
    >
      <Stack align={"center"}>
        <Avatar bg="#5D78FF" size="lg" mb="2" />
        <Heading fontSize={"2xl"} textAlign={"center"} fontWeight={"500"}>
          Forgot your password?
        </Heading>
      </Stack>
      <Stack
        spacing={4}
        w={"full"}
        maxW={"md"}
        bg={useColorModeValue("white", "gray.700")}
        rounded={"xl"}
        boxShadow={"lg"}
        p={6}
        my={12}
      >
        <Text
          fontSize={{ base: "sm", sm: "md" }}
          color={useColorModeValue("gray.800", "gray.400")}
        >
          You&apos;ll get an email with a reset link
        </Text>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl isInvalid={Boolean(errors?.email)}>
            <Input
              placeholder="your email address"
              type="email"
              {...register("email")}
            />
            <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
          </FormControl>
          <Stack spacing={6} mt="4">
            <Button
              type="submit"
              variant="solid"
              colorScheme="buttonPrimary"
              width="full"
            >
              Request Reset
            </Button>
          </Stack>
        </form>
      </Stack>
    </Flex>
  );
}
