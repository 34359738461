import {
  Card,
  CardBody,
  Flex,
  Grid,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Select,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useLocation } from "react-router-dom";
import { BiSearch } from "react-icons/bi";
import LoadingScreen from "../../common/loadingScreen";
import NoDataCard from "../../common/nodata";
import useApplliedTalent from "./useApplliedTalent";
import SearchTalentCard from "../search-talent/searchTalentCard";
import AppliedTalentCard from "./appliedTalentCard";

const AppliedTalent = () => {
  const {
    candidateList,
    candidateLoading,
    jobFunctionListOption,
    selectJobFunc,
    jobFunction,
    handleSearch,
  } = useApplliedTalent();
  return (
    <>
      <Card bg="#f7f6f8" borderRadius={"0px"} boxShadow={"none"}>
        <CardBody>
          <Flex
            w={{ base: "full", lg: "100%" }}
            flexDir={{ base: "column", lg: "row" }}
            gap={"4"}
          >
            <HStack
              w="full"
              spacing={4}
              mr={{ base: "0", sm: "2" }}
              align={"baseline"}
            >
              <Text color="#646C9A" whiteSpace={"nowrap"} fontSize={"13px"}>
                {`${
                  candidateList?.users?.length
                    ? candidateList?.users?.length === 1
                      ? "1 Total"
                      : `${candidateList?.users?.length} Total`
                    : 0
                }`}
              </Text>
              <InputGroup w={{ base: "100%", sm: "60%" }}>
                <InputRightElement
                  pointerEvents="none"
                  children={<BiSearch color="#5867DD" />}
                />
                <Input
                  type="text"
                  placeholder="Search ..."
                  bg="#edeef3"
                  border={"none"}
                  sx={{ "::placeholder": { fontSize: "13px" } }}
                  onChange={handleSearch}
                />
              </InputGroup>
            </HStack>
            <HStack
              w="full"
              spacing={4}
              mt={{ base: "2", sm: "0" }}
              align={"baseline"}
            >
              <Text color="#646C9A" whiteSpace={"nowrap"} fontSize={"13px"}>
                Job Name
              </Text>
              <Select
                w={{ base: "100%", sm: "60%" }}
                isDisabled={false}
                color={"#646C9A"}
                size="xs"
                h="42px"
                borderRadius={"md"}
                name="duration"
                mr="2"
                onChange={selectJobFunc}
                value={jobFunction}
              >
                <option value={"All"}>{"All"}</option>
                {jobFunctionListOption?.map((item: any) => {
                  return <option value={item?.jobId}>{item?.jobName}</option>;
                })}
              </Select>
            </HStack>
           
          </Flex>
        </CardBody>
      </Card>
      <Flex w="full" justify={"center"} m="auto" p="4" py="8" bg="#f0f1f7">
        {candidateLoading ? (
          <LoadingScreen />
        ) : candidateList?.users?.length ? (
          <Grid
            w="100% !important"
            templateColumns={[
              "1fr",
              "1fr",
              "1fr",
              "repeat(2, 1fr)",
              "repeat(2, 1fr)",
              "repeat(3, 1fr)",
            ]}
            //   p="2"
            gap={4}
            overflowX="auto"
            width={["100%", "100%", "100%", "100%", "100%", "auto"]}
            sx={{
              "&::-webkit-scrollbar": {
                height: "4px",
              },
              "&::-webkit-scrollbar-track": {
                background: "transparent",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "gray.400",
                borderRadius: "full",
              },
            }}
          >
            {" "}
            {candidateList?.users?.map((data: any) => (
              <AppliedTalentCard key={data?.userId} data={data} />
            ))}
          </Grid>
        ) : (
          <NoDataCard
            message="We will notify you as soon as we have
            identified potential matching"
          />
        )}
      </Flex>
    </>
  );
};

export default AppliedTalent;
