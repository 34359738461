import React from "react";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Center,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Progress,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { BsThreeDots } from "react-icons/bs";
import { BiMedal } from "react-icons/bi";
import { AiFillFire } from "react-icons/ai";
import { GoLocation } from "react-icons/go";
import { noUser } from "../../../assets/images";

const ArchivedTalentCard = ({ data }: any) => {
  const { pathname } = useLocation();

  const navigate = useNavigate();
  return (
    <>
      <Card maxW="lg" borderRadius={"md"} py="2" maxWidth={"100%"}>
        <Flex justifyContent={"flex-end"} h="24px" pr="4">
          {" "}
          <Menu>
            <MenuButton
              as={IconButton}
              variant="ghost"
              colorScheme="gray"
              aria-label="See menu"
              my="1"
              icon={<BsThreeDots size={"22px"} color="#5867DD" />}
            />
            <MenuList>
              {/* <MenuItem>Schedule an Interview</MenuItem> */}
              <MenuItem onClick={() => navigate(`/chat`, { state: data })}>
                Message Candidate
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
        <CardHeader>
          <Card w="full" boxShadow="md" border="1px solid grey">
            <CardBody padding="10px">
              <Flex
                direction="column"
                color="#48465B"
                fontWeight="500"
                mb="3px !important"
              >
                <Text fontSize="12px">Job Name</Text>
                <Heading
                  fontSize={{
                    base: "16.8px",
                    md: "16.8px",
                    lg: "18.2px",
                    xl: "18.2px",
                  }}
                >
                  {data?.jobName?.length > 30
                    ? `${data?.jobName?.slice(0, 30)}...`
                    : data?.jobName}
                </Heading>
              </Flex>
            </CardBody>
          </Card>
        </CardHeader>
        <CardBody>
          <Flex>
            <Flex flex="1" gap="4" align="start">
              <Avatar
                name=""
                src={data?.profilePicture || noUser}
                size={"xl"}
              />

              <VStack align={"baseline"} pos="relative">
                <Heading
                  size="lg"
                  color="#48465b"
                  fontWeight={"500"}
                  fontSize={{
                    base: "16.8px",
                    md: "16.8px",
                    lg: "18.2px",
                    xl: "18.2px",
                  }}
                  mb="6px !important"
                >
                  {data?.fullName?.length > 25
                    ? `${data?.fullName?.slice(0, 25)}...`
                    : data?.fullName}
                </Heading>
                <Text
                  fontSize={"13px"}
                  fontWeight="bold"
                  color={"#646c9a"}
                  mt="0px !important"
                >
                  {data?.jobTitle?.length > 25
                    ? `${data?.jobTitle?.slice(0, 25)} ...`
                    : data?.jobTitle}

                  {data?.companyName &&
                    ` @ ${
                      data?.companyName?.length > 25
                        ? `${data?.companyName?.slice(0, 25)}...`
                        : data?.companyName
                    }`}
                </Text>
                <Text fontSize={"13px"} color={"#646c9a"} mt="0px !important">
                  {data?.latestDegree}{" "}
                  {data?.schoolName &&
                    `@ ${
                      data?.schoolName?.length > 25
                        ? `${data?.schoolName?.slice(0, 25)}...`
                        : data?.schoolName
                    }`}
                </Text>
                {data?.userLocation?.address && (
                  <Flex align="start" gap="2px">
                    <Icon as={GoLocation} color="blue.500" fontSize="13px" />
                    <Text fontSize="13px" color={"#646c9a"}>
                      {JSON.parse(data?.userLocation?.address)?.label}
                    </Text>
                  </Flex>
                )}
              </VStack>
            </Flex>

            {/* <Box
                h="50px"
                w="40px"
                bg="#fd397a"
                borderBottomRightRadius={"md"}
                borderBottomLeftRadius={"md"}
                pos={"absolute"}
                top="0"
                boxShadow={"rgba(0, 0, 0, 0.35) 0px 5px 15px"}
              >
                <Center h="full">
                  <Icon as={AiFillFire} fontSize="24px" color="white" />
                </Center>
              </Box> */}
          </Flex>

          <VStack align={"baseline"} w="full">
            <HStack w={"full"} alignItems="flex-start" mt="4 !important">
              <HStack flexWrap={"wrap"}>
                <Icon as={BiMedal} fontSize="30px" color="gray.500" mt="0.5" />
                {data?.interestedJobFunctions?.map((item: string) => (
                  <Badge
                    bg={"#ffb822"}
                    fontWeight="100"
                    px="1.5"
                    py="0.5"
                    borderRadius={"xl"}
                    fontSize="12px"
                    mt="0.5 !important"
                    mr="0 !important"
                    ml="1 !important"
                    key={item}
                    color={"#48465b"}
                    textTransform={"capitalize"}
                  >
                    {item}
                  </Badge>
                ))}
              </HStack>
            </HStack>
          </VStack>
        </CardBody>

        <CardFooter justify="space-between" flexWrap="wrap">
          <Button
            flex="1"
            variant="outline"
            w="full"
            // colorScheme="buttonPrimary"
            color={"#5867DD"}
            borderColor={"#5867DD"}
            fontSize={"13px"}
            py="6"
            onClick={() =>
              navigate(`${pathname}/${data?.userId}`, { state: data })
            }
            _hover={{ bg: "#5867DD", color: "white" }}
            maxW={"500px"}
          >
            View Details
          </Button>
        </CardFooter>
      </Card>
    </>
  );
};

export default ArchivedTalentCard;
