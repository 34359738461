import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";

import { SignupSchemaEmail, SignupSchemaNumber } from "../../utils/validation";
import { useAuthStore } from "../../store/useAuth";
import { useSignupApi } from "../../service/auth.service";

enum CLIENT_TYPE {
  CANDIDATE = "candidate",
  EMPLOYER = "employer",
}

const getSignupTypeFromClientType = (client: string) => {
  let signupType = "";
  switch (client) {
    case CLIENT_TYPE.CANDIDATE:
      signupType = "Looking for job";
      break;
    case CLIENT_TYPE.EMPLOYER:
      signupType = "Hiring Talent";
      break;
    default:
      signupType = "Looking for job";
  }
  return signupType;
};

const useSignup: any = () => {
  const [companySize, setCompanySize] = useState<[]>([]);
  const [searchParams] = useSearchParams();
  const { setUserType } = useAuthStore();
  const navigate = useNavigate();
  let clientType = searchParams.get("client");

  const [signupType, setSignupType] = useState(
    clientType !== null && clientType !== ""
      ? getSignupTypeFromClientType(clientType)
      : "Looking for job"
  );

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      fullName: "",
      company: "",
      phoneNumber: "",
    },
    resolver: yupResolver(
      signupType === "Hiring Talent" ? SignupSchemaEmail : SignupSchemaNumber
    ),
  });

  const { mutate: signupCall, isLoading: signupLoading } = useSignupApi();
  const onSubmit = async (data: any) => {
    let userType = signupType === "Looking for job" ? 2 : 1;
    const { email, phoneNumber } = data;

    const formData =
      userType === 1
        ? { query: email, userType }
        : { query: phoneNumber, userType };
    try {
      await signupCall(formData, {
        onSuccess: (resData) => {
          const { token, ...rest } = resData;
          setUserType(resData?.userType === "EMPLOYER" ? true : false);
          const id = "";
          navigate("/verify", { state: { formData, id } });
        },
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  return {
    register,
    handleSubmit,
    errors,
    onSubmit,
    navigate,
    setSignupType,
    control,
    signupLoading,
    signupType,
    setValue,
    companySize,
    setCompanySize,
  };
};

export default useSignup;
