import { useState } from "react";
import { FaUserAlt } from "react-icons/fa";
import { useForm } from "react-hook-form";

import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  HStack,
  Button,
  Text,
  InputLeftElement,
  CheckboxGroup,
  Checkbox,
  SimpleGrid,
  useToast,
  Select,
} from "@chakra-ui/react";
import useSignup from "../useSignup";
import { useFetch } from "../../../utils/useFetch";

import "../signup.css";

const Registration = ({ setIsHiringLogin }: any) => {
  const [isLoading, setIsloading] = useState(false);
  const [validHiringError, setValidHiringError] = useState("");
  const [validError, setValidError] = useState("");
  const toast = useToast();
  const { signupType, navigate } = useSignup();
  const [companySize, setCompanySize] = useState<any>();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: {
      email: "",
      fullName: "",
      company: "",
    },
  });
  console.log("errors", errors);
  const [hiringFor, setHiringFor] = useState<string[]>([]);
  const hiringForList = [
    { value: "DATA_SCIENTIST", label: "Data Scientist" },
    { value: " ML_AI_ENGINEER", label: "ML/AI Engineer" },
    { value: "DATA_ENGINEER", label: "Data Engineer" },
    { value: "SOFTWARE_ENGINEER", label: "Software Engineer" },
    { value: "PRODUCT_MANAGER", label: "Product Manager" },
    { value: "OTHER", label: "Other" },
  ];

  const onSubmit = async (data: any) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const prohibitedDomains = ["gmail.com", "yahoo.com", "hotmail.com"];

    if (
      !data.email.trim() ||
      !data.company.trim() ||
      !data.fullName.trim() ||
      hiringFor?.length < 1 ||
      companySize < 1 ||
      !companySize
    ) {
      if (!data.email.trim()) {
        setError("email", {
          type: "required",
          message: "Email is required",
        });
      }
      if (!data.company.trim()) {
        setError("company", {
          type: "required",
          message: "Company is required",
        });
      }
      if (!data.fullName.trim()) {
        setError("fullName", {
          type: "required",
          message: "Name is required",
        });
      }
      if (hiringFor?.length < 1) {
        setValidHiringError("This Field Is Required");
      }
      if (!companySize || companySize < 1) {
        setValidError("Company Size Is Required");
      }
      return;
    } else if (!emailRegex.test(data.email)) {
      toast({
        title: `Invalid Email`,
        description: "Please Enter a Valid Email",
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });
    } else {
      const emailDomain = data.email.split("@")[1];
      if (prohibitedDomains.includes(emailDomain)) {
        toast({
          title: `Invalid Email`,
          description: "Only work domain emails are allowed",
          status: "error",
          variant: "top-accent",
          isClosable: true,
        });
      } else {
        setIsloading(true);
        let userType = 1;
        const formData = { query: data?.email, userType };
        const BODY = {
          fullName: data?.fullName.trim(),
          companyName: data?.company.trim(),
          companySize: companySize,
          email: data?.email.trim(),
          hiringForList: hiringFor,
        };
        try {
          useFetch
            .post(`/users/auth/company-signup`, BODY)
            .then((response) => {
              const id = response.data;
              setIsloading(false);
              navigate("/verify", { state: { formData, id } });
            })
            .catch((error) => {
              setIsloading(false);
              toast({
                title: `${error.response.data}`,
                description: "",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            });
        } catch (error) {
          console.log("error", error);
        }
      }
    }
  };

  return (
    <>
      <form
        style={{ width: "100%" }}
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(onSubmit)();
        }}
        onKeyDown={(e: any) => {
          if (e.key === "Enter") {
            e.preventDefault();
            handleSubmit(onSubmit)();
          }
        }}
      >
        <Flex direction="column" gap={3}>
          <FormControl isInvalid={Boolean(errors?.fullName)}>
            <Flex align="start">
              <FormLabel me="4px">Full Name</FormLabel>
              <Text color="red" fontSize="md">
                *
              </Text>
            </Flex>

            <Input
              type="text"
              placeholder="Enter Full Name"
              {...register("fullName")}
              maxLength={255}
            />

            <Text fontSize="sm" color="red.500">
              {errors?.fullName?.message}
            </Text>
          </FormControl>
          <HStack align="start">
            <FormControl isInvalid={Boolean(errors?.company)}>
              <Flex align="start">
                <FormLabel me="4px">Company</FormLabel>
                <Text color="red" fontSize="md">
                  *
                </Text>
              </Flex>
              <Input
                type="text"
                {...register("company")}
                placeholder="Company Name"
                maxLength={255}
              />
              <Text fontSize="sm" color="red.500">
                {errors?.company?.message}
              </Text>
            </FormControl>
            <FormControl isInvalid={Boolean(errors?.email)}>
              <Flex align="start">
                <FormLabel me="4px">Work Email</FormLabel>
                <Text color="red" fontSize="md">
                  *
                </Text>
              </Flex>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<FaUserAlt color="gray.300" />}
                />
                <Input
                  type="email"
                  placeholder="Email address"
                  {...register("email")}
                  maxLength={255}
                />
              </InputGroup>
              <Text fontSize="sm" color="red.500">
                {errors?.email?.message}
              </Text>
            </FormControl>
          </HStack>
          <FormControl>
            <Flex align="start">
              <FormLabel me="4px">Company Size</FormLabel>
              <Text color="red" fontSize="md">
                *
              </Text>
            </Flex>

            <Select
              placeholder="Select Company Size"
              value={companySize}
              onChange={(e) => {
                let value = e.target.value;
                setCompanySize(value);
                if (value.length) {
                  setValidError("");
                }
              }}
              isInvalid={Boolean(validError)}
            >
              <option value="Myself Only">Myself Only</option>
              <option value="1-10 employees">1-10 employees</option>
              <option value="11-50 employees">11-50 employees</option>
              <option value="51-200 employees">51-200 employees</option>
              <option value="201-500 employees">201-500 employees</option>
              <option value="501-1000 employees">501-1000 employees</option>
              <option value="1001-5000 employees">1001-5000 employees</option>
              <option value="5001-10,000 employees">
                5001-10,000 employees
              </option>
              <option value="10,001+ employees">10,001+ employees</option>
            </Select>
            {validError && (
              <Text fontSize="sm" color="red.500">
                {validError}
              </Text>
            )}
          </FormControl>
          <FormControl>
            <Flex align="start">
              <FormLabel me="4px">Hiring For</FormLabel>
              <Text color="red" fontSize="md">
                *
              </Text>
            </Flex>
            <Box bg="gray.50" padding="10px 20px">
              <Flex alignItems="start">
                <CheckboxGroup
                  colorScheme="blue"
                  onChange={(newValues) => {
                    setHiringFor(newValues as string[]);
                    if (newValues.length) {
                      setValidHiringError("");
                    }
                  }}
                >
                  <SimpleGrid columns={{ base: 1, lg: 2 }} spacing="10px">
                    {hiringForList?.map((data: any) => (
                      <Checkbox value={data?.value} key={data?.value}>
                        {data?.label}
                      </Checkbox>
                    ))}
                  </SimpleGrid>
                </CheckboxGroup>
              </Flex>
            </Box>
            {validHiringError && (
              <Text fontSize="sm" color="red.500" textAlign="center">
                {validHiringError}
              </Text>
            )}
          </FormControl>

          <Button
            // borderRadius={0}
            type="submit"
            variant="solid"
            colorScheme="buttonPrimary"
            width="full"
            // onClick={handleSubmit(onSubmit)}
            isLoading={isLoading}
            isDisabled={isLoading}
          >
            Sign up
          </Button>
          <HStack justify="center">
            <Text fontSize="12px" fontWeight="500">
              Already have an account ?
            </Text>
            <Text
              fontSize="12px"
              cursor="pointer"
              fontWeight="600"
              _hover={{
                color: "blue.500",
                textDecoration: "underline",
              }}
              onClick={() => setIsHiringLogin(true)}
            >
              Log In
            </Text>
          </HStack>
        </Flex>
      </form>
    </>
  );
};

export default Registration;
