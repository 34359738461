import {
  Button,
  Card,
  CardBody,
  Heading,
  HStack,
  Stack,
  VStack,
  Badge,
  Box,
  Flex,
  Text,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  useToast,
  Tooltip,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { RiThumbUpFill, RiThumbDownFill } from "react-icons/ri";
import { IoMdArchive } from "react-icons/io";

import {
  BiDotsHorizontalRounded,
  BiCoinStack,
  BiSliderAlt,
} from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlineBank } from "react-icons/ai";
import {
  useAppliedJobStatusChange,
  useFetchCandidateById,
  useLikeJobStatusChange,
} from "../../../../service/job.service";
import { useUserStore } from "../../../../store/useUser";
import LoadingScreen from "../../../common/loadingScreen";
import { useFetch } from "../../../../utils/useFetch";
import { addCommas } from "../../../../utils/helper";

const JobDetail = () => {
  const toast = useToast();
  const [isLoading, setIsloading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { user } = useUserStore();
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const { mutate: likeStatusMutate, isLoading: likeStatusLoading } =
    useLikeJobStatusChange();

  const { mutate: appliedStatusMutate, isLoading: appliedStatusLoading } =
    useAppliedJobStatusChange();
  const {
    isLoading: candidateLoading,
    data: candidateDetail,
    refetch,
  } = useFetchCandidateById(state?.id);
  useEffect(() => {
    refetch();
  }, []);
  const handleUnApplyJob = (id: string) => {
    setIsloading(true);
    try {
      useFetch
        .put(`/jobs/apply/unapply-job?jobId=${id}`)
        .then((res) => {
          setIsloading(false);
          toast({
            title: "Application withdrawn successfully",
            status: "success",
            isClosable: true,
            position: "bottom",
            duration: 3000,
          });
          refetch();
        })
        .catch((error) => {
          console.log(error);
          setIsloading(false);
        });
    } catch {}
  };
  const handleApplyJob = (e: any) => {
    e.stopPropagation();
    appliedStatusMutate(candidateDetail?.id, {
      onSuccess: () => {
        toast({
          title: "Applied Successfully",
          status: "success",
          isClosable: true,
          position: "bottom",
          duration: 3000,
        });
        refetch();
      },
      onError: (err) => {
        if (err.response.status === 409) console.log(err.response.data);
      },
    });
  };
  const handleDeleteJob = (id: string) => {
    setIsDeleting(true);
    try {
      useFetch
        .delete(`/users/talent/employer-interest-candidate?jobId=${id}`)
        .then((res) => {
          setIsDeleting(false);
          navigate("/likesYou");
        });
    } catch (error) {
      setIsDeleting(false);
      console.log(error);
    }
  };
  return (
    <>
      {candidateLoading ? (
        <LoadingScreen />
      ) : (
        <VStack w="full" spacing={6}>
          <Card boxShadow={"none"} borderRadius="none" w="full" bg="#f7f6f8">
            <CardBody py="2.5 !important">
              <Stack
                direction="row"
                spacing={4}
                align="center"
                justify="space-between"
              >
                <Heading
                  size="sm"
                  color={"#959cb6"}
                  _hover={{ color: "#5D78FF" }}
                >
                  Job Details
                </Heading>
                <Button
                  loadingText="Loading"
                  color="#74788d"
                  spinnerPlacement="end"
                  size="sm"
                  fontWeight={"bold"}
                  _hover={{ color: "white", bg: "#5867DD" }}
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button>
              </Stack>
            </CardBody>
          </Card>
          <VStack
            w="full"
            spacing={6}
            p="4"
            py="8"
            bg="#f0f1f7"
            gap={"4"}
            mt="0px !important"
          >
            <Card w="full" borderRadius={"sm"}>
              <CardBody>
                <Flex flex="1" gap="4" alignItems="flex-start" flexWrap="wrap">
                  <VStack align={"baseline"}>
                    <HStack align={"flex-start"}>
                      <Heading
                        fontSize={{
                          base: "18.8px",
                          md: "18.8px",
                          lg: "20.2px",
                          xl: "20.2px",
                        }}
                        fontWeight={"500"}
                        color="#48465b"
                      >
                        {candidateDetail?.companyName}
                      </Heading>
                    </HStack>
                  </VStack>
                </Flex>
              </CardBody>
            </Card>
            <Card w="full" mt="0px ! important">
              <CardBody>
                <VStack w="full">
                  <Box flex={1} w="full">
                    <Text fontSize={"20px"} fontWeight="bold" color={"#48465b"}>
                      {candidateDetail?.name}
                    </Text>
                    <HStack w="full">
                      <Text
                        fontSize={"13px"}
                        fontWeight={"bold"}
                        color={"#646c9a"}
                        minW={{ base: "auto", md: "115px" }}
                      >
                        Primary Location:
                      </Text>
                      <Text fontSize={"13px"} color="#595d6e">
                        {candidateDetail?.locationsList &&
                          candidateDetail?.locationsList[0]}
                      </Text>
                    </HStack>
                    <HStack w="full">
                      <Text
                        fontSize={"13px"}
                        fontWeight={"bold"}
                        color={"#646c9a"}
                        minW={{ base: "auto", md: "115px" }}
                      >
                        Other Location:
                      </Text>
                      <HStack wrap="wrap">
                        {candidateDetail?.locationsList?.length > 1 ? (
                          <>
                            {candidateDetail?.locationsList
                              ?.slice(1)
                              .map((item: string, index: number) => {
                                return (
                                  <Text
                                    fontSize="13px"
                                    color="#595d6e"
                                    whiteSpace="nowrap"
                                  >
                                    {item}
                                    {index <
                                      candidateDetail?.locationsList?.length -
                                        2 && <span>,</span>}
                                  </Text>
                                );
                              })}
                          </>
                        ) : (
                          <Text
                            fontSize="13px"
                            color="#595d6e"
                            whiteSpace="nowrap"
                          >
                            N/A
                          </Text>
                        )}
                      </HStack>
                    </HStack>
                    <HStack w="full" align={"baseline"}>
                      <Text
                        fontSize={"13px"}
                        fontWeight={"bold"}
                        whiteSpace={"nowrap"}
                        color={"#646c9a"}
                        minW={{ base: "auto", md: "115px" }}
                      >
                        Job type:
                      </Text>
                      <Text fontSize={"13px"} color="#595d6e">
                        {candidateDetail?.locationsType}
                      </Text>
                    </HStack>
                    <HStack w="full">
                      <Text
                        fontSize={"13px"}
                        fontWeight={"bold"}
                        display={"flex"}
                        color={"#646c9a"}
                        minW={{ base: "auto", md: "115px" }}
                      >
                        Job functions:
                      </Text>

                      <HStack flexWrap={"wrap"} w="full">
                        {candidateDetail?.jobFunctions?.map(
                          (item: any, index: any) => (
                            <React.Fragment key={index}>
                              <Text
                                fontSize={"13px"}
                                fontWeight={"100"}
                                color="#595d6e"
                              >
                                {`${item}`}
                              </Text>
                              {index !==
                                candidateDetail.jobFunctions.length - 1 && (
                                <Text
                                  fontSize={"13px"}
                                  fontWeight={"100"}
                                  color="#595d6e"
                                  ml="0px !important"
                                  mr="1 !important"
                                >
                                  {`, `}
                                </Text>
                              )}
                            </React.Fragment>
                          )
                        )}
                      </HStack>
                    </HStack>

                    {candidateDetail?.reqNumber && (
                      <HStack>
                        <Text
                          fontSize={"13px"}
                          fontWeight={"bold"}
                          color={"#646c9a"}
                          minW={{ base: "auto", md: "115px" }}
                        >
                          Requisition:
                        </Text>
                        <Text fontSize={"13px"} color="#595d6e">
                          {candidateDetail?.reqNumber}
                        </Text>
                      </HStack>
                    )}
                    <HStack>
                      <Text
                        color="#646c9a"
                        fontWeight={"bold"}
                        fontSize="13px"
                        minW={{ base: "auto", md: "115px" }}
                      >
                        Must have:
                      </Text>
                      <HStack flexWrap={"wrap"} gap="5px">
                        {candidateDetail?.mustHaveSkills?.map((item: any) => (
                          <Badge
                            bg={"#ffb822"}
                            fontWeight="100"
                            px="2"
                            py="0"
                            borderRadius={"xl"}
                            fontSize="12px"
                            mt="0 !important"
                            mr="2 !important"
                            ml="0 !important"
                            key={item}
                            color={"#595d6e"}
                            textTransform={"capitalize"}
                          >
                            {item}
                          </Badge>
                        ))}
                      </HStack>
                    </HStack>
                  </Box>
                </VStack>
              </CardBody>
            </Card>
            <Stack
              w="full"
              alignItems={"flex-start"}
              flexDir={{ base: "column", "2xl": "row" }}
              mt="0px !important"
              gap={"4"}
            >
              <VStack
                w={{ base: "100%", "2xl": "40%" }}
                mr={{ md: 0, lg: 6 }}
                gap={"4"}
              >
                <Card w="full">
                  <CardBody>
                    <Stack direction="row" spacing={4} justify="center">
                      <HStack>
                        {" "}
                        {pathname.includes("/likesYou") ? (
                          <Button
                            leftIcon={<RiThumbDownFill />}
                            colorScheme="red"
                            variant="solid"
                            isLoading={isDeleting}
                            isDisabled={isDeleting}
                            my="1"
                            size={{ base: "xs", sm: "sm", "2xl": "md" }}
                            onClick={(e: any) =>
                              handleDeleteJob(candidateDetail?.id)
                            }
                          >
                            Not Interested
                          </Button>
                        ) : (
                          <Button
                            leftIcon={
                              candidateDetail?.isInterested ? (
                                <RiThumbDownFill />
                              ) : (
                                <RiThumbUpFill />
                              )
                            }
                            colorScheme={
                              candidateDetail?.isInterested ? "red" : "green"
                            }
                            variant="solid"
                            isLoading={appliedStatusLoading || isLoading}
                            isDisabled={appliedStatusLoading || isLoading}
                            my="1"
                            size={{ base: "xs", sm: "sm", "2xl": "md" }}
                            onClick={(e: any) => {
                              candidateDetail?.isInterested
                                ? handleUnApplyJob(candidateDetail?.id)
                                : handleApplyJob(e);
                            }}
                          >
                            {candidateDetail?.isInterested
                              ? "Withdraw Application"
                              : "I'm interested"}
                          </Button>
                        )}
                        <Button
                          leftIcon={<IoMdArchive />}
                          isLoading={likeStatusLoading}
                          isDisabled={likeStatusLoading}
                          variant="outline"
                          my="1"
                          color="white"
                          colorScheme="blue"
                          bg="#4361EE"
                          size={{ base: "xs", sm: "sm", "2xl": "md" }}
                          _hover={{ color: "white", bg: "#5570ef" }}
                          onClick={() => {
                            likeStatusMutate(
                              {
                                jobId: candidateDetail?.id,
                                userId: user?.userId,
                              },
                              {
                                onSuccess: () => {
                                  refetch();
                                },
                              }
                            );
                          }}
                        >
                          {candidateDetail?.isArchived
                            ? "Unarchive"
                            : "Archive"}
                        </Button>
                      </HStack>
                      <HStack>
                        <Menu>
                          <MenuButton
                            as={IconButton}
                            variant="ghost"
                            aria-label="See menu"
                            color="#9382dd"
                            height={"32px"}
                            my="1"
                            _hover={{
                              color: "#5867DD !important",
                              bg: "#5d78ff1a",
                            }}
                            icon={<BiDotsHorizontalRounded size="30px" />}
                          />
                          <MenuList>
                            <MenuItem
                              onClick={() => navigate(`/chat`, { state })}
                            >
                              Message Employer
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </HStack>
                    </Stack>
                  </CardBody>
                </Card>
                <Card w="full">
                  <CardBody>
                    <VStack
                      gap="5px"
                      flexDir={{ base: "column", sm: "row", "2xl": "column" }}
                      align="start"
                    >
                      <Flex
                        flexDir={{ base: "column", sm: "row" }}
                        w="full"
                        flexWrap={"wrap"}
                        align="start"
                      >
                        <HStack w={{ base: "100%", lg: "50%" }}>
                          <Icon
                            as={BiCoinStack}
                            fontSize="40px"
                            color="#94a0b0"
                          />
                          <VStack align={"baseline"}>
                            <Text
                              color="#595d6e"
                              fontWeight={"600"}
                              fontSize={{ base: "xs", md: "xs" }}
                            >
                              Base Salary
                            </Text>
                            {candidateDetail?.salary &&
                            (candidateDetail?.salary?.from !== null ||
                              candidateDetail?.salary?.to !== null) ? (
                              <Text
                                color="#48465b"
                                fontWeight={"600"}
                                mt={"0px !important"}
                                fontSize={{ base: "xs", md: "sm" }}
                              >
                                {`$${
                                  candidateDetail?.salary?.from === null
                                    ? "0"
                                    : addCommas(candidateDetail?.salary?.from)
                                } - $${
                                  candidateDetail?.salary?.to === null
                                    ? "0"
                                    : addCommas(candidateDetail?.salary?.to)
                                }`}
                              </Text>
                            ) : (
                              <Text
                                color="#48465b"
                                fontWeight={"600"}
                                mt={"0px !important"}
                                fontSize={{ base: "xs", md: "sm" }}
                              >
                                Not Specified
                              </Text>
                            )}
                          </VStack>
                        </HStack>
                        <HStack
                          w={{ base: "100%", lg: "50%" }}
                          mt={{ base: "2", md: "0px !important" }}
                        >
                          <Icon
                            as={AiOutlineBank}
                            fontSize="40px"
                            color="#94a0b0"
                          />
                          <VStack align={"baseline"}>
                            <Text
                              color="#595d6e"
                              fontWeight={"600"}
                              fontSize={{ base: "xs", md: "xs" }}
                            >
                              Bonus - Stock
                            </Text>

                            {candidateDetail?.bonus?.from !== null ? (
                              <Text
                                color="#48465b"
                                fontWeight={"600"}
                                mt={"0px !important"}
                                fontSize={{ base: "xs", md: "sm" }}
                              >
                                {`${
                                  candidateDetail?.bonus?.bonusType ===
                                  "percentage"
                                    ? ""
                                    : "$"
                                }${
                                  candidateDetail?.bonus?.from === null
                                    ? "N/A"
                                    : addCommas(candidateDetail?.bonus?.from)
                                }${
                                  candidateDetail?.bonus?.bonusType ===
                                  "percentage"
                                    ? "%"
                                    : ""
                                } - ${
                                  candidateDetail?.stock?.from === null
                                    ? "N/A"
                                    : addCommas(candidateDetail?.stock?.from)
                                }${
                                  candidateDetail?.stock?.stockType &&
                                  candidateDetail?.stock?.stockType?.toLowerCase() ===
                                    "percentage"
                                    ? "%"
                                    : ""
                                }`}
                              </Text>
                            ) : (
                              <Text
                                color="#48465b"
                                fontWeight={"600"}
                                mt={"0px !important"}
                                fontSize={{ base: "xs", md: "sm" }}
                              >
                                Not Specified
                              </Text>
                            )}
                          </VStack>
                        </HStack>
                      </Flex>
                      <Flex
                        flexDir={{ base: "column", sm: "row" }}
                        w="full"
                        // mt={{ base: "2 !important", lg: "4 !important" }}
                        flexWrap={"wrap"}
                        mt="0px !important"
                      >
                        <HStack w={{ base: "100%", lg: "50%" }}>
                          <Icon
                            as={BiSliderAlt}
                            fontSize="40px"
                            color="#94a0b0"
                          />
                          <VStack align={"baseline"}>
                            <Text
                              color="#595d6e"
                              fontWeight={"600"}
                              fontSize={{ base: "xs", md: "xs" }}
                            >
                              Report to
                            </Text>
                            <Text
                              color="#48465b"
                              fontWeight={"600"}
                              mt={"0px !important"}
                              fontSize={{ base: "xs", md: "sm" }}
                            >
                              {candidateDetail?.createdByName?.length > 60 ? (
                                <Tooltip
                                  hasArrow
                                  label={candidateDetail?.createdByName}
                                >
                                  {candidateDetail?.createdByName?.slice(
                                    0,
                                    60
                                  ) + "..."}
                                </Tooltip>
                              ) : (
                                candidateDetail?.createdByName
                              )}
                            </Text>
                          </VStack>
                        </HStack>
                        {/* <HStack
                        w={{ base: "100%", lg: "50%" }}
                        mt={{ base: "2", md: "0px !important" }}
                      >
                        <Icon
                          as={BsFileEarmarkText}
                          fontSize="40px"
                          color="#94a0b0"
                        />
                        <VStack align={"baseline"}>
                          <Text
                            color="#595d6e"
                            fontWeight={"600"}
                            fontSize={{ base: "xs", md: "xs" }}
                          >
                            Other Benefits
                          </Text>
                          <Popover>
                            <PopoverTrigger>
                              <Text
                                color="blue.500"
                                fontWeight={"bold"}
                                mt={"0px !important"}
                                cursor={"pointer"}
                                fontSize={{ base: "xs", md: "sm" }}
                              >
                                View
                              </Text>
                            </PopoverTrigger>
                            <PopoverContent>
                              <PopoverArrow />
                              <PopoverCloseButton color="white" />
                              <PopoverHeader bg="#5D78FF" color="white">
                                Benefits!
                              </PopoverHeader>
                              <PopoverBody>
                                <VStack align={"baseline"}>
                                  {state?.employeeBenefitList?.length ? (
                                    state?.employeeBenefitList.map(
                                      (item: any, index: any) => {
                                        return (
                                          <Text>
                                            {index + 1}
                                            {`. ${item}`}
                                          </Text>
                                        );
                                      }
                                    )
                                  ) : (
                                    <Text>No Data</Text>
                                  )}
                                </VStack>
                              </PopoverBody>
                            </PopoverContent>
                          </Popover>
                        </VStack>
                      </HStack> */}
                      </Flex>
                    </VStack>
                  </CardBody>
                </Card>
              </VStack>
              <Stack
                w={{ base: "100%", "2xl": "60%" }}
                mt={{
                  base: "0 !important",
                  md: "0 important",
                  xl: "0 !important",
                }}
              >
                <Card w="full">
                  <CardBody>
                    <Tabs>
                      <TabList>
                        <Tab>Position Summary</Tab>
                      </TabList>

                      <TabPanels>
                        <TabPanel>
                          <VStack spacing={6}>
                            <Card w="full">
                              <CardBody bg="#f7f8fc" w="full">
                                <VStack w="full" align={"baseline"}>
                                  <Text
                                    textAlign={"justify"}
                                    fontSize={"13px"}
                                    color={"#646c9a"}
                                  >
                                    {candidateDetail?.description}
                                  </Text>
                                </VStack>
                              </CardBody>
                            </Card>
                          </VStack>
                        </TabPanel>
                      </TabPanels>
                    </Tabs>
                  </CardBody>
                </Card>
              </Stack>
            </Stack>
          </VStack>
        </VStack>
      )}
    </>
  );
};

export default JobDetail;
