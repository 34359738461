import { BiPencil } from "react-icons/bi";
import {
  Box,
  Center,
  Flex,
  HStack,
  Heading,
  IconButton,
  Link,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import EditBasicInfo from "./EditBasicInfo";
import { getUserId } from "../../../../utils/helper";
import { useBasicInfoFetch } from "../../../../service/education.service";
import LoadingScreen from "../../../common/loadingScreen";

const BasicInfo = () => {
  const userId = getUserId();
  const { isLoading: loadingBasic, data: basicInfo } =
    useBasicInfoFetch(userId);
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box
        position="relative"
        width="full"
        background="white"
        p={{ base: "3", md: "5" }}
      >
        <Heading size="md" py="5px" mb="5px" color="#646c9a">
          Additional Information
        </Heading>
        {loadingBasic ? (
          <LoadingScreen />
        ) : basicInfo ? (
          <>
            <Flex
              pt="20px"
              gap={10}
              flexDir="column"
              alignItems="start"
              p={{ base: "2", md: "5" }}
            >
              <HStack alignItems="start">
                <Text
                  minW={{ base: "100px", md: "200px" }}
                  fontSize={{ base: "sm", md: "md" }}
                  maxW={{ base: "100px", md: "200px" }}
                >
                  Bio
                </Text>
                <Text fontSize={{ base: "xs", md: "md" }} textAlign={"justify"}>
                  {basicInfo?.shortBio}
                </Text>
              </HStack>
              <HStack alignItems="start">
                <Text
                  minW={{ base: "100px", md: "200px" }}
                  fontSize={{ base: "sm", md: "md" }}
                  maxW={{ base: "100px", md: "200px" }}
                >
                  Achievements
                </Text>
                <Flex gap="2" flexWrap="wrap" alignItems="start">
                  {basicInfo?.achievement?.map((element: string) => (
                    <Text
                      background="#5867DD"
                      color="#fff"
                      fontWeight="bold"
                      borderRadius="lg"
                      px="2"
                      py="1"
                      fontSize="13px"
                    >
                      {element}
                    </Text>
                  ))}
                </Flex>
              </HStack>
              <HStack alignItems="start">
                <Text
                  minW={{ base: "100px", md: "200px" }}
                  fontSize={{ base: "sm", md: "md" }}
                  maxW={{ base: "100px", md: "200px" }}
                >
                  Spoken Languages
                </Text>
                <Flex gap="2" flexWrap="wrap">
                  {basicInfo?.spokenLanguagesList?.map((element: string) => (
                    <Text
                      background="#5867DD"
                      color="#fff"
                      fontWeight="bold"
                      borderRadius="lg"
                      px="2"
                      py="1"
                      fontSize="13px"
                    >
                      {element}
                    </Text>
                  ))}
                </Flex>
              </HStack>
              <HStack alignItems="start">
                <Text
                  minW={{ base: "100px", md: "200px" }}
                  fontSize={{ base: "sm", md: "md" }}
                  maxW={{ base: "100px", md: "200px" }}
                >
                  Interests and Hobbies
                </Text>
                <Flex gap="2" flexWrap="wrap">
                  {basicInfo?.interest?.map((element: string) => (
                    <Text
                      background="#5867DD"
                      color="#fff"
                      fontWeight="bold"
                      borderRadius="lg"
                      px="2"
                      py="1"
                      fontSize="13px"
                    >
                      {element}
                    </Text>
                  ))}
                </Flex>
              </HStack>
              <Flex direction="column" gap={4}>
                <Text fontSize={{ base: "14px", md: "16px" }} fontWeight="600">
                  Professional Networks
                </Text>
                {basicInfo?.linkedInProfile ? (
                  <HStack alignItems="start">
                    <Text
                      minW={{ base: "100px", md: "200px" }}
                      fontSize={{ base: "sm", md: "md" }}
                      maxW={{ base: "100px", md: "200px" }}
                    >
                      Linkedin Profile:
                    </Text>
                    <Link
                      fontSize={{ base: "xs", md: "md" }}
                      _hover={{ color: "#5867DD", textDecor: "underline" }}
                      href={basicInfo?.linkedInProfile}
                      isExternal
                    >
                      <Text wordBreak="break-word">
                        {basicInfo?.linkedInProfile?.length > 80
                          ? basicInfo?.linkedInProfile?.slice(0, 80) + "... "
                          : basicInfo?.linkedInProfile}
                      </Text>
                    </Link>
                  </HStack>
                ) : (
                  <HStack alignItems="start">
                    <Text
                      minW={{ base: "100px", md: "200px" }}
                      fontSize={{ base: "sm", md: "md" }}
                      maxW={{ base: "100px", md: "200px" }}
                    >
                      Resume:
                    </Text>
                    <Link
                      fontSize={{ base: "xs", md: "md" }}
                      _hover={{ color: "#5867DD", textDecor: "underline" }}
                      color="blue.400"
                      href={basicInfo?.resume}
                      isExternal
                    >
                      View Resume
                    </Link>
                  </HStack>
                )}

                <HStack alignItems="start">
                  <Text
                    minW={{ base: "100px", md: "200px" }}
                    fontSize={{ base: "sm", md: "md" }}
                    maxW={{ base: "100px", md: "200px" }}
                  >
                    Website or Portfolio:
                  </Text>
                  <Link
                    fontSize={{ base: "xs", md: "md" }}
                    _hover={{ color: "#5867DD", textDecor: "underline" }}
                    href={basicInfo?.portfolio}
                    isExternal
                  >
                    {" "}
                    <Text wordBreak="break-word">
                      {basicInfo?.portfolio?.length > 80
                        ? basicInfo?.portfolio.slice(0, 80) + "... "
                        : basicInfo?.portfolio}
                    </Text>
                  </Link>
                </HStack>
                <HStack alignItems="start">
                  <Text
                    minW={{ base: "100px", md: "200px" }}
                    fontSize={{ base: "sm", md: "md" }}
                    maxW={{ base: "100px", md: "200px" }}
                  >
                    GitHub Profile:
                  </Text>
                  <Link
                    fontSize={{ base: "xs", md: "md" }}
                    _hover={{ color: "#5867DD", textDecor: "underline" }}
                    href={basicInfo?.githubProfileLink}
                    isExternal
                  >
                    <Text wordBreak="break-word">
                      {basicInfo?.githubProfileLink?.length > 80
                        ? basicInfo?.githubProfileLink.slice(0, 80) + "... "
                        : basicInfo?.githubProfileLink}
                    </Text>
                  </Link>
                </HStack>
              </Flex>
            </Flex>
          </>
        ) : (
          <Center>No Data</Center>
        )}
        <IconButton
          position="absolute"
          top="15px"
          right={{ base: "2px", md: "15px" }}
          variant="outline"
          aria-label="Edit Icon"
          borderRadius="full"
          icon={<BiPencil />}
          onClick={onOpen}
        />
      </Box>

      <EditBasicInfo isOpen={isOpen} onClose={onClose} basicInfo={basicInfo} />
    </>
  );
};

export default BasicInfo;
