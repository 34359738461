import { useState } from "react";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { RiThumbDownFill, RiThumbUpFill } from "react-icons/ri";
import { BiMedal } from "react-icons/bi";
import { useUserStore } from "../../../store/useUser";
import {
  useAppliedJobStatusChange,
  useLikeJobStatusChange,
} from "../../../service/job.service";
import { useFetch } from "../../../utils/useFetch";
import { noUser } from "../../../assets/images";

const LikeJobCard = ({ data, refetch }: any) => {
  const { user } = useUserStore();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const {
    mutate: likeStatusMutate,
    isLoading: likeStatusLoading,
    data: likeStatusData,
  } = useLikeJobStatusChange();
  const {
    mutate: appliedStatusMutate,
    isLoading: appliedStatusLoading,
    data: appliedStatus,
  } = useAppliedJobStatusChange();
  const handleUnApplyJob = (id: string) => {
    try {
      useFetch.put(`/jobs/apply/unapply-job?jobId=${id}`).then((res) => {
        refetch();
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleDeleteJob = (id: string) => {
    setIsLoading(true);
    try {
      useFetch
        .delete(`/users/talent/employer-interest-candidate?jobId=${id}`)
        .then((res) => {
          setIsLoading(false);
          refetch();
        });
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleApplyJob = (e: any) => {
    e.stopPropagation();
    appliedStatusMutate(data?.id, {
      onSuccess: () => {
        refetch();
      },
    });
  };
  return (
    <>
      <Card maxW="100%" borderRadius={"md"} w="full" cursor={"pointer"}>
        <Card
          mx="4"
          my="2"
          boxShadow={"sm"}
          border="1px solid #e3e3e3"
          onClick={() => navigate(`/likesYou/${data?.id}`, { state: data })}
        >
          <Flex p="2">
            <Flex flex="1" gap="4" alignItems="center">
              <Avatar
                name=""
                src={data?.profilePicture || noUser}
                bg=""
                size={"lg"}
              />

              <Box>
                <Heading size="sm" fontWeight={"600"}>
                  {data?.employerName?.length > 30
                    ? data?.employerName.slice(0, 30) + ".."
                    : data?.employerName}
                  <Badge
                    ml="1"
                    fontSize="0.8em"
                    colorScheme="green"
                    p="1"
                    color={"green.500"}
                    textTransform={"capitalize"}
                    mb="5px"
                  >
                    I'm hiring
                  </Badge>
                </Heading>
                <Heading fontSize="xs" color="#646c9a">
                  {data?.positionName && `${data?.positionName} @ `}
                  {data?.companyName && `${data?.companyName}`}
                </Heading>
              </Box>
            </Flex>
          </Flex>
        </Card>
        <CardHeader
          pb="0px"
          onClick={() => navigate(`/likesYou/${data?.id}`, { state: data })}
        >
          <Flex>
            <Flex flex="1" gap="4" alignItems="start" mt="6">
              <Avatar name="" src={data?.companyLogo || noUser} size={"lg"} />
              <Box>
                <Heading
                  size="lg"
                  color="#48465b"
                  fontWeight={"500"}
                  fontSize={{
                    base: "16.8px",
                    md: "16.8px",
                    lg: "18.2px",
                    xl: "18.2px",
                  }}
                  mb="2"
                >
                  {data?.name?.length > 30
                    ? data?.name?.slice(0, 30) + ".."
                    : data?.name}
                </Heading>
                <Text
                  fontSize={"13px"}
                  color={"#646c9a"}
                  fontWeight="500"
                  mt="0px"
                >
                  {data?.locationsList?.length
                    ? `${data?.locationsList[0]}`
                    : "Remote"}
                  {data?.reqNumber && ` | Req#  ${data?.reqNumber}`}
                </Text>
              </Box>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody
          onClick={() => navigate(`/likesYou/${data?.id}`, { state: data })}
        >
          <HStack w={"full"} alignItems="flex-start" mt="4 !important">
            <HStack flexWrap={"wrap"}>
              <Icon as={BiMedal} fontSize="30px" color="gray.500" mt="0.5" />
              {data?.jobFunctions?.map((item: any, index: number) => (
                <Badge
                  bg={"#ffb822"}
                  fontWeight="100"
                  px="1.5"
                  py="0.5"
                  borderRadius={"xl"}
                  fontSize="12px"
                  mt="0.5 !important"
                  mr="0 !important"
                  ml="1 !important"
                  key={index}
                  color={"#48465b"}
                  textTransform={"capitalize"}
                >
                  {item}
                </Badge>
              ))}
            </HStack>
          </HStack>
        </CardBody>
        <Divider color={"gray.300"} />
        <CardFooter justify="center" flexWrap="wrap" cursor="auto">
          <Stack
            direction="row"
            spacing={4}
            flexWrap="wrap"
            justify="center"
            w="full"
            align={"baseline"}
          >
            <Button
              leftIcon={<RiThumbDownFill />}
              colorScheme="red"
              variant="solid"
              isLoading={isLoading}
              isDisabled={isLoading}
              my="1"
              size={{ base: "xs", sm: "sm", "2xl": "md" }}
              onClick={(e: any) => handleDeleteJob(data?.id)}
              // onClick={(e: any) => {
              //   data?.isInterested
              //     ? handleUnApplyJob(data?.id)
              //     : handleApplyJob(e);
              // }}
            >
              Not Interested
              {/* {data?.isInterested ? "Not interested" : "I'm interested"} */}
            </Button>

            <Button
              leftIcon={<RiThumbDownFill />}
              isLoading={likeStatusLoading}
              isDisabled={likeStatusLoading}
              my="1"
              color="white"
              colorScheme="blue"
              bg="#4361EE"
              size={{ base: "xs", sm: "sm", "2xl": "md" }}
              _hover={{ color: "white", bg: "#5570ef" }}
              onClick={(e) => {
                e.stopPropagation();
                likeStatusMutate(
                  {
                    jobId: data?.id,
                    userId: user?.userId,
                  },
                  {
                    onSuccess: () => {
                      refetch();
                    },
                  }
                );
              }}
            >
              {data?.isArchived ? "Unarchive" : "Archive"}
            </Button>
          </Stack>
        </CardFooter>
      </Card>
    </>
  );
};

export default LikeJobCard;
