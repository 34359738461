import {
  Card,
  CardBody,
  Flex,
  HStack,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Conversation from "./conversation";
import Inbox from "./inbox";
import useChat from "./useChat";

const Chat = () => {
  const [messages, setMessages] = useState([
    { from: "computer", text: "Hi, My Name is HoneyChat" },
    { from: "me", text: "Hey there" },
    { from: "me", text: "Myself Ferin Patel" },
    {
      from: "computer",
      text: "Nice to meet you. You can send me message and i'll reply you with same message.",
    },
  ]);
  const { selectedUser, handleSelectedUser, handleSelectedUserSearched } =
    useChat();
  const [inputMessage, setInputMessage] = useState("");

  const handleSendMessage = () => {
    if (!inputMessage.trim().length) {
      return;
    }
    const data = inputMessage;

    setMessages((old) => [...old, { from: "me", text: data }]);
    setInputMessage("");

    setTimeout(() => {
      setMessages((old) => [...old, { from: "computer", text: data }]);
    }, 1000);
  };

  return (
    <>
      {/* <Card boxShadow={"none"} borderRadius="none" mb="2">
        <CardBody>
          <Stack
            direction="row"
            spacing={4}
            align="center"
            justify="space-between"
          >
            <Heading size="md" color={"#8188ad"}>
              Messages
            </Heading>
          </Stack>
        </CardBody> */}
      {/* </Card> */}
      <Flex w="100%" justify="center" align="flex-start" h="full" p="4" py="8">
        <HStack
          w="full"
          h="full"
          spacing={6}
          display={{ base: "none", md: "none", lg: "flex" }}
        >
          <Inbox
            handleSelectedUser={handleSelectedUser}
            selectedUser={selectedUser}
            handleSelectedUserSearched={handleSelectedUserSearched}
          />
          <Conversation
            inputMessage={inputMessage}
            handleSendMessage={handleSendMessage}
            setInputMessage={setInputMessage}
            messages={messages}
            selectedUser={selectedUser}
          />
        </HStack>
        <Tabs w="full" display={{ base: "box", md: "box", lg: "none" }}>
          <TabList mb="2">
            <Tab>Inbox</Tab>
            <Tab>Conversation</Tab>
          </TabList>
          <TabPanels>
            <TabPanel p="0">
              <Inbox
                handleSelectedUser={handleSelectedUser}
                selectedUser={selectedUser}
                handleSelectedUserSearched={handleSelectedUserSearched}
              />
            </TabPanel>
            <TabPanel p="0">
              <Conversation
                inputMessage={inputMessage}
                handleSendMessage={handleSendMessage}
                setInputMessage={setInputMessage}
                messages={messages}
                selectedUser={selectedUser}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </>
  );
};

export default Chat;
