import { useEffect, useState } from "react";
import {
  Box,
  Input,
  Heading,
  Stack,
  Card,
  FormControl,
  FormLabel,
  VStack,
  HStack,
  Button,
  Progress,
  Text,
  FormErrorMessage,
  Flex,
  Tag,
  TagLabel,
  TagCloseButton,
  Select,
} from "@chakra-ui/react";
import { useWizard } from "react-use-wizard";
import CheckBoxButton from "../../../common/checkboxButton";
import RadioGroup from "../../../common/radioGroup";
import { useFetch } from "../../../../utils/useFetch";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { components } from "react-select";
import { prefilledLocations } from "../../../../utils/helper";
import "./custom.css";
const JobFunc = ({
  jobFunctionList,
  setJobFunctionList,
  handleInputChange,
  name,
  reqNumber,
  setSeniorityLevel,
  seniorityLevel,
  locationsType,
  setLocationType,
  locationsList,
  setLocationList,
  optionRemoteLocations,
  handleLocationList,
  searchLocationList,
  setSearchLocationList,
  customLocationList,
  setCustomLocationList,
}: any) => {
  const googleAPI = process.env.REACT_APP_GOOGLE_API_KEY;
  const { previousStep, nextStep, activeStep, stepCount } = useWizard();
  const progress = ((activeStep + 1) / stepCount) * 100;
  const [customLocation, setCustomLocation] = useState("");
  const [customJobFunction, setCustomJobFunction] = useState("");

  const [selected, setSelected] = useState<any>();
  const [jobFuncDisplayList, setJobFuncDisplayList] = useState<any>([]);
  const [errors, setErrors] = useState<any>({
    jobFunctionList: "",
    availableLocationError: "",
    locationListError: "",
    customLocationError: "",
  });

  const [showCustomInput, setShowCustomInput] = useState(false);
  const handleValidation = () => {
    let errors: any = {
      jobFunctionList: "",
      availableLocationError: "",
      locationListError: "",
    };
    let formIsValid = true;
    if (!name) {
      formIsValid = false;
      errors.name = "Please  Enter Job Title";
    }
    if (!jobFunctionList?.length) {
      formIsValid = false;
      errors.jobFunctionList = "Select atleast one option";
    }
    if (!seniorityLevel) {
      formIsValid = false;
      errors.seniorityLevel = "Please Select Seniority Level";
    }
    if (!locationsType) {
      formIsValid = false;
      errors.locationsType = "Please Select Work Enviornment";
    }
    if (
      locationsType &&
      locationsType !== "Remote" &&
      searchLocationList?.length === 0
    ) {
      formIsValid = false;
      errors.availableLocationError =
        "Please enter at least one available location";
    }
    if (
      locationsType &&
      locationsType === "Remote" &&
      locationsList?.length === 0
    ) {
      formIsValid = false;
      errors.locationListError = "Please enter at least one available location";
    }
    if (
      locationsType &&
      locationsType === "Remote" &&
      locationsList.includes("Specific countries (please specify below)") &&
      customLocationList?.length == 0
    ) {
      formIsValid = false;
      errors.customLocationError = "Please enter at least one Specific Country";
    }
    setErrors(errors);
    return formIsValid;
  };
  useEffect(() => {
    try {
      useFetch
        .get(`/users/domain/job-function-list`)
        .then((response) => {
          setJobFuncDisplayList(response.data);
        })
        .catch((error) => {
          console.log("Error", error);
        });
    } catch (error) {
      console.log("ERROR", error);
    }
  }, []);
  const [error, setError] = useState({
    jobFunctionsError: "",
  });

  const handleAddJobFunctions = (value: any) => {
    if (jobFunctionList?.length >= 3) {
      setError((prevError) => ({
        ...prevError,
        jobFunctionsError: "You can select up to 3 options",
      }));
    } else {
      if (value === "Other") {
        if (customJobFunction.trim() !== "") {
          if (
            jobFunctionList.some(
              (func: any) =>
                func.toLowerCase() === customJobFunction.toLowerCase()
            )
          ) {
            setError((prevError) => ({
              ...prevError,
              jobFunctionsError: "You have already selected this job function",
            }));
          } else {
            setError(() => ({
              jobFunctionsError: "",
            }));
            setJobFunctionList((prevJobFuncList: any) => [
              ...prevJobFuncList,
              customJobFunction,
            ]);
            setCustomJobFunction("");
          }
        }
      } else if (jobFunctionList.includes(value)) {
        setError((prevError) => ({
          ...prevError,
          jobFunctionsError: "You have already selected this job function",
        }));
      } else {
        setJobFunctionList((prevJobFuncList: any) => [
          ...prevJobFuncList,
          value,
        ]);
        setError((prevError) => ({
          ...prevError,
          jobFunctionsError: "",
        }));
      }
    }
  };

  const handleRemoveJobFunction = (indexToRemove: number) => {
    setJobFunctionList((prevJobfuncList: any) =>
      prevJobfuncList.filter((_: any, index: number) => index !== indexToRemove)
    );
  };
  const handleRemoveAvailableLocations = (indexToRemove: number) => {
    setSearchLocationList((prevCitiesList: any) =>
      prevCitiesList.filter((_: any, index: number) => index !== indexToRemove)
    );
  };
  const handleAddSpecificCountries = () => {
    if (customLocation) {
      if (!customLocationList?.includes(customLocation)) {
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          customLocationError: "",
        }));
        setCustomLocationList((prevAchievementsList: any) => [
          ...prevAchievementsList,
          customLocation,
        ]);
        setCustomLocation("");
      } else {
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          customLocationError: "You have already selected this Location",
        }));
      }
    }
  };
  const CustomMenuList = (props: any) => {
    const handleSelectOption = (item: any) => {
      props.selectProps.onChange(item);
      props.selectProps.onMenuClose();
    };
    return (
      <components.MenuList {...props}>
        {props.options?.length > 0 ? (
          props.children
        ) : (
          <Flex direction="column" gap={1}>
            {prefilledLocations.map((item: any) => (
              <Text
                w="full"
                _hover={{ bg: "blue.50" }}
                padding="5px 10px"
                fontWeight="400"
                key={item?.value?.place_id}
                onClick={() => handleSelectOption(item)}
                cursor="pointer"
              >
                {item?.label}
              </Text>
            ))}
          </Flex>
        )}
      </components.MenuList>
    );
  };

  const handleAddLocation = (newValue: any) => {
    // Check if the newValue already exists in searchLocationList
    if (
      searchLocationList.some(
        (existingLocation: any) => existingLocation === newValue?.label
      )
    ) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        availableLocationError: "You have already selected this Location",
      }));
    } else {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        availableLocationError: "",
      }));
      setSearchLocationList((prevCitiesList: any) => [
        ...prevCitiesList,
        newValue?.label,
      ]);
    }
  };
  const handleRemoveCustomLocation = (indexToRemove: number) => {
    setCustomLocationList((prevAchievementsList: any) =>
      prevAchievementsList.filter(
        (_: any, index: number) => index !== indexToRemove
      )
    );
  };

  return (
    <VStack w="full">
      <Card p="4" w="full">
        <Box>
          <Progress
            colorScheme="progressBar"
            size="md"
            w="full"
            borderRadius={"lg"}
            my="6"
            value={progress}
            mb={8}
          />
        </Box>
        <Box maxW={"800px"} my="4">
          <Stack>
            <Stack mb="5px">
              <Heading size="md">Basic Information</Heading>
              <FormControl
                isRequired
                mb="2 !important"
                isInvalid={Boolean(errors?.name)}
              >
                <FormLabel htmlFor="customer" fontWeight="600">
                  Job Title
                </FormLabel>
                <Input
                  type="text"
                  placeholder="Enter job title"
                  maxW={{ base: "100%", md: "70%" }}
                  borderColor="grey"
                  name="name"
                  maxLength={255}
                  onChange={handleInputChange}
                  value={name}
                />
                {!name && (
                  <Text fontSize="sm" color="red.500">
                    {errors?.name}
                  </Text>
                )}
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="reqNumber" fontWeight="600">
                  Job Requisition Number (optional)
                </FormLabel>
                <Input
                  type="text"
                  placeholder="Enter Requisition number"
                  maxW={{ base: "100%", md: "70%" }}
                  borderColor="grey"
                  name="reqNumber"
                  onChange={handleInputChange}
                  value={reqNumber}
                  maxLength={50}
                />
              </FormControl>
            </Stack>
            <Flex direction="column" w="full">
              <FormControl
                position="relative"
                isInvalid={Boolean(error.jobFunctionsError)}
                isRequired
              >
                <FormLabel size="md" fontWeight="600">
                  Job Functions (select upto 3)
                </FormLabel>
                <Flex w={{ base: "100%", md: "70%" }}>
                  <Select
                    onChange={(e) => {
                      setSelected(e.target.value);
                      setShowCustomInput(e.target.value === "Other");
                      if (e.target.value !== "Other") {
                        handleAddJobFunctions(e.target.value);
                      }
                    }}
                    placeholder="Select job functions"
                    value={selected}
                    border="1px solid grey"
                  >
                    {jobFuncDisplayList?.map((data: any, key: number) => (
                      <option key={key} value={data.name}>
                        {data.name}
                      </option>
                    ))}
                  </Select>
                </Flex>
                {showCustomInput && (
                  <FormControl mt={5}>
                    <Input
                      placeholder="Enter custom job function"
                      type="text"
                      maxW={{ base: "100%", md: "70%" }}
                      onChange={(e) => setCustomJobFunction(e.target.value)}
                      value={customJobFunction}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.stopPropagation();
                          e.preventDefault();
                          handleAddJobFunctions("Other");
                        }
                      }}
                    />
                    <Button
                      ml="2"
                      colorScheme="buttonPrimary"
                      onClick={() => handleAddJobFunctions("Other")}
                    >
                      Add
                    </Button>
                  </FormControl>
                )}
              </FormControl>
              <Flex gap={"10px"} mt="10px">
                {jobFunctionList.map((functions: string, index: number) => (
                  <Tag key={index}>
                    <TagLabel>{functions}</TagLabel>
                    <TagCloseButton
                      onClick={() => handleRemoveJobFunction(index)}
                    />
                  </Tag>
                ))}
              </Flex>
              {error.jobFunctionsError && (
                <Text fontSize="sm" color="red.500" mt="2px !important">
                  {error.jobFunctionsError}
                </Text>
              )}
              {!jobFunctionList?.length && (
                <Text color="red" pl="1" mt="2px !important">
                  {errors?.jobFunctionList}
                </Text>
              )}
            </Flex>

            <FormControl isRequired>
              <FormLabel fontWeight="600">Seniority Level</FormLabel>
            </FormControl>
            <RadioGroup
              options={[
                "Entry-level",
                "Mid-level, hands-on",
                "Senior-level, hands-on",
                "Manager, hands-on",
                "Manager, with direct reports",
              ]}
              name="seniorityLevel"
              defaultValue={""}
              value={seniorityLevel}
              onChange={(value: string) => {
                setSeniorityLevel(value);
              }}
              maxW="100%"
            />
            {!seniorityLevel && (
              <Text color="red" pl="1" mt="2px !important">
                {errors?.seniorityLevel}
              </Text>
            )}
            <FormControl isRequired>
              <FormLabel fontSize="16px" fontWeight="600">
                Work Environment
              </FormLabel>
            </FormControl>
            <RadioGroup
              options={["Remote", "In Office", "Hybrid"]}
              name="management"
              defaultValue={""}
              value={locationsType}
              onChange={(value: string) => {
                setLocationType(value);
                setLocationList([]);
                setSearchLocationList([]);
              }}
              maxW="100%"
            />
            {!locationsType && (
              <Text color="red" pl="1">
                {errors?.locationsType}
              </Text>
            )}
            {locationsType === "Remote" && (
              <>
                <Flex align="start">
                  <Text fontSize="16px" fontWeight="600" me="3px">
                    Preferred Locations for Remote Work
                  </Text>
                  <Box as="span" color="red.500" fontSize="md" fontWeight="600">
                    *
                  </Box>
                </Flex>
                <CheckBoxButton
                  handleOptionSelection={handleLocationList}
                  options={optionRemoteLocations}
                  selectedOptions={locationsList}
                />
                {locationsList?.length === 0 && errors?.locationListError && (
                  <Text color="red" pl="1">
                    {errors?.locationListError}
                  </Text>
                )}
                {(locationsList.includes(
                  "Specific countries (please specify below)"
                ) ||
                  customLocationList?.length >= 1) && (
                  <>
                    <FormControl w={{ base: "100%", md: "70%" }}>
                      <Flex align="start">
                        <FormLabel fontWeight="600" me="2px">
                          Specific Countries for Remote Work
                        </FormLabel>
                        <Box
                          as="span"
                          color="red.500"
                          fontSize="md"
                          fontWeight="600"
                        >
                          *
                        </Box>
                      </Flex>
                      <Flex gap="2">
                        <Input
                          type="text"
                          placeholder="Enter specific countries"
                          border="1px solid grey"
                          value={customLocation}
                          onChange={(e) =>
                            setCustomLocation(e.target.value.trimLeft())
                          }
                          isInvalid={errors?.customLocationError}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.stopPropagation();
                              e.preventDefault();
                              handleAddSpecificCountries();
                            }
                          }}
                        />
                        <Button
                          ml="2"
                          colorScheme="buttonPrimary"
                          onClick={handleAddSpecificCountries}
                        >
                          Add
                        </Button>
                      </Flex>
                      {errors?.customLocationError && (
                        <Text size="sm" color="red">
                          {errors?.customLocationError}
                        </Text>
                      )}
                    </FormControl>
                    <Flex direction="column" gap="1" w="90%">
                      <Flex gap={"10px"} pt="2" w="full" flexWrap="wrap">
                        {customLocationList &&
                          customLocationList?.map(
                            (location: any, index: number) => (
                              <Tag key={index}>
                                <TagLabel>{location}</TagLabel>
                                <TagCloseButton
                                  onClick={() =>
                                    handleRemoveCustomLocation(index)
                                  }
                                />
                              </Tag>
                            )
                          )}
                      </Flex>
                    </Flex>
                  </>
                )}
              </>
            )}
            {locationsType && locationsType !== "Remote" && (
              <>
                <FormControl
                  isRequired
                  isInvalid={Boolean(errors.availableLocationError)}
                >
                  <Flex
                    direction="column"
                    maxW={{ base: "100%", md: "70%" }}
                    w="full"
                  >
                    <FormLabel fontSize="sm" fontWeight="600">
                      Available Locations
                    </FormLabel>
                    <GooglePlacesAutocomplete
                      apiKey={googleAPI}
                      selectProps={{
                        value: searchLocationList[0],
                        onChange: (newValue) => handleAddLocation(newValue),
                        placeholder: "Enter the location...",
                        components: {
                          MenuList: CustomMenuList,
                        },
                        styles: {
                          input: (provided) => ({
                            ...provided,
                            color: "blue",
                          }),
                        },
                      }}
                    />
                  </Flex>
                  <Flex gap={"10px"} pt="2">
                    {searchLocationList.map((city: any, index: any) => (
                      <Tag key={index}>
                        <TagLabel>{city}</TagLabel>
                        <TagCloseButton
                          onClick={() => handleRemoveAvailableLocations(index)}
                        />
                      </Tag>
                    ))}
                  </Flex>

                  {errors && errors.availableLocationError && (
                    <Text fontSize="sm" color="red.500">
                      {errors.availableLocationError}
                    </Text>
                  )}
                </FormControl>
              </>
            )}
          </Stack>
        </Box>
        <HStack
          spacing={"24px"}
          justify={"space-between"}
          w="full"
          mt="6 !important"
        >
          <Button
            loadingText="Loading"
            color="#74788d"
            spinnerPlacement="end"
            size="md"
            fontWeight={"bold"}
            _hover={{ color: "white", bg: "#5867DD" }}
            onClick={() => {
              previousStep();
            }}
          >
            Back
          </Button>
          <Button
            colorScheme="buttonPrimary"
            onClick={() => {
              if (handleValidation()) {
                nextStep();
              }
            }}
          >
            Next
          </Button>
        </HStack>
      </Card>
    </VStack>
  );
};
export default JobFunc;
