import { useQuery } from "react-query";

import { useToast } from "@chakra-ui/react";

import { useFetch } from "../utils/useFetch";

const fetchJobFunc = async (id: any) => {
  // const { data: response } = await useFetch.get(
  //   // `users/domain/get-all-job-function?userId=${id}`
  // );
  // return response?.body ? response?.body : response;
};

export const useFetchJobFunc = (data: any) => {
  return useQuery(["jobFunc-fetch", data], () => fetchJobFunc(data), {
    staleTime: 0,
    onSuccess: (data: any) => {},
    onError: (error: any) => {},
  });
};
const fetchUserJobs = async (id: any) => {
  const { data: response } = await useFetch.get(`jobs/user-jobs`);

  return response?.body ? response?.body : response;
};

export const useFetchUserJobs = (data: any) => {
  const toast = useToast();
  return useQuery(["job-fetch", data], () => fetchUserJobs(data), {
    staleTime: 0,
    onSuccess: (data: any) => {},
    onError: (error: any) => {
      toast({
        title: "Error Getting Job Function  list",
        description: `Something Went Wrong`,
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });
    },
  });
};
const fetchUserUnassignedJobs = async (id: any) => {
  const { data: response } = await useFetch.get(
    `users/employer/candidate-not-applicant-in-jobs?candidateId=${id}`
  );

  return response?.body ? response?.body : response;
};

export const useFetchUserUnassignedJobs = (data: any) => {
  const toast = useToast();
  return useQuery(["job-fetch", data], () => fetchUserUnassignedJobs(data), {
    staleTime: 0,
    onSuccess: (data: any) => {},
    onError: (error: any) => {
      toast({
        title: "Error Getting Job Function  list",
        description: `Something Went Wrong`,
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });
    },
  });
};
