import {
  Badge,
  Card,
  CardBody,
  Flex,
  Heading,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const AppliedJobCard = ({ data }: any) => {
  const navigate = useNavigate();
  return (
    <>
      <Card
        maxW="100%"
        borderRadius="md"
        w="full"
        cursor="pointer"
        onClick={() => navigate(`/jobs/${data?.id}`, { state: data })}
      >
        <CardBody py="30px">
          <Flex direction="column" gap="2" mt="5px">
            <Heading
              size="lg"
              color="#48465b"
              fontWeight="500"
              fontSize={{
                base: "16.8px",
                md: "16.8px",
                lg: "18.2px",
                xl: "18.2px",
              }}
              mb="2"
              _hover={{ color: "#5867DD", cursor: "pointer" }}
            >
              {data?.name}
            </Heading>
            <Text
              fontSize="13px"
              color="#646c9a"
              fontWeight="500"
              mt="0px"
              mb="2"
            >
              {data?.locationsList?.length
                ? `${data?.locationsList[0]}`
                : "Remote"}
              {data?.reqNumber && ` | Req#  ${data?.reqNumber}`}
            </Text>
            <Stack
              direction="row"
              spacing={4}
              flexWrap="wrap"
              justify="space-between"
              w="full"
            >
              <Text fontSize="13px" color="#646c9a">
                Posting Date:
                <Badge
                  borderRadius="md"
                  ml="1"
                  colorScheme="buttonPrimary"
                  p="1"
                  color="#5867DD"
                  px="2"
                  bg="#5d78ff1a"
                  textTransform="capitalize"
                >
                  {moment(data?.createdAt).format("MMMM DD, YYYY")}
                </Badge>
              </Text>
            </Stack>
          </Flex>
        </CardBody>
      </Card>
    </>
  );
};

export default AppliedJobCard;
