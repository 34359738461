import React, { useState } from "react";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Center,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Progress,
  Text,
  VStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BsThreeDots } from "react-icons/bs";
import { BiMedal } from "react-icons/bi";
import { AiFillFire } from "react-icons/ai";
import { GoLocation } from "react-icons/go";
import axios from "axios";
import { useFetch } from "../../../utils/useFetch";

const SearchTalentCard = ({ data }: any) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { pathname } = useLocation();
  const [linkedIn, setLinkedIn] = useState("");
  const navigate = useNavigate();

  const downloadResume = async (userId: string) => {
    try {
      const response = await useFetch.get(
        `/users/talent/resume?userId=${userId}`
      );

      if (
        response.data.body.resume === "" ||
        response.data.body.resume === null
      ) {
        useFetch
          .get(`/users/talent/linkedin-profile?userId=${userId}`)
          .then((res) => {
            setLinkedIn(res.data);
            onOpen();
          })
          .catch((error) => {
            console.log("error", error);
          });
      } else {
        let fileUrl = response.data.body.resume;
        const downloadLink = document.createElement("a");
        downloadLink.href = fileUrl;
        downloadLink.target = "_blank";
        downloadLink.download = "downloaded-file.pdf";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    } catch (error) {
      console.error("Error downloading resume:", error);
    }
  };

  return (
    <>
      <Card maxW="lg" borderRadius={"md"} py="2" maxWidth={"100%"}>
        <Flex justifyContent={"flex-end"} h="24px" pr="4">
          {" "}
          <Menu>
            <MenuButton
              as={IconButton}
              variant="ghost"
              colorScheme="gray"
              aria-label="See menu"
              my="1"
              icon={<BsThreeDots size={"22px"} color="#5867DD" />}
            />
            <MenuList>
              {/* <MenuItem>Schedule an Interview</MenuItem> */}
              <MenuItem onClick={() => navigate(`/chat`, { state: data })}>
                Message Candidate
              </MenuItem>
              <MenuItem onClick={() => downloadResume(data?.userId)}>
                Download Resume
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
        <CardHeader>
          <Flex>
            <Flex flex="1" gap="4" alignItems="start">
              <Avatar name="" src={data?.profilePicture} size={"xl"} />

              <VStack align={"baseline"} pos="relative">
                <Heading
                  size="lg"
                  color="#48465b"
                  fontWeight={"500"}
                  fontSize={{
                    base: "16.8px",
                    md: "16.8px",
                    lg: "18.2px",
                    xl: "18.2px",
                  }}
                  // whiteSpace={"nowrap"}
                  mb="6px !important"
                >
                  {data?.fullName?.length > 25
                    ? `${data?.fullName?.slice(0, 25)}...`
                    : data?.fullName}
                </Heading>
                <Text
                  fontSize={"13px"}
                  fontWeight="bold"
                  color={"#646c9a"}
                  mt="0px !important"
                >
                  {data?.jobTitle?.length > 25
                    ? `${data?.jobTitle?.slice(0, 25)} ...`
                    : data?.jobTitle}

                  {data?.companyName &&
                    ` @ ${
                      data?.companyName?.length > 25
                        ? `${data?.companyName?.slice(0, 25)}...`
                        : data?.companyName
                    }`}
                </Text>
                <Text fontSize={"13px"} color={"#646c9a"} mt="0px !important">
                  {data?.latestDegree}{" "}
                  {data?.schoolName &&
                    `@ ${
                      data?.schoolName?.length > 25
                        ? `${data?.schoolName?.slice(0, 25)}...`
                        : data?.schoolName
                    }`}
                </Text>
                <HStack mt="0px !important">
                  <Icon as={GoLocation} color="blue.500" />
                  <Text fontSize={"13px"} color={"#646c9a"}>
                    {" "}
                    {/* {data?.userLocation?.city}, {data?.userLocation?.state}{" "} */}
                    {JSON.parse(data?.userLocation?.address)?.label}
                    {/* {data?.userLocation.address} */}
                  </Text>
                </HStack>
              </VStack>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody pt="0">
          <VStack align={"baseline"} w="full">
            <HStack w={"full"} alignItems="flex-start" mt="4 !important">
              <HStack flexWrap={"wrap"}>
                <Icon as={BiMedal} fontSize="30px" color="gray.500" mt="0.5" />
                {data?.interestedJobFunctions?.map((item: string) => (
                  <Badge
                    bg="#ffb822"
                    fontWeight="100"
                    px="1.5"
                    py="0.5"
                    borderRadius="xl"
                    fontSize="12px"
                    mt="0.5 !important"
                    mr="0 !important"
                    ml="1 !important"
                    key={item}
                    color="#48465b"
                    textTransform="capitalize"
                    whiteSpace="normal"
                    wordBreak="break-word"
                  >
                    {item}
                  </Badge>
                ))}
              </HStack>
            </HStack>
          </VStack>
        </CardBody>

        <CardFooter justify="space-between" flexWrap="wrap">
          <Button
            flex="1"
            variant="outline"
            w="full"
            color={"#5867DD"}
            borderColor={"#5867DD"}
            fontSize={"13px"}
            py="6"
            onClick={() =>
              navigate(`${pathname}/${data?.userId}`, { state: data })
            }
            _hover={{ bg: "#5867DD", color: "white" }}
            maxW={"500px"}
          >
            View Details
          </Button>
        </CardFooter>
      </Card>
      <Modal isCentered isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(1px) " />
        <ModalContent>
          <ModalCloseButton onClick={onClose} />
          <ModalBody pt="50px">
            <Text fontWeight="700" textAlign="center" fontSize="20px">
              This candidate has not uploaded Resume
            </Text>
            <VStack padding="20px 40px">
              <Text textAlign="center">
                Please visit their LinkedIn profile
              </Text>
              <Link to={linkedIn} target="_blank" style={{ color: "blue" }}>
                <Text
                  wordBreak="break-word"
                  _hover={{ textDecor: "underline" }}
                >
                  {" "}
                  {linkedIn}
                </Text>
              </Link>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SearchTalentCard;
