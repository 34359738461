import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Input,
  Heading,
  Stack,
  Card,
  FormControl,
  FormLabel,
  VStack,
  HStack,
  Button,
  Progress,
  FormErrorMessage,
  Textarea,
  useToast,
  Flex,
  Icon,
  Text,
} from "@chakra-ui/react";
import { useWizard } from "react-use-wizard";
import { FiUpload } from "react-icons/fi";

import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";

// PDFLib.GlobalWorkerOptions.workerSrc =
// "//mozilla.github.io/pdf.js/build/pdf.worker.mjs";

const BasicInfoJob = ({
  handleInputChange,
  reqNumber,
  name,
  description,
  setDescription,
}: any) => {
  const toast = useToast();
  const { handleStep, previousStep, nextStep, activeStep, stepCount } =
    useWizard();
  const progress = ((activeStep + 1) / stepCount) * 100;
  const [errors, setErrors] = useState<any>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleValidation = () => {
    let error: any = {
      description: "",
    };

    let formIsValid = true;

    if (!description) {
      formIsValid = false;
      error.description = "Please Enter Description";
    } else if (description.length > 5000) {
      formIsValid = false;
      error.description =
        "Description length exceeds the maximum limit of 5000 characters.";
    }
    setErrors(error);
    return formIsValid;
  };

  const handlePDF = async (file: File) => {
    let pdfjs: any = undefined;

    if (window && (window as any).pdfjsLib) {
      pdfjs = (window as any).pdfjsLib;
      pdfjs.GlobalWorkerOptions.workerSrc = (window as any).pdfWorker;
    }

    const reader = new FileReader();

    return new Promise<string>((resolve) => {
      reader.onload = async (e) => {
        const contents = e?.target?.result;

        if (!contents || !pdfjs) {
          resolve("");
          return;
        }

        const loadingTask = pdfjs.getDocument(contents);
        const pdfDocument = await loadingTask.promise;

        const numberOfPages = pdfDocument._pdfInfo.numPages;
        let extractedText = "";
        for (let i = 1; i <= numberOfPages; ++i) {
          const page = await pdfDocument.getPage(i);

          const textContent = await page.getTextContent();
          const pageText = textContent.items
            .map((item: any) => item.str)
            .join(" ");
          extractedText += pageText;
        }
        resolve(extractedText);
      };
      reader.readAsArrayBuffer(file);
    });
  };
  const handleFileChange = async (file: File) => {
    let description = "";

    const fileExtension = file?.name?.split(".")?.pop()?.toLowerCase();

    switch (fileExtension) {
      case "txt":
        const readerTxt = new FileReader();
        description = await new Promise<string>((resolve) => {
          readerTxt.onload = (e: any) => {
            const arrayBuffer: any = e?.target?.result;
            resolve(new TextDecoder("utf-8").decode(arrayBuffer));
          };
          readerTxt.readAsArrayBuffer(file);
        });
        break;

      case "pdf":
        description = await handlePDF(file);
        break;

      case "docx":
        const readerDocx = new FileReader();
        description = await new Promise<string>((resolve) => {
          readerDocx.onload = async (e: any) => {
            const arrayBuffer: any = e?.target?.result;

            const zip = new PizZip(arrayBuffer);
            const doc = new Docxtemplater().loadZip(zip);
            description = doc.getFullText();

            resolve(description);
          };
          readerDocx.readAsArrayBuffer(file);
        });
        break;

      default:
        toast({
          title: "Unsupported File Type",
          description: `Unsupported file type: ${fileExtension}`,
          status: "error",
          variant: "top-accent",
          isClosable: true,
        });
    }

    return description;
  };

  const handleFlexClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  useEffect(() => {
    if (description.length > 5000) {
      setErrors({
        description:
          "Description length exceeds the maximum limit of 5000 characters.",
      });
    } else {
      setErrors({ description: "" });
    }
  }, [description]);
  return (
    <VStack w="full" gap={4}>
      <Card p="4" w="full">
        <Box>
          <Progress
            colorScheme="progressBar"
            size="md"
            w="full"
            borderRadius={"lg"}
            my="6"
            value={progress}
            mb={8}
          />
        </Box>
        <Box maxW="800px" my="4">
          <Stack>
            <FormControl
              isRequired
              mb="2 !important"
              isInvalid={Boolean(errors?.description)}
            >
              <FormLabel fontSize="xl" fontWeight="600" htmlFor="description">
                Job Description
              </FormLabel>
              <Flex gap="3" my="5" align="center">
                <Text> Upload the JD </Text>
                <div>
                  <Button
                    onClick={handleFlexClick}
                    colorScheme="buttonPrimary"
                    w="100px"
                    rightIcon={<FiUpload />}
                    size="sm"
                  >
                    Upload
                  </Button>
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={(e: any) => {
                      const selectedFile = e?.target?.files[0];
                      if (selectedFile) {
                        handleFileChange(selectedFile).then((result) => {
                          setDescription(result);
                        });
                      }
                    }}
                    style={{ display: "none" }}
                  />
                </div>
              </Flex>
              <Textarea
                flex={1}
                placeholder="Enter description"
                maxW={{ base: "100%", md: "70%" }}
                borderColor="grey"
                name="description"
                value={description}
                onChange={(e) => setDescription(e.target.value.trimLeft())}
                h="300px"
              />
              {errors?.description && (
                <Text fontSize="sm" color="red">
                  {errors?.description}
                </Text>
              )}
            </FormControl>
          </Stack>
        </Box>
        <HStack justify="space-between" w="full" mt="6 !important">
          <Flex w="full" flexDirection="column">
            <Text>
              Characters: <strong>{description?.length}</strong>
            </Text>
            <Text>
              Max Limit: <strong>5000 </strong>characters
            </Text>
          </Flex>
          <Button
            colorScheme="buttonPrimary"
            onClick={() => {
              if (handleValidation()) {
                nextStep();
              }
            }}
          >
            Next
          </Button>
        </HStack>
      </Card>
    </VStack>
  );
};

export default BasicInfoJob;
