import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthStore } from "../../store/useAuth";
import { handleLoginLocalStorage } from "../../utils/helper";
import { useSignupApi, useVerifyAPI } from "../../service/auth.service";
import { useUserStore } from "../../store/useUser";
import { useFetch } from "../../utils/useFetch";
import { useToast } from "@chakra-ui/react";

const useVerify = () => {
  const { setUser } = useUserStore();
  const navigate = useNavigate();
  const toast = useToast();
  const [lodaing, setLoading] = useState(false);
  const [isResendOTP, setIsResendOTP] = useState(false);
  const { state } = useLocation();
  const [error, setError] = useState({
    digitCompletionError: "",
    wrongOtpError: "",
  });
  const {
    mutate: verifyCall,

    isLoading: verifyLoading,
    errorMessage,
  } = useVerifyAPI();
  useEffect(() => {
    if (errorMessage) {
      setError((prevError) => ({
        ...prevError,
        digitCompletionError: "",
        wrongOtpError: errorMessage,
      }));
    }
  }, [errorMessage]);
  const { mutate: signupCall, isLoading: signupLoading } = useSignupApi();

  const { isEmployeer } = useAuthStore();
  const [otp, setOtp] = useState<string>();

  const {
    control,
    handleSubmit,
    watch,
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<any>({});
  const onSubmit = async (otpProps: any) => {
    if (!otpProps.length) {
      setError((prevError) => ({
        ...prevError,
        digitCompletionError: "Please Enter 6 digit code",
      }));
      return;
    }
    const query = state?.formData?.query;
    const userType = state?.formData?.userType;
    const id = state?.id;
    const formDATA = {
      pinCode: +otpProps,
      query,
      userType,
      id,
    };
    if (state.id.length > 1) {
      setError((prevError) => ({
        ...prevError,
        digitCompletionError: "",
      }));
      setLoading(true);
      useFetch
        .post(`/users/auth/employer-verify`, formDATA)
        .then((resData) => {
          setLoading(false);
          const token = resData.data.token;
          const rest = resData.data;
          handleLoginLocalStorage(token, rest);
          setUser(rest);
          localStorage.setItem(
            "profilepercentage",
            JSON.stringify(resData?.data?.profileCompletePercentage)
          );
          navigate("/");
        })
        .catch((error) => {
          setLoading(false);
          setError((prevError) => ({
            ...prevError,
            digitCompletionError: error?.response?.data,
          }));
        });
    } else {
      await verifyCall(formDATA, {
        onSuccess: (resData) => {
          const { token, ...rest } = resData;
          handleLoginLocalStorage(token, rest);
          setUser(rest);
          localStorage.setItem(
            "profilepercentage",
            JSON.stringify(resData?.profileCompletePercentage)
          );
          if (resData?.profileCompletePercentage >= 29) {
            navigate("/");
          } else if (isEmployeer) {
            navigate("/");
          } else {
            navigate("/setupFlow/location");
          }
        },
      });
    }
  };
  const handlePaste = (event: any, fieldIndex: any) => {
    const pastedValue = event.clipboardData.getData("text/plain");
    const values = pastedValue.split("");
    for (let i = 0; i < values.length; i++) {
      const fieldName: any = `pin${fieldIndex + i}`;
      setValue(`${fieldName}`, values[i]);
      trigger(`${fieldName}`);
    }
  };
  const handleResendOTP = () => {
    setIsResendOTP(true);
    useFetch
      .post(`/users/auth/employer-verify-resend-otp`, { query: state?.id })
      .then((res) => {
        setIsResendOTP(false);
        // toast({
        //   title: "OTP Resend Successfully",
        //   status: "success",
        //   duration: 2000,
        //   isClosable: true,
        // });
      })
      .catch((error) => {
        setIsResendOTP(false);
        console.error();
      });
  };
  return {
    onSubmit,
    control,
    handleSubmit,
    errors,
    register,
    state,
    lodaing,
    verifyLoading,
    signupLoading,
    signupCall,
    watch,
    handlePaste,
    handleResendOTP,
    isResendOTP,
    error,
    setError,
    otp,
    setOtp,
  };
};
export default useVerify;
