import React from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { GenericModal } from "../../../common/generic-modal";
import useReschedule from "./useReschedule";
import CustomDatePicker from "../../../common/date-picker/datepicker";
import CheckBoxButton from "../../../common/checkboxButton";
import StepsAnimation from "../../../schedule-meeting/steps-animation";
import moment from "moment";
const optionstimes: any = [
  "12:00 AM",

  "01:00 AM",

  "02:00 AM",

  "03:00 AM",

  "04:00 AM",

  "05:00 AM",

  "06:00 AM",

  "07:00 AM",

  "08:00 AM",

  "09:00 AM",

  "10:00 AM",

  "11:00 AM",

  "12:00 PM",

  "01:00 PM",

  "02:00 PM",

  "03:00 PM",

  "04:00 PM",

  "05:00 PM",

  "06:00 PM",

  "07:00 PM",

  "08:00 PM",

  "09:00 PM",

  "10:00 PM",

  "11:00 PM",
];

const MySchedule = () => {
  const {
    isOpen,
    onOpen,
    onModalClose,
    onChangeDate,
    handleOptionTimes,
    selectedTimes,
    watch,
    setReschedule,
    reschedule,
    isSubmitTimes,
    setIsSubmitTimes,
    setCopied,
    copied,
    onChangeDate2,
    handleOptionTimes2,
    isSubmitTimes2,
    selectedTimes2,
    toggleAnotherDay,
    anotherDay,
    setIsSubmitTimes2,
    scheduleList,
    handleView,
    selectedItem,
    onSubmit,
    scheduleLoading,
    fetchLoading,
    error,
  } = useReschedule();

  return (
    <>
      <Card h="calc(30% - 26px)" w="100%" mb="4 !important">
        <CardHeader pb="0px !important">
          <Heading fontSize={"16px"} as="h4">
            My Schedule
          </Heading>
          <Text color={"gray"} fontSize="sm">
            {`  ${
              scheduleList?.length ? scheduleList.length : 0
            } upcoming meetings`}
          </Text>
        </CardHeader>
        <CardBody overflow={"auto"}>
          <VStack w="full" spacing={4}>
            {scheduleList?.map((item: any) => (
              <HStack
                w="full"
                justify={"space-between"}
                key={item?.candidateName}
              >
                <VStack
                  borderLeft={"4px solid #bebfc1"}
                  paddingStart="4"
                  align={"baseline"}
                >
                  <HStack>
                    <Text
                      fontSize={"sm"}
                      mt="0px !important"
                      fontWeight={"bold"}
                    >
                      Selected Times :
                    </Text>
                    {item?.selectedTimes?.map((time: string, index: number) => (
                      <Text fontSize={"sm"} mt="0px !important" key={index}>
                        {time}
                        {index !== item.selectedTimes.length - 1 ? "," : ""}
                      </Text>
                    ))}
                  </HStack>

                  <Text fontSize={"sm"} mt="0px !important" fontWeight={"bold"}>
                    {item?.eventName}
                  </Text>
                  <HStack mt="0px !important">
                    <Text fontSize={"sm"} color="gray">
                      Schedule with
                    </Text>{" "}
                    <Text fontSize={"sm"} color="blue.500">
                      {item?.candidateName}
                    </Text>
                  </HStack>
                </VStack>
                <Button
                  colorScheme="buttonPrimary"
                  size="sm"
                  onClick={() => handleView(item)}
                >
                  View
                </Button>
              </HStack>
            ))}
          </VStack>
        </CardBody>
      </Card>
      <GenericModal
        isOpen={isOpen}
        onClose={onModalClose}
        title={"Schedule Details"}
        onClickHandler={() => console.log("values")}
        buttonText={false ? "Loading" : "Save"}
        loadingButton={false || false}
        buttonShow={false}
      >
        <VStack w="full" justify="start" align="start">
          <HStack w="full">
            <Avatar size="sm" name="" src={selectedItem?.candidateImage} />
            <Text fontSize={"lg"} fontWeight={"bold"} w="full">
              {selectedItem?.candidateName}
            </Text>
          </HStack>

          <HStack w="full">
            <Text fontSize={"sm"} color="gray">
              Event Name :
            </Text>{" "}
            <Text fontSize={"sm"} color="blue.500">
              {selectedItem?.eventName}
            </Text>
          </HStack>
          <Text fontSize={"sm"} color={"gray"} w="full">
            {moment(selectedItem?.date).format("ddd, MMMM DD, YYYY")}
          </Text>
          <HStack w="full">
            <Text fontSize={"sm"} mt="0px !important" fontWeight={"bold"}>
              Selected Times :
            </Text>
            {selectedItem?.selectedTimes?.map((time: string, index: number) => (
              <Text fontSize={"sm"} mt="0px !important" key={index}>
                {time}
                {index !== selectedItem.selectedTimes.length - 1 ? "," : ""}
              </Text>
            ))}
          </HStack>

          {!reschedule && (
            <>
              {selectedItem?.locations === "phone" && (
                <FormControl>
                  <FormLabel fontWeight={"bold"}> Phone Number</FormLabel>
                  <InputGroup
                    size="md"
                    w={{ base: "100%", md: "60%" }}
                    ml="-5px"
                  >
                    <Input isReadOnly={true} value={selectedItem?.phone} />
                    <InputRightElement width="4.5rem">
                      <Button
                        h="1.75rem"
                        size="sm"
                        onClick={async () => {
                          setCopied(true);
                          await navigator.clipboard.writeText(
                            selectedItem?.phone
                          );
                        }}
                      >
                        {copied ? "Copied" : "Copy"}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
              )}
              {selectedItem?.locations === "video/web" && (
                <FormControl>
                  <FormLabel fontWeight={"bold"}> Meeting Link</FormLabel>
                  <InputGroup
                    size="md"
                    w={{ base: "100%", md: "60%" }}
                    ml="-5px"
                  >
                    <Input isReadOnly={true} value={selectedItem?.link} />
                    <InputRightElement width="4.5rem">
                      <Button
                        h="1.75rem"
                        size="sm"
                        onClick={async () => {
                          setCopied(true);
                          await navigator.clipboard.writeText(
                            selectedItem?.link
                          );
                        }}
                      >
                        {copied ? "Copied" : "Copy"}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
              )}
              {selectedItem?.locations === "in-person" && (
                <FormControl>
                  <FormLabel fontWeight={"bold"}> Address</FormLabel>
                  <InputGroup
                    size="md"
                    w={{ base: "100%", md: "60%" }}
                    ml="-5px"
                  >
                    <Input
                      isReadOnly={true}
                      value={
                        selectedItem?.address
                          ? JSON.parse(selectedItem?.address)?.label
                          : ""
                      }
                    />
                    <InputRightElement width="4.5rem">
                      <Button
                        h="1.75rem"
                        size="sm"
                        onClick={async () => {
                          setCopied(true);
                          await navigator.clipboard.writeText(
                            selectedItem?.address
                              ? JSON.parse(selectedItem?.address)?.label
                              : ""
                          );
                        }}
                      >
                        {copied ? "Copied" : "Copy"}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
              )}
              <Text fontWeight={"bold"} w="full" mt="2">
                Interview Detail
              </Text>
              <Box
                border="1px solid gray"
                w={{ base: "100%", md: "70%" }}
                p={{ base: "1", md: "2", lg: "3" }}
                // ps="20"
              >
                <HStack mt="2" gap={2} align="start">
                  <Avatar name="" src={selectedItem?.companyLogo} size="md" />
                  <VStack align="start">
                    <Text fontSize="16px" fontWeight={"bold"} color={"#646c9a"}>
                      {selectedItem?.companyName ?? "N/A"}
                    </Text>
                    <Text fontSize={"sm"} fontWeight={"bold"} color={"#646c9a"}>
                      {selectedItem?.jobName}
                    </Text>
                    <Text fontSize={"xs"} color={"#646c9a"} mt="0 !important">
                      {selectedItem?.jobLocation?.length
                        ? `${selectedItem?.jobLocation[0]}`
                        : "Remote"}
                      {selectedItem?.reqNumber &&
                        ` | Req#  ${selectedItem?.reqNumber}`}
                    </Text>
                  </VStack>
                </HStack>
              </Box>

              <Text fontWeight={"bold"} w="full" mt="2">
                Invitee Details
              </Text>
              {selectedItem?.inputs && (
                <Box
                  border="1px solid gray"
                  w={{ base: "100%", md: "70%" }}
                  p={{ base: "1", md: "2", lg: "3" }}
                  // ps="20"
                >
                  <VStack w="full">
                    {selectedItem?.inputs.map((data: any, index: any) => (
                      <React.Fragment key={index}>
                        <VStack w="60%">
                          <HStack gap={2} align="start" w="full">
                            <Text fontSize="13px" fontWeight="700">
                              Email:
                            </Text>
                            <Text fontSize="13px">{data?.value}</Text>
                          </HStack>
                          <HStack gap={2} align="start" w="full">
                            <Text fontSize="13px" fontWeight="700">
                              Interview Type:
                            </Text>
                            <Text fontSize="13px">{data?.option}</Text>
                          </HStack>
                        </VStack>
                        {index !== selectedItem.inputs.length - 1 && (
                          <Divider
                            orientation="horizontal"
                            borderBottom="1px solid black"
                          />
                        )}
                      </React.Fragment>
                    ))}
                  </VStack>
                </Box>
              )}

              <Button
                colorScheme="buttonPrimary"
                mt="4 !important"
                onClick={() => setReschedule(true)}
              >
                Reschedule
              </Button>
            </>
          )}
          {reschedule && (
            <>
              <StepsAnimation>
                <Stack
                  w="full"
                  minH="350px"
                  maxH={"350px"}
                  overflow="auto"
                  align={"flex-start"}
                  flexDir={{ base: "column", md: "row" }}
                >
                  <VStack w="full" align={"flex-start"}>
                    <VStack w="full" align={"flex-start"}>
                      <Text fontWeight={"bold"} fontSize="md" w="full" pl="1">
                        Pick a day
                      </Text>
                      <Box
                        maxW={anotherDay ? "96%" : { base: "100%", md: "70%" }}
                        w="full"
                      >
                        <CustomDatePicker
                          label="Select date"
                          selected={watch("date")}
                          onChange={onChangeDate}
                        />
                      </Box>
                    </VStack>
                    <VStack w="full" align={"flex-start"}>
                      <Text
                        fontWeight={"bold"}
                        fontSize="md"
                        w="full"
                        pl="1"
                        mt="2"
                      >
                        Select Time Slots
                      </Text>
                      <Box
                        maxW={anotherDay ? "96%" : { base: "100%", md: "70%" }}
                        w="full"
                        maxH="200px"
                        h="200px"
                        overflow={"auto"}
                        p="2"
                      >
                        <CheckBoxButton
                          handleOptionSelection={handleOptionTimes}
                          options={optionstimes}
                          selectedOptions={selectedTimes}
                          width="full"
                        />
                      </Box>
                      {error?.time1 && (
                        <Text color="red" pl="2">
                          {error?.time1}
                        </Text>
                      )}
                    </VStack>
                  </VStack>
                  <VStack
                    w="full"
                    align={"flex-start"}
                    display={anotherDay ? "flex" : "none"}
                    mt={{ base: "2 !important", md: "0px !important" }}
                  >
                    <VStack w="full" align={"flex-start"}>
                      <Text fontWeight={"bold"} fontSize="md" w="full" pl="1">
                        Pick another day
                      </Text>
                      <Box
                        maxW={anotherDay ? "96%" : { base: "100%", md: "70%" }}
                        w="full"
                      >
                        <CustomDatePicker
                          label="Select date"
                          selected={watch("date2")}
                          onChange={onChangeDate2}
                        />
                      </Box>
                      {error?.day2 && (
                        <Text color="red" pl="2">
                          {error?.day2}
                        </Text>
                      )}
                    </VStack>
                    <VStack w="full" align={"flex-start"}>
                      <Text
                        fontWeight={"bold"}
                        fontSize="md"
                        w="full"
                        pl="1"
                        mt="2"
                      >
                        Select Time Slots
                      </Text>
                      <Box
                        maxW={anotherDay ? "96%" : { base: "100%", md: "70%" }}
                        w="full"
                        maxH="200px"
                        h="200px"
                        overflow={"auto"}
                        p="2"
                      >
                        <CheckBoxButton
                          handleOptionSelection={handleOptionTimes2}
                          options={optionstimes}
                          selectedOptions={selectedTimes2}
                          width="full"
                        />
                      </Box>
                      {error?.time2 && (
                        <Text color="red" pl="2">
                          {error?.time2}
                        </Text>
                      )}
                    </VStack>
                  </VStack>
                </Stack>
              </StepsAnimation>

              <HStack
                spacing={"24px"}
                justify={"space-between"}
                w="full"
                my="2"
              >
                <HStack>
                  <Button
                    loadingText="Loading"
                    color="#74788d"
                    spinnerPlacement="end"
                    size="md"
                    fontWeight={"bold"}
                    _hover={{ color: "white", bg: "#5867DD" }}
                    // leftIcon={<IoIosArrowBack />}
                    onClick={() => {
                      setReschedule(false);
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    colorScheme="buttonPrimary"
                    fontWeight={"bold"}
                    _hover={{ color: "white", bg: "#5867DD" }}
                    // leftIcon={<IoIosArrowBack />}
                    onClick={toggleAnotherDay}
                  >
                    {anotherDay ? "Cancel" : "Add another day"}
                  </Button>
                </HStack>
                <Button
                  colorScheme="buttonPrimary"
                  isLoading={scheduleLoading}
                  isDisabled={scheduleLoading}
                  onClick={() => {
                    setIsSubmitTimes(true);
                    selectedTimes.length > 0 && onSubmit();
                  }}
                >
                  Reschedule
                </Button>
              </HStack>
            </>
          )}
        </VStack>
      </GenericModal>
    </>
  );
};

export default MySchedule;
