import {
  Box,
  Button,
  Card,
  Center,
  Flex,
  HStack,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { BiPlus } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import useExperience from "./useExperience";
import ExperienceCard from "./experienceCard";
import { verfiedIcon } from "../../../../assets/images";

const Experience = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { experienceList, experienceListLoading } = useExperience();

  const handleContinue = () => {
    if (state && state === "profile") {
      navigate(-1);
    } else {
      onOpen();
    }
  };

  return (
    <>
      <Card p="4" w="full">
        <Box my="4">
          <Stack>
            <HStack w="full" justify={"space-between"}>
              <Flex direction="column">
                <Heading size="lg" fontWeight={"500"}>
                  Experience Information
                </Heading>
                <Text>
                  List your recent work experiences. You can add multiple
                  entries
                </Text>
              </Flex>
              {experienceList && experienceList?.length < 1 && (
                <Button
                  colorScheme="buttonPrimary"
                  variant="outline"
                  size={"sm"}
                  onClick={() => navigate("/search")}
                  _hover={{ color: "white", bg: "buttonPrimary.600" }}
                >
                  Skip This Step
                </Button>
              )}
            </HStack>
            <Flex>
              {/* {experienceList && experienceList.length > -1 && ( */}
              <Button
                leftIcon={<BiPlus />}
                colorScheme="buttonPrimary"
                variant="outline"
                size={"sm"}
                onClick={() => navigate("add")}
                _hover={{ color: "white", bg: "buttonPrimary.600" }}
              >
                Add Position
              </Button>
              {/* )} */}
            </Flex>
            {experienceListLoading ? (
              <Center>
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="xl"
                />
              </Center>
            ) : experienceList && experienceList?.length > 0 ? (
              experienceList.map((item: any, index: any) => (
                <ExperienceCard data={item} key={index} index={index} />
              ))
            ) : (
              <Center
                h="100px"
                border="1px solid #e3e3e3"
                w="full"
                borderRadius={"md"}
              >
                <Text color={"#c1c1c1"}> No Experience Data available</Text>
              </Center>
            )}
          </Stack>
          {experienceList && experienceList?.length !== 0 && (
            <Button colorScheme="buttonPrimary" mt="4" onClick={handleContinue}>
              {state && state === "profile" ? "Save" : "Continue"}
            </Button>
          )}
        </Box>
      </Card>
      <Modal isCentered isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(5px) " />
        <ModalContent>
          <ModalCloseButton
            onClick={() => {
              onClose();
              navigate("/search");
            }}
          />
          <ModalBody pt="50px">
            <Text fontWeight="700" textAlign="center" fontSize="20px">
              Congratulations!
            </Text>
            <VStack padding="20px 40px">
              <Text textAlign="center">
                Thank you for completing your profile! A talent advocate at Moon
                will review your details within the next 48 hours and will get
                back to you.
              </Text>
              <Image src={verfiedIcon} height="56px" width="56px" />
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Experience;
