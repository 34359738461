import { useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  Card,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Heading,
  Input,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Controller } from "react-hook-form";

import useEducation from "../useEducation";
import { useFetch } from "../../../../../utils/useFetch";

import "./add-education.css";
import CustomDatePicker from "../../../../common/date-picker/datepicker";

const educationStatusOptions = [
  { label: "Education in progress", value: "IN_PROCESS" },
  { label: "Incomplete program", value: "INCOMPLETE" },
  { label: "Education completed", value: "COMPLETED" },
];

const AddEducation = () => {
  const {
    onSubmit,

    handleStartDateChange,
    handleEndDateChange,
    educationLoading,
    updateHandler,
    state,
    educationUpdateLoading,
    finalSchoolName,
    setFinalSchoolName,
    finalFieldofStudy,
    setFinalFieldOfStudy,
    errors,
    setErrors,
    handleValidation,
    degree,
    setDegree,
    educationStatus,
    setEducationStatus,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
  } = useEducation();
  const [school, setSchool] = useState("");
  const [schoolResult, setSchoolResult] = useState([]);
  const [showSchoolResult, setShowSchoolResult] = useState(false);
  const [isSchoolFocused, setIsSchoolFocused] = useState(false);
  const [fieldOfStudy, setFieldOfStudy] = useState("");
  const [fieldResult, setFieldResult] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);

  const [showfieldResult, setShowfieldResult] = useState(false);
  const [isFieldFocused, setIsFieldFocused] = useState(false);

  const handleFocusBlur = (value: boolean) => {
    setIsSchoolFocused(value);
  };
  useEffect(() => {
    if (isSchoolFocused) {
      setFinalSchoolName(school);
      useFetch
        .get(`/users/domain/all-schoolname-list?query=${school}`)
        .then((response) => {
          setSchoolResult(response.data);
          setShowSchoolResult(true);
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }
  }, [school]);
  const handleFieldFocusBlur = (value: boolean) => {
    setIsFieldFocused(value);
  };
  useEffect(() => {
    if (isFieldFocused) {
      setFinalFieldOfStudy(fieldOfStudy);
      useFetch
        .get(`/users/domain/all-study-field-list?query=${fieldOfStudy}`)
        .then((response) => {
          setFieldResult(response.data);
          setShowfieldResult(true);
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }
  }, [fieldOfStudy]);
  const handleSchoolName = (search: any) => {
    setSchool(search);
    setFinalSchoolName(search);
    setShowSchoolResult(false);
    setErrors((prevError) => ({
      ...prevError,
      schoolName: "",
    }));
  };
  const handleFieldName = (search: any) => {
    setFieldOfStudy(search);
    setFinalFieldOfStudy(search);
    setShowfieldResult(false);
    setErrors((prevError) => ({
      ...prevError,
      major: "",
    }));
  };

  const searchFieldRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        searchFieldRef.current &&
        !searchFieldRef.current.contains(event.target as Node)
      ) {
        handleFocusBlur(false);
        setShowSchoolResult(false);
        setShowfieldResult(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Card p="4" w="full">
      <form
        style={{ width: "100%" }}
        onSubmit={(e) => {
          e.preventDefault();
          if (handleValidation()) {
            if (state && state.isEdit) {
              updateHandler();
            } else {
              onSubmit();
            }
          }
        }}
        onKeyDown={(e: any) => {
          if (e.key === "Enter" && handleValidation()) {
            if (state && state.isEdit) {
              updateHandler();
            } else {
              onSubmit();
            }
          }
        }}
      >
        <Box my="4">
          <Stack>
            <HStack w="full" justify={"space-between"}>
              <Heading size="lg" fontWeight={"500"}>
                {" "}
                {state?.isEdit ? "Edit Education" : "Add Education"}
              </Heading>{" "}
            </HStack>
            <FormControl isInvalid={Boolean(errors?.degree)}>
              <Flex align="start">
                <FormLabel
                  fontWeight={"bold"}
                  fontSize="md"
                  me="2px !important"
                >
                  Degree
                </FormLabel>
                <Box as="span" color="red" fontSize="md">
                  *
                </Box>
              </Flex>

              <Select
                w={{ base: "100%", md: "70%" }}
                isDisabled={false}
                placeholder="Please select degree"
                h="42px"
                borderRadius={"md"}
                mr="2"
                onChange={(e) => {
                  setDegree(e.target.value);
                  setErrors((prevError) => ({ ...prevError, degree: "" }));
                }}
                value={degree}
                name="degree"
                size="md"
                color={degree ? "black" : "gray.500"}
              >
                <option value="Associate's">Associate's</option>
                <option value="Bachelor's">Bachelor's</option>
                <option value="Master's">Master's</option>
                <option value="PHD">PHD</option>
                <option value="Diploma">Diploma</option>
              </Select>
              {errors?.degree && (
                <Text color="red.500" fontSize="sm">
                  {errors?.degree}
                </Text>
              )}
            </FormControl>
            <VStack position="relative" align="start">
              <FormControl isInvalid={Boolean(errors?.schoolName)}>
                <Flex align="start">
                  <FormLabel
                    fontWeight={"bold"}
                    fontSize="md"
                    me="2px !important"
                  >
                    School Name
                  </FormLabel>
                  <Box as="span" color="red" fontSize="md">
                    *
                  </Box>
                </Flex>

                <Input
                  maxLength={100}
                  type="text"
                  placeholder="Enter school name"
                  w={{ base: "100%", md: "70%" }}
                  onChange={(e) => setSchool(e.target.value.trimLeft())}
                  onFocus={() => handleFocusBlur(true)}
                  onBlur={() => handleFocusBlur(false)}
                  value={finalSchoolName}
                />
                {errors?.schoolName && (
                  <Text color="red.500" fontSize="sm">
                    {errors?.schoolName}
                  </Text>
                )}
              </FormControl>
              {showSchoolResult && schoolResult?.length >= 1 && (
                <VStack
                  ref={searchFieldRef}
                  w={{ base: "100%", md: "70%" }}
                  position="absolute"
                  top="64px"
                  zIndex="99"
                  align="start"
                  boxShadow="md"
                  padding="10px 0px"
                  bg="white"
                  borderRadius="0px 0px 20px 20px"
                  maxH="200px"
                  overflowY="auto"
                  borderWidth="2px"
                  borderColor="gray.200"
                >
                  {schoolResult &&
                    schoolResult.map((data: any) => (
                      <Flex
                        key={data}
                        onClick={() => handleSchoolName(data.name)}
                        _hover={{ bg: "gray.50" }}
                        cursor="pointer"
                        w="full"
                        mt="0px !important"
                      >
                        <Text
                          fontWeight="400"
                          fontSize="16px"
                          lineHeight="20px"
                          color="#737098"
                          padding="10px"
                          letterSpacing="-0.16px"
                        >
                          {data.name}
                        </Text>
                      </Flex>
                    ))}
                </VStack>
              )}
            </VStack>
            <VStack position="relative" align="start">
              <FormControl isInvalid={Boolean(errors?.major)}>
                <Flex align="start">
                  <FormLabel
                    fontWeight={"bold"}
                    fontSize="md"
                    me="2px !important"
                  >
                    Field of study (major)
                  </FormLabel>
                  <Box as="span" color="red" fontSize="md">
                    *
                  </Box>
                </Flex>

                <Input
                  maxLength={100}
                  type="text"
                  placeholder="Enter field"
                  w={{ base: "100%", md: "70%" }}
                  onChange={(e) => {
                    setFieldOfStudy(e.target.value.trimLeft());
                  }}
                  onFocus={() => handleFieldFocusBlur(true)}
                  onBlur={() => handleFieldFocusBlur(false)}
                  value={finalFieldofStudy}
                />
                {errors?.major && (
                  <Text color="red.500" fontSize="sm">
                    {errors?.major}
                  </Text>
                )}
              </FormControl>
              {showfieldResult && fieldResult?.length >= 1 && (
                <VStack
                  ref={searchFieldRef}
                  w={{ base: "100%", md: "70%" }}
                  position="absolute"
                  top="64px"
                  zIndex="99"
                  height="auto"
                  boxShadow="md"
                  padding="10px 0px"
                  bg="white"
                  borderRadius="0px 0px 20px 20px"
                  maxH="200px"
                  overflowY="auto"
                  borderWidth="2px"
                  borderColor="gray.200"
                >
                  {fieldResult &&
                    fieldResult.map((data: any) => (
                      <Flex
                        key={data}
                        onClick={() => handleFieldName(data.name)}
                        _hover={{ bg: "gray.50" }}
                        cursor="pointer"
                        w="full"
                        mt="0px !important"
                      >
                        <Text
                          fontWeight="400"
                          fontSize="16px"
                          lineHeight="20px"
                          color="#737098"
                          padding="10px"
                          letterSpacing="-0.16px"
                        >
                          {data.name}
                        </Text>
                      </Flex>
                    ))}
                </VStack>
              )}
            </VStack>

            <FormControl isInvalid={Boolean(errors?.educationStatus)}>
              <Flex align="start">
                <FormLabel
                  fontWeight={"bold"}
                  fontSize="md"
                  me="2px !important"
                >
                  Education Status
                </FormLabel>
                <Box as="span" color="red" fontSize="md">
                  *
                </Box>
              </Flex>

              <RadioGroup
                value={educationStatus}
                onChange={(value) => {
                  setEducationStatus(value);
                  setErrors((prevError) => ({
                    ...prevError,
                    educationStatus: "",
                  }));
                }}
              >
                <VStack w="full" align="flex-start">
                  {educationStatusOptions.map((option) => (
                    <Radio key={option.value} value={option.value}>
                      {option.label}
                    </Radio>
                  ))}
                </VStack>
              </RadioGroup>
              {errors?.educationStatus && (
                <Text color="red.500" fontSize="sm">
                  {errors?.educationStatus}
                </Text>
              )}
            </FormControl>

            {educationStatus && (
              <Flex
                w={{ base: "100%", md: "70%" }}
                flexDir={{ base: "column", lg: "row" }}
                gap="3"
              >
                <FormControl isInvalid={Boolean(errors?.start)}>
                  <FormLabel fontWeight={"bold"} fontSize="md">
                    {educationStatus === "IN_PROCESS"
                      ? "Start Date"
                      : "Start Date"}
                  </FormLabel>
                  <CustomDatePicker
                    label="mm/yyyy"
                    selected={startDate}
                    placeholderText="mm/yyyy"
                    onChange={(date: any) => {
                      handleStartDateChange(date);
                      setSelectedStartDate(date);
                    }}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    dropdownMode="select"
                    maxDate={endDate || new Date()}
                  />
                  {errors?.start && (
                    <Text color="red.500" fontSize="sm">
                      {errors?.start}
                    </Text>
                  )}
                </FormControl>

                <FormControl isInvalid={Boolean(errors?.end)}>
                  <FormLabel fontWeight="bold" fontSize="md">
                    {educationStatus === "INCOMPLETE"
                      ? "End Date (Optional)"
                      : educationStatus === "IN_PROCESS"
                      ? "Expected Completion Date"
                      : "Completion Date"}
                  </FormLabel>

                  <CustomDatePicker
                    label="mm/yyyy"
                    selected={endDate}
                    placeholderText="mm/yyyy"
                    onChange={(date: any) => {
                      handleEndDateChange(date);
                    }}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    dropdownMode="select"
                    minDate={startDate}
                    maxDate={
                      educationStatus === "COMPLETED" ? new Date() : null
                    }
                  />
                  {errors?.end && (
                    <Text color="red.500" fontSize="sm">
                      {errors?.end}
                    </Text>
                  )}
                </FormControl>
              </Flex>
            )}
          </Stack>
          <Button
            colorScheme="buttonPrimary"
            mt="4"
            type="submit"
            isLoading={educationLoading || educationUpdateLoading}
            isDisabled={educationLoading || educationUpdateLoading}
          >
            Submit
          </Button>
        </Box>
      </form>
    </Card>
  );
};

export default AddEducation;
