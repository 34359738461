import { Flex, Text } from "@chakra-ui/react";

const ReportProgressBar = ({ noOfDays, value, color }: any) => {
  return (
    <>
      <Flex direction="column" gap={2}>
        <Text fontSize="13px" color="#212529">
          {noOfDays} Days
        </Text>
        <Flex
          height="6px"
          bg="#ebedf2"
          borderRadius="0.25rem"
          w="90px"
          overflow="hidden"
        >
          <Flex
            height="6px"
            bg={
              color === "red"
                ? "#fd397a"
                : color === "green"
                ? "#0abb87"
                : color === "yellow"
                ? "#ffb822"
                : ""
            }
            borderRadius="0.25rem"
            w={color === "green" && value === 0 ? "100%" : `${value}%`}
            maxWidth="100%"
          />
        </Flex>
      </Flex>
    </>
  );
};

export default ReportProgressBar;
