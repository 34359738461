import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Center,
  Heading,
  Image,
  Text,
  VStack,
  Spinner,
  useToast,
  Progress,
  Flex,
} from "@chakra-ui/react";
import { upload } from "../../../../assets/images";
import AWS from "aws-sdk";
import { getUserId } from "../../../../utils/helper";
import { useFetch } from "../../../../utils/useFetch";
import { useNavigate } from "react-router-dom";

const ResumeCard = () => {
  const [uploadedFileName, setUploadedFileName] = useState<string>(() => {
    return localStorage.getItem("uploadedFileName") || "";
  });
  const toast = useToast();
  const navigate = useNavigate();
  const fileInputRef = useRef<any>(null);
  const [resumeOrLinkedin, setResumeOrLinkedin] = useState("");
  const [user, setUser] = useState({});
  const [isLoading, setIsloading] = useState(false);

  const userId = getUserId();
  const [continueLoading, setContinueLoading] = useState(false);
  const [fileValidity, setFileValidity] = useState({
    valid: true,
    message: "",
  });

  const handleFileInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file: any = event.target.files?.[0];
    const reader = new FileReader();
    const imageFileType = ["pdf", "doc", "docx"];
    setUploadedFileName(file.name);
    localStorage.setItem("uploadedFileName", file.name);

    if (!file) {
      return;
    }
    const fileExtension = file.name.split(".").pop()?.toLowerCase();

    if (!fileExtension || !imageFileType.includes(fileExtension)) {
      setFileValidity({
        valid: false,
        message: "Invalid file type. Please select a PDF, DOC, or DOCX file.",
      });
      return;
    }

    setFileValidity({
      valid: true,
      message: "",
    });

    const S3_BUCKET = process.env.REACT_APP_S3_BUCKET || "";
    const REGION = process.env.REACT_APP_REGION;

    AWS.config.update({
      accessKeyId: process.env.REACT_APP_accessKeyId,
      secretAccessKey: process.env.REACT_APP_secretAccessKey,
    });
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });
    const folder = "resume";
    const params = {
      Bucket: S3_BUCKET,
      Key: `${folder}/${file.name}`,
      Body: file,
    };

    try {
      setIsloading(true);
      const upload = await s3.putObject(params).promise();
      const url = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${folder}/${file.name}`;
      setResumeOrLinkedin(url as string);
      localStorage.setItem("resumeOrLinkedinUrl", url as string);
      setResumeOrLinkedin(url as string);
      setUploadedFileName(file.name);
      setUser({ ...user, resumeOrLinkedin: url });
      setIsloading(false);
    } catch (error) {
      console.error("Error uploading file", error);
      setIsloading(false);
    }
  };
  const handleContinueClick = async () => {
    setContinueLoading(true);
    if (resumeOrLinkedin === "") {
      setContinueLoading(false);

      toast({
        title: "No file uploaded",
        description: "Please upload a file before continuing.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else {
      const postApiUrl = "/users/talent/resume";
      const postPayload = {
        resume: resumeOrLinkedin,
        userId: getUserId(),
      };

      try {
        await useFetch.post(postApiUrl, postPayload).then((res) => {
          toast({
            title: "File Uploaded Successfully",
            description: "",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        });
      } catch (error) {
        console.error("Error sending data to the server (POST)", error);
      } finally {
        setContinueLoading(false);
      }

      navigate("/setupFlow/personalizeJobSearch");
    }
  };
  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  useEffect(() => {
    const resumeURL = localStorage.getItem("resumeOrLinkedinUrl");
    {
      resumeURL && setResumeOrLinkedin(resumeURL);
    }
  }, []);
  return (
    <>
      <Progress
        colorScheme="progressBar"
        size="xs"
        value={60}
        w="full"
        borderRadius={"lg"}
        mb="6 !important"
        mt="2 !important"
      />
      <Card w="full">
        <CardBody p="8">
          <Heading size="lg" fontWeight={"500"} pb={"2"}>
            Upload Your Resume Or LinkedIn Profile
          </Heading>
          <Text pb={"10px"}>
            Help us find the best job opportunities for you.
          </Text>
          <VStack justify={"flex-end"} w="full" p={"20px"} position="relative">
            {isLoading ? (
              <Spinner size="xl" padding="5" />
            ) : (
              <Flex
                padding="20px 40px"
                border="1px dashed gray"
                borderRadius="16px"
                align="center"
                justify="center"
                cursor="pointer"
                _hover={{ background: "gray.500" }}
                onClick={handleImageClick}
              >
                <input
                  required={true}
                  type="file"
                  style={{ display: "none" }}
                  accept=".pdf, .docx, .doc"
                  id="resumeUpload"
                  ref={fileInputRef}
                  onChange={handleFileInputChange}
                />
                <Image src={upload} height="50px" width="50px" />
              </Flex>
            )}
          </VStack>
          {uploadedFileName && (
            <Text fontSize="14px" color="green" textAlign="center">
              Uploaded File: {uploadedFileName}
            </Text>
          )}
          <Text pb={"20px"} fontWeight={"600"} textAlign={"center"}>
            Upload Your Resume or LinkedIn PDF file (Accepted formats: .pdf,
            .doc, .docx):
          </Text>
          <Text
            fontSize={"13px"}
            color={"grey"}
            fontStyle={"italic"}
            textAlign={"center"}
          >
            Tip: To upload your LinkedIn profile, use the 'Save to PDF' option
            on LinkedIn
          </Text>
          {!fileValidity.valid && (
            <Text textAlign="center" color="red">
              {fileValidity.message}
            </Text>
          )}
          <Center>
            <Button
              colorScheme="buttonPrimary"
              mt="4"
              onClick={handleContinueClick}
              isDisabled={
                !resumeOrLinkedin || !fileValidity.valid || !uploadedFileName
              }
            >
              Continue
            </Button>
          </Center>
        </CardBody>
      </Card>
    </>
  );
};

export default ResumeCard;
