// import React from "react";
// import { Flex, Input, Button } from "@chakra-ui/react";

// const Footer = ({ inputMessage, setInputMessage, handleSendMessage }: any) => {
//   return (
//     <Flex w="100%" mb="5" height={"100%"} maxH="46px">
// <Input
//   placeholder="Type Something..."
//   border="none"
//   borderRadius="none"
//   outline={"none"}
//   _focus={{
//     border: "none",
//     outline: "none important",
//   }}
//   onKeyPress={(e) => {
//     if (e.key === "Enter") {
//       handleSendMessage();
//     }
//   }}
//   value={inputMessage}
//   onChange={(e) => setInputMessage(e.target.value)}
//       />
//       <Button
//         colorScheme={"blue"}
//         mr="2"
//         color="white"
//         borderRadius="md"
//         _hover={{
//           bg: "white",
//           color: "black",
//           border: "1px solid black",
//         }}
// disabled={inputMessage.trim().length <= 0}
// onClick={handleSendMessage}
//       >
//         Send
//       </Button>
//     </Flex>
//   );
// };

// export default Footer;

import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  IconButton,
  Tooltip,
  CloseButton,
  HStack,
  VStack,
  Textarea,
} from "@chakra-ui/react";
// import { AttachmentIcon, CloseIcon, EmojiIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { RiAttachmentFill, RiEmotionLaughLine } from "react-icons/ri";
import useConversation from "./useConversation";

function Footer({ handleSendMessage, inputMessage, setInputMessage }: any) {
  const [file, setFile] = useState<any>(null);

  // const handleInputChange = (event: any) => {
  //   setMessage(event.target.value);
  // };

  const handleFileChange = (event: any) => {
    setFile(event.target.files[0]);
  };

  const handleClearFile = () => {
    setFile(null);
  };

  // const handleEmojiSelect = (emoji: any) => {
  //   setMessage(message + emoji);
  // };

  return (
    <VStack w="full">
      <Flex alignItems="center" padding={2} w="full" justify={"space-between"}>
        <VStack w="full">
          <InputGroup flexGrow={1}>
            <Textarea
              placeholder="Type Here..."
              border="none"
              borderRadius="none"
              resize={"none"}
              outline={"none !important"}
              _focus={{
                border: "none",
                outline: "none !important",
              }}
              sx={{ "::placeholder": { fontSize: "13px" } }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSendMessage();
                  e.preventDefault();
                }
              }}
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
            />
          </InputGroup>
          <HStack w="full" justify={"space-between"}>
            <HStack w="full" justify={"flex-end"}>
            
              <Box marginLeft={2}>
                <Button
                  size="sm"
                  colorScheme="buttonPrimary"
                  disabled={inputMessage.trim().length <= 0}
                  onClick={handleSendMessage}
                >
                  Send
                </Button>
              </Box>
            </HStack>
          </HStack>
        </VStack>
      </Flex>
      {/* {file && (
        <HStack w="full" alignItems={"baseline"}>
          <Tooltip label={file.name}>
            <>
              <Button
                size="sm"
                colorScheme="teal"
                leftIcon={<RiAttachmentFill />}
                marginLeft={4}
                mb="2"
              >
                {file.name.substring(0, 10)}...
              </Button>
              <CloseButton onClick={handleClearFile} />
            </>
          </Tooltip>
        </HStack>
      )} */}
    </VStack>
  );
}

export default Footer;
