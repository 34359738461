import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Progress,
  Select,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useWizard } from "react-use-wizard";
import RadioGroup from "../../common/radioGroup";
import StepsAnimation from "../steps-animation";
import { BiMinus, BiPlus } from "react-icons/bi";
import { useState } from "react";
import { useUserStore } from "../../../store/useUser";

const Step1 = ({
  setValue,
  watch,
  register,
  errors,
  handleSubmit,
  trigger,
  isSubmitted,
  handleAddInput,
  inputs,
  handleRemoveInput,
  handleInputChange,
  jobFunctionListOption,
  user,
  setInputs,
  inputError,
  setInputError,
}: any) => {
  const { nextStep } = useWizard();
  const [isAnyValueEmpty, setIsAnyValueEmpty] = useState(false);
  const [email, setEmail] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [isDuplicateError, setIsDuplicateError] = useState(false);
  const [error, setError] = useState({
    email: "",
    emailType: false,
    interviewformat: "",
    formatType: false,
  });
  const validateEmail = (value: any) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };
  const onNext = (value: any) => {
    setInputError("");
    const hasDuplicateInput = inputs.some(
      (input: { value: any; option: any }, index: any) =>
        inputs.findIndex(
          (otherInput: { value: any; option: any }, otherIndex: any) =>
            input.value === otherInput.value &&
            input.option === otherInput.option &&
            index !== otherIndex
        ) !== -1
    );

    if (hasDuplicateInput) {
      setIsDuplicateError(true);
    } else {
      setIsDuplicateError(false);
    }
    let isAnyValueEmpty;
    if (
      watch("interviewType") &&
      watch("interviewType") !== "Schedule with me" &&
      (isAnyValueEmpty = inputs.some((obj: any) => obj.value === ""))
    ) {
      setError((prevData) => ({
        ...prevData,
        email: "Email Is Required",
        emailType: true,
      }));

      setIsAnyValueEmpty(isAnyValueEmpty);
    }
    if (
      watch("interviewType") &&
      (isAnyValueEmpty = inputs.some((obj: any) => obj.option === ""))
    ) {
      setError((prevData) => ({
        ...prevData,
        interviewformat: "Interview Format is Required",
        formatType: true,
      }));

      setIsAnyValueEmpty(isAnyValueEmpty);
    }

    if (
      watch("duration") &&
      watch("interviewType") &&
      !isAnyValueEmpty &&
      isValid &&
      watch("selecctedJob")
    ) {
      setError({
        email: "",
        emailType: false,
        interviewformat: "",
        formatType: false,
      });
      nextStep();
    }
  };

  return (
    <VStack mt="2">
      <Progress
        colorScheme="progressBar"
        size="sm"
        value={1}
        w="full"
        borderRadius={"lg"}
        my="2"
      />
      <StepsAnimation>
        <VStack
          w="full"
          align={"flex-start"}
          minH="300px"
          maxH={"300px"}
          overflow="auto"
        >
          <VStack w="full" align="flex-start">
            <Text fontWeight={"bold"} fontSize="md" w="full" pl="1">
              Your Selected job
            </Text>
            <Select
              placeholder="Please select job"
              {...register("selecctedJob")}
              maxW={{ base: "100%", md: "70%" }}
              value={watch("selecctedJob")}
              isDisabled={!!watch("selecctedJob")}
            >
              {jobFunctionListOption?.map((item: any) => {
                return <option value={item?.jobId}>{item?.jobName}</option>;
              })}
            </Select>
            {!watch("selecctedJob") && isSubmitted && (
              <Text color="red.500" pl="1">
                {"Field is required"}
              </Text>
            )}
          </VStack>

          <VStack w="full" align={"flex-start"}>
            <FormControl isInvalid={Boolean(!watch("duration"))} isRequired>
              <FormLabel fontWeight={"bold"} fontSize="md" w="full" pl="1">
                Duration
              </FormLabel>
              <RadioGroup
                options={[
                  "15 mins",
                  "30 mins",
                  "45 mins",
                  "60 mins",
                  "60+ mins",
                ]}
                name="management"
                defaultValue={watch("duration") ? watch("duration") : ""}
                onChange={(value: string) => {
                  setValue("duration", value);
                  trigger("duration");
                }}
                maxW="100%"
              />
              {!watch("duration") && isSubmitted && (
                <Text fontSize="sm" mt="2px !important" color="red.500">
                  {"Duration is required"}
                </Text>
              )}
            </FormControl>
          </VStack>
          <VStack w="full" align="flex-start">
            <FormControl
              isInvalid={Boolean(!watch("interviewType"))}
              isRequired
            >
              <FormLabel fontWeight={"bold"} fontSize="md" w="full" pl="1">
                Type
              </FormLabel>
              <RadioGroup
                options={[
                  "Schedule with me",
                  "Schedule with someone else",
                  "Panel Interview",
                ]}
                name="management"
                defaultValue={
                  watch("interviewType") ? watch("interviewType") : ""
                }
                onChange={(value: string) => {
                  setValue("interviewType", value);
                  trigger("interviewType");
                  handleInputChange(0, "value", "");
                  handleInputChange(0, "option", "");
                  setInputs([{ value: "", option: "" }]);
                  setError({
                    email: "",
                    emailType: false,
                    interviewformat: "",
                    formatType: false,
                  });
                }}
                maxW="100%"
              />
              {!watch("interviewType") && isSubmitted && (
                <Text fontSize="sm" mt="2px !important" color="red.500">
                  {"Type of meeting is required"}
                </Text>
              )}
            </FormControl>
          </VStack>
          <FormControl isInvalid={isDuplicateError}>
            {(watch("interviewType") === "Schedule with someone else" ||
              watch("interviewType") === "Panel Interview") && (
              <VStack spacing={4} w="full" justify={"flex-start"}>
                <HStack w="full" justify={"flex-start"} align={"flex-start"}>
                  <VStack
                    gap={2}
                    justify={"flex-start"}
                    align={"flex-start"}
                    w="50%"
                  >
                    <FormControl isInvalid={!isValid}>
                      <Flex align="start" gap="3px" pt="2px">
                        <Text
                          fontWeight={"bold"}
                          fontSize="md"
                          pl="1"
                          mb="15px"
                        >
                          Email address
                        </Text>
                        <Text color="red.500" fontWeight="bold">
                          *
                        </Text>
                      </Flex>
                      <Flex direction="column" w="full" gap={5} pt="15px">
                        {inputs.map((value: any, index: number) => (
                          <Flex key={index}>
                            <Input
                              isInvalid={error?.emailType}
                              h="38px"
                              value={value?.value}
                              onChange={(e) => {
                                setEmail(e.target.value);
                                setIsValid(validateEmail(e.target.value));
                                setIsAnyValueEmpty(false);
                                handleInputChange(
                                  index,
                                  "value",
                                  e.target.value.trim()
                                );
                              }}
                              placeholder="Enter an email"
                            />

                            {watch("interviewType") === "Panel Interview" &&
                              index === inputs.length - 1 && (
                                <>
                                  <IconButton
                                    icon={<BiPlus />}
                                    aria-label="Add Input"
                                    onClick={handleAddInput}
                                    ml={2}
                                    size="sm"
                                    h="auto"
                                  />
                                </>
                              )}

                            {watch("interviewType") === "Panel Interview" &&
                              index !== inputs.length - 1 && (
                                <IconButton
                                  icon={<BiMinus />}
                                  aria-label="Remove Input"
                                  onClick={() => handleRemoveInput(index)}
                                  ml={2}
                                  size="sm"
                                  h="auto"
                                />
                              )}
                          </Flex>
                        ))}
                        <Flex direction="column" w="full">
                          {inputError.length ? (
                            <Text
                              fontSize="sm"
                              mt="2px !important"
                              color="red.500"
                            >
                              {inputError}
                            </Text>
                          ) : (
                            error?.email && (
                              <Text
                                fontSize="sm"
                                mt="2px !important"
                                color="red.500"
                              >
                                {error?.email}
                              </Text>
                            )
                          )}
                          {!isValid && (
                            <Text color="red.500">
                              Please enter a valid email address
                            </Text>
                          )}
                        </Flex>
                      </Flex>
                    </FormControl>
                  </VStack>

                  <VStack
                    gap={2}
                    justify={"flex-start"}
                    align={"flex-start"}
                    w="50%"
                  >
                    <Flex align="start" p="0" marginTop="0 !important">
                      <Text fontWeight={"bold"} fontSize="md" pl="1" mb="14px">
                        Interview Format
                      </Text>
                      <Text color="red.500" fontWeight="bold" ms="3px">
                        *
                      </Text>
                    </Flex>

                    <Flex direction="column" w="full" gap={5}>
                      {inputs.map((value: any, index: number) => (
                        <Select
                          isInvalid={error?.formatType}
                          maxW={{ base: "96%" }}
                          value={value?.option}
                          isDisabled={false}
                          size="md"
                          h="38px"
                          borderRadius={"md"}
                          onChange={(e) => {
                            setIsAnyValueEmpty(false);
                            handleInputChange(index, "option", e.target.value);
                          }}
                          name="format"
                          mr="1"
                          placeholder="Select format option"
                        >
                          <option value="Initial Screening">
                            Initial Screening
                          </option>
                          <option value="Technical Interview">
                            Technical Interview
                          </option>
                          <option value="Final Interview">
                            Final Interview
                          </option>
                        </Select>
                      ))}
                    </Flex>
                    {error?.interviewformat && (
                      <Text fontSize="sm" mt="2px !important" color="red.500">
                        {error?.interviewformat}
                      </Text>
                    )}
                    {isDuplicateError && (
                      <Text color="red.500">
                        Duplicate format for the same Email
                      </Text>
                    )}
                  </VStack>
                </HStack>
              </VStack>
            )}
          </FormControl>
          {watch("interviewType") === "Schedule with me" && (
            <FormControl>
              <VStack spacing={4} w="full" justify={"flex-start"}>
                <HStack w="full" justify={"flex-start"} align={"flex-start"}>
                  <VStack
                    gap={2}
                    justify={"flex-start"}
                    align={"flex-start"}
                    w="50%"
                  >
                    <FormControl isInvalid={!isValid}>
                      <Flex align="start" gap="3px" pt="2px">
                        <Text
                          fontWeight={"bold"}
                          fontSize="md"
                          pl="1"
                          mb="15px"
                        >
                          Email address
                        </Text>
                        <Text color="red.500" fontWeight="bold">
                          *
                        </Text>
                      </Flex>
                      <Flex direction="column" w="full" gap={3} pt="12px">
                        {inputs.map((value: any, index: number) => (
                          <Flex key={index}>
                            <Input
                              h="38px"
                              value={user?.email}
                              isDisabled
                              onChange={(e) => {
                                setEmail(user?.email);
                                setIsValid(validateEmail(user?.email));
                                setIsAnyValueEmpty(false);
                                handleInputChange(index, "value", user?.email);
                              }}
                              placeholder="Enter an email"
                            />

                            {watch("interviewType") === "Panel Interview" &&
                              index === inputs.length - 1 && (
                                <>
                                  <IconButton
                                    icon={<BiPlus />}
                                    aria-label="Add Input"
                                    onClick={handleAddInput}
                                    ml={2}
                                    size="sm"
                                    h="auto"
                                  />
                                </>
                              )}

                            {watch("interviewType") === "Panel Interview" &&
                              index !== inputs.length - 1 && (
                                <IconButton
                                  icon={<BiMinus />}
                                  aria-label="Remove Input"
                                  onClick={() => handleRemoveInput(index)}
                                  ml={2}
                                  size="sm"
                                  h="auto"
                                />
                              )}
                          </Flex>
                        ))}
                      </Flex>
                    </FormControl>
                  </VStack>

                  <VStack
                    gap={2}
                    justify={"flex-start"}
                    align={"flex-start"}
                    w="50%"
                  >
                    <Flex align="start" p="0" marginTop="0 !important">
                      <Text fontWeight={"bold"} fontSize="md" pl="1" mb="14px">
                        Interview Format
                      </Text>
                      <Text color="red.500" fontWeight="bold" ms="3px">
                        *
                      </Text>
                    </Flex>
                    {inputs.map((value: any, index: number) => (
                      <Select
                        isInvalid={error?.formatType}
                        maxW={{ base: "96%" }}
                        value={value?.option}
                        isDisabled={false}
                        size="md"
                        h="38px"
                        borderRadius={"md"}
                        onChange={(e) => {
                          setIsAnyValueEmpty(false);
                          handleInputChange(index, "option", e.target.value);
                          handleInputChange(index, "value", user?.email);
                        }}
                        name="format"
                        mr="1"
                        placeholder="Select format option"
                      >
                        <option value="Initial Screening">
                          Initial Screening
                        </option>
                        <option value="Technical Interview">
                          Technical Interview
                        </option>
                        <option value="Final Interview">Final Interview</option>
                      </Select>
                    ))}
                    {error?.interviewformat && (
                      <Text color="red.500">{error?.interviewformat}</Text>
                    )}
                  </VStack>
                </HStack>
              </VStack>
            </FormControl>
          )}
        </VStack>
      </StepsAnimation>
      <HStack spacing={"24px"} justify={"flex-end"} w="full" mt="2 !important">
        <Button colorScheme="buttonPrimary" onClick={handleSubmit(onNext)}>
          Next
        </Button>
      </HStack>
    </VStack>
  );
};

export default Step1;
