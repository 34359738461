import { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import ReactPaginate from "react-paginate";
import {
  Card,
  CardBody,
  Flex,
  Grid,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Text,
} from "@chakra-ui/react";

import useTalent from "./useTalent";
import LoadingScreen from "../../common/loadingScreen";
import SearchTalentCard from "./searchTalentCard";
import NoDataCard from "../../common/nodata";
import { useFetch } from "../../../utils/useFetch";

const SearchTalent = () => {
  const {
    candidateList,
    candidateLoading,
    selectJobFunc,
    jobFunction,
    itemOffset,
    setItemOffset,
    setQuery,
  } = useTalent();

  const [searchField, setSearchField] = useState("");
  const handlePageClick = (event: any) => {
    const newOffset = event.selected;
    setItemOffset(newOffset);
  };
  const [searchJobFunctionResult, setSearchJobFunctionResult] = useState<any>(
    []
  );

  const handleSearchJobFunctions = (search: any) => {
    selectJobFunc(search);
    if (search.length && itemOffset !== 0) {
      setItemOffset(0);
    }
  };
  const handleChange = (e: any) => {
    e.preventDefault();
    let value = e.target.value;
    setQuery(value.trimLeft());
    setSearchField(value.trimLeft());
    if (value.length && itemOffset !== 0) {
      setItemOffset(0);
    }
  };
  useEffect(() => {
    try {
      useFetch
        .get(
          `/users/domain/all-job-function-list?query=&page=${itemOffset}&limit=500`
        )
        .then((response: any) => {
          setSearchJobFunctionResult(response.data);
        })
        .catch((error: any) => {
          console.log("Error", error);
        });
    } catch (error) {
      console.log("ERROR", error);
    }
  }, []);

  return (
    <>
      <Card bg="#f7f6f8" borderRadius="0px" boxShadow="none">
        <CardBody>
          <Flex
            w={{ base: "full", lg: "100%" }}
            flexDir={{ base: "column", lg: "row" }}
            gap="4"
          >
            <HStack
              w="full"
              spacing={4}
              mr={{ base: "0", sm: "2" }}
              align={"baseline"}
            >
              <Text color="#646C9A" whiteSpace={"nowrap"} fontSize={"13px"}>
                {`${
                  candidateList?.users?.length
                    ? candidateList?.users?.length === 1
                      ? "1 Total"
                      : `${candidateList?.totalSize} Total`
                    : 0
                }`}
              </Text>
              <InputGroup w={{ base: "100%", sm: "60%" }}>
                <InputRightElement
                  pointerEvents="none"
                  children={<BiSearch color="#5867DD" />}
                />
                <Input
                  type="text"
                  placeholder="Search ..."
                  bg="#edeef3"
                  border={"none"}
                  sx={{ "::placeholder": { fontSize: "13px" } }}
                  onChange={(e) => handleChange(e)}
                />
              </InputGroup>
            </HStack>
            <HStack
              w="full"
              spacing={4}
              mt={{ base: "2", sm: "0" }}
              align={"baseline"}
            >
              <Text color="#646C9A" whiteSpace={"nowrap"} fontSize={"13px"}>
                Job Functions
              </Text>
              <Select
                placeholder="All"
                value={jobFunction}
                onChange={(e) => handleSearchJobFunctions(e.target.value)}
              >
                {searchJobFunctionResult?.map((item: any) => (
                  <option value={item?.name}>{item?.name}</option>
                ))}
              </Select>
            </HStack>
          </Flex>
        </CardBody>
      </Card>
      <Flex w="full" m="auto" p="4" py="8" bg="#f0f1f7">
        {candidateLoading && !candidateList?.users?.length ? (
          <LoadingScreen />
        ) : candidateList?.users?.length ? (
          <>
            <Flex
              flexDirection={"column"}
              justify="space-between"
              minH="70vh"
              w="full"
            >
              <Grid
                w="100% !important"
                templateColumns={[
                  "1fr",
                  "1fr",
                  "1fr",
                  "repeat(2, 1fr)",
                  "repeat(2, 1fr)",
                  "repeat(3, 1fr)",
                ]}
                //   p="2"
                gap={4}
                overflowX="auto"
                // width={["100%", "100%", "100%", "100%", "100%", "auto"]}
                sx={{
                  "&::-webkit-scrollbar": {
                    height: "4px",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "transparent",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "gray.400",
                    borderRadius: "full",
                  },
                }}
              >
                {" "}
                {candidateList?.users?.map((data: any) => (
                  <SearchTalentCard key={data?.userId} data={data} />
                ))}
              </Grid>
              <ReactPaginate
                nextLabel="Next >"
                onPageChange={handlePageClick}
                forcePage={itemOffset}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={candidateList?.totalPages}
                previousLabel="< Previous"
                breakLabel="..."
                previousClassName={`page-item${
                  itemOffset === 0 ? " disabled" : ""
                }`}
                nextClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName={"container"}
                previousLinkClassName={"page"}
                breakClassName={"page"}
                nextLinkClassName={"page"}
                pageClassName={"page"}
                disabledClassName={"disabled"}
                activeClassName={"active-venu-button"}
              />
            </Flex>
          </>
        ) : searchField.length >= 1 || jobFunction.length >= 1 ? (
          <Card p={4} boxShadow="md" borderRadius="md" py="10" w="full">
            <Flex w="full" align="center" justify="center">
              <Text fontSize="20px" fontWeight="500">
                No Matching Record Found
              </Text>
            </Flex>
          </Card>
        ) : (
          <NoDataCard message="Stay tuned for updates on candidate selection." />
        )}
      </Flex>
    </>
  );
};

export default SearchTalent;
