import moment from "moment";

export const handleLogoutLocalStorage = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("data");
  localStorage.removeItem("profilepercentage");
  localStorage.clear();
};
export const handleLoginLocalStorage = (token: string, data: string) => {
  localStorage.setItem("data", JSON.stringify(data));
  localStorage.setItem("access_token", JSON.stringify(token));
};
export const handleLoginLocalStorageData = (data: any) => {
  try {
    const dataString = JSON.stringify(data);
    localStorage.setItem("data", dataString);
  } catch (error) {
    console.error("Error storing data in localStorage:", error);
  }
};
export const getAccessToken = () => {
  return localStorage.getItem("access_token");
};
export const getUserId = () => {
  const localData: any = localStorage.getItem("data") ?? null;
  const data: any = localData !== null ? JSON.parse(localData) : null;
  return data?.userId;
};

export const getBypassedUserId = () => {
  const localData: any = localStorage.getItem("bypassed_user") ?? null;
  const data: any = JSON.parse(localData);
  return data?.id;
};
export const getUserName = () => {
  const localData: any = localStorage.getItem("data") ?? null;
  const data: any = JSON.parse(localData);
  return data?.fullName;
};
export const getUserProfilePicture = () => {
  const localData: any = localStorage.getItem("data") ?? null;
  const data: any = JSON.parse(localData);
  return data?.profilePicture;
};
export const getUserRole = () => {
  const localData: any = localStorage.getItem("data") ?? null;
  const data: any = JSON.parse(localData);
  if (data?.userType === "EMPLOYER") {
    return 1;
  } else if (data?.userType === "TALENT") {
    return 2;
  } else {
    return null;
  }
};
export const getisProfileCompleted = () => {
  const localData: any = localStorage.getItem("data") ?? null;

  const data: any = JSON.parse(localData);
  return data?.isProfileComplete;
};
export const getisProfile30percent = () => {
  const localData: any = localStorage.getItem("profilepercentage") ?? null;

  const data: any = JSON.parse(localData);
  return data;
};
export const getTimeAgo = (timestamp: any) => {
  const currentTime: any = new Date();
  const messageTime: any = new Date(timestamp);

  const timeDiff = currentTime - messageTime;
  const seconds = Math.floor(timeDiff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  if (seconds < 60) {
    return "Just now";
  } else if (minutes < 60) {
    return `${minutes} minute${minutes === 1 ? "" : "s"} ago`;
  } else if (hours < 24) {
    return `${hours} hour${hours === 1 ? "" : "s"} ago`;
  } else {
    return moment(messageTime).format("MM/DD/YYYY, hh:mm a"); // Display full timestamp if more than 24 hours
  }
};

export const getMonthsAndYears = (monthsWorked: number) => {
  if (monthsWorked === 0) {
    return "few days";
  }

  const years = Math.floor(monthsWorked / 12);
  const months = monthsWorked % 12;

  let result = "";

  if (years > 0) {
    result += years === 1 ? "1 yr" : `${years} yrs`;
    if (months > 0) {
      result += months === 1 ? " 1 mo" : ` ${months} mos`;
    }
  } else {
    result = months === 1 ? "1 mo" : `${months} mos`;
  }

  return result;
};
export const languageArray = [
  "Afrikaans",
  "Albanian - shqip",
  "Amharic - አማርኛ",
  "Arabic - العربية",
  "Aragonese - aragonés",
  "Armenian - հայերեն",
  "Asturian - asturianu",
  "Azerbaijani - azərbaycan dili",
  "Basque - euskara",
  "Belarusian - беларуская",
  "Bengali - বাংলা",
  "Bosnian - bosanski",
  "Breton - brezhoneg",
  "Bulgarian - български",
  "Catalan - català",
  "Central Kurdish - کوردی (دەستنوسی عەرەبی)",
  "Chinese - 中文",
  "Chinese (Hong Kong) - 中文（香港）",
  "Chinese (Simplified) - 中文（简体）",
  "Chinese (Traditional) - 中文（繁體）",
  "Corsican",
  "Croatian - hrvatski",
  "Czech - čeština",
  "Danish - dansk",
  "Dutch - Nederlands",
  "English",
  "English (Australia)",
  "English (Canada)",
  "English (India)",
  "English (New Zealand)",
  "English (South Africa)",
  "English (United Kingdom)",
  "English (United States)",
  "Esperanto - esperanto",
  "Estonian - eesti",
  "Faroese - føroyskt",
  "Filipino",
  "Finnish - suomi",
  "French - français",
  "French (Canada) - français (Canada)",
  "French (France) - français (France)",
  "French (Switzerland) - français (Suisse)",
  "Galician - galego",
  "Georgian - ქართული",
  "German - Deutsch",
  "German (Austria) - Deutsch (Österreich)",
  "German (Germany) - Deutsch (Deutschland)",
  "German (Liechtenstein) - Deutsch (Liechtenstein)",
  "German (Switzerland) - Deutsch (Schweiz)",
  "Greek - Ελληνικά",
  "Guarani",
  "Gujarati - ગુજરાતી",
  "Hausa",
  "Hawaiian - ʻŌlelo Hawaiʻi",
  "Hebrew - עברית",
  "Hindi - हिन्दी",
  "Hungarian - magyar",
  "Icelandic - íslenska",
  "Indonesian - Indonesia",
  "Interlingua",
  "Irish - Gaeilge",
  "Italian - italiano",
  "Italian (Italy) - italiano (Italia)",
  "Italian (Switzerland) - italiano (Svizzera)",
  "Japanese - 日本語",
  "Kannada - ಕನ್ನಡ",
  "Kazakh - қазақ тілі",
  "Khmer - ខ្មែរ",
  "Korean - 한국어",
  "Kurdish - Kurdî",
  "Kyrgyz - кыргызча",
  "Lao - ລາວ",
  "Latin",
  "Latvian - latviešu",
  "Lingala - lingála",
  "Lithuanian - lietuvių",
  "Macedonian - македонски",
  "Malay - Bahasa Melayu",
  "Malayalam - മലയാളം",
  "Maltese - Malti",
  "Marathi - मराठी",
  "Mongolian - монгол",
  "Nepali - नेपाली",
  "Norwegian - norsk",
  "Norwegian Bokmål - norsk bokmål",
  "Norwegian Nynorsk - nynorsk",
  "Occitan",
  "Oriya - ଓଡ଼ିଆ",
  "Oromo - Oromoo",
  "Pashto - پښتو",
  "Persian - فارسی",
  "Polish - polski",
  "Portuguese - português",
  "Portuguese (Brazil) - português (Brasil)",
  "Portuguese (Portugal) - português (Portugal)",
  "Punjabi - ਪੰਜਾਬੀ",
  "Quechua",
  "Romanian - română",
  "Romanian (Moldova) - română (Moldova)",
  "Romansh - rumantsch",
  "Russian - русский",
  "Scottish Gaelic",
  "Serbian - српски",
  "Serbo - Croatian",
  "Shona - chiShona",
  "Sindhi",
  "Sinhala - සිංහල",
  "Slovak - slovenčina",
  "Slovenian - slovenščina",
  "Somali - Soomaali",
  "Southern Sotho",
  "Spanish - español",
  "Spanish (Argentina) - español (Argentina)",
  "Spanish (Latin America) - español (Latinoamérica)",
  "Spanish (Mexico) - español (México)",
  "Spanish (Spain) - español (España)",
  "Spanish (United States) - español (Estados Unidos)",
  "Sundanese",
  "Swahili - Kiswahili",
  "Swedish - svenska",
  "Tajik - тоҷикӣ",
  "Tamil - தமிழ்",
  "Tatar",
  "Telugu - తెలుగు",
  "Thai - ไทย",
  "Tigrinya - ትግርኛ",
  "Tongan - lea fakatonga",
  "Turkish - Türkçe",
  "Turkmen",
  "Twi",
  "Ukrainian - українська",
  "Urdu - اردو",
  "Uyghur",
  "Uzbek - o‘zbek",
  "Vietnamese - Tiếng Việt",
  "Walloon - wa",
  "Welsh - Cymraeg",
  "Western Frisian",
  "Xhosa",
  "Yiddish",
  "Yoruba - Èdè Yorùbá",
  "Zulu - isiZulu",
];
export const prefilledLocations = [
  {
    label: "NYC, NY, USA",
    value: {
      description: "NYC, NY, USA",
      matched_substrings: [
        {
          length: 3,
          offset: 0,
        },
      ],
      place_id: "ChIJOwg_06VPwokRYv534QaPC8g",
      reference: "ChIJOwg_06VPwokRYv534QaPC8g",
      structured_formatting: {
        main_text: "NYC",
        main_text_matched_substrings: [
          {
            length: 3,
            offset: 0,
          },
        ],
        secondary_text: "NY, USA",
      },
      terms: [
        {
          offset: 0,
          value: "NYC",
        },
        {
          offset: 5,
          value: "NY",
        },
        {
          offset: 9,
          value: "USA",
        },
      ],
      types: ["locality", "political", "geocode"],
    },
  },
  {
    label: "Boston, MA, USA",
    value: {
      description: "Boston, MA, USA",
      matched_substrings: [
        {
          length: 6,
          offset: 0,
        },
      ],
      place_id: "ChIJGzE9DS1l44kRoOhiASS_fHg",
      reference: "ChIJGzE9DS1l44kRoOhiASS_fHg",
      structured_formatting: {
        main_text: "Boston",
        main_text_matched_substrings: [
          {
            length: 6,
            offset: 0,
          },
        ],
        secondary_text: "MA, USA",
      },
      terms: [
        {
          offset: 0,
          value: "Boston",
        },
        {
          offset: 8,
          value: "MA",
        },
        {
          offset: 12,
          value: "USA",
        },
      ],
      types: ["locality", "political", "geocode"],
    },
  },
  {
    label: "Austin, TX, USA",
    value: {
      description: "Austin, TX, USA",
      matched_substrings: [
        {
          length: 6,
          offset: 0,
        },
      ],
      place_id: "ChIJLwPMoJm1RIYRetVp1EtGm10",
      reference: "ChIJLwPMoJm1RIYRetVp1EtGm10",
      structured_formatting: {
        main_text: "Austin",
        main_text_matched_substrings: [
          {
            length: 6,
            offset: 0,
          },
        ],
        secondary_text: "TX, USA",
      },
      terms: [
        {
          offset: 0,
          value: "Austin",
        },
        {
          offset: 8,
          value: "TX",
        },
        {
          offset: 12,
          value: "USA",
        },
      ],
      types: ["locality", "political", "geocode"],
    },
  },
  {
    label: "Washington D.C., DC, USA",
    value: {
      description: "Washington D.C., DC, USA",
      matched_substrings: [
        {
          length: 10,
          offset: 0,
        },
      ],
      place_id: "ChIJW-T2Wt7Gt4kRKl2I1CJFUsI",
      reference: "ChIJW-T2Wt7Gt4kRKl2I1CJFUsI",
      structured_formatting: {
        main_text: "Washington D.C.",
        main_text_matched_substrings: [
          {
            length: 10,
            offset: 0,
          },
        ],
        secondary_text: "DC, USA",
      },
      terms: [
        {
          offset: 0,
          value: "Washington D.C.",
        },
        {
          offset: 17,
          value: "DC",
        },
        {
          offset: 21,
          value: "USA",
        },
      ],
      types: ["locality", "political", "geocode"],
    },
  },
  // {
  //   label: "Louisiana, USA",
  //   value: {
  //     description: "Louisiana, USA",
  //     matched_substrings: [
  //       {
  //         length: 9,
  //         offset: 0,
  //       },
  //     ],
  //     place_id: "ChIJZYIRslSkIIYRA0flgTL3Vck",
  //     reference: "ChIJZYIRslSkIIYRA0flgTL3Vck",
  //     structured_formatting: {
  //       main_text: "Louisiana",
  //       main_text_matched_substrings: [
  //         {
  //           length: 9,
  //           offset: 0,
  //         },
  //       ],
  //       secondary_text: "USA",
  //     },
  //     terms: [
  //       {
  //         offset: 0,
  //         value: "Louisiana",
  //       },
  //       {
  //         offset: 11,
  //         value: "USA",
  //       },
  //     ],
  //     types: ["administrative_area_level_1", "political", "geocode"],
  //   },
  // },
  {
    label: "Chicago, IL, USA",
    value: {
      description: "Chicago, IL, USA",
      matched_substrings: [
        {
          length: 6,
          offset: 0,
        },
      ],
      place_id: "ChIJ7cv00DwsDogRAMDACa2m4K8",
      reference: "ChIJ7cv00DwsDogRAMDACa2m4K8",
      structured_formatting: {
        main_text: "Chicago",
        main_text_matched_substrings: [
          {
            length: 6,
            offset: 0,
          },
        ],
        secondary_text: "IL, USA",
      },
      terms: [
        {
          offset: 0,
          value: "Chicago",
        },
        {
          offset: 9,
          value: "IL",
        },
        {
          offset: 13,
          value: "USA",
        },
      ],
      types: ["locality", "political", "geocode"],
    },
  },
];
export const addCommas = (num: any) =>
  num?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const removeNonNumeric = (num: any) =>
  num?.toString()?.replace(/[^0-9]/g, "");
export const extractNumberFromString = (
  stringNumber: any,
  getAs = "string"
) => {
  const numb = stringNumber?.match(/[+-]?\d+(\.\d+)?/g);
  if (getAs === "string") return numb ? numb.join("") : NaN;
  else return numb ? Number(numb.join("")) : NaN;
};
// export const scrollToTop = () => {
//   window.scrollTo({
//     top: 0,
//     behavior: "smooth",
//   });
// };
// export const scrollableRef: any = useRef(null);

// export const scrollToTop = () => {
//   if (scrollableRef.current) {
//     scrollableRef.current.scrollTop = 0;
//   }
// };
export const stageOptions = [
  {
    id: 1,
    color: "#0abb87",
    bg: "rgba(10, 187, 135, 0.1)",
    name: "Under Review",
    value: "UNDER_REVIEW",
  },
  {
    id: 2,
    color: "#0abb87",
    bg: "rgba(10, 187, 135, 0.1)",
    name: "HR Screen",
    value: "HR_SCREEN",
  },
  {
    id: 3,
    color: "#fd397a",
    bg: "rgba(253, 57, 122, 0.1)",
    name: "Interview Pending Scheduling",
    value: "INTERVIEW_PENDING_SCHEDULING",
  },
  {
    id: 4,
    color: "#fd397a",
    bg: "rgba(253, 57, 122, 0.1)",
    name: "Interview Scheduled",
    value: "INTERVIEW_SCHEDULED",
  },
  {
    id: 5,
    color: "#fd397a",
    bg: "rgba(253, 57, 122, 0.1)",
    name: "Interview Pending Feedback",
    value: "INTERVIEW_PENDING_FEEDBACK",
  },
  {
    id: 6,
    color: "#5d78ff",
    bg: "rgba(93, 120, 255, 0.1)",
    name: "Onsite Pending Scheduling",
    value: "ONSITE_PENDING_SCHEDULING",
  },
  {
    id: 7,
    color: "#5d78ff",
    bg: "rgba(93, 120, 255, 0.1)",
    name: "Onsite Scheduled",
    value: "ONSITE_SCHEDULED",
  },
  {
    id: 8,
    color: "#5d78ff",
    bg: "rgba(93, 120, 255, 0.1)",
    name: "Onsite Pending Feedback",
    value: "ONSITE_PENDING_FEEDBACK",
  },
  {
    id: 9,
    color: "#ffb822",
    bg: "rgba(255, 184, 34, 0.1)",
    name: "Offer Pending",
    value: "OFFER_PENDING",
  },
  {
    id: 10,
    color: "#ffb822",
    bg: "rgba(255, 184, 34, 0.1)",
    name: "Offer Extended",
    value: "OFFER_EXTENDED",
  },
  {
    id: 11,
    color: "#ffb822",
    bg: "rgba(255, 184, 34, 0.1)",
    name: "Offer Accepted",
    value: "OFFER_ACCEPTED",
  },
  {
    id: 12,
    color: "#ffb822",
    bg: "rgba(255, 184, 34, 0.1)",
    name: "Hired Started",
    value: "HIRED_STARTED",
  },
  {
    id: 13,
    color: "#ffb822",
    bg: "rgba(255, 184, 34, 0.1)",
    name: "Archived",
    value: "ARCHIVED",
  },
  // {
  //   id: 14,
  //   color: "#fd397a",
  //   bg: "rgba(253, 57, 122, 0.1)",
  //   name: "Rejected",
  //   value: "REJECTED",
  // },
];
export const trapSpacesForRequiredFields = (value: string) => !!value.trim();
