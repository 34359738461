import {
  Box,
  Card,
  Button,
  CardBody,
  CardHeader,
  Flex,
  HStack,
  VStack,
  Heading,
  Icon,
  IconButton,
  Menu,
  Badge,
  MenuButton,
  Avatar,
  MenuItem,
  MenuList,
  Text,
  CardFooter,
  GridItem,
  Divider,
} from "@chakra-ui/react";

import { useEffect, useState } from "react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { GrAmazon } from "react-icons/gr";
import { BsSun } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useUserStore } from "../../../store/useUser";

const InterviewCard = ({ data }: any) => {
  const { user } = useUserStore();

  const [sortedTimes1, setSortedTimes1] = useState<string[]>([]);
  const [sortedTimes2, setSortedTimes2] = useState<string[]>([]);

  const sortTimes = () => {
    const sortFunction = (a: string, b: string) => {
      const currentDate = new Date(); // Use the current date
      const timeA = new Date(
        `${
          currentDate.getMonth() + 1
        }/${currentDate.getDate()}/${currentDate.getFullYear()} ${a}`
      );
      const timeB = new Date(
        `${
          currentDate.getMonth() + 1
        }/${currentDate.getDate()}/${currentDate.getFullYear()} ${b}`
      );
      return timeA.getTime() - timeB.getTime();
    };

    const sortedSelectedTimes1 = [...data?.selectedTimes].sort(sortFunction);
    const sortedSelectedTimes2 = [...data?.selectedTimes2].sort(sortFunction);

    setSortedTimes1([...sortedSelectedTimes1]);
    setSortedTimes2([...sortedSelectedTimes2]);
  };

  useEffect(() => {
    sortTimes();
  }, [data?.selectedTimes, data?.selectedTimes2]);

  const navigate = useNavigate();

  return (
    <Card borderRadius={"md"} width={"100%"} maxW="100%" px="4">
      <CardHeader p="2" px="4" pt="4" mx="2">
        {data?.isRescheduleRequested && (
          <Flex w="full" justify="end">
            <Badge
              borderRadius={"md"}
              ml="1"
              colorScheme="buttonPrimary"
              p="1"
              color={"#5867DD"}
              px="2"
              bg={"#5d78ff1a"}
              textTransform={"capitalize"}
              // pos="absolute"
              // top="15px"
              // right={"15px"}
            >
              Reschedule Request
            </Badge>
          </Flex>
        )}
        <Flex mt="5px">
          <Flex flex="1" gap="4" alignItems="center" wrap="wrap">
            <Flex gap="4">
              <Flex direction="column">
                <Heading
                  size="lg"
                  color="#48465b"
                  fontWeight={"500"}
                  fontSize={{
                    base: "16px",
                    md: "16px",
                    lg: "17px",
                    xl: "17px",
                  }}
                  whiteSpace={"nowrap"}
                  mb="6px !important"
                  _hover={{ color: "#5867DD", cursor: "pointer" }}
                >
                  {`${
                    data?.date && moment(data?.date).format("MMMM DD, YYYY")
                  }`}
                </Heading>
                <HStack justify="space-evenly">
                  <Flex w="full" justify="start">
                    <Badge
                      // ml="1"

                      mr="40px"
                      fontSize="12px"
                      colorScheme="buttonPrimary"
                      p="1"
                      color={"#5867DD"}
                      px="2"
                      bg={"#5d78ff1a"}
                      textTransform={"capitalize"}
                    >
                      {data?.date && moment(data?.date).format("dddd")}
                    </Badge>
                  </Flex>
                </HStack>
              </Flex>

              {data?.date2 && (
                <Heading
                  size="lg"
                  color="#48465b"
                  fontWeight={"500"}
                  fontSize={{
                    base: "16px",
                    md: "16px",
                    lg: "17px",
                    xl: "17px",
                  }}
                  whiteSpace={"nowrap"}
                  mb="6px !important"
                >
                  |
                </Heading>
              )}
            </Flex>
            {data?.date2 && (
              <Flex direction="column">
                <Heading
                  size="lg"
                  color="#48465b"
                  fontWeight={"500"}
                  fontSize={{
                    base: "16.8px",
                    md: "16.8px",
                    lg: "18.2px",
                    xl: "18.2px",
                  }}
                  whiteSpace={"nowrap"}
                  mb="6px !important"
                  _hover={{ color: "#5867DD", cursor: "pointer" }}
                >
                  {moment(data?.date2).format("MMMM DD, YYYY")}
                </Heading>
                <HStack justify="space-evenly">
                  <Flex w="full" justify="start">
                    <Badge
                      // ml="1"

                      mr="40px"
                      fontSize="12px"
                      colorScheme="buttonPrimary"
                      p="1"
                      color={"#5867DD"}
                      px="2"
                      bg={"#5d78ff1a"}
                      textTransform={"capitalize"}
                    >
                      {moment(data?.date2).format("dddd")}
                    </Badge>
                  </Flex>
                </HStack>
              </Flex>
            )}
          </Flex>
        </Flex>
      </CardHeader>
      <CardBody p="2" px="4" mx="2">
        <HStack align="" mt="3" gap={2}>
          <Icon as={BsSun} boxSize={8} />
          <VStack align="start" justify="start">
            <HStack align="start">
              <VStack align="start" mr="15px">
                {sortedTimes1.map((time: string, index: number) => (
                  <Text fontSize={"sm"} mt="0px !important" key={index}>
                    {time}
                    {index !== sortedTimes1.length - 1 ? "," : ""}
                  </Text>
                ))}
              </VStack>
              {data?.selectedTimes2?.length && (
                <Box h="100%" borderLeft="1px solid #48465b" />
              )}
              <VStack
                align="start"
                justify="start"
                ml="15px"
                marginStart={"15px !important"}
              >
                {sortedTimes2.map((time: string, index: number) => (
                  <Text fontSize={"sm"} mt="0px !important" key={index}>
                    {time}
                    {index !== sortedTimes2.length - 1 ? "," : ""}
                  </Text>
                ))}
              </VStack>
            </HStack>

            <Text fontSize="xs" color="#646c9a">
              {`${data?.duration} meeting`}
            </Text>
          </VStack>
        </HStack>
        <HStack mt="2" gap={2}>
          <Avatar name="" size="sm" src={data?.candidateImage} />
          <Text fontSize={"sm"} fontWeight={"bold"} color={"#646c9a"}>
            {data?.candidateName}
          </Text>
        </HStack>
        <HStack alignItems="center" mt="3" gap={2}>
          <Avatar name="" src={data?.companyLogo} size="sm" />
          <VStack alignItems="start">
            <Text fontSize={"sm"} fontWeight={"bold"} color={"#646c9a"}>
              {data?.jobName}
            </Text>
            <Text fontSize={"xs"} color={"#646c9a"} mt="0 !important">
              {data?.jobLocation.length ? `${data?.jobLocation[0]}` : "Remote"}
              {data?.reqNumber && ` | Req#  ${data?.reqNumber}`}
            </Text>
            <Text fontSize={"xs"} color={"#646c9a"} mt="0 !important">
              {data?.companyName}
            </Text>
          </VStack>
        </HStack>
      </CardBody>

      <CardFooter justify="space-between" flexWrap="wrap">
        <Button
          flex="1"
          variant="outline"
          w="full"
          color={"#5867DD"}
          borderColor={"#5867DD"}
          fontSize={"13px"}
          py="6"
          onClick={() =>
            navigate(`/interview/details`, { state: data.interviewScheduleId })
          }
          _hover={{ bg: "#5867DD", color: "white" }}
        >
          View Details
        </Button>
      </CardFooter>
    </Card>
  );
};

export default InterviewCard;
