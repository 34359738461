import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { BiSearch } from "react-icons/bi";
import ChatList from "./chatList";
import useInbox from "./useInbox";
import LoadingScreen from "../../../common/loadingScreen";
import { IoMdArrowDropdown } from "react-icons/io";
import { useUserStore } from "../../../../store/useUser";

const Inbox = ({
  handleSelectedUser,
  selectedUser,
  handleSelectedUserSearched,
}: any) => {
  const { user } = useUserStore();

  const [filter, setFilter] = useState<String>("All");
  const [showFilterResult, setShowFilterResult] = useState(false);
  const { chatList, chatListLoading } = useInbox(
    handleSelectedUser,
    selectedUser
  );
  const [showChat, setShowChat] = useState([]);
  useEffect(() => {
    setShowChat(chatList);
  }, [chatList]);
  const filteredChats =
    filter === "Read"
      ? showChat.filter(
          (item: any) =>
            item?.lastMsgTo !== user?.userId ||
            (item?.lastMsgTo === user?.userId && !item.unreadCount)
        )
      : filter === "Unread"
      ? showChat.filter(
          (item: any) => item?.lastMsgTo === user?.userId && item.unreadCount
        )
      : showChat;

  const [results, setResults] = useState([]);
  const handleQueryChange = async (event: any) => {
    const { value } = event.target;
    const loweredValue = value.toLowerCase();

    let filteredData = chatList?.filter((data: any) =>
      data?.sendBy?.id === user?.userId
        ? data?.sendTo?.name.toLowerCase().includes(loweredValue)
        : data?.sendBy?.name.toLowerCase().includes(loweredValue)
    );
    setShowChat(filteredData);
  };

  const inboxResultRef = useRef<HTMLDivElement>(null);
  function handleClickOutside(event: MouseEvent) {
    if (
      inboxResultRef.current &&
      !inboxResultRef.current.contains(event.target as Node)
    ) {
      setShowFilterResult(false);
    }
  }
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <VStack
      w={["100%", "100%", "100%", "40%"]}
      bg="white"
      h="100%"
      borderRadius={"md"}
      boxShadow="lg"
      p="6"
    >
      {chatListLoading ? (
        <LoadingScreen />
      ) : chatList?.length ? (
        <>
          <Flex
            w="full"
            gap="2"
            align="center"
            // flexWrap="wrap"
            px="2"
            justify="space-between"
          >
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<IoMdArrowDropdown />}
                minW="110px"
                justifyItems="flex-start"
              >
                {filter}
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => setFilter("All")}>All</MenuItem>
                <MenuItem onClick={(e) => setFilter("Read")}>Read</MenuItem>
                <MenuItem onClick={() => setFilter("Unread")}>Unread</MenuItem>
              </MenuList>
            </Menu>

            <Flex direction="column" position="relative" w="70%" gap={2}>
              <InputGroup>
                <InputLeftElement
                  mt="0.5"
                  pointerEvents="none"
                  children={<BiSearch color="#5867DD" />}
                />
                <Input
                  type="text"
                  placeholder="Search "
                  bg="#edeef3"
                  border={"none"}
                  sx={{ "::placeholder": { fontSize: "14px" } }}
                  onChange={handleQueryChange}
                />
              </InputGroup>
              {showFilterResult && results?.length > 0 && (
                <Box
                  ref={inboxResultRef}
                  border="1px"
                  borderColor="gray.200"
                  boxShadow="md"
                  borderRadius="md"
                  bg="white"
                  width="full"
                  position="absolute"
                  zIndex="99"
                  top="41px"
                  height="250px"
                  overflow="auto"
                >
                  {results.map((result: any) => (
                    <Text
                      key={result.id}
                      p={2}
                      _hover={{ bg: "gray.100" }}
                      onClick={() => {
                        handleSelectedUserSearched(result);
                        setResults([]);
                      }}
                    >
                      {result.fullName}
                    </Text>
                  ))}
                </Box>
              )}
            </Flex>
          </Flex>

          {/* <Divider /> */}
          <Box w="full" overflow="auto">
            {filteredChats.map((item: any) => (
              <ChatList
                key={item?.chatId}
                item={item}
                handleSelectedUser={handleSelectedUser}
              />
            ))}
          </Box>
        </>
      ) : (
        <VStack w="full" h="full">
          <InputGroup>
            <InputLeftElement
              mt="0.5"
              pointerEvents="none"
              children={<BiSearch color="#5867DD" />}
            />
            <Input
              type="text"
              placeholder="Search "
              bg="#edeef3"
              border={"none"}
              sx={{ "::placeholder": { fontSize: "14px" } }}
              onChange={handleQueryChange}
            />
          </InputGroup>
          {results?.length > 0 && (
            <Box
              border="1px"
              borderColor="gray.200"
              boxShadow="md"
              borderRadius="md"
              bg="white"
              width={"full"}
            >
              {results?.map((result: any) => (
                <Text
                  key={result.id}
                  p={2}
                  _hover={{ bg: "gray.100" }}
                  onClick={() => {
                    handleSelectedUserSearched(result);
                    setResults([]);
                  }}
                >
                  {result.fullName}
                </Text>
              ))}
            </Box>
          )}
          <Center h="full">
            <Text color="#646c9a"> No Conversations</Text>
          </Center>
        </VStack>
      )}
    </VStack>
  );
};

export default Inbox;
