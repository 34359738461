import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useFetchUserJobs } from "../../service/helper.service";
import { getUserId } from "../../utils/helper";
import { useAddSchedule } from "../../service/schedule.service";
import { useUserStore } from "../../store/useUser";

const useSchedule = (
  onClose: any,
  jobId: any,
  clientId: any,
  refetchCandidate: any
) => {
  const { user } = useUserStore();
  const [error, setError] = useState({
    day1: "",
    day2: "",
    time1: "",
    time2: "",
  });
  const userId = getUserId();
  const { data: jobFunctionListOption, isSuccess } = useFetchUserJobs(userId);
  const { mutate: scheduleMutation, isLoading: scheduleLoading } =
    useAddSchedule();

  useEffect(() => {
    if (isSuccess && jobId) {
      setValue("selecctedJob", jobId);
    }
  }, [isSuccess]);

  const [selectedTimes, setSelectedtimes] = useState<any>([]);
  const [selectedTimes2, setSelectedtimes2] = useState<any>([]);
  const [isSubmitTimes, setIsSubmitTimes] = useState(false);
  const [anotherDay, setAnotherDay] = useState(false);
  const [isSubmitTimes2, setIsSubmitTimes2] = useState(false);
  const [inputs, setInputs] = useState([{ value: "", option: "" }]);
  const [emailFormats, setEmailFormats] = useState([{ email: "", format: "" }]);

  const [inputError, setInputError] = useState("");
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    reset,
    control,
    formState: { errors, isSubmitted, isDirty },
  } = useForm({
    defaultValues: {
      interviewType: "",
      duration: "",
      maxInvites: 2,
      selecctedJob: "",
      locations: "",
      link: "",
      eventName: "",
      phone: "",
      address: "",
      date: new Date(),
      date2: null,
    },
  });

  const handleInputChange = (index: number, key: string, value: string) => {
    const newInputs: any = [...inputs];
    newInputs[index][key] = value;
    setInputs(newInputs);
  };

  const handleAddInput = () => {
    if (inputs?.length >= 3) {
      setInputError("Maximum 3 members are allowed for Panel Interview");
      // toast({
      //   title: "You can select up to 3 options",
      //   status: "info",
      //   variant: "top-accent",
      //   isClosable: true,
      //   position: "top-right",
      //   duration: 3000,
      // });
    } else {
      setInputs([...inputs, { value: "", option: "" }]);
    }
  };

  const handleRemoveInput = (index: number) => {
    const newInputs = [...inputs];
    newInputs.splice(index, 1);
    setInputs(newInputs);
  };

  const toggleAnotherDay = () => {
    setAnotherDay(!anotherDay);
    setValue("date2", null);
    setSelectedtimes2([]);
    setError((prevData) => ({
      ...prevData,
      time2: "",
      day2: "",
    }));
  };

  const onModalClose = () => {
    // reset();
    onClose();
    setIsSubmitTimes(false);
    setIsSubmitTimes2(false);
    setSelectedtimes([]);
    setSelectedtimes2([]);
    setInputs([{ value: "", option: "" }]);
  };
  const onChangeDate = (date: any) => {
    setValue("date", date);
    trigger("date");
  };

  const handleOptionTimes = (option: any) => {
    setError((prevData) => ({
      ...prevData,
      time1: "",
    }));

    if (selectedTimes.includes(option)) {
      setSelectedtimes((prevSelectedOptions: any) =>
        prevSelectedOptions.filter((o: any) => o !== option)
      );
    } else {
      if (selectedTimes.length >= 3) {
        setError((prevData) => ({
          ...prevData,
          time1: "You can only select up to 3 time slots",
        }));
      } else {
        setSelectedtimes((prevSelectedOptions: any) => [
          ...prevSelectedOptions,
          option,
        ]);
      }
    }
  };
  const onChangeDate2 = (date: any) => {
    setError((prevData) => ({ ...prevData, day2: "" }));
    setValue("date2", date);
    trigger("date2");
  };

  const handleOptionTimes2 = (option: any) => {
    setError((prevData) => ({
      ...prevData,
      time2: "",
    }));

    if (selectedTimes2.includes(option)) {
      setSelectedtimes2((prevSelectedOptions: any) =>
        prevSelectedOptions.filter((o: any) => o !== option)
      );
    } else {
      if (selectedTimes2?.length >= 3) {
        setError((prevData) => ({
          ...prevData,
          time2: "You can only select up to 3 time slots",
        }));
      } else {
        setSelectedtimes2((prevSelectedOptions: any) => [
          ...prevSelectedOptions,
          option,
        ]);
      }
    }
  };
  const onSubmit = () => {
    const data = {
      anotherDay,
      selectedTimes,
      selectedTimes2,
      inputs,
      interviewType: watch("interviewType"),
      duration: watch("duration"),
      selecctedJob: watch("selecctedJob"),
      locations: watch("locations").trim(),
      link: watch("locations") === "video/web" ? watch("link").trim() : "",
      date: watch("date"),
      date2: watch("date2"),
      eventName: watch("eventName").trim(),
      phone: watch("locations") === "phone" ? watch("phone") : "",
      address:
        watch("locations") === "in-person"
          ? JSON.stringify(watch("address"))
          : "",
      userId: clientId,
    };
    let errors = {};

    if (anotherDay && watch("date2") === null) {
      errors = {
        ...errors,
        day2: "Please Select a Date",
        time1: "",
      };
    }

    if (anotherDay && selectedTimes2?.length < 1) {
      errors = {
        ...errors,
        time2: "Select at least one time slot",
        time1: "",
      };
    }

    if (Object.keys(errors).length > 0) {
      setError((prevData) => ({
        ...prevData,
        ...errors,
      }));
    } else {
      setError({ time1: "", time2: "", day1: "", day2: "" });

      scheduleMutation(data, {
        onSuccess: () => {
          refetchCandidate();
          reset();
          onModalClose();
        },
      });
    }
  };

  return {
    handleOptionTimes,
    selectedTimes,
    register,
    onChangeDate,
    handleSubmit,
    errors,
    setValue,
    watch,
    trigger,
    onModalClose,
    isDirty,
    control,
    isSubmitted,
    isSubmitTimes,
    setIsSubmitTimes,
    handleAddInput,
    inputs,
    handleRemoveInput,
    handleInputChange,
    onChangeDate2,
    handleOptionTimes2,
    isSubmitTimes2,
    selectedTimes2,
    toggleAnotherDay,
    anotherDay,
    setIsSubmitTimes2,
    onSubmit,
    jobFunctionListOption,
    scheduleLoading,
    inputError,
    setInputError,
    user,
    setInputs,
    error,
    setError,
  };
};

export default useSchedule;
