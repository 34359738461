import * as yup from "yup";

export const loginSchema = yup
  .object()
  .shape({
    email: yup
      .string()
      .email("Email must be a valid email")
      .required("Email field is required"),
    password: yup
      .string()
      .required("Password field is required")
      .min(8, "Length must be greater than 8"),
  })
  .required();
export const forgotPassSchema = yup
  .object()
  .shape({
    email: yup
      .string()
      .email("Email must be a valid email")
      .required("Email field is required"),
  })
  .required();
export const resetPassSchema = yup
  .object()
  .shape({
    password: yup
      .string()
      .required("Password field is required")
      .min(8, "Length must be greater than 8"),
    confirmPassword: yup
      .string()
      .required("Password field is required")
      .min(8, "Length must be greater than 8")
      .oneOf([yup.ref("password"), ""], "Passwords must match"),
  })
  .required();
export const SignupSchemaEmail = yup
  .object()
  .shape({
    email: yup
      .string()
      .email("Email must be a valid email")
      .required("Email field is required"),
  })
  .required();
export const SignupSchemaNumber = yup
  .object()
  .shape({
    phoneNumber: yup.string().required("Phone number is required"),
  })
  .required();
export let scheduleStep1Schema: any = yup
  .object()
  .shape({
    interviewType: yup.string().required("Type of meeting is required"),
    duration: yup.string().required("Duration is required "),
  })
  .required();
export let userDetailSchema: any = yup
  .object()
  .shape({
    companyName: yup
      .string()
      .trim()
      .required("Company Name is required")
      .test(
        "no-leading-spaces",
        "Company Name cannot start or end with spaces",
        (value: string | undefined) => {
          if (!value?.trim()) return false; // Reject empty value
          return value.trim() === value;
        }
      ),
    companyWebsite: yup
      .string()
      .required("Company Website is required")
      .matches(/^(ftp|http|https):\/\/[^ "]+$/, "Invalid URL format"),
    fullName: yup
      .string()
      .required("Full Name is required")
      .test(
        "no-leading-spaces",
        "Full Name cannot start or end with spaces",
        (value: string | undefined) => {
          if (!value?.trim()) return false; // Reject empty value
          return value.trim() === value;
        }
      ),
    title: yup
      .string()
      .required("Title is required")
      .test(
        "no-leading-spaces",
        "Title cannot start or end with spaces",
        (value: string | undefined) => {
          if (!value?.trim()) return false; // Reject empty value
          return value.trim() === value;
        }
      ),
    roleName: yup
      .string()
      .required("Role is required")
      .test(
        "no-leading-spaces",
        "Role cannot start or end with spaces",
        (value: string | undefined) => {
          if (!value?.trim()) return false; // Reject empty value
          return value.trim() === value;
        }
      ),
    email: yup
      .string()
      .email("Email must be a valid email")
      .required("Email is required")
      .test(
        "no-leading-spaces",
        "Email cannot start or end with spaces",
        (value: string | undefined) => {
          if (!value?.trim()) return false; // Reject empty value
          return value.trim() === value;
        }
      ),
    linkedinUrl: yup
      .string()
      .required("LinkedIn URL is required")
      .matches(/^(ftp|http|https):\/\/[^ "]+$/, "Invalid URL format"),
    calandarUrl: yup
      .string()
      .required("Calendar URL is required")
      .matches(/^(ftp|http|https):\/\/[^ "]+$/, "Invalid URL format"),
  })
  .required();
