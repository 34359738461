import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import EducationPage from "../../../../pages/Candidate/signupFlow/educationPage";
import ExperiencePage from "../../../../pages/Candidate/signupFlow/experiencePage";
import SetupPage from "../../../../pages/Candidate/signupFlow/setupPage";
import FlowWrapper from "./flowWrapper";
import LocationPage from "../../../../pages/Candidate/signupFlow/locationPage";
import AddEducationPage from "../../../../pages/Candidate/signupFlow/addEducationPage";
import AddExperiencePage from "../../../../pages/Candidate/signupFlow/addExperiencePage";
import ResumeCard from "../../../../components/candidate/signupFlow/resume/resumeCard";
import PersonalizeJobSearch from "../../../candidate/signupFlow/personalizeJobSearch/personalizeJobSearchCard";

export const SignupFlow = () => {
  return (
    <Routes>
      <Route element={<FlowWrapper />}>
        <Route path="/location" element={<LocationPage />} />
        <Route path="/resume" element={<ResumeCard />} />
        <Route
          path="/personalizeJobSearch"
          element={<PersonalizeJobSearch />}
        />
        <Route path="/education" element={<EducationPage />} />
        <Route path="/education/add" element={<AddEducationPage />} />
        <Route path="/setup" element={<SetupPage />} />
        <Route path="/experience" element={<ExperiencePage />} />
        <Route path="/experience/add" element={<AddExperiencePage />} />
      </Route>
    </Routes>
  );
};
