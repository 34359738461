import {
  Button,
  Card,
  CardBody,
  Flex,
  Grid,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Select,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BiSearch } from "react-icons/bi";
import LoadingScreen from "../../common/loadingScreen";
import NoDataCard from "../../common/nodata";
import { useFetch } from "../../../utils/useFetch";
import useTalent from "../search-talent/useTalent";
import SearchTalentCard from "../search-talent/searchTalentCard";
import ArchivedTalentCard from "../liked/archivedTalentCard";
import LikedTalentCard from "../liked/archivedTalentCard";
const ViewApplicant = (data: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const applicantData = location?.state?.data;

  const {
    candidateLoading,
    jobFunctionListOption,
    selectJobFunc,
    jobFunction,

    handleSearch,
  } = useTalent();
  const [functions, setFunctions] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [searchJobFunctionResult, setSearchJobFunctionResult] = useState<any>(
    []
  );
  const [showJobFunctionResult, setShowJobFunctionResult] = useState(false);
  const searchFieldRef = useRef<HTMLDivElement>(null);
  const inputref = useRef<HTMLInputElement>(null);
  const handleFocusBlur = (value: boolean) => {
    setIsFocused(value);
  };
  const handleSearchJobFunctions = (search: any) => {
    setFunctions(search);
    setShowJobFunctionResult(false);
  };
  useEffect(() => {
    try {
      useFetch
        //   .get(`/users/domain/all-job-function-list?query=${functions}`)
        .get(`/jobs/apply/get-applicants?jobId=${applicantData?.id}`)

        .then((response: any) => {
          setSearchJobFunctionResult(response.data.users);
        })
        .catch((error: any) => {
          console.log("Error Fetching Data", error);
        });
    } catch (error) {
      console.log("ERROR", error);
    }
  }, [applicantData?.id]);
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        searchFieldRef.current &&
        !searchFieldRef.current.contains(event.target as Node)
      ) {
        handleFocusBlur(false);
        setShowJobFunctionResult(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <Card bg="#F7F6F8" borderRadius={"0px"} boxShadow={"none"}>
        <CardBody>
          <Flex w="full" justify="end" gap={"4"}>
            <Button
              color="#74788d"
              size="sm"
              fontWeight={"bold"}
              _hover={{ color: "white", bg: "#5867DD" }}
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
          </Flex>
        </CardBody>
      </Card>
      <Flex w="full" justify={"center"} m="auto" p="4" py="8" bg="#F0F1F7">
        {candidateLoading ? (
          <LoadingScreen />
        ) : searchJobFunctionResult && searchJobFunctionResult.length > 0 ? (
          <Grid
            w="100% !important"
            templateColumns={[
              "1fr",
              "1fr",
              "1fr",
              "repeat(2, 1fr)",
              "repeat(2, 1fr)",
              "repeat(3, 1fr)",
            ]}
            //   p="2"
            gap={4}
            overflowX="auto"
            width="100%"
            sx={{
              "&::-webkit-scrollbar": {
                height: "4px",
              },
              "&::-webkit-scrollbar-track": {
                background: "transparent",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "gray.400",
                borderRadius: "full",
              },
            }}
          >
            {searchJobFunctionResult &&
              searchJobFunctionResult.map((user: any, index: number) => (
                <LikedTalentCard key={index} data={user} />
              ))}
          </Grid>
        ) : (
          <Card p={4} boxShadow="md" borderRadius="md" py="10" w="full">
            <Heading size="md" mb={4} color="#48465b">
              No Applicants
            </Heading>

            <Text color="#646c9a">
              No candidate(s) have applied for this job.
            </Text>
          </Card>
        )}
      </Flex>
    </>
  );
};
export default ViewApplicant;
