import {
  Box,
  Button,
  Card,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Heading,
  Image,
  Input,
  Select,
  Stack,
  Switch,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import RadioGroup from "../../../../common/radioGroup";
import useExperience from "../useExperience";
import { Controller } from "react-hook-form";
import { useFetch } from "../../../../../utils/useFetch";
import CustomDatePicker from "../../../../common/date-picker/datepicker";

const AddExperience = () => {
  const inputref = useRef<HTMLInputElement>(null);
  const {
    register,
    errors,
    handleSubmit,
    watch,
    onSubmit,
    control,
    isSubmitted,
    handleStartDateChange,
    handleEndDateChange,
    experienceLoading,
    state,
    experienceUpdateLoading,
    updateHandler,
    industriesList,
    setIndustriesList,
    jobFunc,
    setJobFunc,
    topSkills,
    setTopSkills,
    industryError,
    functionError,
    skillsError,
    isSwitchOn,
    setIsSwitchOn,
    setValue,
    setSkillsError,
    setFunctionError,
    setIndustryError,
    handleValidation,
  } = useExperience();
  const today = new Date();
  const [industryName, setIndustryName] = useState("");
  const [functions, setFunctions] = useState("");
  const [skills, setSkills] = useState("");
  const [showJobIndustriesResult, setShowJobIndustriesResult] = useState(false);
  const [isIndustryFocused, setIsIndustryFocused] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isSkillsFocused, setIsSkillsFocused] = useState(false);
  const [searchJobFunctionResult, setSearchJobFunctionResult] = useState<any>(
    []
  );
  const [jobIndustryResult, setJobIndustryResult] = useState<any>([]);
  const [searchTopSkillsResult, setSearchTopSkillsResult] = useState<any>([]);
  const [showJobFunctionResult, setShowJobFunctionResult] = useState(false);
  const [showTopSkillsResult, setShowTopSkillsResult] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
  const [formErrorFunctions, setFormErrorFunctions] = useState("");
  const [formErrorSkills, setFormErrorSkills] = useState("");
  const [formErrorIndustry, setFormErrorIndustry] = useState("");
  const [error, setError] = useState({
    jobFunctionsError: "",
    prefferedIndustriesError: "",
    topSkillsError: "",
  });

  const handleIndustryFocusBlur = (value: boolean) => {
    setIsIndustryFocused(value);
  };
  const handleFocusBlur = (value: boolean) => {
    setIsFocused(value);
  };
  const handleSkillsFocusBlur = (value: boolean) => {
    setIsSkillsFocused(value);
  };

  const handleRemoveIndustry = (indexToRemove: number) => {
    setIndustriesList((prevIndustriesList: any) =>
      prevIndustriesList.filter(
        (_: any, index: number) => index !== indexToRemove
      )
    );
  };

  const handleAddIndustry = (industryNameProps: string) => {
    if (industryNameProps) {
      setShowJobIndustriesResult(false);
      setIndustryError(false);
      if (industriesList.includes(industryNameProps)) {
        setError((prevError: any) => ({
          ...prevError,
          prefferedIndustriesError: "You have already selected this industry",
        }));
      } else if (industriesList?.length >= 3) {
        setError((prevError: any) => ({
          ...prevError,
          prefferedIndustriesError: "You can select up to 3 options",
        }));
      } else {
        setIndustriesList((prevIndustriesList: any) => [
          ...prevIndustriesList,
          industryNameProps,
        ]);
        setIndustryName("");
        setError((prevError: any) => ({
          ...prevError,
          prefferedIndustriesError: "",
        }));
      }
    }
  };
  const searchFieldRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        searchFieldRef.current &&
        !searchFieldRef.current.contains(event.target as Node)
      ) {
        handleFocusBlur(false);
        setShowJobFunctionResult(false);
        setShowTopSkillsResult(false);
        setShowJobIndustriesResult(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleAddJobFunctions = (functionsProps: string) => {
    if (functionsProps) {
      setFunctionError(false);
      setShowJobFunctionResult(false);

      if (jobFunc.includes(functionsProps)) {
        setError((prevError) => ({
          ...prevError,
          jobFunctionsError: "You have already selected this job function",
        }));
      } else if (jobFunc?.length >= 3) {
        setError((prevError) => ({
          ...prevError,
          jobFunctionsError: "You can select up to 3 options",
        }));
      } else {
        setJobFunc((prevJobFuncList) => [...prevJobFuncList, functionsProps]);
        setFunctions("");
        setError((prevError) => ({ ...prevError, jobFunctionsError: "" }));
      }
    } else {
      setError((prevError) => ({
        ...prevError,
        jobFunctionsError: "Please fill the required field",
      }));
    }
  };

  const handleAddTopSkills = (skillsProps: string) => {
    if (skillsProps) {
      setShowTopSkillsResult(false);
      if (topSkills.includes(skillsProps)) {
        setError((prevError) => ({
          ...prevError,
          topSkillsError: "You have already selected this skill",
        }));
      } else if (topSkills?.length >= 3) {
        setError((prevError) => ({
          ...prevError,
          topSkillsError: "You can select up to 3 options",
        }));
      } else {
        setTopSkills((prevTopSkillsList: any) => [
          ...prevTopSkillsList,
          skillsProps,
        ]);
        setSkills("");
        setError((prevError) => ({ ...prevError, topSkillsError: "" }));
      }
    }
  };

  const handleRemoveTopSkills = (indexToRemove: number) => {
    setTopSkills((prevTopSkillsList: any) =>
      prevTopSkillsList.filter(
        (_: any, index: number) => index !== indexToRemove
      )
    );
  };
  useEffect(() => {
    if (isIndustryFocused) {
      if (industryName?.length > 0) {
        try {
          useFetch
            .get(`/users/domain/all-job-industry-list?query=${industryName}`)
            .then((response) => {
              setJobIndustryResult(response.data);
              setShowJobIndustriesResult(true);
            })
            .catch((error) => {
              console.log("Error", error);
            });
        } catch (error) {
          console.log("ERROR", error);
        }
      } else {
        setShowJobIndustriesResult(false);
      }
    }
  }, [industryName]);
  useEffect(() => {
    if (isFocused) {
      if (functions?.length > 0) {
        try {
          useFetch
            .get(`/users/domain/all-job-function-list?query=${functions}`)
            .then((response) => {
              setSearchJobFunctionResult(response.data);
              setShowJobFunctionResult(true);
            })
            .catch((error) => {
              console.log("Error", error);
            });
        } catch (error) {
          console.log("ERROR", error);
        }
      } else {
        setShowJobFunctionResult(false);
      }
    }
  }, [functions]);

  useEffect(() => {
    if (isSkillsFocused) {
      if (skills?.length > 0) {
        try {
          useFetch
            .get(`/users/domain/all-job-skill-name-list?query=${skills}`)
            .then((response) => {
              setSearchTopSkillsResult(response.data);
              setShowTopSkillsResult(true);
            })
            .catch((error) => {
              console.log("Error", error);
            });
        } catch (error) {
          console.log("ERROR", error);
        }
      } else {
        setShowTopSkillsResult(false);
      }
    }
  }, [skills]);

  const handleRemoveJobFunction = (indexToRemove: number) => {
    setJobFunc((prevJobfuncList: any) =>
      prevJobfuncList.filter((_: any, index: number) => index !== indexToRemove)
    );
  };
  const handleSwitchChange = (e: any) => {
    setIsSwitchOn(e.target.checked);
    if (e.target.checked == true) {
      setValue("indirectReports", 0);
    }
  };
  const validateEmpltyName = (value: string) => {
    if (!value.trim()) {
      return "Full Name is required";
    }
    return true; // Validation passed
  };
  return (
    <Card p="4" w="full">
      <form
        style={{ width: "100%" }}
        onSubmit={(e) => {
          e.preventDefault();
          handleValidation();
          handleSubmit((values) => {
            if (state?.isEdit && topSkills?.length > 0 && jobFunc?.length > 0) {
              updateHandler({ ...values });
            } else {
              onSubmit(values);
            }
          })();
        }}
        onKeyDown={(e: any) => {
          if (e.key === "Enter") {
            e.preventDefault();
            handleValidation();
            handleSubmit((values) => {
              if (
                state?.isEdit &&
                topSkills?.length > 0 &&
                jobFunc?.length > 0
              ) {
                updateHandler({ ...values });
              } else {
                onSubmit(values);
              }
            })();
          }
        }}
      >
        <Box my="4">
          <Stack spacing={6}>
            <Heading size="lg" fontWeight={"500"}>
              {state?.isEdit ? "Edit Experience" : "Add Experience"}
            </Heading>

            <FormControl isInvalid={Boolean(errors?.jobTitle)}>
              <Flex align="start">
                <FormLabel
                  fontWeight={"bold"}
                  fontSize="md"
                  me="2px !important"
                >
                  Job Title
                </FormLabel>
                <Box as="span" color="red" fontSize="md">
                  *
                </Box>
              </Flex>
              <Input
                maxLength={100}
                type="text"
                placeholder="Enter job title"
                w={{ base: "100%", md: "70%" }}
                {...register("jobTitle", {
                  required: "Job Title is required",
                  validate: (value) => {
                    if (!value.trim()) {
                      return "Job Title is required";
                    }
                    if (value.trim() !== value) {
                      return "Job Title cannot start or end with spaces";
                    }
                    return true;
                  },
                })}
                value={watch("jobTitle")}
              />
              <Text fontSize="sm" color="red.500">
                {errors?.jobTitle?.message}
              </Text>
            </FormControl>
            <FormControl isInvalid={Boolean(errors?.companyName)}>
              <Flex align="start">
                <FormLabel
                  fontWeight={"bold"}
                  fontSize="md"
                  me="2px !important"
                >
                  Company Name
                </FormLabel>
                <Box as="span" color="red" fontSize="md">
                  *
                </Box>
              </Flex>

              <Input
                maxLength={100}
                type="text"
                placeholder="Enter Company name"
                w={{ base: "100%", md: "70%" }}
                {...register("companyName", {
                  required: "Company Name is required",
                  validate: (value) => {
                    if (!value.trim()) {
                      return "Company Name is required";
                    }
                    if (value.trim() !== value) {
                      return "Company Name cannot start or end with spaces";
                    }
                    return true;
                  },
                })}
                value={watch("companyName")}
              />
              <Text fontSize="sm" color="red.500">
                {errors?.companyName?.message}
              </Text>
            </FormControl>
            <FormControl isInvalid={Boolean(errors?.employeeType)}>
              <Flex align="start">
                <FormLabel
                  fontWeight={"bold"}
                  fontSize="md"
                  me="2px !important"
                >
                  Employment Type
                </FormLabel>
                <Box as="span" color="red" fontSize="md">
                  *
                </Box>
              </Flex>
              <Select
                w={{ base: "100%", md: "70%" }}
                isDisabled={false}
                size="md"
                color={watch("employeeType") ? "black" : "gray.500"}
                h="42px"
                borderRadius={"md"}
                placeholder="Please select employee type"
                {...register("employeeType", {
                  required: "Employee type is required",
                })}
                value={watch("employeeType")}
                name="employeeType"
                mr="2"
              >
                <option value={0}>Full Time</option>
                <option value={1}>Part Time</option>
                <option value={2}>Contract/Temporary</option>
                <option value={3}>Internship</option>
                <option value={4}>Freelance</option>
                <option value={5}>Self-Employed</option>
                <option value={6}>Volunteer</option>
                <option value={7}>Apprenticeship</option>
              </Select>
              {errors?.employeeType && (
                <Text fontSize="sm" color="red.500">
                  {errors?.employeeType?.message?.toString()}
                </Text>
              )}
            </FormControl>
            <Checkbox
              id="currentlyWorking"
              {...register("currentlyWorking")}
              isChecked={watch("currentlyWorking")}
            >
              I am currently working in this role
            </Checkbox>
            <HStack w="full" align="start">
              <VStack w="full" align={"baseline"}>
                <HStack
                  w={{
                    base: "100%",
                    md: !watch("currentlyWorking") ? "100%" : "49.5%",
                  }}
                >
                  <Controller
                    name="startDate"
                    control={control}
                    rules={{ required: "This date is required" }}
                    render={({ field: { onChange, value } }) => (
                      <FormControl isInvalid={Boolean(errors?.startDate)}>
                        <Flex align="start">
                          <FormLabel
                            fontWeight={"bold"}
                            fontSize="md"
                            me="2px !important"
                          >
                            Start Date
                          </FormLabel>
                          <Box as="span" color="red" fontSize="md">
                            *
                          </Box>
                        </Flex>
                        <CustomDatePicker
                          label="mm/yyyy"
                          selected={value}
                          onChange={(date: any) => {
                            handleStartDateChange(date);
                            setSelectedStartDate(date);
                          }}
                          dateFormat="MM/yyyy"
                          placeholderText="mm/yyyy"
                          showMonthYearPicker
                          dropdownMode="select"
                          maxDate={watch("endDate") || today}
                        />
                        {errors?.startDate && (
                          <Text fontSize="sm" color="red.500">
                            {errors?.startDate?.message?.toString()}
                          </Text>
                        )}
                      </FormControl>
                    )}
                  />
                </HStack>
              </VStack>

              {!watch("currentlyWorking") && (
                <VStack w="full" align={"baseline"}>
                  <HStack w={{ base: "100%", md: "100%" }} align="start">
                    <Controller
                      name="endDate"
                      control={control}
                      rules={{ required: "This date is required" }}
                      render={({ field: { onChange, value } }) => (
                        <FormControl isInvalid={Boolean(errors?.endDate)}>
                          <Flex align="start">
                            <FormLabel
                              fontWeight={"bold"}
                              fontSize="md"
                              me="2px !important"
                            >
                              End Date
                            </FormLabel>
                            <Box as="span" color="red" fontSize="md">
                              *
                            </Box>
                          </Flex>

                          <CustomDatePicker
                            label="mm/yyyy"
                            selected={value}
                            onChange={(date: any) => {
                              handleEndDateChange(date);
                            }}
                            dateFormat="MM/yyyy"
                            placeholderText="mm/yyyy"
                            showMonthYearPicker
                            dropdownMode="select"
                            minDate={watch("startDate")}
                            maxDate={today}
                          />

                          {errors?.endDate && (
                            <Text fontSize="sm" color="red.500">
                              {errors?.endDate?.message?.toString()}
                            </Text>
                          )}
                        </FormControl>
                      )}
                    />
                  </HStack>
                </VStack>
              )}
            </HStack>

            <FormControl
              position="relative"
              isInvalid={Boolean(error.prefferedIndustriesError)}
            >
              <Flex align="start">
                <FormLabel
                  fontWeight={"bold"}
                  fontSize="md"
                  me="2px !important"
                >
                  Industries
                </FormLabel>
                <Box as="span" color="red" fontSize="md">
                  *
                </Box>
              </Flex>

              <HStack>
                <Input
                  maxLength={50}
                  type="text"
                  placeholder="Enter Industry Name"
                  maxW={{ base: "100%", md: "70%" }}
                  borderColor="grey"
                  onChange={(e) => setIndustryName(e.target.value.trimLeft())}
                  value={industryName}
                  ref={inputref}
                  onFocus={() => handleIndustryFocusBlur(true)}
                  onBlur={() => handleIndustryFocusBlur(false)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.stopPropagation();
                      e.preventDefault();
                      handleAddIndustry(industryName);
                    }
                  }}
                />

                <Button
                  ml="2"
                  colorScheme="buttonPrimary"
                  onClick={() => handleAddIndustry(industryName)}
                >
                  Add
                </Button>
              </HStack>
              {error.prefferedIndustriesError && (
                <Text fontSize="sm" color="red.500">
                  {error.prefferedIndustriesError}
                </Text>
              )}
              {showJobIndustriesResult && jobIndustryResult?.length >= 1 && (
                <VStack
                  ref={searchFieldRef}
                  maxH="150px"
                  overflowY="auto"
                  position="absolute"
                  top="72px"
                  zIndex="1"
                  bg="white"
                  border="1px solid #F1F0F9"
                  borderRadius="10px"
                  w="70%"
                  boxShadow="0px 8px 32px 0px rgba(78, 48, 123, 0.04)"
                  padding="5px 0px"
                >
                  {jobIndustryResult.map((data: any, index: number) => (
                    <Flex
                      w="full"
                      p="5px"
                      borderBottom="1px solid #eae8e8"
                      onClick={() => handleAddIndustry(data.name)}
                      _hover={{ bg: "#F1F0F9", color: "black" }}
                      key={index}
                      cursor="pointer"
                    >
                      <Text ms="4px">{data.name}</Text>
                    </Flex>
                  ))}
                </VStack>
              )}
              {industryError && (
                <Text textColor="#E53E3E" fontSize="0.875rem" pt="5px">
                  Industries are required
                </Text>
              )}
              {formErrorIndustry && (
                <Text color="red.500" fontSize="sm">
                  {formErrorIndustry}
                </Text>
              )}
              <Flex gap={"10px"} mt="10px">
                {industriesList.map((Industry, index) => (
                  <Tag key={index}>
                    <TagLabel>{Industry}</TagLabel>
                    <TagCloseButton
                      onClick={() => handleRemoveIndustry(index)}
                    />
                  </Tag>
                ))}
              </Flex>
            </FormControl>

            <>
              <Controller
                name={"managedPeople"}
                control={control}
                defaultValue={watch("managedPeople")}
                rules={{ required: "Please select managed people role" }}
                render={({ field: { onChange, value } }) => (
                  <FormControl isInvalid={Boolean(errors?.managedPeople)}>
                    <Flex align="start">
                      <FormLabel
                        fontWeight={"bold"}
                        fontSize="md"
                        me="2px !important"
                      >
                        Managed people in this role?
                      </FormLabel>
                      <Box as="span" color="red" fontSize="md">
                        *
                      </Box>
                    </Flex>

                    <RadioGroup
                      options={[
                        "No, I was individual contributor",
                        "Lead teams but no direct reports",
                        "Yes, I had direct reports",
                      ]}
                      name="managedPeople"
                      defaultValue={
                        watch("managedPeople") ? watch("managedPeople") : ""
                      }
                      onChange={onChange}
                      maxW="100%"
                      value={watch("managedPeople")}
                    />
                    <Text fontSize="sm" color="red.500">
                      {errors?.managedPeople?.message}
                    </Text>
                  </FormControl>
                )}
              />
              {!!watch("managedPeople") &&
                watch("managedPeople") === "Yes, I had direct reports" && (
                  <>
                    <Flex
                      w="full"
                      align="start"
                      gap="2"
                      direction={{ base: "column", md: "row" }}
                    >
                      <FormControl
                        isInvalid={Boolean(errors?.directReports)}
                        w={{ base: "100%", md: "50%" }}
                      >
                        <Flex align="start">
                          <FormLabel
                            fontWeight={"bold"}
                            fontSize="md"
                            me="2px !important"
                          >
                            Number of Direct Reports
                          </FormLabel>
                          <Box as="span" color="red" fontSize="md">
                            *
                          </Box>
                        </Flex>

                        <Input
                          type="text"
                          placeholder="Enter direct reports"
                          {...register("directReports", {
                            required: "Direct reports are required",
                          })}
                          onInput={(e) => {
                            const inputElement = e.target as HTMLInputElement;
                            inputElement.value = inputElement.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                          }}
                          border="1px solid grey"
                        />
                        <Text fontSize="sm" color="red.500">
                          {errors?.directReports?.message}
                        </Text>
                      </FormControl>
                      <FormControl
                        isInvalid={Boolean(
                          !isSwitchOn && errors?.indirectReports
                        )}
                      >
                        <Flex w="full" align="start" gap="6">
                          <Flex direction="column">
                            <FormLabel
                              fontWeight={"bold"}
                              fontSize="md"
                              me="2px !important"
                            >
                              Number of Indirect Reports (if any)
                              <Box
                                as="span"
                                ml="2"
                                color="red"
                                fontSize="md"
                                mt="1"
                              >
                                {isSwitchOn ? "" : "*"}
                              </Box>
                            </FormLabel>

                            <Input
                              maxLength={50}
                              type="text"
                              placeholder="Enter Indirect reports"
                              w="full"
                              {...register("indirectReports", {
                                required: isSwitchOn
                                  ? false
                                  : "Number of Indirect Reports is required",
                              })}
                              disabled={isSwitchOn}
                              value={watch("indirectReports") || undefined}
                              onInput={(e) => {
                                const inputElement =
                                  e.target as HTMLInputElement;
                                inputElement.value = inputElement.value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                              }}
                              border="1px solid grey"
                            />
                          </Flex>
                          <Flex direction="column">
                            <FormLabel fontWeight={"bold"} fontSize="md">
                              None or N/A
                            </FormLabel>
                            <Switch
                              id="indirectReports"
                              onChange={(e: any) => handleSwitchChange(e)}
                              isChecked={isSwitchOn}
                            />
                          </Flex>
                        </Flex>
                        {!isSwitchOn && errors?.indirectReports && (
                          <Text fontSize="sm" color="red.500">
                            {errors?.indirectReports?.message}
                          </Text>
                        )}
                      </FormControl>
                    </Flex>
                  </>
                )}

              <FormControl
                position="relative"
                isInvalid={Boolean(error.jobFunctionsError)}
              >
                <Heading size="sm" pos="relative" me="2px !important">
                  Your Job Functions
                  <Box
                    as="span"
                    color="red"
                    fontWeight="400"
                    fontSize="md"
                    mt="1"
                  >
                    *
                  </Box>
                </Heading>
                <HStack>
                  <Input
                    maxLength={50}
                    type="text"
                    placeholder="Search Job Functions"
                    maxW={{ base: "100%", md: "70%" }}
                    ref={inputref}
                    onFocus={() => handleFocusBlur(true)}
                    onBlur={() => handleFocusBlur(false)}
                    borderColor="grey"
                    onChange={(e) => setFunctions(e.target.value.trimLeft())}
                    value={functions}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.stopPropagation();
                        e.preventDefault();
                        handleAddJobFunctions(functions);
                      }
                    }}
                  />

                  <Button
                    ml="2"
                    colorScheme="buttonPrimary"
                    onClick={() => handleAddJobFunctions(functions)}
                  >
                    Add
                  </Button>
                </HStack>
                {error.jobFunctionsError && (
                  <Text fontSize="sm" color="red.500">
                    {error.jobFunctionsError}
                  </Text>
                )}
                {showJobFunctionResult &&
                  searchJobFunctionResult?.length >= 1 && (
                    <VStack
                      maxH="150px"
                      overflowY="auto"
                      position="absolute"
                      top="60px"
                      zIndex="50"
                      bg="white"
                      border="1px solid #F1F0F9"
                      borderRadius="10px"
                      w="70%"
                      boxShadow="0px 8px 32px 0px rgba(78, 48, 123, 0.04)"
                      padding="5px 0px"
                      ref={searchFieldRef}
                    >
                      {searchJobFunctionResult.map(
                        (data: any, index: number) => (
                          <Flex
                            w="full"
                            p="5px"
                            borderBottom="1px solid #eae8e8"
                            onClick={() => handleAddJobFunctions(data.name)}
                            _hover={{ bg: "#F1F0F9", color: "black" }}
                            key={index}
                            cursor="pointer"
                          >
                            <Text ms="4px">{data.name}</Text>
                          </Flex>
                        )
                      )}
                    </VStack>
                  )}
                {functionError && (
                  <Text textColor="#E53E3E" fontSize="0.875rem" pt="5px">
                    Job functions are required
                  </Text>
                )}
                {formErrorFunctions && (
                  <Text color="red.500" fontSize="sm">
                    {formErrorFunctions}
                  </Text>
                )}
                <Flex gap={"10px"} mt="10px">
                  {jobFunc.map((functions, index) => (
                    <Tag key={index}>
                      <TagLabel>{functions}</TagLabel>
                      <TagCloseButton
                        onClick={() => handleRemoveJobFunction(index)}
                      />
                    </Tag>
                  ))}
                </Flex>
              </FormControl>

              <FormControl
                position="relative"
                isInvalid={Boolean(error.topSkillsError)}
              >
                <Heading size="sm" me="2px !important">
                  Top 3 Skills related to the role
                  <Box
                    as="span"
                    fontWeight="400"
                    color="red"
                    fontSize="md"
                    mt="1"
                  >
                    *
                  </Box>
                </Heading>
                <HStack>
                  <Input
                    maxLength={50}
                    type="text"
                    placeholder="Search Top Skills"
                    maxW={{ base: "100%", md: "70%" }}
                    borderColor="grey"
                    onChange={(e) => setSkills(e.target.value.trimLeft())}
                    value={skills}
                    ref={inputref}
                    onFocus={() => handleSkillsFocusBlur(true)}
                    onBlur={() => handleSkillsFocusBlur(false)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.stopPropagation();
                        e.preventDefault();
                        handleAddTopSkills(skills);
                      }
                    }}
                  />
                  <Button
                    ml="2"
                    colorScheme="buttonPrimary"
                    onClick={() => handleAddTopSkills(skills)}
                  >
                    Add
                  </Button>
                </HStack>
                {error.topSkillsError && (
                  <Text fontSize="sm" color="red.500">
                    {error.topSkillsError}
                  </Text>
                )}
                {showTopSkillsResult && searchTopSkillsResult?.length >= 1 && (
                  <VStack
                    ref={searchFieldRef}
                    maxH="150px"
                    overflowY="auto"
                    position="absolute"
                    top="60px"
                    zIndex="50"
                    bg="white"
                    border="1px solid #F1F0F9"
                    borderRadius="10px"
                    w="70%"
                    boxShadow="0px 8px 32px 0px rgba(78, 48, 123, 0.04)"
                    padding="5px 0px"
                  >
                    {searchTopSkillsResult.map((data: any, index: number) => (
                      <Flex
                        w="full"
                        p="5px"
                        borderBottom="1px solid #eae8e8"
                        onClick={() => handleAddTopSkills(data.name)}
                        _hover={{ bg: "#F1F0F9", color: "black" }}
                        key={index}
                        cursor="pointer"
                      >
                        <Text ms="4px">{data.name}</Text>
                      </Flex>
                    ))}
                  </VStack>
                )}
                {skillsError && (
                  <Text textColor="#E53E3E" fontSize="0.875rem" pt="5px">
                    Top skills are required
                  </Text>
                )}
                {formErrorSkills && (
                  <Text color="red.500" fontSize="sm">
                    {formErrorSkills}
                  </Text>
                )}
                <Flex gap={"10px"} mt="10px">
                  {topSkills.map((skills: string, index: number) => (
                    <Tag key={index}>
                      <TagLabel>{skills}</TagLabel>
                      <TagCloseButton
                        onClick={() => handleRemoveTopSkills(index)}
                      />
                    </Tag>
                  ))}
                </Flex>
              </FormControl>

              <FormControl w="80%">
                <FormLabel fontWeight={"bold"} fontSize="md">
                  Job Description
                </FormLabel>
                <Textarea
                  {...register("jobDescription")}
                  value={watch("jobDescription")}
                  placeholder="Enter Job Description"
                  size="sm"
                  maxLength={5000}
                  border="1px solid grey"
                />
                <Text fontSize="sm" color="red.500">
                  {errors?.jobDescription?.message}
                </Text>
              </FormControl>
            </>
          </Stack>

          <Button
            colorScheme="buttonPrimary"
            mt="4"
            type="submit"
            isLoading={experienceLoading || experienceUpdateLoading}
            isDisabled={experienceLoading || experienceUpdateLoading}
          >
            Submit
          </Button>
        </Box>
      </form>
    </Card>
  );
};

export default AddExperience;
