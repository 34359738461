import {
  Avatar,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { IoIosArrowBack } from "react-icons/io";
import React, { useEffect, useState } from "react";
import { GrAmazon } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import CustomDatePicker from "../../common/date-picker/datepicker";
import StepsAnimation from "../../schedule-meeting/steps-animation";
import CheckBoxButton from "../../common/checkboxButton";
import useInterviewReschedule from "./useInterviewReschedule";
import { GenericModal } from "../../common/generic-modal";
import LoadingScreen from "../../common/loadingScreen";
import moment from "moment";
import RadioGroup from "../../common/radioGroup";
import { useUserStore } from "../../../store/useUser";

const MeetingDetails = () => {
  // const handleJobFunc = (option: any) => {
  //   if (reason.includes(option)) {
  //     setReason((prevSelectedOptions: any) =>
  //       prevSelectedOptions.filter((o: any) => o !== option)
  //     );
  //   } else if (reason.length < 3) {
  //     setReason((prevSelectedOptions: any) => [
  //       ...prevSelectedOptions,
  //       option,
  //     ]);
  //   }
  // };

  const optionstimes: any = [
    "12:00 AM",

    "01:00 AM",

    "02:00 AM",

    "03:00 AM",

    "04:00 AM",

    "05:00 AM",

    "06:00 AM",

    "07:00 AM",

    "08:00 AM",

    "09:00 AM",

    "10:00 AM",

    "11:00 AM",

    "12:00 PM",

    "01:00 PM",

    "02:00 PM",

    "03:00 PM",

    "04:00 PM",

    "05:00 PM",

    "06:00 PM",

    "07:00 PM",

    "08:00 PM",

    "09:00 PM",

    "10:00 PM",

    "11:00 PM",
  ];
  const reasonOptions = [
    "Family emergency",
    "Conflicting meetings",
    "Feeling ill",
    "Too much going on",
    "Other",
  ];
  const {
    isOpen,
    onOpen,
    onModalClose,
    onChangeDate,
    handleOptionTimes,
    selectedTimes,
    watch,
    setReschedule,
    reschedule,
    isSubmitTimes,
    setIsSubmitTimes,
    setCopied,
    copied,
    scheduleData,
    fetchLoading,
    scheduleLoading,
    reason,
    setReason,
    valueReason,
    setValueReason,
    onSubmit,
    navigate,
    selectedTimes2,
    anotherDay,
    handleOptionTimes2,
    onChangeDate2,
    toggleAnotherDay,
    timeSlotsError,
    timeSlots2Error,
    error,
  } = useInterviewReschedule();

  const handleInputTextAreaChange = (e: any) => {
    let InputTextAreaValue = e.target.value;
    setValueReason(InputTextAreaValue);
  };
  const { user } = useUserStore();
  const [sortedTimes1, setSortedTimes1] = useState<string[]>([]);
  const [sortedTimes2, setSortedTimes2] = useState<string[]>([]);
  const sortTimes = () => {
    const sortFunction = (a: string, b: string) => {
      const currentDate = new Date(); // Use the current date
      const timeA = new Date(
        `${
          currentDate.getMonth() + 1
        }/${currentDate.getDate()}/${currentDate.getFullYear()} ${a}`
      );
      const timeB = new Date(
        `${
          currentDate.getMonth() + 1
        }/${currentDate.getDate()}/${currentDate.getFullYear()} ${b}`
      );
      return timeA.getTime() - timeB.getTime();
    };

    const sortedSelectedTimes1 =
      scheduleData?.selectedTimes && scheduleData?.selectedTimes?.length > 1
        ? [...scheduleData?.selectedTimes]?.sort(sortFunction)
        : scheduleData?.selectedTimes;
    const sortedSelectedTimes2 =
      scheduleData?.selectedTimes2 && scheduleData?.selectedTimes2?.length > 1
        ? [...scheduleData?.selectedTimes2]?.sort(sortFunction)
        : scheduleData?.selectedTimes2;

    setSortedTimes1(
      scheduleData?.selectedTimes && scheduleData?.selectedTimes?.length > 1
        ? [...sortedSelectedTimes1]
        : [sortedSelectedTimes1]
    );
    setSortedTimes2(
      scheduleData?.selectedTimes2 && scheduleData?.selectedTimes2?.length > 1
        ? [...sortedSelectedTimes2]
        : [sortedSelectedTimes2]
    );
  };

  useEffect(() => {
    sortTimes();
  }, [scheduleData?.selectedTimes, scheduleData?.selectedTimes2]);

  return (
    <>
      {fetchLoading ? (
        <LoadingScreen />
      ) : (
        <VStack
          w={{ base: "full", md: "80%", lg: "60%" }}
          m="auto"
          spacing={"3"}
          p="4"
          py="8"
        >
          <Card p="4" w="full">
            <Box my="4">
              <Stack
                direction="row"
                spacing={4}
                align="center"
                justify="space-between"
                w="full"
              >
                <Heading fontSize={"xl"} color="#646c9a">
                  Meeting Details
                </Heading>
                <Button
                  loadingText="Loading"
                  color="#74788d"
                  spinnerPlacement="end"
                  size="sm"
                  fontWeight={"bold"}
                  _hover={{ color: "white", bg: "#5867DD" }}
                  // leftIcon={<IoIosArrowBack />}
                  onClick={() => navigate("/interview")}
                >
                  Back
                </Button>
              </Stack>
            </Box>
          </Card>
          <Card boxShadow={"none"} borderRadius="none" w="full">
            <CardBody>
              <VStack w="full" justify="flex-start" align="start" gap="0">
                <HStack w="full">
                  <Avatar
                    size="sm"
                    name=""
                    src={scheduleData?.candidateImage}
                    border="1px solid #ebebebb"
                  />
                  <Text fontSize={"lg"} fontWeight={"bold"} w="full">
                    {scheduleData?.candidateName}
                  </Text>
                </HStack>
                <HStack
                  align="start"
                  gap="2"
                  height="auto"
                  alignItems="stretch"
                >
                  <Flex direction="column">
                    {" "}
                    <Text fontSize={"sm"} color={"gray"} w="full">
                      {moment(scheduleData?.date).format("ddd, MMMM DD, YYYY")}
                    </Text>
                    {sortedTimes1?.map((time: string, index: number) => (
                      <Text
                        mt="0px !important"
                        key={index}
                        fontSize={"sm"}
                        color={"gray"}
                        w="full"
                      >
                        {time}
                        {index !== scheduleData.selectedTimes.length - 1
                          ? ","
                          : ""}
                      </Text>
                    ))}
                  </Flex>

                  {scheduleData?.date2 && (
                    <>
                      <Box minH="100%" borderLeft="1px solid #48465b" />

                      <Flex direction="column">
                        {" "}
                        <Text fontSize={"sm"} color={"gray"} w="full">
                          {moment(scheduleData?.date2).format(
                            "ddd, MMMM DD, YYYY"
                          )}
                        </Text>
                        {sortedTimes2?.map((time: string, index: number) => (
                          <Text
                            mt="0px !important"
                            key={index}
                            fontSize={"sm"}
                            color={"gray"}
                            w="full"
                          >
                            {time}
                            {index !== scheduleData.selectedTimes.length - 1
                              ? ","
                              : ""}
                          </Text>
                        ))}
                      </Flex>
                    </>
                  )}
                </HStack>
                {scheduleData?.isRescheduleRequested && (
                  <HStack w="full">
                    <Text fontSize={"md"} fontWeight={"bold"}>
                      Reason:
                    </Text>
                    <Text fontSize={"md"} w="full">
                      {scheduleData?.reason}
                    </Text>
                  </HStack>
                )}

                {scheduleData?.locations === "phone" && (
                  <FormControl>
                    <FormLabel fontWeight={"bold"}> Phone Number</FormLabel>
                    <InputGroup
                      size="md"
                      w={{ base: "100%", md: "70%" }}
                      ml="-5px"
                    >
                      <Input isReadOnly={true} value={scheduleData?.phone} />
                      <InputRightElement width="4.5rem">
                        <Button
                          h="1.75rem"
                          size="sm"
                          onClick={async () => {
                            setCopied(true);
                            await navigator.clipboard.writeText(
                              scheduleData?.phone
                            );
                          }}
                        >
                          {copied ? "Copied" : "Copy"}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>
                )}
                {scheduleData?.locations === "video/web" && (
                  <FormControl>
                    <FormLabel fontWeight={"bold"}> Meeting Link</FormLabel>
                    <InputGroup
                      size="md"
                      w={{ base: "100%", md: "70%" }}
                      ml="-5px"
                    >
                      <Input isReadOnly={true} value={scheduleData?.link} />
                      <InputRightElement width="4.5rem">
                        <Button
                          h="1.75rem"
                          size="sm"
                          onClick={async () => {
                            setCopied(true);
                            await navigator.clipboard.writeText(
                              scheduleData?.link
                            );
                          }}
                        >
                          {copied ? "Copied" : "Copy"}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>
                )}
                {scheduleData?.locations === "in-person" && (
                  <FormControl>
                    <FormLabel fontWeight={"bold"}> Address</FormLabel>
                    <InputGroup
                      size="md"
                      w={{ base: "100%", md: "70%" }}
                      ml="-5px"
                    >
                      <Input
                        isReadOnly={true}
                        value={
                          scheduleData?.address &&
                          JSON.parse(scheduleData?.address)?.label
                        }
                      />
                      <InputRightElement width="4.5rem">
                        <Button
                          h="1.75rem"
                          size="sm"
                          onClick={async () => {
                            setCopied(true);
                            await navigator.clipboard.writeText(
                              scheduleData?.address &&
                                JSON.parse(scheduleData?.address)?.label
                            );
                          }}
                        >
                          {copied ? "Copied" : "Copy"}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>
                )}

                <Text fontWeight={"bold"} w="full" mt="2">
                  Interview Detail
                </Text>
                <Box
                  border="1px solid gray"
                  w={{ base: "100%", md: "70%" }}
                  p={{ base: "1", md: "2", lg: "3" }}
                  // ps="20"
                >
                  <HStack mt="2" gap={2} align="start">
                    <Avatar
                      name=""
                      src={scheduleData?.companyLogo}
                      size="md"
                      border="1px solid #ebebebb"
                    />
                    <VStack alignItems="start">
                      <Text
                        fontSize="16px"
                        fontWeight={"bold"}
                        color={"#646c9a"}
                      >
                        {scheduleData?.companyName ?? "N/A"}
                      </Text>
                      <Text
                        fontSize={"sm"}
                        fontWeight={"bold"}
                        color={"#646c9a"}
                      >
                        {scheduleData?.jobName}
                      </Text>
                      <Text fontSize={"xs"} color={"#646c9a"} mt="0 !important">
                        {scheduleData?.jobLocation.length
                          ? `${scheduleData?.jobLocation[0]}`
                          : "Remote"}
                        {scheduleData?.reqNumber &&
                          ` | Req#  ${scheduleData?.reqNumber}`}
                      </Text>
                    </VStack>
                  </HStack>
                  <HStack
                    alignItems="center"
                    mt="2"
                    gap={2}
                    align={"flex-start"}
                  ></HStack>
                </Box>

                {/* {scheduleData?.interviewType !== "Schedule with me" && (
                  <Flex direction="column" w="full">
                    <Text fontWeight="bold">Interview Type</Text>
                    <Text ms="10px">
                      {scheduleData?.interviewType === "initial-screening"
                        ? "Initial Screening"
                        : scheduleData?.interviewType === "technical-interview"
                        ? "Technical Interview"
                        : "Final Interview"}
                    </Text>
                  </Flex>
                )} */}

                <Text fontWeight={"bold"} w="full" mt="4">
                  Invitee Details
                </Text>
                {scheduleData?.inputs && (
                  <Box
                    border="1px solid gray"
                    w={{ base: "100%", md: "70%" }}
                    p={{ base: "1", md: "2", lg: "3" }}
                    // ps="20"
                  >
                    <VStack w="full">
                      {scheduleData?.inputs.map((data: any, index: any) => (
                        <React.Fragment key={index}>
                          <VStack w="80%" gap="1px">
                            <Flex
                              gap={{ base: "2px", md: "2" }}
                              align={{ base: "start", md: "center" }}
                              w="full"
                              direction={{ base: "column", md: "row" }}
                            >
                              <Text
                                fontSize="13px"
                                fontWeight="700"
                                // minW={{ base: "70px", md: "100px" }}
                                // maxW={{ base: "70px", md: "100px" }}
                              >
                                Email
                              </Text>
                              <Text fontSize="13px" wordBreak="break-word">
                                {data?.value}
                              </Text>
                            </Flex>
                            <Flex
                              gap={{ base: "2px", md: "2" }}
                              align={{ base: "start", md: "center" }}
                              w="full"
                              direction={{ base: "column", md: "row" }}
                            >
                              <Text
                                fontSize="13px"
                                fontWeight="700"
                                // minW={{ base: "70px", md: "100px" }}
                                // maxW={{ base: "70px", md: "100px" }}
                              >
                                Interview Type
                              </Text>
                              <Text fontSize="13px">{data?.option}</Text>
                            </Flex>
                          </VStack>
                          {index !== scheduleData.inputs.length - 1 && (
                            <Divider
                              orientation="horizontal"
                              borderBottom="1px solid black"
                            />
                          )}
                        </React.Fragment>
                      ))}
                    </VStack>
                  </Box>
                )}

                <Stack
                  w="full"
                  align={"flex-start"}
                  flexDir={{ base: "column", md: "row" }}
                >
                  <VStack w="full" align={"flex-start"}>
                    <VStack w="full" align={"flex-start"}>
                      <Text fontWeight={"bold"} fontSize="md" w="full" pl="1">
                        Pick a day
                      </Text>
                      <Box
                        maxW={anotherDay ? "96%" : { base: "100%", md: "70%" }}
                        w="full"
                      >
                        <CustomDatePicker
                          label="Select date"
                          selected={watch("date")}
                          onChange={onChangeDate}
                          minDate={new Date()}
                        />
                      </Box>
                    </VStack>
                    <FormControl isInvalid={timeSlotsError}>
                      <VStack w="full" align={"flex-start"}>
                        <Text
                          fontWeight={"bold"}
                          fontSize="md"
                          w="full"
                          pl="1"
                          mt="2"
                        >
                          Select Time Slots
                        </Text>
                        <Box
                          maxW={
                            anotherDay ? "96%" : { base: "100%", md: "70%" }
                          }
                          w="full"
                          maxH="200px"
                          h="200px"
                          overflow={"auto"}
                          p="2"
                        >
                          <CheckBoxButton
                            handleOptionSelection={handleOptionTimes}
                            options={optionstimes}
                            selectedOptions={selectedTimes}
                            width="full"
                          />
                        </Box>
                        {error?.time1 && (
                          <Text color="red" pl="2">
                            {error?.time1}
                          </Text>
                        )}
                      </VStack>
                    </FormControl>
                  </VStack>

                  <VStack
                    w="full"
                    align={"flex-start"}
                    display={anotherDay ? "flex" : "none"}
                    mt={{ base: "2 !important", md: "0px !important" }}
                  >
                    <FormControl isInvalid={timeSlots2Error}>
                      <VStack w="full" align="flex-start">
                        <Text fontWeight={"bold"} fontSize="md" w="full" pl="1">
                          Pick another day
                        </Text>
                        <Box
                          maxW={
                            anotherDay ? "96%" : { base: "100%", md: "70%" }
                          }
                          w="full"
                        >
                          <CustomDatePicker
                            label="Select date"
                            selected={watch("date2")}
                            onChange={onChangeDate2}
                            minDate={new Date()}
                          />
                          {error?.day2 && (
                            <Text color="red" pl="2">
                              {error?.day2}
                            </Text>
                          )}
                        </Box>
                      </VStack>
                      <VStack w="full" align={"flex-start"}>
                        <Text
                          fontWeight={"bold"}
                          fontSize="md"
                          w="full"
                          pl="1"
                          mt="2"
                        >
                          Select Time Slots
                        </Text>
                        <Box
                          maxW={
                            anotherDay ? "96%" : { base: "100%", md: "70%" }
                          }
                          w="full"
                          maxH="200px"
                          h="200px"
                          overflow={"auto"}
                          p="2"
                        >
                          <CheckBoxButton
                            handleOptionSelection={handleOptionTimes2}
                            options={optionstimes}
                            selectedOptions={selectedTimes2}
                            width="full"
                          />
                        </Box>
                        {error?.time2 && (
                          <Text color="red" pl="2">
                            {error?.time2}
                          </Text>
                        )}
                      </VStack>
                    </FormControl>
                  </VStack>
                </Stack>
              </VStack>
            </CardBody>
            <CardFooter flexWrap="wrap">
              <Flex
                mt="4 !important"
                direction={{ base: "column", sm: "row" }}
                align={{ base: "start", md: "center" }}
                gap={{ base: "10px", md: "24px" }}
                justify={"space-between"}
                w="full"
                my="2"
              >
                <Button
                  colorScheme="buttonPrimary"
                  w="150px"
                  onClick={onSubmit}
                  isLoading={scheduleLoading}
                  isDisabled={scheduleLoading}
                >
                  Reschedule
                </Button>
                <Button
                  colorScheme="buttonPrimary"
                  fontWeight={"bold"}
                  _hover={{ color: "white", bg: "#5867DD" }}
                  // leftIcon={<IoIosArrowBack />}
                  onClick={toggleAnotherDay}
                >
                  {anotherDay ? "Cancel" : "Add another day"}
                </Button>
              </Flex>
            </CardFooter>
          </Card>
        </VStack>
      )}
    </>
  );
};

export default MeetingDetails;
