import { create } from "zustand";

interface IUser {
  user: any;
  hasUnreadMessage: boolean;
  setUser: (data: any) => void;
  setHasUnreadMessage: (data: any) => void;
  userName: any;
  setUserName: (data: any) => void;
  profilepicture: any;
  setprofilePicture: (data: any) => void;
}
const username = localStorage.getItem("userName");

const initial_state = {
  user: username ? username : null,
  hasUnreadMessage: false,
  userName: null,
  profilepicture: null,
};
export const useUserStore = create<IUser>((set) => ({
  ...initial_state,
  setUser: (data: any) => {
    set((state) => {
      return {
        ...state,
        user: {
          ...data,
        },
      };
    });
  },
  setUserName: (data: any) => {
    set((state: any) => {
      return {
        ...state,
        userName: data,
      };
    });
  },

  setHasUnreadMessage: (data: any) => {
    set((state) => {
      return {
        ...state,
        hasUnreadMessage: data,
      };
    });
  },
  setprofilePicture: (data: any) => {
    set((state: any) => {
      return {
        ...state,
        profilepicture: data,
      };
    });
  },
}));
