import React, { useEffect, useRef, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { components } from "react-select";

import {
  Avatar,
  AvatarBadge,
  Box,
  Button,
  Card,
  Center,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Spinner,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";

import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { ref } from "firebase/storage";
import { storage } from "../../../../firebase";
import AWS from "aws-sdk";

import { getUserId, prefilledLocations } from "../../../../utils/helper";
import { GenericModal } from "../../../common/generic-modal";
import { useUserStore } from "../../../../store/useUser";

import {
  useFetchLocation,
  useUpdateLocation,
} from "../../../../service/education.service";
import { useAddProfileImage } from "../../../../service/candidate.service";

interface EditProfileGeneralProps {
  isOpen: any;
  onClose: any;
}
const EditProfileGeneral = ({ isOpen, onClose }: EditProfileGeneralProps) => {
  const {
    mutate: profileImageMutation,
    isLoading: profileImageLoading,
    data: profileImage,
  } = useAddProfileImage();
  const { user, setUser } = useUserStore();
  const [fullName, setFullName] = useState("");
  const googleAPI = process.env.REACT_APP_GOOGLE_API_KEY;
  const [error, setError] = useState({
    profilePictureError: "",
    nameError: "",
    locationError: "",
  });
  const [currentLocation, setCurrentLocation] = useState<any>(null);
  const [companyLogoLoading, setCompanyLogoLoading] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const toast = useToast();

  const handleFileInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file: any = event.target.files?.[0];
    if (!file) {
      return;
    }

    const allowedTypes = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "image/svg+xml",
    ];
    if (!allowedTypes.includes(file.type)) {
      toast({
        title: "Invalid File Type",
        description:
          " Please make sure to upload an image in PNG, JPG, JPEG, or SVG format.",
        status: "error",
        isClosable: true,
        position: "bottom",
        duration: 3000,
      });
      return;
    }
    const S3_BUCKET = process.env.REACT_APP_S3_BUCKET || "";
    const REGION = process.env.REACT_APP_REGION;

    AWS.config.update({
      accessKeyId: process.env.REACT_APP_accessKeyId,
      secretAccessKey: process.env.REACT_APP_secretAccessKey,
    });
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });
    const folder = "profile-images";
    const params = {
      Bucket: S3_BUCKET,
      Key: `${folder}/${file.name}`,
      Body: file,
    };

    try {
      const upload = await s3.putObject(params).promise();
      const url = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${folder}/${file.name}`;
      console.log("Upload URL:", url);
      console.log("upload", upload);
      profileImageMutation({ profilePicture: url, userId: user?.userId });
      setUser({ ...user, profilePicture: url });
      setCompanyLogoLoading(false);
    } catch (error: any) {
      console.error(error);
      setCompanyLogoLoading(false);
      toast({
        title: "Upload failed",
        description: "An error occurred while uploading the file.",
        status: "error",
        isClosable: true,
        position: "bottom",
        duration: 2000,
      });
    }
  };
  const {
    mutate: locationMutation,
    isLoading: locationLoading,
    data: locationData,
  } = useUpdateLocation();
  const userId = getUserId();
  const { isLoading: experienceListLoading, data: locationDataList } =
    useFetchLocation(userId);

  useEffect(() => {
    if (locationDataList?.userId) {
      setFullName(locationDataList.fullName);
      setCurrentLocation(
        JSON.parse(locationDataList?.userLocationDto?.address)
      );
    }
  }, [locationDataList]);
  const handleValidation = () => {
    let errors: any = {
      profilePictureError: "",
      nameError: "",
      locationError: "",
    };

    let formIsValid = true;
    if (!user?.profilePicture) {
      formIsValid = false;
      errors.profilePictureError = "Profile picture is required";
    }
    if (!fullName) {
      formIsValid = false;
      errors.nameError = "Full Name is required";
    }
    if (!currentLocation) {
      formIsValid = false;
      errors.locationError = "Location is required";
    }

    setError(errors);
    return formIsValid;
  };
  const onSubmit = async () => {
    const formDate = {
      userId,
      fullName: fullName.trim(),
      userLocationDto: {
        address: JSON.stringify(currentLocation),
        city: "",
        state: "",
      },
    };
    await locationMutation(formDate, {
      onSuccess: (resData) => {
        setUser({ ...user, fullName: fullName.trim() });
        onClose();
      },
    });
  };

  const CustomMenuList = (props: any) => {
    const handleSelectOption = (item: any) => {
      props.selectProps.onChange(item);
      props.selectProps.onMenuClose();
    };
    return (
      <components.MenuList {...props}>
        {props.options?.length > 0 ? (
          props.children
        ) : (
          <Flex direction="column" gap={1}>
            {prefilledLocations.map((item: any) => (
              <Text
                w="full"
                _hover={{ bg: "blue.50" }}
                padding="5px 10px"
                fontWeight="400"
                key={item?.value?.place_id}
                onClick={() => handleSelectOption(item)}
                cursor="pointer"
              >
                {item?.label}
              </Text>
            ))}
          </Flex>
        )}
      </components.MenuList>
    );
  };
  const handleEditButtonClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <>
      <GenericModal
        isOpen={isOpen}
        onClose={onClose}
        title={"Edit Personal Information"}
        onClickHandler={() => console.log("values")}
        buttonText={false ? "Loading" : "Save"}
        loadingButton={false || false}
        buttonShow={false}
      >
        <form
          style={{ width: "100%" }}
          onSubmit={(e) => {
            e.preventDefault();
            if (!!handleValidation()) {
              onSubmit();
            }
          }}
          onKeyDown={(e: any) => {
            if (e.key === "Enter") {
              e.preventDefault();
              if (!!handleValidation()) {
                onSubmit();
              }
            }
          }}
        >
          <Card p="4" w="full">
            <Box my="4">
              <Stack>
                <Card justifyContent={"space-around"} p="2">
                  <Flex align="start">
                    <Text
                      fontSize="16px"
                      color="gray.800"
                      fontWeight="500"
                      me="2px !important"
                    >
                      Upload Profile Picture
                    </Text>
                    <Box as="span" color="red" fontSize="md">
                      *
                    </Box>
                  </Flex>
                  <Center w="full" position="relative">
                    {profileImageLoading && (
                      <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="blue.500"
                        size="xl"
                        position="absolute"
                        zIndex="9"
                      />
                    )}
                    <Avatar size="xl" src={user?.profilePicture}>
                      <AvatarBadge
                        as={IconButton}
                        size="sm"
                        rounded="full"
                        top="-10px"
                        colorScheme="buttonPrimary"
                        aria-label="edit Image"
                        icon={<FiEdit />}
                        onClick={handleEditButtonClick}
                      />
                    </Avatar>

                    <input
                      ref={fileInputRef}
                      name="profilePicture"
                      type="file"
                      accept=".png, .jpeg, .jpg, .svg"
                      style={{ display: "none" }}
                      onChange={handleFileInputChange}
                      // onChange={handleFileChange}
                    />
                  </Center>
                  {error?.profilePictureError && (
                    <Text fontSize="sm" color="red.500">
                      {error?.profilePictureError}
                    </Text>
                  )}
                </Card>
                <FormControl isInvalid={Boolean(error?.nameError)}>
                  <Flex align="start">
                    <FormLabel htmlFor="fullName" me="2px !important">
                      Your Full Name
                    </FormLabel>
                    <Box as="span" color="red" fontSize="md">
                      *
                    </Box>
                  </Flex>
                  <Input
                    maxLength={255}
                    type="text"
                    placeholder="Enter full name"
                    maxW={{ base: "100%", md: "70%" }}
                    borderColor="grey"
                    value={fullName}
                    onChange={(e) => setFullName(e?.target?.value?.trimStart())}
                  />
                  {error?.nameError && (
                    <Text fontSize="sm" color="red.500">
                      {error?.nameError}
                    </Text>
                  )}
                </FormControl>
                <FormControl
                  maxW={{ base: "100%", md: "70%" }}
                  position="relative"
                >
                  <Flex align="start">
                    <FormLabel htmlFor="location" me="2px !important">
                      Current Location
                    </FormLabel>
                    <Box as="span" color="red" fontSize="md">
                      *
                    </Box>
                  </Flex>

                  <GooglePlacesAutocomplete
                    apiKey={googleAPI}
                    selectProps={{
                      value: currentLocation,
                      onChange: (newValue) => setCurrentLocation(newValue),
                      placeholder: "Enter the location...",
                      components: {
                        MenuList: CustomMenuList,
                      },
                      styles: {
                        input: (provided) => ({
                          ...provided,
                          color: "blue",
                        }),
                      },
                    }}
                  />

                  {error?.locationError && (
                    <Text fontSize="sm" color="red.500">
                      {error?.locationError}
                    </Text>
                  )}
                </FormControl>
              </Stack>
              <Button
                colorScheme="buttonPrimary"
                mt="4"
                type="submit"
                isLoading={locationLoading}
                isDisabled={locationLoading}
              >
                Save
              </Button>
            </Box>
          </Card>
        </form>
      </GenericModal>
    </>
  );
};

export default EditProfileGeneral;
