import React from "react";
import {
  useFetchCadidateScheldules,
  useFetchEmployerScheldules,
} from "../../../service/schedule.service";

const useInterview = () => {
  const { isLoading: fetchLoading, data: scheduleList } =
    useFetchEmployerScheldules();
  return { fetchLoading, scheduleList };
};

export default useInterview;
