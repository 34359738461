import { useState } from "react";
import {
  Box,
  Input,
  Heading,
  Stack,
  Card,
  VStack,
  HStack,
  Button,
  Text,
  Select,
  Divider,
  Progress,
  InputGroup,
  InputRightElement,
  InputRightAddon,
} from "@chakra-ui/react";
import { useWizard } from "react-use-wizard";

const Salary = ({
  handleSalary,
  salary,
  handleBonus,
  bonus,
  handleStock,
  stock,
  handleSubmit,
  jobLoading,
  state,
  jobUpdateLoading,
}: any) => {
  const { handleStep, previousStep, nextStep, activeStep, stepCount } =
    useWizard();
  const progress = ((activeStep + 1) / stepCount) * 100;
  const [errors, setErrors] = useState<any>({});
  const handleValidationSalary = () => {
    let errors = {
      salaryCurrency: "",
      salaryFrom: "",
      salaryTo: "",
      salaryDuration: "",
      bonusType: "",
      bonusTo: "",
      bonusFrom: "",
      bonusDuration: "",
      stockType: "",
      type: "",
      stockTo: "",
      stockFrom: "",
      stockDuration: "",
    };

    let formIsValid = true;

    if ((salary?.to || salary?.from) && !salary?.currency) {
      formIsValid = false;
      errors.salaryCurrency = "Please select a currency type.";
    }

    if (salary?.to && !salary?.from) {
      formIsValid = false;
      errors.salaryFrom = "Please enter a value.";
    }
    if (!salary?.to && salary?.from) {
      formIsValid = false;
      errors.salaryTo = "Please enter a value.";
    }
    if (!salary?.duration) {
      formIsValid = false;
      errors.salaryDuration = "Please select a duration.";
    }
    if ((bonus?.from || bonus?.to) && !bonus?.bonusType) {
      formIsValid = false;
      errors.bonusType = "Please select a bonus type.";
    }

    if (!bonus?.from && bonus?.to) {
      formIsValid = false;
      errors.bonusFrom = "Please enter a value.";
    }
    if (bonus?.from && !bonus?.to) {
      formIsValid = false;
      errors.bonusTo = "Please enter a value.";
    }
    if (!bonus?.duration) {
      formIsValid = false;
      errors.bonusDuration = "Please select a duration.";
    }
    if (!stock?.stockType) {
      formIsValid = false;
      errors.stockType = "Please select a stock type.";
    }
    if ((stock?.from || stock?.to) && !stock?.type) {
      formIsValid = false;
      errors.type = "Please select a  type.";
    }

    if (!stock?.from && stock?.to) {
      formIsValid = false;
      errors.stockFrom = "Please enter a value.";
    }
    if (stock?.from && !stock?.to) {
      formIsValid = false;
      errors.stockTo = "Please enter a value.";
    }
    if (!stock?.duration) {
      formIsValid = false;
      errors.stockDuration = "Please select a duration.";
    }

    setErrors(errors);
    return formIsValid;
  };

  return (
    <VStack w="full">
      <Card p="4" w="full">
        <Box>
          <Progress
            colorScheme="progressBar"
            size="md"
            w="full"
            borderRadius={"lg"}
            my="6"
            value={progress}
            mb={8}
          />
        </Box>
        <Box maxW={"800px"} my="4">
          <Stack>
            <VStack w="full" justify={"flex-start"}>
              <Heading size="md" w="full">
                Salary
              </Heading>
              <HStack w="full" align={"flex-start"}>
                <Select
                  isDisabled={false}
                  onChange={handleSalary}
                  size="xs"
                  h="38px"
                  borderRadius={"md"}
                  name="currency"
                  value={salary?.currency}
                >
                  <option value="usd">USD</option>
                </Select>
                {!salary?.currency && (
                  <Text textAlign={"center"} fontSize={"sm"} color={"red.500"}>
                    {errors["salaryCurrency"]}
                  </Text>
                )}
                <VStack w="full">
                  <Input
                    placeholder="Enter starting value"
                    type="text"
                    bg="white"
                    onChange={(e) => handleSalary(e)}
                    name="from"
                    value={salary?.from}
                    sx={{ "::placeholder": { fontSize: "14px" } }}
                  />
                  {!salary?.from && (
                    <Text
                      textAlign={"center"}
                      fontSize={"sm"}
                      color={"red.500"}
                    >
                      {errors["salaryFrom"]}
                    </Text>
                  )}
                </VStack>
                <VStack w="full">
                  <Input
                    type="text"
                    placeholder="Enter ending value"
                    bg="white"
                    onChange={(e) => handleSalary(e)}
                    name="to"
                    value={salary?.to}
                    sx={{ "::placeholder": { fontSize: "14px" } }}
                  />
                  {!salary?.to && (
                    <Text
                      textAlign={"center"}
                      fontSize={"sm"}
                      color={"red.500"}
                    >
                      {errors["salaryTo"]}
                    </Text>
                  )}
                </VStack>
                <Select
                  isDisabled={false}
                  size="xs"
                  h="38px"
                  borderRadius={"md"}
                  onChange={handleSalary}
                  name="duration"
                  value={salary?.duration}
                >
                  <option value="annually">Annually</option>
                  <option value="hourly">Hourly</option>
                  <option value="monthly">Monthly</option>
                  <option value="quarterly">Quarterly</option>
                  <option value="bi-annual">Bi-annual</option>
                </Select>
                {!salary?.duration && (
                  <Text textAlign={"center"} fontSize={"sm"} color={"red.500"}>
                    {errors["salaryDuration"]}
                  </Text>
                )}
              </HStack>
              <Divider mt="4 !important" />
              <Heading size="md" w="full">
                Bonus
              </Heading>
              <HStack w="full" align={"flex-start"}>
                <Select
                  isDisabled={false}
                  onChange={handleBonus}
                  size="xs"
                  h="38px"
                  borderRadius={"md"}
                  name="bonusType"
                  value={bonus?.bonusType}
                >
                  <option value="percentage">Percentage</option>
                  <option value="fixed">Fixed</option>
                </Select>
                <VStack w="full">
                  <InputGroup>
                    <Input
                      placeholder="Enter starting value"
                      type="text"
                      bg="white"
                      onChange={handleBonus}
                      name="from"
                      value={bonus?.from}
                      sx={{ "::placeholder": { fontSize: "14px" } }}
                    />
                    {bonus?.bonusType === "percentage" && (
                      <InputRightAddon>%</InputRightAddon>
                    )}
                  </InputGroup>
                  {!bonus?.from && (
                    <Text
                      textAlign={"center"}
                      fontSize={"sm"}
                      color={"red.500"}
                    >
                      {errors["bonusFrom"]}
                    </Text>
                  )}
                </VStack>
                <VStack w="full">
                  <InputGroup>
                    <Input
                      type="text"
                      placeholder="Enter ending value"
                      bg="white"
                      onChange={handleBonus}
                      name="to"
                      value={bonus?.to}
                      sx={{ "::placeholder": { fontSize: "14px" } }}
                    />
                    {bonus?.bonusType === "percentage" && (
                      <InputRightAddon>%</InputRightAddon>
                    )}
                  </InputGroup>
                  {!bonus?.to && (
                    <Text
                      textAlign={"center"}
                      fontSize={"sm"}
                      color={"red.500"}
                    >
                      {errors["bonusTo"]}
                    </Text>
                  )}
                </VStack>
                <Select
                  isDisabled={false}
                  size="xs"
                  h="38px"
                  borderRadius={"md"}
                  onChange={handleBonus}
                  name="duration"
                  value={bonus?.duration}
                >
                  <option value="annually">Annually</option>
                  <option value="hourly">Hourly</option>
                  <option value="monthly">Monthly</option>
                  <option value="quarterly">Quarterly</option>
                  <option value="bi-annual">Bi-annual</option>
                </Select>
              </HStack>
              <Divider mt="4 !important" />
              <Heading size="md" w="full">
                Stock
              </Heading>
              <HStack w="full" align={"flex-start"}>
                <Select
                  isDisabled={false}
                  onChange={handleStock}
                  size="xs"
                  h="38px"
                  borderRadius={"md"}
                  name="type"
                  value={stock?.type}
                >
                  <option value="RSU's">RSU's</option>
                  <option value="Stock">Stock</option>
                </Select>
                <Select
                  isDisabled={false}
                  onChange={handleStock}
                  size="xs"
                  h="38px"
                  borderRadius={"md"}
                  name="stockType"
                  value={stock?.stockType}
                >
                  <option value="percentage">Percentage</option>
                  <option value="fixed">Fixed</option>
                </Select>
                <VStack w="full">
                  <InputGroup>
                    <Input
                      placeholder="Enter starting value"
                      type="text"
                      bg="white"
                      onChange={handleStock}
                      name="from"
                      value={stock?.from}
                      sx={{ "::placeholder": { fontSize: "14px" } }}
                    />
                    {stock?.stockType === "percentage" && (
                      <InputRightAddon>%</InputRightAddon>
                    )}
                  </InputGroup>
                  {!stock?.from && (
                    <Text
                      textAlign={"center"}
                      fontSize={"sm"}
                      color={"red.500"}
                    >
                      {errors["stockFrom"]}
                    </Text>
                  )}
                </VStack>
                <VStack w="full">
                  <InputGroup>
                    <Input
                      type="text"
                      placeholder="Enter ending value"
                      bg="white"
                      onChange={handleStock}
                      name="to"
                      value={stock?.to}
                      sx={{ "::placeholder": { fontSize: "14px" } }}
                    />
                    {stock?.stockType === "percentage" && (
                      <InputRightAddon>%</InputRightAddon>
                    )}
                  </InputGroup>
                  {!stock?.to && (
                    <Text
                      textAlign={"center"}
                      fontSize={"sm"}
                      color={"red.500"}
                    >
                      {errors["stockTo"]}
                    </Text>
                  )}
                </VStack>
                <Select
                  isDisabled={false}
                  onChange={handleStock}
                  size="xs"
                  h="38px"
                  borderRadius={"md"}
                  name="duration"
                  value={stock?.duration}
                >
                  <option value="Annually grant - fixed amonth">
                    Annually grant - fixed amonth
                  </option>
                  <option value="Annually grant - variable amonth One-time grant">
                    Annually grant - variable amonth One-time grant
                  </option>
                </Select>
              </HStack>
            </VStack>
          </Stack>
        </Box>
        <HStack
          spacing={"24px"}
          justify={"space-between"}
          w="full"
          mt="6 !important"
        >
          <Button
            loadingText="Loading"
            color="#74788d"
            spinnerPlacement="end"
            size="md"
            fontWeight={"bold"}
            _hover={{ color: "white", bg: "#5867DD" }}
            // leftIcon={<IoIosArrowBack />}
            onClick={() => {
              previousStep();
            }}
          >
            Back
          </Button>
          <Button
            colorScheme="buttonPrimary"
            onClick={() => {
              if (handleValidationSalary()) {
                handleSubmit();
              }
            }}
            isLoading={jobLoading || jobUpdateLoading}
            isDisabled={jobLoading || jobUpdateLoading}
          >
            {state?.isEdit ? "Update" : "Save"}
          </Button>
        </HStack>
      </Card>
    </VStack>
  );
};

export default Salary;
