import { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import {
  Avatar,
  AvatarBadge,
  Badge,
  Button,
  Card,
  CardBody,
  Center,
  Divider,
  Flex,
  FormControl,
  HStack,
  Heading,
  IconButton,
  Input,
  Select,
  SkeletonCircle,
  Stack,
  Text,
  Tooltip,
  VStack,
  useToast,
} from "@chakra-ui/react";
import UserModal from "../userModal";

import { useFetch } from "../../../../utils/useFetch";
import { useAllTeamMembers } from "../../../../store/useStore";

import "./overview.css";

const Overview = ({
  onModalClose,
  onOpen,
  isOpen,
  handleEditButtonClick,
  handleFileInputChange,
  profilePicture,
  profileLogoLoading,
  fileInputRef,
  user,
  watch,
}: any) => {
  const { teamMembersList, fetchTeamList } = useAllTeamMembers();
  const [isEmailLoading, setIsEmailLoading] = useState(false);
  useEffect(() => {
    fetchTeamList();
  }, []);
  const [addTeamMemberEmail, setAddTeamMemberEmail] = useState("");
  const [fullName, setFullName] = useState<string>("");
  const [selectedRole, setSelectedRole] = useState<any>("");

  const [id, setId] = useState();

  const toast = useToast();
  const handleAddTeamMember = () => {
    if (!selectedRole && !addTeamMemberEmail && !fullName) {
      return toast({
        title: `Please Fill all fields`,
        description: "",
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });
    }
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const excludedEmailDomains = /@(?!gmail\.com|ymail\.com|yahoo\.com)/;
    if (
      !emailRegex.test(addTeamMemberEmail) ||
      !excludedEmailDomains.test(addTeamMemberEmail)
    ) {
      toast({
        title: `Invalid Email`,
        description: "Please Enter a Valid Work Email",
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });

      return;
    }
    if (!selectedRole) {
      return toast({
        title: `Please Select a Role`,
        description: "Role can not be empty",
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });
    }
    if (!fullName) {
      return toast({
        title: `Please Enter Full Name`,
        description: "",
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });
    }
    const isUserEmail = user?.email === addTeamMemberEmail;
    // Check if the email is already in the teamMembersList
    const isEmailInList = teamMembersList.some(
      (member) => member.email === addTeamMemberEmail
    );
    if (isUserEmail) {
      toast({
        title: `You cannot invite yourself!`,
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });
      return;
    }
    if (isEmailInList) {
      toast({
        title: `Email Already Added`,
        description: "This user is already added as a Team Member",
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });
      return;
    }
    if (addTeamMemberEmail) {
      const emailParts = user?.email?.split("@");
      const inputMailParts = addTeamMemberEmail.split("@");
      if (emailParts.length === 2) {
        const [username, domain] = emailParts;
        const [InputUsername, Inputdomain] = inputMailParts;
        if (domain !== Inputdomain) {
          toast({
            title: `Cannot Add Email with Different Domain`,
            description:
              "Please add an email associated with your company's domain.",
            status: "error",
            variant: "top-accent",
            isClosable: true,
          });
          return;
        }
      }
    }
    setIsEmailLoading(true);
    useFetch
      .post(`users/employer/invite-team-member`, {
        email: addTeamMemberEmail.trim(),
        roleName: selectedRole,
        fullName: fullName?.trimLeft(),
      })
      .then(() => {
        setIsEmailLoading(false);
        fetchTeamList();
        setAddTeamMemberEmail("");
        setFullName("");
        setSelectedRole("");
        toast({
          title: `User invited successfully `,
          description: "",
          status: "success",
          variant: "top-accent",
          isClosable: true,
        });
      })
      .catch((error) => {
        setIsEmailLoading(false);

        console.log("err", error);
        toast({
          title: `${error.response.data}`,
          description: "",
          status: "error",
          variant: "top-accent",
          isClosable: true,
        });
      });
  };

  return (
    <VStack
      w={["100%", "100%", "100%", "33%"]}
      h="100%"
      borderRadius={"md"}
      boxShadow="lg"
      spacing={6}
    >
      <Card h="86%" w="100%" overflow="scroll">
        <CardBody>
          <FormControl id="userName">
            <Stack direction={["column"]} spacing={6}>
              <Center w="full">
                {profileLogoLoading ? (
                  <SkeletonCircle size="64px" />
                ) : (
                  <Avatar size="lg" src={profilePicture}>
                    <AvatarBadge
                      as={IconButton}
                      size="sm"
                      rounded="full"
                      top="-10px"
                      colorScheme="buttonPrimary"
                      aria-label="edit Image"
                      icon={<FiEdit />}
                      onClick={handleEditButtonClick}
                    />
                  </Avatar>
                )}

                <input
                  ref={fileInputRef}
                  type="file"
                  // accept="image/*"
                  accept="image/png, image/jpeg, image/jpg, image/svg"
                  style={{ display: "none" }}
                  onChange={handleFileInputChange}
                />
              </Center>
              <Text
                textAlign={"center"}
                // fontSize="lg"
                fontWeight={"bold"}
                color="#48465b"
                fontSize={{
                  base: "16.8px",
                  md: "16.8px",
                  lg: "18.2px",
                  xl: "18.2px",
                }}
                whiteSpace={"nowrap"}
                mb="6px !important"
                _hover={{ color: "#5867DD" }}
              >
                <Tooltip label={watch("fullName") || ""} hasArrow>
                  {(watch("fullName")?.length > 30
                    ? watch("fullName")?.slice(0, 30) + "..."
                    : watch("fullName")) || ""}
                </Tooltip>
              </Text>
              <Flex w="full" mt="1 !important">
                <Badge
                  ml="1"
                  fontSize="14px"
                  colorScheme="buttonPrimary"
                  p="1"
                  color={"#5867DD"}
                  px="2"
                  bg={"#5d78ff1a"}
                  textTransform={"capitalize"}
                  w="fit-content"
                  margin={"auto"}
                >
                  {(watch("roleName")?.length > 30
                    ? watch("roleName")?.slice(0, 30) + "..."
                    : watch("roleName")) || ""}
                </Badge>
              </Flex>

              <HStack w="full" justify={"space-between"}>
                <Text fontWeight={"bold"} fontSize={"20px"}>
                  {" "}
                  Details
                </Text>
                <Button size="sm" colorScheme="buttonPrimary" onClick={onOpen}>
                  Edit
                </Button>
              </HStack>
              <VStack w="full" spacing={4} overflow="scroll">
                <VStack w="full" align={"flex-start"}>
                  <Text fontWeight={"bold"}> Company name</Text>
                  <Text mt="0px !important" color="gray">
                    {(watch("companyName")?.length > 30
                      ? watch("companyName")?.slice(0, 30) + "..."
                      : watch("companyName")) || ""}
                  </Text>
                </VStack>
                <VStack w="full" align={"flex-start"}>
                  <Text fontWeight={"bold"}> Email</Text>
                  <Text mt="0px !important" color="gray">
                    {watch("email") || ""}
                  </Text>
                </VStack>
                <VStack w="full" align={"flex-start"}>
                  <Text fontWeight={"bold"}> Your Title</Text>
                  <Text mt="0px !important" color="gray">
                    {watch("title") || ""}
                  </Text>
                </VStack>
                <VStack w="full" align={"flex-start"}>
                  <Text fontWeight={"bold"}>Your Role</Text>
                  <Text mt="0px !important" color="gray">
                    {watch("roleName") || ""}
                  </Text>
                </VStack>
                <VStack w="full" align={"flex-start"}>
                  <Text fontWeight={"bold"}> Linkedin URL</Text>
                  <Text mt="0px !important" color="gray">
                    <Tooltip hasArrow label={watch("linkedinUrl") || ""}>
                      {(watch("linkedinUrl")?.length > 30
                        ? watch("linkedinUrl")?.slice(0, 30) + "..."
                        : watch("linkedinUrl")) || ""}
                    </Tooltip>
                  </Text>
                </VStack>
                <VStack w="full" align={"flex-start"}>
                  <Text fontWeight={"bold"}> Calendar URL</Text>
                  <Text mt="0px !important" color="gray">
                    <Tooltip hasArrow label={watch("calandarUrl") || ""}>
                      {(watch("calandarUrl")?.length > 30
                        ? watch("calandarUrl")?.slice(0, 30) + "..."
                        : watch("calandarUrl")) || ""}
                    </Tooltip>
                  </Text>
                </VStack>
              </VStack>
            </Stack>
          </FormControl>
        </CardBody>
      </Card>
      {user?.isParent && (
        <Card
          h="500"
          w="100%"
          mb={{ base: "4 !important", lg: "0 !important" }}
        >
          <CardBody>
            <VStack w="full" align={"flex-start"}>
              <Text fontWeight={"bold"} size={"lg"}>
                Add Team Members to Collaborate
              </Text>
              <Divider orientation="horizontal" />
              <Text color={"gray"} fontSize="sm">
                Enter the email address of the team member you wish to add.
              </Text>
              <Flex align="center" gap={2}>
                <Input
                  // w={{ md: "222px", lg: "300px", base:"217px" }}
                  flex={5}
                  type="text"
                  placeholder="Enter email"
                  onChange={(e) => setAddTeamMemberEmail(e.target.value.trim())}
                  value={addTeamMemberEmail}
                  className="chakra-input"
                />
              </Flex>
              <Flex
                align="end"
                gap={2}
                pt="5px"
                direction={{ base: "column", md: "row" }}
                wrap="wrap"
              >
                <FormControl isRequired flex={1}>
                  <Heading fontWeight={"bold"} fontSize="md" pb="10px">
                    Enter Name
                  </Heading>
                  <Input
                    type="text"
                    placeholder="Full Name"
                    onChange={(e) => setFullName(e.target.value.trimLeft())}
                    value={fullName}
                    className="chakra-input"
                  />
                </FormControl>
                <FormControl isRequired flex={1}>
                  <Heading fontWeight={"bold"} fontSize="md" pb="10px">
                    Select Role
                  </Heading>
                  <Select
                    isDisabled={false}
                    size="md"
                    h="38px"
                    // w={{ base: "227px", lg: "260px" }}
                    className="chakra-select"
                    placeholder="Choose Option"
                    value={selectedRole}
                    onChange={(e) => setSelectedRole(e.target.value)}
                  >
                    <option value="Interviewer">Interviewer</option>
                    <option value="Recruiter">Recruiter</option>
                    <option value="Human Resources">Human Resources</option>
                    <option value="Coordinator">Coordinator</option>
                    <option value="Project Manager">Project Manager</option>
                    <option value="Administrator">Administrator</option>
                  </Select>
                  {/* </HStack> */}
                  {/* {isSubmitted && (
                      <FormErrorMessage>
                        {errors?.role?.message}
                      </FormErrorMessage>
                    )}*/}
                </FormControl>
              </Flex>
              <Button
                size="sm"
                // flex={1}
                // rightIcon={<Icon as={RiSendPlaneFill} />}
                isLoading={isEmailLoading}
                width="auto"
                p="20px"
                bg="blue.500"
                color="white"
                colorScheme="black"
                onClick={handleAddTeamMember}
              >
                Send
              </Button>
            </VStack>
          </CardBody>
        </Card>
      )}

      {isOpen && (
        <UserModal
          onModalClose={onModalClose}
          onOpen={onOpen}
          isOpen={isOpen}
        />
      )}
    </VStack>
  );
};

export default Overview;
