import {
  Button,
  Card,
  CardBody,
  Heading,
  HStack,
  Stack,
  VStack,
  Avatar,
  Badge,
  Box,
  Flex,
  Text,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  Tag,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Textarea,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { GoLocation } from "react-icons/go";
import { RiThumbUpFill, RiThumbDownFill, RiTrophyLine } from "react-icons/ri";
import { AiFillTags } from "react-icons/ai";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import ScheduleMeeting from "../../../schedule-meeting";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getMonthsAndYears, getUserId } from "../../../../utils/helper";
import moment from "moment";
import { useFetchUserUnassignedJobs } from "../../../../service/helper.service";
import { useWidrawRequest } from "../../../../service/schedule.service";
import {
  useArchiveStatusEmployerSide,
  useFetchCandidatesById,
  useLikedStatusEmployerSide,
} from "../../../../service/candidate.service";
import LoadingScreen from "../../../common/loadingScreen";
import { FaPlus } from "react-icons/fa";
import { useFetch } from "../../../../utils/useFetch";
import TalentOpportunities from "../talent-opportunities/talentOpportunities";
import RadioGroup from "../../../common/radioGroup";

import "./talent-detail.css";
const reasonOptions = [
  " Not a fit for the role",
  "Insufficient experience",
  "Overqualified",
  "Position filled",
  " Other (please specify)",
];
const TalenDetail = () => {
  const {
    isOpen: isOpenLinkedInModal,
    onOpen: onOpenLinkedInModal,
    onClose: onCloseLinkedInModal,
  } = useDisclosure();
  const [linkedIn, setLinkedIn] = useState("");
  const toast = useToast();
  const { state } = useLocation();
  const userId: any = getUserId();
  const [reason, setReason] = useState("");
  const [valueReason, setValueReason] = useState("");
  const [errors, setErrors] = useState({
    reason: "",
    valueReason: "",
  });
  const [isJobRejected, setIsJobRejected] = useState(false);
  const [showReason, setShowReason] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [updatedStage, setUpdatedStage] = useState({ id: "", value: "" });
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [menuOpenId, setMenuOpenId] = useState("");
  const [jobToBeUpdated, setJobToBeUpdated] = useState<any>();
  const [selectedDate, setSelectedDate] = useState<any>({ id: "", value: "" });
  const [searchJob, setSearchJob] = useState("");
  const [jobResult, setJobResult] = useState([]);
  const [showJobResult, setShowJobResult] = useState(false);
  const [selectedJob, setSelectedJob] = useState<any>();
  const [appliedJobList, setAppliedJobList] = useState([]);
  const [archievedJobList, setArchievedJobList] = useState<any>([]);
  const {
    isOpen: isAddJobModalOpen,
    onOpen: onAddJobModalOpen,
    onClose: onAddJobModalClose,
  } = useDisclosure();

  const [jobId, setJobId] = useState("");
  const [arvhieveJobId, setArvhieveJobId] = useState("");
  const {
    mutate: widrawMutation,
    isLoading: widrawLoading,
    data: widrawData,
  } = useWidrawRequest({});
  const {
    isFetching: candidateLoading,
    data: candidateData,
    refetch: refetchCandidate,
  } = useFetchCandidatesById({
    candidateId: state?.userId,
    jobId: state?.jobId ? state.jobId : null,
  });
  const {
    mutate: archiveStatusMutate,
    isLoading: archiveStatusLoading,
    data: archiveStatus,
  } = useArchiveStatusEmployerSide();
  const {
    mutate: likedStatusMutate,
    isLoading: likedStatusLoading,
    data: likedStatus,
  } = useLikedStatusEmployerSide();
  const {
    isLoading: loadingUnAssigned,
    data: unAssignedJobListOption,
    refetch: refetchUnAssignedJobs,
  } = useFetchUserUnassignedJobs(candidateData?.userId);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenAlert2,
    onOpen: onOpenAlert2,
    onClose: onCloseAlert2,
  } = useDisclosure();
  const {
    isOpen: isOpenAlert3,
    onOpen: onOpenAlert3,
    onClose: onCloseAlert3,
  } = useDisclosure();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const {
    isOpen: isOpenAlert,
    onOpen: onOpenAlert,
    onClose: onCloseAlert,
  } = useDisclosure();
  const {
    isOpen: isArhiveOpenAlert,
    onOpen: onArhiveOpenAlert,
    onClose: onArhiveCloseAlert,
  } = useDisclosure();
  const cancelRef: any = React.useRef();
  const cancelRef2: any = React.useRef();
  const cancelRef3: any = React.useRef();
  useEffect(() => {
    refetchCandidate();
  }, []);

  interface userLocationType {
    address: any;
    city: string;
    country: string;
    id: string;
    state: string;
  }

  const emptyuserLocationType = {
    address: "",
    city: "",
    country: "",
    id: "",
    state: "",
  };
  const locationUser: userLocationType = candidateData?.userLocation
    ? candidateData?.userLocation
    : emptyuserLocationType;

  const downloadResume = async (userId: string) => {
    try {
      const response = await useFetch.get(
        `/users/talent/resume?userId=${userId}`
      );

      if (
        response.data.body.resume === "" ||
        response.data.body.resume === null
      ) {
        useFetch
          .get(`/users/talent/linkedin-profile?userId=${userId}`)
          .then((res) => {
            setLinkedIn(res.data);
            onOpenLinkedInModal();
          })
          .catch((error) => {
            console.log("error", error);
          });
      } else {
        let fileUrl = response.data.body.resume;
        const downloadLink = document.createElement("a");
        downloadLink.href = fileUrl;
        downloadLink.target = "_blank";
        downloadLink.download = "downloaded-file.pdf";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    } catch (error) {
      console.error("Error downloading resume:", error);
    }
  };
  const handleSubmitStage = () => {
    const BODY = {
      candidateId: candidateData?.userId,
      jobId: updatedStage?.id,
      stagingLevel: updatedStage?.value,
      modifyTime: selectedDate?.value !== "" ? selectedDate.value : new Date(),
    };
    try {
      useFetch
        .put(`jobs/apply/candidate-application-stage`, BODY)
        .then((res) => {
          setMenuIsOpen(false);
          fetchCandidateJobs();
          toast({
            title: "Stage Updated Successfully",
            description: "",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setUpdatedStage({ id: "", value: "" });
          setJobToBeUpdated("");
          setSelectedDate({ id: "", value: new Date() });
        })
        .catch((error) => {
          console.log("errror", error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearchJob = (value: any) => {
    setSearchJob(value);
    if (isFocused) {
      try {
        useFetch
          .get(
            `/jobs/all-jobs-for-employer?employerId=${userId}&query=${value}`
          )
          .then((res) => {
            setShowJobResult(true);
            setJobResult(res?.data);
          })
          .catch((error) => {
            console.log("errror", error);
          });
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleFocusBlur = (value: boolean) => {
    setIsFocused(value);
  };
  const handleSubmitData = () => {
    if (selectedJob) {
      if (appliedJobList?.some((job: any) => job?.id === selectedJob?.id)) {
        toast({
          title: "This Job is Already Assigned to this Candidate",
          description: "",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } else {
        try {
          const BODY = {
            candidateId: candidateData?.userId,
            jobId: selectedJob?.id,
          };
          useFetch
            .post(`users/employer/employer-interest-candidate`, BODY)
            .then((res) => {
              onAddJobModalClose();
              fetchCandidateJobs();
              setSearchJob("");
            })
            .catch((error) => {
              console.log(error);
            });
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      toast({
        title: "Please Select a Job",
        description: "",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  function fetchArchieveCandidateJobs() {
    try {
      useFetch
        .get(`/jobs/user-archive-job-list?candidateId=${candidateData?.userId}`)
        .then((response) => {
          setArchievedJobList(response?.data);
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.log(error);
    }
  }
  function fetchCandidateJobs() {
    try {
      useFetch
        .get(
          `/users/employer/candidate-application-by-user-company?candidateId=${candidateData?.userId}`
        )
        .then((response) => {
          setAppliedJobList(response?.data);
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.log(error);
    }
  }

  const handleRejectCandidate = (id: any) => {
    let errors: any = {
      reason: "",
      valueReason: "",
    };

    let formIsValid = true;
    if (reason.includes("Other") && valueReason === "") {
      formIsValid = false;
      errors.valueReason = "Please Enter reason for rejection";
    }

    setErrors(errors);
    if (!formIsValid) {
      return;
    } else {
      setIsJobRejected(true);
      const BODY = {
        candidateId: candidateData?.userId,
        jobId: id,
        reason: reason && reason.includes("Other") ? valueReason : reason || "",
      };

      useFetch
        .delete(`users/employer/remove-candidate-from-job`, { data: BODY })
        .then((res) => {
          setIsJobRejected(false);
          onCloseAlert3();
          navigate(-1);
          toast({
            title: "Candidate Rejected Successfully",
            description:
              "The candidate profile has been archived for future reference.",
            status: "success",
            variant: "top-accent",
            isClosable: true,
          });
        })
        .catch((error) => {
          setIsJobRejected(false);
          toast({
            title: "Failed to Reject Candidate",
            description: `${error.response.data}`,
            status: "error",
            variant: "top-accent",
            isClosable: true,
          });
        });
    }
  };

  useEffect(() => {
    fetchCandidateJobs();
    fetchArchieveCandidateJobs();
  }, [candidateData]);

  return (
    <>
      {candidateLoading ? (
        <LoadingScreen />
      ) : (
        <VStack w="full" spacing={6}>
          <Card boxShadow={"none"} borderRadius="none" w="full" bg="#f7f6f8">
            <CardBody py="2.5 !important">
              <Stack
                direction="row"
                spacing={4}
                align="center"
                justify="space-between"
              >
                <Heading
                  size="sm"
                  color={"#959cb6"}
                  _hover={{ color: "#5D78FF" }}
                >
                  Candidate Details
                </Heading>
                <Button
                  loadingText="Loading"
                  color="#74788d"
                  spinnerPlacement="end"
                  size="sm"
                  fontWeight={"bold"}
                  _hover={{ color: "white", bg: "#5867DD" }}
                  // leftIcon={<IoIosArrowBack />}
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button>
              </Stack>
            </CardBody>
          </Card>
          <VStack
            w="full"
            spacing={6}
            p="4"
            py="8"
            bg="#f0f1f7"
            gap={"4"}
            mt="0px !important"
          >
            <Card w="full" borderRadius={"sm"}>
              <CardBody py="8">
                <Flex flex="1" gap="4" alignItems="flex-start">
                  <Avatar
                    name=""
                    src={candidateData?.profilePicture}
                    size={{ base: "md", md: "xl", lg: "xl" }}
                  />

                  <Box w="full">
                    <Heading
                      fontSize={{
                        base: "16.8px",
                        md: "16.8px",
                        lg: "18.2px",
                        xl: "18.2px",
                      }}
                      fontWeight={"500"}
                      _hover={{ color: "#5867DD" }}
                    >
                      {candidateData?.fullName}
                    </Heading>
                    <Text
                      fontSize={{ base: "13px", md: "13px" }}
                      fontWeight="bold"
                      color={"#646c9a"}
                      mt="1"
                    >
                      {candidateData?.jobTitle}

                      {candidateData?.companyName &&
                        `  @ ${candidateData?.companyName}`}
                    </Text>
                    <Text
                      fontSize={{ base: "13px", md: "13px" }}
                      color={"#646c9a"}
                      mt="1"
                    >
                      {candidateData?.latestDegree}{" "}
                      {candidateData?.schoolName &&
                        `  @ ${candidateData?.schoolName}`}
                    </Text>
                    <HStack align={"center"} mt="1">
                      <Icon as={GoLocation} mr="1" color={"blue.500"} />
                      <Text
                        fontSize={{ base: "13px", md: "13px" }}
                        color={"#646c9a"}
                        ml="0px !important"
                      >
                        {locationUser?.address &&
                          JSON.parse(locationUser?.address)?.label}
                      </Text>
                      {candidateData?.willingToRelocate && (
                        <Badge
                          ml="1"
                          fontSize="10px"
                          colorScheme="buttonPrimary"
                          p="1"
                          color={"#5867DD"}
                          px="2"
                          bg={"#5d78ff1a"}
                          textTransform={"capitalize"}
                        >
                          willing to relocate
                        </Badge>
                      )}
                    </HStack>
                    <Text
                      fontSize={{ base: "13px", md: "13px" }}
                      mt="1"
                      p="1"
                      maxW="700px"
                      color="#595d6e"
                    >
                      {candidateData?.shortBio}
                    </Text>
                  </Box>
                </Flex>
              </CardBody>
            </Card>
            <Stack
              w="full"
              alignItems={"flex-start"}
              flexDir={{ base: "column", "2xl": "row" }}
              mt="2 !important"
              gap={"4"}
            >
              <VStack
                w={{ base: "100%", "2xl": "40%" }}
                mr={{ md: 0, "2xl": 6 }}
                gap={"4"}
              >
                {/* {pathname.includes("search") && ( */}
                <Card w="full">
                  <CardBody w="full">
                    <Flex align="center" justify="center" w="full">
                      <Flex
                        direction="column"
                        align="center"
                        w={{ base: "100%", md: "50%", lg: "80%" }}
                        gap="2"
                      >
                        <HStack
                          w="full"
                          justify="space-between"
                          px={{ base: "5px", md: "20px" }}
                        >
                          <Text>Opportunities ({appliedJobList?.length})</Text>{" "}
                          <Flex
                            color="#5d78ff"
                            align="center"
                            fontWeight="900"
                            fontSize="14px"
                            cursor="pointer"
                            onClick={onAddJobModalOpen}
                          >
                            <Icon as={FaPlus} />
                            <Text textDecor="underline">Add</Text>
                          </Flex>
                        </HStack>
                        <Flex
                          px={{ base: "5px", md: "20px" }}
                          direction="column"
                          gap="5px"
                          maxH="140px"
                          overflowY="auto"

                          // w={{ base: "290px", md: "400px", "2xl": "full" }}
                        >
                          {appliedJobList &&
                            appliedJobList.map((job: any, index: number) => (
                              <TalentOpportunities
                                job={job}
                                updatedStage={updatedStage}
                                setUpdatedStage={setUpdatedStage}
                                jobToBeUpdated={jobToBeUpdated}
                                setJobToBeUpdated={setJobToBeUpdated}
                                selectedDate={selectedDate}
                                setSelectedDate={setSelectedDate}
                                handleSubmitStage={handleSubmitStage}
                                menuIsOpen={menuIsOpen}
                                setMenuIsOpen={setMenuIsOpen}
                                menuOpenId={menuOpenId}
                                setMenuOpenId={setMenuOpenId}
                                key={index}
                              />
                            ))}
                        </Flex>
                      </Flex>
                    </Flex>
                  </CardBody>
                </Card>
                {/* )} */}
                <Card w="full">
                  <CardBody p="5px">
                    {candidateData?.isInterested === true &&
                    (pathname.includes("liked") ||
                      pathname.includes("/jobs/view-applicant")) ? (
                      candidateData?.isArchived ? (
                        <HStack w="full" justify="center">
                          <Button
                            leftIcon={<RiThumbDownFill />}
                            // bg="white"
                            // borderColor="#fd397a"
                            // color="#fd397a"
                            variant="outline"
                            my="1"
                            color="white"
                            colorScheme="blue"
                            bg="#4361EE"
                            size={{ base: "xs", sm: "sm", "2xl": "md" }}
                            _hover={{ color: "white", bg: "#5570ef" }}
                            isLoading={archiveStatusLoading}
                            isDisabled={archiveStatusLoading}
                            onClick={() => {
                              archiveStatusMutate(
                                {
                                  candidateId: candidateData?.userId,
                                  jobId: candidateData?.jobId,
                                },
                                {
                                  onSuccess: () => {
                                    refetchCandidate();
                                    fetchCandidateJobs();
                                    onArhiveCloseAlert();
                                    setArvhieveJobId("");
                                    navigate(-1);
                                  },
                                }
                              );
                            }}
                          >
                            Unarchive
                          </Button>
                          <Button
                            colorScheme="buttonPrimary"
                            variant="solid"
                            my="1"
                            size={{ base: "xs", sm: "sm", "2xl": "md" }}
                            onClick={() => navigate(`/chat`, { state })}
                          >
                            Message
                          </Button>{" "}
                        </HStack>
                      ) : (
                        <>
                          <Stack direction="row" spacing={4} justify="center">
                            <HStack
                              flexDirection={{
                                base: "column",
                                sm: "row",
                              }}
                              w="full"
                              align="center"
                              justify="center"
                            >
                              {/* <Button
                              colorScheme="buttonPrimary"
                              variant="solid"
                              my="1"
                              size={{ base: "xs", sm: "sm", "2xl": "md" }}
                              onClick={() => navigate(`/chat`, { state })}
                            >
                              Message
                            </Button> */}
                              <Button
                                colorScheme="red"
                                // variant="outline"
                                my="1"
                                // color="white"
                                // bg="#fd397a"
                                size={{ base: "xs", sm: "sm", "2xl": "md" }}
                                // _hover={{ color: "white", bg: "#F51B63" }}
                                onClick={() => {
                                  onOpenAlert3();
                                }}
                              >
                                Reject Candidate
                              </Button>
                              <HStack>
                                {" "}
                                {candidateData?.isInterviewScheduled ? (
                                  <>
                                    <Button
                                      colorScheme="buttonPrimary"
                                      variant="solid"
                                      my="1"
                                      size={{
                                        base: "xs",
                                        sm: "sm",
                                        "2xl": "md",
                                      }}
                                      isLoading={widrawLoading}
                                      isDisabled={widrawLoading}
                                      onClick={() => {
                                        onOpenAlert2();
                                      }}
                                    >
                                      Withdraw Request
                                    </Button>

                                    <AlertDialog
                                      isOpen={isOpenAlert2}
                                      leastDestructiveRef={cancelRef2}
                                      onClose={onCloseAlert2}
                                    >
                                      <AlertDialogOverlay>
                                        <AlertDialogContent>
                                          <AlertDialogHeader
                                            fontSize="lg"
                                            fontWeight="bold"
                                          >
                                            Withdraw Request
                                          </AlertDialogHeader>

                                          <AlertDialogBody>
                                            Are you sure to withdraw interview
                                            schedule request ? You can't undo
                                            this action afterwards.
                                          </AlertDialogBody>

                                          <AlertDialogFooter>
                                            <Button onClick={onCloseAlert2}>
                                              Cancel
                                            </Button>
                                            <Button
                                              colorScheme="customPrimary"
                                              ml={3}
                                              onClick={() => {
                                                widrawMutation(
                                                  {
                                                    jobId: candidateData?.jobId,
                                                    candidateId:
                                                      candidateData?.userId,
                                                  },
                                                  {
                                                    onSuccess: () => {
                                                      onCloseAlert2();
                                                      navigate("/liked");
                                                    },
                                                  }
                                                );
                                              }}
                                            >
                                              Withdraw
                                            </Button>
                                          </AlertDialogFooter>
                                        </AlertDialogContent>
                                      </AlertDialogOverlay>
                                    </AlertDialog>
                                  </>
                                ) : (
                                  <Button
                                    colorScheme="buttonPrimary"
                                    variant="solid"
                                    my="1"
                                    size={{ base: "xs", sm: "sm", "2xl": "md" }}
                                    onClick={() => onOpen()}
                                  >
                                    Schedule an Interview
                                  </Button>
                                )}
                                <HStack>
                                  <Menu>
                                    <MenuButton
                                      as={IconButton}
                                      variant="ghost"
                                      aria-label="See menu"
                                      color="#9382dd"
                                      height={"32px"}
                                      // isDisabled={
                                      //   candidateData?.isInterviewScheduled
                                      // }
                                      my="1"
                                      _hover={{
                                        color: "#5867DD !important",
                                        bg: "#5d78ff1a",
                                      }}
                                      icon={
                                        <BiDotsHorizontalRounded size="30px" />
                                      }
                                    />
                                    <MenuList>
                                      <MenuItem
                                        onClick={() =>
                                          navigate(`/chat`, { state })
                                        }
                                      >
                                        Message Candidate
                                      </MenuItem>
                                      <MenuItem
                                        onClick={() => {
                                          downloadResume(candidateData?.userId);
                                        }}
                                      >
                                        Download Resume
                                      </MenuItem>
                                    </MenuList>
                                  </Menu>
                                </HStack>
                              </HStack>
                            </HStack>
                          </Stack>
                        </>
                      )
                    ) : (
                      <Stack direction="row" spacing={4} justify="center">
                        <HStack align="center">
                          {" "}
                          <>
                            <Button
                              leftIcon={<RiThumbUpFill />}
                              colorScheme="green"
                              variant="solid"
                              my="1"
                              isLoading={likedStatusLoading}
                              isDisabled={likedStatusLoading}
                              size={{ base: "xs", sm: "sm", "2xl": "md" }}
                              onClick={() => {
                                onOpenAlert();
                              }}
                            >
                              I'm Interested
                            </Button>

                            <AlertDialog
                              isOpen={isOpenAlert}
                              leastDestructiveRef={cancelRef}
                              onClose={onCloseAlert}
                            >
                              <AlertDialogOverlay>
                                <AlertDialogContent>
                                  <AlertDialogHeader
                                    fontSize="lg"
                                    fontWeight="bold"
                                  >
                                    Select Job
                                  </AlertDialogHeader>

                                  <AlertDialogBody>
                                    <Select
                                      isDisabled={false}
                                      color="#646C9A"
                                      size="xs"
                                      h="42px"
                                      borderRadius="md"
                                      name="selectJob"
                                      mr="2"
                                      onChange={(
                                        event: React.ChangeEvent<
                                          HTMLInputElement | HTMLSelectElement
                                        >
                                      ) => {
                                        setJobId(event.target.value);
                                      }}
                                      value={jobId}
                                      placeholder="Please Select a Job"
                                      className="custom-select"
                                    >
                                      {unAssignedJobListOption?.map(
                                        (item: any) => {
                                          return (
                                            <option value={item?.id}>
                                              {item?.name?.length > 50
                                                ? item?.name.slice(0, 50) +
                                                  "..."
                                                : item?.name}
                                            </option>
                                          );
                                        }
                                      )}
                                    </Select>
                                  </AlertDialogBody>

                                  <AlertDialogFooter>
                                    <Button onClick={onCloseAlert}>
                                      Cancel
                                    </Button>
                                    <Button
                                      colorScheme="customPrimary"
                                      isLoading={likedStatusLoading}
                                      isDisabled={!jobId || likedStatusLoading}
                                      onClick={() => {
                                        likedStatusMutate(
                                          {
                                            candidateId: candidateData?.userId,
                                            jobId: jobId,
                                          },
                                          {
                                            onSuccess: () => {
                                              refetchCandidate();
                                              fetchCandidateJobs();
                                              refetchUnAssignedJobs();
                                              fetchArchieveCandidateJobs();
                                              onCloseAlert();
                                              setJobId("");
                                            },
                                          }
                                        );
                                        // handleInterestjob(jobId);
                                      }}
                                      ml={3}
                                    >
                                      Submit
                                    </Button>
                                  </AlertDialogFooter>
                                </AlertDialogContent>
                              </AlertDialogOverlay>
                            </AlertDialog>
                            <AlertDialog
                              isOpen={isArhiveOpenAlert}
                              leastDestructiveRef={cancelRef}
                              onClose={onArhiveCloseAlert}
                            >
                              <AlertDialogOverlay>
                                <AlertDialogContent>
                                  <AlertDialogHeader
                                    fontSize="lg"
                                    fontWeight="bold"
                                  >
                                    Select Job
                                  </AlertDialogHeader>

                                  <AlertDialogBody>
                                    <Select
                                      isDisabled={false}
                                      color="#646C9A"
                                      size="xs"
                                      h="42px"
                                      borderRadius="md"
                                      name="selectJob"
                                      mr="2"
                                      onChange={(
                                        event: React.ChangeEvent<
                                          HTMLInputElement | HTMLSelectElement
                                        >
                                      ) => {
                                        setArvhieveJobId(event.target.value);
                                      }}
                                      value={arvhieveJobId}
                                      placeholder="Please Select a Job"
                                      className="custom-select"
                                    >
                                      {archievedJobList?.map((item: any) => {
                                        return (
                                          <option value={item?.job_Id}>
                                            {item?.job_Name?.length > 50
                                              ? item?.job_Name.slice(0, 50) +
                                                "..."
                                              : item?.job_Name}
                                          </option>
                                        );
                                      })}
                                    </Select>
                                  </AlertDialogBody>

                                  <AlertDialogFooter>
                                    <Button onClick={onArhiveCloseAlert}>
                                      Cancel
                                    </Button>
                                    <Button
                                      colorScheme="customPrimary"
                                      isLoading={archiveStatusLoading}
                                      isDisabled={archiveStatusLoading}
                                      onClick={() => {
                                        archiveStatusMutate(
                                          {
                                            candidateId: candidateData?.userId,
                                            jobId: arvhieveJobId,
                                          },
                                          {
                                            onSuccess: () => {
                                              refetchCandidate();
                                              fetchCandidateJobs();
                                              onArhiveCloseAlert();
                                              setArvhieveJobId("");
                                              refetchUnAssignedJobs();
                                              fetchArchieveCandidateJobs();
                                              // navigate(-1);
                                            },
                                          }
                                        );

                                        // handleInterestjob(jobId);
                                      }}
                                      ml={3}
                                    >
                                      Submit
                                    </Button>
                                  </AlertDialogFooter>
                                </AlertDialogContent>
                              </AlertDialogOverlay>
                            </AlertDialog>
                          </>
                          <Button
                            leftIcon={<RiThumbDownFill />}
                            // bg="white"
                            // borderColor="#fd397a"
                            // color="#fd397a"
                            variant="outline"
                            my="1"
                            color="white"
                            colorScheme="blue"
                            bg="#4361EE"
                            size={{ base: "xs", sm: "sm", "2xl": "md" }}
                            _hover={{ color: "white", bg: "#5570ef" }}
                            isLoading={archiveStatusLoading}
                            isDisabled={archiveStatusLoading}
                            onClick={() => {
                              onArhiveOpenAlert();
                            }}
                          >
                            Archive
                            {/* {candidateData?.isArchived
                              ? "Unarchive"
                              : "Archive"} */}
                          </Button>
                          <Menu>
                            <MenuButton
                              as={IconButton}
                              variant="ghost"
                              aria-label="See menu"
                              color="#9382dd"
                              // height={"32px"}
                              // isDisabled={candidateData?.isInterviewScheduled}

                              _hover={{
                                color: "#5867DD !important",
                                bg: "#5d78ff1a",
                              }}
                              icon={<BiDotsHorizontalRounded size="30px" />}
                            />
                            <MenuList>
                              <MenuItem
                                onClick={() => navigate(`/chat`, { state })}
                              >
                                Message Candidate
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  downloadResume(candidateData?.userId);
                                }}
                              >
                                Download Resume
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </HStack>
                      </Stack>
                    )}
                  </CardBody>
                </Card>
                <Card w="full">
                  <CardBody pt="2" pl="6">
                    <HStack
                      w={"full"}
                      alignItems="flex-start"
                      mt={{ base: "2 !important", lg: "4 !important" }}
                    >
                      <Icon as={RiTrophyLine} fontSize="36px" color="#94a0b0" />
                      <HStack flexWrap={"wrap"}>
                        {candidateData?.interestedJobFunctions?.map(
                          (item: string) => (
                            <Badge
                              bg={"#ffb822"}
                              fontWeight="100"
                              px="2"
                              py="0.5"
                              borderRadius={"xl"}
                              fontSize="12px"
                              mt="2 !important"
                              mr="2 !important"
                              ml="0 !important"
                              key={item}
                              color={"#48465b"}
                              textTransform={"capitalize"}
                              whiteSpace="normal"
                              wordBreak="break-word"
                            >
                              {item}
                            </Badge>
                          )
                        )}
                      </HStack>
                    </HStack>
                  </CardBody>
                </Card>
              </VStack>
              <Stack
                w={{ base: "100%", "2xl": "60%" }}
                mt={{
                  base: "2 !important",
                  md: "2 important",
                  xl: "0 !important",
                }}
              >
                <Card w="full">
                  <CardBody p={{ base: "2", md: "5" }}>
                    <Tabs>
                      <TabList fontSize={"13px"}>
                        <Tab
                          _selected={{
                            color: "#5D78FF",
                            borderBottomColor: "#5D78FF",
                          }}
                          pr="2px"
                        >
                          Experience & Education
                        </Tab>
                      </TabList>
                      <TabPanels>
                        <TabPanel>
                          <VStack spacing={6} align="start">
                            {candidateData?.positionList?.length &&
                              candidateData?.positionList?.map(
                                (expData: any) => {
                                  return (
                                    <Card w="full" bg="#f7f8fc">
                                      <CardBody>
                                        <Tag
                                          pos="absolute"
                                          right="45px"
                                          top="4px"
                                          colorScheme="whatsapp"
                                          bg={"#0abb87"}
                                          color={"white"}
                                          fontSize={"12px"}
                                          borderRadius={"sm"}
                                        >
                                          {getMonthsAndYears(
                                            expData?.monthsWorked
                                          )}
                                        </Tag>
                                        <VStack>
                                          <Flex
                                            flexDir={{
                                              base: "column",
                                              md: "row",
                                            }}
                                            w="full"
                                            justify={"space-between"}
                                            mt="3"
                                            align="center"
                                          >
                                            <Text
                                              fontWeight={"bold"}
                                              fontSize={{
                                                base: "md",
                                                md: "lg",
                                              }}
                                              _hover={{ color: "#5867DD" }}
                                              color={"#595d63"}
                                            >
                                              {expData?.companyName}
                                            </Text>
                                            {expData?.isStillWorking ? (
                                              <Text
                                                color={"#646c9a"}
                                                fontWeight={"bold"}
                                                fontSize={"12px"}
                                                mr="5"
                                              >
                                                {moment(
                                                  expData?.startDate
                                                ).format("MMM YYYY")}{" "}
                                                – Present
                                              </Text>
                                            ) : (
                                              <Text
                                                color={"#646c9a"}
                                                fontWeight={"bold"}
                                                fontSize={"12px"}
                                                mr="5"
                                              >
                                                {moment(
                                                  expData?.startDate
                                                ).format("MMM YYYY")}{" "}
                                                -{" "}
                                                {moment(
                                                  expData?.endDate
                                                ).format("MMM YYYY")}
                                              </Text>
                                            )}
                                          </Flex>
                                          <Flex w="full">
                                            <Text
                                              fontSize={"sm"}
                                              color={"#646c9a"}
                                              fontWeight={"bold"}
                                            >
                                              {expData?.jobTitle}
                                              <Badge
                                                ml="1"
                                                fontSize="0.8em"
                                                colorScheme="blue"
                                                p="1"
                                                color={"#5867DD"}
                                                px="2"
                                                bg={"#5d78ff1a"}
                                                textTransform={"capitalize"}
                                              >
                                                {expData?.employmentType?.replace(
                                                  "_",
                                                  " "
                                                )}
                                              </Badge>
                                            </Text>
                                          </Flex>
                                          {/* <Flex w="full">
                                            <Text
                                              fontSize={"sm"}
                                              color={"#646c9a"}
                                            >
                                              {expData?.jobLocation
                                                ? expData?.jobLocation
                                                : "Remote"}
                                            </Text>
                                          </Flex> */}
                                          <HStack
                                            w={"full"}
                                            alignItems="flex-start"
                                          >
                                            <Icon
                                              as={AiFillTags}
                                              fontSize="30px"
                                              color="gray.500"
                                              mt="1.5"
                                            />
                                            <HStack flexWrap={"wrap"}>
                                              {expData?.jobFunc.map(
                                                (item: any) => (
                                                  <Badge
                                                    variant="outline"
                                                    key={item}
                                                    // bg={"gray.400"}
                                                    // fontWeight="400"
                                                    px="4"
                                                    bg="#fff"
                                                    py="1"
                                                    fontWeight="300"
                                                    borderRadius={"xl"}
                                                    mt="2 !important"
                                                    ml="0px !important"
                                                    mr="4px !important"
                                                    fontSize={{
                                                      base: "8px",
                                                      md: "12px",
                                                    }}
                                                    textTransform={"capitalize"}
                                                  >
                                                    {item}
                                                  </Badge>
                                                )
                                              )}
                                            </HStack>
                                          </HStack>
                                          <Flex w="full"></Flex>
                                        </VStack>
                                      </CardBody>
                                    </Card>
                                  );
                                }
                              )}

                            {candidateData?.educationList?.length &&
                              candidateData?.educationList.map(
                                (eduData: any) => {
                                  return (
                                    <Card w="full" bg="#f7f8fc">
                                      <HStack
                                        justify={"flex-end"}
                                        w="full"
                                        pos="relative"
                                        top="2"
                                        right="2"
                                      />
                                      <CardBody pt="0px">
                                        <VStack>
                                          <Flex
                                            flexDir={{
                                              base: "column",
                                              md: "row",
                                            }}
                                            w="full"
                                            justify={"space-between"}
                                            mt="3"
                                          >
                                            <Text
                                              fontWeight={"bold"}
                                              fontSize={{
                                                base: "md",
                                                md: "md",
                                              }}
                                              color={"#595d63"}
                                              _hover={{ color: "#5867DD" }}
                                            >
                                              {eduData?.schoolName}
                                            </Text>
                                          </Flex>

                                          <Flex w="full">
                                            <Text
                                              fontSize={"sm"}
                                              color={"#646c9a"}
                                              fontWeight={"bold"}
                                            >
                                              {eduData.degree}
                                            </Text>
                                          </Flex>
                                          <Flex w="full" mt="0px !important">
                                            {eduData?.educationStatus ===
                                            "INCOMPLETE" ? (
                                              <Text
                                                color={"#646c9a"}
                                                fontWeight={"bold"}
                                                fontSize={"12px"}
                                              >
                                                Incomplete
                                              </Text>
                                            ) : eduData?.educationStatus ===
                                              "COMPLETED" ? (
                                              <Text
                                                color={"#646c9a"}
                                                fontWeight={"bold"}
                                                fontSize={"12px"}
                                              >
                                                {moment(
                                                  eduData?.startDate
                                                ).format("MMM YYYY")}{" "}
                                                -{" "}
                                                {moment(
                                                  eduData?.endDate
                                                ).format("MMM YYYY")}
                                              </Text>
                                            ) : (
                                              <Text
                                                color={"#646c9a"}
                                                fontWeight={"bold"}
                                                fontSize={"12px"}
                                              >
                                                {moment(
                                                  eduData?.startDate
                                                ).format("MMM YYYY")}{" "}
                                                - Present
                                              </Text>
                                            )}
                                          </Flex>

                                          <Flex w="full"></Flex>
                                        </VStack>
                                      </CardBody>
                                    </Card>
                                  );
                                }
                              )}
                            {Boolean(candidateData?.positionList?.length) ===
                              false &&
                              Boolean(candidateData?.educationList?.length) ===
                                false && (
                                <Flex
                                  align="center"
                                  w="full"
                                  justify="center"
                                  py="8"
                                >
                                  <Text>
                                    The Candidate has not provided information
                                    about their education and experience.
                                  </Text>
                                </Flex>
                              )}
                          </VStack>
                        </TabPanel>
                      </TabPanels>
                    </Tabs>
                  </CardBody>
                </Card>
              </Stack>
            </Stack>
          </VStack>
        </VStack>
      )}
      {isOpen && (
        <ScheduleMeeting
          isOpen={isOpen}
          onClose={onClose}
          jobId={
            pathname.includes("applicants") ||
            pathname.includes("liked") ||
            pathname.includes("view-applicant")
              ? candidateData?.jobId
              : null
          }
          clientId={candidateData?.userId}
          refetchCandidate={refetchCandidate}
        />
      )}
      <Modal isOpen={isAddJobModalOpen} onClose={onAddJobModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader bg="white">Add Job</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl position="relative">
              <FormLabel>Search:</FormLabel>
              <Input
                type="text"
                placeholder="Search for jobs..."
                value={searchJob}
                onChange={(e) => handleSearchJob(e?.target?.value)}
                onFocus={() => handleFocusBlur(true)}
                onBlur={() => handleFocusBlur(false)}
              />

              <Flex
                direction="column"
                position="absolute"
                boxShadow="0px 0px 50px 0px rgba(82, 63, 105, 0.15)"
                bg="#fff"
                borderRadius="8px"
                w="full"
                zIndex="99"
                padding="5px 10px"
                gap="2"
                maxHeight="200px"
                overflow="auto"
              >
                {showJobResult &&
                  jobResult.map((job: any) => (
                    <Flex
                      align="center"
                      gap="2"
                      _hover={{ bg: "gray.50", cursor: "pointer" }}
                      key={job?.id}
                      padding="5px "
                      onClick={() => {
                        setSelectedJob(job);
                        setShowJobResult(false);
                        setSearchJob(job?.name);
                      }}
                    >
                      {/* <Image
                        src={job?.companyLogo}
                        height="35px"
                        width="35px"
                        borderRadius="full"
                      /> */}
                      <Flex direction="column" w="full" gap="1px">
                        <Text fontSize="13px">{job?.name}</Text>
                        {job?.companyName && (
                          <Text fontSize="12px">@{job?.companyName}</Text>
                        )}
                      </Flex>
                    </Flex>
                  ))}
              </Flex>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onAddJobModalClose}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={handleSubmitData}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AlertDialog
        isOpen={isOpenAlert3}
        leastDestructiveRef={cancelRef3}
        onClose={() => {
          onCloseAlert3();
          setReason("");
          setValueReason("");
          setShowReason(false);
        }}
        size="xl"
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader
              fontSize="lg"
              fontWeight="bold"
              bg="blue.500"
              color="white"
            >
              Confirm Candidate Rejection
            </AlertDialogHeader>

            <AlertDialogBody>
              {showReason ? (
                <>
                  <Text>Please select a reason for rejection (optional):</Text>
                  <RadioGroup
                    options={reasonOptions}
                    name="jobType"
                    value={reason}
                    onChange={(value) => {
                      setReason(value);
                      setValueReason("");
                    }}
                    maxW="100%"
                  />

                  {reason.includes("Other") && (
                    <Flex direction="column" w="full" mt="2">
                      <Textarea
                        value={valueReason}
                        onChange={(e) => setValueReason(e.target.value)}
                        placeholder=""
                        size="sm"
                      />

                      {!valueReason.length && errors?.valueReason && (
                        <Text color="red" textAlign="center" w="full" pl="1">
                          *{errors?.valueReason}
                        </Text>
                      )}
                    </Flex>
                  )}
                </>
              ) : (
                <Text py="5">
                  Are you sure you want to reject this candidate?
                </Text>
              )}
            </AlertDialogBody>

            <AlertDialogFooter>
              <HStack>
                <Button
                  onClick={() => {
                    onCloseAlert3();
                    setReason("");
                    setValueReason("");
                    setShowReason(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  colorScheme="customPrimary"
                  isDisabled={showReason && isJobRejected}
                  isLoading={showReason && isJobRejected}
                  ml={3}
                  onClick={() => {
                    showReason
                      ? handleRejectCandidate(state?.jobId)
                      : setShowReason(true);
                  }}
                >
                  {showReason ? "Submit" : "Confirm"}
                </Button>
              </HStack>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Modal
        isCentered
        isOpen={isOpenLinkedInModal}
        onClose={onCloseLinkedInModal}
        size="xl"
      >
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(1px) " />
        <ModalContent>
          <ModalCloseButton onClick={onClose} />
          <ModalBody pt="50px">
            <Text fontWeight="700" textAlign="center" fontSize="20px">
              This candidate has not uploaded Resume
            </Text>
            <VStack padding="20px 40px">
              <Text textAlign="center">
                Please visit their LinkedIn profile
              </Text>
              <Link to={linkedIn} target="_blank" style={{ color: "blue" }}>
                <Text
                  wordBreak="break-word"
                  _hover={{ textDecor: "underline" }}
                >
                  {" "}
                  {linkedIn}
                </Text>
              </Link>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TalenDetail;
