import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { resetPassSchema } from "../../utils/validation";
import { yupResolver } from "@hookform/resolvers/yup";

const useResetPass = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
    resolver: yupResolver(resetPassSchema),
  });
  console.log("errors", errors);

  const onSubmit = (data: any) => {
    navigate("/signup");
  };
  return {
    register,
    handleSubmit,
    errors,
    onSubmit,
    navigate,
  };
};

export default useResetPass;
