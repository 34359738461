import { Navigate, Route, Routes } from "react-router-dom";
import ApplicantEmployeerPage from "../../../pages/Employeer/applicant-employeer";
import ChatEmployeerPage from "../../../pages/Employeer/chat-employeer";
import JobPostEmployeerPage from "../../../pages/Employeer/job-post";
import JobsEmployeerPage from "../../../pages/Employeer/jobs-employeer";
import SearchEmployeerPage from "../../../pages/Employeer/search-employeer";
import SettingEmployeerPage from "../../../pages/Employeer/setting-employeer";
import NotFoundPage from "../../../pages/not-found";
import CongratulationPostJob from "../../employeer/jos-post/congratulation";
import TalenDetail from "../../employeer/search-talent/talent-detail";
import Layout from "./Layout";
import PrivateRoutes from "./PrivateRoute/private-routes";
import JobDetail from "../../employeer/jobs/details/index";
import InterviewEmployerPage from "../../../pages/Employeer/interview-employer";
import MeetingDetails from "../../employeer/interview/MeetingDetails";
import ArchivePage from "../../../pages/Employeer/archive";
import LikedPage from "../../../pages/Employeer/liked-employeer";
import ViewApplicant from "../../employeer/jobs/viewApplicant";
import PiplelineVisualizationPage from "../../../pages/Employeer/pipleline-visualization";
import { useEmployerProfile } from "../../../service/employerProfile.service";
import { getUserId } from "../../../utils/helper";
import { useUserStore } from "../../../store/useUser";

export const EmployeerRoutes = () => {
  const userId = getUserId();
  const { setUser } = useUserStore();
  const { refetch: fetchProfile, data: profileData } =
    useEmployerProfile(userId);
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* we want to protect these routes */}
        <Route element={<PrivateRoutes />}>
          <Route path="/" element={<Navigate to="/jobs" replace />} />
          {/* <Route path="/home" element={<DashboardEmployeerPage />} /> */}
          <Route path="/jobs" element={<JobsEmployeerPage />} />
          <Route path="/search" element={<SearchEmployeerPage />} />
          <Route path="/applicants" element={<ApplicantEmployeerPage />} />
          <Route path="/jobs/archived" element={<ArchivePage />} />
          <Route path="/liked" element={<LikedPage />} />
          <Route path="/chat" element={<ChatEmployeerPage />} />
          <Route path="/chat/:id" element={<ChatEmployeerPage />} />
          <Route path="/interview" element={<InterviewEmployerPage />} />
          <Route path="/profile" element={<SettingEmployeerPage />} />
          <Route path="/jobs/post" element={<JobPostEmployeerPage />} />
          <Route path="/jobs-post-done" element={<CongratulationPostJob />} />
          <Route path="/interview/details" element={<MeetingDetails />} />
          <Route path="/search/:id" element={<TalenDetail />} />
          <Route path="/applicants/:id" element={<TalenDetail />} />
          <Route path="/jobs/archived/:id" element={<TalenDetail />} />
          <Route path="/liked/:id" element={<TalenDetail />} />
          <Route path="/jobs/view-applicant/:id" element={<TalenDetail />} />
          <Route path="/jobs/:id" element={<JobDetail />} />
          <Route path="/jobs/view-applicant" element={<ViewApplicant />} />
          <Route path="/reports" element={<PiplelineVisualizationPage />} />
        </Route>

        {/* catch all */}
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};
