import React, { useEffect, useRef, useState } from "react";
import { useFetch } from "../../../../utils/useFetch";
import { useToast } from "@chakra-ui/react";

const useFilter = () => {
  const toast = useToast();
  const inputref: any = useRef(null);
  const [requisition, setRequisition] = useState("");

  const [jobFuncDisplayList, setJobFuncDisplayList] = useState<any>([]);
  const [domainDisplayList, setDomainDisplayList] = useState<any>([]);

  const [jobFunctionList, setJobFunctionList] = useState<any>([]);
  const [customJobFunction, setCustomJobFunction] = useState("");

  const [domainList, setDomainList] = useState<any>([]);
  const [seniorityLevelList, setSeniorityLevelList] = useState<any>([]);
  const [workPermitList, setWorkPermitList] = useState<any>([]);
  const [locationList, setLocationList] = useState<any>([]);
  const [stageList, setStageList] = useState<any>([]);
  const [hiringTeamList, setHiringTeamList] = useState<any>([]);
  const [jobRequisitionList, setJobRequisitionList] = useState<any>([]);

  const [selected, setSelected] = useState<any>();
  const [showCustomInput, setShowCustomInput] = useState(false);

  const [skills, setSkills] = useState("");
  const [showTopSkillsResult, setShowTopSkillsResult] = useState(false);
  const [searchTopSkillsResult, setSearchTopSkillsResult] = useState<any>([]);
  const [hiringTeamResults, setHiringTeamResults] = useState<any>([]);
  const [isSkillsFocused, setIsSkillsFocused] = useState(false);
  const [topSkills, setTopSkills] = useState<any>([]);
  const [error, setError] = useState<any>({
    jobFunctionsError: "",
  });

  useEffect(() => {
    try {
      useFetch
        .get(`/users/domain/job-function-list`)
        .then((response) => {
          setJobFuncDisplayList(response.data);
        })
        .catch((error) => {
          console.log("Error", error);
        });
    } catch (error) {
      console.log("ERROR", error);
    }
  }, []);
  useEffect(() => {
    try {
      useFetch
        .get(`/users/domain/all-job-skill-name-list?query=`)
        .then((response) => {
          setSearchTopSkillsResult(response.data);
        })
        .catch((error) => {
          console.log("Error", error);
        });
    } catch (error) {
      console.log("ERROR", error);
    }
  }, []);
  useEffect(() => {
    useFetch
      .get(`/users/domain/hobbies-interest?query=`)
      .then((response) => {
        setDomainDisplayList(response?.data);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }, []);
  useEffect(() => {
    useFetch
      .get(`/users/employer/hiring-team`)
      .then((response) => {
        setHiringTeamResults(response?.data);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }, []);
  const handleAddJobFunctions = (value: any) => {
    if (!value.trim()) return;

    if (value === "Other") {
      setError((prevError: any) => ({
        ...prevError,
        jobFunctionsError: "",
      }));
      setJobFunctionList(["Other"]);
      setCustomJobFunction("");
      return;
    }

    const updatedList = jobFunctionList.filter((func: any) => func !== "Other");

    if (jobFunctionList.includes(value)) {
      setError((prevError: any) => ({
        ...prevError,
        jobFunctionsError: "You have already selected this job function",
      }));
      return;
    }

    setJobFunctionList([...updatedList, value]);
    setError((prevError: any) => ({
      ...prevError,
      jobFunctionsError: "",
    }));
  };

  const handleRemoveJobFunction = (indexToRemove: number) => {
    setJobFunctionList((prevJobfuncList: any) =>
      prevJobfuncList.filter((_: any, index: number) => index !== indexToRemove)
    );
  };

  const handleAddSeniorityList = (value: any) => {
    if (!value.trim()) return;
    if (seniorityLevelList.includes(value)) {
      setError((prevError: any) => ({
        ...prevError,
        sniorityError: "You have already selected this Seniority Level",
      }));
    } else {
      setSeniorityLevelList((prevSeniorityList: any) => [
        ...prevSeniorityList,
        value,
      ]);
      setError((prevError: any) => ({
        ...prevError,
        sniorityError: "",
      }));
    }
  };
  const handleRemoveSeniorityLevel = (indexToRemove: number) => {
    setSeniorityLevelList((prevSeniorityList: any) =>
      prevSeniorityList.filter(
        (_: any, index: number) => index !== indexToRemove
      )
    );
  };
  const handleAddDomainList = (value: any) => {
    if (!value.trim()) return;
    if (domainList.includes(value)) {
      setError((prevError: any) => ({
        ...prevError,
        domainError: "You have already selected this Domain",
      }));
    } else {
      setDomainList((prevDomainList: any) => [...prevDomainList, value]);
      setError((prevError: any) => ({
        ...prevError,
        domainError: "",
      }));
    }
  };

  const handleRemoveDomain = (indexToRemove: number) => {
    setDomainList((prevDomianList: any) =>
      prevDomianList.filter((_: any, index: number) => index !== indexToRemove)
    );
  };

  const handleAddTopSkills = (value: any) => {
    if (!value.trim()) return;
    if (topSkills.includes(value)) {
      setError((prevError: any) => ({
        ...prevError,
        skillsError: "You have already selected this skill",
      }));
    } else {
      setTopSkills((prevTopSkillsList: any) => [...prevTopSkillsList, value]);
    }
  };

  const handleRemoveSkill = (indexToRemove: number) => {
    setTopSkills((prevSkillList: any) =>
      prevSkillList.filter((_: any, index: number) => index !== indexToRemove)
    );
  };

  const handleSkillsFocusBlur = (value: boolean) => {
    setIsSkillsFocused(value);
  };
  const handleRemoveTopSkills = (indexToRemove: number) => {
    setTopSkills((prevTopSkillsList: any) =>
      prevTopSkillsList.filter(
        (_: any, index: number) => index !== indexToRemove
      )
    );
  };

  const handleAddWorkPermit = (value: any) => {
    if (!value.trim()) return;
    if (workPermitList.includes(value)) {
      setError((prevError: any) => ({
        ...prevError,
        workPermitError: "You have already selected this permit",
      }));
    } else {
      setWorkPermitList((prevDomainList: any) => [...prevDomainList, value]);
      setError((prevError: any) => ({
        ...prevError,
        workPermitError: "",
      }));
    }
  };

  const handleRemoveWorkPermit = (indexToRemove: number) => {
    setWorkPermitList((prevDomianList: any) =>
      prevDomianList.filter((_: any, index: number) => index !== indexToRemove)
    );
  };
  const handleAddLocation = (value: any) => {
    if (!value.trim()) return;
    if (locationList.includes(value)) {
      setError((prevError: any) => ({
        ...prevError,
        locationError: "You have already selected this location",
      }));
    } else {
      setLocationList((prevLocationList: any) => [...prevLocationList, value]);
      setError((prevError: any) => ({
        ...prevError,
        locationError: "",
      }));
    }
  };

  const handleRemoveLcoation = (indexToRemove: number) => {
    setLocationList((prevLocationList: any) =>
      prevLocationList.filter(
        (_: any, index: number) => index !== indexToRemove
      )
    );
  };
  const handleAddStages = (value: any) => {
    if (!value.trim()) return;
    if (stageList.includes(value)) {
      setError((prevError: any) => ({
        ...prevError,
        stageError: "You have already selected this Stage",
      }));
    } else {
      setStageList((prevStageList: any) => [...prevStageList, value]);
      setError((prevError: any) => ({
        ...prevError,
        stageError: "",
      }));
    }
  };

  const handleRemoveStages = (indexToRemove: number) => {
    setStageList((prevStageList: any) =>
      prevStageList.filter((_: any, index: number) => index !== indexToRemove)
    );
  };

  const handleAddHiringTeam = (value: any) => {
    if (!value.trim()) return;
    const parsedValue = JSON.parse(value);
    if (hiringTeamList.includes(parsedValue?.id)) {
      setError((prevError: any) => ({
        ...prevError,
        hiringTeamError: "You have already selected this Member",
      }));
    } else {
      setHiringTeamList((prevTeamList: any) => [...prevTeamList, parsedValue]);
      setError((prevError: any) => ({
        ...prevError,
        hiringTeamError: "",
      }));
    }
  };
  const handleRemoveHiringTeam = (indexToRemove: number) => {
    setHiringTeamList((prevStageList: any) =>
      prevStageList.filter((_: any, index: number) => index !== indexToRemove)
    );
  };

  const handleAddJobRequisition = (value: any) => {
    if (!value.trim()) return;
    if (jobRequisitionList.includes(value)) {
      setError((prevError: any) => ({
        ...prevError,
        jobRequisitionError: "You have already selected this Job Requisition",
      }));
    } else {
      setJobRequisitionList((prevRequisitionList: any) => [
        ...prevRequisitionList,
        value,
      ]);
      setRequisition("");
      setError((prevError: any) => ({
        ...prevError,
        jobRequisitionError: "",
      }));
    }
  };

  const handleRemoveJobRequisition = (indexToRemove: number) => {
    setJobRequisitionList((prevRequisitionList: any) =>
      prevRequisitionList.filter(
        (_: any, index: number) => index !== indexToRemove
      )
    );
  };

  return {
    inputref,
    error,
    topSkills,
    isSkillsFocused,
    setIsSkillsFocused,
    setTopSkills,
    selected,
    jobFunctionList,
    setJobFunctionList,
    jobFuncDisplayList,
    setJobFuncDisplayList,
    seniorityLevelList,
    setSeniorityLevelList,
    setSelected,
    skills,
    setSkills,
    showCustomInput,
    setShowCustomInput,
    searchTopSkillsResult,
    setSearchTopSkillsResult,
    setError,
    handleAddTopSkills,
    handleRemoveSkill,
    handleRemoveJobFunction,
    handleAddJobFunctions,
    handleRemoveSeniorityLevel,
    handleAddSeniorityList,
    handleSkillsFocusBlur,
    handleRemoveTopSkills,
    showTopSkillsResult,
    setShowTopSkillsResult,
    domainDisplayList,
    handleRemoveDomain,
    handleAddDomainList,
    domainList,
    setDomainList,
    workPermitList,
    setWorkPermitList,
    handleAddWorkPermit,
    handleRemoveWorkPermit,
    locationList,
    handleAddLocation,
    handleRemoveLcoation,
    stageList,
    hiringTeamList,
    setHiringTeamList,
    hiringTeamResults,
    jobRequisitionList,
    setJobRequisitionList,
    handleAddStages,
    handleRemoveStages,
    handleAddHiringTeam,
    handleRemoveHiringTeam,
    handleAddJobRequisition,
    handleRemoveJobRequisition,
    requisition,
    setRequisition,
  };
};

export default useFilter;
