import { useEffect, useState } from "react";
import { useFetch } from "../../../utils/useFetch";
import { getUserId } from "../../../utils/helper";
import { scrollToTopOfScrollable } from "../../../utils/scroll-to-top";

export function useFetchAllAproveJobs() {
  const userId = getUserId();
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState<any>(null);
  const [itemOffset, setItemOffset] = useState(0);
  const [query, setQuery] = useState("");
  const [jobFunction, setjobFunction] = useState("");

  const fetchAllAproveJobs = async () => {
    setIsLoading(true);
    try {
      const result = await useFetch.get(
        `/jobs/gat-all-approve-jobs?page=${itemOffset}&limit=12&jobFunction=${jobFunction}&jobName=${query.trim()}&employId=${userId}`
      );
      setResponse(result.data);
      scrollToTopOfScrollable();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (event: any) => {
    const searchParam = event.target.value.trim();
    setQuery(searchParam);
  };
  const selectJobFunc = (e: any) => {
    if (e === "All") {
      setjobFunction("");
    } else {
      setjobFunction(e);
    }
  };

  useEffect(() => {
    fetchAllAproveJobs();
  }, [itemOffset, query, jobFunction]);
  return {
    fetchAllAproveJobs,
    isLoading,
    response,
    selectJobFunc,
    jobFunction,
    handleSearch,
    query,
    setQuery,
    itemOffset,
    setItemOffset,
    userId,
  };
}
