import ArchivedTalent from "../../components/employeer/archived";

const ArchivePage = () => {
  return (
    <>
      <ArchivedTalent />
    </>
  );
};

export default ArchivePage;
