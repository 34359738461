import LikedTalent from "../../components/employeer/liked";
import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import ArchivedTalent from "../../components/employeer/archived";

const LikedPage = () => {
  return (
    <Flex direction="column" padding="2">
      <Tabs variant="enclosed">
        <TabList>
          <Tab>Job Applicants</Tab>
          <Tab>Archived Talent</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <LikedTalent />
          </TabPanel>
          <TabPanel>
            <ArchivedTalent />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};

export default LikedPage;
