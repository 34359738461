import { GenericModal } from "../../../common/generic-modal";
import Setup from "../../signupFlow/setup";

interface EditProfileOtherInfoProps {
  isOpen: any;
  onClose: any;
}
const EditProfileOtherInfo = ({
  isOpen,
  onClose,
}: EditProfileOtherInfoProps) => {
  return (
    <GenericModal
      isOpen={isOpen}
      onClose={onClose}
      title={"Edit Job Preferences"}
      onClickHandler={() => console.log("values")}
      buttonText={false ? "Loading" : "Save"}
      loadingButton={false || false}
      buttonShow={false}
    >
      <Setup onModalClose={onClose} />
    </GenericModal>
  );
};

export default EditProfileOtherInfo;
