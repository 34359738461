import { useEffect, useRef, useState } from "react";
import {
  Avatar,
  AvatarBadge,
  Box,
  Button,
  Card,
  Center,
  HStack,
  Text,
  IconButton,
  Input,
  Select,
  Stack,
  Switch,
  VStack,
  useToast,
  Heading,
  SkeletonCircle,
  Flex,
  Tag,
  TagCloseButton,
  TagLabel,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { useFetch } from "../../../../utils/useFetch";
import useSetup from "./useSetup";
import RadioGroup from "../../../common/radioGroup";
import ReactDatePicker from "react-datepicker";
import CustomDatePicker from "../../../common/date-picker/datepicker";

const Setup = ({ onModalClose }: any) => {
  const { pathname } = useLocation();
  const isSetupPage = pathname === "/setupFlow/setup";

  const {
    otherInfo,
    workVisaRequired,
    setNonImmigrantVisaStatus,
    whenYouCanStart,
    isWorkRemotely,
    isWorkHybrid,
    isWorkOnSite,
    customJoiningDate,
    salary,
    errors,
    setErrors,
    nonImmigrantVisaStatus,
    profilePicture,
    handleFileInputChange,
    handleEditButtonClick,
    handleSalaryChange,
    optionsSponsorship,
    workVisaRequiredLookup,
    workVisaRequiredLookupReverse,
    whenYouStartLookup,
    whenYouStartLookupReverse,
    setSalary,
    setJobSearch,
    setWorkVisaRequired,
    setWhenYouCanStart,
    setCustomJoiningDate,
    fileInputRef,
    onSubmit,
    isLoading,
    profileImageLoading,
    jobSearch,
    navigate,
    otherInfoAddLoading,
    additionalDetails,
    setAdditionalDetails,
    interestedJobFunctions,
    setJobFunc,
    industriesList,
    setIndustriesList,
  } = useSetup(onModalClose);

  const [otherInput, setOtherInput] = useState("");
  const toast = useToast();
  const inputref = useRef<HTMLInputElement>(null);
  const [functions, setFunctions] = useState("");
  const [showJobFunctionResult, setShowJobFunctionResult] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [searchJobFunctionResult, setSearchJobFunctionResult] = useState<any>(
    []
  );
  const [showJobIndustriesResult, setShowJobIndustriesResult] = useState(false);
  const [isIndustryFocused, setIsIndustryFocused] = useState(false);
  const [industryName, setIndustryName] = useState("");
  const [jobIndustryResult, setJobIndustryResult] = useState<any>([]);
  const [error, setError] = useState({
    jobFunctionsError: "",
    prefferedIndustriesError: "",
    topSkillsError: "",
    additionalDetails: "",
  });
  const handleIndustryFocusBlur = (value: boolean) => {
    setIsIndustryFocused(value);
  };
  const handleAddIndustry = (industryNameProps: string) => {
    if (industryNameProps) {
      setShowJobIndustriesResult(false);
      if (industriesList.includes(industryNameProps)) {
        setError((prevError: any) => ({
          ...prevError,
          prefferedIndustriesError: "You have already selected this industry",
        }));
      } else if (industriesList?.length >= 3) {
        setError((prevError: any) => ({
          ...prevError,
          prefferedIndustriesError: "You can select up to 3 options",
        }));
      } else {
        setIndustriesList((prevIndustriesList: any) => [
          ...prevIndustriesList,
          industryNameProps,
        ]);
        setIndustryName("");
        setError((prevError: any) => ({
          ...prevError,
          prefferedIndustriesError: "",
        }));
      }
    }
  };
  // const handleJobIndustries = (search: any) => {
  //   setIndustryName(search);
  //   setShowJobIndustriesResult(false);
  // };
  const searchFieldRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        searchFieldRef.current &&
        !searchFieldRef.current.contains(event.target as Node)
      ) {
        handleFocusBlur(false);
        setShowJobFunctionResult(false);

        setShowJobIndustriesResult(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleRemoveIndustry = (indexToRemove: number) => {
    setIndustriesList((prevIndustriesList: any) =>
      prevIndustriesList.filter(
        (_: any, index: number) => index !== indexToRemove
      )
    );
  };
  useEffect(() => {
    if (isIndustryFocused) {
      if (industryName?.length > 0) {
        try {
          useFetch
            .get(`/users/domain/all-job-industry-list?query=${industryName}`)
            .then((response) => {
              setJobIndustryResult(response.data);
              setShowJobIndustriesResult(true);
            })
            .catch((error) => {
              console.log("Error", error);
            });
        } catch (error) {
          console.log("ERROR", error);
        }
      } else {
        setShowJobIndustriesResult(false);
      }
    }
  }, [industryName]);
  useEffect(() => {
    if (isFocused) {
      if (functions?.length > 0) {
        try {
          useFetch
            .get(`/users/domain/all-job-function-list?query=${functions}`)
            .then((response) => {
              setSearchJobFunctionResult(response.data);
              setShowJobFunctionResult(true);
            })
            .catch((error) => {
              console.log("Error", error);
            });
        } catch (error) {
          console.log("ERROR", error);
        }
      } else {
        setShowJobFunctionResult(false);
      }
    }
  }, [functions]);

  const handleAddJobFunctions = (functionsProps: string) => {
    if (functionsProps) {
      setShowJobFunctionResult(false);

      if (interestedJobFunctions.includes(functionsProps)) {
        setError((prevError: any) => ({
          ...prevError,
          jobFunctionsError: "You have already selected this function",
        }));
      } else if (interestedJobFunctions?.length >= 3) {
        setError((prevError: any) => ({
          ...prevError,
          jobFunctionsError: "You can select upto 3 Options",
        }));
      } else {
        setError((prevError: any) => ({
          ...prevError,
          jobFunctionError: "",
        }));
        setJobFunc((prevJobFuncList: any) => [
          ...prevJobFuncList,
          functionsProps,
        ]);
        setError((prevError: any) => ({
          ...prevError,
          jobFunctionsError: "",
        }));
        setFunctions("");
      }
    }
  };

  const handleSearchJobFunctions = (search: any) => {
    setFunctions(search);
    setShowJobFunctionResult(false);
  };
  const handleRemoveJobFunction = (indexToRemove: number) => {
    setJobFunc((prevJobfuncList: any) =>
      prevJobfuncList.filter((_: any, index: number) => index !== indexToRemove)
    );
  };
  const handleFocusBlur = (value: boolean) => {
    setIsFocused(value);
  };

  const handleAddOtherInput = () => {
    if (otherInput.trim() !== "") {
      setAdditionalDetails(otherInput);
      setOtherInput("");
      setError((prevDetails) => ({ ...prevDetails, additionalDetails: "" }));
    }
    // else {
    //   setError((prevDetails) => ({
    //     ...prevDetails,
    //     additionalDetails: "Details cannot be empty",
    //   }));
    // }
  };

  return (
    <form
      style={{ width: "100%" }}
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
      onKeyDown={(e: any) => {
        if (e.key === "Enter") {
          e.preventDefault();
          onSubmit();
        }
      }}
    >
      <VStack w="full">
        <>
          {isSetupPage && (
            <Card p="4" w="full">
              <HStack justify="space-between" w="full">
                <Heading size="lg" fontWeight="500" color="#48465b">
                  Provide Additional Information
                </Heading>
              </HStack>
            </Card>
          )}
          {pathname !== "/profile" && (
            <Card p="4" w="full">
              <Text fontWeight="bold" size="md">
                Upload a profile picture
                <Box as="span" ml="2" color="red" fontSize="md" mt="1">
                  *
                </Box>
              </Text>
              <Center w="full">
                {profileImageLoading ? (
                  <SkeletonCircle size="24" />
                ) : (
                  <Avatar size="xl" src={profilePicture}>
                    <AvatarBadge
                      as={IconButton}
                      size="sm"
                      rounded="full"
                      top="-10px"
                      colorScheme="buttonPrimary"
                      aria-label="edit Image"
                      icon={<FiEdit />}
                      onClick={handleEditButtonClick}
                    />
                  </Avatar>
                )}

                <input
                  ref={fileInputRef}
                  type="file"
                  accept=".png, .jpeg, .jpg, .svg"
                  style={{ display: "none" }}
                  onChange={handleFileInputChange}
                />
              </Center>
              {!profilePicture && (
                <Text textAlign="left" fontSize="sm" color="red.500">
                  {errors["profilePicture"]}
                </Text>
              )}
            </Card>
          )}
          <Card p="4" w="full">
            <Box maxW="800px" my="4">
              <Stack>
                <Text fontWeight="bold" size="md">
                  Where are you in your job search?{" "}
                  <Box
                    as="span"
                    fontWeight="400"
                    color="red"
                    fontSize="md"
                    mt="1"
                  >
                    *
                  </Box>
                </Text>
                <RadioGroup
                  options={["Passively looking", "Actively searching"]}
                  name="jobSearch"
                  defaultValue={
                    otherInfo?.jobSearch === 0
                      ? "Passively looking"
                      : otherInfo?.jobSearch === 1
                      ? "Actively searching"
                      : ""
                  }
                  onChange={(value: string) => {
                    if (value === "Passively looking") {
                      setJobSearch(0);
                    } else {
                      setJobSearch(1);
                    }
                  }}
                  maxW="100%"
                />
                {!(jobSearch === 0 || Boolean(jobSearch)) && (
                  <Text textAlign="left" fontSize="sm" color="red.500">
                    {errors["jobSearch"]}
                  </Text>
                )}
              </Stack>
            </Box>
          </Card>
          {otherInfo && !pathname.includes("setupFlow") && (
            <Card p="4" w="full">
              <Box maxW="800px" my="4">
                <Flex position="relative" direction="column" w="full" gap="2">
                  <Flex align="start">
                    {" "}
                    <Heading size="sm">Job Functions</Heading>
                    <Box as="span" fontWeight="400" color="red" fontSize="md">
                      *
                    </Box>
                  </Flex>
                  <HStack>
                    <Input
                      maxLength={50}
                      type="text"
                      placeholder="Search Job Functions"
                      maxW={{ base: "100%", md: "70%" }}
                      ref={inputref}
                      onFocus={() => handleFocusBlur(true)}
                      onBlur={() => handleFocusBlur(false)}
                      borderColor="grey"
                      onChange={(e) => setFunctions(e.target.value.trimLeft())}
                      value={functions}
                      isInvalid={
                        !interestedJobFunctions?.length &&
                        errors?.interestedJobFunctions
                      }
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.stopPropagation();
                          e.preventDefault();
                          handleAddJobFunctions(functions);
                        }
                      }}
                    />

                    <Button
                      ml="2"
                      colorScheme="buttonPrimary"
                      onClick={() => handleAddJobFunctions(functions)}
                    >
                      Add
                    </Button>
                  </HStack>
                  {!interestedJobFunctions?.length && (
                    <Text textAlign="left" fontSize="sm" color="red.500">
                      {errors.interestedJobFunctions}
                    </Text>
                  )}
                  {error?.jobFunctionsError && (
                    <Text color="red.500" fontSize="sm">
                      {error?.jobFunctionsError}
                    </Text>
                  )}
                  {showJobFunctionResult &&
                    searchJobFunctionResult?.length >= 1 && (
                      <VStack
                        ref={searchFieldRef}
                        height="100px"
                        overflowY="scroll"
                        position="absolute"
                        top="70px"
                        zIndex="50"
                        bg="white"
                        border="1px solid #F1F0F9"
                        borderRadius="10px"
                        w="70%"
                        boxShadow="0px 8px 32px 0px rgba(78, 48, 123, 0.04)"
                        padding="5px 0px"
                      >
                        {searchJobFunctionResult.map(
                          (data: any, index: number) => (
                            <Flex
                              w="full"
                              p="5px"
                              borderBottom="1px solid #eae8e8"
                              onClick={() => handleAddJobFunctions(data.name)}
                              _hover={{ bg: "#F1F0F9", color: "black" }}
                              key={index}
                              cursor="pointer"
                            >
                              <Text ms="4px">{data.name}</Text>
                            </Flex>
                          )
                        )}
                      </VStack>
                    )}
                </Flex>
                <Flex gap="10px" mt="10px">
                  {interestedJobFunctions.map(
                    (functions: string, index: number) => (
                      <Tag key={index}>
                        <TagLabel>{functions}</TagLabel>
                        <TagCloseButton
                          onClick={() => handleRemoveJobFunction(index)}
                        />
                      </Tag>
                    )
                  )}
                </Flex>
              </Box>
            </Card>
          )}
          <Card p="4" w="full">
            <Box maxW="800px" my="4">
              <Stack>
                <Text fontWeight="bold" size="md">
                  Sponsorship{" "}
                  <Box
                    as="span"
                    fontWeight="400"
                    color="red"
                    fontSize="md"
                    mt="1"
                  >
                    *
                  </Box>
                </Text>
                <Text fontWeight="bold" fontSize="sm">
                  Do you need a work visa?
                </Text>
                <RadioGroup
                  options={["Yes", "No", "I'm not sure"]}
                  name="workVisa"
                  defaultValue={
                    workVisaRequiredLookupReverse[otherInfo?.workVisaRequired]
                  }
                  onChange={(value: string) => {
                    const workVisa = workVisaRequiredLookup[value];
                    setWorkVisaRequired(workVisa);
                    setNonImmigrantVisaStatus("");
                  }}
                  maxW="100%"
                />
                {!(workVisaRequired === 0 || Boolean(workVisaRequired)) && (
                  <Text textAlign="left" fontSize="sm" color="red.500">
                    {errors["workVisaRequired"]}
                  </Text>
                )}
                {workVisaRequired === 0 && (
                  <>
                    <Text fontWeight="bold" fontSize="sm">
                      Your current nonimmigrant Visa status{" "}
                      <Box
                        as="span"
                        fontWeight="400"
                        ml="2"
                        color="red"
                        fontSize="md"
                        mt="1"
                      >
                        *
                      </Box>
                    </Text>
                    <RadioGroup
                      options={optionsSponsorship}
                      name="workVisa"
                      defaultValue={
                        nonImmigrantVisaStatus ? nonImmigrantVisaStatus : ""
                      }
                      onChange={(value: string) => {
                        setNonImmigrantVisaStatus(value);
                        setAdditionalDetails("");
                        setOtherInput("");
                      }}
                      maxW="100%"
                    />
                    {(nonImmigrantVisaStatus === "Other" ||
                      additionalDetails) && (
                      <>
                        <Flex w="full" direction="column" gap="3">
                          <Flex align="center" gap="4">
                            <Input
                              maxLength={50}
                              placeholder="Enter Details ..."
                              bg="white"
                              maxW={{ base: "100%", md: "70%" }}
                              borderColor="grey"
                              onChange={(e) => {
                                setOtherInput(e.target.value.trimLeft());
                              }}
                              value={otherInput}
                              isInvalid={Boolean(error?.additionalDetails)}
                              isDisabled={!!additionalDetails}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  handleAddOtherInput();
                                }
                              }}
                            />
                            <Button
                              colorScheme="buttonPrimary"
                              onClick={handleAddOtherInput}
                            >
                              Add
                            </Button>
                          </Flex>
                          {additionalDetails && (
                            <Tag w="fit-content">
                              <TagLabel>{additionalDetails}</TagLabel>
                              <TagCloseButton
                                onClick={() => setAdditionalDetails("")}
                              />
                            </Tag>
                          )}
                        </Flex>
                        {error.additionalDetails && (
                          <Text textAlign="left" fontSize="sm" color="red.500">
                            {error.additionalDetails}
                          </Text>
                        )}
                        {errors.additionalDetails && (
                          <Text textAlign="left" fontSize="sm" color="red.500">
                            {errors["additionalDetails"]}
                          </Text>
                        )}
                      </>
                    )}
                    {!nonImmigrantVisaStatus && (
                      <Text textAlign="left" fontSize="sm" color="red.500">
                        {errors["nonImmigrantVisaStatus"]}
                      </Text>
                    )}
                  </>
                )}
              </Stack>
            </Box>
          </Card>
          <Card p="4" w="full">
            <Box maxW="800px" my="4">
              <Stack>
                <VStack w="full" justify="flex-start">
                  <Text fontWeight={"bold"} size="md" w="full">
                    Your desired salary?
                    <Box
                      as="span"
                      fontWeight="400"
                      color="red"
                      fontSize="md"
                      mt="1"
                    >
                      *
                    </Box>
                  </Text>
                  <HStack w="full" align="baseline">
                    <VStack w="full" align="start" gap="0">
                      <Select
                        value={salary?.currencyType}
                        onChange={handleSalaryChange}
                        size="md"
                        color={salary?.currencyType ? "black" : "gray.500"}
                        h="38px"
                        borderRadius="md"
                        name="currencyType"
                        placeholder="Please select currency"
                      >
                        <option value="USD">USD</option>
                        <option value="EUR">EUR</option>
                        <option value="GBP">GBP</option>
                      </Select>
                      {!salary?.currencyType && (
                        <Text
                          textAlign="left"
                          fontSize="sm"
                          color="red.500"
                          mt="2px !important"
                        >
                          {errors["currencyType"]}
                        </Text>
                      )}
                    </VStack>

                    <VStack w="full" align="start">
                      <Select
                        value={salary?.payType}
                        onChange={handleSalaryChange}
                        size="md"
                        color={salary?.payType ? "black" : "gray.500"}
                        h="38px"
                        borderRadius="md"
                        name="payType"
                        placeholder="Please select payment type"
                      >
                        <option value={0}>Hourly</option>
                        <option value={1}>Monthly</option>
                        <option value={2}>Annually</option>
                      </Select>
                      {!salary?.payType && (
                        <Text
                          textAlign="left"
                          fontSize="sm"
                          color="red.500"
                          mt="2px !important"
                        >
                          {errors["payType"]}
                        </Text>
                      )}
                    </VStack>
                  </HStack>
                  <Box maxW={"800px"} my="4" w="full">
                    <Stack>
                      <Text fontWeight={"bold"} size="sm">
                        Your desired annual total compensation
                        <Box
                          as="span"
                          fontWeight="400"
                          ml="2"
                          color="red"
                          fontSize="md"
                          mt="1"
                        >
                          *
                        </Box>
                      </Text>
                      <Input
                        value={salary?.totalCompensation}
                        onChange={handleSalaryChange}
                        placeholder="Enter total compensation"
                        bg="white"
                        maxW={{ base: "100%", md: "70%" }}
                        borderColor="grey"
                        name="totalCompensation"
                        type="text"
                        onInput={(e) => {
                          const inputElement = e.target as HTMLInputElement;
                          inputElement.value = inputElement.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                      />
                      {!salary?.totalCompensation && (
                        <Text
                          textAlign="left"
                          fontSize="sm"
                          color="red.500"
                          mt="2px !important"
                        >
                          {errors["totalCompensation"]}
                        </Text>
                      )}
                      <HStack w="full">
                        <Switch
                          id="email-alerts"
                          isChecked={salary?.isVisibleToEmployers}
                          onChange={() =>
                            setSalary((prevState: any) => ({
                              ...prevState,
                              isVisibleToEmployers:
                                !prevState.isVisibleToEmployers,
                            }))
                          }
                        />
                        <Text fontWeight="bold" mt="0px !important" size="md">
                          {salary?.isVisibleToEmployers
                            ? "Visible to employers"
                            : "Not visible to employers"}
                        </Text>
                      </HStack>
                    </Stack>
                  </Box>
                </VStack>
              </Stack>
            </Box>
          </Card>
          {pathname.includes("profile") && (
            <Card p="4" w="full">
              <FormControl
                position="relative"
                isInvalid={Boolean(error.prefferedIndustriesError)}
              >
                <FormLabel fontWeight={"bold"} fontSize="md">
                  Preferred Industries (Optional)
                </FormLabel>
                <HStack gap={3}>
                  <Input
                    maxLength={50}
                    type="text"
                    placeholder="Enter Industry Name"
                    maxW={{ base: "100%", md: "70%" }}
                    borderColor="grey"
                    onChange={(e) => setIndustryName(e.target.value.trimLeft())}
                    value={industryName}
                    ref={inputref}
                    onFocus={() => handleIndustryFocusBlur(true)}
                    onBlur={() => handleIndustryFocusBlur(false)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.stopPropagation();
                        e.preventDefault();
                        handleAddIndustry(industryName);
                      }
                    }}
                  />

                  <Button
                    ml="2"
                    colorScheme="buttonPrimary"
                    onClick={() => handleAddIndustry(industryName)}
                  >
                    Add
                  </Button>
                </HStack>
                {error.prefferedIndustriesError && (
                  <FormErrorMessage>
                    {error.prefferedIndustriesError}
                  </FormErrorMessage>
                )}
                {showJobIndustriesResult && jobIndustryResult?.length >= 1 && (
                  <VStack
                    ref={searchFieldRef}
                    height="100px"
                    overflowY="scroll"
                    position="absolute"
                    top="72px"
                    zIndex="1"
                    bg="white"
                    border="1px solid #F1F0F9"
                    borderRadius="10px"
                    w="70%"
                    boxShadow="0px 8px 32px 0px rgba(78, 48, 123, 0.04)"
                    padding="5px 0px"
                  >
                    {jobIndustryResult.map((data: any, index: number) => (
                      <Flex
                        w="full"
                        p="5px"
                        borderBottom="1px solid #eae8e8"
                        onClick={() => handleAddIndustry(data.name)}
                        _hover={{ bg: "#F1F0F9", color: "black" }}
                        key={index}
                        cursor="pointer"
                      >
                        <Text ms="4px">{data.name}</Text>
                      </Flex>
                    ))}
                  </VStack>
                )}
              </FormControl>

              <Flex gap={"10px"} pt="8px">
                {industriesList.map((Industry: any, index: any) => (
                  <Tag key={index}>
                    <TagLabel>{Industry}</TagLabel>
                    <TagCloseButton
                      onClick={() => handleRemoveIndustry(index)}
                    />
                  </Tag>
                ))}
              </Flex>
            </Card>
          )}
          <Card p="4" w="full">
            <Box maxW="800px" my="4">
              <Stack>
                <Text fontWeight="bold" size="md">
                  When can you start?
                  <Box
                    as="span"
                    fontWeight="400"
                    color="red"
                    fontSize="md"
                    mt="1"
                  >
                    *
                  </Box>
                </Text>
                <RadioGroup
                  options={[
                    "Immediately",
                    "With in a month",
                    "In 2-3 months",
                    "Custom date",
                  ]}
                  name="management"
                  defaultValue={
                    whenYouStartLookupReverse[otherInfo?.whenYouCanStart]
                  }
                  onChange={(value: string) => {
                    const whenToStart = whenYouStartLookup[value];
                    setWhenYouCanStart(whenToStart);
                    setErrors((prevError: any) => ({
                      ...prevError,
                      whenYouCanStart: "",
                    }));
                  }}
                  maxW="100%"
                />

                {!(whenYouCanStart === 0 || Boolean(whenYouCanStart)) && (
                  <Text textAlign={"left"} fontSize={"sm"} color={"red.500"}>
                    {errors["whenYouCanStart"]}
                  </Text>
                )}
                {whenYouCanStart === 3 && (
                  <Box w="50%">
                    <CustomDatePicker
                      label="Select date"
                      selected={customJoiningDate}
                      onChange={(value: any) => {
                        setCustomJoiningDate(value);
                      }}
                      minDate={new Date()}
                    />

                    <Text textAlign={"left"} fontSize={"sm"} color={"red.500"}>
                      {errors["whenYouCanStart"]}
                    </Text>
                  </Box>
                )}
              </Stack>
            </Box>
          </Card>
          <Card p="4" w="full">
            <Flex align="center" justify="center" w="full">
              <Button
                w="20vh"
                colorScheme="buttonPrimary"
                mt="4"
                isLoading={otherInfoAddLoading || isLoading}
                isDisabled={otherInfoAddLoading || isLoading}
                type="submit"
                // onClick={async () => {
                //   onSubmit(additionalDetails);
                // }}
              >
                {pathname === "/profile" ? "Save" : "Continue"}
              </Button>
            </Flex>
          </Card>
        </>
      </VStack>
    </form>
  );
};

export default Setup;
