import {
  Card,
  CardBody,
  Flex,
  HStack,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React from "react";
import Overview from "./overview";
import Schedule from "./schedule";
import useSettings from "./useSettings";
import { useUserStore } from "../../../store/useUser";

const Settings = () => {
  const { user } = useUserStore();

  const {
    onModalClose,
    onOpen,
    isOpen,
    handleEditButtonClick,
    handleFileInputChange,
    profilePicture,
    profileLogoLoading,
    fileInputRef,
    profileVideo,
    setCompanyProfile,
    profileData,
    watch,
  } = useSettings();

  return (
    <>
      {" "}
      {/* <Card boxShadow={"none"} borderRadius="none" mb="2" bg="#f7f6f8">
        <CardBody>
          <Stack
            direction="row"
            spacing={4}
            align="center"
            justify="space-between"
          >
             <Heading size="sm" color={"#959cb6"}>
             Profile Overview
              </Heading>
     
          </Stack>
        </CardBody>
      </Card> */}
      <Flex w="full" justify="center" align="flex-start" h="full" p="4" py="4">
        <HStack
          w="full"
          h="full"
          spacing={6}
          display={{ base: "none", md: "none", lg: "flex" }}
        >
          <Overview
            onModalClose={onModalClose}
            onOpen={onOpen}
            isOpen={isOpen}
            handleEditButtonClick={handleEditButtonClick}
            handleFileInputChange={handleFileInputChange}
            profilePicture={profilePicture}
            profileLogoLoading={profileLogoLoading}
            fileInputRef={fileInputRef}
            profileVideo={profileVideo}
            setCompanyProfile={setCompanyProfile}
            watch={watch}
            user={user}
          />
          <Schedule user={user} />
        </HStack>
        <Tabs w="full" display={{ base: "box", md: "box", lg: "none" }}>
          <TabList mb="2">
            <Tab>Overview</Tab>
            <Tab>Schedule/Notifications</Tab>
          </TabList>
          <TabPanels>
            <TabPanel p="0">
              {" "}
              <Overview
                onModalClose={onModalClose}
                onOpen={onOpen}
                isOpen={isOpen}
                handleEditButtonClick={handleEditButtonClick}
                handleFileInputChange={handleFileInputChange}
                profilePicture={profilePicture}
                profileLogoLoading={profileLogoLoading}
                fileInputRef={fileInputRef}
                profileVideo={profileVideo}
                setCompanyProfile={setCompanyProfile}
                watch={watch}
                user={user}
              />
            </TabPanel>
            <TabPanel p="0">
              {" "}
              <Schedule user={user} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </>
  );
};

export default Settings;
