import { useLocation, Navigate, Outlet, useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  HStack,
  IconButton,
  Image,
  VStack,
} from "@chakra-ui/react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import logo from "../../../../assets/images/logoBlack.svg";

const FlowWrapper = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <Box
      backgroundColor="gray.50"
      minH={{ base: "100%", md: "100vh" }}
      minW="100%"
    >
      <VStack mt="12" w="full" maxW="800px" m="auto" p="2" py="10" pt="20">
        {/* <Avatar
          size="lg"
          name="Logo"
          bg="gray"
          pos={"absolute"}
          left={{ base: "42%", md: "30px" }}
          top="20px"
          cursor={"pointer"}
          onClick={() => navigate("/setupFlow/location")}
        ></Avatar> */}
        <Image src={logo} maxW="200px" mb="8"   mr="30px"></Image>
        {!(pathname === "/setupFlow/location") && (
          <HStack w="full">
            <IconButton
              onClick={() => navigate(-1)}
              variant="outline"
              aria-label="open menu"
              border="none"
              icon={<AiOutlineArrowLeft fontSize={"25px"} />}
            />
          </HStack>
        )}
        <Outlet />{" "}
      </VStack>
    </Box>
  );
};

export default FlowWrapper;
