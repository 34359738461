import React, { useEffect, useRef } from "react";
import { Avatar, Flex, HStack, Text, VStack } from "@chakra-ui/react";
import { getTimeAgo, getUserId } from "../../../../utils/helper";
import { useUserStore } from "../../../../store/useUser";

const Messages = ({ message, selectedUser }: any) => {
  const userId = getUserId();
  const AlwaysScrollToBottom = () => {
    const elementRef: any = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return <div ref={elementRef} />;
  };
  const { user } = useUserStore();

  return (
    <Flex
      w="100%"
      h="100%"
      overflowY="scroll"
      flexDirection="column"
      px="6"
      py="3"
      minH={"45vh"}
    >
      {message?.map((item: any, index: any) => {
        if (item.sentBy === userId) {
          return (
            <VStack key={index}>
              <HStack w="full" justify="flex-end">
                <Text
                  color="#74788d"
                  fontSize={{ base: "11px", "2xl": "12px" }}
                >
                  {getTimeAgo(
                    new Date(
                      item?.sentAt.seconds * 1000 +
                        item.sentAt.nanoseconds / 1000000
                    )
                  )}
                </Text>
                <Text
                  fontWeight={"bold"}
                  color="#48465b"
                  fontSize={{ base: "13px", "2xl": "14px" }}
                  _hover={{ color: "#5867DD", cursor: "pointer" }}
                >
                  You
                </Text>
                <Avatar size="sm" name="" src={user?.profilePicture}></Avatar>
              </HStack>
              <Flex key={index} w="100%" justify="flex-end">
                <Flex
                  bg="#eef1ff"
                  minW="100px"
                  maxW="350px"
                  my="1"
                  p="3"
                  fontSize={{ base: "13px", "2xl": "14px" }}
                  color="#6c7293"
                  borderRadius={"md"}
                >
                  <Text fontWeight={"bold"} wordBreak={"break-all"}>
                    {item.text}
                  </Text>
                </Flex>
              </Flex>
            </VStack>
          );
        } else {
          return (
            <VStack key={index}>
              <HStack w="full">
                <Avatar
                  size="sm"
                  name=""
                  src={
                    selectedUser?.sendTo?.id === user?.userId
                      ? selectedUser?.sendBy?.profilePicture
                      : selectedUser?.sendTo?.profilePicture
                  }
                  // bg="blue.300"
                ></Avatar>
                <Text
                  fontWeight={"bold"}
                  color="#48465b"
                  fontSize={{ base: "13px", "2xl": "14px" }}
                  _hover={{ color: "#5867DD", cursor: "pointer" }}
                >
                  {selectedUser?.sendTo?.id === user?.userId
                    ? selectedUser?.sendBy?.name?.length > 30
                      ? selectedUser?.sendBy?.name.slice(0, 30) + "..."
                      : selectedUser?.sendBy?.name
                    : selectedUser?.sendTo?.name?.length > 30
                    ? selectedUser?.sendTo?.name.slice(0, 30) + "..."
                    : selectedUser?.sendTo?.name}
                </Text>
                <Text
                  color="#74788d"
                  fontSize={{ base: "11px", "2xl": "12px" }}
                >
                  {getTimeAgo(
                    new Date(
                      item?.sentAt.seconds * 1000 +
                        item.sentAt.nanoseconds / 1000000
                    )
                  )}
                </Text>
              </HStack>
              <Flex key={index} w="100%">
                <Flex
                  bg="#e6f8f3"
                  fontSize={{ base: "13px", "2xl": "14px" }}
                  color="#6c7293"
                  minW="100px"
                  maxW="350px"
                  my="1"
                  p="3"
                  borderRadius={"md"}
                >
                  <Text fontWeight={"bold"} wordBreak={"break-all"}>
                    {item.text}
                  </Text>
                </Flex>
              </Flex>
            </VStack>
          );
        }
      })}
      <AlwaysScrollToBottom />
    </Flex>
  );
};

export default Messages;
