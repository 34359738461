import React, { useEffect, useState } from "react";
import { getUserId } from "../../../utils/helper";
import { useFetchAppliedCandidates } from "../../../service/candidate.service";
import {
  useFetchJobFunc,
  useFetchUserJobs,
} from "../../../service/helper.service";

const useApplliedTalent = () => {
  const [jobFunction, setjobFunction] = useState("");
  const [query, setQuery] = useState("");
  const [initialFetch, setInitialFetch] = useState(true);
  const userId = getUserId();
  const {
    isLoading: loadingJobFunc,
    data: jobFunctionListOption,
    isSuccess,
  } = useFetchUserJobs(userId);
  const {
    isLoading: candidateLoading,
    data: candidateList,
    refetch: refetchCandidate,
  } = useFetchAppliedCandidates({ query, jobFunction });
  const selectJobFunc = (e: any) => {
    if (e.target.value === "All") {
      setjobFunction("");
    } else {
      setjobFunction(e.target.value);
    }
  };

  useEffect(() => {
    if (initialFetch) {
      refetchCandidate();
      setInitialFetch(false);
    } else {
      const delayDebounceFn = setTimeout(() => {
        refetchCandidate();
      }, 1000);

      return () => {
        clearTimeout(delayDebounceFn);
      };
    }
  }, [query, refetchCandidate, initialFetch]);
  useEffect(() => {
    refetchCandidate();
  }, [jobFunction]);

  const handleSearch = (event: any) => {
    setQuery(event.target.value);
  };

  return {
    candidateList,
    candidateLoading,
    jobFunctionListOption,
    selectJobFunc,
    jobFunction,
    handleSearch,
  };
};

export default useApplliedTalent;
