import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Center, Flex, Spinner } from "@chakra-ui/react";
import axios from "axios";

const LoginAs = () => {
  const navigate = useNavigate();
  const API = process.env.REACT_APP_BACKEND_URL;
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const token = searchParams.get("token");

  useEffect(() => {
    if (token) {
      const AuthStr = "Bearer ".concat(token);
      axios
        .get(`${API}/users/auth/login-as-data`, {
          headers: { Authorization: AuthStr },
        })
        .then((response) => {
          localStorage.setItem(
            "profilepercentage",
            JSON.stringify(response?.data?.profileCompletePercentage)
          );
          const acessToken = response?.data?.token;
          const dataString = JSON.stringify(response?.data);
          localStorage.setItem("data", dataString);
          localStorage.setItem("access_token", JSON.stringify(acessToken));

          navigate("/");
        })
        .catch((error) => console.log("ERROR", error));
    }
  }, [token]);
  return (
    <Flex justifyContent="center" alignItems="center">
      <Center h="100vh">
        <Spinner
          boxSize="150px"
          thickness="10px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Center>
    </Flex>
  );
};

export default LoginAs;
