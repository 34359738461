import { Box, Center, Text, Button, Card, HStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

function CongratulationPostJob() {
  const navigate = useNavigate();
  return (
    <Card p="10" maxW="800px" m="auto" mt="4">
      <Box mx="auto" textAlign="center" mt="6">
        <Text fontSize="3xl" fontWeight="bold" mb="4">
          Congratulations!
        </Text>
        <Text fontSize="lg" mb="3">
          You have successfully posted your job.
        </Text>
        <Text fontSize="lg" mb="3">
          You will receive notification on your job approval. Expect a
          confirmation shortly.
        </Text>
        <HStack justify="center">
          {" "}
          <Button
            colorScheme="buttonPrimary"
            size="md"
            w={{ base: "100%", md: "30%" }}
            onClick={() => navigate("/profile")}
          >
            Go to Profile
          </Button>
          <Button
            colorScheme="buttonPrimary"
            size="md"
            w={{ base: "100%", md: "30%" }}
            onClick={() => navigate("/jobs")}
          >
            Close
          </Button>
        </HStack>
      </Box>
    </Card>
  );
}

export default CongratulationPostJob;
