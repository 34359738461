import {
  Stack,
  Card,
  CardBody,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  VStack,
  Tag,
  Flex,
  Badge,
  HStack,
  Icon,
  Text,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { AiFillTags } from "react-icons/ai";
import { BiPencil } from "react-icons/bi";
import EditProfileEdu from "./EditProfileEducation";
import EditProfileExperience from "./EditProfileExperience";
import useEducation from "../../signupFlow/education/useEducation";
import moment from "moment";
import useExperience from "../../signupFlow/experience/useExperience";
import { getMonthsAndYears } from "../../../../utils/helper";
import { useNavigate } from "react-router-dom";

const ProfileEducationAndExperience = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const { isOpen: isExpModalOpen, onClose: onExpModalClose } = useDisclosure();
  const {
    educationList,
    setEditableData,
    finalSchoolName,
    setFinalSchoolName,
    setFinalFieldOfStudy,
    finalFieldofStudy,
    handleStartDateChange,
    handleEndDateChange,
  } = useEducation(onClose, true);

  const {
    register: registerExp,
    errors: errosExp,
    handleSubmit: handleSubmitExp,
    watch: watchExp,
    onSubmit: onSubmitExp,
    updateHandler: updateHandlerExp,
    control: controlExp,
    isSubmitted,
    handleOptionSkills,
    handleJobFunc,
    jobFunc,
    setJobFunc,
    topSkills,
    setTopSkills,
    setEditableDataExp,
    experienceList,
    setValue,
    trigger,
    industriesList,
    setIndustriesList,
    state,
    error,
    setError,
    handleStartDateChange: exphandleStartDateChange,
    handleEndDateChange: exphandleEndDateChange,
  } = useExperience(onExpModalClose);

  const onModalExperienceClose = () => {
    onExpModalClose();
    setEditableDataExp(null);
  };

  return (
    <>
      <Stack w="100%" pos="relative">
        <Card w="full">
          <CardBody p={{ base: "2", md: "5" }}>
            <Tabs>
              <TabList fontSize={"13px"}>
                <Tab
                  _selected={{ color: "#5D78FF", borderBottomColor: "#5D78FF" }}
                  pr="2px"
                >
                  Experience
                </Tab>
                <Tab
                  _selected={{ color: "#5D78FF", borderBottomColor: "#5D78FF" }}
                  pr="2px"
                >
                  Education
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <IconButton
                    variant="outline"
                    aria-label="Edit Icon"
                    borderRadius="full"
                    icon={<BiPencil />}
                    pos="absolute"
                    top="8px"
                    right="20px"
                    onClick={() =>
                      navigate("/setupFlow/experience", { state: "profile" })
                    }
                    cursor={"pointer"}
                  />
                  <VStack spacing={6} align="start">
                    {!!experienceList?.length &&
                      experienceList?.map((expData: any) => {
                        return (
                          <Card w="full" bg="#f7f8fc">
                            <CardBody>
                              {/* <IconButton
                                variant="outline"
                                aria-label="Edit Icon"
                                borderRadius="full"
                                icon={<BiPencil />}
                                pos="absolute"
                                top="0"
                                right="0"
                                onClick={() => {
                                  setEditableDataExp(expData);
                                  setTimeout(() => {
                                    onExpModalOpen();
                                  }, 500);
                                }}
                                cursor={"pointer"}
                              /> */}
                              <Tag
                                pos="absolute"
                                right="45px"
                                top="4px"
                                colorScheme="whatsapp"
                                bg={"#0abb87"}
                                color={"white"}
                                fontSize={"12px"}
                                borderRadius={"sm"}
                              >
                                {getMonthsAndYears(expData?.monthsWorked)}
                              </Tag>
                              <VStack>
                                <Flex
                                  flexDir={{ base: "column", md: "row" }}
                                  w="full"
                                  justify={"space-between"}
                                  mt="3"
                                >
                                  <Text
                                    fontWeight={"bold"}
                                    fontSize={{ base: "md", md: "lg" }}
                                    _hover={{ color: "#5867DD" }}
                                    color={"#595d63"}
                                  >
                                    {expData?.companyName}
                                  </Text>
                                  {expData?.isStillWorking ? (
                                    <Text
                                      color={"#646c9a"}
                                      fontWeight={"bold"}
                                      fontSize={"12px"}
                                      mr="5"
                                    >
                                      {moment(expData?.startDate).format(
                                        "MMM YYYY"
                                      )}{" "}
                                      – Present
                                    </Text>
                                  ) : (
                                    <Text
                                      color={"#646c9a"}
                                      fontWeight={"bold"}
                                      fontSize={"12px"}
                                      mr="5"
                                    >
                                      {moment(expData?.startDate).format(
                                        "MMM YYYY"
                                      )}{" "}
                                      -{" "}
                                      {moment(expData?.endDate).format(
                                        "MMM YYYY"
                                      )}
                                    </Text>
                                  )}
                                </Flex>
                                <Flex w="full">
                                  <Text
                                    fontSize={"sm"}
                                    color={"#646c9a"}
                                    fontWeight={"bold"}
                                  >
                                    {expData?.jobTitle}
                                    <Badge
                                      ml="1"
                                      fontSize="0.8em"
                                      colorScheme="blue"
                                      p="1"
                                      color={"#5867DD"}
                                      px="2"
                                      bg={"#5d78ff1a"}
                                      textTransform={"capitalize"}
                                    >
                                      {expData?.employmentType?.replace(
                                        "_",
                                        " "
                                      )}
                                    </Badge>
                                  </Text>
                                </Flex>
                                <Flex w="full">
                                  <Text fontSize={"sm"} color={"#646c9a"}>
                                    {expData?.jobLocation &&
                                      expData?.jobLocation}
                                  </Text>
                                </Flex>
                                <HStack w={"full"} alignItems="flex-start">
                                  <Icon
                                    as={AiFillTags}
                                    fontSize="30px"
                                    color="gray.500"
                                    mt="1.5"
                                  />
                                  <HStack flexWrap={"wrap"}>
                                    {expData?.jobFunc.map((item: any) => (
                                      <Badge
                                        variant="outline"
                                        key={item}
                                        px="4"
                                        bg="#fff"
                                        py="1"
                                        fontWeight="300"
                                        borderRadius={"xl"}
                                        mt="2 !important"
                                        ml="0px !important"
                                        mr="4px !important"
                                        fontSize={{ base: "8px", md: "12px" }}
                                        textTransform={"capitalize"}
                                      >
                                        {item}
                                      </Badge>
                                    ))}
                                  </HStack>
                                </HStack>
                              </VStack>
                            </CardBody>
                          </Card>
                        );
                      })}

                    {!experienceList?.length && (
                      <Flex
                        direction="column"
                        align="center"
                        justify="center"
                        w="full"
                        h="100px"
                      >
                        <Text color="#48465b">No Experience added.</Text>
                        <Text color="#48465b">
                          Please complete profile to add experience
                        </Text>
                      </Flex>
                    )}
                  </VStack>
                </TabPanel>
                <TabPanel>
                  <IconButton
                    variant="outline"
                    aria-label="Edit Icon"
                    borderRadius="full"
                    icon={<BiPencil />}
                    pos="absolute"
                    top="8px"
                    right="20px"
                    onClick={() =>
                      navigate("/setupFlow/education", { state: "profile" })
                    }
                    cursor="pointer"
                  />

                  <VStack spacing={6} align="start">
                    {educationList?.length &&
                      educationList.map((eduData: any) => {
                        return (
                          <Card w="full" bg="#f7f8fc">
                            <HStack
                              justify={"flex-end"}
                              w="full"
                              pos="relative"
                              top="2"
                              right="2"
                            >
                              {/* <IconButton
                                variant="outline"
                                aria-label="Edit Icon"
                                borderRadius="full"
                                icon={<BiPencil />}
                                onClick={() => {
                                  onOpen();
                                  setEditableData(eduData);
                                }}
                                cursor={"pointer"}
                              /> */}
                            </HStack>
                            <CardBody pt="0px">
                              <VStack>
                                <Flex
                                  flexDir={{ base: "column", md: "row" }}
                                  w="full"
                                  justify={"space-between"}
                                  mt="3"
                                >
                                  <Text
                                    fontWeight={"bold"}
                                    fontSize={{ base: "md", md: "md" }}
                                    color={"#595d63"}
                                    _hover={{ color: "#5867DD" }}
                                  >
                                    {eduData?.schoolName}
                                  </Text>
                                </Flex>
                                <Flex w="full" mt="0px !important">
                                  {eduData?.educationStatus === "INCOMPLETE" ? (
                                    <Text
                                      color={"#646c9a"}
                                      fontWeight={"bold"}
                                      fontSize={"12px"}
                                    >
                                      Incomplete
                                    </Text>
                                  ) : eduData?.educationStatus ===
                                    "COMPLETED" ? (
                                    <Text
                                      color={"#646c9a"}
                                      fontWeight={"bold"}
                                      fontSize={"12px"}
                                    >
                                      {moment(eduData?.startDate).format(
                                        "MMM YYYY"
                                      )}{" "}
                                      -{" "}
                                      {moment(eduData?.endDate).format(
                                        "MMM YYYY"
                                      )}
                                    </Text>
                                  ) : (
                                    <Text
                                      color={"#646c9a"}
                                      fontWeight={"bold"}
                                      fontSize={"12px"}
                                    >
                                      {moment(eduData?.startDate).format(
                                        "MMM YYYY"
                                      )}{" "}
                                      - Present
                                    </Text>
                                  )}
                                </Flex>
                                <Flex w="full">
                                  <Text
                                    fontSize={"sm"}
                                    color={"#646c9a"}
                                    fontWeight={"bold"}
                                  >
                                    {eduData?.degree}
                                  </Text>
                                </Flex>

                                <Flex w="full"></Flex>
                              </VStack>
                            </CardBody>
                          </Card>
                        );
                      })}

                    {!educationList?.length && (
                      <Flex
                        direction="column"
                        align="center"
                        justify="center"
                        w="full"
                        h="100px"
                      >
                        <Text color="#48465b">No Education added.</Text>
                        <Text color="#48465b">
                          Please complete profile to add education
                        </Text>
                      </Flex>
                    )}
                  </VStack>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </CardBody>
        </Card>
      </Stack>
      <EditProfileExperience
        isOpen={isExpModalOpen}
        onClose={onModalExperienceClose}
        register={registerExp}
        errors={errosExp}
        handleSubmit={handleSubmitExp}
        watch={watchExp}
        onSubmit={onSubmitExp}
        updateHandler={updateHandlerExp}
        control={controlExp}
        isSubmitted={isSubmitted}
        handleOptionSkills={handleOptionSkills}
        handleJobFunc={handleJobFunc}
        jobFunc={jobFunc}
        setJobFunc={setJobFunc}
        topSkills={topSkills}
        setTopSkills={setTopSkills}
        setValue={setValue}
        trigger={trigger}
        industriesList={industriesList}
        setIndustriesList={setIndustriesList}
        state={state}
        error={error}
        setError={setError}
        handleStartDateChange={exphandleStartDateChange}
        handleEndDateChange={exphandleEndDateChange}
      />
      {/* {
        <EditProfileEdu
          isOpen={isOpen}
          onClose={onClose}
          register={register}
          errors={errors}
          handleSubmit={handleSubmit}
          watch={watch}
          onSubmit={onSubmit}
          updateHandler={updateHandler}
          control={control}
          finalSchoolName={finalSchoolName}
          setFinalSchoolName={setFinalSchoolName}
          setFinalFieldOfStudy={setFinalFieldOfStudy}
          finalFieldofStudy={finalFieldofStudy}
          handleStartDateChange={handleStartDateChange}
          handleEndDateChange={handleEndDateChange}
        />
      } */}
    </>
  );
};

export default ProfileEducationAndExperience;
