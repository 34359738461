import { useEffect, useRef, useState } from "react";
import {
  Box,
  Input,
  Stack,
  Card,
  FormControl,
  FormLabel,
  VStack,
  HStack,
  Button,
  Text,
  Progress,
  Tag,
  TagLabel,
  TagCloseButton,
  Flex,
} from "@chakra-ui/react";
import { useWizard } from "react-use-wizard";
import CheckBoxButton from "../../../common/checkboxButton";
import RadioGroup from "../../../common/radioGroup";
import { useFetch } from "../../../../utils/useFetch";

const EducationJob = ({
  setMinEducationType,
  minEducation,
  preferredCollegeMajors,
  setPreferredCollegeMajors,
  setSponsorshipType,
  sponsorshipType,
  handleSponsorshipList,
  optionsSponsorship,
  sponsorshipList,
  mustHaveSkills,
  setMustHaveSkills,
  niceToHaveSkills,
  setNiceToHaveSkills,
}: any) => {
  const inputref = useRef<HTMLInputElement>(null);

  const { previousStep, nextStep, activeStep, stepCount } = useWizard();
  const [isSkillsFocused, setIsSkillsFocused] = useState(false);

  const [prefferedMajor, setPrefferedMajor] = useState("");
  const [mustSingleSkill, setMustSingleSkill] = useState("");
  const [showMustSkillResults, setShowMustSkillResults] = useState(false);
  const [searchMustSkillsResult, setSearchMustSkillsResult] = useState<any>([]);
  const [isMustSkillsFocused, setIsMustSkillsFocused] = useState(false);

  const [niceSingleSkill, setNiceSingleSkill] = useState("");

  const [showNiceSkillsResult, setShowNiceSkillsResult] = useState(false);
  const [searchNiceSkillsResult, setSearchNiceSkillsResult] = useState<any>([]);
  const [isNiceSkillsFocused, setIsNiceSkillsFocused] = useState(false);
  const progress = ((activeStep + 1) / stepCount) * 100;
  const [errors, setErrors] = useState({
    minEducation: "",
    sponsorshipList: "",
    sponsershipType: "",
    topSkillsError: "",
    nicetoHaveSkillsError: "",
    prefferedMajorsError: "",
  });
  const [fieldResult, setFieldResult] = useState([]);
  const [showfieldResult, setShowfieldResult] = useState(false);
  const [isFieldFocused, setIsFieldFocused] = useState(false);

  const handleFieldFocusBlur = (value: boolean) => {
    setIsFieldFocused(value);
  };
  useEffect(() => {
    if (isFieldFocused) {
      if (prefferedMajor?.length > 0) {
        // setPreferredCollegeMajors(fieldOfStudy);
        useFetch
          .get(`/users/domain/all-study-field-list?query=${prefferedMajor}`)
          .then((response) => {
            setFieldResult(response.data);
            setShowfieldResult(true);
          })
          .catch((error) => {
            console.log("Error", error);
          });
      } else {
        setShowfieldResult(false);
      }
    }
  }, [prefferedMajor]);
  const handleFieldName = (search: any) => {
    setPrefferedMajor(search);
    // setPreferredCollegeMajors(search);
    setShowfieldResult(false);
  };
  const searchFieldRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        searchFieldRef.current &&
        !searchFieldRef.current.contains(event.target as Node)
      ) {
        handleFieldFocusBlur(false);
        setShowfieldResult(false);
        handleMustSkillsFocusBlur(false);
        setShowMustSkillResults(false);
        handleNiceSkillsFocusBlur(false);
        setShowNiceSkillsResult(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleRemoveMusthaveTag = (indexToRemove: number) => {
    setMustHaveSkills((prevMustHaveSkills: any) =>
      prevMustHaveSkills.filter(
        (_: any, index: number) => index !== indexToRemove
      )
    );
  };

  useEffect(() => {
    if (isNiceSkillsFocused) {
      if (niceSingleSkill?.length > 0) {
        try {
          useFetch
            .get(
              `/users/domain/all-job-skill-name-list?query=${niceSingleSkill}`
            )
            .then((response) => {
              setSearchNiceSkillsResult(response.data);
              setShowNiceSkillsResult(true);
            })
            .catch((error) => {
              console.log("Error", error);
            });
        } catch (error) {
          console.log("ERROR", error);
        }
      } else {
        setShowNiceSkillsResult(false);
      }
    }
  }, [niceSingleSkill]);
  useEffect(() => {
    if (isMustSkillsFocused) {
      if (mustSingleSkill?.length > 0) {
        try {
          useFetch
            .get(
              `/users/domain/all-job-skill-name-list?query=${mustSingleSkill}`
            )
            .then((response) => {
              setSearchMustSkillsResult(response.data);
              setShowMustSkillResults(true);
            })
            .catch((error) => {
              console.log("Error", error);
            });
        } catch (error) {
          console.log("ERROR", error);
        }
      } else {
        setShowMustSkillResults(false);
      }
    }
  }, [mustSingleSkill]);
  const handleValidation = () => {
    let formIsValid = true;

    if (!minEducation) {
      formIsValid = false;
      setErrors((prevErrors) => ({
        ...prevErrors,
        minEducation: "Select at least one option",
      }));
    }

    // if (!preferredCollegeMajors.length) {
    //   formIsValid = false;
    //   setErrors((prevErrors) => ({
    //     ...prevErrors,
    //     prefferedMajorsError: "Select at least one option",
    //   }));
    // }

    if (mustHaveSkills?.length === 0) {
      formIsValid = false;
      setErrors((prevErrors) => ({
        ...prevErrors,
        topSkillsError: "Please add at least one skill",
      }));
    }

    // if (niceToHaveSkills.length === 0) {
    //   formIsValid = false;
    //   setErrors((prevErrors) => ({
    //     ...prevErrors,
    //     nicetoHaveSkillsError: "Please add at least one skill",
    //   }));
    // }

    // if (!sponsorshipType) {
    //   formIsValid = false;
    //   setErrors((prevErrors) => ({
    //     ...prevErrors,
    //     sponsershipType: "Please select Visa sponsorship type",
    //   }));
    // }

    if (sponsorshipType === "Yes" && sponsorshipList?.length === 0) {
      formIsValid = false;
      setErrors((prevErrors) => ({
        ...prevErrors,
        sponsorshipList: "Please select available Visa sponsorship type",
      }));
    }

    return formIsValid;
  };
  const handlePrefferedLocation = (prefferedMajorProps: string) => {
    if (prefferedMajorProps) {
      setShowfieldResult(false);

      if (preferredCollegeMajors.includes(prefferedMajorProps)) {
        setErrors((prevError: any) => ({
          ...prevError,
          prefferedMajorsError: "You have already selected this major",
        }));
      } else if (preferredCollegeMajors?.length >= 3) {
        setErrors((prevError: any) => ({
          ...prevError,
          prefferedMajorsError: "You can Add up to 3 options",
        }));
      } else {
        setPreferredCollegeMajors((prevJobFuncList: any) => [
          ...prevJobFuncList,
          prefferedMajorProps,
        ]);
        setPrefferedMajor("");
        setErrors((prevError: any) => ({
          ...prevError,
          prefferedMajorsError: "",
        }));
      }
    } else {
      setErrors((prevError: any) => ({
        ...prevError,
        prefferedMajorsError: "Please fill the required field",
      }));
    }
  };
  const handleRemoveTag = (indexToRemove: number) => {
    setPreferredCollegeMajors((prevMajors: any) =>
      prevMajors.filter((_: any, index: number) => index !== indexToRemove)
    );
  };
  const handleMustSkillsFocusBlur = (value: boolean) => {
    setIsMustSkillsFocused(value);
  };
  const handleNiceSkillsFocusBlur = (value: boolean) => {
    setIsNiceSkillsFocused(value);
  };

  const handleAddTopMustSkills = (mustSingleSkillProps: string) => {
    if (mustSingleSkillProps) {
      setShowMustSkillResults(false);

      if (mustHaveSkills.includes(mustSingleSkillProps)) {
        setErrors((prevError: any) => ({
          ...prevError,
          topSkillsError: "You have already selected this skill",
        }));
      } else if (mustHaveSkills?.length >= 3) {
        setErrors((prevError: any) => ({
          ...prevError,
          topSkillsError: "You can Add up to 3 options",
        }));
      } else {
        setMustHaveSkills((prevJobFuncList: any) => [
          ...prevJobFuncList,
          mustSingleSkillProps,
        ]);
        setMustSingleSkill("");
        setErrors((prevError: any) => ({ ...prevError, topSkillsError: "" }));
      }
    } else {
      setErrors((prevError: any) => ({
        ...prevError,
        topSkillsError: "Please fill the required field",
      }));
    }
  };
  const handleNicetoHaveSkills = (niceSingleSkillProps: string) => {
    if (niceSingleSkillProps) {
      setShowNiceSkillsResult(false);

      if (niceToHaveSkills.includes(niceSingleSkillProps)) {
        setErrors((prevError: any) => ({
          ...prevError,
          nicetoHaveSkillsError: "You have already selected this skill",
        }));
      } else if (niceToHaveSkills?.length >= 3) {
        setErrors((prevError: any) => ({
          ...prevError,
          nicetoHaveSkillsError: "You can Add up to 3 options",
        }));
      } else {
        setNiceToHaveSkills((prevJobFuncList: any) => [
          ...prevJobFuncList,
          niceSingleSkillProps,
        ]);
        setNiceSingleSkill("");
        setErrors((prevError: any) => ({
          ...prevError,
          nicetoHaveSkillsError: "",
        }));
      }
    } else {
      setErrors((prevError: any) => ({
        ...prevError,
        nicetoHaveSkills: "Please fill the required field",
      }));
    }
  };
  const handleRemoveNiceTohaveTag = (indexToRemove: number) => {
    setNiceToHaveSkills((prevNiceToHaveSkills: any) =>
      prevNiceToHaveSkills.filter(
        (_: any, index: number) => index !== indexToRemove
      )
    );
  };

  const handleTopMustSkills = (search: any) => {
    setMustSingleSkill(search);
    setShowMustSkillResults(false);
  };
  const handleTopNiceSkills = (search: any) => {
    setNiceSingleSkill(search);
    setShowNiceSkillsResult(false);
  };
  const AlwaysScrollToTop = () => {
    const elementRef: any = useRef();
    useEffect(() =>
      elementRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      })
    );
    return <div ref={elementRef} />;
  };
  console.log("SPONSER ", sponsorshipType);
  console.log("SPONSER LIST", sponsorshipList);

  return (
    <VStack w="full">
      <Card p="4" w="full">
        <Box>
          <Progress
            colorScheme="progressBar"
            size="md"
            w="full"
            borderRadius={"lg"}
            my="6"
            value={progress}
            mb={8}
          />
        </Box>
        <Box maxW={"800px"} my="4">
          <Stack>
            <FormControl isRequired>
              <FormLabel size="md" fontWeight="600">
                Minimum Education Level
              </FormLabel>
            </FormControl>

            <RadioGroup
              options={[
                "N/A (Not Applicable)",
                "High School Diploma",
                "Bachelor's",
                "Master's",
                "Ph.D. or Higher",
              ]}
              name="minEducation"
              defaultValue={""}
              value={minEducation}
              onChange={(value: string) => {
                setMinEducationType(value);
              }}
              maxW="100%"
            />
            {!minEducation && (
              <Text color="red" pl="1">
                {errors?.minEducation}
              </Text>
            )}
            <Text fontSize="16px" fontWeight="700">
              Preferred College Majors:
            </Text>
            <FormControl
              position="relative"
              isInvalid={!!errors?.prefferedMajorsError}
            >
              <HStack>
                <Input
                  type="text"
                  placeholder="Enter college major"
                  w={{ base: "100%", md: "70%" }}
                  border="1px solid grey"
                  onChange={(e) => setPrefferedMajor(e.target.value.trimLeft())}
                  onFocus={() => handleFieldFocusBlur(true)}
                  onBlur={() => handleFieldFocusBlur(false)}
                  value={prefferedMajor}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.stopPropagation();
                      e.preventDefault();
                      handlePrefferedLocation(prefferedMajor);
                    }
                  }}
                />
                <Button
                  colorScheme="buttonPrimary"
                  onClick={() => handlePrefferedLocation(prefferedMajor)}
                  w="65px"
                >
                  Add
                </Button>
              </HStack>
              {errors.prefferedMajorsError && (
                <Text color="red.500" fontSize="sm">
                  {errors.prefferedMajorsError}
                </Text>
              )}
              {showfieldResult && fieldResult?.length >= 1 && (
                <VStack
                  ref={searchFieldRef}
                  w={{ base: "calc(100% - 70px)", md: "70%" }}
                  position="absolute"
                  top="41px"
                  zIndex="99"
                  p="2px"
                  align="start"
                  boxShadow="md"
                  bg="white"
                  borderRadius="0px 0px 20px 20px"
                  maxH="250px"
                  overflowY="auto"
                >
                  {fieldResult &&
                    fieldResult.map((data: any) => (
                      <Flex
                        key={data}
                        onClick={() => handlePrefferedLocation(data.name)}
                        w="full"
                        cursor="pointer"
                        _hover={{ bg: "gray.50" }}
                        m="0"
                      >
                        <Text
                          m="0px"
                          fontWeight="400"
                          fontSize="16px"
                          padding="8px 10px"
                          color="#737098"
                          letterSpacing="-0.16px"
                        >
                          {data.name}
                        </Text>
                      </Flex>
                    ))}
                </VStack>
              )}
              <Flex gap="10px" pt="10px">
                {preferredCollegeMajors.map(
                  (Industry: string, index: number) => (
                    <Tag key={index}>
                      <TagLabel>{Industry}</TagLabel>
                      <TagCloseButton onClick={() => handleRemoveTag(index)} />
                    </Tag>
                  )
                )}
              </Flex>
            </FormControl>

            <Box maxW={"800px"} my="1">
              <FormControl
                isRequired
                isInvalid={Boolean(errors.topSkillsError)}
              >
                <FormLabel size="md" fontWeight="600">
                  Must-Have Skills/Qualifications
                </FormLabel>
                <Flex direction="column" w="full" position="relative">
                  <HStack>
                    <Input
                      type="text"
                      placeholder="Search Top Skills"
                      maxW={{ base: "100%", md: "70%" }}
                      borderColor="grey"
                      onChange={(e) =>
                        setMustSingleSkill(e.target.value.trimLeft())
                      }
                      value={mustSingleSkill}
                      ref={inputref}
                      onFocus={() => handleMustSkillsFocusBlur(true)}
                      onBlur={() => handleMustSkillsFocusBlur(false)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.stopPropagation();
                          e.preventDefault();
                          handleAddTopMustSkills(mustSingleSkill);
                        }
                      }}
                    />
                    <Button
                      ml="2"
                      colorScheme="buttonPrimary"
                      onClick={() => handleAddTopMustSkills(mustSingleSkill)}
                      w="65px"
                    >
                      Add
                    </Button>
                  </HStack>

                  {errors.topSkillsError && (
                    <Text color="red.500" fontSize="sm">
                      {errors.topSkillsError}
                    </Text>
                  )}

                  {showMustSkillResults &&
                    searchMustSkillsResult?.length >= 1 && (
                      <VStack
                        ref={searchFieldRef}
                        w={{ base: "calc(100% - 70px)", md: "70%" }}
                        position="absolute"
                        top="41px"
                        zIndex="99"
                        p="2px"
                        align="start"
                        boxShadow="md"
                        bg="white"
                        borderRadius="0px 0px 20px 20px"
                        maxH="250px"
                        overflowY="auto"
                      >
                        {searchMustSkillsResult.map(
                          (data: any, index: number) => (
                            <Flex
                              w="full"
                              p="5px"
                              borderBottom="1px solid #eae8e8"
                              onClick={() => handleAddTopMustSkills(data.name)}
                              _hover={{ bg: "#F1F0F9", color: "black" }}
                              key={index}
                              cursor="pointer"
                            >
                              <Text ms="4px">{data.name}</Text>
                            </Flex>
                          )
                        )}
                      </VStack>
                    )}
                </Flex>
              </FormControl>
              <Flex gap={"10px"} pt="10px">
                {mustHaveSkills.map((skills: string, index: number) => (
                  <Tag key={index}>
                    <TagLabel>{skills}</TagLabel>
                    <TagCloseButton
                      onClick={() => handleRemoveMusthaveTag(index)}
                    />
                  </Tag>
                ))}
              </Flex>
              <Stack my={2}>
                <FormControl
                  gap="10px"
                  isInvalid={Boolean(errors?.nicetoHaveSkillsError)}
                >
                  <FormLabel size="md" fontWeight="600">
                    Nice-to-Have Skills/Qualifications
                  </FormLabel>
                  <Flex direction="column" w="full" position="relative">
                    <HStack>
                      <Input
                        type="text"
                        placeholder="Search Top Skills"
                        maxW={{ base: "100%", md: "70%" }}
                        borderColor="grey"
                        value={niceSingleSkill}
                        onChange={(e) =>
                          setNiceSingleSkill(e.target.value.trimLeft())
                        }
                        ref={inputref}
                        onFocus={() => handleNiceSkillsFocusBlur(true)}
                        onBlur={() => handleNiceSkillsFocusBlur(false)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.stopPropagation();
                            e.preventDefault();
                            handleNicetoHaveSkills(niceSingleSkill);
                          }
                        }}
                      />
                      <Button
                        ml="2"
                        colorScheme="buttonPrimary"
                        onClick={() => handleNicetoHaveSkills(niceSingleSkill)}
                        w="65px"
                      >
                        Add
                      </Button>
                    </HStack>
                    {errors.nicetoHaveSkillsError && (
                      <Text color="red.500" fontSize="sm">
                        {errors.nicetoHaveSkillsError}
                      </Text>
                    )}
                    {showNiceSkillsResult &&
                      searchNiceSkillsResult?.length >= 1 && (
                        <VStack
                          ref={searchFieldRef}
                          w={{ base: "calc(100% - 70px)", md: "70%" }}
                          position="absolute"
                          top="41px"
                          zIndex="99"
                          p="2px"
                          align="start"
                          boxShadow="md"
                          bg="white"
                          borderRadius="0px 0px 20px 20px"
                          maxH="250px"
                          overflowY="auto"
                        >
                          {searchNiceSkillsResult.map(
                            (data: any, index: number) => (
                              <Flex
                                w="full"
                                p="5px"
                                borderBottom="1px solid #eae8e8"
                                onClick={() =>
                                  handleNicetoHaveSkills(data.name)
                                }
                                _hover={{ bg: "#F1F0F9", color: "black" }}
                                key={index}
                                cursor="pointer"
                              >
                                <Text ms="4px">{data.name}</Text>
                              </Flex>
                            )
                          )}
                        </VStack>
                      )}
                  </Flex>
                </FormControl>
                <Flex gap={"10px"} pt="5px">
                  {niceToHaveSkills.map((skills: string, index: number) => (
                    <Tag key={index}>
                      <TagLabel>{skills}</TagLabel>
                      <TagCloseButton
                        onClick={() => handleRemoveNiceTohaveTag(index)}
                      />
                    </Tag>
                  ))}
                </Flex>
              </Stack>
              {/* <Stack my={3} gap="10px">
                {!mustHaveSkills.length && (
              <Text color="red" pl="1">
                {errors?.mustHaveSkills}
              </Text>
            )}
              </Stack> */}
            </Box>
            <Stack>
              <Text size="md" fontWeight="600">
                Will You Provide Work Visa Sponsorship?
              </Text>
              <RadioGroup
                options={[
                  "Yes",
                  "No",
                  "Don't know / not decided yet",
                  "It depends",
                ]}
                name="management"
                defaultValue={""}
                value={sponsorshipType}
                onChange={(value: string) => {
                  setSponsorshipType(value);
                }}
                maxW="100%"
              />
              {!sponsorshipType && (
                <Text color="red" pl="1">
                  {errors?.sponsershipType}
                </Text>
              )}
              {sponsorshipType === "Yes" && (
                <>
                  <Flex align="start" gap="2px">
                    <Text fontSize="16px" fontWeight="600">
                      Select types of visa sponsorship available
                    </Text>
                    <Box
                      as="span"
                      color="red.500"
                      fontSize="md"
                      fontWeight="600"
                    >
                      *
                    </Box>
                  </Flex>
                  {/* <Input
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    bg="white"
                    maxW={{ base: "100%", md: "70%" }}
                    borderColor="grey"
                  /> */}
                  <CheckBoxButton
                    handleOptionSelection={handleSponsorshipList}
                    options={optionsSponsorship}
                    selectedOptions={sponsorshipList}
                  />
                  {!sponsorshipList?.length && (
                    <Text color="red" pl="1">
                      {errors?.sponsorshipList}
                    </Text>
                  )}
                </>
              )}
            </Stack>
          </Stack>
        </Box>
        <HStack
          spacing={"24px"}
          justify={"space-between"}
          w="full"
          mt="6 !important"
        >
          <Button
            loadingText="Loading"
            color="#74788d"
            spinnerPlacement="end"
            size="md"
            fontWeight={"bold"}
            _hover={{ color: "white", bg: "#5867DD" }}
            // leftIcon={<IoIosArrowBack />}
            onClick={() => {
              previousStep();
            }}
          >
            Back
          </Button>
          <Button
            colorScheme="buttonPrimary"
            onClick={() => {
              if (handleValidation()) {
                nextStep();
              }
            }}
          >
            Next
          </Button>
        </HStack>
      </Card>
      <AlwaysScrollToTop />
    </VStack>
  );
};

export default EducationJob;
