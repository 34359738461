import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Center,
  Flex,
  Heading,
  IconButton,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import EditProfileOtherInfo from "./EditProfileOtherInfo";
import useSetup from "../../signupFlow/setup/useSetup";
import { BiPencil } from "react-icons/bi";

const ProfileOtherInfo = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    otherInfo,
    seniorityLevelLookupReverse,
    workVisaRequiredLookupReverse,
  } = useSetup();

  return (
    <>
      {otherInfo && otherInfo?.interestedJobFunctions ? (
        <Box
          position="relative"
          width="full"
          background="white"
          p={{ base: "2", md: "5" }}
        >
          <Heading size="md" color="#646c9a">
            Job Preferences
          </Heading>
          <Flex
            pt="20px"
            mt={{ base: "25px", md: "0px" }}
            gap={10}
            flexDir="column"
            alignItems="start"
            p={{ base: "2", md: "5" }}
          >
            <Flex gap="2">
              <Text
                flex="1"
                minW={{ base: "100px", md: "200px" }}
                fontSize={{ base: "sm", md: "md" }}
                maxW="200px"
              >
                Job Functions
              </Text>
              <Flex gap="2" flexWrap="wrap">
                {otherInfo?.interestedJobFunctions?.map((element: string) => (
                  <Text
                    fontSize="13px"
                    background="#5867DD"
                    color="#fff"
                    fontWeight="bold"
                    borderRadius="lg"
                    px="2"
                    py="1"
                    w="fit-content"
                  >
                    {element}
                  </Text>
                ))}
              </Flex>
            </Flex>
            <Flex gap="2">
              <Text
                flex="1"
                minW={{ base: "100px", md: "200px" }}
                fontSize={{ base: "sm", md: "md" }}
                maxW="200px"
              >
                Job Search
              </Text>
              <Flex gap="1" flexWrap="wrap">
                {otherInfo?.jobSearch !== null && (
                  <Text
                    background="#5867DD"
                    color="#fff"
                    fontWeight="bold"
                    borderRadius="lg"
                    px="2"
                    py="1"
                    fontSize="13px"
                  >
                    {otherInfo?.jobSearch !== null && otherInfo?.jobSearch === 0
                      ? "Passively looking"
                      : otherInfo?.jobSearch === 1 && "Actively searching"}
                  </Text>
                )}
              </Flex>
            </Flex>
            <Flex gap="2">
              <Text
                minW={{ base: "100px", md: "200px" }}
                fontSize={{ base: "sm", md: "md" }}
                maxW="200px"
              >
                Seniority Level
              </Text>
              <Flex gap="2" flexWrap="wrap">
                <Text
                  background="#5867DD"
                  color="#fff"
                  fontWeight="bold"
                  borderRadius="lg"
                  px="2"
                  py="1"
                  fontSize="13px"
                >
                  {seniorityLevelLookupReverse[otherInfo?.seniorityLevel]}
                </Text>
              </Flex>
            </Flex>
            <Flex gap="2">
              <Text
                minW={{ base: "100px", md: "200px" }}
                fontSize={{ base: "sm", md: "md" }}
                maxW="200px"
              >
                Sponsorship
              </Text>
              {otherInfo?.workVisaRequired !== null && (
                <Flex gap="2" flexWrap="wrap">
                  {otherInfo?.workVisaRequired === 0 &&
                  otherInfo?.nonImmigrantVisaStatus !== "" ? (
                    <Text
                      background="#5867DD"
                      color="#fff"
                      fontWeight="bold"
                      borderRadius="lg"
                      px="2"
                      py="1"
                      fontSize="13px"
                    >
                      {otherInfo?.nonImmigrantVisaStatus}
                    </Text>
                  ) : (
                    <Text
                      background="#5867DD"
                      color="#fff"
                      fontWeight="bold"
                      borderRadius="lg"
                      px="2"
                      py="1"
                      fontSize="13px"
                    >
                      {
                        workVisaRequiredLookupReverse[
                          otherInfo?.workVisaRequired
                        ]
                      }
                    </Text>
                  )}
                </Flex>
              )}
            </Flex>
            <Flex gap="2">
              <Text
                minW={{ base: "100px", md: "200px" }}
                fontSize={{ base: "sm", md: "md" }}
                maxW="200px"
              >
                Industries I like to work
              </Text>
              <Flex gap="2" flexWrap="wrap">
                {otherInfo?.interestedIndustry?.map((element: string) => (
                  <Text
                    background="#5867DD"
                    color="#fff"
                    fontWeight="bold"
                    borderRadius="lg"
                    px="2"
                    py="1"
                    fontSize="13px"
                  >
                    {element}
                  </Text>
                ))}
              </Flex>
            </Flex>
          </Flex>
          <IconButton
            position="absolute"
            top="15px"
            right="15px"
            variant="outline"
            aria-label="Edit Icon"
            borderRadius="full"
            icon={<BiPencil />}
            onClick={onOpen}
          />
        </Box>
      ) : (
        <Card boxShadow={"none"} borderRadius="none" w="full">
          <CardHeader>
            {" "}
            <Heading size="md" color="#646c9a">
              Job Preferences
            </Heading>
          </CardHeader>
          <CardBody>
            <Center>No Data Available</Center>
          </CardBody>
        </Card>
      )}
      <EditProfileOtherInfo isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default ProfileOtherInfo;
