import {
  Card,
  CardBody,
  Flex,
  HStack,
  IconButton,
  Text,
  VStack,
} from "@chakra-ui/react";
import moment from "moment";
import useExperience from "./useExperience";
import { RiDeleteBin2Line } from "react-icons/ri";
import { BiPencil } from "react-icons/bi";
import { getUserId } from "../../../../utils/helper";

const ExperienceCard = ({ data, index }: any) => {
  const userId = getUserId();
  const { deleteHandler, navigate, experienceDelLoading } = useExperience();
  const { jobTitle, companyName, startDate, endDate, id, currentlyWorking } =
    data;

  return (
    <Card w="full">
      <CardBody p="2">
        <HStack justify="flex-end" w="full">
          <IconButton
            aria-label="edit"
            icon={<BiPencil />}
            size="sm"
            as="span"
            onClick={() =>
              navigate("add", { state: { ...data, isEdit: true } })
            }
            cursor={"pointer"}
            color={"blue.500"}
          />
          <IconButton
            aria-label="delete"
            icon={<RiDeleteBin2Line />}
            size="sm"
            as="span"
            onClick={() => deleteHandler(id, userId)}
            isLoading={experienceDelLoading}
            cursor="pointer"
            color="red.500"
          />
        </HStack>
        <VStack w="full" align="baseline">
          <HStack w="full">
            <Text fontWeight="bold"> {`Job Title: `}</Text>
            <Text>
              {jobTitle?.length > 60 ? jobTitle.slice(0, 60) + "..." : jobTitle}{" "}
            </Text>
          </HStack>
          <HStack w="full">
            <Text fontWeight={"bold"}> {`Company Name: `}</Text>
            <Text>
              {" "}
              {companyName?.length > 60
                ? companyName?.slice(0, 60) + "..."
                : companyName}{" "}
            </Text>
          </HStack>
          <Flex
            direction={{ base: "column", md: "row" }}
            w="full"
            justify={{ base: "start", md: "space-between" }}
            align={{ base: "start", md: "center" }}
          >
            <HStack align="baseline">
              <Text fontWeight="bold"> {`Start Date: `}</Text>
              <Text> {moment(startDate).format("MMMM YYYY")}</Text>
            </HStack>
            {endDate ? (
              <>
                <HStack align="baseline">
                  <Text fontWeight="bold">{`End Date: `}</Text>

                  <Text>{moment(endDate).format("MMMM YYYY")}</Text>
                </HStack>
              </>
            ) : (
              <HStack align="baseline">
                <Text fontWeight="bold">Currently Working</Text>
                <Text fontWeight="bold"></Text>
              </HStack>
            )}
          </Flex>
        </VStack>
      </CardBody>
    </Card>
  );
};

export default ExperienceCard;
