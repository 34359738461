import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Icon,
  Text,
  VStack,
  useToast,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  Select,
} from "@chakra-ui/react";
import { Suspense, useState } from "react";
import { useEffect } from "react";
import MyTasks from "./my-tasks";
import MySchedule from "./my-schedule";
import { useFetch } from "../../../../utils/useFetch";
import {
  RiSendPlaneFill,
  RiDeleteBin6Line,
  RiEditBoxLine,
} from "react-icons/ri";
import { useAllTeamMembers } from "../../../../store/useStore";

const Schedule = ({ user }: any) => {
  const { teamMembersList, setTeamMembersList, fetchTeamList } =
    useAllTeamMembers();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [role, setRole] = useState("");
  const [teamMember, setTeamMember] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoader, setIsloader] = useState({
    email: "",
    boolean: false,
  });
  const [error, setError] = useState("");
  const toast = useToast();
  useEffect(() => {}, []);

  const handleAddTeamMember = (email: string, roleName: string) => {
    setIsloader({ email: email, boolean: true });
    useFetch
      .post(`users/employer/invite-team-member`, {
        email,
        roleName,
      })
      .then((res) => {
        setIsloader({ email: "", boolean: false });
        fetchTeamList();
        toast({
          title: `Invite has been Re-sent`,
          description: "",
          status: "success",
          variant: "top-accent",
          isClosable: true,
        });
      })
      .catch((error) => {
        setIsloader({ email: "", boolean: false });

        console.log("err", error);
        toast({
          title: "Invite already consumed",
          description: "",
          status: "error",
          variant: "top-accent",
          isClosable: true,
        });
      });
  };
  const handleEditTeamMember = () => {
    if (!role) {
      setError("Role is Required");
    } else {
      setIsLoading(true);
      useFetch
        .put(`users/employer/update-team-member`, {
          id: teamMember?.id,
          roleName: role,
        })
        .then((res) => {
          setIsLoading(false);
          fetchTeamList();
          toast({
            title: `Team Member Updated Successfully`,
            description: "",
            status: "success",
            variant: "top-accent",
            isClosable: true,
          });
          onClose();
        })
        .catch((error) => {
          setIsLoading(false);
          console.log("err", error);
          toast({
            title: "Error Updating Team Member",
            description: `${error?.data ? error?.data : ""}`,
            status: "error",
            variant: "top-accent",
            isClosable: true,
          });
          onClose();
        });
    }
  };
  const handleDeleteTeamMember = (teamMemberId: string) => {
    setIsloader({ email: teamMemberId, boolean: true });
    useFetch
      .delete(`users/employer/delete-team-member?teamMemberId=${teamMemberId}`)
      .then((res) => {
        setIsloader({ email: "", boolean: false });

        fetchTeamList();
        toast({
          title: "Member Deleted",
          description: "",
          status: "success",
          variant: "top-accent",
          isClosable: true,
        });
      })
      .catch((error) => {
        setIsloader({ email: "", boolean: false });

        console.log("err", error);
        toast({
          title: "Member Not Deleted",
          description: "",
          status: "error",
          variant: "top-accent",
          isClosable: true,
        });
      });
  };

  return (
    <VStack w={["100%", "100%", "100%", "65%"]} h="full" borderRadius={"md"}>
      <VStack align={"flex-start"} w="full">
        <Box w="content-fit" display={{ base: "none", lg: "flex" }}>
          <Text
            ml="2"
            textAlign={"left"}
            color="#5D78FF"
            borderBottom={"2px solid #5D78FF"}
            pb="2"
            fontWeight={"bold"}
            fontSize={"16px"}
            whiteSpace={"nowrap"}
          >
            Overview
          </Text>
        </Box>
      </VStack>

      <MySchedule />
      <MyTasks />
      <Card w="100%" mb={{ base: "4 !important", lg: "0 !important" }}>
        <CardHeader>
          <Text fontWeight={"bold"} size={"lg"}>
            Team Members
          </Text>
        </CardHeader>
        <CardBody maxH="27vh" overflow="scroll">
          <Suspense fallback={<div>Loading...</div>}>
            {teamMembersList && teamMembersList.length > 0 ? (
              <TableContainer sx={{ scrollbarWidth: "thin" }}>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Name</Th>
                      <Th>Email</Th>
                      <Th>Role</Th>

                      {user?.isParent && <Th>Actions</Th>}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {teamMembersList.map((member: any) => (
                      <Tr key={member?.id}>
                        <Td>{member?.username ? member?.username : "--"}</Td>
                        <Td>{member.email}</Td>
                        <Td>{member.roleName}</Td>

                        {user?.isParent && (
                          <Td>
                            {member.inviteAccepted ? (
                              <HStack>
                                <Button
                                  bg="blue.500"
                                  color="white"
                                  colorScheme="blue"
                                  size="sm"
                                  onClick={() => {
                                    setRole(member?.roleName);
                                    setTeamMember(member);
                                    onOpen();
                                  }}
                                  rightIcon={<Icon as={RiEditBoxLine} />}
                                >
                                  Edit
                                </Button>
                                <Button
                                  bg="red.500"
                                  color="white"
                                  colorScheme="red"
                                  size="sm"
                                  onClick={() =>
                                    handleDeleteTeamMember(member.id)
                                  }
                                  rightIcon={<Icon as={RiDeleteBin6Line} />}
                                  isLoading={member.id === isLoader.email}
                                  isDisabled={member.id === isLoader.email}
                                >
                                  Delete
                                </Button>
                              </HStack>
                            ) : (
                              <Button
                                rightIcon={<Icon as={RiSendPlaneFill} />}
                                bg="blue.500"
                                color="white"
                                colorScheme="blue"
                                size="sm"
                                isLoading={member.email === isLoader.email}
                                isDisabled={member.email === isLoader.email}
                                onClick={() =>
                                  handleAddTeamMember(
                                    member.email,
                                    member.roleName
                                  )
                                }
                              >
                                Resend Invite
                              </Button>
                            )}
                          </Td>
                        )}
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            ) : (
              <Text textAlign="center">No team members available</Text>
            )}
          </Suspense>
        </CardBody>
      </Card>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Team Member</ModalHeader>
          <ModalCloseButton color="white" />
          <ModalBody>
            <VStack w="full" padding="20px 30px">
              <FormControl>
                <FormLabel>Email:</FormLabel>
                <Input type="text" isDisabled value={teamMember?.email} />{" "}
              </FormControl>
              <FormControl>
                <FormLabel>Role:</FormLabel>
                <Select
                  isDisabled={false}
                  size="md"
                  h="38px"
                  className="chakra-select"
                  placeholder="Choose Option"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                >
                  <option value="Interviewer">Interviewer</option>
                  <option value="Recruiter">Recruiter</option>
                  <option value="Human Resourses">Human Resourses</option>
                  <option value="Coordinator">Coordinator</option>
                  <option value="Project Manager">Project Manager</option>
                  <option value="Administrator">Administrator</option>
                </Select>
                {error && (
                  <Text fontSize="sm" color="red.500" mt="2px !improtant">
                    {error}
                  </Text>
                )}
              </FormControl>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={handleEditTeamMember}
                padding="20px 30px"
                isLoading={isLoading}
                isDisabled={isLoading}
              >
                Save
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default Schedule;
