import { useState } from "react";
import {
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Flex,
  Heading,
  HStack,
  Stack,
  Text,
  Icon,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { HiOutlineClipboardCheck } from "react-icons/hi";
import { RiThumbDownFill, RiThumbUpFill } from "react-icons/ri";
import { useAppliedJobStatusChange } from "../../../service/job.service";
import { BiMedal } from "react-icons/bi";
import { useFetch } from "../../../utils/useFetch";

const SearchJobCard = ({ data, refetch }: any) => {
  const toast = useToast();
  const [isLoading, setIsloading] = useState(false);
  const navigate = useNavigate();
  const { mutate: appliedStatusMutate, isLoading: appliedStatusLoading } =
    useAppliedJobStatusChange();
  const handleUnApplyJob = (id: string) => {
    setIsloading(true);
    try {
      useFetch.put(`/jobs/apply/unapply-job?jobId=${id}`).then((res) => {
        setIsloading(false);
        toast({
          title: "Application withdrawn successfully",
          status: "success",
          isClosable: true,
          position: "bottom",
          duration: 3000,
        });
        refetch();
      });
    } catch (error) {
      setIsloading(false);
      console.log(error);
    }
  };
  const handleApplyJob = (e: any) => {
    e.stopPropagation();
    appliedStatusMutate(data?.id, {
      onSuccess: () => {
        toast({
          title: "Applied Successfully",
          status: "success",
          isClosable: true,
          position: "bottom",
          duration: 3000,
        });
        refetch();
      },
    });
  };
  return (
    <>
      <Card maxW="100%" borderRadius={"md"} w="full" cursor={"pointer"}>
        <CardHeader
          pb="0px"
          onClick={() => {
            navigate(`/search/${data?.id}`, { state: data });
          }}
        >
          <Flex>
            <Flex flex="1" gap="4" alignItems="center" mt="6">
              <Box>
                <Heading
                  size="lg"
                  color="#48465b"
                  fontWeight={"500"}
                  fontSize={{
                    base: "16.8px",
                    md: "16.8px",
                    lg: "18.2px",
                    xl: "18.2px",
                  }}
                  _hover={{ color: "#5867DD", cursor: "pointer" }}
                  mb="2"
                >
                  {data?.name?.length > 30
                    ? `${data?.name?.slice(0, 31)}...`
                    : data?.name}
                </Heading>
                <Text
                  fontSize={"13px"}
                  color={"#646c9a"}
                  fontWeight="500"
                  mt="0px"
                  mb="2"
                >
                  {data?.locationsList?.length
                    ? `${data?.locationsList[0]}`
                    : "Remote"}
                  {data?.reqNumber && ` | Req#  ${data?.reqNumber}`}
                </Text>
              </Box>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody
          onClick={() => {
            navigate(`/search/${data?.id}`, { state: data });
          }}
        >
          <HStack w={"full"} alignItems="flex-start" mt="4 !important">
            <HStack flexWrap={"wrap"}>
              <Icon as={BiMedal} fontSize="30px" color="gray.500" mt="0.5" />
              {data?.jobFunctions?.map((item: string) => (
                <Badge
                  bg={"#ffb822"}
                  fontWeight="100"
                  px="1.5"
                  py="0.5"
                  borderRadius={"xl"}
                  fontSize="12px"
                  mt="0.5 !important"
                  mr="0 !important"
                  ml="1 !important"
                  key={item}
                  color={"#48465b"}
                  textTransform={"capitalize"}
                >
                  {item}
                </Badge>
              ))}
            </HStack>
          </HStack>
        </CardBody>
        <Divider color={"gray.300"} />
        <CardFooter justify="space-between" cursor="auto" flexWrap="wrap">
          <Stack
            direction="row"
            flexWrap="wrap"
            justify="space-between"
            w="full"
          >
            <Button
              leftIcon={<HiOutlineClipboardCheck size="18px" />}
              colorScheme="buttonPrimary"
              variant="ghost"
              cursor="default"
            >
              {`Active candidates: ${data?.totalApplicants}`}
            </Button>
            <Button
              leftIcon={
                data?.isInterested ? <RiThumbDownFill /> : <RiThumbUpFill />
              }
              colorScheme={data?.isInterested ? "red" : "green"}
              variant="solid"
              my="1"
              isLoading={appliedStatusLoading || isLoading}
              isDisabled={appliedStatusLoading || isLoading}
              size={{ base: "xs", sm: "sm", md: "sm" }}
              onClick={(e: any) => {
                data?.isInterested
                  ? handleUnApplyJob(data?.id)
                  : handleApplyJob(e);
              }}
            >
              {data?.isInterested ? "Withdraw Application" : "I'm interested"}
            </Button>
          </Stack>
        </CardFooter>
      </Card>
    </>
  );
};

export default SearchJobCard;
