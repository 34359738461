import {
  Button,
  HStack,
  Badge,
  Flex,
  Text,
  Icon,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { FaSuitcase, FaCaretDown } from "react-icons/fa";
import { stageOptions } from "../../../../utils/helper";
import CustomDatePicker from "../../../common/date-picker/datepicker";

const TalentOpportunities = ({
  job,
  updatedStage,
  setUpdatedStage,
  jobToBeUpdated,
  setJobToBeUpdated,
  selectedDate,
  setSelectedDate,
  handleSubmitStage,
  menuIsOpen,
  setMenuIsOpen,
  menuOpenId,
  setMenuOpenId,
  key,
}: any) => {
  const searchFieldRef = useRef<HTMLDivElement>(null);
  const [filteredOptions, setFilteredOptions] = useState<any>([]);
  const [currentDate, setCurrentDate] = useState<Date | null>(new Date());
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const mouseClick = (e: any) => {
    const rect = e.target.getBoundingClientRect();
    setPosition({ x: rect.left, y: rect.bottom });
  };
  const handleStageFilterWithBg = (stageProps: string) => {
    const selectedStage = stageOptions.find(
      (stage: any) => stage.value === stageProps
    );

    if (selectedStage) {
      return {
        color: selectedStage.color,
        background: selectedStage.bg,
      };
    }
    return {
      color: "",
      background: "",
    };
  };
  const { color, background } = handleStageFilterWithBg(job?.stagingLevel);
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        searchFieldRef.current &&
        !searchFieldRef.current.contains(event.target as Node)
      ) {
        setMenuIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (job?.modifyDate !== null) {
      const [year, month, day, hours, minutes, seconds, timestamp] =
        job?.modifyDate || [];
      const dateObject = new Date(
        year,
        month - 1,
        day,
        hours,
        minutes,
        seconds,
        (timestamp ? timestamp : 345) / 1e6
      );

      setCurrentDate(dateObject);
    } else {
      setCurrentDate(new Date());
    }

    const filteredData = filterStageOptions(job?.stagingLevel);
    setFilteredOptions(filteredData);
  }, [job]);
  function filterStageOptions(stage: string) {
    const searchTerm = stage?.split("_")[0];

    switch (searchTerm) {
      case "UNDER":
        return stageOptions.filter(
          (option) =>
            option.value !== "REJECTED" &&
            (option.value.includes("INTERVIEW") ||
              option.value.includes("HR_SCREEN") ||
              option.value === "ARCHIVED")
        );
      case "HR":
        return stageOptions.filter(
          (option) =>
            option.value !== "REJECTED" &&
            (option.value.includes("INTERVIEW") || option.value === "ARCHIVED")
        );
      case "INTERVIEW":
        return stageOptions.filter(
          (option) =>
            option.value !== "REJECTED" &&
            (option.value.includes("ONSITE") ||
              option.value.includes("INTERVIEW") ||
              option.value === "ARCHIVED")
        );
      case "ONSITE":
        return stageOptions.filter(
          (option) =>
            option.value !== "REJECTED" &&
            (option.value.includes("OFFER") ||
              option.value.includes("ONSITE") ||
              option.value === "ARCHIVED")
        );
      case "OFFER":
        return stageOptions.filter(
          (option) =>
            option.value !== "REJECTED" &&
            (option.value === "HIRED_STARTED" ||
              option.value.includes("OFFER") ||
              option.value === "ARCHIVED")
        );
      case "HIRED":
        return stageOptions.filter(
          (option) => option.value !== "REJECTED" && option.value === "ARCHIVED"
        );
      default:
        return stageOptions;
    }
  }

  function getStageName(value: string) {
    for (const stage of stageOptions) {
      if (stage.value === value) {
        return stage.name;
      }
    }
    return "";
  }

  const selectedStage = getStageName(job?.stagingLevel);
  return (
    <>
      <HStack
        justify="space-between"
        w="full"
        key={key}
        // w="auto"
      >
        <HStack>
          <Icon as={FaSuitcase} />
          <Text fontSize="13px" color="#646c9a" fontWeight="500">
            {job?.name && job?.name.length > 20 ? (
              <Tooltip hasArrow label={job?.name}>
                {job?.name?.slice(0, 20) + "..."}
              </Tooltip>
            ) : (
              job?.name
            )}
          </Text>
        </HStack>
        <HStack>
          <Badge
            color={color}
            bg={background}
            padding="7px 5px"
            textTransform="capitalize"
            whiteSpace={{ base: "normal", sm: "nowrap" }}
          >
            {selectedStage}
          </Badge>
          <Flex
            direction="column"
            w="full"
            //  position="relative"
          >
            <IconButton
              height="40px"
              width="40px"
              aria-label="Search database"
              icon={<FaCaretDown />}
              onClick={(e: any) => {
                setMenuIsOpen(!menuIsOpen);
                setJobToBeUpdated(job);
                setMenuOpenId(job?.id);
                mouseClick(e);
              }}
            />

            {menuOpenId === job?.id && menuIsOpen === true && (
              <Flex
                ref={searchFieldRef}
                direction="column"
                minW="260px"
                w="auto"
                // position="absolute"
                position="fixed"
                top={position.y}
                left={position.x}
                bg="#ffffff"
                zIndex="999"
                boxShadow="2xl"
                padding="5px"
                overflow="auto"
                // mt="44px"
                // top="95px"
                // right="0px"
              >
                <Text color="#595d6e" padding="10px 15px">
                  Current Stage:
                </Text>
                <Flex direction="column" w="full" maxH="240px" overflow="auto">
                  {filteredOptions.map((stage: any) => (
                    <Flex
                      padding="10px 15px"
                      _hover={{
                        bg:
                          updatedStage?.value === stage?.value &&
                          updatedStage?.id === job?.id
                            ? "gray.200"
                            : "gray.50",
                        cursor: "pointer",
                      }}
                      bg={
                        updatedStage?.value === stage?.value &&
                        updatedStage?.id === job?.id
                          ? "gray.200"
                          : "transparent"
                      }
                      onClick={() => {
                        setUpdatedStage({
                          id: job?.id,
                          value: stage?.value,
                        });
                      }}
                    >
                      <Badge
                        color={stage?.color}
                        bg={stage?.bg}
                        key={stage?.id}
                        padding="7px 8px"
                      >
                        {stage?.name}
                      </Badge>
                    </Flex>
                  ))}
                </Flex>
                <Flex padding="7px 5px">
                  <CustomDatePicker
                    label="dd/MM/yyyy"
                    selected={currentDate}
                    onChange={(date: any) => {
                      setCurrentDate(date);
                      setSelectedDate({ id: job?.id, value: date });
                    }}
                    dateFormat="dd/MM/yyyy"
                    dropdownMode="select"
                    minDate={currentDate}
                    maxDate={new Date()}
                  />
                </Flex>
                <Flex padding="7px 5px" align="center" justify="end">
                  <Button
                    size="sm"
                    colorScheme="buttonPrimary"
                    onClick={handleSubmitStage}
                    isDisabled={updatedStage.id !== job?.id}
                  >
                    Save
                  </Button>
                </Flex>
              </Flex>
            )}
          </Flex>
        </HStack>
      </HStack>
      {/* );
        })} */}
    </>
  );
};

export default TalentOpportunities;
