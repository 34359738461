import { Box, useRadio } from "@chakra-ui/react";

const RadioCard = (props: any) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label" h="auto" m="1 !important">
      <input {...input} height="40px" />
      <Box
        h="auto"
        {...checkbox}
        w={{ base: "100%", md: "fit-content" }}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="sm"
        fontSize={{ base: "10px", sm: "13px" }}
        color="buttonPrimary.600"
        fontWeight={"bold"}
        border={"1px solid #5D78FF"}
        _checked={{
          bg: "buttonPrimary.600",
          color: "white",
          borderColor: "buttonPrimary.600",
          fontWeight: "bold",
        }}
        _hover={{
          borderColor: "buttonPrimary.600",
          fontWeight: "bold",
        }}
        px={5}
        py={2}
        textAlign="center"
        whiteSpace={{ base: "break-spaces", md: "nowrap" }}
      >
        {props.children}
      </Box>
    </Box>
  );
};
export default RadioCard;
