import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { forgotPassSchema } from "../../utils/validation";
import { yupResolver } from "@hookform/resolvers/yup";

const useForgotPass = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(forgotPassSchema),
  });
  const onSubmit = (data: any) => {
    navigate("/reset-password");
  };
  return {
    register,
    handleSubmit,
    errors,
    onSubmit,
    navigate,
  };
};

export default useForgotPass;
