import InterviewPage from "../../components/candidate/interview";

const InterviewCandidatePage = () => {
  return (
    <>
      <InterviewPage />
    </>
  );
};

export default InterviewCandidatePage;
