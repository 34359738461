import {
  Avatar,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import useInterviewReschedule from "./useInterviewReschedule";
import { GenericModal } from "../../common/generic-modal";
import LoadingScreen from "../../common/loadingScreen";
import moment from "moment";
import RadioGroup from "../../common/radioGroup";
import React, { useEffect, useState } from "react";

const MeetingDetails = () => {
  const reasonOptions = [
    "Family emergency",
    "Conflicting meetings",
    "Feeling ill",
    "Too much going on",
    "Other",
  ];
  const {
    isOpen,
    onOpen,
    onModalClose,
    onChangeDate,
    handleOptionTimes,
    selectedTimes,
    watch,
    setReschedule,
    reschedule,
    isSubmitTimes,
    setIsSubmitTimes,
    setCopied,
    copied,
    scheduleData,
    fetchLoading,
    scheduleLoading,
    reason,
    setReason,
    valueReason,
    setValueReason,
    onSubmit,
    navigate,
    anotherDay,
    setAnotherDay,
    isSubmitTimes2,
    setIsSubmitTimes2,
    selectedTimes2,
    setSelectedtimes2,
    onChangeDate2,
    handleOptionTimes2,
    errors,
  } = useInterviewReschedule();

  const handleInputTextAreaChange = (e: any) => {
    let InputTextAreaValue = e.target.value;
    setValueReason(InputTextAreaValue);
  };
  const [sortedTimes1, setSortedTimes1] = useState<string[]>([]);
  const [sortedTimes2, setSortedTimes2] = useState<string[]>([]);
  const sortTimes = () => {
    const sortFunction = (a: string, b: string) => {
      const currentDate = new Date(); // Use the current date
      const timeA = new Date(
        `${
          currentDate.getMonth() + 1
        }/${currentDate.getDate()}/${currentDate.getFullYear()} ${a}`
      );
      const timeB = new Date(
        `${
          currentDate.getMonth() + 1
        }/${currentDate.getDate()}/${currentDate.getFullYear()} ${b}`
      );
      return timeA.getTime() - timeB.getTime();
    };

    const sortedSelectedTimes1 =
      scheduleData?.selectedTimes && scheduleData?.selectedTimes?.length > 1
        ? [...scheduleData?.selectedTimes]?.sort(sortFunction)
        : scheduleData?.selectedTimes;
    const sortedSelectedTimes2 =
      scheduleData?.selectedTimes2 && scheduleData?.selectedTimes2?.length > 1
        ? [...scheduleData?.selectedTimes2]?.sort(sortFunction)
        : scheduleData?.selectedTimes2;
    setSortedTimes1(
      scheduleData?.selectedTimes && scheduleData?.selectedTimes?.length > 1
        ? [...sortedSelectedTimes1]
        : [sortedSelectedTimes1]
    );
    setSortedTimes2(
      scheduleData?.selectedTimes2 && scheduleData?.selectedTimes2?.length > 1
        ? [...sortedSelectedTimes2]
        : [sortedSelectedTimes2]
    );
  };

  useEffect(() => {
    sortTimes();
  }, [scheduleData?.selectedTimes, scheduleData?.selectedTimes2]);

  return (
    <>
      {fetchLoading ? (
        <LoadingScreen />
      ) : (
        <VStack
          w={{ base: "full", md: "80%", lg: "60%" }}
          m="auto"
          spacing={"3"}
          p="4"
          py="8"
        >
          <Card p="4" w="full">
            <Box my="4">
              <Stack
                direction="row"
                spacing={4}
                align="center"
                justify="space-between"
                w="full"
              >
                <Heading fontSize={"xl"} color="#646c9a">
                  Meeting Details
                </Heading>
                <Button
                  loadingText="Loading"
                  color="#74788d"
                  spinnerPlacement="end"
                  size="sm"
                  fontWeight={"bold"}
                  _hover={{ color: "white", bg: "#5867DD" }}
                  // leftIcon={<IoIosArrowBack />}
                  onClick={() => navigate("/interview")}
                >
                  Back
                </Button>
              </Stack>
            </Box>
          </Card>
          <Card boxShadow={"none"} borderRadius="none" w="full">
            <CardBody>
              <VStack w="full" align="flex-start" justify="start">
                <HStack w="full">
                  <Avatar size="sm" name="" src={scheduleData?.employerImage} />
                  <Text fontSize={"lg"} fontWeight={"bold"} w="full">
                    {scheduleData?.employerName}
                  </Text>
                </HStack>
                <HStack
                  align="start"
                  gap="2"
                  height="auto"
                  alignItems="stretch"
                >
                  <Flex direction="column">
                    {" "}
                    <Text fontSize={"sm"} color={"gray"} w="full">
                      {moment(scheduleData?.date).format("ddd, MMMM DD, YYYY")}
                    </Text>
                    {sortedTimes1?.map((time: string, index: number) => (
                      <Text
                        mt="0px !important"
                        key={index}
                        fontSize={"sm"}
                        color={"gray"}
                        w="full"
                      >
                        {time}
                        {index !== scheduleData.selectedTimes?.length - 1
                          ? ","
                          : ""}
                      </Text>
                    ))}
                  </Flex>

                  {scheduleData?.date2 && (
                    <>
                      <Box minH="100%" borderLeft="1px solid #48465b" />

                      <Flex direction="column">
                        {" "}
                        <Text fontSize={"sm"} color={"gray"} w="full">
                          {moment(scheduleData?.date2).format(
                            "ddd, MMMM DD, YYYY"
                          )}
                        </Text>
                        {sortedTimes2?.map((time: string, index: number) => (
                          <Text
                            mt="0px !important"
                            key={index}
                            fontSize={"sm"}
                            color={"gray"}
                            w="full"
                          >
                            {time}
                            {index !== scheduleData.selectedTimes?.length - 1
                              ? ","
                              : ""}
                          </Text>
                        ))}
                      </Flex>
                    </>
                  )}
                </HStack>
                {scheduleData?.locations === "phone" && (
                  <FormControl>
                    <FormLabel fontWeight={"bold"}> Phone Number</FormLabel>
                    <InputGroup
                      size="md"
                      w={{ base: "100%", md: "70%" }}
                      ml="-5px"
                    >
                      <Input isReadOnly={true} value={scheduleData?.phone} />
                      <InputRightElement width="4.5rem">
                        <Button
                          h="1.75rem"
                          size="sm"
                          onClick={async () => {
                            setCopied(true);
                            await navigator.clipboard.writeText(
                              scheduleData?.phone
                            );
                          }}
                        >
                          {copied ? "Copied" : "Copy"}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>
                )}
                {scheduleData?.locations === "video/web" && (
                  <FormControl>
                    <FormLabel fontWeight={"bold"}> Meeting Link</FormLabel>
                    <InputGroup
                      size="md"
                      w={{ base: "100%", md: "70%" }}
                      ml="-5px"
                    >
                      <Input isReadOnly={true} value={scheduleData?.link} />
                      <InputRightElement width="4.5rem">
                        <Button
                          h="1.75rem"
                          size="sm"
                          onClick={async () => {
                            setCopied(true);
                            await navigator.clipboard.writeText(
                              scheduleData?.link
                            );
                          }}
                        >
                          {copied ? "Copied" : "Copy"}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>
                )}
                {scheduleData?.locations === "in-person" && (
                  <FormControl>
                    <FormLabel fontWeight={"bold"}> Address</FormLabel>
                    <InputGroup
                      size="md"
                      w={{ base: "100%", md: "70%" }}
                      ml="-5px"
                    >
                      <Input
                        isReadOnly={true}
                        value={
                          scheduleData?.address &&
                          JSON.parse(scheduleData?.address)?.label
                        }
                      />
                      <InputRightElement width="4.5rem">
                        <Button
                          h="1.75rem"
                          size="sm"
                          onClick={async () => {
                            setCopied(true);
                            await navigator.clipboard.writeText(
                              scheduleData?.address &&
                                JSON.parse(scheduleData?.address)?.label
                            );
                          }}
                        >
                          {copied ? "Copied" : "Copy"}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>
                )}

                <Text fontWeight={"bold"} w="full" mt="2">
                  Interview Detail
                </Text>
                <Box
                  border="1px solid gray"
                  w={{ base: "100%", md: "70%" }}
                  p={{ base: "1", md: "2", lg: "3" }}
                  // ps="20"
                >
                  <HStack align="start" mt="2" gap={2}>
                    <Avatar name="" src={scheduleData?.companyLogo} size="md" />
                    <VStack alignItems="start">
                      <Text
                        fontSize="16px"
                        fontWeight={"bold"}
                        color={"#646c9a"}
                      >
                        {scheduleData?.companyName ?? "N/A"}
                      </Text>
                      <Text
                        fontSize={"sm"}
                        fontWeight={"bold"}
                        color={"#646c9a"}
                      >
                        {scheduleData?.jobName}
                      </Text>
                      <Text fontSize={"xs"} color={"#646c9a"} mt="0 !important">
                        {scheduleData?.jobLocation?.length
                          ? `${scheduleData?.jobLocation[0]}`
                          : "Remote"}
                        {scheduleData?.reqNumber &&
                          ` | Req#  ${scheduleData?.reqNumber}`}
                      </Text>
                    </VStack>
                  </HStack>
                </Box>

                <Text fontWeight={"bold"} w="full" mt="4">
                  Invitee Details
                </Text>
                {scheduleData?.inputs && (
                  <Box
                    border="1px solid gray"
                    w={{ base: "100%", md: "70%" }}
                    p={{ base: "1", md: "2", lg: "3" }}
                    // ps="20"
                  >
                    <VStack w="full">
                      {scheduleData?.inputs.map((data: any, index: any) => (
                        <React.Fragment key={index}>
                          <VStack w="80%">
                            <Flex
                              gap={2}
                              align={{ base: "start", md: "center" }}
                              w="full"
                              direction={{ base: "column", md: "row" }}
                            >
                              <Text fontSize="13px" fontWeight="700">
                                Email
                              </Text>
                              <Text fontSize="13px" wordBreak="break-word">
                                {data?.value}
                              </Text>
                            </Flex>
                            <Flex
                              gap={2}
                              align={{ base: "start", md: "center" }}
                              w="full"
                              direction={{ base: "column", md: "row" }}
                            >
                              <Text fontSize="13px" fontWeight="700">
                                Interview Type
                              </Text>
                              <Text fontSize="13px">{data?.option}</Text>
                            </Flex>
                          </VStack>
                          {index !== scheduleData.inputs?.length - 1 && (
                            <Divider
                              orientation="horizontal"
                              borderBottom="1px solid black"
                            />
                          )}
                        </React.Fragment>
                      ))}
                    </VStack>
                  </Box>
                )}
              </VStack>
            </CardBody>
            <CardFooter flexWrap="wrap">
              <Button
                colorScheme="buttonPrimary"
                mt="4 !important"
                w="150px"
                onClick={() => onOpen()}
              >
                Reschedule
              </Button>
            </CardFooter>
            <GenericModal
              isOpen={isOpen}
              onClose={onModalClose}
              title={"Reason For Reschedule ? "}
              onClickHandler={() => console.log("values")}
              buttonText={false ? "Loading" : "Save"}
              loadingButton={false || false}
              buttonShow={false}
            >
              <VStack>
                <RadioGroup
                  options={reasonOptions}
                  name="jobType"
                  value={reason}
                  onChange={(value) => {
                    setReason(value);
                  }}
                  maxW="100%"
                />

                {reason === "Other" ? (
                  <Flex direction="column" w="full">
                    <Textarea
                      value={valueReason}
                      onChange={handleInputTextAreaChange}
                      placeholder=""
                      size="sm"
                    />

                    {!valueReason?.length && errors?.valueReason && (
                      <Text color="red" textAlign="center" w="full" pl="1">
                        *{errors?.valueReason}
                      </Text>
                    )}
                  </Flex>
                ) : (
                  !reason?.length &&
                  errors?.reason && (
                    <Text color="red" textAlign="center" w="full" pl="1">
                      *{errors?.reason}
                    </Text>
                  )
                )}
                <Button
                  colorScheme="buttonPrimary"
                  mt="4 !important"
                  w="300px"
                  onClick={onSubmit}
                  isLoading={scheduleLoading}
                  isDisabled={scheduleLoading}
                >
                  {` Share with ${scheduleData?.employerName}`}
                </Button>
              </VStack>
            </GenericModal>
          </Card>
        </VStack>
      )}
    </>
  );
};

export default MeetingDetails;
