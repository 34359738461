import { Progress } from "@chakra-ui/react";
import AddEducation from "../../../components/candidate/signupFlow/education/add-education";

const AddEducationPage = () => {
  return (
    <>
      <Progress
        colorScheme="progressBar"
        size="xs"
        value={50}
        w="full"
        borderRadius={"lg"}
        mb="6 !important"
        mt="2 !important"
      />
      <AddEducation />
    </>
  );
};

export default AddEducationPage;
