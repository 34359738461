import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthStore } from "../../../../store/useAuth";

import { useToast } from "@chakra-ui/react";
import {
  addCommas,
  getUserId,
  handleLoginLocalStorage,
  removeNonNumeric,
} from "../../../../utils/helper";
import {
  useAddOtherInfo,
  useFetchAllOtherInfo,
  useUpdateOtherInfo,
} from "../../../../service/auth.service";
import { useFetchJobFunc } from "../../../../service/helper.service";
import { useUserStore } from "../../../../store/useUser";
import { usePercentageStore } from "../../../../store/useStore";
import AWS from "aws-sdk";

const optionsJobFunc: any = [
  "Clinical Research Associate",
  "Analytics",
  "Ml Engineer",
  "ML Researcher",
  "Data Engineeer",
  "Ml Ops",
  "Data Analyst",
  "Report & Bi",
];
const optionsLocations: any = [
  "NYC",
  "Boston",
  "Chicago",
  "LA",
  "London",
  "Lahore",
  "Delhi",
];
const optionsSponsorship: any = [
  "H1B sponsorship",
  "H1B transfer",
  "Green Card Sponsorship",
  "OPT/CPT Candidates",
  "TN Visa",
  "Other",
];
const optionsIndustry: any = ["HealtCare", "Finance", "Insurance", "Marketing"];
const optionsDomain: any = [
  "Data Science ",
  "Analytics",
  "Data Engineeer",
  "Ml Ops",
  "Data Analyst",
  "Report & Bi",
  "Node.js",
  "React.js",
];

const seniorityLevelLookup: any = {
  "Entry-level": 0,
  "Mid-level, hands-on": 1,
  "Senior-level, hands-on": 2,
  "Manager, hands-on": 3,
  "Manager, with direct reports": 4,
};
const seniorityLevelLookupReverse: any = {
  0: "Entry-level",
  1: "Mid-level, hands-on",
  2: "Senior-level, hands-on",
  3: "Manager, hands-on",
  4: "Manager, with direct reports",
};

const workVisaRequiredLookup: any = {
  Yes: 0,
  No: 1,
  "I'm not sure": 2,
};
const workVisaRequiredLookupReverse: any = {
  0: "Yes",
  1: "No",
  2: "I'm not sure",
};
const whenYouStartLookup: any = {
  Immediately: 0,
  "With in a month": 1,
  "In 2-3 months": 2,
  "Custom date": 3,
};
const whenYouStartLookupReverse: any = {
  0: "Immediately",
  1: "With in a month",
  2: "In 2-3 months",
  3: "Custom date",
};

const useSetup = (onModalClose?: any) => {
  const { updatePercentage } = usePercentageStore();
  const { setUser } = useUserStore();
  const userId = getUserId();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { setOtherOptions, user } = useAuthStore();
  const [isLoading, setIsLoading] = useState(false);
  const [interestedJobFunctions, setJobFunc] = useState<any>([]);
  const [profileImageLoading, setProfileImageLoading] = useState<any>(false);
  const [workVisaRequired, setWorkVisaRequired] = useState<number | null>(null);
  const [jobSearch, setJobSearch] = useState<any>("");
  const [seniorityLevel, setSeniorityLevel] = useState<any>("");
  const [whenYouCanStart, setWhenYouCanStart] = useState<any>("");
  const [nonImmigrantVisaStatus, setNonImmigrantVisaStatus] = useState<any>("");
  const [isWorkRemotely, setIsWorkRemotely] = useState<any>(false);
  const [isWorkHybrid, setIsWorkHybrid] = useState<any>(false);
  const [isWorkOnSite, setIsWorkOnSite] = useState<any>(false);
  const [willingToRelocate, setWillingToRelocate] = useState<any>(false);
  const [customJoiningDate, setCustomJoiningDate] = useState<any>(null);
  const [additionalDetails, setAdditionalDetails] = useState("");
  const [industriesList, setIndustriesList] = useState<any>([]);
  const [salary, setSalary] = useState<any>({
    currencyType: "",
    amount: 1,
    payType: "",
    totalCompensation: "",
    isVisibleToEmployers: true,
  });
  const [errors, setErrors] = useState<any>({
    currencyType: "",
    amount: "",
    payType: "",
    totalCompensation: "",
    profilePicture: "",
    locationList: "",
    nonImmigrantVisaStatus: "",
    interestedIndustry: "",
    avoidIndustry: "",
    interestedDomain: "",
    avoidDomain: "",
    interestedJobFunctions: "",
    workVisaRequired: "",
    jobSearch: "",
    seniorityLevel: "",
    whenYouCanStart: "",
    isWorkType: "",
    additionalDetails: "",
  });
  const toast = useToast();
  const {
    mutate: otherInfoMutation,
    isLoading: otherInfoAddLoading,
    data: otherInfoData,
    isSuccess: isSuccessAdd,
  } = useAddOtherInfo();
  const {
    mutate: otherInfoUpdateMutation,
    isLoading: otherInfoUpdateLoading,
    data: otherInfoUpdateData,
    isSuccess: isSuccessUpdate,
  } = useUpdateOtherInfo();
  const { isLoading: loadingJobFunc, data: jobFunctionList } =
    useFetchJobFunc(userId);
  const { isLoading: otherInfoLoading, data: otherInfo }: any =
    useFetchAllOtherInfo(userId);

  const handleJobFun = (option: any) => {
    if (interestedJobFunctions.includes(option)) {
      setJobFunc((prevSelectedOptions: any) =>
        prevSelectedOptions.filter((o: any) => o !== option)
      );
    } else if (interestedJobFunctions?.length < 3) {
      setJobFunc((prevSelectedOptions: any) => [
        ...prevSelectedOptions,
        option,
      ]);
    } else if (interestedJobFunctions?.length >= 3) {
      toast({
        title: "You can select up to 3 options",
        status: "info",
        variant: "top-accent",
        isClosable: true,
        position: "top-right",
        duration: 3000,
      });
    }
  };

  const useSelectOptions = (initialState: any) => {
    const [selectedOptions, setSelectedOptions] = useState<any>(initialState);

    const handleOptionSelection = (option: any) => {
      if (selectedOptions?.includes(option)) {
        setSelectedOptions((prevSelectedOptions: any) =>
          prevSelectedOptions.filter((o: any) => o !== option)
        );
      } else {
        setSelectedOptions((prevSelectedOptions: any): any => [
          ...prevSelectedOptions,
          option,
        ]);
      }
    };
    const updateHandler = (value: any) => {
      setSelectedOptions(value ? value : []);
    };

    return [selectedOptions, handleOptionSelection, updateHandler];
  };
  const [locationList, handleLocations, updateHandlerLocation] =
    useSelectOptions([]);

  const [
    interestedIndustry,
    handleLikedIndustries,
    updateHandlerInterestedIndustry,
  ] = useSelectOptions([]);
  const [avoidIndustry, handleAvoidIndustries, updateHandlerAvoidIndustry] =
    useSelectOptions([]);
  const [interestedDomain, handleLikedDomains, updateHandlerInterestedDomain] =
    useSelectOptions([]);
  const [avoidDomain, handleAvoidDomains, updateHandlerAvoidDomain] =
    useSelectOptions([]);

  const [profilePicture, setProfilePicture] = useState<string>("");
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file: File | null = event.target.files?.[0] || null;
    if (!file) {
      return;
    }
    const allowedTypes = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "image/svg+xml",
    ];
    if (!allowedTypes.includes(file.type)) {
      toast({
        title: "Inavlid File Type",
        description:
          "Please make sure to upload an image in PNG, JPG, JPEG, or SVG format.",
        status: "error",
        isClosable: true,
        position: "bottom",
        duration: 3000,
      });
      return;
    }

    let uploadCanceled = false;

    const S3_BUCKET = process.env.REACT_APP_S3_BUCKET || "";
    const REGION = process.env.REACT_APP_REGION;

    AWS.config.update({
      accessKeyId: process.env.REACT_APP_accessKeyId,
      secretAccessKey: process.env.REACT_APP_secretAccessKey,
    });
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });
    const folder = "profile-images";
    const params = {
      Bucket: S3_BUCKET,
      Key: `${folder}/${file.name}`,
      Body: file,
    };

    try {
      setProfileImageLoading(true);
      const upload = await s3.putObject(params).promise();
      const url = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${folder}/${file.name}`;
      setProfilePicture(url);
      setProfileImageLoading(false);
    } catch (error: any) {
      if (error.code === "storage/canceled") {
        uploadCanceled = true;
      } else {
        console.log("error", error);
        setProfileImageLoading(false);
      }
    }
  };

  const handleEditButtonClick = () => {
    fileInputRef.current?.click();
  };
  const handleSalaryChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    const formatedValue = addCommas(value);
    setSalary((prevState: any) => ({
      ...prevState,
      [name]: formatedValue,
    }));
  };

  const handleValidationSalary = () => {
    let errors = {
      profilePicture: "",
      currencyType: "",
      amount: "",
      payType: "",
      totalCompensation: "",
      locationList: "",
      nonImmigrantVisaStatus: "",
      interestedIndustry: "",
      avoidIndustry: "",
      interestedDomain: "",
      avoidDomain: "",
      interestedJobFunctions: "",
      workVisaRequired: "",
      jobSearch: "",
      seniorityLevel: "",
      whenYouCanStart: "",
      isWorkType: "",
      additionalDetails: "",
    };

    let formIsValid = true;

    if (pathname !== "/profile" && !profilePicture) {
      formIsValid = false;
      errors.profilePicture = "Please select a profile picture";
    }
    if (!pathname.includes("setupFlow") && !interestedJobFunctions?.length) {
      formIsValid = false;
      errors.interestedJobFunctions = "Please select at least one job function";
    }
    if (!salary?.currencyType) {
      formIsValid = false;
      errors.currencyType = "Please select currency type";
    }

    if (!salary?.payType) {
      formIsValid = false;
      errors.payType = "Please select payment type";
    }

    if (!salary?.totalCompensation) {
      formIsValid = false;
      errors.totalCompensation = "Please enter total compensation";
    }

    if (workVisaRequired === null) {
      formIsValid = false;
      errors.workVisaRequired = "Please select Work Visa type";
    }
    if (workVisaRequired === 0) {
      // errors.workVisaRequired = "Please select the work visa status";
      if (
        nonImmigrantVisaStatus === null ||
        nonImmigrantVisaStatus === "" ||
        nonImmigrantVisaStatus === undefined
      ) {
        formIsValid = false;
        errors.nonImmigrantVisaStatus = "Please select Non Immigrant Visa type";
      }
      if (nonImmigrantVisaStatus === "Other" && !additionalDetails) {
        formIsValid = false;
        errors.additionalDetails = "Please Enter Other Status";
      }
    }

    if (jobSearch === null || jobSearch === "" || jobSearch === undefined) {
      formIsValid = false;
      errors.jobSearch = "Please select job search type";
    }

    if (
      whenYouCanStart === null ||
      whenYouCanStart === "" ||
      whenYouCanStart === undefined
    ) {
      formIsValid = false;
      errors.whenYouCanStart =
        "Please select a timeframe or choose Custom Date";
    }
    if (whenYouCanStart === 3 && !customJoiningDate) {
      formIsValid = false;
      errors.whenYouCanStart = "Enter joining date";
    }
    setErrors(errors);
    return formIsValid;
  };
  useEffect(() => {
    if (otherInfo && Object.keys(otherInfo)?.length > 0) {
      const {
        salary,
        locationList,
        interestedIndustry,
        avoidIndustry,
        interestedDomain,
        avoidDomain,
        nonImmigrantVisaStatus,
        interestedJobFunctions,
        workVisaRequired,
        jobSearch,
        seniorityLevel,
        whenYouCanStart,
        isWorkHybrid,
        isWorkOnSite,
        isWorkRemotely,
        customJoiningDate,
        profilePicture,
        willingToRelocate,
      } = otherInfo;

      setSalary(
        salary
          ? {
              ...salary,
              totalCompensation: salary.totalCompensation
                ? addCommas(salary.totalCompensation)
                : "",
              payType: salary.payType.toString(),
            }
          : {
              currencyType: "",
              //amount: 1,
              payType: "",
              totalCompensation: "",
              isVisibleToEmployers: true,
            }
      );
      setJobSearch(jobSearch);
      setJobFunc(interestedJobFunctions ? interestedJobFunctions : []);
      setIndustriesList(interestedIndustry ? interestedIndustry : []);
      setWorkVisaRequired(workVisaRequired);
      setSeniorityLevel(seniorityLevel);
      setWhenYouCanStart(whenYouCanStart);
      setIsWorkRemotely(isWorkRemotely);
      setIsWorkHybrid(isWorkHybrid);
      setIsWorkOnSite(isWorkOnSite);
      if (nonImmigrantVisaStatus) {
        if (
          ![
            "H1B sponsorship",
            "H1B transfer",
            "Green Card Sponsorship",
            "OPT/CPT Candidates",
            "TN Visa",
          ].includes(nonImmigrantVisaStatus)
        ) {
          setNonImmigrantVisaStatus("Other");
          setAdditionalDetails(nonImmigrantVisaStatus);
        } else {
          setNonImmigrantVisaStatus(nonImmigrantVisaStatus);
        }
      }
      setCustomJoiningDate(
        customJoiningDate ? new Date(customJoiningDate) : null
      );
      updateHandlerLocation(locationList);
      updateHandlerInterestedIndustry(interestedIndustry);
      updateHandlerAvoidIndustry(avoidIndustry);
      updateHandlerInterestedDomain(interestedDomain);
      updateHandlerAvoidDomain(avoidDomain);
      setProfilePicture(profilePicture);
      setWillingToRelocate(willingToRelocate ? willingToRelocate : false);
    }
  }, [otherInfo]);

  const onSubmit = async (): Promise<any> => {
    if (!!handleValidationSalary()) {
      console.log("ENTERING");

      let date = null;
      if (whenYouCanStart === 3 && customJoiningDate) {
        date = `${customJoiningDate.getFullYear()}-${
          customJoiningDate.getMonth() + 1 < 10
            ? "0" + (customJoiningDate.getMonth() + 1)
            : customJoiningDate.getMonth() + 1
        }-${
          customJoiningDate.getDate() < 10
            ? "0" + customJoiningDate.getDate()
            : customJoiningDate.getDate()
        }`;
      }

      const data = {
        salary: {
          currencyType: salary?.currencyType,
          payType: salary?.payType,
          totalCompensation: salary?.totalCompensation
            ? Number(removeNonNumeric(salary?.totalCompensation))
            : 0,
          isVisibleToEmployers: salary?.isVisibleToEmployers,
        },
        workVisaRequired,
        jobSearch,
        whenYouCanStart,
        profilePicture,
        customJoiningDate: whenYouCanStart === 3 ? date : null,
        nonImmigrantVisaStatus,
        interestedJobFunctions,
        interestedIndustry: industriesList,
      };
      if (workVisaRequired === 0 && nonImmigrantVisaStatus === "Other") {
        data.nonImmigrantVisaStatus = additionalDetails;
      } else {
        data.nonImmigrantVisaStatus = nonImmigrantVisaStatus;
      }
      const userId = getUserId();

      try {
        setIsLoading(true);

        if (pathname !== "/profile") {
          await otherInfoMutation(
            { userId, ...data },

            {
              onSuccess: (resData) => {
                const { token, ...rest } = resData;
                setIsLoading(false);
                localStorage.setItem(
                  "profilepercentage",
                  JSON.stringify(rest?.profileCompletePercentage)
                );
                updatePercentage(rest?.profileCompletePercentage);
                handleLoginLocalStorage(token, rest);
                setUser(rest);
                navigate("/setupFlow/education");
              },
              onError: () => {
                setIsLoading(false);
              },
            }
          );
        } else {
          setIsLoading(true);

          await otherInfoUpdateMutation(
            { userId, ...data },
            {
              onSuccess: (resData) => {
                setIsLoading(false);

                const { token, ...rest } = resData;
                onModalClose();
              },
            }
          );
        }

        if (isSuccessAdd || isSuccessUpdate) {
          return true;
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      return false;
    }
  };

  return {
    user,
    isLoading,
    interestedJobFunctions,
    workVisaRequired,
    jobSearch,
    seniorityLevel,
    whenYouCanStart,
    isWorkRemotely,
    isWorkHybrid,
    isWorkOnSite,
    customJoiningDate,
    salary,
    errors,
    setErrors,
    locationList,
    handleLocations,
    nonImmigrantVisaStatus,
    setNonImmigrantVisaStatus,
    interestedIndustry,
    handleLikedIndustries,
    avoidIndustry,
    handleAvoidIndustries,
    interestedDomain,
    handleLikedDomains,
    avoidDomain,
    handleAvoidDomains,
    profilePicture,
    optionsJobFunc,
    optionsLocations,
    optionsSponsorship,
    optionsIndustry,
    optionsDomain,
    seniorityLevelLookup,
    seniorityLevelLookupReverse,
    workVisaRequiredLookup,
    workVisaRequiredLookupReverse,
    whenYouStartLookup,
    whenYouStartLookupReverse,
    fileInputRef,
    setProfilePicture,
    handleFileInputChange,
    handleEditButtonClick,
    handleSalaryChange,
    handleValidationSalary,
    setSalary,
    setJobSearch,
    setJobFunc,
    setWorkVisaRequired,
    setSeniorityLevel,
    setWhenYouCanStart,
    setIsWorkRemotely,
    setIsWorkHybrid,
    setIsWorkOnSite,
    setCustomJoiningDate,
    handleJobFun,
    navigate,
    onSubmit,
    setOtherOptions,
    profileImageLoading,
    otherInfo,
    pathname,
    jobFunctionList,
    loadingJobFunc,
    otherInfoAddLoading,
    setWillingToRelocate,
    willingToRelocate,
    additionalDetails,
    setAdditionalDetails,
    industriesList,
    setIndustriesList,
  };
};

export default useSetup;
