import {
  Avatar,
  AvatarBadge,
  Box,
  Card,
  CardBody,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { getTimeAgo } from "../../../../utils/helper";
import { useUserStore } from "../../../../store/useUser";

const ChatList = ({ item, handleSelectedUser }: any) => {
  const { user } = useUserStore();
  // setSelectedUser();

  return (
    <Card
      mb="1 !important"
      w="98%"
      bg={item === 1 || item === 21 ? "#f6fdff" : "white"}
      cursor="pointer"
      margin="auto"
      boxShadow={"none"}
      my="3"
      p="0px !important"
      onClick={() => handleSelectedUser(item)}
    >
      <CardBody py="1" px="0px !important">
        <HStack w="full" justify="space-between">
          <HStack>
            {item.lastMsgTo === user?.userId && item?.unreadCount ? (
              <Box h="10px" w="10px" borderRadius="50%" bg="blue.500"></Box>
            ) : (
              <Box h="10px" w="10px" borderRadius="50%" bg="white"></Box>
            )}
            <Avatar
              size="sm"
              src={
                item?.sendTo.id === user?.userId
                  ? item?.sendBy?.profilePicture
                  : item?.sendTo?.profilePicture
              }
            >
              {/* <AvatarBadge boxSize="1em" bg="green.500" /> */}
            </Avatar>
          </HStack>
          <VStack alignItems={"baseline"} w="full">
            <HStack w="full" justify="space-between">
              <Text
                fontWeight={"bold"}
                color="#48465b"
                fontSize={{ base: "13px", "2xl": "14px" }}
                _hover={{ color: "#5867DD", cursor: "pointer" }}
              >
                {item?.sendTo.id === user?.userId
                  ? item?.sendBy?.name?.length > 15
                    ? item?.sendBy?.name.substring(0, 15) + "..."
                    : item?.sendBy?.name
                  : item?.sendTo?.name?.length > 15
                  ? item?.sendTo?.name.substring(0, 15) + "..."
                  : item?.sendTo?.name}
              </Text>
              <Text
                color={"#74788d"}
                fontSize={{ base: "12px", "2xl": "13px" }}
              >
                {getTimeAgo(
                  new Date(
                    item?.lastUpdate.seconds * 1000 +
                      item.lastUpdate.nanoseconds / 1000000
                  )
                )}
              </Text>
            </HStack>
            <Text
              marginTop={"0px !important"}
              color={"#646c9a"}
              fontSize={{ base: "12px", "2xl": "13px" }}
            >
              {item?.recentMessage?.length > 70
                ? item?.recentMessage?.slice(0, 70) + "..."
                : item?.recentMessage}
            </Text>
          </VStack>
        </HStack>
      </CardBody>
    </Card>
  );
};

export default ChatList;
