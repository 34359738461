import { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardBody,
  Flex,
  Grid,
  Heading,
  Stack,
} from "@chakra-ui/react";
import LikeJobCard from "./likeJobCard";
import { useFetchLikedJobs } from "../../../service/job.service";
import { getUserId } from "../../../utils/helper";
import LoadingScreen from "../../common/loadingScreen";
import NoDataCard from "../../common/nodata";
import ReactPaginate from "react-paginate";

const LikeJobs = () => {
  const userId = getUserId();
  const [itemOffset, setItemOffset] = useState(0);
  const [jobsList, setJobsList] = useState<any>();
  const { isLoading: isLoading, data, refetch } = useFetchLikedJobs(itemOffset);
  const handlePageClick = (event: any) => {
    const newOffset = event.selected;
    setItemOffset(newOffset);
  };
  useEffect(() => {
    if (userId) {
      refetch();
    }
  }, [userId, itemOffset]);
  useEffect(() => {
    if (data !== undefined) {
      setJobsList(data);
    }
  }, [data]);

  return (
    <>
      <Card boxShadow={"none"} borderRadius="none" w="full" bg="#f7f6f8">
        <CardBody py="4 !important">
          <Stack
            direction="row"
            spacing={4}
            align="center"
            justify="flex-start"
          >
            <Heading size="sm" color={"#959cb6"} _hover={{ color: "#5D78FF" }}>
              Likes You
            </Heading>
          </Stack>
        </CardBody>
      </Card>
      {isLoading && !jobsList?.data ? (
        <LoadingScreen />
      ) : jobsList?.data?.length ? (
        <Flex
          flexDirection="column"
          justify="space-between"
          w="full"
          m="auto"
          p="4"
          py="8"
          minH="70vh"
          bg="#f0f1f7"
        >
          <Grid
            w="100% !important"
            templateColumns={[
              "1fr",
              "1fr",
              "1fr",
              "repeat(2, 1fr)",
              "repeat(2, 1fr)",
              "repeat(3, 1fr)",
            ]}
            gap={4}
            overflowX="auto"
            // width={["100%", "100%", "100%", "100%", "100%", "auto"]}
            width="100%"
            sx={{
              "&::-webkit-scrollbar": {
                height: "4px",
              },
              "&::-webkit-scrollbar-track": {
                background: "transparent",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "gray.400",
                borderRadius: "full",
              },
            }}
          >
            {jobsList?.data?.map((item: any, index: number) => (
              <LikeJobCard key={index} data={item} refetch={refetch} />
            ))}
          </Grid>
          <ReactPaginate
            nextLabel="Next >"
            onPageChange={handlePageClick}
            forcePage={itemOffset}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={jobsList?.totalPages ?? 1}
            previousLabel="< Previous"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            breakLabel="..."
            nextClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName={"container"}
            previousLinkClassName={"page"}
            breakClassName={"page"}
            nextLinkClassName={"page"}
            pageClassName={"page"}
            disabledClassName={"disabled"}
            activeClassName={"active-venu-button"}
            // renderOnZeroPageCount={null}
          />
        </Flex>
      ) : (
        <Box p="4">
          <NoDataCard
            title="Employer Interest"
            message={
              "You will see job opportunities here when employers express interest in your profile."
            }
          />
        </Box>
      )}
    </>
  );
};

export default LikeJobs;
