import { Center, Heading, Image, Text } from "@chakra-ui/react";
import {
  Button,
  FormControl,
  Flex,
  Stack,
  useColorModeValue,
  HStack,
} from "@chakra-ui/react";
import { PinInput, PinInputField } from "@chakra-ui/react";
import useVerify from "./useVerify";
import logo from "../../assets/images/logoBlack.svg";
export default function VerifyEmailForm(): JSX.Element {
  const {
    onSubmit,
    handleSubmit,
    state,
    verifyLoading,
    lodaing,
    signupLoading,
    signupCall,
    handleResendOTP,
    isResendOTP,
    error,
    setError,
    otp,
    setOtp,
  } = useVerify();
  const handleOtpChange = (value: any) => {
    setOtp(value);
    setError((prevError: any) => ({
      ...prevError,
      digitCompletionError: "",
    }));
    if (value?.length === 6) {
      onSubmit(value);
    }
  };
  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      backgroundColor="gray.50"
      flexDir={"column"}
    >
      <Stack align={"center"}>
        <Image src={logo} maxW={{ base: "120px", md: "200px" }} mb="8" />
        <Heading fontSize={"2xl"} fontWeight={"500"} textAlign={"center"}>
          {state?.formData?.userType === 1
            ? "Verify your Email"
            : "Verify your Phone Number"}
        </Heading>
      </Stack>
      <Stack
        spacing={4}
        w={"full"}
        maxW={"md"}
        bg={useColorModeValue("white", "gray.700")}
        rounded={"md"}
        boxShadow={"lg"}
        p={6}
        my={10}
      >
        <Center
          fontSize={{ base: "sm", sm: "md" }}
          color={useColorModeValue("gray.800", "gray.400")}
        >
          We have sent code on
        </Center>
        <Center
          fontSize={{ base: "sm", sm: "md" }}
          fontWeight="bold"
          color={useColorModeValue("gray.800", "gray.400")}
        >
          {state?.formData?.query}
        </Center>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl
            isInvalid={
              Boolean(error.digitCompletionError) ||
              Boolean(error.wrongOtpError)
            }
          >
            <Center>
              <HStack>
                <PinInput value={otp} onChange={handleOtpChange}>
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
                {/* <FormErrorMessage>{errors.pin.message}</FormErrorMessage> */}
              </HStack>
            </Center>
            <Center mt={2}>
              {error && (
                <Text color="red">
                  {error.digitCompletionError
                    ? error.digitCompletionError
                    : error.wrongOtpError}
                </Text>
              )}
            </Center>
          </FormControl>
          <Stack spacing={6} mt="2">
            <Button
              type="submit"
              variant="solid"
              colorScheme="buttonPrimary"
              width="70%"
              margin={"auto"}
              isLoading={verifyLoading || lodaing}
              isDisabled={verifyLoading}
            >
              Verify
            </Button>
            <Text
              color="#5D78FF"
              w="full"
              textAlign={"center"}
              cursor={"pointer"}
              onClick={async () => {
                if (!signupLoading) {
                  state.id.length > 1
                    ? handleResendOTP()
                    : await signupCall(state?.formData, {
                        onSuccess: () => {},
                      });
                }
              }}
            >
              {signupLoading || isResendOTP ? "Sending..." : "Resend OTP"}
            </Text>
          </Stack>
        </form>
      </Stack>
    </Flex>
  );
}
