import { Wizard } from "react-use-wizard";
import { GenericModal } from "../common/generic-modal";
import Step1 from "./steps/step1";
import Step2 from "./steps/step2";
import Step3 from "./steps/step3";
import useSchedule from "./useSchedule";

const ScheduleMeeting = ({
  isOpen,
  onClose,
  jobId,
  clientId,
  refetchCandidate,
}: any) => {
  const {
    handleOptionTimes,
    selectedTimes,
    onChangeDate,
    register,
    handleSubmit,
    errors,
    setValue,
    watch,
    trigger,
    onModalClose,
    isSubmitted,
    isSubmitTimes,
    setIsSubmitTimes,
    handleAddInput,
    inputs,
    handleRemoveInput,
    handleInputChange,
    onChangeDate2,
    handleOptionTimes2,
    isSubmitTimes2,
    selectedTimes2,
    toggleAnotherDay,
    anotherDay,
    setIsSubmitTimes2,
    onSubmit,
    control,
    jobFunctionListOption,
    scheduleLoading,
    user,
    setInputs,
    error,
    setError,
    inputError,
    setInputError,
  } = useSchedule(onClose, jobId, clientId, refetchCandidate);

  return (
    <GenericModal
      isOpen={isOpen}
      onClose={onModalClose}
      title={"Schedule a Meeting"}
      onClickHandler={() => {}}
      buttonText={false ? "Loading" : "Save"}
      loadingButton={false || false}
      buttonShow={false}
    >
      <Wizard>
        <Step1
          watch={watch}
          register={register}
          errors={errors}
          setValue={setValue}
          handleSubmit={handleSubmit}
          trigger={trigger}
          isSubmitted={isSubmitted}
          handleAddInput={handleAddInput}
          inputs={inputs}
          handleRemoveInput={handleRemoveInput}
          handleInputChange={handleInputChange}
          jobFunctionListOption={jobFunctionListOption}
          user={user}
          setInputs={setInputs}
          inputError={inputError}
          setInputError={setInputError}
        />
        <Step2
          watch={watch}
          register={register}
          control={control}
          errors={errors}
          setValue={setValue}
          handleSubmit={handleSubmit}
          trigger={trigger}
        />

        <Step3
          selectedTimes={selectedTimes}
          handleOptionTimes={handleOptionTimes}
          onChangeDate={onChangeDate}
          watch={watch}
          onModalClose={onModalClose}
          isSubmitTimes={isSubmitTimes}
          setIsSubmitTimes={setIsSubmitTimes}
          onChangeDate2={onChangeDate2}
          handleOptionTimes2={handleOptionTimes2}
          isSubmitTimes2={isSubmitTimes2}
          selectedTimes2={selectedTimes2}
          toggleAnotherDay={toggleAnotherDay}
          anotherDay={anotherDay}
          setIsSubmitTimes2={setIsSubmitTimes2}
          onSubmit={onSubmit}
          scheduleLoading={scheduleLoading}
          error={error}
          setError={setError}
        />
      </Wizard>
    </GenericModal>
  );
};

export default ScheduleMeeting;
