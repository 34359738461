import { Center, Text, VStack } from "@chakra-ui/react";
import Divider from "../divider";
import Footer from "./footer";
import Header from "./header";
import Messages from "./messages";
import useConversation from "./useConversation";
import LoadingScreen from "../../../common/loadingScreen";

const Conversation = ({ selectedUser }: any) => {
  const {
    handleSendMessage,
    inputMessage,
    setInputMessage,
    message,
    messageLoading,
  } = useConversation(selectedUser);

  return (
    <VStack
      w={["100%", "100%", "100%", "60%"]}
      bg="white"
      h="full"
      borderRadius={"md"}
      boxShadow="lg"
    >
      {messageLoading ? (
        <LoadingScreen />
      ) : selectedUser && message ? (
        <>
          <Header selectedUser={selectedUser} />
          <Divider />
          <Messages message={message} selectedUser={selectedUser} />
          <Divider />
          <Footer
            handleSendMessage={handleSendMessage}
            inputMessage={inputMessage}
            setInputMessage={setInputMessage}
          />
        </>
      ) : (
        <Center minH="70vh">
          <Text color="#646c9a"> No Messages</Text>
        </Center>
      )}
    </VStack>
  );
};

export default Conversation;
