import { useEffect, useState } from "react";
import { getUserId } from "../../../utils/helper";
import { useFetchJobFunc } from "../../../service/helper.service";
import { useFetch } from "../../../utils/useFetch";
import { scrollToTopOfScrollable } from "../../../utils/scroll-to-top";

const useTalent = () => {
  const [jobFunction, setjobFunction] = useState("");
  const [query, setQuery] = useState("");
  const [initialFetch, setInitialFetch] = useState(true);
  const userId = getUserId();
  const [candidateList, setCandidateList] = useState<any>([]);
  const [candidateLoading, setCandidateLoading] = useState(false);
  const [itemOffset, setItemOffset] = useState(0);

  const { data: jobFunctionListOption } = useFetchJobFunc(userId);

  // const {
  //   isLoading: candidateLoading,
  //   data: candidateList,
  //   refetch: refetchCandidate,
  // } = useFetchCandidates({ query, jobFunction, itemOffset });
  function fetchCandidateData() {
    setCandidateLoading(true);
    useFetch
      .get(
        `/users/talent/candidate?page=${itemOffset}&limit=12&function=${jobFunction}&query=${query.trim()}`
      )
      .then((res) => {
        setCandidateLoading(false);
        setCandidateList(res?.data);
        scrollToTopOfScrollable();
      })
      .catch((error) => {
        setCandidateLoading(false);
        console.log("ERROR", error);
      });
  }

  const selectJobFunc = (e: any) => {
    if (e === "All") {
      setjobFunction("");
    } else {
      setjobFunction(e);
    }
  };
  useEffect(() => {
    fetchCandidateData();
  }, []);
  useEffect(() => {
    fetchCandidateData();
  }, [itemOffset, jobFunction, query]);
  // useEffect(() => {
  //   if (initialFetch) {
  //     refetchCandidate();
  //     setInitialFetch(false);
  //   } else {
  //     const delayDebounceFn = setTimeout(() => {
  //       refetchCandidate();
  //     }, 1000);

  //     return () => {
  //       clearTimeout(delayDebounceFn);
  //     };
  //   }
  // }, [query, refetchCandidate, initialFetch]);

  const handleSearch = (event: any) => {
    const searchParam = event.target.value.trim();
    setQuery(searchParam);
    const delayDebounceFn = setTimeout(() => {
      fetchCandidateData();
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  };
  return {
    candidateList,
    candidateLoading,
    jobFunctionListOption,
    selectJobFunc,
    jobFunction,
    handleSearch,
    setQuery,
    itemOffset,
    setItemOffset,
  };
};

export default useTalent;
