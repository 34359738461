import * as React from "react";
import { Route, Routes } from "react-router";
import { useLocation } from "react-router-dom";

import { CandidateRoutes } from "./components/routes/candidate-routes";
import { EmployeerRoutes } from "./components/routes/employeer-routes";
import PublicRoutes from "./components/routes/PublicRoute/public-route";
import ForgotPassPage from "./pages/forgot-password-employeer";
import LoginPage from "./pages/login";
import ResetPasswordPage from "./pages/reset-password";
import SignupPage from "./pages/signup";
import VerifyPage from "./pages/verify";
import LoadingScreen from "./components/common/splash";
import PrivateRoutes from "./components/routes/employeer-routes/PrivateRoute/private-routes";
import MembersAuth from "./components/employeer/settings/membersAuth";
import LoginAs from "./components/signup/loginAs/LoginAs";

import { getUserId, handleLogoutLocalStorage } from "./utils/helper";
import { useFetch } from "./utils/useFetch";
import { useUserStore } from "./store/useUser";

export const App = () => {
  const userId = getUserId();
  const { pathname } = useLocation();
  const [role, setRole] = React.useState<any>(null);
  const { setUser, setUserName } = useUserStore();

  const bypassrole = localStorage.getItem("bypassed_user");
  const parsedRole = bypassrole ? JSON.parse(bypassrole) : null;
  const name = localStorage.getItem("userName");

  React.useEffect(() => {
    const isName =
      name && name !== "" && name !== "undefined"
        ? JSON?.parse(name)
        : undefined;

    setUserName(isName);
  }, []);

  React.useEffect(() => {
    const strifigyData: any = localStorage?.getItem("data");
    const fetchRole = async () => {
      try {
        const { data: response } = await useFetch.get(
          `/users/auth/user-type?userId=${userId}`
        );

        setUser(JSON.parse(strifigyData));
        setRole(parsedRole?.userType);
        setRole(response);

        if (response === "User Not Found 400") {
          handleLogoutLocalStorage();
        }
      } catch (error) {
        handleLogoutLocalStorage();
        window.location.href = "/";
        console.error("Error fetching role:", error);
      }
    };
    if (userId) {
      fetchRole();
    } else {
      setRole("GUEST");
    }
  }, [userId]);

  if (role === null && pathname !== "/signup" && pathname !== "/verify") {
    // Show loading state until the role is fetched
    return <LoadingScreen />;
  }

  return (
    <>
      <Routes>
        <Route element={<PublicRoutes />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="signup" element={<SignupPage />} />
          <Route path="forgot-password" element={<ForgotPassPage />} />
          <Route path="verify" element={<VerifyPage />} />
          <Route path="reset-password" element={<ResetPasswordPage />} />
          <Route path="membersAuth" element={<MembersAuth />} />
          <Route path="loginAs" element={<LoginAs />} />
        </Route>
        {role === 1 ? (
          <Route path="/*" element={<EmployeerRoutes />} />
        ) : role === 2 ? (
          <Route path="/*" element={<CandidateRoutes />} />
        ) : (
          <Route element={<PrivateRoutes />}>
            <Route path="/*" element={<LoadingScreen />} />
          </Route>
        )}
      </Routes>
    </>
  );
};
