import { Button, Card, Flex, Heading, Text, VStack } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserRole } from "../../utils/helper";

const NoDataCard = ({ title, message }: any) => {
  const navigate = useNavigate();
  const userRole = getUserRole();
  const { pathname } = useLocation();
  return (
    <>
      <VStack w="full">
        <Card p={4} boxShadow="md" borderRadius="md" py="10" w="full">
          {userRole === 1 ? (
            pathname === "/liked" ? (
              <Heading size="md" mb={4} color="#48465b">
                Job Applicants Overview
              </Heading>
            ) : pathname === "/search" ? (
              <Heading size="md" mb={4} color="#48465b">
                No Candidate Assigned Yet
              </Heading>
            ) : pathname === "/interview" ? (
              <Heading size="md" mb={4} color="#48465b">
                Scheduled Interviews
              </Heading>
            ) : (
              <Heading size="md" mb={4} color="#48465b">
                Post Your First Job
              </Heading>
            )
          ) : (
            <Heading size="md" mb={4} color="#48465b">
              {title || "Thank you for signing!"}
            </Heading>
          )}

          <Text color="#646c9a">{message}</Text>
          {userRole === 1 && pathname === "/jobs" && (
            <Flex>
              <Button
                loadingText="Loading"
                colorScheme="green"
                bg="#0abb87"
                color="white"
                size="sm"
                fontSize={"12px"}
                onClick={() => navigate("/jobs/post")}
                textTransform={"uppercase"}
              >
                Post a New Job
              </Button>
            </Flex>
          )}
        </Card>
      </VStack>
    </>
  );
};

export default NoDataCard;
