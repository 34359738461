import {
  Avatar,
  Badge,
  Box,
  Flex,
  HStack,
  Heading,
  Icon,
  IconButton,
  SkeletonCircle,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { GoLocation } from "react-icons/go";
import { BiPencil } from "react-icons/bi";
import React, { useEffect, useRef, useState } from "react";
import EditProfileGeneral from "./EditProfileGeneral";
import { getUserId } from "../../../../utils/helper";
import {
  useFetchLocation,
  useGeneralInfoFetch,
} from "../../../../service/education.service";
import { useUserStore } from "../../../../store/useUser";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../../../../firebase";
import { useAddProfileImage } from "../../../../service/candidate.service";

const ProfileGeneral = () => {
  const { mutate: profileImageMutation } = useAddProfileImage();
  const userId = getUserId();

  const [companyLogoLoading, setCompanyLogoLoading] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isLoading: loadingGeneral, data: generalInfo } =
    useGeneralInfoFetch(userId);
  const { user, setUser, profilepicture, setprofilePicture } = useUserStore();
  const handleFileInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file: any = event.target.files?.[0];
    const imageRef = ref(storage, `images/${userId}`);
    try {
      setCompanyLogoLoading(true);
      await uploadBytes(imageRef, file).then(async (snapshot) => {
        await getDownloadURL(snapshot.ref).then(async (url: any) => {
          profileImageMutation({ profilePicture: url, userID: user?.userId });
          setUser({ ...user, profilePicture: url });
          setCompanyLogoLoading(false);
        });
      });
    } catch (error) {
      setCompanyLogoLoading(false);
    }
  };
  useEffect(() => {
    setprofilePicture(generalInfo?.profilePicture);
  }, [generalInfo]);
  return (
    <>
      <Box position="relative" background="white" p="5">
        <Heading size="md" mb="4" color="#646c9a">
          Personal Information
        </Heading>

        <Flex flex="1" gap="4" alignItems="flex-start">
          {companyLogoLoading ? (
            <SkeletonCircle size="96px" />
          ) : (
            <Avatar size="xl" src={user?.profilePicture || profilepicture} />
          )}

          <input
            ref={fileInputRef}
            type="file"
            accept=".png, .jpeg, .jpg, .svg"
            style={{ display: "none" }}
            onChange={handleFileInputChange}
          />

          <Box>
            <Heading
              fontSize={{
                base: "16.8px",
                md: "16.8px",
                lg: "18.2px",
                xl: "18.2px",
              }}
              fontWeight={"500"}
              _hover={{ color: "#5867DD" }}
            >
              {generalInfo?.fullName}
            </Heading>
            <Text
              fontSize={{ base: "13px", md: "13px" }}
              fontWeight="bold"
              color={"#646c9a"}
              mt="1"
            >
              {generalInfo?.jobTitle}
              {generalInfo?.companyName && ` @ ${generalInfo?.companyName}`}
            </Text>
            <Text
              fontSize={{ base: "13px", md: "13px" }}
              color={"#646c9a"}
              mt="1"
            >
              {generalInfo?.latestDegree}{" "}
              {generalInfo?.schoolName && ` @ ${generalInfo?.schoolName}`}
            </Text>
            <HStack align={{ base: "start", lg: "end" }}>
              <Icon as={GoLocation} mr="1" color={"blue.500"} mt="2" />
              <Text fontSize={{ base: "13px", md: "13px" }} color={"#646c9a"}>
                {generalInfo && JSON.parse(generalInfo?.address)?.label}
              </Text>
              {generalInfo?.willingToRelocate && (
                <Badge
                  ml="1"
                  fontSize="8px"
                  colorScheme="buttonPrimary"
                  p="1"
                  color={"#5867DD"}
                  px="2"
                  bg={"#5d78ff1a"}
                  textTransform={"capitalize"}
                >
                  willing to relocate
                </Badge>
              )}
            </HStack>
          </Box>
        </Flex>
        <IconButton
          position="absolute"
          top="15px"
          right={{ base: "5px", md: "15px" }}
          variant="outline"
          aria-label="Edit Icon"
          borderRadius="full"
          icon={<BiPencil />}
          onClick={onOpen}
        />
      </Box>
      <EditProfileGeneral isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default ProfileGeneral;
