import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ReactPaginate from "react-paginate";
import { Button } from "@chakra-ui/button";
import { Card, CardBody } from "@chakra-ui/card";
import { Flex, Grid, Heading, Stack } from "@chakra-ui/layout";
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Text,
  Tabs,
} from "@chakra-ui/react";

import JobCard from "./jobCard";
import { useFetchJobs } from "../../../service/candidate.service";
import { getUserId } from "../../../utils/helper";
import LoadingScreen from "../../common/loadingScreen";

import "./paginationStyling.css";

const JobsEmployeer = () => {
  const navigate = useNavigate();
  const userId = getUserId();
  const [jobsList, setJobsList] = useState<any>([]);
  const [activeJobsData, setActiveJobsData] = useState<any>([]);
  const [closedJobsData, setClosedJobsData] = useState<any>([]);

  const [itemOffset, setItemOffset] = useState(0);
  const [activeitemOffset, setActiveItemOffset] = useState(0);
  const [closeitemOffset, setCloseItemOffset] = useState(0);

  const handlePageClick = (event: any) => {
    const newOffset = event.selected;
    setItemOffset(newOffset);
  };

  const handleActivePageClick = (event: any) => {
    const newOffset = event.selected;
    setActiveItemOffset(newOffset);
  };

  const handleClosePageClick = (event: any) => {
    const newOffset = event.selected;
    setCloseItemOffset(newOffset);
  };

  const {
    isLoading: allJobsLoading,
    data: allJobs,
    refetch: refetchAllJobs,
  } = useFetchJobs(userId, "gat-all", itemOffset, setItemOffset);

  const {
    isLoading: activeJobsLoading,
    data: activeJobs,
    refetch: refetchActiveJobs,
  } = useFetchJobs(userId, "active", activeitemOffset, setActiveItemOffset);

  const {
    isLoading: closedJobsLoading,
    data: closedJobs,
    refetch: refetchClosedJobs,
  } = useFetchJobs(userId, "closed", closeitemOffset, setCloseItemOffset);

  useEffect(() => {
    if (allJobs !== undefined) {
      setJobsList(allJobs);
    }
  }, [allJobs]);
  useEffect(() => {
    if (activeJobs !== undefined) {
      setActiveJobsData(activeJobs);
    }
  }, [activeJobs]);
  useEffect(() => {
    if (closedJobs !== undefined) {
      setClosedJobsData(closedJobs);
    }
  }, [closedJobs]);
  // useEffect(() => {}, [itemOffset, activeitemOffset, closeitemOffset]);

  useEffect(() => {
    refetchAllJobs();
  }, [itemOffset]);

  useEffect(() => {
    refetchActiveJobs();
  }, [activeitemOffset]);

  useEffect(() => {
    refetchClosedJobs();
  }, [closeitemOffset]);

  return (
    <>
      <Flex direction="column" w="full" padding="10px" gap="10px">
        <Card
          bg="#fff"
          borderRadius="0px"
          boxShadow="none"
          borderTop="1px solid #e6e9fa"
        >
          <CardBody py="3 !important">
            <Stack
              direction="row"
              spacing={4}
              align="center"
              justify="space-between"
            >
              <Heading fontSize="20px" color="#646c9a" fontWeight="500">
                Your Jobs
              </Heading>
            </Stack>
          </CardBody>
        </Card>
        <Tabs variant="enclosed">
          <TabList>
            <Tab>All</Tab>
            <Tab>Active</Tab>
            <Tab>Closed</Tab>
          </TabList>
          {allJobsLoading && !!!jobsList?.data ? (
            <Flex minH="20vh" align="end">
              <LoadingScreen />
            </Flex>
          ) : jobsList?.data?.length ? (
            <TabPanels>
              <TabPanel>
                {jobsList?.data?.length ? (
                  <>
                    <Flex
                      flexDirection={"column"}
                      justify="space-between"
                      minH="70vh"
                      w="full"
                    >
                      <Grid
                        w="100% !important"
                        templateColumns={[
                          "1fr",
                          "1fr",
                          "1fr",
                          "repeat(2, 1fr)",
                          "repeat(2, 1fr)",
                          "repeat(3, 1fr)",
                        ]}
                        //   p="2"
                        gap={4}
                        overflowX="auto"
                        sx={{
                          "&::-webkit-scrollbar": {
                            height: "4px",
                          },
                          "&::-webkit-scrollbar-track": {
                            background: "transparent",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            background: "gray.400",
                            borderRadius: "full",
                          },
                        }}
                      >
                        <Card
                          p={4}
                          boxShadow="md"
                          borderRadius="md"
                          py="10"
                          w="full"
                          gap={4}
                        >
                          <Heading size="md" color="#48465b">
                            Post A New Job
                          </Heading>

                          <Text color="#646c9a">
                            Ready to find more talent? Post your next job here.
                          </Text>

                          <Flex>
                            <Button
                              loadingText="Loading"
                              colorScheme="green"
                              bg="#0abb87"
                              color="white"
                              size="sm"
                              fontSize={"12px"}
                              onClick={() => navigate("/jobs/post")}
                              textTransform={"uppercase"}
                            >
                              Post a New Job
                            </Button>
                          </Flex>
                        </Card>

                        {jobsList?.data?.map((item: any, index: number) => (
                          <JobCard
                            key={index}
                            data={item}
                            refetchDataArray={[
                              refetchAllJobs,
                              refetchActiveJobs,
                              refetchClosedJobs,
                            ]}
                          />
                        ))}
                      </Grid>

                      <ReactPaginate
                        nextLabel="Next >"
                        onPageChange={handlePageClick}
                        forcePage={itemOffset}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                        pageCount={jobsList?.totalPages || 0}
                        previousLabel="< Previous"
                        breakLabel="..."
                        previousClassName={`page-item${
                          itemOffset === 0 ? " disabled" : ""
                        }`}
                        nextClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName={"container"}
                        previousLinkClassName={"page"}
                        breakClassName={"page"}
                        nextLinkClassName={"page"}
                        pageClassName={"page"}
                        disabledClassName={"disabled"}
                        activeClassName={"active-venu-button"}
                      />
                    </Flex>
                  </>
                ) : (
                  <Card>
                    <CardBody>
                      <Text>No Jobs Available</Text>
                    </CardBody>
                  </Card>
                )}
              </TabPanel>
              <TabPanel>
                {activeJobsLoading && !!!activeJobsData?.data ? (
                  <Flex minH="20vh" align="end">
                    <LoadingScreen />
                  </Flex>
                ) : activeJobsData?.data?.length ? (
                  <>
                    <Flex
                      flexDirection="column"
                      justify="space-between"
                      minH="70vh"
                      w="full"
                    >
                      <Grid
                        w="100% !important"
                        templateColumns={[
                          "1fr",
                          "1fr",
                          "1fr",
                          "repeat(2, 1fr)",
                          "repeat(2, 1fr)",
                          "repeat(3, 1fr)",
                        ]}
                        //   p="2"
                        gap={4}
                        overflowX="auto"
                        sx={{
                          "&::-webkit-scrollbar": {
                            height: "4px",
                          },
                          "&::-webkit-scrollbar-track": {
                            background: "transparent",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            background: "gray.400",
                            borderRadius: "full",
                          },
                        }}
                      >
                        {activeJobsData?.data?.map(
                          (item: any, index: number) => (
                            <JobCard
                              key={index}
                              data={item}
                              refetchDataArray={[
                                refetchAllJobs,
                                refetchActiveJobs,
                                refetchClosedJobs,
                              ]}
                            />
                          )
                        )}
                      </Grid>
                      <ReactPaginate
                        nextLabel="Next >"
                        onPageChange={handleActivePageClick}
                        forcePage={itemOffset}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={activeJobsData?.totalPages || 0}
                        previousLabel="< Previous"
                        breakLabel="..."
                        previousClassName={`page-item${
                          itemOffset === 0 ? " disabled" : ""
                        }`}
                        nextClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName={"container"}
                        previousLinkClassName={"page"}
                        breakClassName={"page"}
                        nextLinkClassName={"page"}
                        pageClassName={"page"}
                        disabledClassName={"disabled"}
                        activeClassName={"active-venu-button"}
                      />
                    </Flex>
                  </>
                ) : (
                  <Card>
                    <CardBody>
                      <Text>No Jobs Available</Text>
                    </CardBody>
                  </Card>
                )}
              </TabPanel>
              <TabPanel>
                {closedJobsData?.data?.length ? (
                  <>
                    <Flex
                      flexDirection="column"
                      justify="space-between"
                      minH="70vh"
                      w="full"
                    >
                      <Grid
                        w="100% !important"
                        templateColumns={[
                          "1fr",
                          "1fr",
                          "1fr",
                          "repeat(2, 1fr)",
                          "repeat(2, 1fr)",
                          "repeat(3, 1fr)",
                        ]}
                        //   p="2"
                        gap={4}
                        overflowX="auto"
                        sx={{
                          "&::-webkit-scrollbar": {
                            height: "4px",
                          },
                          "&::-webkit-scrollbar-track": {
                            background: "transparent",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            background: "gray.400",
                            borderRadius: "full",
                          },
                        }}
                      >
                        {closedJobsData?.data?.map(
                          (item: any, index: number) => (
                            <JobCard
                              key={index}
                              data={item}
                              refetchDataArray={[
                                refetchAllJobs,
                                refetchActiveJobs,
                                refetchClosedJobs,
                              ]}
                            />
                          )
                        )}
                      </Grid>
                      <ReactPaginate
                        nextLabel="Next >"
                        onPageChange={handleClosePageClick}
                        forcePage={itemOffset}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={closedJobsData?.totalPages || 0}
                        previousLabel="< Previous"
                        breakLabel="..."
                        previousClassName={`page-item${
                          itemOffset === 0 ? " disabled" : ""
                        }`}
                        nextClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName={"container"}
                        previousLinkClassName={"page"}
                        breakClassName={"page"}
                        nextLinkClassName={"page"}
                        pageClassName={"page"}
                        disabledClassName={"disabled"}
                        activeClassName={"active-venu-button"}
                      />
                    </Flex>
                  </>
                ) : (
                  <Card>
                    <CardBody>
                      <Text>No Jobs Available</Text>
                    </CardBody>
                  </Card>
                )}
              </TabPanel>
            </TabPanels>
          ) : (
            <Card
              p={4}
              boxShadow="md"
              borderRadius="md"
              py="10"
              w="full"
              gap={4}
            >
              <Heading size="md" color="#48465b">
                Post Your First Job
              </Heading>

              <Text color="#646c9a">
                It's time to post your first job and start interviewing
                candidates right away!
              </Text>
              <Flex>
                <Button
                  loadingText="Loading"
                  colorScheme="green"
                  bg="#0abb87"
                  color="white"
                  size="sm"
                  fontSize="12px"
                  onClick={() => navigate("/jobs/post")}
                  textTransform={"uppercase"}
                >
                  Post a New Job
                </Button>
              </Flex>
            </Card>
          )}
        </Tabs>
      </Flex>
    </>
  );
};

export default JobsEmployeer;
