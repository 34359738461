import { motion } from "framer-motion";

const StepsAnimation = ({ children }: any) => {
  const pageVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  };

  const pageTransition = {
    type: "spring",
    ease: "linear",
    duration: 1.5,
  };
  return (
    <motion.div
      variants={pageVariants}
      transition={pageTransition}
      initial={{ x: -20, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      style={{ width: "100%" }}
    >
      {children}
    </motion.div>
  );
};

export default StepsAnimation;
