import {
  Card,
  CardBody,
  Flex,
  HStack,
  IconButton,
  Text,
  VStack,
} from "@chakra-ui/react";
import moment from "moment";
import { RiDeleteBin2Line } from "react-icons/ri";
import useEducation from "./useEducation";
import { BiPencil } from "react-icons/bi";
import { getUserId } from "../../../../utils/helper";

const EducationCard = ({ data, index, isLoading }: any) => {
  const userId = getUserId();
  const { deleteHandler, educationDelLoading, navigate } = useEducation();
  const { degree, educationStatus, startDate, endDate, schoolName, id, major } =
    data;

  return (
    <Card w="full">
      <CardBody p="2">
        <HStack justify={"flex-end"} w="full">
          <IconButton
            aria-label="edit"
            icon={<BiPencil />}
            size="sm"
            as="span"
            onClick={() =>
              navigate("add", { state: { ...data, isEdit: true } })
            }
            cursor={"pointer"}
            color={"blue.500"}
          />
          <IconButton
            aria-label="delete"
            icon={<RiDeleteBin2Line />}
            size="sm"
            as="span"
            onClick={() => deleteHandler(id, userId)}
            isLoading={educationDelLoading}
            isDisabled={educationDelLoading}
            cursor={"pointer"}
            color={"red.500"}
          />
        </HStack>

        <VStack w="full" align={"baseline"}>
          <HStack w="full">
            <Text fontWeight={"bold"}>Degree: </Text>
            <Text> {`${degree}`}</Text>
          </HStack>
          <HStack w="full">
            <Text fontWeight={"bold"}>School Name:</Text>
            <Text>
              {schoolName?.length > 60
                ? schoolName?.slice(0, 60) + "..."
                : schoolName}
            </Text>
          </HStack>
          <HStack w="full">
            <Text fontWeight={"bold"}>Field of Study:</Text>
            <Text>
              {major?.length > 60 ? major?.slice(0, 60) + "..." : major}
            </Text>
          </HStack>
          <Flex
            direction={{ base: "column", md: "row" }}
            w="full"
            justify={{ base: "start", md: "space-between" }}
            align={{ base: "start", md: "center" }}
            gap={{ base: "2", md: "3" }}
          >
            <HStack align={"baseline"} flex={1}>
              <Text fontWeight={"bold"}>Status:</Text>
              <Text ml={"12px"}>
                {educationStatus === "IN_PROCESS"
                  ? "In Progress"
                  : educationStatus === "INCOMPLETE"
                  ? "Incomplete"
                  : educationStatus === "COMPLETED"
                  ? "Completed"
                  : educationStatus}
              </Text>
            </HStack>
            {educationStatus && (
              <Flex
                align={{ base: "start", md: "center" }}
                direction={{ base: "column", md: "row" }}
                justify={{ base: "start", md: "space-between" }}
                gap="2"
                flex={2}
              >
                <HStack align="center">
                  <Text fontWeight="bold">
                    {educationStatus === "IN_PROCESS"
                      ? "Start Date: "
                      : "Start Date: "}
                  </Text>
                  <Text>{moment(startDate).format("MMMM YYYY")}</Text>
                </HStack>
                <HStack align="center" minW={"150px"}>
                  <Text fontWeight={"bold"}>
                    {educationStatus === "IN_PROCESS"
                      ? "Expected End Date:"
                      : "End Date:"}
                  </Text>
                  <Text>
                    {endDate && endDate !== null
                      ? moment(endDate).format("MMMM YYYY")
                      : "N/A"}
                  </Text>
                </HStack>
              </Flex>
            )}
          </Flex>
        </VStack>
      </CardBody>
    </Card>
  );
};

export default EducationCard;
