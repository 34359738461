import { useEffect } from "react";
import { Center, Flex, Spinner } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useUserStore } from "../../../store/useUser";

const MembersAuth = () => {
  const { setUser } = useUserStore();
  const navigate = useNavigate();
  const API = process.env.REACT_APP_BACKEND_URL;
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const token = searchParams.get("h");

  useEffect(() => {
    if (token) {
      axios
        .get(`${API}/users/auth/verify-invite?h=${token}`)
        .then((response) => {
          const acessToken = response?.data?.token;
          const dataString = JSON.stringify(response?.data);
          localStorage.setItem("data", dataString);
          localStorage.setItem("access_token", JSON.stringify(acessToken));
          setUser(response?.data);
          navigate("/");
        })
        .catch((error) => console.log("ERROR", error));
    }
  }, [token]);
  return (
    <Flex justifyContent="center" alignItems="center">
      <Center h="100vh">
        <Spinner
          boxSize="150px"
          thickness="10px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Center>
    </Flex>
  );
};

export default MembersAuth;
