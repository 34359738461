import { useEffect, useRef, useState } from "react";
import { IoMdSettings } from "react-icons/io";
import { useForm, SubmitHandler, FieldValues } from "react-hook-form";

import {
  Flex,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { FaSearch } from "react-icons/fa";
import { BsFilterRight } from "react-icons/bs";

import PipelineFilter from "./filter/pipeline-filter";
import ReportView from "./reoprt-view/report-view";
import { useFetch } from "../../../utils/useFetch";
import { useUserStore } from "../../../store/useUser";
import useFilter from "./filter/useFilter";
import { stageOptions } from "../../../utils/helper";
interface Stage {
  stagingLevel: string;
  bestTime: number;
}
const PipelineVisualization = () => {
  const {
    inputref,
    error,
    topSkills,
    isSkillsFocused,
    setIsSkillsFocused,
    setTopSkills,
    selected,
    jobFunctionList,
    setJobFunctionList,
    jobFuncDisplayList,
    setJobFuncDisplayList,
    seniorityLevelList,
    setSeniorityLevelList,
    setSelected,
    skills,
    setSkills,
    showCustomInput,
    setShowCustomInput,
    searchTopSkillsResult,
    setSearchTopSkillsResult,
    setError,
    handleAddTopSkills,
    handleRemoveJobFunction,
    handleAddJobFunctions,
    handleRemoveSeniorityLevel,
    handleAddSeniorityList,
    handleSkillsFocusBlur,
    handleRemoveTopSkills,
    showTopSkillsResult,
    setShowTopSkillsResult,
    domainDisplayList,
    handleRemoveDomain,
    handleAddDomainList,
    domainList,
    handleRemoveSkill,
    handleAddWorkPermit,
    handleRemoveWorkPermit,
    workPermitList,
    setWorkPermitList,
    handleAddLocation,
    handleRemoveLcoation,
    locationList,
    stageList,
    hiringTeamList,
    setHiringTeamList,
    hiringTeamResults,
    jobRequisitionList,
    setJobRequisitionList,
    handleAddStages,
    handleRemoveStages,
    handleAddHiringTeam,
    handleRemoveHiringTeam,
    handleAddJobRequisition,
    handleRemoveJobRequisition,
    requisition,
    setRequisition,
  } = useFilter();

  const { register, handleSubmit } = useForm();
  const [activeTab, setActiveTab] = useState("Active Candidate");
  const [isActiveLoading, setIsActiveLoading] = useState(false);
  const [isArchiveLoading, setIsArchiveLoading] = useState(false);
  const [isTimeSubmitting, setIsTimeSubmitting] = useState(false);
  const [activeItemOffset, setActiveItemOffset] = useState(0);
  const [archievedItemOffset, setArchievedItemOffset] = useState(0);
  const [queryActive, setQueryActive] = useState("");
  const [queryArchive, setQueryArchive] = useState("");
  const [isActivelyInterviewing, setIsActivelyInterviewing] =
    useState<any>(false);
  const [stagingList, setStagingList] = useState<any>([]);
  const [activePipelineData, setActivePipelineData] = useState<any>([]);
  const [archievedPipelineData, setArchievedPipelineData] = useState<any>([]);
  const { user } = useUserStore();
  const btnRef: any = useRef<HTMLButtonElement>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isSettingOpen,
    onOpen: onSettingOpen,
    onClose: onSettingClose,
  } = useDisclosure();

  function fetchStagingTime() {
    useFetch
      .get(`jobs/staging/time?companyId=${user?.companyId}`)
      .then((response) => {
        setStagingList(response.data);
      })
      .catch((error) => console.log(error));
  }
  function fetchActivePipelineData() {
    setIsActiveLoading(true);
    const hiringIDsList = hiringTeamList
      .filter((item: any) => item.id)
      .map((data: any) => data.id);
    const seniorityLevels = seniorityLevelList.map((data: any) => {
      let level = null;
      if (data === "Entry-level") {
        level = 0;
      } else if (data === "Mid-level, hands-on") {
        level = 1;
      } else if (data === "Senior-level, hands-on") {
        level = 2;
      } else if (data === "Manager, hands-on") {
        level = 3;
      } else if (data === "Manager, with direct reports") {
        level = 4;
      }

      return level;
    });

    try {
      useFetch
        .get(
          `/jobs/staging/pipeline?page=${activeItemOffset}&limit=10&query=${queryActive?.trim()}&jobFunctions=${jobFunctionList}&seniority=${seniorityLevels}&jobSkills=${topSkills}&workPermits=${workPermitList}&jobLocations=${locationList}&hiringTeamId=${hiringIDsList}&jobRequisitions=${jobRequisitionList}&isActivelyInterviewing=${isActivelyInterviewing}`
        )
        .then((response) => {
          setIsActiveLoading(false);
          setActivePipelineData(response?.data);
        });
    } catch (error) {
      console.log("error", error);
      setIsActiveLoading(false);
    }
  }
  function fetchArchievedPipelineData() {
    setIsArchiveLoading(true);
    const hiringIDsList = hiringTeamList
      .filter((item: any) => item.id)
      .map((data: any) => data.id);
    try {
      useFetch
        .get(
          `/jobs/staging/archived-candidates-pipeline?query=${queryArchive?.trim()}&jobFunctions=${jobFunctionList}&seniority=${seniorityLevelList}&jobSkills=${topSkills}&workPermits=${workPermitList}&jobLocations=${locationList}&hiringTeamId=${hiringIDsList}&jobRequisitions=${jobRequisitionList}&isActivelyInterviewing=${isActivelyInterviewing}`
        )
        .then((response) => {
          setIsArchiveLoading(false);

          setArchievedPipelineData(response?.data);
        });
    } catch (error) {
      setIsArchiveLoading(false);

      console.log("error", error);
    }
  }
  function resetFilters() {
    setJobFunctionList([]);
    setSeniorityLevelList([]);
    setTopSkills([]);
    setWorkPermitList([]);
    setHiringTeamList([]);
    setJobRequisitionList([]);
    setQueryActive("");
    setQueryArchive("");
    setIsActivelyInterviewing(false);
  }
  useEffect(() => {
    fetchStagingTime();
  }, []);

  useEffect(() => {
    fetchActivePipelineData();
  }, [activeItemOffset, queryActive, isActivelyInterviewing]);
  useEffect(() => {
    fetchArchievedPipelineData();
  }, [archievedItemOffset, queryArchive, isActivelyInterviewing]);

  const applyFilters = () => {
    if (activeTab === "Active Candidate") {
      fetchActivePipelineData();
    } else {
      fetchArchievedPipelineData();
    }
  };

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    setIsTimeSubmitting(true);
    const updatedStagingList = stagingList.map((stage: any, index: number) => ({
      ...stage,
      bestTime: data.stagingList[index]?.bestTime || stage.bestTime,
    }));

    const BODY = {
      companyId: user?.companyId,
      stagingLevel: updatedStagingList,
    };
    useFetch
      .put(`jobs/staging/time`, BODY)
      .then((response) => {
        setIsTimeSubmitting(false);
        fetchStagingTime();
        onSettingClose();
      })
      .catch((error) => {
        setIsTimeSubmitting(false);
        console.log(error);
      });
  };
  function getStageName(value: string) {
    for (const stage of stageOptions) {
      if (stage.value === value) {
        return stage.name;
      }
    }
    return "";
  }
  return (
    <>
      <Flex direction="column" w="full" borderTop="1px solid #ECEDF3">
        <Flex
          align="center"
          w="full"
          bg="white"
          padding={{ base: "5px 10px", lg: "0 25px" }}
          minH="54px"
          justify="space-between"
        >
          <Flex align="center" w="full">
            <Flex flex="0 0 40%">
              <Text
                fontWeight="500"
                color="#6483C4"
                fontSize={{ base: "14px", md: "16px", lg: "1.5rem" }}
              >
                Pipeline Visualization
              </Text>
            </Flex>
            <Flex
              align={{ base: "start", md: "center" }}
              gap={{ base: "5px", lg: "15px" }}
              direction={{ base: "column", md: "row" }}
            >
              <Text
                minW={{ base: "60px", lg: "80px" }}
                fontSize={{ base: "12px", md: "14px", lg: "16px" }}
              >
                {activeTab === "Active Candidate"
                  ? activePipelineData?.totalElements
                  : archievedPipelineData?.totalElements}{" "}
                Total
              </Text>
              <InputGroup bg="#F2F3F7">
                <Input
                  type="text"
                  placeholder="Search..."
                  value={
                    activeTab === "Active Candidate"
                      ? queryActive
                      : queryArchive
                  }
                  onChange={(e) => {
                    activeTab === "Active Candidate"
                      ? setQueryActive(e?.target?.value)
                      : setQueryArchive(e?.target?.value);
                  }}
                />
                <InputRightElement>
                  <FaSearch color="#788EFE" />
                </InputRightElement>
              </InputGroup>
            </Flex>
          </Flex>
          <Flex direction="column" gap="3px" ps="4px">
            <IoMdSettings cursor="pointer" onClick={onSettingOpen} />
            <Flex display={{ base: "flex", lg: "none" }} justify="end" flex={1}>
              <Icon
                as={BsFilterRight}
                fontSize="18px"
                onClick={onOpen}
                cursor="pointer"
              />
            </Flex>
          </Flex>
        </Flex>
        <Flex padding="25px " gap="20px" w="full">
          <Flex display={{ base: "none", lg: "flex" }}>
            <PipelineFilter
              inputef={inputref}
              error={error}
              topSkills={topSkills}
              isSkillsFocused={isSkillsFocused}
              setIsSkillsFocused={setIsSkillsFocused}
              setTopSkills={setTopSkills}
              selected={selected}
              jobFunctionList={jobFunctionList}
              setJobFunctionList={setJobFunctionList}
              jobFuncDisplayList={jobFuncDisplayList}
              setJobFuncDisplayList={setJobFuncDisplayList}
              seniorityLevelList={seniorityLevelList}
              setSeniorityLevelList={setSeniorityLevelList}
              setSelected={setSelected}
              skills={skills}
              setSkills={setSkills}
              showCustomInput={showCustomInput}
              setShowCustomInput={setShowCustomInput}
              searchTopSkillsResult={searchTopSkillsResult}
              setSearchTopSkillsResult={setSearchTopSkillsResult}
              setError={setError}
              handleAddTopSkills={handleAddTopSkills}
              handleRemoveJobFunction={handleRemoveJobFunction}
              handleAddJobFunctions={handleAddJobFunctions}
              handleRemoveSeniorityLevel={handleRemoveSeniorityLevel}
              handleAddSeniorityList={handleAddSeniorityList}
              handleSkillsFocusBlur={handleSkillsFocusBlur}
              handleRemoveTopSkills={handleRemoveTopSkills}
              showTopSkillsResult={showTopSkillsResult}
              setShowTopSkillsResult={setShowTopSkillsResult}
              domainDisplayList={domainDisplayList}
              handleRemoveDomain={handleRemoveDomain}
              handleAddDomainList={handleAddDomainList}
              domainList={domainList}
              handleRemoveSkill={handleRemoveSkill}
              handleAddWorkPermit={handleAddWorkPermit}
              handleRemoveWorkPermit={handleRemoveWorkPermit}
              workPermitList={workPermitList}
              handleAddLocation={handleAddLocation}
              handleRemoveLcoation={handleRemoveLcoation}
              locationList={locationList}
              stageList={stageList}
              hiringTeamList={hiringTeamList}
              jobRequisitionList={jobRequisitionList}
              handleAddStages={handleAddStages}
              handleRemoveStages={handleRemoveStages}
              handleAddHiringTeam={handleAddHiringTeam}
              handleRemoveHiringTeam={handleRemoveHiringTeam}
              handleAddJobRequisition={handleAddJobRequisition}
              handleRemoveJobRequisition={handleRemoveJobRequisition}
              requisition={requisition}
              setRequisition={setRequisition}
              hiringTeamResults={hiringTeamResults}
              applyFilters={applyFilters}
            />
          </Flex>

          <ReportView
            activePipelineData={activePipelineData}
            isActivelyInterviewing={isActivelyInterviewing}
            setIsActivelyInterviewing={setIsActivelyInterviewing}
            archievedPipelineData={archievedPipelineData}
            setArchievedPipelineData={setArchievedPipelineData}
            activeItemOffset={activeItemOffset}
            setActiveItemOffset={setActiveItemOffset}
            archievedItemOffset={archievedItemOffset}
            setArchievedItemOffset={setArchievedItemOffset}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            resetFilters={resetFilters}
            isActiveLoading={isActiveLoading}
            isArchiveLoading={isArchiveLoading}
          />
        </Flex>
      </Flex>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderRadius="0px">Filter</DrawerHeader>
          <DrawerBody>
            <PipelineFilter
              inputef={inputref}
              error={error}
              topSkills={topSkills}
              isSkillsFocused={isSkillsFocused}
              setIsSkillsFocused={setIsSkillsFocused}
              setTopSkills={setTopSkills}
              selected={selected}
              jobFunctionList={jobFunctionList}
              setJobFunctionList={setJobFunctionList}
              jobFuncDisplayList={jobFuncDisplayList}
              setJobFuncDisplayList={setJobFuncDisplayList}
              seniorityLevelList={seniorityLevelList}
              setSeniorityLevelList={setSeniorityLevelList}
              setSelected={setSelected}
              skills={skills}
              setSkills={setSkills}
              showCustomInput={showCustomInput}
              setShowCustomInput={setShowCustomInput}
              searchTopSkillsResult={searchTopSkillsResult}
              setSearchTopSkillsResult={setSearchTopSkillsResult}
              setError={setError}
              handleAddTopSkills={handleAddTopSkills}
              handleRemoveJobFunction={handleRemoveJobFunction}
              handleAddJobFunctions={handleAddJobFunctions}
              handleRemoveSeniorityLevel={handleRemoveSeniorityLevel}
              handleAddSeniorityList={handleAddSeniorityList}
              handleSkillsFocusBlur={handleSkillsFocusBlur}
              handleRemoveTopSkills={handleRemoveTopSkills}
              showTopSkillsResult={showTopSkillsResult}
              setShowTopSkillsResult={setShowTopSkillsResult}
              domainDisplayList={domainDisplayList}
              handleRemoveDomain={handleRemoveDomain}
              handleAddDomainList={handleAddDomainList}
              domainList={domainList}
              handleRemoveSkill={handleRemoveSkill}
              handleAddWorkPermit={handleAddWorkPermit}
              handleRemoveWorkPermit={handleRemoveWorkPermit}
              workPermitList={workPermitList}
              handleAddLocation={handleAddLocation}
              handleRemoveLcoation={handleRemoveLcoation}
              locationList={locationList}
              stageList={stageList}
              hiringTeamList={hiringTeamList}
              jobRequisitionList={jobRequisitionList}
              handleAddStages={handleAddStages}
              handleRemoveStages={handleRemoveStages}
              handleAddHiringTeam={handleAddHiringTeam}
              handleRemoveHiringTeam={handleRemoveHiringTeam}
              handleAddJobRequisition={handleAddJobRequisition}
              handleRemoveJobRequisition={handleRemoveJobRequisition}
              requisition={requisition}
              setRequisition={setRequisition}
              hiringTeamResults={hiringTeamResults}
              applyFilters={applyFilters}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Modal isOpen={isSettingOpen} onClose={onSettingClose} size="xl">
        <ModalOverlay />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalContent borderRadius="12px">
            <ModalHeader>Staging Time configuration</ModalHeader>
            <ModalCloseButton color="white" />
            <ModalBody padding="10px">
              <Flex direction="column" w="full" align="center" gap="5px">
                <Flex
                  align="center"
                  w="80%"
                  justify="space-between"
                  borderBottom="1px solid grey"
                  mb="5px"
                >
                  <Text fontWeight="500">Stage Name</Text>
                  <Text fontWeight="500">No. of days</Text>
                </Flex>
                {Array.isArray(stagingList) &&
                  stagingList
                    ?.filter(
                      (item) =>
                        item?.stagingLevel !== "ARCHIVED" &&
                        item?.stagingLevel !== "HIRED_STARTED"
                    )
                    .map((stage: any, index: number) => (
                      <Flex
                        align="center"
                        w="80%"
                        justify="space-between"
                        gap="5px"
                        key={index}
                      >
                        <Text fontWeight="700" fontSize="13px">
                          {index + 1 + ")"}
                        </Text>
                        <Text flex={1}>
                          {getStageName(stage?.stagingLevel)}
                        </Text>{" "}
                        <Input
                          type="number"
                          max="99"
                          w="100px"
                          min={1 || "1"}
                          defaultValue={stage.bestTime}
                          borderRadius="24px"
                          {...register(
                            `stagingList[${index}].bestTime` as const,
                            { required: true }
                          )}
                        />
                      </Flex>
                    ))}
              </Flex>
            </ModalBody>

            <ModalFooter>
              <Button
                variant="ghost"
                mr={3}
                type="button"
                onClick={onSettingClose}
              >
                Close
              </Button>
              <Button
                colorScheme="blue"
                type="submit"
                isDisabled={isTimeSubmitting}
                isLoading={isTimeSubmitting}
              >
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </>
  );
};

export default PipelineVisualization;
