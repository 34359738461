import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Progress,
  Select,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { BiWorld } from "react-icons/bi";
import { FaPhone } from "react-icons/fa";
import { useWizard } from "react-use-wizard";
import { components } from "react-select";

import StepsAnimation from "../steps-animation";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { Controller } from "react-hook-form";
import { prefilledLocations } from "../../../utils/helper";

import "../schedule-meeting.css";

const Step2 = ({
  setValue,
  watch,
  register,
  errors,
  handleSubmit,
  control,
  trigger,
}: any) => {
  const { previousStep, nextStep } = useWizard();
  const googleAPI = process.env.REACT_APP_GOOGLE_API_KEY;

  const onNext = (value: any) => {
    nextStep();
  };
  const validateName = (value: any) => {
    if (!value.trim()) {
      return "Name is required";
    }
    return true;
  };
  const validateLocations = (value: any) => {
    if (!value) {
      return "Location is required";
    }

    return true;
  };

  const validatePhone = (value: any) => {
    if (!value) {
      return "Phone Number is required";
    } else if (!/^\d{10}$/.test(value)) {
      return "Phone Number should have exactly 10 characters";
    } else {
      return true;
    }
  };
  const validateAddress = (value: any) => {
    if (!value) {
      return "Address is required";
    }
    return true;
  };
  const validateLink = (value: any) => {
    if (!value) {
      return "Link is required";
    }

    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

    if (!urlRegex.test(value)) {
      return "Invalid URL format";
    }

    return true;
  };
  const CustomMenuList = (props: any) => {
    const handleSelectOption = (item: any) => {
      props.selectProps.onChange(item);
      props.selectProps.onMenuClose();
    };
    return (
      <components.MenuList {...props}>
        {props.options.length > 0 ? (
          props.children
        ) : (
          <Flex direction="column" gap={1}>
            {prefilledLocations.map((item: any) => (
              <Text
                w="full"
                _hover={{ bg: "blue.50" }}
                padding="5px 10px"
                fontWeight="400"
                key={item?.value?.place_id}
                onClick={() => handleSelectOption(item)}
                cursor="pointer"
              >
                {item?.label}
              </Text>
            ))}
          </Flex>
        )}
      </components.MenuList>
    );
  };
  return (
    <VStack mt="2" spacing={4}>
      <Progress
        colorScheme="progressBar"
        size="sm"
        value={34}
        w="full"
        borderRadius={"lg"}
        my="2"
      />
      <StepsAnimation>
        <VStack
          w="full"
          align={"flex-start"}
          minH="300px"
          maxH={"300px"}
          overflow="auto"
        >
          <FormControl isRequired isInvalid={Boolean(errors?.eventName)}>
            <FormLabel fontWeight={"bold"} htmlFor="customer" fontSize="md">
              Event Name
            </FormLabel>
            <Input
              maxLength={50}
              type="text"
              placeholder="Enter Event Name"
              maxW={{ base: "100%", md: "70%" }}
              borderColor="grey"
              {...register("eventName", { validate: validateName })}
              name="eventName"
            />
            <FormErrorMessage>{errors?.eventName?.message}</FormErrorMessage>
          </FormControl>

          <VStack w="full" align={"flex-start"}>
            <FormControl isRequired isInvalid={Boolean(errors?.locations)}>
              <FormLabel fontWeight={"bold"} htmlFor="customer" fontSize="md">
                Interview Type
              </FormLabel>
              <Select
                maxW={{ base: "100%", md: "70%" }}
                isDisabled={false}
                size="md"
                h="38px"
                borderRadius={"md"}
                color={watch("locations") ? "black" : "gray.500"}
                {...register("locations", { validate: validateLocations })}
                name="locations"
                ml="0px"
                placeholder="Select location option"
                border="1px solid grey"
              >
                <option value="in-person">In-person meeting</option>
                <option value="phone">Phone</option>
                <option value="video/web">Video/Web</option>
              </Select>
              <FormErrorMessage>{errors?.locations?.message}</FormErrorMessage>
            </FormControl>
            {watch("locations") === "phone" && (
              <FormControl isRequired isInvalid={Boolean(errors?.phone)}>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<FaPhone color="gray" />}
                  />
                  <Input
                    border="1px solid grey"
                    type="tel"
                    placeholder="Phone number"
                    maxW={{ base: "100%", md: "70%" }}
                    {...register("phone", { validate: validatePhone })}
                    name="phone"
                  />
                </InputGroup>
                <FormErrorMessage>{errors?.phone?.message}</FormErrorMessage>
              </FormControl>
            )}
            {watch("locations") === "video/web" && (
              <FormControl isRequired isInvalid={Boolean(errors?.link)}>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<BiWorld color="gray" />}
                  />
                  <Input
                    border="1px solid grey"
                    maxLength={250}
                    type="text"
                    placeholder="Enter Link"
                    maxW={{ base: "100%", md: "70%" }}
                    {...register("link", { validate: validateLink })}
                    name="link"
                  />
                </InputGroup>
                <FormErrorMessage>{errors?.link?.message}</FormErrorMessage>
              </FormControl>
            )}
            {watch("locations") === "in-person" && (
              <Flex w={{ base: "100%", md: "70%" }}>
                <Controller
                  name="address"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Address is required" }}
                  render={({ field: { onChange, value } }) => (
                    <FormControl
                      isRequired
                      isInvalid={Boolean(errors?.address)}
                    >
                      <GooglePlacesAutocomplete
                        apiKey={googleAPI}
                        selectProps={{
                          value: value,
                          onChange: (newValue) => onChange(newValue),
                          placeholder: "Enter the location...",
                          components: {
                            MenuList: CustomMenuList,
                          },
                          styles: {
                            input: (provided) => ({
                              ...provided,
                              color: "blue",
                            }),
                          },
                        }}
                      />

                      <FormErrorMessage>
                        {errors?.address?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
              </Flex>
            )}
          </VStack>
        </VStack>
      </StepsAnimation>
      <HStack
        spacing={"24px"}
        justify={"space-between"}
        w="full"
        mt="0.5 !important"
      >
        <Button
          loadingText="Loading"
          color="#74788d"
          spinnerPlacement="end"
          size="md"
          fontWeight={"bold"}
          _hover={{ color: "white", bg: "#5867DD" }}
          // leftIcon={<IoIosArrowBack />}
          onClick={() => {
            previousStep();
          }}
        >
          Back
        </Button>
        <Button colorScheme="buttonPrimary" onClick={handleSubmit(onNext)}>
          Next
        </Button>
      </HStack>
    </VStack>
  );
};

export default Step2;
