import { useMutation, useQuery, useQueryClient } from "react-query";

import { useToast } from "@chakra-ui/react";

import { useFetch } from "../utils/useFetch";
import { scrollToTopOfScrollable } from "../utils/scroll-to-top";
const addJob = async (data: any) => {
  const { data: response } = await useFetch.post(
    `/jobs/add-job?userId=${data?.userId}`,
    data
  );

  return response;
};
export const useAddJobs = () => {
  const queryClient = useQueryClient();
  const toast = useToast();
  return useMutation(addJob, {
    onSuccess: (data: any) => {
      queryClient.invalidateQueries("jobs-fetch");
      toast({
        title: "Job added successfully",
        status: "success",
        variant: "top-accent",
        isClosable: true,
      });
    },
    onError: (error: any) => {
      toast({
        title: "Failed to Add Job",
        description: `${error.response.data.message}`,
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });
    },
  });
};
const updateJob = async (data: any) => {
  const { data: response } = await useFetch.put(
    `/jobs/update-job?userId=${data?.userId}&jobId=${data?.jobId}`,
    data
  );

  return response;
};
export const useUpdateJob = () => {
  const queryClient = useQueryClient();
  const toast = useToast();
  return useMutation(updateJob, {
    onSuccess: (data: any) => {
      queryClient.invalidateQueries("jobs-fetch");
      toast({
        title: "Job Updated Successfully",
        status: "success",
        variant: "top-accent",
        isClosable: true,
      });
    },
    onError: (error: any) => {
      toast({
        title: "Failed to Add Job",
        description: `${error.response.data.message}`,
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });
    },
  });
};

const fetchApprovedJobs = async (data: any) => {
  const { data: response } = await useFetch.get(
    `/jobs/gat-all-approve-jobs?page=${data.itemOffset}&limit=12&jobFunction=${
      data.jobFunction
    }&jobName=${data?.query.trim()}&employId=${data?.userId}`
  );

  return response;
};

export const useFetchApprovedJobs = (data: any) => {
  const toast = useToast();
  return useQuery(
    ["job-approved-fetch", data],
    () => fetchApprovedJobs(data),

    {
      enabled: false,
      onSuccess: (data: any) => {},
      onError: (error: any) => {
        toast({
          title: "Error Getting Users",
          description: `Something Went`,
          status: "error",
          variant: "top-accent",
          isClosable: true,
        });
      },
    }
  );
};

const likeJobStatusChange = async (body: any) => {
  const { data: response } = await useFetch.post(`/jobs/archive-job`, body);

  return response;
};
export const useLikeJobStatusChange = () => {
  const toast = useToast();
  return useMutation(likeJobStatusChange, {
    onSuccess: (data: any) => {},
    onError: (error: any) => {
      toast({
        title: "Failed to Add Job",
        description: `${error.response.data.message}`,
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });
    },
  });
};
const fetchLikedJobs = async (data: any) => {
  const { data: response } = await useFetch.get(
    `/users/talent/all-likes-you?page=${data}&limit=12`
  );

  return response;
};

export const useFetchLikedJobs = (data: any) => {
  const toast = useToast();
  return useQuery(["job-liked-fetch", data], () => fetchLikedJobs(data), {
    enabled: false,
    onSuccess: (data: any) => {
      scrollToTopOfScrollable();
    },
    onError: (error: any) => {
      toast({
        title: "Error Getting Users",
        description: `Something Went`,
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });
    },
  });
};

const appliedJobStatusChange = async (body: any) => {
  const { data: response } = await useFetch.post(
    `/jobs/apply/apply-job?jobId=${body}`
  );

  return response;
};
export const useAppliedJobStatusChange = () => {
  const toast = useToast();
  return useMutation(appliedJobStatusChange, {
    onSuccess: (data: any) => {},
    onError: (error: any) => {
      if (error.response.status === 409) {
        toast({
          title: "Failed to Add Job",
          description: `${error.response.data.message}`,
          status: "error",
          variant: "top-accent",
          isClosable: true,
        });
      }
    },
  });
};
const fetchAppliedJobs = async (data: any) => {
  const { data: response } = await useFetch.get(
    `/jobs/apply/apply-job?page=${data.itemOffset}&limit=12&userId=${data?.userId}`
  );

  return response;
};

export const useFetchAppliedJobs = (data: any) => {
  const toast = useToast();
  return useQuery(["job-applied-fetch", data], () => fetchAppliedJobs(data), {
    onSuccess: (data: any) => {
      scrollToTopOfScrollable();
    },
    onError: (error: any) => {
      toast({
        title: "Error Getting Users",
        description: `Something Went`,
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });
    },
  });
};
const fetchCandidateById = async (data: any) => {
  const { data: response } = await useFetch.get(
    `/jobs/get-job-by-id?jobId=${data}`
  );

  return response;
};

export const useFetchCandidateById = (data: any) => {
  const toast = useToast();
  return useQuery(["job-by-id", data], () => fetchCandidateById(data), {
    enabled: false,
    onSuccess: (data: any) => {},
    onError: (error: any) => {
      toast({
        title: "Error Getting Users",
        description: `Something Went`,
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });
    },
  });
};
const fetchArchivedJobs = async (data: any) => {
  const { data: response } = await useFetch.get(
    `/jobs/archive-job?page=${data.itemOffset}&limit=12&userId=${data?.userId}`
  );

  return response;
};

export const useFetchArchivedJobs = (data: any) => {
  const toast = useToast();
  return useQuery(["job-archived-fetch", data], () => fetchArchivedJobs(data), {
    enabled: false,
    onSuccess: (data: any) => {
      scrollToTopOfScrollable();
    },
    onError: (error: any) => {
      toast({
        title: "Error Getting Users",
        description: `Something Went`,
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });
    },
  });
};
