import { create } from "zustand";
import { useFetch } from "../utils/useFetch";

interface PercentageStore {
  percentage: number;
  updatePercentage: (newValue: number) => void;
}

export const usePercentageStore = create<PercentageStore>((set) => ({
  percentage: 30,
  updatePercentage: (newValue: number) => {
    set({ percentage: newValue });
  },
}));

interface TeamMembersStore {
  teamMembersList: any[];
  setTeamMembersList: (newValue: any[]) => void;
  fetchTeamList: () => Promise<void>;
}

export const useAllTeamMembers = create<TeamMembersStore>((set) => ({
  teamMembersList: [],
  setTeamMembersList: (newValue: any[]) => {
    set({ teamMembersList: newValue });
  },
  fetchTeamList: async () => {
    try {
      const response = await useFetch.get(`users/employer/all-team-member`);
      set({ teamMembersList: response?.data });
    } catch (error) {
      console.log(error);
    }
  },
}));
