import { useEffect, useState } from "react";
import { useAuthStore } from "../../../../store/useAuth";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import moment from "moment";
import { getUserId } from "../../../../utils/helper";
import {
  useAddExperience,
  useDeleteExperience,
  useFetchAllExperience,
  useUpdateExperience,
} from "../../../../service/experience.service";

const optionManageLookup: any = {
  NO_I_WAS_INDIVIDUAL_CONTRIBUTOR: "No, I was individual contributor",
  LEAD_TEAMS_BUT_NO_DIRECT_REPORTS: "Lead teams but no direct reports",
  YES_I_HAD_DIRECT_REPORTS: "Yes, I had direct reports",
};

const useExperience = (onModalClose?: () => void, isEdit?: boolean) => {
  const { user, setExperience } = useAuthStore();
  const toast = useToast();
  const [jobFunc, setJobFunc] = useState<string[]>([]);
  const [topSkills, setTopSkills] = useState<any>([]);
  const userId = getUserId();
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const [editableDataExp, setEditableDataExp] = useState<any>(null);

  const [industriesList, setIndustriesList] = useState<string[]>([]);
  const [experienceListInfo, setExperienceListInfo] = useState<string[]>([]);
  const [error, setError] = useState({
    industryError: "",
    jobFunctionError: "",
    skillsError: "",
  });
  const [industryError, setIndustryError] = useState(false);
  const [functionError, setFunctionError] = useState(false);
  const [skillsError, setSkillsError] = useState(false);
  const [isSwitchOn, setIsSwitchOn] = useState<any>(false);

  const {
    mutate: experienceMutation,
    isLoading: experienceLoading,
    data: experienceData,
  } = useAddExperience();
  const {
    mutate: experienceUpdateMutation,
    isLoading: experienceUpdateLoading,
    data: experienceUpdatedData,
  } = useUpdateExperience();
  const { isLoading: experienceListLoading, data: experienceList } =
    useFetchAllExperience(userId);
  const {
    mutate: experienceDelete,
    isLoading: experienceDelLoading,
    isSuccess: experienceDelSuccess,
    isError: experienceDelError,
  } = useDeleteExperience();
  type FormData = {
    experienceInfo: string;
    jobDescription: string;
    jobTitle: string;
    employeeType: any;
    companyName: string;
    jobType: string;
    jobLocation: string;
    managedPeople: string;
    directReports: number | null;
    indirectReports: number | null;
    startDate: Date | null;
    endDate: Date | null;
    currentlyWorking: boolean;
  };

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    trigger,
    reset,
    formState: { errors, isSubmitted },
  } = useForm<FormData>({
    mode: "all",
    shouldUnregister: true,
    defaultValues: {
      experienceInfo: "",
      jobDescription: "",
      jobTitle: "",
      employeeType: "",
      companyName: "",
      jobType: "",
      jobLocation: "",
      managedPeople: "",
      directReports: null,
      indirectReports: null,
      startDate: null,
      endDate: null,

      currentlyWorking: false,
    },
  });

  useEffect(() => {
    if (state?.isEdit) {
      const {
        experienceInfo,
        jobTitle,
        jobDescription,
        employmentType,
        companyName,
        position,
        jobLocation,
        managedPeople,
        indirectReports,
        directReports,
        startDate,
        endDate,
        isStillWorking,
        jobFunc,
        topSkills,
        industries,
      } = state;
      setIsSwitchOn(indirectReports === 0 ? true : false);
      setIndustriesList(industries);
      setValue("jobTitle", jobTitle);
      setValue("jobDescription", jobDescription);
      trigger("jobDescription");
      setValue("experienceInfo", experienceInfo);
      setValue("employeeType", employmentType === "FULL_TIME" ? "0" : "1");
      setValue("companyName", companyName);
      setValue("jobType", position);
      trigger("jobType");
      setValue("jobLocation", jobLocation);
      setValue("managedPeople", optionManageLookup[managedPeople]);
      trigger("managedPeople");
      setValue("directReports", directReports);
      setValue("indirectReports", indirectReports);
      setValue(
        "startDate",
        startDate ? moment(startDate).toDate() : new Date()
      );
      setValue(
        "endDate",
        endDate
          ? moment(endDate).toDate()
          : moment(new Date()).add(1, "year").toDate()
      );
      setValue("currentlyWorking", isStillWorking);
      setJobFunc(jobFunc ? jobFunc : []);
      setTopSkills(topSkills ? topSkills : []);
    }

    return () => {
      setJobFunc([]);
      setTopSkills([]);
    };
  }, [state]);
  let startDateCheck: any = "";

  useEffect(() => {
    if (editableDataExp) {
      const {
        experienceInfo,
        jobDescription,
        jobTitle,
        employmentType,
        companyName,
        position,
        jobLocation,
        managedPeople,
        indirectReports,
        directReports,
        startDate,
        endDate,
        industries,
        isStillWorking,
        jobFunc,
        topSkills,
        jobIndustiesList,
      } = editableDataExp;
      setIsSwitchOn(indirectReports === 0 ? true : false);

      setIndustriesList(industries);
      setValue("experienceInfo", experienceInfo);
      trigger("jobTitle");
      setValue("jobDescription", jobDescription);
      trigger("jobDescription");
      setValue("jobTitle", jobTitle);
      trigger("jobTitle");
      setValue("employeeType", employmentType === "FULL_TIME" ? "0" : "1");
      trigger("employeeType");
      setValue("companyName", companyName);
      trigger("companyName");
      setValue("jobType", position);
      trigger("jobType");
      setValue("jobLocation", jobLocation);
      trigger("jobLocation");
      setValue("managedPeople", optionManageLookup[managedPeople]);
      trigger("managedPeople");
      setValue("directReports", directReports);
      trigger("directReports");
      setValue("indirectReports", indirectReports);
      trigger("indirectReports");
      setValue(
        "startDate",
        startDate ? moment(startDate).toDate() : new Date()
      );
      trigger("startDate");
      setValue("endDate", endDate ? moment(endDate).toDate() : null);
      trigger("endDate");

      setValue("currentlyWorking", isStillWorking);
      trigger("currentlyWorking");
      setJobFunc(jobFunc ? jobFunc : []);
      setTopSkills(topSkills ? topSkills : []);
      if (startDate !== null && startDate !== undefined) {
        startDateCheck = moment(startDate).toDate();
      } else {
      }
    } else {
      return () => {
        setJobFunc([]);
        setTopSkills([]);
      };
    }
  }, [editableDataExp]);

  const handleJobFunc = (option: any) => {
    if (jobFunc.includes(option)) {
      setJobFunc((prevSelectedOptions: any) =>
        prevSelectedOptions.filter((o: any) => o !== option)
      );
    } else if (jobFunc?.length < 3) {
      setJobFunc((prevSelectedOptions: any) => [
        ...prevSelectedOptions,
        option,
      ]);
    } else if (jobFunc?.length >= 3) {
      toast({
        title: "You can select up to 3 options",
        status: "info",
        variant: "top-accent",
        isClosable: true,
        position: "top-right",
        duration: 3000,
      });
    }
  };
  const handleOptionSkills = (option: any) => {
    if (topSkills.includes(option)) {
      setTopSkills((prevSelectedOptions: any) =>
        prevSelectedOptions.filter((o: any) => o !== option)
      );
    } else if (topSkills?.length < 3) {
      setTopSkills((prevSelectedOptions: any) => [
        ...prevSelectedOptions,
        option,
      ]);
    } else if (topSkills?.length >= 3) {
      toast({
        title: "You can select up to 3 options",
        status: "info",
        variant: "top-accent",
        isClosable: true,
        position: "top-right",
        duration: 3000,
      });
    }
  };

  const handleStartDateChange = (date: any) => {
    setValue(
      "startDate",
      moment(date).startOf("month").endOf("day").utc().toDate()
    );
    trigger("startDate");
  };

  const handleEndDateChange = (date: any) => {
    setValue(
      "endDate",
      moment(date).startOf("month").endOf("day").utc().toDate()
    );
    trigger("endDate");
  };
  const handleValidation = () => {
    let isValid = true;
    if (jobFunc?.length < 1) {
      setFunctionError(true);
      isValid = false;
      setError((prevError: any) => ({
        ...prevError,
        jobFunctionError: "Please Select atleast one Job Function",
      }));
    }
    if (industriesList?.length < 1) {
      isValid = false;
      setError((prevError: any) => ({
        ...prevError,
        industryError: "Please Select atleast one Industry",
      }));
      setIndustryError(true);
    }

    if (topSkills?.length < 1) {
      isValid = false;
      setSkillsError(true);
      setError((prevError: any) => ({
        ...prevError,
        skillsError: "Please Select atleast one Skill",
      }));
    }
    return isValid;
  };
  const onSubmit = async (values: any) => {
    if (!!handleValidation()) {
      const userId = getUserId();
      const {
        jobType,
        jobLocation,
        managedPeople,
        jobDescription,
        directReports,
        indirectReports,
        currentlyWorking,
        companyName,
        jobTitle,
        startDate,
        endDate,
        ...rest
      } = values;
      let finalStartDate = "";
      let finalEndDate = "";
      if (startDate) {
        finalStartDate = `${startDate.getFullYear()}-${
          startDate.getMonth() + 1 < 10
            ? "0" + (startDate.getMonth() + 1)
            : startDate.getMonth() + 1
        }-${
          startDate.getDate() < 10
            ? "0" + startDate.getDate()
            : startDate.getDate()
        }`;
      }
      if (endDate) {
        finalEndDate = `${endDate.getFullYear()}-${
          endDate.getMonth() + 1 < 10
            ? "0" + (endDate.getMonth() + 1)
            : endDate.getMonth() + 1
        }-${
          endDate.getDate() < 10 ? "0" + endDate.getDate() : endDate.getDate()
        }`;
      }
      const employeeType = parseInt(values.employeeType);
      const formData = {
        jobType,
        ...rest,
        jobLocation,
        directReports,
        indirectReports,
        jobDescription: jobDescription.trim(),
        companyName: companyName.trim(),
        jobTitle: jobTitle.trim(),
        jobFunc: jobFunc,
        topSkills: topSkills,
        jobIndustry: industriesList,
        experienceListInfo,
        employeeType: employeeType,
        startDate: finalStartDate,
        endDate: finalEndDate,
      };

      if (managedPeople !== "Yes, I had direct reports") {
        delete formData.directReports;
        delete formData.indirectReports;
        if (
          formData.jobFunc?.length === 0 ||
          formData.topSkills?.length === 0
        ) {
          return;
        }
      }
      const reqData = {
        ...formData,
        managedPeople:
          managedPeople === "Yes, I had direct reports"
            ? 2
            : managedPeople === "Lead teams but no direct reports"
            ? 1
            : 0,
      };

      try {
        experienceMutation(
          {
            userId,
            positionRequestBody: { ...reqData },
            noIndirectReportsAvailible: isSwitchOn,
          },
          {
            onSuccess: (resData) => {
              localStorage.setItem(
                "profilepercentage",
                JSON.stringify(resData?.profileCompletePercentage)
              );

              pathname !== "/search"
                ? navigate(-1)
                : onModalClose && onModalClose();
            },
          }
        );
      } catch (error) {
        console.log("error", error);
      }
    }
  };
  const deleteHandler = (positionId: string, userId: string) => {
    experienceDelete({ positionId, userId });
  };

  const updateHandler = (values: any) => {
    if (!!handleValidation()) {
      const {
        jobType,
        jobLocation,
        managedPeople,
        jobDescription,
        directReports,
        indirectReports,
        currentlyWorking,
        companyName,
        jobTitle,
        startDate,
        endDate,
        ...rest
      } = values;
      let finalStartDate = "";
      let finalEndDate = "";
      if (startDate) {
        finalStartDate = `${startDate.getFullYear()}-${
          startDate.getMonth() + 1 < 10
            ? "0" + (startDate.getMonth() + 1)
            : startDate.getMonth() + 1
        }-${
          startDate.getDate() < 10
            ? "0" + startDate.getDate()
            : startDate.getDate()
        }`;
      }
      if (endDate) {
        finalEndDate = `${endDate.getFullYear()}-${
          endDate.getMonth() + 1 < 10
            ? "0" + (endDate.getMonth() + 1)
            : endDate.getMonth() + 1
        }-${
          endDate.getDate() < 10 ? "0" + endDate.getDate() : endDate.getDate()
        }`;
      }
      const employeeType = parseInt(values.employeeType);
      const formData = {
        jobType,
        ...rest,
        jobLocation,
        directReports,
        indirectReports,
        jobDescription: jobDescription.trim(),
        companyName: companyName.trim(),
        jobTitle: jobTitle.trim(),
        jobFunc: jobFunc,
        topSkills: topSkills,
        jobIndustry: industriesList,
        experienceListInfo,
        employeeType: employeeType,
        startDate: finalStartDate,
        endDate: finalEndDate,
      };

      if (jobType === "Remote") {
        delete formData.jobLocation;
      }

      if (managedPeople !== "Yes, I had direct reports") {
        delete formData.directReports;
        delete formData.indirectReports;
        if (
          formData.jobFunc?.length === 0 ||
          formData.topSkills?.length === 0
        ) {
          return;
        }
      }

      const reqData = {
        ...formData,
        managedPeople:
          managedPeople === "Yes, I had direct reports"
            ? 2
            : managedPeople === "Lead teams but no direct reports"
            ? 1
            : 0,
        currentlyWorking,
      };

      try {
        experienceUpdateMutation(
          {
            positionRequestBody: { ...reqData },
            userId,
            experienceId: state?.id ? state?.id : editableDataExp?.id,
          },
          {
            onSuccess: (resData) => {
              pathname !== "/profile"
                ? navigate(-1)
                : onModalClose && onModalClose();
              setError({
                industryError: "",
                jobFunctionError: "",
                skillsError: "",
              });
            },
          }
        );
      } catch (error) {
        console.log("error::", error);
      }
    }
  };

  return {
    register,
    errors,
    handleSubmit,
    watch,
    onSubmit,
    control,
    setExperience,
    user,
    isSubmitted,
    handleOptionSkills,
    handleJobFunc,
    jobFunc,
    setJobFunc,
    topSkills,
    setTopSkills,
    deleteHandler,
    handleStartDateChange,
    handleEndDateChange,
    updateHandler,
    navigate,
    experienceDelLoading,
    experienceLoading,
    experienceData,
    experienceList,
    experienceListLoading,
    state,
    experienceUpdateLoading,
    setEditableDataExp,
    setValue,
    trigger,
    industriesList,
    setIndustriesList,
    experienceListInfo,
    setExperienceListInfo,
    industryError,
    functionError,
    skillsError,
    setSkillsError,
    setFunctionError,
    setIndustryError,
    isSwitchOn,
    setIsSwitchOn,
    error,
    setError,
    handleValidation,
  };
};

export default useExperience;
