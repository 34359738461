import { Progress } from "@chakra-ui/react";
import Experience from "../../../components/candidate/signupFlow/experience";
import { getisProfile30percent } from "../../../utils/helper";

const ExperiencePage = () => {
  const profilePercentage = getisProfile30percent();

  return (
    <>
      <Progress
        colorScheme="progressBar"
        size="xs"
        value={profilePercentage}
        w="full"
        borderRadius={"lg"}
        mb="6 !important"
        mt="2 !important"
      />
      <Experience />
    </>
  );
};

export default ExperiencePage;
