import { useMutation, useQuery, useQueryClient } from "react-query";

import { useToast } from "@chakra-ui/react";

import { useFetch } from "../utils/useFetch";
import { handleLogoutLocalStorage } from "../utils/helper";

const addEducation = async (data: any) => {
  const { data: response } = await useFetch.post(
    "/users/talent/edit-education",
    data
  );

  return response;
};
export const useAddEducation = () => {
  const queryClient = useQueryClient();
  const toast = useToast();
  return useMutation(addEducation, {
    onSuccess: (data: any) => {
      queryClient.invalidateQueries("education-fetch");
      toast({
        title: "Education added successfully",
        status: "success",
        variant: "top-accent",
        isClosable: true,
      });
    },
    onError: (error: any) => {
      toast({
        title: "Failed to Add Education",
        description: `${error.response.data.message}`,
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });
    },
  });
};
const fetchAllEducation = async (id: any) => {
  const { data: response } = await useFetch.get(
    `users/talent/talent-all-education?userId=${id}`
  );

  return response?.body ? response?.body : response;
};

export const useFetchAllEducation = (data: any) => {
  const toast = useToast();
  return useQuery(["education-fetch", data], () => fetchAllEducation(data), {
    onSuccess: (data: any) => {},
    onError: (error: any) => {
      toast({
        title: "Error Getting Education list",
        description: `Something Went Wrong`,
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });
    },
  });
};
const deleteEducation = async ({ educationId, userId }: any) => {
  const { data: response } = await useFetch.delete(
    `/users/talent/delete-education?userId=${userId}&educationId=${educationId}`
  );
  return response;
};
export const useDeleteEducation = () => {
  const queryClient = useQueryClient();
  const toast = useToast();
  return useMutation(deleteEducation, {
    onSuccess: (data: any) => {
      queryClient.invalidateQueries("education-fetch");
      toast({
        title: `Education entry deleted.`,
        description: " Continue to the next step or add another",
        status: "success",
        variant: "top-accent",
        isClosable: true,
      });
    },
    onError: (error: any) => {
      toast({
        title: "Failed to Delete Education data",
        description: `${error.response.data.message}`,
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });
    },
  });
};
const updateEducation = async (data: any) => {
  const { data: response } = await useFetch.put(
    "/users/talent/update-education",
    data
  );

  return response;
};
export const useUpdateEducation = () => {
  const queryClient = useQueryClient();
  const toast = useToast();
  return useMutation(updateEducation, {
    onSuccess: (data: any) => {
      queryClient.invalidateQueries("education-fetch");
      toast({
        title: "Education updated successfully",
        status: "success",
        variant: "top-accent",
        isClosable: true,
      });
    },
    onError: (error: any) => {
      toast({
        title: "Failed to update education",
        description: `${error.response.data.message}`,
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });
    },
  });
};

const addLocation = async (data: any) => {
  const { data: response } = await useFetch.post(
    "/users/talent/location-fullname",
    data
  );

  return response;
};
export const useAddLocation = () => {
  const toast = useToast();
  return useMutation(addLocation, {
    onSuccess: (data: any) => {
      {
        data?.fullName &&
          localStorage.setItem("userName", JSON.stringify(data?.fullName));
      }
      toast({
        title: "Personal Information Added Successfully",
        status: "success",
        variant: "top-accent",
        isClosable: true,
      });
    },
    onError: (error: any) => {
      toast({
        title: "Failed to Add Location and Name",
        description: `${error.response.data.message}`,
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });
    },
  });
};

const updateLocation = async (data: any) => {
  const { data: response } = await useFetch.put(
    "/users/talent/location-fullname",
    data
  );

  return response;
};
export const useUpdateLocation = () => {
  const queryClient = useQueryClient();
  const toast = useToast();
  return useMutation(updateLocation, {
    onSuccess: (data: any) => {
      queryClient.invalidateQueries("generalInfo-fetch");
      toast({
        title: "Updated successfully",
        status: "success",
        variant: "top-accent",
        isClosable: true,
      });
    },
    onError: (error: any) => {
      toast({
        title: "Failed to Update",
        description: `${error.response.data.message}`,
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });
    },
  });
};
const fetchLocation = async (id: any) => {
  const { data: response } = await useFetch.get(
    `/users/talent/location-fullname?userId=${id}`
  );

  return response?.body ? response?.body : response;
};

export const useFetchLocation = (data: any) => {
  const toast = useToast();
  return useQuery(["location-fetch", data], () => fetchLocation(data), {
    onSuccess: (data: any) => {
      localStorage.setItem("userName", JSON.stringify(data?.fullName));
    },
    onError: (error: any) => {
      toast({
        title: "Error Getting Data ",
        description: `Something Went Wrong`,
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });
    },
  });
};

const fetchGeneralInfo = async (id: any) => {
  try {
    const { data: response } = await useFetch.get(
      `users/talent/specific-details?userId=${id}`
    );

    return response ? response : {};
  } catch (error: any) {
    if (error?.response?.data === "User Not Found") {
      handleLogoutLocalStorage();
      window.location.href = "/";
    }
  }
};

export const useGeneralInfoFetch = (data: any) => {
  const toast = useToast();
  return useQuery(["generalInfo-fetch", data], () => fetchGeneralInfo(data), {
    onSuccess: (data: any) => {
      localStorage.setItem("userName", JSON.stringify(data?.fullName));
    },
    onError: (error: any) => {
      toast({
        title: "Error Getting Other information",
        description: `Something Went Wrong`,
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });
    },
  });
};
const fetchBasicInfo = async (id: any) => {
  const { data: response } = await useFetch.get(
    `users/talent/get-basic-profile?userId=${id}`
  );

  return response ? response : null;
};

export const useBasicInfoFetch = (data: any) => {
  const toast = useToast();
  return useQuery(["basicInfo-fetch", data], () => fetchBasicInfo(data), {
    onSuccess: (data: any) => {},
    onError: (error: any) => {
      toast({
        title: "Error Getting Other information",
        description: `Something Went Wrong`,
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });
    },
  });
};
const addBasicInfo = async (data: any) => {
  const { data: response } = await useFetch.post(
    "/users/talent/create-basic-profile",
    data
  );

  return response;
};

export const useAddBasic = () => {
  const queryClient = useQueryClient();
  const toast = useToast();
  return useMutation(addBasicInfo, {
    onSuccess: (data: any) => {
      queryClient.invalidateQueries("basicInfo-fetch");
      toast({
        title: "Updated Successfully",
        status: "success",
        variant: "top-accent",
        isClosable: true,
      });
    },
    onError: (error: any) => {
      toast({
        title: "Failed to Add Location and Name",
        description: `${error.response.data.message}`,
        status: "error",
        variant: "top-accent",
        isClosable: true,
      });
    },
  });
};
