import { Navigate, Route, Routes } from "react-router-dom";
import InterviewCandidatePage from "../../../pages/Candidate/interview-candidate";
import JobsCandidatePage from "../../../pages/Candidate/jobs-candidate";
import SearchCandidatePage from "../../../pages/Candidate/seacrh-candidate";
import SettingsCandidatePage from "../../../pages/Candidate/settings-candidate";
import NotFoundPage from "../../../pages/not-found";
import Layout from "./Layout";
import PrivateRoutes from "./PrivateRoute/private-routes";
import { SignupFlow } from "./signup-flow/signupFlowRoutes";
import MeetingDetails from "../../candidate/interview/MeetingDetails";
import SearchJobDetails from "../../candidate/searchJobs/details";
import ChatEmployeerPage from "../../../pages/Employeer/chat-employeer";
import ArchivedCandidatePage from "../../../pages/Candidate/archived-candidate";
import LikesYouPage from "../../../pages/Candidate/likesYou-candidate";

export const CandidateRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* we want to protect these routes */}
        <Route element={<PrivateRoutes />}>
          <Route path="/" element={<Navigate to="/search" replace />} />
          {/* <Route path="/home" element={<DashboardPageCandidate />} /> */}
          <Route path="/search" element={<SearchCandidatePage />} />
          <Route path="/archived" element={<ArchivedCandidatePage />} />
          <Route path="/likesYou" element={<LikesYouPage />} />
          <Route path="/jobs" element={<JobsCandidatePage />} />
          <Route path="/chat" element={<ChatEmployeerPage />} />
          {/* <Route path="/chat-ai" element={<ChatAICandidatePage />} /> */}
          <Route path="/interview" element={<InterviewCandidatePage />} />
          <Route path="/profile" element={<SettingsCandidatePage />} />
          <Route path="/interview/details" element={<MeetingDetails />} />
          <Route path="/search/:id" element={<SearchJobDetails />} />
          <Route path="/archived/:id" element={<SearchJobDetails />} />
          <Route path="/likesYou/:id" element={<SearchJobDetails />} />
          <Route path="/jobs/:id" element={<SearchJobDetails />} />
        </Route>
        <Route path="/setupFlow/*" element={<SignupFlow />} />

        {/* catch all */}
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};
